import './AvailableScheduleMenu.css'
import React, { useState } from "react";
import { IcoBin, MaskDataHora } from '../../../../../../../../../../../../component';
import IcoQuickly from '../../../../../../../../../../../../component/icon/quickly';
import IcoBlockCalendar from '../../../../../../../../../../../../component/icon/calendar_block';
import { NewPatientIcon } from '../../../../../../../../../../../../component/icon/newPatient';
import { useBookingAppointmentCursor } from '../../../../../../BookingAppointmentCursor';
import AssignScheduleToPatient from '../../../AssignScheduleToPatient/AssignScheduleToPatient';
import DeleteScheduleSlot from '../../../DeleteScheduleSlot/DeleteScheduleSlot';
import MarkTimeAsUnavailable from '../../../MarkTimeAsUnavailable/MarkTimeAsUnavailable';

export default function AvailableScheduleMenu({ appointment={}, displayMode='daily' }) {
    const [isOpen, setIsOpen] = useState(false);
    const { setModalInfo, setSelectedTimesCursor, selectedTimesCursor } = useBookingAppointmentCursor()
    const startHourTime = MaskDataHora(appointment?.fields?.start || appointment?.start, 'DD/MMM/AAAA', '-');
    const endHourTime = MaskDataHora(appointment?.fields?.end || appointment?.end, 'DD/MMM/AAAA', '-');
    const appointmentDuration = new Date(appointment.fields.end) - new Date(appointment.fields.start)
    const isChecked = !!selectedTimesCursor.find(({pk}) => pk === appointment.pk)

    const openAssignScheduleToPatient = () => {
      const start = appointment?.fields?.start?.slice(11,16) || appointment?.start?.slice(11,16) || '-'
      const end = appointment?.fields?.end?.slice(11,16) || appointment?.end?.slice(11,16) || '-'

      setModalInfo(prev => ({ 
          ...prev, 
          open: true,
          title: `Editar horário | ${startHourTime?.data} | ${start} - ${end}`,
          content: <AssignScheduleToPatient initSchedulingInfo={appointment} setModalInfo={setModalInfo} />
      }))
    }

    const openRemoveSchedule = () => {
      setModalInfo(prev => ({
          ...prev,
          open: true,
          title: 'Remover Horário(s)',
          content: <DeleteScheduleSlot appointments={[appointment]} />
      }))
    }

    const handleBlockSchedule = async () => {
      setModalInfo(prev => ({
        ...prev,
        open: true,
        title: 'Bloquear Horário(s)',
        content: <MarkTimeAsUnavailable appointments={[appointment]} />
      }))
    }

    const dropdownOptions = [
      { 
        title: 'Adicionar Paciente', 
        Icon: <NewPatientIcon style={{ width: '14px', height: '14px' }} />,
        action: openAssignScheduleToPatient,
      },
      { 
        title: 'Excluir Horário',
        Icon: <IcoBin style={{ width: '14px', height: '14px' }} className='' />,
        action: openRemoveSchedule
      },
      { 
        title: 'Bloquear Horário', 
        Icon: <IcoBlockCalendar style={{ width: '14px', height: '14px' }} className='' />,
        action: handleBlockSchedule
      },
    ]

    return <div 
      className='AvailableScheduleMenu'
      style={displayMode === 'weekly' ? {fontSize: '12px'} : {fontSize: '14px'}}
      onMouseEnter={() => setIsOpen(true)} 
      onMouseLeave={() => setIsOpen(false)}
    >
      <div>
        <div className='AvailableScheduleMenu-TitleDiv'>
          <span className='AvailableScheduleMenu-Title'>
            <strong>Disponível </strong>&nbsp;
            <span style={{ fontSize: '12px' }}>
              {appointmentDuration <= 1500000 && displayMode === 'daily' && (
                `| ${startHourTime?.hora?.slice(0,5) || '-'} | ${endHourTime?.hora?.slice(0,5) || '-'}`
                  )}
            </span>          
          </span>
          {displayMode==='weekly' && (
            <input 
            type='checkbox'
            className='AvailableScheduleMenu-checkbox'
            checked={isChecked}
            onChange={
              () => {
                isChecked 
                ? setSelectedTimesCursor(prev => prev.filter(({pk}) => appointment.pk != pk))
                : setSelectedTimesCursor(prev => ([ ...prev, appointment ]))}
              }
          />
          )}
          
        </div>
        {appointmentDuration > 1500000 && (
          <div className='AvailableScheduleMenu-HourTime'>
            <IcoQuickly style={displayMode === 'daily' ? { fontSize: '16px' } : { fontSize: '14px' }} /> 
            <span style={displayMode === 'daily' ? { fontSize: '12px' } : { fontSize: '10px' }}>{startHourTime?.hora?.slice(0,5) || '-'} | {endHourTime?.hora?.slice(0,5) || '-'}</span>
          </div>
        )}
      </div>
      <div className='AvailableScheduleMenu-Dropdown'>
          {isOpen && (
            <div className="AvailableScheduleMenu-Dropdown-content">
              {dropdownOptions.map((option, index) => (
                <div
                  key={index}
                  title={option.title}
                  onClick={option.action} 
                  className="AvailableScheduleMenu-Dropdown-item"
                >
                  {option.Icon} {option.label}
                </div>
              ))}
            </div>
          )}
      </div>
    </div>
  }