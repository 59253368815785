import './ConfirmAssign.css'
import Button, { ButtonModel } from '../../../../../../../../../../../newcomponents/button/button'

export default function ConfirmAssign({ patient , cancelSubmission, continueSubmission }) {
    const formatBirthday = () => {
        const [birthdayYear, birthdayMonth, birthdayDay] = patient.birthday.split('-')
        const formattedBirthday = `${birthdayDay}/${birthdayMonth}/${birthdayYear}`
        return formattedBirthday
    }

    const getFormattedDocument = () => {
        const documentType = patient.document_type === 'RG' ? 'RG' : 'CPF'
        const document = documentType ? (documentType === 'CPF' ? patient.cpf : patient.rg) : '-'
        return {documentType, document}
    }

    const formattedBirthday = formatBirthday()
    const {documentType: formattedDocumentType, document: formattedDocument} = getFormattedDocument()

    return <section className="ConfirmAssignSchedule">
        <p>
            O paciente <strong>{patient.name ? patient.name : '-'}</strong>, data de nascimento <strong>{patient.birthday ? formattedBirthday : '-'}</strong>,
            nome da mãe <strong>{patient.mother_name ? patient.mother_name : '-'}</strong> e {formattedDocumentType} <strong>{formattedDocument}</strong> está 
            inativo no sistema. Ao prosseguir com o atendimento, ele será reativado. <br/>Recomenda-se atualizar os dados do paciente.
        </p>

        <div className='ConfirmAssignSchedule-Btn-Box'>
            <Button 
                model={ButtonModel.SECONDARY}
                label={'Cancelar'}
                onClick={() => cancelSubmission()}
            />
            <Button 
                model={ButtonModel.PRIMARY}
                label={'Prosseguir'}
                onClick={() => continueSubmission(patient)}
            />
        </div>
    </section>
}