const { DECISION_CHOICES } = require('../../component/form/select/options.json')

function getLast12Months() {
    const months = [];
    let currentDate = new Date();
  
    for (let i = 0; i < 12; i++) {
      months.unshift(currentDate.toLocaleString('default', { month: 'short' }) + '/' + currentDate.getFullYear());
      currentDate.setMonth(currentDate.getMonth() - 1);
    }
  
    return months;
}


export function strpBarChartData(count_attendances_by_month_and_type=null) {
    let init = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    
    return {
        "labels": getLast12Months(),
        "datasets": [
            {
              "label": "Normal",
              "data": count_attendances_by_month_and_type ? Object.values(count_attendances_by_month_and_type).map(({ NORMAL }) => NORMAL) : init,
              "backgroundColor": "#bbb",
              "barThickness": 10,
              "categoryPercentage": 0.8,
              "barPercentage": 0.5
            },
            {
              "label": "Telemedicina",
              "data": count_attendances_by_month_and_type ? Object.values(count_attendances_by_month_and_type).map(({ TELEMEDICINE }) => TELEMEDICINE) : init,
              "backgroundColor": "#40434E",
              "barThickness": 10,
              "categoryPercentage": 0.8,
              "barPercentage": 0.5
            },
            {
              "label": "Total",
              "data": count_attendances_by_month_and_type ? Object.values(count_attendances_by_month_and_type).map(({ TOTAL }) => TOTAL) : init,
              "backgroundColor": "#000000",
              "barThickness": 10,
              "categoryPercentage": 0.8,
              "barPercentage": 0.5
            }
        ]
    }
}

export function strpTodayChartData(today_attendance_counts=null) {
    const defaultData = new Array(DECISION_CHOICES.length).fill(0);

    const attendanceMapping = DECISION_CHOICES.reduce((acc, choice) => {
        acc[choice.id] = choice.name;
        return acc;
    }, {});

    const colorMapping = DECISION_CHOICES.reduce((acc, choice) => {
        acc[choice.id] = choice.color;
        return acc;
    }, {});

    const labels = Object.values(attendanceMapping);
    const backgroundColors = Object.keys(attendanceMapping).map(key => colorMapping[key]);

    const data = today_attendance_counts ? labels.map(label => {
        const key = Object.keys(attendanceMapping).find(key => attendanceMapping[key] === label);
        return today_attendance_counts[key] || 0;
    }) : defaultData;

    return {
        labels: labels,
        datasets: [{
            label: "Tipo de Atendimento",
            data: data,
            backgroundColor: backgroundColors,
            hoverOffset: 4
        }]
    };
}