import React from "react"

export default function IcoBlockCalendar({ style={}, className='icon-group' }) {
    return <svg style={style} className={className} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M1081 4659 c-107 -53 -149 -130 -158 -286 l-6 -101 -211 -4 c-192 -4
            -216 -6 -270 -27 -79 -31 -165 -117 -197 -197 l-24 -59 0 -1640 0 -1640 32
            -67 c37 -79 98 -142 173 -177 l55 -26 2085 0 2085 0 56 26 c75 35 134 96 172
            177 l32 67 3 1604 c1 1058 -1 1623 -8 1660 -22 122 -107 229 -215 271 -55 22
            -78 24 -271 28 l-211 4 -6 96 c-8 142 -42 217 -121 271 -57 38 -99 51 -170 51
            -108 0 -209 -68 -255 -170 -16 -36 -21 -66 -21 -147 l0 -103 -1070 0 -1070 0
            0 103 c0 86 -4 110 -24 152 -71 150 -237 208 -385 134z m197 -129 c56 -34 72
            -73 72 -172 l0 -88 -140 0 -140 0 0 86 c0 103 15 142 68 172 46 27 97 28 140
            2z m2715 -6 c43 -31 57 -73 57 -168 l0 -86 -140 0 -140 0 0 88 c0 99 16 137
            72 173 40 25 112 22 151 -7z m-2921 -503 c3 -90 5 -102 28 -126 32 -34 72 -55
            105 -55 32 0 75 -41 75 -73 0 -13 -11 -34 -25 -47 -21 -20 -31 -22 -78 -17
            -66 6 -124 35 -171 83 -60 62 -86 144 -86 270 l0 64 74 0 74 0 4 -99z m2698
            12 c0 -70 4 -95 20 -120 24 -41 58 -62 106 -70 76 -11 109 -78 63 -124 -19
            -19 -30 -21 -78 -16 -69 6 -123 32 -173 84 -61 63 -78 112 -78 231 l0 102 70
            0 70 0 0 -87z m-2300 -643 c29 -29 26 -74 -6 -99 -26 -21 -36 -21 -565 -21
            l-539 0 0 70 0 70 545 0 c532 0 545 0 565 -20z m1308 5 c726 -118 1225 -802
            1116 -1533 -42 -288 -172 -542 -383 -752 -198 -197 -412 -313 -684 -372 -145
            -31 -389 -31 -535 0 -276 60 -513 191 -706 392 -177 185 -289 394 -347 650
            -28 122 -35 346 -16 475 29 188 103 386 199 536 202 314 540 538 913 603 108
            19 328 19 443 1z m1982 -55 l0 -70 -539 0 c-529 0 -539 0 -565 21 -31 24 -35
            72 -9 101 15 17 46 18 565 18 l548 0 0 -70z"/>
            <path d="M2432 3050 c-219 -31 -418 -129 -573 -283 -298 -297 -377 -744 -199
            -1124 41 -87 67 -111 112 -100 13 4 312 296 666 649 569 571 642 647 642 674
            0 40 -28 65 -115 105 -149 67 -375 101 -533 79z"/>
            <path d="M2687 1932 c-483 -483 -647 -653 -647 -670 0 -13 5 -33 11 -44 14
            -25 127 -78 245 -115 82 -25 103 -27 259 -27 148 -1 181 2 255 22 407 111 696
            451 739 866 16 161 -5 309 -70 476 -39 102 -70 140 -112 140 -29 0 -96 -64
            -680 -648z"/>
        </g>
    </svg>
}


