/* eslint-disable no-useless-escape */
const typePatern = {
  phone: /\([0-9]{2}\) [0-9]{4,7}-[0-9]{4,5}$/,
  zip: /^[0-9]{5}-[0-9]{3}$/,
  email: /^[a-zA-ZçÇ0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  ip: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  number: /[0-9]+$/,
  senha: {
    especial: `/^(?=.*[@!#$%^&*()/\\])[@!#$%^&*()/\\a-zA-Z0-9]{1,20}$/`,
    maiuscula: `/^(?=.*[A-Z])[@!#$%^&*()/\\a-zA-Z0-9]{1,20}$/`
  }
}

const getPatternType = (pattern, name, valor) => {
  const type = {
    object: {      
      number: {campo: name, mensagem: validarNumber({ valor, max: pattern.max, min: pattern.min })},
      date: {campo: name, mensagem: validarData({ valor, max: pattern.max, min: pattern.min })},
    },
    string: {
      cpf: {campo: name, mensagem: validarCPF(valor)},
      cnpj: {campo: name, mensagem: validarCNPJ(valor)},
      phone: {campo: name, mensagem: validarPattern(pattern, valor)},
      zip: {campo: name, mensagem: validarPattern(pattern, valor)},
      select: valor && !Object.keys(valor).length ? { campo: name, mensagem: 'vazio' } : { campo: name, mensagem: false },
      multiselect: !valor.length ? { campo: name, mensagem: 'vazio' } : { campo: name, mensagem: false },
    },
  }

  return pattern?.type ? type[typeof pattern][pattern.type] : type[typeof pattern][pattern] ? type[typeof pattern][pattern] : null
}

export const validacaoCampo = ({ valor, name, pattern }) => {
  if (pattern?.search('type') !== -1) {
    pattern = JSON.parse(pattern)
  }

  const getPattern = getPatternType(pattern, name, valor)

  if (typeof valor === 'object') {
    return getPattern
  }  

  if (getPattern) return getPattern

  return valor?.search(typePatern[pattern] ? typePatern[pattern] : pattern) === -1 ? {campo: name, mensagem: 'regex'} : {campo: name, mensagem: false}
}

export const validarCampo = e => {
  const valor = e.target.value
  const { name, pattern } = e.target
  if (!valor) {
    return { campo: name, mensagem: 'vazio' }
  }
  return validacaoCampo({ valor, name, pattern })
}

export const validacaoForm = e => {
  let erro = {}

  const pattern = e => {
    if (typeof e === 'object') {
      return JSON.stringify(e)
    } else if (typeof e === 'string') {
      return e
    } else {
      return ''
    }
  }

  for (const name in e.formRequired) {
    if (e.formRequired[name]) {
      let valForm = validacaoCampo({ valor: e.formValues[name], name, pattern: pattern( e.formRequired[name] ) })
      erro = { ...erro, [name]: valForm.mensagem }
    } else {
      erro = { ...erro, [name]: !e.formValues[name] }
    }
  }
  return erro
}

export const validarPattern = (pattern, valor) => {
  const regex = new RegExp(typePatern[pattern])
  return regex.test(valor) ? false : 'invalido'
}

export const validarCPF = (e) => {
  if (typeof e !== "string") return 'invalido'
  e = e.replace(/[\s.-]*/igm, '')
  if (
      !e ||
      e.length !== 11 ||
      e === "00000000000" ||
      e === "11111111111" ||
      e === "22222222222" ||
      e === "33333333333" ||
      e === "44444444444" ||
      e === "55555555555" ||
      e === "66666666666" ||
      e === "77777777777" ||
      e === "88888888888" ||
      e === "99999999999" 
  ) {
      return 'invalido'
  }
  var soma = 0
  var resto
  for (var i = 1; i <= 9; i++) 
      soma = soma + parseInt(e.substring(i-1, i)) * (11 - i)
  resto = (soma * 10) % 11
  if ((resto === 10) || (resto === 11)) resto = 0
  if (resto !== parseInt(e.substring(9, 10)) ) return 'invalido'
  soma = 0
  for (i = 1; i <= 10; i++)
      soma = soma + parseInt(e.substring(i-1, i)) * (12 - i)
  resto = (soma * 10) % 11
  if ((resto === 10) || (resto === 11)) resto = 0
  if (resto !== parseInt(e.substring(10, 11) ) ) return 'invalido'
  return false
}

export const validarCNPJ = (cnpj) => {

  if (typeof cnpj !== "string") return 'invalido'
 
  cnpj = cnpj.replace(/\D/g, '')
   
  if (cnpj.length !== 14) return 'invalido'

  if (cnpj === "00000000000000" || 
      cnpj === "11111111111111" || 
      cnpj === "22222222222222" || 
      cnpj === "33333333333333" || 
      cnpj === "44444444444444" || 
      cnpj === "55555555555555" || 
      cnpj === "66666666666666" || 
      cnpj === "77777777777777" || 
      cnpj === "88888888888888" || 
      cnpj === "99999999999999")
      return 'invalido'

  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  const digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2)
          pos = 9
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  if (resultado !== Number(digitos.charAt(0))) return 'invalido'
    
  tamanho += 1
  numeros = cnpj.substring(0,tamanho)
  soma = 0
  pos = tamanho - 7

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2)
          pos = 9
  }

  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  if (resultado !== Number(digitos.charAt(1))) return 'invalido'
  return false
}

export const validarData = (e) =>{

  if(e.valor.length < 10)
    return 'invalid'

  const dmin = new Date(e.min);
  const dmax = new Date(e.max);
  const de = new Date(e.valor);
  if(de < dmin) return 'menor';
  if(de > dmax) return 'maior';

  return false;
}

export const validarNumber = (e) =>{
  if( Number(e.valor) < Number(e.min) ) return 'menor';
  if( Number(e.valor) > Number(e.max) ) return 'maior';
  return false;
}