import './DuplicatePatientModal.css'
import { AddAlert, AlertType, Modal } from '../../../../../component'
import { MODAL_INIT_PROPS } from '../../../../../newcomponents/modal/modal'
import { MergePatientModal } from '../MergePatientModal/MergePatientModal'
import ShowPatientData from '../subcomponents/ShowPatientData/ShowPatientData'
import { useEffect, useState } from "react"
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import api from '../../../../../helpers/api'

function formatBirthday(birthday){
    let [year, month, day] = birthday.split('-')
    return `${day}/${month}/${year}`
}

export default function DuplicatePatientModal({setModalInfo=() => null, patient= null, handleRemoveDuplicatePatient=() =>null}){
    const [basePatient, setBasePatient] = useState()
    const [duplicatePatients, setDuplicatePatients] = useState([])
    const [secondaryModalInfo, setSecondaryModalInfo] = useState()
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(()=>{
        setBasePatient(patient)
        setDuplicatePatients(patient?.duplicate_records)
    }, [patient])

    const handleMergePatients = (duplicatePatient) => {
        setModalInfo({
            open: true,
            title: 'Mesclar Paciente',
            content: <MergePatientModal patient1={basePatient} patient2={duplicatePatient} setModalInfo={setModalInfo} />
        })
    }

    const handleIgnore = async (duplicatePatient) => {
        try{
            let res = await api.patch(`patient/ignore_duplicate/${duplicatePatient.id}/`)
            if (res.status === 200){
                dispatch(AddAlert('Aviso Ignorado', 'O aviso de registro possivelmente duplicado foi ignorado com sucesso.', AlertType.SUCCESS))
                setDuplicatePatients(prev => prev.filter(patient => patient.id !== duplicatePatient.id))
                handleRemoveDuplicatePatient(basePatient, duplicatePatient)
            }
        } catch (err){
            dispatch(AddAlert('Ocorreu um erro ao tentar ignorar o aviso. Tente novamente mais tarde.', AlertType.ERROR))
        }
    }

    return (
    <div className="Duplicate-Modal">
        <Modal {...secondaryModalInfo} dismissFn={() => setSecondaryModalInfo(MODAL_INIT_PROPS)} />

        <ShowPatientData patient={basePatient} title={'O paciente abaixo: '}/>

        <div>
            <p className='Duplicate-Modal-Table-Title'>Possui a(s) seguinte(s) duplicata(s)</p>
            <div className='Duplicate-Modal-Table-Container'>
                <table className='Duplicate-Modal-Table'>
                    <thead className='Duplicate-Modal-Table-Head'>
                        <tr className='Duplicate-Modal-Table-Row'>
                            <th>Nome</th>
                            <th>Prontuário</th>
                            <th>Nascimento</th>
                            <th>Documento</th>
                            <th>Nome da Mãe</th>
                            <th>Atendimentos</th>
                            <th>Arquivos</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            duplicatePatients.map(duplicatePatient => {
                                return <tr className='Duplicate-Modal-Table-Row'>
                                <td>{duplicatePatient?.name}</td>
                                <td>{duplicatePatient?.medical_record_number}</td>
                                <td>{formatBirthday(duplicatePatient?.birthday)}</td>
                                <td> {duplicatePatient?.cpf ? duplicatePatient.cpf : (duplicatePatient?.rg ? duplicatePatient.rg : '-')}</td>
                                <td>{duplicatePatient?.mother_name}</td>
                                <td>{duplicatePatient?.attendance_count}</td>
                                <td>{duplicatePatient?.document_count}</td>
                                <td class="Duplicate-Modal-Table-Actions">
                                    <button className="Duplicate-Modal-Button-1" onClick={() => window.open(`/pacientes/${duplicatePatient?.id}/detalhar`)}>Visualizar</button>
                                    <button className="Duplicate-Modal-Button-2" onClick={()=> handleMergePatients(duplicatePatient)}>Mesclar</button>
                                    <button className='Duplicate-Modal-Button-3' onClick={()=> handleIgnore(duplicatePatient)}>Ignorar</button>
                                </td>
                            </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>)
}