import './PersonalDataProfileImage.css'
import React, { useEffect, useRef, useState } from 'react'
import { useApp } from '../../../../../../../layout/App'
import { useConfiguration } from '../../../../../configuration'
import PersonalDataProfileImageModal from './PersonalDataProfileImageModal/PersonalDataProfileImageModal'

export default function PersonalDataProfileImage() {
    const { currentHealthPlaceUser, currentUser, loged } = useApp()
    const fileInputRef = useRef(null);
    const { setModalProps } = useConfiguration()
    const defaultUser = require('../../../../../../../media/DefaultUser.png')

    const handleEditButtonClick = () => {
        fileInputRef.current.click()
    }

    const openProfileImageModal = (event) => {
        event.preventDefault();
        let files;
        if (event.dataTransfer) {
            files = event.dataTransfer.files;
        } else if (event.target) {
            files = event.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setModalProps({
                open: true,
                title: 'Editar Foto',
                content: <PersonalDataProfileImageModal 
                    initHoldingUserData={{ id: currentHealthPlaceUser?.user?.person?.id, base64_avatar: reader.result }} 
                    setModalInfo={setModalProps}
                    fetchExtraAction={loged}
                />,
                dismissText: '',
            });
        };
        reader.readAsDataURL(files[0]);
    }

  return (
    <div className='PersonalDataProfileImage'>
        <div className='PersonalDataProfileImage-Header'>
            <div className='PersonalDataProfileImage-Header-Icon'>F</div>
            <div className='PersonalDataProfileImage-Header-Title'>Foto</div>
        </div>
        <input 
            type="file" 
            className="PersonalDataProfileImage-HideChooseFile" 
            name="file_photo"
            ref={fileInputRef}
            onChange={openProfileImageModal}
            style={{ display: 'none' }}
        />
        <div className='PersonalDataProfileImage-Container-Avatar'>
            <div className='img'>
                <img
                    onClick={handleEditButtonClick}
                    className='PersonalDataProfileImage-Container-Avatar-Img'
                    alt='Foto de perfil'
                    src={currentUser?.person?.avatar_img_url || defaultUser}
                />
            </div>
        </div>
        <div className='PersonalDataProfileImage-ActionRow'>
            <div 
                className='PersonalDataProfileImage-ActionRow-Btn' 
                onClick={handleEditButtonClick}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
  )
}