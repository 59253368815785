import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Portal from '../potal/portal'
import './alert.css'

import { IcoClose } from '../icon/icon'
import { AlertType, RemoveAlert } from './actions'
import CheckIcon from '../icon/check'
import InfoIcon from '../icon/info'
import WarningIcon from '../icon/warning'
export * from './actions'

const AlertIcon = ({ type }) => {
  switch (type) {
    case AlertType.SUCCESS:
      return <CheckIcon width={40} height={40} color='white' />
    case AlertType.ERROR:
      return <IcoClose size={40} color='white' />
    case AlertType.WARNING:
      return <WarningIcon width={40} height={40} color='white' />
    default:
      return <InfoIcon width={40} height={40} color='white' />
  }
}

const getAlertIconBackground = (type) => {
  switch (type) {
    case AlertType.SUCCESS:
      return '#14A44D'
    case AlertType.ERROR:
      return '#F95C5C'
    case AlertType.WARNING:
      return '#f8b24a'
    default:
      return '#005daa'
  }
}

export function Alert() {
  const dispatch = useDispatch()
  const alerts = useSelector(state => state.alerts)
  return (
    <Portal name='alert'>
      {alerts.map(alert => {
        setTimeout(() => dispatch(RemoveAlert(alert.id)), alert.time || 3000)

        return (
          <div key={alert.id} className='box-alert' onClick={() => dispatch(RemoveAlert(alert.id))}>
            <div className='box-alert-content'>
              <div className='BoxAlertIcon' style={{ backgroundColor: getAlertIconBackground(alert.type) }}>
                <AlertIcon type={alert.type}/>
              </div>
              <div>
                <h6>{alert.title}</h6>
                <p>{alert.message}</p>
              </div>
            </div>
          </div>
        )
      })}
    </Portal>
  )
}
