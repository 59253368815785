import api from "../../../../helpers/api";

export function patchAvailableEvents(pk=null, payload={}) {
    let response = api.patch(`schedule/events/set_available/?pk=${pk}`, payload)
    return response
}

export function patchUnvailableEvents(pk=null, payload={}) {
    let response = api.patch(`schedule/events/set_unavailable/?pk=${pk}`, payload)
    return response
}

export function createEvents(pk=null, payload={}) {
    let response = api.post(`schedule/events/create/?pk=${pk}`, payload)
    return response
}

export function deleteEvents(pk=null, payload={}) {
    let response = api.delete(`schedule/events/delete/?pk=${pk}`, payload)
    return response
}