import './RemoveStudent.css'
import React from "react";
import { useDispatch } from 'react-redux';
import { MODAL_INIT_PROPS } from "../../../../../../../newcomponents/modal/modal";
import Button, { ButtonModel } from '../../../../../../../newcomponents/button/button';
import { AddAlert, AlertType } from '../../../../../../../component';
import { apiV2 } from '../../../../../../../helpers';

export default function RemoveStudent({ associatedStudentEdge, setModalInfo= () => null, fetchAssociatedStudents= () => null }) {
    const dispatch = useDispatch()

    const handleRemoveAssociatedEdge = async (associatedStudentEdge) => {
        apiV2.delete(`preceptorship/associated/student/${associatedStudentEdge?.id}`)
            .then(() => {
                fetchAssociatedStudents()
                setModalInfo(MODAL_INIT_PROPS)
            })
            .catch(err => {
                dispatch(AddAlert('Remover aluno', 'Falha ao remover o aluno da turma, tente novamente', AlertType.ERROR))
                console.log('RemoveStudent ~ handleRemoveAssociatedEdge ', err)
            })
    }

    return <div>
        <p>Tem certeza que deseja remover o aluno <b>{associatedStudentEdge?.student_health_place_user.user?.person?.name || associatedStudentEdge?.student_health_place_user?.user?.email}</b> da sua turma? </p>

        <div className="RemoveStudent-BtnBox">
            <Button 
                label='Fechar'
                model={ButtonModel.SECONDARY}
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
            />
            <Button 
                label='Remover'
                model={ButtonModel.DANGER}
                onClick={() => handleRemoveAssociatedEdge(associatedStudentEdge)}
            />
        </div>
    </div>
}