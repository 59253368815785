import './HealthPlaceInventoryAdminModal.css'
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import NewButton from '../../../../../../../../newcomponents/button/button';
import { useApp } from '../../../../../../../../layout/App';
import { patchInventoryConfig, postInventoryConfig } from '../../../http';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { AddAlert, AlertType, Input } from '../../../../../../../../component';
import NewMultiSelect from '../../../../../../../../newcomponents/form/select/multiselect';
import { stringifyCollaboratorOption } from '../../../../../../../../utils/stringifyCollaboratorOption';
import { getCollaborators } from '../../../../../../../collaborators/http';


export default function HealthPlaceInventoryAdminModal({ initAccessConfiguration={}, extraAction=async () => null }) {
    const dispatch = useDispatch()
    const searchCollaboratorsTimeout = useRef(null)
    const [holdingInventoryAdmins, setHoldingInventoryAdmins] = useState(initAccessConfiguration?.admins || [])
    const [collaboratorOptions, setCollaboratorOptions] = useState([])
    const { currentHealthPlaceUser } = useApp()

    console.log('initAccessConfiguration ~ ', initAccessConfiguration)
    //const [holdingAccessConfig, setHoldingAccessConfig] = useState(initAccessConfiguration)

    useEffect(() => {
        fetchCollaborators()
    }, [])

    const handleSearchCollaborators = (event) => {
        if (searchCollaboratorsTimeout.current) clearTimeout(searchCollaboratorsTimeout.current)
    
        searchCollaboratorsTimeout.current = setTimeout(() => {
            fetchCollaborators({ 'name_cpf_or_email': event.target.value })
        }, 400, event.target.value)
    }

    const fetchCollaborators = async (params={}) => {
		await getCollaborators({
			...params,
			health_place__id: currentHealthPlaceUser?.health_place?.id,
			limit: 500,
			has_person: true
		})
			.then(res => setCollaboratorOptions(res.data.results.map((instance) => stringifyCollaboratorOption(instance))))
			.catch(err => {
				console.error('HealthPlaceInventoryAdmin ~ fetchCollaborators ~ ', err)
				setCollaboratorOptions([])
			})
	}

    const toggleOption = ({ optionRef }) => {
        let isExisting = holdingInventoryAdmins.find(profile => profile === optionRef)
        let paramHoldingInventory = holdingInventoryAdmins

        if (isExisting) {
            paramHoldingInventory = paramHoldingInventory.filter(profile => profile !== optionRef)
        } else {
            paramHoldingInventory = [...paramHoldingInventory, optionRef]
        }
        setHoldingInventoryAdmins(paramHoldingInventory)
        fetchCollaborators();
    };

    const handleSubmit = async () => {

        dispatch(loading())
        try {
            const payload = {
                "admins": holdingInventoryAdmins || [],
                "health_place": currentHealthPlaceUser?.health_place?.id,
            }
            if (!initAccessConfiguration?.health_place) {
                await postInventoryConfig(payload)
            } else {
                await patchInventoryConfig(initAccessConfiguration?.health_place, payload)
            }
            dispatch(AddAlert('Configuração de Estoque', 'Permissões de Acesso salvas com sucesso', AlertType.SUCCESS))
            await extraAction()
        } catch (err) {
            console.error('HealthPlaceInventoryAdminModal ~ handleSubmit ~ ', err)
        }
        dispatch(loaded())
    }

    return <div className='HealthPlaceInventoryAdminModal'>
        <div className='HealthPlaceInventoryAdminModal-Header'>
            <div className='HealthPlaceInventoryAdminModal-Header-Icon'>A</div>
            <div className='HealthPlaceInventoryAdminModal-Header-Title'>Administradores</div>
        </div>
        <div>
            <NewMultiSelect
				label=''
				filterNode={<div className='ManageTeamEdgesModal-NewSelect'>
					<Input 
						placeholder='Pesquisar por e-mail, nome ou cpf'
						action={handleSearchCollaborators}
                        actionFocus={()=> fetchCollaborators()}
					/>
				</div>}
				defaultText={holdingInventoryAdmins.length ? `${holdingInventoryAdmins.length} selecionados` : 'Selecione'}
				onlyDefaultText
				onSelect={toggleOption}
				selectedOptions={holdingInventoryAdmins || []}
				optionRefKey='health_place_user__id'
				optionStrKey='str'
				options={collaboratorOptions}
			/>
        </div>
        <div className='HealthPlaceInventoryAdminModal-BtnBox'>
            <NewButton 
                label='Salvar'
                onClick={handleSubmit}
            />
        </div>
    </div>
}