import api from "../../helpers/api"
import { getParams } from "../../utils"

export async function getAttendancesHistory(params={}) {
    params = getParams(params)

    let response = await api.get(`appointment/history/${params}`)

    return response
}

export async function postNewImmediateAppointment(payload={}) {
    const url = '/appointment/new/immediate'

    return api.post(url, payload)
}

export async function postQuickEmission(payload = {}) {
    const url = '/reports/attendances/quick_emission/';

    const response = await api.post(url, payload);

    return response;
}



export async function patchAttendance(appointmentID, payload={}) {
	const url = `appointment/history/${appointmentID}/`

	const response = await api.patch(url, payload)
	return response.data
}

export async function getReportsRoutine(params={}) {
    params = getParams(params)
    const url = `/reports/routine/${params}`
    const response = await api.get(url)

    return response
}

export async function postReportsRoutine(payload={}) {
    const url = '/reports/routine/'
    const response = await api.post(url, payload)

    return response
}


export async function patchReportsRoutine(reportID, payload={}) {
    const url = `/reports/routine/${reportID}/`
    const response = await api.patch(url, payload)

    return response
}

export async function deleteReportsRoutine(reportID) {
    const url = `reports/routine/${reportID}/`
    const response = await api.delete(url)

    return response
}

export async function getTicketsHistory(params={}) {
    params = getParams({ ...params })
    const url = `appointment/tickets/${params}`
    const response = await api.get(url)

    return response
}

export async function createCallPanelServiceTicket(payload={}) {
    const url = `appointment/tickets/create_ticket/`
    const response = await api.post(url, payload)

    return response
}

export async function patchRecallPanelServiceTicket(id, payload={}) {
    const url = `appointment/tickets/${id}/recall/`
    const response = await api.patch(url, payload)

    return response
}

export async function postCancelPanelServiceTicket(id) {
    const url = `appointment/tickets/${id}/cancel/`
    const response = await api.post(url)

    return response
}


export async function patchCallPanelServiceTicket(id, payload={}) {
    const url = `appointment/tickets/${id}/`
    const response = await api.patch(url, payload)

    return response
}
