import React from 'react'

export function IcoEditCalendar({ style={} }) {
  return (
    <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M1055 4946 c-37 -17 -70 -52 -84 -89 -6 -16 -11 -89 -11 -163 l0
            -134 -188 0 c-136 0 -204 -4 -247 -15 -172 -45 -305 -179 -350 -352 -23 -86
            -23 -2860 0 -2946 45 -173 178 -307 350 -352 51 -13 173 -15 856 -15 l796 0 6
            48 c9 73 33 142 72 206 19 32 35 60 35 62 0 2 -384 5 -854 6 -845 3 -855 3
            -882 24 -15 11 -37 33 -48 48 -21 27 -21 35 -24 1057 l-2 1029 1840 0 1840 0
            0 -289 0 -289 38 9 c70 16 178 9 265 -17 16 -5 17 32 17 678 0 742 0 739 -56
            850 -36 71 -123 159 -193 197 -97 51 -154 61 -362 61 l-188 0 -3 149 c-3 133
            -5 153 -24 177 -39 53 -71 69 -134 69 -63 0 -95 -16 -134 -69 -19 -24 -21 -44
            -24 -177 l-3 -149 -1039 0 -1039 0 -3 149 c-3 162 -8 178 -70 224 -34 25 -113
            32 -153 13z"/>
            <path d="M4192 2541 c-30 -10 -68 -29 -85 -42 -37 -27 -1495 -1326 -1575
            -1403 -103 -98 -120 -165 -124 -486 -3 -181 -2 -186 23 -240 32 -67 87 -123
            153 -157 59 -30 173 -43 415 -46 163 -2 167 -2 221 25 37 19 157 119 366 305
            170 153 533 477 805 720 272 242 504 456 516 475 59 91 67 209 21 310 -28 61
            -382 454 -450 501 -85 57 -191 71 -286 38z"/>
        </g>

    </svg>
  )
}

