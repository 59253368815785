import { useEffect, useState } from "react";

export const useInternetSpeed = () => {
    const [speed, setSpeed] = useState(null);
    
    useEffect(() => {
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      
      if (connection) {
        setSpeed(connection.downlink);
      }
    }, []);
    
    return speed;
};