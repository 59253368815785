import api from "../../../../../../../../helpers/api";
import getParams from "../../../../../../../../utils/getParams";

export async function getInventoryMaterialLocations(params={}) {
    params = getParams({ ...params, limit: 200 })
    const url = `/inventory/location/${params}`
    let response = api.get(url)

    return response
}

export async function postInventoryMaterialLocation(payload={}) {
    const url = '/inventory/location/'
    let response = api.post(url, payload)
    
    return response
}

export async function patchInventoryMaterialLocation(id, payload={}) {
    const url = `/inventory/location/${id}/`
    let response = api.patch(url, payload)

    return response
}

export async function deleteInventoryMaterialLocation(id) {
    const url = `inventory/location/${id}/`
    let response = api.delete(url)

    return response
}