import React from "react"
import NewButton, { ButtonModel } from "../../../../../../newcomponents/button/button"
import img from './image.png'

export default function AllowPopUpRedictModal({ onClick=() => null, download_url='' }) {
    return <div style={{ width: '400px' }}>
        <img style={{ width: '400px', marginBottom: '1rem', cursor: 'pointer' }} src={img} alt='Imagem Instrução' />
        <p>Por favor, permita pop-ups e redirecionamento para o site nas configurações do navegador.</p>
        <p style={{ marginTop: '1rem', color: '#aaa', fontSize: '12px', fontWeight: '500', cursor: 'pointer' }}>Tentando acessar {download_url}</p>
        <div style={{ marginTop: '3rem', display: 'flex', justifyContent: 'end' }}>
            <NewButton 
                label='Imprimir Atendimentos'
                onClick={onClick}
                model={ButtonModel.SECONDARY}
            />
        </div>
    </div>
}