import './PatientExistentHandler.css'
import React, { useState } from "react";
import { AddAlert, AlertType, RadioButton } from "../../../../../component";
import NewButton, { ButtonModel } from '../../../../../newcomponents/button/button';
import api from '../../../../../helpers/api';
import { useDispatch } from 'react-redux';
import { loaded, loading } from '../../../../../layout/redux/AppActions';
import { MODAL_INIT_PROPS } from '../../../../../newcomponents/modal/modal';

export default function PatientExistentHandler({ patientExistentQuestion={}, documentType='', document='', setSecondaryModalInfo=() => null }) {
    const dispatch = useDispatch()
    const [randomBirthdays, setRandomBirthdays] = useState(patientExistentQuestion?.random_birthdays_with_shuffled || [])
    const [selectedBirthday, setSelectedBirthday] = useState({})
    
    const handleSubmit = async () => {
        let payload = { "document": document, "birthday": selectedBirthday.birthday, "document_type": documentType }

        if (!payload?.document) return dispatch(AddAlert('Paciente já existente', 'Documento não achado!', AlertType.ERROR))
        if (!payload?.birthday) return dispatch(AddAlert('Paciente já existente', 'Data de nascimento não selecionada!', AlertType.ERROR))
        if (!payload?.document_type) return dispatch(AddAlert('Paciente já existente', 'Tipo de documento não achado!', AlertType.ERROR))

        dispatch(loading())
        try {
            let res = await api.post('patient/associate_patient/', payload)
            setSecondaryModalInfo(MODAL_INIT_PROPS)
            window.open(`/pacientes/${res?.data?.patient_id}/detalhar`)
            dispatch(AddAlert('Associação de Paciente', 'Paciente associado com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            let error = err?.response?.data?.non_field_errors?.[0]
            if (error === 'No person found with the provided birthday and document.') {
                dispatch(AddAlert('Associação de Paciente', 'Nenhum paciente encontrado com esse documento e data de nascimento.', AlertType.ERROR))
            } else {
                dispatch(AddAlert('Associação de Paciente', 'Falha ao associar paciente.', AlertType.ERROR))
            }
            console.error('PatientExistentHandler ~ handleSubmit ~ ', err)
        }
        dispatch(loaded())
    }

    return <div className="PatientExistentHandler">
        <p>Há no sistema um registro para <b>{patientExistentQuestion?.patient_name || '-'}</b> já cadastrado com esse <b>{documentType} {document}</b>. </p>
        <p>Selecione a data de nascimento que corresponde a esse paciente para associá-lo à sua base de pacientes ou retorne para ajustar.</p>
        <div className='PatientExistentHandler-RadioManager'>
            <RadioButton
                optionValue='birthday'
				optionLabel='birthday_str'
				action={(event) => setSelectedBirthday(event.target.value)}
				checked={selectedBirthday}
				options={randomBirthdays}
			/>
        </div>
        <div className='PatientExistentHandler-BtnBox'>
            <NewButton 
                label='Retornar para ajustar'
                onClick={() => setSecondaryModalInfo(MODAL_INIT_PROPS)}
                model={ButtonModel.SECONDARY}
            />
            <NewButton 
                label='Associar paciente'
                model={ButtonModel.PRIMARY}
                onClick={handleSubmit}
            />
        </div>
    </div>
}