import './DeleteCallPanelServiceOptionModal.css'
import React from "react";
import { useDispatch } from 'react-redux';
import { AddAlert, AlertType } from '../../../../../../../../component';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button';
import { deleteAppointmentCallPanelService } from '../../../http';

export default function DeleteCallPanelServiceOptionModal({ callPanelService={}, setSecondaryModal=() => null, extraAction= async () => null }) {
    const dispatch = useDispatch()

    const handleDelete = async () => {
        if (callPanelService?.id) {
            try {
                dispatch(loading())
                await deleteAppointmentCallPanelService(callPanelService?.id)
                await extraAction()
                setSecondaryModal(MODAL_INIT_PROPS)
                dispatch([AddAlert('Deleção de Painel', 'Item deletado com sucesso.', AlertType.SUCCESS), loaded()])
            } catch (err) {
                console.error('DeleteCallPanelServiceOptionModal ~ handleDelete ~ deleteAppointmentCallPanelService ~ ', err)
            }
        }
    }

    return <div className='DeleteCallPanelServiceOptionModal'>
        <p>Deseja realmente excluir este painel?</p>
        <div className='DeleteCallPanelServiceOptionModal-BtnBox'>
            <NewButton
                label='Não, cancelar'
                onClick={() => setSecondaryModal(MODAL_INIT_PROPS)}
                model={ButtonModel.SECONDARY}
            />
            <NewButton 
                label='Sim, excluir'
                onClick={handleDelete}
                model={ButtonModel.DANGER}
            />
        </div>
    </div>
}