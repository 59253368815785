import './TreponemalTestView.css'
import React from 'react'
import { Button, IcoBin, IcoPencil, List } from '../../../../../../../../../../../../../../../component'
import { getTreponemalResult } from '../utils'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import AlertRowText from '../../../../../../../../../../../../../../../component/alertRowText/alertRowText'
import { convertDate } from '../../../../../../../../../../../../../../../utils/convertDate'
import TreponemalTestWrite from '../Write/TreponemalTestWrite'
import api from '../../../../../../../../../../../../../../../helpers/api'

export default function TreponemalTestView() {
	const { 
		selectedLanguage,
		holdingProject: { treponemal_test_exams }, 
		setModalInfo,
		fetchThousandDaysProjects
	} = useThousandDays()

	const {
		Treponemaltest,
		Reagent,
		Notreagent,
		Notevaluated,
		Indeterminate,
		Dates,
		Conclusion,
		Notconfirmedsyphillis,
		NotDidTrepomic,
		Notinformation,
		Greaterthan,
		NOT_EVALUATED,
	} = require('../../../../../../../../../../../../../../data/translation.json')[selectedLanguage]

	const headDefaultTreponemalTest = [
		{ colunm: 'h_treponemal_conclusion', text: Conclusion },
		{ colunm: 'h_date', text: Dates, width: '100px' },
		{ colunm: 'h_action', text: '', width: '100px' },
	]

	const translate = {
		REAGENT: Reagent,
		NON_REAGENT: Notreagent,
		INDETERMINATE: Indeterminate,
		NOT_EVALUATED: Notevaluated,
		ONE_BY_ONE: '1 / 1',
		ONE_BY_TWO: '1 / 2',
		ONE_BY_FOUR: '1 / 4',
		ONE_BY_EIGHT: '1 / 8',
		ONE_BY_SIXTEEN: '1 / 16',
		ONE_BY_THIRTY_TWO: '1 / 32',
		ONE_BY_SIXTY_FOUR: '1 / 64',
		GREATER_ONE_BY_SIXTY_FOUR: `${Greaterthan} 1 / 64`,
	}

	const handleDelete = async (id) => {
		await api.delete(`exam/treponemal_test/${id}/`)
		fetchThousandDaysProjects()
	}

	const openExamWriteModal = (holdingExam) => {
		setModalInfo(prev => ({
			...prev,
			open: true,
			title: Treponemaltest,
			content: (
				<div className='SOAP-ThousandDaysExamSubSection-Modal'>
					<TreponemalTestWrite
						holdingTreponemalTest={holdingExam} 
						setModalInfo={setModalInfo} 
					/>
				</div>
			),
		}))
	}

	return (
		<div className='TreponemalTestView'>
			<div>
				<List
					head={headDefaultTreponemalTest}
					data={treponemal_test_exams}
					noData={Notinformation}
					listCustom={(exam, i) => {
						exam['h_treponemal_conclusion'] = <AlertRowText {...getTreponemalResult(exam, Notconfirmedsyphillis, NotDidTrepomic, NOT_EVALUATED)} />
						exam['h_date'] = exam?.date ? convertDate(exam.date, '-', '/') : '-'
						exam['h_action'] = (
							<div>
								<Button
									color='secondary'
									type='btn circle'
									action={() => openExamWriteModal(exam)}
									title='Editar exame'>
									<IcoPencil />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									action={() => handleDelete(exam?.id)}
									title='Excluir exame'>
									<IcoBin />
								</Button>
							</div>
						)
						return exam
					}}
				/>
			</div>
		</div>
	)
}
