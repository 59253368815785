import { tree } from 'd3';
import { isValidCPF } from '../../../../validators'
import isOfLegalAge from '../../../../validators/isOfLegalAge'
import isValidDateExcludingFutureDates from '../../../../validators/isValidDateExcludingFutureDates'
import isValidRG from '../../../../validators/isValidRG'

export function strpPerson(person={}) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    let [birthday_year, birthday_month, birthday_day] = regex.test(person?.birthday)
        ? person.birthday.split('-').map(value => Number(value))
        : ['', '', '']
    let [passport_naturalization_year, passport_naturalization_month, passport_naturalization_day] = regex.test(person?.passport_naturalization_date)
        ? person.passport_naturalization_date.split('-').map(value => Number(value))
        : ['', '', '']
    let [passport_issuance_year, passport_issuance_month, passport_issuance_day] = regex.test(person?.passport_issuance_date)
        ? person.passport_issuance_date.split('-').map(value => Number(value))
        : ['', '', '']
    let [passport_expiration_year, passport_expiration_month, passport_expiration_day] = regex.test(person?.passport_expiration_date)
        ? person.passport_expiration_date.split('-').map(value => Number(value))
        : ['', '', '']
    let [year_of_death, month_of_death, day_of_death] = regex.test(person?.date_of_death)
        ? person.date_of_death.split('-').map(value=>Number(value))
        : ['','','']

    return {
        "id": person?.id || null,
        "is_it_the_same_social_name": person?.is_it_the_same_social_name || "YES",
        "name": person?.name || '',
        "social_name": person?.social_name || '',
        "document": (person?.document_type === 'RG' ? person?.rg : person?.cpf) || '',
        "document_type": person?.document_type || 'CPF',
        "mother_name": person?.mother_name || '',
		"birthday_day": person?.birthday ? `${birthday_day < 10 ? '0': ''}${birthday_day}` : '',
		"birthday_month": person?.birthday ? `${birthday_month < 10 ? '0': ''}${birthday_month}` : '',
		"birthday_year": person?.birthday ? `${birthday_year}` : '',
        "contact_phone": person?.contact_phone || '',
        "phone_type": person?.phone_type || "CELL_PHONE",
        "email": person?.user?.email || '',
        "nationality": person?.nationality || '',
        "gender": person?.gender || '',
        "sus_card_number": person?.sus_card_number || '',
        "sex": person?.sex || '',
        "race": person?.race || '',
        "marital_status": person?.marital_status || '',
        "education": person?.education || '',
        "career": person?.career || null,
        
        "passport_naturalization_day": person?.passport_naturalization_date ? `${passport_naturalization_day < 10 ? '0': ''}${passport_naturalization_day}` : '',
		"passport_naturalization_month": person?.passport_naturalization_date ? `${passport_naturalization_month < 10 ? '0': ''}${passport_naturalization_month}` : '',
		"passport_naturalization_year": person?.passport_naturalization_date ? `${passport_naturalization_year}` : '',
        "passport_country_of_birth": person?.passport_country_of_birth || '',
        "passport_number": person?.passport_number || '',
        "passport_issuing_country": person?.passport_issuing_country || '',
        "passport_issuance_day": person?.passport_issuance_date ? `${passport_issuance_day < 10 ? '0': ''}${passport_issuance_day}` : '',
		"passport_issuance_month": person?.passport_issuance_date ? `${passport_issuance_month  < 10 ? '0': ''}${passport_issuance_month}` : '',
		"passport_issuance_year": person?.passport_issuance_date ? `${passport_issuance_year}` : '',
        "passport_expiration_day": person?.passport_expiration_date ? `${passport_expiration_day < 10 ? '0': ''}${passport_expiration_day}` : '',
		"passport_expiration_month": person?.passport_expiration_date ? `${passport_expiration_month < 10 ? '0': ''}${passport_expiration_month}` : '',
		"passport_expiration_year": person?.passport_expiration_date ? `${passport_expiration_year}` : '',

        "person_responsible_name": person?.person_responsible_name || '',
        "person_responsible_kinship": person?.person_responsible_kinship || '',
        "person_responsible_contact_phone": person?.person_responsible_contact_phone || '',
        "person_responsible_cpf": person?.person_responsible_cpf || '',
        "day_of_death": person?.date_of_death ? `${day_of_death < 10 ? '0': ''}${day_of_death}` : '',
        "month_of_death": person?.date_of_death ? `${month_of_death < 10 ? '0': ''}${month_of_death}` : '',
        "year_of_death": person?.date_of_death ? `${year_of_death}` : '',
        "death_reason": person?.death_reason || '',
        "death_cid": person?.death_cid || '',
        "death_declaration_number": person?.death_declaration_number || ''
    }
}

export function validateDataBeforeSubmit(data, errors){
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

    const setError = (field, condition, message, submit=false) => {
        if (submit) {
            if(errors[field]?.submit){
                if (condition){
                    errors[field] = { error: true, message, submit: true }
                } else {
                    delete errors[field]
                }
            }
        } else {
            if (condition) {
                errors[field] = { error: true, message }
            } else {
                delete errors[field]
            }
        }
    }

    const clearError = (field) => {
        if (errors[field]){
            delete errors[field]
        } else {
            return
        }
    }

    const birthdayDate = `${data?.birthday_year}-${data?.birthday_month}-${data?.birthday_day}`
    const isDateComplete = data.birthday_day && data.birthday_month && data.birthday_year
    const cleanedContactPhone = data.contact_phone.replace(/\D/g, '')

    setError('name', !data.name, '', true);
    setError('birthday_day', !data.birthday_day, '', true);
    setError('birthday_month', !data.birthday_month, '', true);
    setError('birthday_year', !data.birthday_year, '', true);
    setError('nationality', !data.nationality, '', true);
    setError('sex', !data.sex, '', true);
    setError('email', data.email && !emailRegex.test(data.email), 'Email inválido.');
    setError('contact_phone', cleanedContactPhone && !([10, 11].includes(cleanedContactPhone.length)), 'Telefone inválido.')

    if (isDateComplete){
        setError('birthday_day', !isValidDateExcludingFutureDates(birthdayDate), 'Data de nascimento inválida.');
        setError('birthday_month', !isValidDateExcludingFutureDates(birthdayDate), 'Data de nascimento inválida.');
        setError('birthday_year', !isValidDateExcludingFutureDates(birthdayDate), 'Data de nascimento inválida.');
    }
    
    if (data.document) {
        if (data.document_type ==="CPF") {
            setError('document', !isValidCPF(data.document), 'CPF inválido.');
        } else if (data.document_type ==="RG") {
            setError('document',!isValidRG(data.document), 'RG inválido.');
        }
    }

    if (data.person_responsible_cpf){
        setError('person_responsible_cpf', !isValidCPF(data.person_responsible_cpf), 'CPF inválido.');
    }
    
    if (isDateComplete){
        if (isOfLegalAge(birthdayDate)){
            setError('document', !data.document, '', true)
            clearError('person_responsible_cpf')
        } else {
            setError('person_responsible_cpf', !data.person_responsible_cpf, '',true);
            setError('person_responsible_name', !data.person_responsible_name, '', true);
            clearError('document')
        }
    }
    


    return errors
}

export class PatientRequest {
    #errors

    constructor(data) {
        Object.assign(this, data)

        this.name = data?.name
        this.is_it_the_same_social_name = data?.is_it_the_same_social_name || 'YES'
        this.birthday = data?.birthday_year && data?.birthday_month && data?.birthday_day
            ? `${data?.birthday_year}-${data?.birthday_month}-${data?.birthday_day}`
            : ""
        this.document = data?.document_type === 'RG' ? data?.document?.replace(/[.\-]/g, '') : data?.document?.replace(/\D/g, '')
        this.document_type = data?.document_type
        this.social_name = data?.social_name || ''
        this.sus_card_number = data?.sus_card_number?.replace(/\D/g, '')
        this.mother_name = data?.mother_name || ''
        this.email = data?.email
        this.contact_phone = data?.contact_phone?.replace(/\D/g, '')
        this.phone_type = data?.phone_type || 'CELL_PHONE'
        this.nationality = data?.nationality || ''
        this.sex = data?.sex || ''
        this.race = data?.race || ''
        this.marital_status = data?.marital_status || ''
        this.gender = data?.gender || ''
        this.education = data?.education || ''
        this.career = data?.career?.id
    
        this.passport_naturalization_date = data?.passport_naturalization_day && data?.passport_naturalization_month && data?.passport_naturalization_year
            ? `${data?.passport_naturalization_year}-${data?.passport_naturalization_month}-${data?.passport_naturalization_day}`
            : null
        this.passport_country_of_birth = data?.passport_country_of_birth || ''
        this.passport_number = data?.passport_number || ''
        this.passport_issuing_country = data?.passport_issuing_country || ''

        this.passport_issuance_date = data?.passport_issuance_day && data?.passport_issuance_month && data?.passport_issuance_year
            ? `${data?.passport_issuance_year}-${data?.passport_issuance_month}-${data?.passport_issuance_day}`
            : null
    
        this.passport_expiration_date = data?.passport_expiration_day && data?.passport_expiration_month && data?.passport_expiration_year
            ? `${data?.passport_expiration_year}-${data?.passport_expiration_month}-${data?.passport_expiration_day}`
            : null

        this.person_responsible_name = data?.person_responsible_name || ''
        this.person_responsible_kinship = data?.person_responsible_kinship || ''
        this.person_responsible_contact_phone = data?.person_responsible_contact_phone?.replace(/\D/g, '')
        this.person_responsible_cpf = data?.person_responsible_cpf?.replace(/\D/g, '')

        this.date_of_death = data?.day_of_death && data?.month_of_death && data?.year_of_death
            ? `${data.year_of_death}-${data.month_of_death}-${data.day_of_death}`
            : null
        this.death_reason = data?.death_reason || ''
        this.death_cid = data?.death_cid || ''
        this.death_declaration_number = data?.death_declaration_number || ''

        this.created_by_health_professional = true 
        
        this.#errors = {}
    }

    isValid() {
        let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g // eslint-disable-line

        Object.entries(this).map(([key, value]) => {
            if (key === 'email' && value && !emailRegex.test(value)) {
                this.#errors['email'] = { error: true, message: 'Email inválido', submit: true }
            } else if (key === 'contact_phone' && value && !([10, 11].includes(value.length))) {
                this.#errors['contact_phone'] = { error: true, message: 'Telefone inválido', submit: true }
            } else if (key === 'birthday' && !isValidDateExcludingFutureDates(value)) {
                this.#errors['birthday_day'] = { error: true, message: '', submit: true }
                this.#errors['birthday_month'] = { error: true, message: '', submit: true }
                this.#errors['birthday_year'] = { error: true, message: '', submit: true }
            } else if (key === 'document' && value) {
                if (this.document_type === 'CPF' && value && !isValidCPF(value)) {
                    this.#errors['document'] = { error: true, message: 'CPF inválido', submit: true }
                } else if (this.document_type === 'RG' && value && !isValidRG(value)) {
                    this.#errors['document'] = { error: true, message: 'RG Inválido', submit: true }
                }
            } else if (key === 'person_responsible_cpf' && value && !isValidCPF(value)) {
                this.#errors['person_responsible_cpf'] = { error: true, message: 'CPF inválido', submit: true }
            } else if (key === 'sex' && !value) {
                this.#errors['sex'] = { error: true, message: '', submit: true }
            } else if (key === 'name' && !value) {
                this.#errors['name'] = { error: true, message: '', submit: true }
            } else if (key === 'nationality' && !value) {
                this.#errors['nationality'] = { error: true, message: '', submit: true }
            } else if (key === 'passport_naturalization_date' && value && !isValidDateExcludingFutureDates(value)) {
                this.#errors['passport_naturalization_day'] = { error: true, message: '', submit: true }
                this.#errors['passport_naturalization_month'] = { error: true, message: '', submit: true }
                this.#errors['passport_naturalization_year'] = { error: true, message: '', submit: true }
            } else if (key === 'passport_issuance_date' && value &&!isValidDateExcludingFutureDates(value)) {
                this.#errors['passport_issuance_day'] = { error: true, message: '', submit: true }
                this.#errors['passport_issuance_month'] = { error: true, message: '' , submit: true }
                this.#errors['passport_issuance_year'] = { error: true, message: '', submit: true }
            }
        })

        if (!this.birthday && !this.document){
            this.#errors['document'] = { error: true, message: ''}
        }

        //CPF nao pode ser igual do responsável
        if (this.document_type === 'CPF' && this.document && this.person_responsible_cpf && this.document == this.person_responsible_cpf) {
            this.#errors['document'] = { error: true, message: 'CPF do paciente e do responsável não podem ser iguais.' }
            this.#errors['person_responsible_cpf'] = { error: true, message: 'CPF do paciente e do responsável não podem ser iguais.' }
        }

        //caso o paciente for menor de idade, um cpf do responsável é obrigatório
        if (this.birthday && !isOfLegalAge(this.birthday) && !this.person_responsible_cpf) {
            this.#errors['person_responsible_cpf'] = { error: true, message: '' }
        }
        
        if (this.birthday && !isOfLegalAge(this.birthday) && !this.person_responsible_name){
            this.#errors['person_responsible_name'] = {error: true,message: '', submit: true}
        }

        //caso o paciente seja maior de idade, o CPF é obrigatório
        if (!this.document && this.birthday && isOfLegalAge(this.birthday)) {
            this.#errors['document'] = { error: true, message: '' }            
        }

        return Object.keys(this.#errors).length === 0
    }

    getErrors() {
        return this.#errors
    }


    formatPayload(forcedSubmission) {
        if (this.document_type === 'CPF') {
            return { ...this, 'forced_submission': forcedSubmission, [this.document_type.toLowerCase()]: this.document, "rg": "" }
        } else {
            return { ...this, 'forced_submission': forcedSubmission, [this.document_type.toLowerCase()]: this.document, "cpf": "" }
        }
    }
}