import './DocumentsHistory.css'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NewCheckbox } from '../../../../../../../../../../../../newcomponents'
import Loading from '../../../../../../../../../../../../newcomponents/loading/loading'
import { formatDate, getFiltersetCategoryID } from './utils'
import { getActivities, patchActivity } from '../../../../../../../../../../http'
import { AddAlert, AlertType, Input, MaskDataHora, RadioButton, List, Paginate, IcoBin } from '../../../../../../../../../../../../component'
import { ContractIcon } from '../../../../../../../../../../../../component/icon/contract'
import { DownloadIcon } from '../../../../../../../../../../../../component/icon/download'
import SignDocumentsModal from './subcomponents/SignDocumentsModal/SignDocumentsModal'
import RemoveDocumentModal from './subcomponents/RemoveDocumentModal/RemoveDocumentModal'
import DownloadDocumentProgressBarModal from './subcomponents/DownloadDocumentProgressBarModal/DownloadDocumentProgressBarModal'
import { AttachIcon } from '../../../../../../../../../../../../component/icon/attach'
import NewTaskIcon from '../../../../../../../../../../../../component/icon/new_task'
import SaveDocumentSection from './subcomponents/NewDocumentModal/SaveDocumentSection/SaveDocumentSection'
import AttachDocumentSection from './subcomponents/NewDocumentModal/AttachDocumentSection/AttachDocumentSection'

const { headDefault, historicDates } = require('./data.json')
const { attachOptions } = require('../../DocumentsEmission.json')

export default function DocumentsHistory({ person=null, attendance=null, setModalInfo=() => null }) {    
    const dispatch = useDispatch()
    const searchTimeOut = useRef(null)
    const [activities, setActivities] = useState([])
    const [activitiesCheckbox, setActivitiesCheckbox] = useState([...attachOptions])
    const [dateTime, setDateTime] = useState({ id: 'all', name: 'Todos' })
    const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })
    const [localLoading, setLocalLoading] = useState(true)

    useEffect(() => {
        fetchActivities({ offset: 0 });
        const customEventHandler = () => {
            fetchActivities({ offset: 0 });
        };
        
        document.addEventListener("DocumentsHistoryFetchActivities", customEventHandler);
        
        return () => {
            document.removeEventListener("DocumentsHistoryFetchActivities", customEventHandler);
        };
    }, [attendance?.person?.id, person?.id, activitiesCheckbox, dateTime]);

    const fetchActivities = async (params={}) => {
        params = {
            ...params,
            "active": true,
            "person": person?.id || attendance?.person?.id,
            "date_time_after": dateTime?.id === 'all' ? '' : formatDate(dateTime?.id),
            "category__in": getFiltersetCategoryID(activitiesCheckbox),
            "limit": 10,
            "ordering": "-document_date"
        }
        setLocalLoading(true)
        try {
            let response = await getActivities(params)
            setActivities(response.data.results)
            setPagination({
                totalElements: response.data.count,
                totalPages: Math.ceil(response.data.count / 10),
                pageNumber: params.offset / 10
            })
        } catch (err) {
            console.error('fetchActivities', err)
        }
        setLocalLoading(false)
    }

    const handleSearch = async (query) => {
        try {
            searchTimeOut.current && clearTimeout(searchTimeOut.current)
            searchTimeOut.current = setTimeout(async (query) => {
                await fetchActivities({ offset: 0, title__icontains: query  })
            }, 300, query)
        } catch (err) {
            console.error('handleSearch', err)
        }
    }

    const handleNextPage = (e) => {
        fetchActivities({ offset: e * 10 })
    }

    const handleChange = (event, activity_id) => {
        let newData = [...activities]
        let idx = activities.findIndex(activity => activity?.id === activity_id)
        newData[idx].title = event.target.value
        setActivities(newData)
    }

    
    const handleTitle = async (event, activityId) => {
        try {
            await patchActivity(activityId, { title: event.target.value })
        } catch (err) {
            console.error('handleTitle', err)
            dispatch(AddAlert('Novo título', 'Falha ao salvar novo título', AlertType.ERROR))
        }
    }

    const openSignModal = (activity) => {
        setModalInfo({
            open: true,
            title: 'Assinar documentos',
            content: <SignDocumentsModal
                selectedDocument={activity}
                attendance={attendance}
                setModalInfo={setModalInfo} 
            />
        })
    }

    const openDownloadDocumentModal = (activity) => {
        setModalInfo({
            open: true,
            title: 'Download',
            content: <DownloadDocumentProgressBarModal
                activity={activity}
                setModalInfo={setModalInfo}
            />
        })
    }

    const openRemoveActivityModal = (activity) => {
        setModalInfo({ 
            open: true, 
            title: 'Remover documento', 
            content: <RemoveDocumentModal 
                activity={activity} 
                setModalInfo={setModalInfo} 
            />  
        })
    }

    return (
    <div className='DocumentsHistory-page'>
        <section className='DocumentsHistory-filters'>
            <Input
                name='DocumentsHistory-search-title'
                action={(event) => handleSearch(event.target.value)}
                autoComplete='off'
                placeholder='Buscar por título'
            />
            <RadioButton 
                label='Data de histórico'
                name='historicDate'
                action={e => setDateTime(e.target.value)}
                checked={dateTime}
                options={historicDates}
            />
            <NewCheckbox
                title='Tipo de documento'
                state={activitiesCheckbox} 
                setState={setActivitiesCheckbox}
                className='DocumentsHistory-NewCheckbox'
            />

        </section>
        <div className='DocumentsHistory-Separator' />
        <div className='DocumentsHistory-Body'>
            { localLoading 
                ? <div className='DocumentsHistory-Loading'><Loading /></div>  
                : (
                    <div className='DocumentsHistory-Body-Wrapper'>
                        {
                            /**
                        <div>
                            <div 
                                className='DocumentsHistory-Body-Wrapper-ActionRow-Btn'
                                onClick={() => setModalInfo(prev => ({ 
                                    ...prev,
                                    open: true,
                                    title: 'Novo documento',
                                    content: <NewDocumentModal
                                        person={person}
                                        attendance={attendance} 
                                        setModalInfo={setModalInfo} 
                                    />
                                }))}
                            >
                                <b>Novo documento</b>
                            </div>
                        </div> 
                
                             */
                        }
                        <div className='DocumentsHistory-Filters-ActionRow'>
                            {
                                attendance
                                ? <div
                                    onClick={() => setModalInfo(prev => ({ 
                                        ...prev,
                                        open: true,
                                        title: 'Novo documento',
                                        content: <SaveDocumentSection
                                            person={person}
                                            attendance={attendance}
                                            setModalInfo={setModalInfo}
                                        />
                                    }))}
                                    title="Novo documento"
                                >
                                    <NewTaskIcon /> 
                                </div>
                                : null
                            }
                            
                            <div
                                onClick={() => setModalInfo(prev => ({
                                    ...prev,
                                    open: true,
                                    title: 'Anexar documento',
                                    content: <AttachDocumentSection
                                        person={person}
                                        attendance={attendance} 
                                        setModalInfo={setModalInfo}
                                    />
                                }))}
                                title="Anexar documento"
                            >
                                <AttachIcon />
                            </div>
                        </div>
                        <div className='DocumentsHistory-Body-List'>
                            <List
                                head={headDefault}
                                data={activities}
                                listCustom={activity => {
                                    const custom = activity;
                                    const document_date = MaskDataHora(activity.document_date, 'DD/MMM/AAAA', '-');
                                    custom['titleFlag'] = <>
                                        <input
                                            id={`DocumentsHistory-${activity.id}`}
                                            name={`DocumentsHistory-${activity.id}`}
                                            className='DocumentsHistory-TitleInput'
                                            value={activity?.title}
                                            onChange={event => handleChange(event, activity.id)}
                                            onBlur={event => handleTitle(event, activity.id)}
                                        />
                                    </> 
                                    custom['created_byFlag'] = `${activity?.created_by === 'P' ? 'Enviado pelo paciente' : activity.health_place_user ? `Enviado por ${activity.health_place_user?.health_place?.name}` : ''}`
                                    custom['dateTimeCustom'] = `${document_date.data}`;
                                    custom['acoes'] = (
                                    <div className='DocumentsHistory-ActionRow'>
                                        <div
                                            onClick={() => openDownloadDocumentModal(activity)}
                                        >
                                            <DownloadIcon />
                                        </div>
                                        <div onClick={() => openRemoveActivityModal(activity)}>
                                            <IcoBin />
                                        </div>
                                        {
                                            attendance
                                            ?  <div 
                                                className={activity?.is_signed ? 'DocumentsHistory-ActionRow-IsSigned' : ''}
                                                onClick={() => !activity?.is_signed ? openSignModal(activity) : null}
                                            >
                                                <ContractIcon />
                                            </div>
                                            : null 
                                        }
                                    </div>
                                    )
                                    return custom
                                }}
                            />
                            <Paginate data={pagination} action={handleNextPage} />
                        </div>
                    </div>

            )}
        </div>
    </div>
    )
}
