import './PersonalDataCouncilManager.css'
import React from 'react'
import { useConfiguration } from '../../../../../configuration'
import PersonalDataCouncilManagerModal from './PersonalDataCouncilManagerModal/PersonalDataCouncilManagerModal'
import { useApp } from '../../../../../../../layout/App'


export default function PersonalDataCouncilManager() {
	const { councils } = useApp()
	const { setModalProps } = useConfiguration()

	return (
		<div>
			<div className='CouncilManager-Header'>
                <span className='CouncilManager-Header-Icon'>C</span>
                <p className='CouncilManager-Header-Title'>Conselho</p>
            </div>

			<div className='CouncilManager-TableBox'>
				<table className='CouncilManager-Table'>
					<thead className='CouncilManager-TableHead'>
						<tr className='CouncilManager-TableRow'>
							<td className='CouncilManager-TableCell'>Número</td>
							<td className='CouncilManager-TableCell'>UF</td>
						</tr>
					</thead>
					<tbody className='CouncilManager-TableBody'>
						{councils.map((council, index) => (
							<tr key={index} className='CouncilManager-TableRow'>
								<td className='CouncilManager-TableCell'>{council.nu_crm}</td>
								<td className='CouncilManager-TableCell'>{council.uf_crm}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>


			<div className='CouncilManager-BtnBox'>
				<div className='CouncilManager-Btn'
					onClick={() =>
						setModalProps(prev => ({
							...prev,
							open: true,
							title: 'Conselho profissional',
							content: <PersonalDataCouncilManagerModal councils={councils}/>,
							dismissText: '',
						}))
					}
					>
					<b>Editar dados</b>
				</div>
			</div>
		</div>
	)
}
