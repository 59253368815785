import React from 'react'

export default function IcoCleanFilter({ style={} }) {
    return <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M3411 5104 c-427 -72 -800 -394 -936 -809 -74 -228 -74 -488 1 -727
            102 -324 349 -599 660 -733 169 -73 390 -113 559 -100 843 62 1363 955 996
            1710 -61 124 -116 203 -212 302 -275 284 -685 421 -1068 357z m-11 -760 c14
            -9 68 -59 120 -110 l95 -94 95 96 c106 106 132 124 186 124 76 0 154 -75 154
            -148 0 -55 -20 -86 -122 -185 l-100 -98 105 -108 c110 -113 125 -140 112 -207
            -9 -48 -58 -101 -106 -114 -70 -19 -106 -1 -224 111 l-105 102 -100 -101 c-77
            -78 -108 -103 -139 -112 -95 -25 -191 47 -191 145 0 53 18 80 124 184 l95 95
            -104 107 c-57 59 -107 119 -111 133 -10 40 1 101 25 134 40 57 139 81 191 46z"/>
            <path d="M485 4261 c-140 -34 -216 -189 -152 -312 12 -24 315 -442 673 -929
            358 -487 657 -896 665 -910 12 -21 15 -185 19 -980 6 -1063 0 -991 75 -1063
            47 -46 121 -71 186 -64 57 7 85 20 164 80 33 24 159 117 280 207 236 176 319
            250 356 320 l24 45 5 710 c4 616 7 714 21 740 8 17 79 117 158 224 78 106 141
            194 139 195 -2 2 -34 16 -73 32 -430 180 -762 576 -872 1043 -33 143 -43 409
            -19 553 9 54 16 102 16 108 0 12 -1617 12 -1665 1z"/>
        </g>
    </svg>
}

