import React from 'react';
import { Chart, LinearScale, CategoryScale, LineController, PointElement, LineElement, Title, } from 'chart.js';
import { Line } from 'react-chartjs-2';
import './ShowDetailChartAnthropometry.css';

Chart.register(
  LinearScale,
  CategoryScale,
  LineController,
  PointElement,
  LineElement,
  Title
);

export default function ShowDetailChartAnthropometry({ chartConfig }) {
  const config = {
    data: {
      labels: chartConfig.labels,
      datasets: [
        {
          label: chartConfig.title,
          data: chartConfig.data,
          borderColor: chartConfig.color,
          backgroundColor: chartConfig.color.replace('1)', '0.1)'),
          fill: true,
          tension: 0.1,
          spanGaps: true,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: '',
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Data',
          },
        },
        y: {
          title: {
            display: true,
            text: chartConfig.yLabel,
          },
        },
      },
    },
  };

  return (
    <div className='ShowDetailChartAnthropometry-Container'>
      <div className='ShowDetailChartAnthropometry-Chart'>
        <Line data={config.data} options={config.options} />
      </div>
    </div>
  );
}
