import React from 'react'

export function NotesIcon({ style={}, className='icon-attendancehistory' }) {
  return (
    <svg style={style} className={className} viewBox="0 0 512.000000 512.000000">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M1345 5111 c-11 -5 -29 -19 -40 -31 -18 -20 -20 -39 -23 -206 l-3
            -184 -198 0 c-220 0 -273 -9 -375 -63 -125 -66 -237 -214 -265 -349 -8 -37
            -11 -615 -9 -1958 l3 -1905 22 -60 c64 -168 212 -299 384 -340 95 -22 3131
            -22 3227 0 196 46 360 216 401 413 8 37 11 604 11 1920 0 2065 4 1929 -65
            2061 -60 114 -166 207 -290 253 -54 20 -82 23 -277 26 l-218 3 0 165 c0 185
            -9 223 -59 248 -38 20 -64 20 -102 0 -50 -25 -59 -63 -59 -249 l0 -165 -370 0
            -370 0 0 165 c0 121 -4 175 -14 200 -15 35 -59 65 -96 65 -37 0 -81 -30 -96
            -65 -10 -25 -14 -79 -14 -200 l0 -165 -477 2 -478 3 -5 180 c-3 99 -9 188 -14
            198 -18 37 -85 56 -131 38z m-65 -801 c0 -194 7 -222 67 -246 43 -19 93 -5
            123 33 18 24 20 40 20 204 l0 179 480 0 480 0 0 -166 c0 -188 9 -222 65 -248
            41 -20 49 -20 90 0 56 26 65 60 65 248 l0 166 370 0 370 0 0 -166 c0 -188 9
            -222 65 -248 41 -20 49 -20 90 0 56 26 65 59 65 249 l0 167 198 -4 c182 -3
            201 -5 250 -27 68 -31 144 -113 169 -183 17 -51 18 -135 18 -1928 l0 -1875
            -26 -55 c-37 -80 -81 -125 -157 -162 l-67 -33 -1555 0 c-1482 0 -1557 1 -1608
            18 -70 25 -152 101 -183 169 l-24 53 0 1895 0 1895 25 50 c43 86 125 154 215
            176 17 4 112 7 213 8 l182 1 0 -170z"/>
            <path d="M1350 3193 c-81 -30 -90 -157 -14 -192 18 -8 224 -11 700 -11 l676 0
            29 29 c48 49 34 142 -26 169 -19 9 -195 12 -688 11 -364 0 -669 -3 -677 -6z"/>
            <path d="M1343 2335 c-33 -14 -63 -59 -63 -95 0 -37 30 -81 65 -96 51 -21
            2379 -21 2430 0 35 15 65 59 65 96 0 37 -30 81 -65 96 -50 21 -2384 20 -2432
            -1z"/>
            <path d="M1333 1478 c-57 -28 -67 -122 -17 -169 l26 -24 1218 0 1218 0 26 24
            c51 48 40 142 -20 170 -34 15 -2419 14 -2451 -1z"/>
        </g>
    </svg>
  )
}