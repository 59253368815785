import React, { useState } from "react";
import { Calendar } from "./calendario";


const CalendarDateModal = ({ name, currentDay, currentMonth, currentYear, renderNextSelectedDay=() => null }) => {
    const currentDate = new Date(currentYear, currentMonth - 1, currentDay);
    const [calendarCursor, setCalendarCursor] = useState({ yearSearchCursor: currentDate.getFullYear(), monthSearchCursor: currentDate.getMonth(), selectedDay: currentDate })

    return <div>
        <Calendar 
            name={name}
            loading={false}
            calendarCursor={calendarCursor}
            setCalendarCursor={setCalendarCursor}
            renderNextSelectedDay={renderNextSelectedDay}
        />
    </div>
}

export default CalendarDateModal