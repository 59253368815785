import './EditCRMModal.css'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddAlert, AlertType, Input } from '../../../../../../../../component'
import NewSelect from '../../../../../../../../newcomponents/form/select/select'
import NewButton, {
	ButtonModel,
} from '../../../../../../../../newcomponents/button/button'
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import { useApp } from '../../../../../../../../layout/App'
import { buildDate } from '../../../../../../../../utils/convertDate'
import { patchCouncil, postCouncil } from '../../../../../../http'
import { useConfiguration } from '../../../../../../configuration'

const strpCouncilData = (data) => {
	return {
		"id": data?.id || null,
		"council": data?.council || "",
		"nu_crm": data?.nu_crm || "",
		"uf_crm": data?.uf_crm || "",
		"person": data?.person || "",
		"registration_date": data?.registration_date || "",
		"specialties": data?.specialties || ""
	}
}


export default function EditCRMModal({ initCRMData, setSecondaryModal }) {
	const dispatch = useDispatch()
	const [holdingCRM, setHoldingCRM] = useState(strpCouncilData(initCRMData))
	const [holdingCRMErr, setHoldingCRMErr] = useState({})
	const { listas } = useSelector(state => state.appState)
	const { currentHealthPlaceUser, fetchUserCouncils, loged } = useApp()
	const { setModalProps } = useConfiguration()

	const handleChange = event => {
		setHoldingCRMErr({})
		setHoldingCRM(prev => ({
			...prev,
			[event.target.name]:
			event.target.value[0] === 't'
				? event.target.value
				: event.target.value.replace(/\D/g, ''),
		}))
	}

	const handleClose = () => {
		setSecondaryModal(MODAL_INIT_PROPS)
		setHoldingCRM(initCRMData)
	}

	const handleSelect = event => {
		setHoldingCRMErr({})
		setHoldingCRM(prev => ({ ...prev, uf_crm: event.target.selected }))
	}

	const handleSaveCRM = async () => {
    let errors = {}
		if (!holdingCRM.nu_crm) errors['nu_crm'] = 'Número obrigatório'
		if (!holdingCRM.uf_crm) errors['uf_crm'] = 'UF obrigatório'
		if (Object.keys(errors).length) return setHoldingCRMErr(errors)
		let currentDate = new Date()
		let payload = {
			...holdingCRM,
			'person': currentHealthPlaceUser?.user?.person?.id,
			'registration_date': buildDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate())
		}
		dispatch(loading())
		try {
			if (holdingCRM?.id) {
				await patchCouncil(holdingCRM?.id, payload)
			} else {
				await postCouncil(payload)
			}
			await fetchUserCouncils()
			dispatch([loaded(), AddAlert(`Edição de CRM`, `CRM salvo com sucesso`, AlertType.SUCCESS)])
			await loged()
			setSecondaryModal(MODAL_INIT_PROPS)
            setModalProps(MODAL_INIT_PROPS)
		} catch (err) {
			console.error(err)
			dispatch([
				loaded(),
				AddAlert(
					`Cadastro de CRM`,
					`Falha ao salvar CRM, tente novamente!`,
					AlertType.ERROR
				),
			])
		}
	}

	return (
		<div>
			<div className='CRMManager-CRMCol'>
				<Input
					label='Número'
					name='nu_crm'
					action={handleChange}
					value={holdingCRM.nu_crm}
					maxLength='8'
					required={{ erro: holdingCRMErr, message: holdingCRMErr.nu_crm }}
					placeholder='ex: 123456'
				/>
				<NewSelect
					options={listas.estados}
					canBeEmpty={false}
					label='UF'
					optionRefKey='sigla'
					optionStrKey='nome'
					selected={holdingCRM.uf_crm}
					onSelect={handleSelect}
					error={holdingCRMErr?.uf_crm}
				/>
			</div>
			<div className='SaveCRMModal-SaveBtn'>
				<NewButton
					label='Fechar'
					onClick={handleClose}
					model={ButtonModel.SECONDARY}
				/>
				<NewButton
					label='Salvar CRM'
					onClick={handleSaveCRM}
				/>
			</div>
		</div>
	)
}
