import React, { useEffect, useRef, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function ConsultationTextField({ id, value, handleChange, title, notes = '', readOnly = false, onBlur=() => null }) {

  return (
    <div className="consultation-text">
      {title && <div className='SOAP-ConsultationSubtitle'>{title}</div>}
      <p>{notes}</p>
      <ReactQuill
        theme="snow"
        value={value}
        readOnly={readOnly}
        onChange={handleChange}
        onBlur={onBlur}
      />
    </div>
  );
}