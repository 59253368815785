import React, { useEffect, useState } from 'react'
import { useThousandDays } from '../../../../../../ThousandDaysSection'
import { Button, IcoAdd, IcoBin, Input, List } from '../../../../../../../../../../../../../../component'

const init_new_drug = { name: '', posology: '', amount: '' }

export default function UsedDrugsInfoSection() {
	const { selectedLanguage, partograph, setPartograph, savePartograph } = useThousandDays()

	const [newDrug, setNewDrug] = useState(init_new_drug)

	useEffect(() => {
		savePartograph()
	}, [partograph.used_drugs])

	const handleChangeNewDrug = event =>
		setNewDrug(prev => ({
			...prev,
			[`${event.target.name}`]: event.target.value,
		}))

	const saveNewDrug = () => {
		if (!newDrug.name && !newDrug.posology && !newDrug.amount) return
		setPartograph(prev => ({
			...prev,
			used_drugs: [...prev.used_drugs, newDrug],
		}))
		setNewDrug(init_new_drug)
	}

	const removeDrug = drug => {
		let newData = partograph.used_drugs.filter(
			(instance, idx) => idx !== drug.id
		)
		setPartograph(prev => ({
			...prev,
			used_drugs: newData,
		}))
	}

    return <div>
        <div className='ThousandDaysChildBirth_DrugParam'>
            <Input
                name='name'
                value={newDrug.name}
                label='Nome'
                action={handleChangeNewDrug}
                placeholder='Bupivacaína'
                autoComplete='off'
            />
            <Input
                name='posology'
                value={newDrug.posology}
                label='Posologia'
                action={handleChangeNewDrug}
                placeholder='Injeção epidural'
                autoComplete='off'
            />
            <Input
                name='amount'
                value={newDrug.amount}
                label='Quantidade'
                action={handleChangeNewDrug}
                placeholder='10 ml / hora'
                autoComplete='off'
            />
            <div className='ThousandDaysChildBirth_DrugParam_Btn'>
                <Button color='save' type='btn circle' action={saveNewDrug} title='Novo'>
                    <IcoAdd />
                </Button>
            </div>
        </div>

        <div className='ThousandDaysChildbirth_List'>
            <List
                head={[
                    { "colunm": "h_name", "text": "Nome", "className": "" },
                    { "colunm": "h_posology", "text": "Posologia", "className": "" },
                    { "colunm": "h_amount", "text": "Quantidade", "className": "" },
                    { "colunm": "h_action", "text": "", "className": "" },
                ]}
                data={partograph.used_drugs.map(instance => ({ ...instance }))}
                listCustom={(drug, index) => {
                    drug['id'] = index
                    drug['h_name'] = drug.name
                    drug['h_posology'] = drug.posology
                    drug['h_amount'] = drug.amount
                    drug['h_action'] = <Button
                        color='primary'
                        type='btn circle'
                        action={() => removeDrug(drug)}
                        title='Remover'
                    >
                        <IcoBin />
                    </Button>
                    return drug
                }}
            />
        </div>


    </div>
}
