import React, { useContext, useState, useEffect, useRef } from "react"
import './SaveHealthPlaceModal.css'
import HealthPlaceGeneralData from "./subcomponents/HealthPlaceGeneralData/HealthPlaceGeneralData"
import HealthPlaceAddressData from "./subcomponents/HealthPlaceAddressData"
import HealthPlaceLogoData from "./subcomponents/HealthPlaceLogoData"
import HealthPlaceTimesData from "./subcomponents/HealthPlaceTimesData"

const HealthPlaceFormContext = React.createContext()

export function useHealthPlaceForm() {
    return useContext(HealthPlaceFormContext)
}

const INIT_HEALTH_PLACE = {
    name: '',
    cnpj: '',
    type: {},
    technical_responsible: {},
    cnes: '',
    contact_whatsapp: '',
    base64_avatar: null,
    address: {},
    timetable: {}
}

const FormSelector = ({ type }) => {
    switch (type) {
      case 'GeneralData':
        return <HealthPlaceGeneralData />;
      case 'AddressData':
        return <HealthPlaceAddressData />;
      case 'TempoData':
        return <HealthPlaceTimesData />;
      case 'LogoData':
        return <HealthPlaceLogoData />;
      default:
        return <></>
    }
  }

  export default function SaveHealthPlaceModal({ initHealthPlace=INIT_HEALTH_PLACE }) {
    const timetableRef = useRef({});
    const base64AvatarRef = useRef('')
    const [section, setSection] = useState({ id: 'GeneralData', name: 'Dados gerais' });
    const [selectedHealthPlace, setSelectedHealthPlace] = useState(initHealthPlace);
    const data = require('./data.json');
    const options = data.options.add_health_place;

    useEffect(() => {
      setSelectedHealthPlace(selectedHealthPlace)
    }, [section])

    const onNext = () => {
      const currentIndex = options.findIndex(op => op.id === section.id);
      if (currentIndex < options.length - 1) {
        setSection(options[currentIndex + 1]);
      }
    }

    return (
      <HealthPlaceFormContext.Provider 
        value={{
          timetableRef,
          base64AvatarRef,
          selectedHealthPlace, 
          setSelectedHealthPlace,
          onNext
        }}
      >
        <section className='HealthPlaceForm'>
          <div className='HealthPlaceForm-SideLeft'>
            {options.map(op => (
              <div
                key={op.id}
                onClick={() => setSection(op)}
                className={`HealthPlaceForm-SideLeft-Btn ${op.id === section.id ? 'active' : ''}`}
              >
                {op.name}
              </div>
            ))}
          </div>
          <div className='HealthPlaceForm-separator' />
          <section className='HealthPlaceForm-Modal'>
            <FormSelector type={section.id} />
          </section>
        </section>
      </HealthPlaceFormContext.Provider>
    );
  }