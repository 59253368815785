import './LocationsOptions.css'
import React from "react";
import HorizontalField from '../../../../../../../../component/HorizontalField/HorizontalField';
import EditLocationsOptionsModal from './EditLocationsOptionsModal/EditLocationsOptionsModal';
import { useInventoryMaterials } from '../../../../InventoryMaterials';

export default function LocationsOptions({ activeLocations=[], setModalInfo=() => null }) {
    const { canReadAndWrite } = useInventoryMaterials()

    return <div className='LocationsOptions'>
        <div className='LocationsOptions-Header'>
            <div className='LocationsOptions-Header-Icon'>L</div>
            <div className='LocationsOptions-Header-Title'>Localizações</div>
        </div>
        <div className='LocationsOptions-ActionRow'>
            {
                canReadAndWrite
                ? <div 
                    className='LocationsOptions-ActionRow-Btn' 
                    onClick={() => setModalInfo({
                        open: true,
                        title: 'Editar opções de localizações',
                        content: <EditLocationsOptionsModal />
                    })}
                >
                    <b>Editar</b>
                </div>
                : null
            }
        </div>
        <div className='LocationsOptions-Body'>
            {
                activeLocations?.length
                ? activeLocations.map(category_cursor => <HorizontalField label={category_cursor?.name} content='Ativo' />) 
                : <p>Nenhuma localização habilitada</p>
            }
        </div>
    </div>
}