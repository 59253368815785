import api from '../../helpers/api';
import { AddAlert, AlertType } from '../../component';
import { history } from '../../helpers';

export const INITIAL_STATE_APP = 'INITIAL_STATE_APP'
export const setStateApp = e => ({
  type: INITIAL_STATE_APP,
  payload: e
})

export const LOAD = 'LOAD'
export const setLoad = e => ({
  type: LOAD,
  payload: e
})


export const loading = e => [setLoad(true)]
export const loaded = e => [setLoad(false)]


export const LISTAS = 'LISTAS'
export const setLista = e => ({
  type: LISTAS,
  payload: e
})

export const listaCidades = e => {
  let params = `?`
  e = { ...e, limit: e?.limit ? e.limit : 20, offset: e?.offset ? e.offset : 0 }

  Object.keys(e).map((par, i)=> {
    params = `${i === 0? params : params + '&'}${par}=${e[par]}`
  })

  return dispatch => {
    api.get(`address/city/${params}`)
    .then(resposta => {
      const { data } = resposta;
      dispatch(setLista({name: 'cidades', value: data.results}))
    })
    .catch(() => dispatch(AddAlert('Listar cidades', 'Erro ao obter cidades', AlertType.ERROR)))
  }
}

export const searchCep = async e => {
  return api.get(`address/cep/${e}/`)
  .then(resposta => {
    const { data } = resposta;
    return data
  })
  .catch(error => {
    return {error: JSON.parse( error.request.response )}
  })
}

export const verificarEmailReenviar = e => {
  return dispatch => {
  api.get(`auth/email/sendemail/`)
    .then(() => {
      dispatch(AddAlert('Verificação de E-mail', 'E-mail reenviado com sucesso', AlertType.SUCCESS))
    })
    .catch(error => {
      dispatch(AddAlert('Verificação de E-mail', 'Falha ao reenviar e-mail', AlertType.ERROR))
      console.error(error)
    })
  }
}

export const logIn = ({ email, senha}) => {
  return dispatch => {
    dispatch(loading())
    api.post(`auth/login/`, {email: email, password: senha})
      .then(response => {
        const user = response.data.user
        if (!user?.health_place_users?.length) 
          return dispatch(AddAlert('Login', 'Você não possui permissão de acesso', AlertType.WARNING))

        localStorage.setItem('token', response.data.key)
        localStorage.setItem('user', JSON.stringify(response.data.user))

        dispatch([
          loaded(),
        ])
        if (!window.location.href.includes('prontuario_odontologico')) {
          user.password_change ? history.push('/configuration/changepassword') : history.push('/home')
        }
      })
      .catch(error => {
        dispatch(loaded())
        console.log(error.response);
        if (error?.response?.data.non_field_errors) {
          dispatch(AddAlert('Login', error.response.data.non_field_errors, AlertType.WARNING))
        } else {
          dispatch(AddAlert('Login', 'Não foi possível acessar. Tente novamente', AlertType.WARNING))
        }
      })
  }
}