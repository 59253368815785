import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import './imagem.css'
import "cropperjs/dist/cropper.css"
import { Cropper } from 'react-cropper'
import { AddAlert, AlertType } from '../../component'
import logo from '../../media/SaluthoLogo.jpg'

export function ImagemCropper({
  action,
  name,
  value,
  imgDefault = logo,
  config = {
    style: { height: 275, width: 275 },
    cropBoxResizable: true,
    minCropBoxHeight: 10,
    minCropBoxWidth: 10,
    initialAspectRatio: 1,
    aspectRatio: 1
  },
  textBtn = 'Selecionar nova foto',
  crop = true,
  disabled,
}) {
  const dispatch = useDispatch()
  const cropperRef = useRef(null);
  const [foto, setfoto] = useState()
  const [cropData, setcropData] = useState()

  useEffect(()=>{
    setfoto(value? value : imgDefault)
    // eslint-disable-next-line
  }, [])

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    let dataType = cropper.url.split(";base64,")[0].split(":")[1]
    setcropData(cropper.getCroppedCanvas().toDataURL(dataType))
    action({target: {name: name, value: cropper.getCroppedCanvas().toDataURL(dataType)}})
  }

  const imgChange = async e => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0])
      reader.onload = event => {
        setfoto(event.target.result)
      }
      reader.onerror = _ => {
        dispatch(AddAlert('Atualizar imagem', 'Falha ao obter imagem, tente novamente!', AlertType.ERROR))
      }
    }
  }

  return (
    <div>
      <div>
        <div>
          <input
            id="uploadfileInput"
            type='file'
            onChange={e => imgChange(e)}
            style={{display: 'none'}}
            disabled={disabled}
          />
          <label
            htmlFor="uploadfileInput"
            className={`uploadfileInputSecondary ${disabled ? 'disabled' : ''}`}
          >
            {textBtn}
          </label>
        </div>
      </div>
      <div className='painel-img-cropper'>
        <div>
          <Cropper
            src={foto}
            style={config.style}
            ref={cropperRef}
            initialAspectRatio={config.initialAspectRatio}
            viewMode={1}
            guides={true}
            minCropBoxHeight={config.minCropBoxHeight}
            minCropBoxWidth={config.minCropBoxWidth}
            cropBoxResizable={config.cropBoxResizable}
            background={false}
            responsive={true}
            autoCropArea={10}
            aspectRatio={1}
            checkOrientation={false}
            crop={onCrop}
          />
        </div>
        <div>
          {
            crop && cropData ?
            <div className='bg-img-default'>
              <img
                style={{ width: 200, height: 200 }}
                src={cropData} alt="cropped"
              />
            </div>
            : null
          }
        </div>
      </div>


    </div>
  )
}
