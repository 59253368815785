import { AlertRowTextType } from '../../../../../../../../component/alertRowText/alertRowText'
import { Mask1DecimalPlace } from '../../../../../../../../component/mask/mask'

const vital_signs_translate = require('../../../../../../../data/translation.json')

export const getIMC = (weight, height) => {
    weight = Number(String(weight).replace(',', '.'))
    height = Number(String(height).replace(',', '.'))

    if (!weight || !height) return ''
    let bmi = (weight / ((height * height) / 10000)).toFixed(2)
    return bmi
}

export function getIMCResult(weight, height, selectedLanguage='pt-BR') {
    const { Underweight, Overweight, Obesity } = vital_signs_translate[selectedLanguage]
    weight = Number(String(weight).replace(',', '.'))
    height = Number(String(height).replace(',', '.'))
    let bmi = getIMC(weight, height)

    if (!bmi) return { type: '', content: '' }
    if (bmi < 18.5)
        return { type: AlertRowTextType.WARNING, content: Underweight }
    if (bmi >= 18.6 && bmi < 25)
        return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
    if (25 <= bmi && bmi <= 29.9)
        return { type: AlertRowTextType.WARNING, content: Overweight }
    return { type: AlertRowTextType.WARNING, content: Obesity }
}

export function getPressureResult(systolic, diastolic, selectedLanguage='pt-BR') {
    const {
        Lowbloodpressure,
        Prehyoertension,
        Hypertensionstageone,
        Hypertensionstageotwo,
    } = vital_signs_translate[selectedLanguage]
    systolic = Number(systolic)
    diastolic = Number(diastolic)

    if (!systolic || !diastolic) {
        return { type: '', content: '' }
    } else if (systolic < 90 || diastolic < 60) {
        return { type: AlertRowTextType.WARNING, content: Lowbloodpressure }
    } else if (systolic > 120 || diastolic > 80) {
        if (systolic <= 129 && diastolic <= 89) {
            return { type: AlertRowTextType.WARNING, content: Prehyoertension }
        } else if (systolic <= 139 || diastolic <= 89) {
            return {
                type: AlertRowTextType.WARNING,
                content: Hypertensionstageone,
            }
        } else {
            return {
                type: AlertRowTextType.WARNING,
                content: Hypertensionstageotwo,
            }
        }
    } else {
        return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
    }
}

export function getRespirationRateResults(
    respiration_rate,
    age,
    selectedLanguage='pt-BR'
) {
    const { Bradypnea, Tachypnea } = vital_signs_translate[selectedLanguage]
    if (!respiration_rate && typeof respiration_rate !== 'number')
        return { type: '', content: '' }

    const respirationRange = {
        '0M_TO_6M': [30, 60],
        '6M_TO_1Y': [24, 40],
        '1Y_TO_5Y': [20, 30],
        '6Y_TO_12Y': [18, 26],
        '13Y_TO_65Y': [12, 20],
        '65_PLUS': [12, 22],
    }

    let interval = ''
    let years = age?.anos
    let months = age?.mes

    switch (true) {
        case !!years:
            if (years <= 5) {
                interval = '1Y_TO_5Y'
            } else if (years <= 12) {
                interval = '6Y_TO_12Y'
            } else if (years <= 65) {
                interval = '13Y_TO_65Y'
            } else {
                interval = '65_PLUS'
            }
            break
        case !!months:
            interval = months <= 6 ? '0M_TO_6M' : '6M_TO_1Y'
            break
        default:
            return { type: '', content: '' }
    }

    let range = respirationRange[interval]

    if (respiration_rate < range[0]) {
        return { type: AlertRowTextType.WARNING, content: Bradypnea }
    } else if (respiration_rate > range[1]) {
        return { type: AlertRowTextType.WARNING, content: Tachypnea }
    } else {
        return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
    }
}

export function getHeartRateResults(heart_rate, age, selectedLanguage='pt-BR') {
    const { Bradycardia, Tachycardia } = vital_signs_translate[selectedLanguage]
    if (!heart_rate && typeof heart_rate !== 'number')
        return { type: '', content: '' }

    const hearRateRange = {
        '0M_TO_1M': [70, 190],
        '1M_TO_11M': [80, 160],
        '1Y_TO_2Y': [80, 130],
        '3Y_TO_5Y': [80, 120],
        '6Y_TO_15Y': [70, 100],
        '16_TO_PLUS': [60, 100],
    }

    let interval = ''
    let years = age?.anos
    let months = age?.mes

    switch (true) {
        case !!years:
            if (years <= 2) {
                interval = '1Y_TO_2Y'
            } else if (years <= 5) {
                interval = '3Y_TO_5Y'
            } else if (years <= 15) {
                interval = '6Y_TO_15Y'
            } else {
                interval = '16_TO_PLUS'
            }
            break
        case !!months:
            interval = months <= 1 ? '0M_TO_1M' : '1M_TO_11M'
            break
        default:
            return { type: '', content: '' }
    }

    let range = hearRateRange[interval]
    if (heart_rate < range[0]) {
        return { type: AlertRowTextType.WARNING, content: Bradycardia }
    } else if (heart_rate > range[1]) {
        return { type: AlertRowTextType.WARNING, content: Tachycardia }
    } else {
        return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
    }
}

export function getBodyTemperatureResults(
    body_temperature,
    age,
    selectedLanguage='pt-BR'
) {
    const { Hypothermia, Febrille } = vital_signs_translate[selectedLanguage]
    if (!body_temperature && typeof body_temperature !== 'number')
        return { type: '', content: '' }
    body_temperature = Number(String(body_temperature).replace(',', '.'))

    const bodyTemperatureRange = {
        '0M_TO_1Y': [36.4, 37.5],
        '1Y_TO_64Y': [36.1, 37.2],
        '65Y_TO_PLUS': [35.6, 36.2],
    }

    let interval = ''
    let years = age?.anos
    let months = age?.mes

    if (!!months && months <= 12) {
        interval = '0M_TO_1Y'
    } else if (!!years) {
        if (years < 64) {
            interval = '1Y_TO_64Y'
        } else {
            interval = '65Y_TO_PLUS'
        }
    } else {
        return { type: '', content: '' }
    }

    let range = bodyTemperatureRange[interval]
    if (body_temperature < range[0]) {
        return { type: AlertRowTextType.WARNING, content: Hypothermia }
    } else if (body_temperature > range[1]) {
        return { type: AlertRowTextType.WARNING, content: Febrille }
    } else {
        return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
    }
}

export function getOxygenSaturationResults(
    oxygen_saturation,
    selectedLanguage='pt-BR'
) {
    const { Hypoxemia } = vital_signs_translate[selectedLanguage]
    if (!oxygen_saturation && typeof oxygen_saturation !== 'number')
        return { type: '', content: '' }
    oxygen_saturation = Number(String(oxygen_saturation).replace(',', '.'))

    if (oxygen_saturation >= 95 && oxygen_saturation <= 100) {
        return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
    } else {
        return { type: AlertRowTextType.WARNING, content: Hypoxemia }
    }
}

export function strpVitalSign(vitalSign = null) {
    return {
        _id: vitalSign?._id,
        person: vitalSign?.person,
        attendance: vitalSign?.attendance,
        systolic: vitalSign?.systolic ? String(vitalSign.systolic) : '',
        diastolic: vitalSign?.diastolic ? String(vitalSign.diastolic) : '',
        oxygen_saturation: vitalSign?.oxygen_saturation
            ? String(vitalSign.oxygen_saturation).replace('.', ',')
            : '',
        body_temperature: vitalSign?.body_temperature
            ? String(vitalSign.body_temperature).replace('.', ',')
            : '',
        heart_rate: vitalSign?.heart_rate ? String(vitalSign.heart_rate) : '',
        respiration_rate: vitalSign?.respiration_rate
            ? String(vitalSign.respiration_rate)
            : '',
        active: true,
    }
}

export function parseVitalSigns(vitalSigns) {
    return {
        _id: vitalSigns?._id,
        person: vitalSigns?.person?.id,
        attendance: vitalSigns?.attendance,
        systolic: vitalSigns?.systolic ? Number(vitalSigns.systolic) : null,
        diastolic: vitalSigns?.diastolic ? Number(vitalSigns.diastolic) : null,
        weight: vitalSigns?.weight
            ? Number(String(vitalSigns.weight).replace(',', '.'))
            : null,
        height: vitalSigns?.height ? Number(vitalSigns.height) : null,
        oxygen_saturation: vitalSigns?.oxygen_saturation
            ? Number(String(vitalSigns.oxygen_saturation).replace(',', '.'))
            : null,
        body_temperature: vitalSigns?.body_temperature
            ? Number(String(vitalSigns.body_temperature).replace(',', '.'))
            : null,
        heart_rate: vitalSigns?.heart_rate ? Number(vitalSigns.heart_rate) : null,
        respiration_rate: vitalSigns?.respiration_rate
            ? Number(vitalSigns.respiration_rate)
            : null,
        active: true
    }
}