import './ChartVitalSigns.css'
import React, { useEffect } from 'react'
import {
    Chart,
    LinearScale,
    CategoryScale,
    LineController,
    PointElement,
    LineElement,
    Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ShowDetailChartVitalSign from '../ShowDetailChartVitalSign/ShowDetailChartVitalSign';
import { MaskDataHora } from '../../../../../../../../../../component';

Chart.register(
    LinearScale,
    CategoryScale,
    LineController,
    PointElement,
    LineElement,
    Title
);

export default function ChartVitalSigns({ vitalSigns = [], fetchVitalSigns=() => null, setModalInfo=() => null }) {
    useEffect(() => {
        fetchVitalSigns({ offset: 0 })
    }, [])

    const vitalLabels = vitalSigns.map((record) => record.date || MaskDataHora(record?.updated_at)?.data);

    const systolicData = vitalSigns.map((record) => record.systolic || null);
    const diastolicData = vitalSigns.map((record) => record.diastolic || null);
    const heartRateData = vitalSigns.map((record) => record.heart_rate || null);
    const oxygenSatData = vitalSigns.map((record) => record.oxygen_saturation || null);
    const bodyTempData = vitalSigns.map((record) => record.body_temperature || null);
    const respirationRateData = vitalSigns.map((record) => record.respiration_rate || null);
  
    // Função auxiliar para criar configurações de gráficos
    const createChartConfig = (datasets, labels, yLabel) => {
      return {
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: '',
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Data',
              },
            },
            y: {
              title: {
                display: true,
                text: yLabel,
              },
            },
          },
        },
      };
    };
  
    // Definição dos gráficos de sinais vitais
    const vitalSignsCharts = [
      {
        title: 'Pressão Arterial',
        datasets: [
          {
            label: 'Sistólica',
            data: systolicData,
            borderColor: 'rgba(255,99,132,1)',
            backgroundColor: 'rgba(255,99,132,0.1)',
            fill: false,
            tension: 0.1,
            spanGaps: true,
          },
          {
            label: 'Diastólica',
            data: diastolicData,
            borderColor: 'rgba(54,162,235,1)',
            backgroundColor: 'rgba(54,162,235,0.1)',
            fill: false,
            tension: 0.1,
            spanGaps: true,
          },
        ],
        labels: vitalLabels,
        yLabel: 'Pressão (mmHg)',
      },
      {
        title: 'Frequência Cardíaca',
        datasets: [
          {
            label: 'Frequência Cardíaca',
            data: heartRateData,
            borderColor: 'rgba(75,192,192,1)',
            backgroundColor: 'rgba(75,192,192,0.1)',
            fill: true,
            tension: 0.1,
            spanGaps: true,
          },
        ],
        labels: vitalLabels,
        yLabel: 'Frequência Cardíaca (bpm)',
      },
      {
        title: 'Saturação de Oxigênio',
        datasets: [
          {
            label: 'Saturação de Oxigênio',
            data: oxygenSatData,
            borderColor: 'rgba(153,102,255,1)',
            backgroundColor: 'rgba(153,102,255,0.1)',
            fill: true,
            tension: 0.1,
            spanGaps: true,
          },
        ],
        labels: vitalLabels,
        yLabel: 'Saturação de Oxigênio (%)',
      },
      {
        title: 'Temperatura Corporal',
        datasets: [
          {
            label: 'Temperatura Corporal',
            data: bodyTempData,
            borderColor: 'rgba(255,159,64,1)',
            backgroundColor: 'rgba(255,159,64,0.1)',
            fill: true,
            tension: 0.1,
            spanGaps: true,
          },
        ],
        labels: vitalLabels,
        yLabel: 'Temperatura (°C)',
      },
      {
        title: 'Frequência Respiratória',
        datasets: [
          {
            label: 'Frequência Respiratória',
            data: respirationRateData,
            borderColor: 'rgba(201,203,207,1)',
            backgroundColor: 'rgba(201,203,207,0.1)',
            fill: true,
            tension: 0.1,
            spanGaps: true,
          },
        ],
        labels: vitalLabels,
        yLabel: 'Frequência Respiratória (mpm)',
      },
    ];

    const handleShowDetailsModal = (chartConfig) => {
      setModalInfo(prev => ({
        ...prev,
        open: true,
        title: 'Mostrar Detalhes',
        content: <ShowDetailChartVitalSign chartConfig={chartConfig} />
      }))
    }

    return <div className='ChartVitalSigns-Grid-Content'>
        {vitalSignsCharts.map((chartConfig, index) => {
        const config = createChartConfig(
            chartConfig.datasets,
            chartConfig.labels,
            chartConfig.yLabel
        );
        return (
            <div
                className='ChartVitalSigns-Grid-Chart-Container'
                key={index}
                onClick={() => handleShowDetailsModal(chartConfig)}
            >
            <div className='ChartVitalSigns-ChartOverlay'>
                <p>Mostrar Detalhes</p>
            </div>
                <Line data={config.data} options={config.options} />
            </div>
        );
        })}
    </div>
}