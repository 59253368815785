import './AssociateCollaboratorModal.css'
import React from "react";
import { useDispatch } from 'react-redux';
import Button, { ButtonModel } from '../../../../../../newcomponents/button/button';
import { MODAL_INIT_PROPS } from '../../../../../../newcomponents/modal/modal';
import { associateCollaborator } from '../../../http';
import { loaded, loading } from '../../../../../../layout/redux/AppActions';
import { AddAlert, AlertType } from '../../../../../../component';

export default function AssociateCollaboratorModal({ collaborator, health_place, setModalInfo=() => null, fetchCollaborators=async () => null, collaboratorInactivated=null }) {
    const dispatch = useDispatch()

    const handleSubmit = async () => {
        const payload = {
            ...collaborator,
            'collaborator_inactivated': collaboratorInactivated,
            "health_place": health_place
        }
        
        dispatch(loading())
        try {
            await associateCollaborator(payload)
            await fetchCollaborators({ offset: 0 })
            dispatch(AddAlert('Associar colaborador', 'Colaborador existente associado com sucesso!', AlertType.SUCCESS))
            setModalInfo(MODAL_INIT_PROPS)
        } catch (err) {
            dispatch(AddAlert('Associar colaborador', 'Falha ao associar colaborador', AlertType.ERROR))
            console.error('AssociateContributorModal ~ handleSubmit ~ ', err)
        }
        dispatch(loaded())
    }
    return <div>
        <p>Um profissional já está cadastrado na plataforma com esse e-mail.</p>
        <p>Deseja associar para a sua instituição?</p>

        <div className='AssociateContributorModal-BtnBox'>
            <Button
                label='Cancelar'
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
                model={ButtonModel.SECONDARY}
            />
            <Button 
                label='Associar'
                onClick={handleSubmit}
            />
        </div>
    </div>
}