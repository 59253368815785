import './PregnancyRiskCalculator.css'
import React, { useState } from 'react'
import { PregnancyRiskBackgroundForm } from './subcomponents/PregnancyRiskBackgroundForm'
import { PregnancyRiskGeneralInfoForm } from './subcomponents/PregnancyRiskGeneralInfoForm'
import { PregnancyRiskMedicalSurgicalForm } from './subcomponents/PregnancyRiskMedicalSurgicalForm'
import { PregnancyRiskObstetricalGynecologicalForm } from './subcomponents/PregnancyRiskObstetricalGynecologicalForm'
import { useThousandDays } from '../../../../ThousandDaysSection'

export default function PregnancyRiskCalculator() {
	const [openSection, setOpenSection] = useState('General')
	const { selectedLanguage } = useThousandDays()

	const {
		Generalinfo,
		Background,
		Medicalandsurgical,
		Obstetricalandgynecological,
	} = require('./PregnancyRiskCalculator.json')[selectedLanguage]

	const sections = {
		General: <PregnancyRiskGeneralInfoForm />,
		Background: <PregnancyRiskBackgroundForm />,
		ObstetricalGynecological: <PregnancyRiskObstetricalGynecologicalForm />,
		MedicalSurgical: <PregnancyRiskMedicalSurgicalForm />,
	}

	return (
		<div className='PregnancyRiskCalculator'>
			<div className='PregnancyRiskCalculator-Header'>
				<p
					className={
						openSection === 'General'
							? 'PregnancyRiskCalculator-Header-Active'
							: ''
					}
					onClick={() => setOpenSection('General')}
				>
					{Generalinfo}
				</p>
				<p
					className={
						openSection === 'Background'
							? 'PregnancyRiskCalculator-Header-Active'
							: ''
					}
					onClick={() => setOpenSection('Background')}
				>
					{Background}
				</p>
				<p
					className={
						openSection === 'ObstetricalGynecological'
							? 'PregnancyRiskCalculator-Header-Active'
							: ''
					}
					onClick={() => setOpenSection('ObstetricalGynecological')}
				>
					{Obstetricalandgynecological}
				</p>
				<p
					className={
						openSection === 'MedicalSurgical'
							? 'PregnancyRiskCalculator-Header-Active'
							: ''
					}
					onClick={() => setOpenSection('MedicalSurgical')}
				>
					{Medicalandsurgical}
				</p>
			</div>
			<div className='PregnancyRiskCalculator-Separator' />
			{sections[openSection]}
		</div>
	)
}
