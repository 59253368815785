import './PreceptorshipManageRoom.css'
import React, { useEffect, useRef, useState } from "react";
import NewButton from '../../../newcomponents/button/button';
import Modal, { MODAL_INIT_PROPS } from '../../../newcomponents/modal/modal'
import AlertRowText, { AlertRowTextType } from '../../../component/alertRowText/alertRowText';
import NewPreceptorshipRoomModal from '../PreceptorshipDetailRoom/subcomponents/NewEditPreceptorshipRoomModal/NewEditPreceptorshipRoomModal';
import { buildDate, formatISO8601Str } from '../../../utils/convertDate';
import Loading from '../../../newcomponents/loading/loading';
import { Input } from '../../../component';
import NewSelect from '../../../newcomponents/form/select/select';
import { getYearsStart1900 } from '../../../utils/getYearsStart1900';
import { getParams } from '../../../utils';
import { apiV2, history } from '../../../helpers';

const defaultOptions = require('../../../component/form/select/options.json')

export default function PreceptorshipManageRoom() {
    const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
    const [loading, setLoading] = useState(false)
    const [rooms, setRooms] = useState([])
    const searchTimeOut = useRef()
    const [timeFilters, setTimeFilters] = useState({ day: '', month: '', year: '' })
    const filtersRef = useRef({ name__icontains: '', description__icontains: '' })

    useEffect(() => {
        const allFieldsFilled = timeFilters?.day && timeFilters?.month && timeFilters?.year
        const allFieldsEmpty = !timeFilters?.day && !timeFilters?.month && !timeFilters?.year
        
        if (allFieldsFilled || allFieldsEmpty) fetchUserRooms()
    }, [timeFilters?.day, timeFilters?.month, timeFilters?.year])

    const fetchUserRooms = async (params={}) => {
        params = {
            ...filtersRef.current,
            time: timeFilters?.year && timeFilters?.month && timeFilters?.day
                ? buildDate(timeFilters.year, timeFilters.month, timeFilters.day)
                : ''
        }

        setLoading(true)
        await apiV2.get(`/preceptorship/groups/owned${getParams(params)}`)
            .then(res => {
                setRooms(res.data)
            })
            .catch(err => {
                console.error('fetchUserRooms', err)
                setRooms([])
            })
        setLoading(false)
    }

    const handleSelect = (event) => {
        setTimeFilters(prev => ({ ...prev, [event.target.id]: event.target.selected }))
    }

    const handleChange = (event) => {
        filtersRef.current = {
            ...filtersRef.current,
            [event.target.name]: event.target.value
        }

        try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(() => {
				fetchUserRooms()
			}, 300)
		} catch (err) {
			console.error('handleSelectDate', err)
		}
    }

    return <>
        <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
        <div className="PreceptorshipManageRoom">
            <div>
                <div className='PreceptorshipManageRoom-BtnFullWidth'>
                    <NewButton
                        label='Novo Grupo'
                        onClick={() => setModalInfo({
                            open: true,
                            title: 'Novo Grupo de Acompanhamento',
                            content: <NewPreceptorshipRoomModal 
                                setModalInfo={setModalInfo} 
                                fetchData={fetchUserRooms} 
                            />
                        })}
                    />
                </div>
                <div className='PreceptorshipManageRoom-Filters-Separator'></div>
                <div>
                    <p><b>Nome do grupo</b></p>
					<Input
						name='name__icontains'
						action={handleChange}
						placeholder='Filtrar por Nome'
					/>
                    <p><b>Descrição</b></p>
					<Input
						name='description__icontains'
						action={handleChange}
						placeholder='Filtrar por Descrição'
					/>
                    <p><b>Data de criação</b></p>
						<div className='PreceptorshipManageRoom-Filters-Select-Date'>
							<NewSelect
								id='day'
								defaultText='Dia'
								label='De'
								options={defaultOptions.days}
								onSelect={handleSelect}
								selected={timeFilters?.day || ''}
							/>
							<NewSelect
								id='month'
								defaultText='Mês'
								options={defaultOptions.months}
								onSelect={handleSelect}
								selected={timeFilters?.month}
							/>
							<NewSelect
								id='year'
								defaultText='Ano'
								options={getYearsStart1900()}
								onSelect={handleSelect}
								selected={timeFilters?.year}
							/>
						</div>
                </div>
            </div>
            <div className='PreceptorshipManageRoom-Separator' />
            {
                loading
                ? <div className='PreceptorshipManageRoom-Loading'>
                    <Loading />
                </div>
                : <div>
                    <p><b>Grupos</b></p>
                    {
                        rooms?.length
                        ? <div className='PreceptorshipManageRoom-Main-Grid'>
                        {
                            rooms.map(room => {
                                return <div onClick={() => history.push(`/preceptoria/grupo/${room.id}`)}>
                                <p className='PreceptorshipManageRoom-Main-Grid-Title'><b>{ room?.name || '-' }</b></p>
                                <div className='PreceptorshipManageRoom-Main-Grid-Content'>
                                    <div>
                                        <b>Data de criação</b>
                                        <p>{ room?.time ? formatISO8601Str(room.time) : '-' }</p>
                                    </div>
                                    <div>
                                        <b>Descrição</b>
                                        <p>{ room?.description || '-' }</p>
                                    </div>
                                </div>
                            </div>
                            })
                        }
                        </div>
                        : <div className='PreceptorshipManageRoom-Main-Warning' onClick={() => null}>
                            <AlertRowText content='Crie seu primeiro grupo' type={AlertRowTextType.INFO} outlined />
                        </div>
                    }
                </div>
            }
        </div>
    </> 
}