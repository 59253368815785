import './EmailVerified.css'
import React from 'react'
import { useHistory } from 'react-router-dom'


export default function EmailVerifiedPage() {
    const history = useHistory()

    return (
    <div className='EmailVerified'>
        <section>
            <h3>Salutho</h3>
            <div className='EmailVerified-Headline'>
                <h4>E-mail validado com sucesso</h4>
                <p>Se você é paciente volte para o <b>Aplicativo</b>.</p>
                <p>Se você é um profissional de saúde, <b className='EmailVerified-Link' onClick={() => history.push('/home')}>clique aqui</b></p>
            </div>
        </section>
    </div>
    )
}
