import './RemoveContributorModal.css'
import React from 'react'
import { useDispatch } from 'react-redux'
import Button, { ButtonModel } from '../../../../newcomponents/button/button'
import api from '../../../../helpers/api'
import { AddAlert, AlertType } from '../../../../component'
import { useCollaborators } from '../../Collaborators'
import { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal'
import { loaded, loading } from '../../../../layout/redux/AppActions'



export default function RemoveContributorModal({ holdingColaborator }) {
  const dispatch = useDispatch()
  const { setModalInfo, fetchCollaborators } = useCollaborators()
  
  const removeColaborator = async () => {
      dispatch(loading())
      api.delete(`health_place_user/user/${holdingColaborator.id}/`)
        .then(() => {
          dispatch([loaded(), AddAlert('Remover colaborador', 'Colaborador removido com sucesso', AlertType.SUCCESS)])
          fetchCollaborators({ offset: 0 })
          setModalInfo(MODAL_INIT_PROPS)
        })
        .catch(error => dispatch([loaded(), AddAlert('Remover colaborador', error.request.response, AlertType.ERROR)]))
  }


  return <div>
    <p>Tem certeza que deseja remover este Colaborador?</p>
    <span><strong>{holdingColaborator.email}</strong></span>
    <div className='RemoveContributorModal-BtnBox'>
      <Button 
        label='Fechar'
        model={ButtonModel.SECONDARY}
        onClick={() => setModalInfo(MODAL_INIT_PROPS)}
      />
      <Button
        label='Remover'
        model={ButtonModel.WARNING_OUTLINED}
        onClick={removeColaborator}
      />
    </div>
  </div>
}
