import React from 'react'

export default function CallIcon({style={}, className=''}) {
    return (<svg style={style} className={className} viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M916 5105 c-81 -21 -164 -67 -249 -140 -94 -81 -431 -424 -486 -495
            -223 -285 -227 -728 -11 -1300 263 -699 756 -1407 1380 -1982 860 -793 2012
            -1306 2605 -1162 88 21 234 89 301 140 68 51 520 515 562 576 78 113 108 264
            78 392 -34 143 -45 156 -480 592 -439 439 -453 450 -598 480 -115 24 -232 3
            -345 -62 -26 -16 -150 -129 -275 -253 l-227 -224 -73 38 c-179 93 -311 182
            -508 344 -202 166 -484 457 -640 661 -113 148 -279 421 -280 458 0 7 78 89
            173 182 200 195 279 286 324 372 56 109 67 247 28 361 -39 116 -77 160 -459
            543 -378 378 -436 428 -544 465 -75 27 -199 33 -276 14z m232 -281 c28 -16
            188 -167 403 -383 389 -390 404 -408 403 -511 0 -91 -24 -123 -285 -384 -132
            -131 -247 -252 -255 -269 -12 -21 -15 -50 -12 -99 3 -60 11 -83 72 -204 138
            -274 320 -515 621 -823 299 -306 597 -534 904 -692 109 -56 130 -64 180 -63
            34 0 70 7 91 18 19 10 143 126 275 257 170 169 253 244 285 258 60 27 138 27
            193 0 60 -30 767 -736 798 -798 17 -33 24 -63 24 -106 0 -103 -21 -131 -305
            -416 -281 -282 -307 -301 -457 -335 -145 -32 -388 -6 -634 67 -375 111 -879
            377 -1294 683 -799 588 -1485 1487 -1770 2318 -136 395 -151 678 -47 888 32
            67 62 101 270 312 128 130 251 248 272 263 90 62 182 69 268 19z"/>
        </g>
    </svg>)
}

