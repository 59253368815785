import './PersonalDataCRMManager.css'
import React from 'react'
import { useConfiguration } from '../../../../../configuration'
import PersonalDataCRMManagerModal from './PersonalDataCRMManagerModal/PersonalDataCRMManagerModal'
import { useApp } from '../../../../../../../layout/App'


export default function PersonalDataCRMManager() {
	const { setModalProps } = useConfiguration()
	const { councils } = useApp()

	return (
		<div>
			<div className='CRMManager-Header'>
                <span className='CRMManager-Header-Icon'>C</span>
                <p className='CRMManager-Header-Title'>Conselho</p>
            </div>

			<div className='CRMManager-TableBox'>
				<table className='CRMManager-Table'>
					<thead className='CRMManager-TableHead'>
						<tr className='CRMManager-TableRow'>
							<td className='CRMManager-TableCell'>Número</td>
							<td className='CRMManager-TableCell'>UF</td>
						</tr>
					</thead>
					<tbody className='CRMManager-TableBody'>
						{councils.map((council, index) => (
							<tr key={index} className='CRMManager-TableRow'>
								<td className='CRMManager-TableCell'>{council.nu_crm}</td>
								<td className='CRMManager-TableCell'>{council.uf_crm}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>


			<div className='CRMManager-BtnBox'>
				<div className='CRMManager-Btn'
					onClick={() =>
						setModalProps(prev => ({
							...prev,
							open: true,
							title: 'Conselho profissional',
							content: <PersonalDataCRMManagerModal councils={councils} />,
							dismissText: '',
						}))
					}
					>
					<b>Editar dados</b>
				</div>
			</div>
		</div>
	)
}
