import React from 'react'

export function ContractIcon({style={}}) {
    return <svg style={style} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M361 5109 c-172 -34 -318 -182 -351 -358 -14 -74 -14 -4308 0 -4382
            34 -180 179 -325 359 -359 74 -14 3088 -14 3162 0 180 34 325 179 359 359 6
            33 10 313 10 735 l0 680 573 576 c530 532 575 580 601 638 58 133 57 259 -3
            388 -80 170 -283 278 -466 247 -140 -24 -174 -49 -452 -326 l-253 -251 0 611
            c0 366 -4 632 -10 664 -23 125 -52 163 -334 445 -282 282 -320 311 -445 334
            -67 12 -2686 12 -2750 -1z m2339 -680 c0 -387 0 -392 22 -431 44 -78 45 -78
            488 -78 l390 0 0 -583 0 -582 -340 -340 -340 -340 -115 -346 c-63 -190 -115
            -359 -115 -375 1 -69 76 -144 145 -144 48 0 714 226 742 251 l23 22 0 -532 c0
            -513 -1 -533 -20 -571 -13 -26 -34 -47 -60 -60 -39 -20 -56 -20 -1570 -20
            -1483 0 -1532 1 -1568 19 -22 11 -46 35 -60 59 l-22 40 0 2143 0 2144 24 39
            c49 81 -51 75 1234 76 l1142 0 0 -391z m377 381 c37 -14 491 -466 509 -507 8
            -18 14 -44 14 -58 l0 -25 -300 0 -300 0 0 300 0 300 24 0 c14 0 37 -5 53 -10z
            m1663 -1490 c43 -22 80 -80 80 -126 0 -48 -37 -107 -117 -186 l-78 -78 -108
            108 -107 107 82 82 c46 45 97 89 113 97 41 22 88 20 135 -4z m-690 -965 l-360
            -360 -107 108 -108 107 360 360 360 360 107 -107 108 -108 -360 -360z m-680
            -470 l105 -105 -40 -39 c-34 -33 -62 -46 -194 -90 -85 -28 -156 -49 -158 -48
            -1 2 20 73 48 158 42 126 58 161 87 192 20 20 39 37 42 37 3 0 52 -47 110
            -105z"/>
            <path d="M681 3601 c-42 -22 -81 -85 -81 -130 0 -48 38 -110 82 -132 36 -18
            77 -19 1118 -19 1063 0 1081 0 1120 20 45 23 80 80 80 130 0 50 -35 107 -80
            130 -39 20 -56 20 -1122 20 -1023 -1 -1085 -2 -1117 -19z"/>
            <path d="M681 3001 c-42 -22 -81 -85 -81 -130 0 -48 38 -110 82 -132 36 -18
            71 -19 818 -19 763 0 782 0 820 20 45 23 80 80 80 130 0 50 -35 107 -80 130
            -38 20 -57 20 -822 20 -735 -1 -785 -2 -817 -19z"/>
            <path d="M681 2401 c-42 -22 -81 -85 -81 -130 0 -48 38 -110 82 -132 36 -18
            71 -19 818 -19 763 0 782 0 820 20 45 23 80 80 80 130 0 50 -35 107 -80 130
            -38 20 -57 20 -822 20 -735 -1 -785 -2 -817 -19z"/>
            <path d="M681 1801 c-42 -22 -81 -85 -81 -130 0 -48 38 -110 82 -132 36 -18
            71 -19 818 -19 763 0 782 0 820 20 45 23 80 80 80 130 0 50 -35 107 -80 130
            -38 20 -57 20 -822 20 -735 -1 -785 -2 -817 -19z"/>
            <path d="M1881 881 c-42 -22 -81 -85 -81 -130 0 -48 38 -110 82 -132 35 -18
            66 -19 518 -19 462 0 482 1 520 20 45 23 80 80 80 130 0 50 -35 107 -80 130
            -38 19 -57 20 -522 20 -446 -1 -486 -2 -517 -19z"/>
        </g>
    </svg>
}
