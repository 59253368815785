import React, { useEffect, useState } from 'react'
import Loading from '../loading/loading'

import './tabs.css'

export default function Tabs({children, initSelected=null}) {
    
    const [selected, setSelected] = useState(initSelected)

    useEffect(() => {
        if(children && !selected)
            setSelected(children[0].props.id)

            // eslint-disable-next-line
    }, [children])

    if(!Array.isArray(children))
        return 'Erro: O componente tabs necessita de mais de uma tab'

    const getSelected = () => {
        for(let tab of children){
            if(tab?.props?.id === selected)
                return tab
        }
    }

    if(!selected)
        return <Loading></Loading>

    return <div className='tabs'>
        <div className='tabs-headers'>
            {children.map((tab, i) => {
                if (!tab) return null
                return <div key={`tab-${i}`}
                    className={`tab-name ${getSelected().props.id === tab.props.id ? 'selected' : ''}`}
                    onClick={() => setSelected(tab.props.id)}
                >
                    {tab.props.name}
                </div>
            })}
        </div>
        <div className='tabs-content'>
            {getSelected()}
        </div>
    </div>
}