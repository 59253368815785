import './PatientActivateModal.css'
import React from 'react'
import Modal from '../../../../../../newcomponents/modal/modal.js'

export default function PatientActivateModal({show, activateHandler, patient}) {

    if(!patient) return null

    return <Modal
        open={show}
        title='Ativar usuário'
        dismissText='Não, cancelar'
        confirmText='Sim, Ativar!'
        dismissFn={activateHandler}
        content={
            <div className='activate-user-modal'>
                <div className='activate-user-warning'>
                    Atenção!
                </div>

                A conta do paciente <b>{patient.name}</b> será ativada, permitindo o uso do sistema.

                <div className='activate-user-confirmation'>
                    Deseja ativar o usuário?
                </div>
            </div>
        }
    />

}