import './MarkTimeAsUnavailable.css'
import React, { useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { patchUnvailableEvents } from "../../../../../../http";
import { useBookingAppointmentCursor } from "../../../../BookingAppointmentCursor";
import { AddAlert, AlertType, MaskDataHora } from "../../../../../../../../../../component";
import NewButton, { ButtonModel } from "../../../../../../../../../../newcomponents/button/button";
import { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal';
import { loaded, loading } from '../../../../../../../../../../layout/redux/AppActions';

export default function MarkTimeAsUnavailable({ appointments=[] }) {
    const dispatch = useDispatch()
    const textareaRef = useRef(null)
    const {
        updateSchedule,
        health_place_user__user__person, 
        setModalInfo,
        setSelectedTimesCursor,
    } = useBookingAppointmentCursor()
    const [message, setMessage] = useState('')

    const handleSubmit = async () => {
        dispatch(loading())
        try {
            let payload = {
                'event_ids': appointments.map(({pk}) => pk),
                'message': message,
            }
            await patchUnvailableEvents(health_place_user__user__person, payload)
            await updateSchedule()
            setSelectedTimesCursor([])
            dispatch(AddAlert('Agendamento', 'Horário(s) bloqueado(s) com sucesso!', AlertType.SUCCESS))
            setModalInfo(MODAL_INIT_PROPS)
        } catch (err) {
            dispatch(AddAlert('Agendamento', 'Falha ao bloquear horário(s) de atendimento(s)', AlertType.ERROR))
            console.error('MarkTimeAsUnavailable ~ handleSubmit ~ ', err)
        }
        dispatch(loaded())
    }

    return <div className="MarkTimeAsUnavailable">
        <p>Tem certeza que deseja mover os atendimentos selecionados para <b>bloqueado</b>?</p>

        <div className='MarkTimeAsUnavailable-TimeRow'>
        {
            appointments.map(appointment => {
                const startHourTime = MaskDataHora(appointment?.fields?.start || appointment?.start, 'DD/MMM/AAAA', '-');
                const endHourTime = MaskDataHora(appointment?.fields?.end || appointment?.end, 'DD/MMM/AAAA', '-');
                return <div>
                    <b>{startHourTime.data} | {startHourTime?.hora?.slice(0, 5) || '-'} - {endHourTime?.hora?.slice(0, 5) || '-'}</b>
                </div>
            })
        }
        </div>
        <div className='MarkTimeAsUnavailable-Box'>
            <p>Justificativa:</p>
            <div className='MarkTimeAsUnavailable-Box-Justification'>
                <textarea
                    ref={textareaRef}
                    id='justification'
                    placeholder='Motivo de bloqueio (opcional)'
                    className='MarkTimeAsUnavailable-Box-Justification-TextEditor'
                    value={message}
                    onChange={event => setMessage(event.target.value)}
                    rows='5'
                />
            </div>
        </div>
        <div className='MarkTimeAsUnavailable-BtnBox'>
            <NewButton 
                label='Cancelar'
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
                model={ButtonModel.SECONDARY}
            />
            <NewButton 
                label='Salvar'
                onClick={handleSubmit}
            />
        </div>
    </div>
}