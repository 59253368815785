import './EditPatient.css'
import React, { useRef, useState } from "react"
import { AddAlert, AlertType, Input, MaskCpf, MaskRG, MaskSUSCard, MaskTelefone, RadioButton } from "../../../../component"
import { useDispatch } from 'react-redux'
import api from '../../../../helpers/api'
import { loaded, loading } from '../../../../layout/redux/AppActions'
import Button, { ButtonModel } from '../../../../newcomponents/button/button'
import NewSelect from '../../../../newcomponents/form/select/select'
import { getYearsStart1900, getYearsStart1900Plus30 } from '../../../../utils/getYearsStart1900'
import { PatientRequest, strpPerson, validateDataBeforeSubmit } from '../../../pacientes/subcomponents/NewPatient/helpers'
import Modal, { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal'
import { getCareersList } from '../../../pacientes/http'
import isOfLegalAge from '../../../../validators/isOfLegalAge'
import PatientExistentHandler from '../../../pacientes/subcomponents/NewPatient/PatientExistentHandler/PatientExistentHandler'
import PatientExistentHandlerSameHealthPlace from '../../../pacientes/subcomponents/NewPatient/PatientExistentHandlerSameHealthPlace/PatientExistentHandlerSameHealthPlace'
import { EVENT_PATIENT_REGISTERED_CHANGE } from '../events'

const {
    SEX_CHOICES, 
    MARITAL_STATUS_CHOICES, 
    RACES_CHOICES, 
    KINSHIP_CHOICES,
    GENDER_CHOICES,
    NATIONALITY_CHOICES,
    COUNTRY_CHOICES
} = require('../../../../component/form/select/options.json')

const defaultOptions = require('../../../../component/form/select/options.json')


export default function EditPatient({ setModalInfo=() => null, initHoldingPatient=null, fetchHoldingPatientData=async () => null  }) {
    const dispatch = useDispatch()
    const searchTimeoutCareer = useRef(null)
    const [careerOptions, setCareerOptions] = useState([])
    const [editingPatient, setEditingPatient] = useState(strpPerson(initHoldingPatient))
    const [errors, setErrors] = useState({})
    const [secondaryModalInfo, setSecondaryModalInfo] = useState(MODAL_INIT_PROPS)

    //useEffect(() => {
    //    fetchCareerOptions({ offset: 0 })
    //}, [])

    async function handlePatientSubmission(editingPatient, errors, successCallback, forcedSubmission=false) {
        let payload = new PatientRequest(editingPatient);
        let thereArentErrors = Object.keys(errors).length === 0;
    
        if (payload.isValid() && thereArentErrors) {
            dispatch(loading());
            try {
                const res = await api.post(`patient/edit_patient/${initHoldingPatient?.id}/`, payload.formatPayload(forcedSubmission))
                await fetchHoldingPatientData()
                const eventPatientRegisteredChange = new Event(EVENT_PATIENT_REGISTERED_CHANGE)
                window.dispatchEvent(eventPatientRegisteredChange)
                dispatch(AddAlert('Editar paciente', 'Paciente salvo com sucesso', AlertType.SUCCESS))
                successCallback(res);
            } catch (err) {
                if (['cpf already registered','rg already registered', 'similar patient already registered'].includes(err?.response?.data?.error)) {
                    setSecondaryModalInfo(prev => ({ 
                        ...prev, 
                        open: true,
                        title: 'Paciete já existente',
                        content: <PatientExistentHandler 
                            patientExistentQuestion={err?.response?.data} 
                            document={payload.document} 
                            documentType={payload.document_type}
                            setSecondaryModalInfo={setSecondaryModalInfo}
                        />,
                        confirmText: ''
                    }))
                } else if (['cpf already registered in health_place','rg already registered in health_place'].includes(err?.response?.data?.error)) {
                    setSecondaryModalInfo(prev => ({
                        ...prev,
                        open: true,
                        content: <PatientExistentHandlerSameHealthPlace 
                            patient_name={err?.response?.data?.patient_name} 
                            patient_id={err?.response?.data?.patient_id}
                            document_type={err?.response?.data?.patient_document_type}
                            document={err?.response?.data?.patient_document}
                            patient_birthday={err?.response?.data?.patient_birthday}
                            patient_mother_name={err?.response?.data?.patient_mother_name}
                            setSecondaryModalInfo={setSecondaryModalInfo}
                            handlePatientSubmission={handlePatientSubmission}
                            inputValues={editingPatient}
                            forcedSubmissionAllowed={false}
                            callback={successCallback}
                        />
                    }))
                } else if (err?.response?.data?.error === 'similar patient already registered in health_place') {
                    setSecondaryModalInfo(prev => ({
                        ...prev,
                        open: true,
                        content: <PatientExistentHandlerSameHealthPlace 
                            patient_name={err?.response?.data?.patient_name} 
                            patient_id={err?.response?.data?.patient_id}
                            document_type={err?.response?.data?.patient_document_type}
                            document={err?.response?.data?.patient_document}
                            patient_birthday={err?.response?.data?.patient_birthday}
                            patient_mother_name={err?.response?.data?.patient_mother_name}
                            setSecondaryModalInfo={setSecondaryModalInfo}
                            handlePatientSubmission={handlePatientSubmission}
                            inputValues={editingPatient}
                            forcedSubmissionAllowed={true}
                            callback={successCallback}
                        />
                    }))
                } else {
                    if (err?.response?.data?.field){
                        setErrors(prevErrors => ({...prevErrors, [err?.response?.data?.field]: {error: true, message: err?.response?.data?.str}}))
                    } else {
                        dispatch(AddAlert('Editar paciente', err?.response?.data?.str || 'Falha ao salvar paciente, revise os dados', AlertType.ERROR));
                        console.error('addNewBird', err);
                    }
                }
            }
            dispatch(loaded());
        } else {
            let errors = payload.getErrors();
            setErrors(prevData => ({ ...prevData, ...errors }));
        }
    }

    const handleSubmit = async () => {
        handlePatientSubmission(editingPatient, errors, () => {
            setModalInfo(MODAL_INIT_PROPS);
        });
    };

    const handleSelect = (event) => {
        setEditingPatient(prevData => ({ ...prevData, [`${event.target.id}`]: event.target.selected }))
        let error = validateDataBeforeSubmit({...editingPatient, [`${event.target.id}`]: event.target.selected}, errors)
        setErrors(error);
    }

    const handleSelectCareer = (event) => {
        let error = validateDataBeforeSubmit(editingPatient)
        setErrors(prevData => ({ ...prevData, ...error }));          
        setEditingPatient(prevData => ({ ...prevData, career: careerOptions?.find(career => career?.id === event?.target?.selected) }))
        fetchCareerOptions({ offset: 0 })
    }

    const handleChange = (event) => {
        setEditingPatient(prevData => ({ ...prevData, [event.target.name]: event.target.value }))
        let error = validateDataBeforeSubmit({...editingPatient, [event.target.name]: event.target.value}, errors)
        setErrors(error);
    }

    const handleSelectRadioButton = (event) => {
        if (event.target.name === 'document_type') {
            setEditingPatient(prev => ({ ...prev, document: '', [event.target.name]: event?.target?.value?.id }))
        } else {
            setEditingPatient(prev => ({ ...prev, [event.target.name]: event?.target?.value?.id }))
        }
        let error = validateDataBeforeSubmit({...editingPatient, [event.target.name]: event?.target?.value?.id}, errors)
        setErrors(error);
    }

    const fetchCareerOptions = async (params={}) => {
        params = { ...params, limit: 50 }
    
        try {
          let res = await getCareersList(params)
    
          setCareerOptions(res.data.results)
        } catch(err) {
            console.error('fetchSpecialtiesOptions ~ ', err)
        }
    }

    const handleSearchCarrer = (event) => {
        if (searchTimeoutCareer.current) clearTimeout(searchTimeoutCareer.current)
    
        searchTimeoutCareer.current = setTimeout(() => {
            fetchCareerOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }
    
    return <div className='EditPatient'>
        <Modal {...secondaryModalInfo} dismissFn={() => setSecondaryModalInfo(MODAL_INIT_PROPS)} />
        <div className='EditPatient-PersonalInfo'>
            <div className='EditPatient-Subtitle-Header'>
                <div className='EditPatient-Subtitle-Header-Icon EditPatient-Subtitle-Header-Icon-Blue'>D</div>
                <div className='EditPatient-Subtitle-Header-Title'>Dados Pessoais</div>
            </div>
            <div className='EditPatient-PersonalInfo-NameBirthdayCPFContainer'>
                <div className='EditPatient-Input'>
                    <span><b className='NewPatinet-Asterisk'>*</b> Nome Completo</span>
                    <Input
                        name='name'
                        value={editingPatient?.name}
                        action={handleChange}
                        errors={{
                            error: {name: errors?.name?.error},
                            message: errors?.name?.message,
                          }}
                        maxLength='60'
                    />
                </div>
                <div>
                    <span>Mesmo nome social?</span>
                    <RadioButton
						name='is_it_the_same_social_name'
						action={handleSelectRadioButton}
						checked={editingPatient?.is_it_the_same_social_name}
						options={defaultOptions.ONLY_YES_OR_NO}
					/>
                </div>
                <div>
                    <span><b className='NewPatinet-Asterisk'>*</b> Data de Nascimento</span>
                    <div className='EditPatient-PersonalInfo-NameBirthdayCPFContainer-BirthdaySelect EditPatient-Select'>
                        <NewSelect
                            id='birthday_day'
                            defaultText='Dia'
                            options={defaultOptions.days}
                            onSelect={handleSelect}
                            selected={editingPatient?.birthday_day}
                            error={errors?.birthday_day?.error}
                        />
                        <NewSelect
                            id='birthday_month'
                            defaultText='Mês'
                            options={defaultOptions.months}
                            onSelect={handleSelect}
                            selected={editingPatient?.birthday_month}
                            error={errors?.birthday_month?.error}
                        />
                        <NewSelect 
                            id='birthday_year'
                            defaultText='Ano'
                            options={getYearsStart1900()}
                            onSelect={handleSelect}
                            selected={editingPatient?.birthday_year}
                            error={errors.birthday_year?.error}
                        />
                    </div>
                </div>
                <div>
                    <span>Tipo de Documento</span>
                    <RadioButton
						name='document_type'
						action={handleSelectRadioButton}
						checked={editingPatient?.document_type}
						options={defaultOptions.DOCUMENT_TYPE_CHOICES}
					/>
                </div>
                <div className='EditPatient-Input'>
                    {
                        editingPatient?.birthday_day && 
                        editingPatient?.birthday_month && 
                        editingPatient?.birthday_year && 
                        !isOfLegalAge(`${editingPatient?.birthday_year}-${editingPatient?.birthday_month}-${editingPatient?.birthday_day}`)
                        ? (
                            editingPatient?.document_type === 'CPF'
                            ? <span>CPF</span>
                            : <span>RG</span>
                        )
                        : (
                            editingPatient?.document_type === 'CPF'
                            ? <span><b className='NewPatinet-Asterisk'>*</b> CPF</span>
                            : <span><b className='NewPatinet-Asterisk'>*</b> RG</span>
                        )
                    }
                    <Input 
                        name='document'
                        value={editingPatient.document_type === 'RG' ? editingPatient?.document : MaskCpf(editingPatient?.document)}
                        maxLength={editingPatient.document_type === 'RG' ? '20' :'14'}
                        action={handleChange}
                        errors={{
                            error: {document: errors?.document?.error},
                            message: errors?.document?.message,
                          }}                       />
                </div>
            </div>
            <div className={editingPatient?.is_it_the_same_social_name === 'NO' ? 'EditPatient-PersonalInfo-SocialNameCNSMotherNameEmailContact' : 'EditPatient-PersonalInfo-CNSMotherNameEmailContact'}>
                {
                    editingPatient?.is_it_the_same_social_name === 'NO'
                    ? <div className='EditPatient-Input'>
                        <span>Nome Social</span>
                        <Input
                            name='social_name'
                            value={editingPatient?.social_name}
                            action={handleChange}
                            maxLength='60'
                        />
                    </div>
                    : null
                }
                <div className='EditPatient-Input'>
                    <span>Cartão Nac. de Saúde (CNS)</span>
                    <Input
                        name='sus_card_number'
                        value={editingPatient?.sus_card_number ? MaskSUSCard(editingPatient.sus_card_number) : ''}
                        maxLength='18' 
                        action={handleChange}
                    />
                </div>
                <div className='EditPatient-Input'>
                    <span>Nome da Mãe</span>
                    <Input
                        name='mother_name'
                        value={editingPatient?.mother_name}
                        action={handleChange}
                        maxLength='60'
                    />
                </div>
                <div>
                    <span>Tipo de Telefone</span>
                    <RadioButton
						name='phone_type'
						action={handleSelectRadioButton}
						checked={editingPatient?.phone_type}
						options={defaultOptions.PHONE_TYPES_CHOICES}
					/>
                </div>
                <div className='EditPatient-Input'>
                    <span>Telefone {defaultOptions?.PHONE_TYPES_CHOICES?.find(instance => instance?.id === editingPatient?.phone_type)?.name}</span>
                    <Input
                        name='contact_phone'
                        value={MaskTelefone(editingPatient?.contact_phone)}
                        maxLength='15'
                        action={handleChange}
                        errors={{
                            error: {contact_phone: errors?.contact_phone?.error},
                            message: errors?.contact_phone?.message,
                        }}                    />
                </div>
            </div>
            <div className='EditPatient-PersonalInfo-NationalitySexGenderMaritalStatusGender'>
                <div className='EditPatient-Select'>
                    <span><b className='NewPatinet-Asterisk'>*</b> Nacionalidade</span>
                    <NewSelect
                        id='nationality'
                        options={NATIONALITY_CHOICES}
                        onSelect={handleSelect}
                        selected={editingPatient?.nationality}
                        error={errors?.nationality?.error}
                        canBeEmpty={false}
                    />
                </div>
                <div className='EditPatient-Select'>
                    <span><b className='NewPatinet-Asterisk'>*</b> Sexo</span>
                    <NewSelect
                        id='sex'
                        options={SEX_CHOICES}
                        onSelect={handleSelect}
                        selected={editingPatient?.sex}
                        error={errors?.sex?.error}
                        canBeEmpty={false}
                    />
                </div>
                <div className='EditPatient-Select'>
                    <span>Raça</span>
                    <NewSelect 
                        id='race'
                        options={RACES_CHOICES}
                        onSelect={handleSelect}
                        selected={editingPatient?.race}
                        defaultText='Sem informação'
                    />
                </div>
                <div className='EditPatient-Select'>
                    <span>Estado Civil</span>
                    <NewSelect 
                        id='marital_status'
                        options={MARITAL_STATUS_CHOICES}
                        onSelect={handleSelect}
                        selected={editingPatient?.marital_status}
                        defaultText='Sem informação'
                    />
                </div>
                <div className='EditPatient-Select'>
                    <span>Gênero</span>
                    <NewSelect 
                        id='gender'
                        options={GENDER_CHOICES}
                        onSelect={handleSelect}
                        selected={editingPatient?.gender}
                        defaultText='Sem informação'
                    />
                </div>
                <div className='EditPatient-Input'>
                    <span>E-mail</span>
                    <Input
                        name='email'
                        value={editingPatient?.email}
                        action={handleChange}
                        errors={{
                            error: {email: errors?.email?.error},
                            message: errors?.email?.message,
                          }}                     
                    />
                </div>
            </div>
            {
                editingPatient?.nationality === 'FOREIGNER'
                ? <div>
                    <div className='EditPatient-PersonalInfo-NationalityInfo-FirstRow'>
                        <div className='EditPatient-Select'>
                            <span>Data de Naturalização</span>
                            <div className='EditPatient-PersonalInfo-NameBirthdayCPFContainer-BirthdaySelect'>
                                <NewSelect
                                    id='passport_naturalization_day'
                                    defaultText='Dia'
                                    options={defaultOptions.days}
                                    onSelect={handleSelect}
                                    selected={editingPatient?.passport_naturalization_day}
                                    error={errors?.passport_naturalization_day?.error}
                                />
                                <NewSelect
                                    id='passport_naturalization_month'
                                    defaultText='Mês'
                                    options={defaultOptions.months}
                                    onSelect={handleSelect}
                                    selected={editingPatient?.passport_naturalization_month}
                                    error={errors?.passport_naturalization_month?.error}
                                />
                                <NewSelect 
                                    id='passport_naturalization_year'
                                    defaultText='Ano'
                                    options={getYearsStart1900()}
                                    onSelect={handleSelect}
                                    selected={editingPatient?.passport_naturalization_year}
                                    error={errors?.passport_naturalization_day_year?.error}
                                />
                            </div>
                        </div>
                        <div className='EditPatient-Select'>
                            <span>País de Nascimento</span>
                            <NewSelect 
                                id='passport_country_of_birth'
                                options={COUNTRY_CHOICES}
                                onSelect={handleSelect}
                                selected={editingPatient?.passport_country_of_birth}
                            />
                        </div>
                        <div className='EditPatient-Input'>
                            <span>Nº de Passaporte</span>
                            <Input
                                name='passport_number'
                                value={editingPatient?.passport_number}
                                maxLength='15'
                                action={handleChange}
                            />
                        </div>
                    </div>
                    <div className='EditPatient-PersonalInfo-NationalityInfo-SecondRow'>
                        <div className='EditPatient-Select'>
                            <span>País Emissor</span>
                            <NewSelect 
                                id='passport_issuing_country'
                                options={COUNTRY_CHOICES}
                                onSelect={handleSelect}
                                selected={editingPatient?.passport_issuing_country}
                            />
                        </div>
                        <div className='EditPatient-Select'>
                            <span>Data de Emissão</span>
                            <div className='EditPatient-PersonalInfo-NameBirthdayCPFContainer-BirthdaySelect'>
                                <NewSelect
                                    id='passport_issuance_day'
                                    defaultText='Dia'
                                    options={defaultOptions.days}
                                    onSelect={handleSelect}
                                    selected={editingPatient?.passport_issuance_day}
                                    error={errors?.passport_issuance_day?.error}
                                />
                                <NewSelect
                                    id='passport_issuance_month'
                                    defaultText='Mês'
                                    options={defaultOptions.months}
                                    onSelect={handleSelect}
                                    selected={editingPatient?.passport_issuance_month}
                                    error={errors?.passport_issuance_month?.error}
                                />
                                <NewSelect 
                                    id='passport_issuance_year'
                                    defaultText='Ano'
                                    options={getYearsStart1900()}
                                    onSelect={handleSelect}
                                    selected={editingPatient?.passport_issuance_year}
                                    error={errors?.passport_issuance_year?.error}
                                />
                            </div>
                        </div>
                        <div className='EditPatient-Select'>
                            <span>Data de Validade</span>
                            <div className='EditPatient-PersonalInfo-NameBirthdayCPFContainer-BirthdaySelect'>
                                <NewSelect
                                    id='passport_expiration_day'
                                    defaultText='Dia'
                                    options={defaultOptions.days}
                                    onSelect={handleSelect}
                                    selected={editingPatient?.passport_expiration_day}
                                />
                                <NewSelect
                                    id='passport_expiration_month'
                                    defaultText='Mês'
                                    options={defaultOptions.months}
                                    onSelect={handleSelect}
                                    selected={editingPatient?.passport_expiration_month}
                                />
                                <NewSelect 
                                    id='passport_expiration_year'
                                    defaultText='Ano'
                                    options={getYearsStart1900Plus30()}
                                    onSelect={handleSelect}
                                    selected={editingPatient?.passport_expiration_year}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }
        </div>
        <div className='EditPatient-FamilyInfo'>
            <div className='EditPatient-Subtitle-Header'>
                <div className='EditPatient-Subtitle-Header-Icon'>R</div>
                <div className='EditPatient-Subtitle-Header-Title'>Informações do responsável / Contato de Emergência</div>
            </div>
            <div className='EditPatient-FamilyInfo-RowInfo'>
                <div className='EditPatient-Input'>
                    <span>Nome Completo </span>
                    <Input
                        name='person_responsible_name'
                        value={editingPatient?.person_responsible_name}
                        maxLength='60'
                        action={handleChange}
                        errors={{
                            error: {person_responsible_name: errors?.person_responsible_name?.error},
                            message: errors?.person_responsible_name?.message,
                          }}  
                    />
                </div>
                <div className='EditPatient-Input'>
                    <span>Telefone</span>
                    <Input
                        name='person_responsible_contact_phone'
                        value={MaskTelefone(editingPatient?.person_responsible_contact_phone)}
                        maxLength='15'
                        action={handleChange}
                    />
                </div>
                <div className='EditPatient-Select'>
                    <span>Parentesco</span>
                    <NewSelect 
                        id='person_responsible_kinship'
                        options={KINSHIP_CHOICES}
                        onSelect={handleSelect}
                        selected={editingPatient?.person_responsible_kinship}
                        defaultText='Sem informação'
                    />
                </div>
                <div className='EditPatient-Input'>
                    {
                        editingPatient?.birthday_day && 
                        editingPatient?.birthday_month && 
                        editingPatient?.birthday_year && 
                        !isOfLegalAge(`${editingPatient?.birthday_year}-${editingPatient?.birthday_month}-${editingPatient?.birthday_day}`)
                        ? <span><b className='NewPatinet-Asterisk'>*</b> CPF do Responsável</span>
                        : <span>CPF do Responsável</span>
                    }
                    <Input 
                        name='person_responsible_cpf'
                        value={MaskCpf(editingPatient?.person_responsible_cpf)}
                        maxLength='14'
                        action={handleChange}
                        errors={{
                            error: {person_responsible_cpf: errors?.person_responsible_cpf?.error},
                            message: errors?.person_responsible_cpf?.message,
                          }}  
                    />
                </div>
            </div>
        </div>

        <div className='EditPatient-Btn-Box'>
            <div>
                <p><span className='EditPatient-Btn-Box-SpanError'><b className='NewPatinet-Asterisk'>*</b> Preenchimento Obrigatório</span></p>
            </div>
            <div className='EditPatient-BtnBox-RightBox'>
                {
                    Object.keys(errors).length > 0 ? 
                    <div>
                        <p><span className='EditPatient-Btn-Box-SpanError'>Para poder salvar, favor corrigir os campos em vermelho!</span></p>
                    </div>
                    : null
                }
                <Button 
                    onClick={handleSubmit}
                    model={ButtonModel.PRIMARY}
                    label='Salvar'
                    disabled={Object.keys(errors).length === 0 ? false : true}
                />
            </div>
        </div>
    </div>
}
