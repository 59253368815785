import api from "../../../../../../../../helpers/api";
import getParams from "../../../../../../../../utils/getParams";

export async function getInventoryMaterialCategories(params={}) {
    params = getParams({ ...params, limit: 200 })
    const url = `/inventory/category/${params}`
    let response = api.get(url)

    return response
}

export async function postInventoryMaterialCategory(payload={}) {
    const url = '/inventory/category/'
    let response = api.post(url, payload)
    
    return response
}

export async function patchInventoryMaterialCategory(id, payload={}) {
    const url = `/inventory/category/${id}/`
    let response = api.patch(url, payload)

    return response
}

export async function deleteInventoryMaterialCategory(id) {
    const url = `inventory/category/${id}/`
    let response = api.delete(url)

    return response
}