import React, { useEffect } from 'react';
import './dropdownFlag.css';

const locales = ["pt-BR", "en-GB"];

/*
    sample:
    const [selectedLanguage, setSelectedLanguage] = useState(locales[0]);
*/

const getFlagSrc = (countryCode) => {
    return /^[A-Z]{2}$/.test(countryCode)
      ? `https://flagsapi.com/${countryCode.toUpperCase()}/shiny/64.png`
      : "";
};

const DropdownFlag = ({ selectedLanguage='pt-BR', setSelectedLanguage=() => null }) => {
  
    useEffect(() => {
        const browserLang = new Intl.Locale(navigator.language).language;
        const matchingLocale = locales.find((locale) => new Intl.Locale(locale).language === browserLang);
        if (matchingLocale) setSelectedLanguage(matchingLocale);
    }, []);

  return (
    <div className="dropdownFlag" tabIndex="0">
      <button id="dropdownFlag-btn">
        <img src={getFlagSrc(new Intl.Locale(selectedLanguage).region)} alt="" />
      </button>
      <ul className="dropdownFlag-content" id="dropdownFlag-content">
        {locales.filter((loc) => loc !== selectedLanguage).map((otherLocale) => (
          <li key={otherLocale} onClick={() => setSelectedLanguage(otherLocale)}>
            <img src={getFlagSrc(new Intl.Locale(otherLocale).region)} alt="" />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownFlag;
