import { adicionaZero } from "../../../../../../../../component";
import { formatScheduleDateWithTimezone } from "../../../../../../helpers";

const dayNames = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

export function generateScheduledEvents(schedulingInfo = {}) {
    const { start_day, start_month, start_year, end_day, end_month, end_year, week_days, repeat_frequency, start_hour, end_hour, duration, gapInterval } = schedulingInfo;

    const startDate = new Date(start_year, start_month - 1, start_day);
    const endDate = new Date(end_year, end_month - 1, end_day);
    const repeatFrequencyWeeks = parseInt(repeat_frequency, 10);
    let events = [];

    let currentDate = startDate;

    while (currentDate <= endDate) {
        const dayOfWeek = dayNames[currentDate.getDay()];

        // Check if the current day is one of the selected weekdays
        if (week_days[dayOfWeek]) {
            let currentHour = parseInt(start_hour.split(":")[0], 10);
            let currentMinute = parseInt(start_hour.split(":")[1], 10);
            const endHour = parseInt(end_hour.split(":")[0], 10);
            const endMinute = parseInt(end_hour.split(":")[1], 10);

            while (currentHour < endHour || (currentHour === endHour && currentMinute < endMinute)) {
                let startTime = `${adicionaZero(currentHour)}:${adicionaZero(currentMinute)}`;
                let endTimeHour = currentHour;
                let endTimeMinute = currentMinute + duration;

                if (endTimeMinute >= 60) {
                    endTimeHour += Math.floor(endTimeMinute / 60);
                    endTimeMinute = endTimeMinute % 60;
                }

                if (endTimeHour > endHour || (endTimeHour === endHour && endTimeMinute > endMinute)) {
                    break;
                }

                let endTime = `${adicionaZero(endTimeHour)}:${adicionaZero(endTimeMinute)}`;

                let dataDe = formatScheduleDateWithTimezone(
                    currentDate.getFullYear(),
                    currentDate.getMonth() + 1,
                    currentDate.getDate(),
                    startTime
                );

                let dataAte = formatScheduleDateWithTimezone(
                    currentDate.getFullYear(),
                    currentDate.getMonth() + 1,
                    currentDate.getDate(),
                    endTime
                );

                events.push({
                    start: dataDe,
                    end: dataAte,
                    color_event: "", // You can set the event color or any other details here
                    description: "" // You can set the event description or any other details here
                });

                // Move to the next event time
                currentMinute += duration + gapInterval;

                if (currentMinute >= 60) {
                    currentHour += Math.floor(currentMinute / 60);
                    currentMinute = currentMinute % 60;
                }
            }
        }

        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1);

        // Skip to the next repeat cycle if the next day is outside the repeat frequency
        if ((currentDate - startDate) / (1000 * 60 * 60 * 24) % (repeatFrequencyWeeks * 7) !== 0) {
            continue;
        }
    }

    return events;
}

export function generateRecurringEvents(recurringInfo = {}) {
    console.log('recurringInfo ~ ', recurringInfo);
    const { start_day, start_month, start_year, end_day, end_month, end_year, week_days, repeat_frequency, start_hour, end_hour } = recurringInfo;

    const startDate = new Date(start_year, start_month - 1, start_day);
    const endDate = new Date(end_year, end_month - 1, end_day);
    const repeatFrequencyWeeks = parseInt(repeat_frequency, 10);
    let events = [];

    let currentDate = startDate;

    // Array of week day names corresponding to Date.getDay()
    const dayNames = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

    while (currentDate <= endDate) {
        const currentDayOfWeek = currentDate.getDay();
        const dayOfWeekName = dayNames[currentDayOfWeek];

        // Check if the current day is one of the selected weekdays
        if (week_days[dayOfWeekName]) {
            let startTime = `${adicionaZero(start_hour.split(":")[0])}:${adicionaZero(start_hour.split(":")[1])}`;
            let endTime = `${adicionaZero(end_hour.split(":")[0])}:${adicionaZero(end_hour.split(":")[1])}`;

            let dataDe = formatScheduleDateWithTimezone(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                currentDate.getDate(),
                startTime
            );

            let dataAte = formatScheduleDateWithTimezone(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                currentDate.getDate(),
                endTime
            );

            events.push({
                start: dataDe,
                end: dataAte,
                color_event: "", // You can set the event color or any other details here
                description: "" // You can set the event description or any other details here
            });
        }

        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1);

        // Once a complete week cycle has been checked, skip to the next repeat frequency cycle
        if (currentDate.getDay() === 0 && repeatFrequencyWeeks > 1) {
            currentDate.setDate(currentDate.getDate() + (repeatFrequencyWeeks - 1) * 7);
        }
    }

    console.log('events ~ ', events);
    return events;
}