import React from 'react'

export default function IcoCalendarUnblock({ style={} }) {
  return (
    <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M942 5104 c-18 -9 -43 -31 -55 -48 -20 -28 -22 -45 -25 -187 l-3
            -156 -177 -6 c-185 -6 -229 -14 -330 -66 -53 -27 -141 -115 -174 -173 -59
            -106 -61 -121 -66 -420 l-4 -278 2457 0 2457 0 -5 273 c-4 240 -7 279 -25 328
            -40 112 -115 205 -212 264 -89 54 -145 66 -336 72 l-172 6 -3 153 c-4 166 -9
            181 -73 228 -39 30 -120 29 -162 -1 -55 -39 -64 -74 -64 -237 l0 -146 -145 0
            -145 0 0 142 c0 167 -14 209 -79 246 -49 28 -93 28 -142 0 -65 -37 -79 -79
            -79 -246 l0 -142 -817 -2 -818 -3 -5 -162 c-4 -143 -7 -165 -26 -190 -43 -59
            -121 -78 -184 -44 -68 36 -74 54 -80 234 l-5 162 -142 3 -141 3 -4 154 c-3
            136 -5 157 -24 181 -49 67 -128 90 -192 58z m218 -531 c0 -163 -9 -201 -55
            -242 -71 -62 -177 -45 -222 34 -21 37 -23 53 -23 193 l0 152 150 0 150 0 0
            -137z m2518 -22 c-3 -173 -8 -187 -69 -233 -67 -50 -171 -22 -208 56 -18 37
            -21 61 -21 189 l0 147 151 0 150 0 -3 -159z m591 0 c-4 -142 -6 -163 -25 -187
            -53 -71 -130 -92 -198 -52 -67 39 -76 70 -76 245 l0 153 151 0 151 0 -3 -159z"/>
            <path d="M1531 5102 c-69 -34 -76 -55 -79 -233 l-3 -159 145 0 146 0 0 154 c0
            142 -2 155 -23 187 -26 39 -79 69 -122 69 -16 0 -45 -8 -64 -18z"/>
            <path d="M110 1958 c0 -1643 -1 -1587 56 -1699 37 -75 138 -172 214 -208 34
            -16 92 -34 128 -41 48 -7 650 -10 2097 -8 1960 3 2032 4 2079 22 81 31 136 67
            192 123 55 55 81 97 118 193 l21 55 3 1538 3 1537 -2456 0 -2455 0 0 -1512z
            m2645 1086 c460 -79 828 -416 945 -864 194 -742 -370 -1470 -1139 -1470 -426
            0 -810 226 -1025 605 -95 165 -146 364 -146 563 0 328 115 608 345 837 186
            186 405 294 685 338 65 11 254 5 335 -9z"/>
            <path d="M2375 2744 c-147 -32 -297 -112 -411 -218 -202 -187 -308 -473 -274
            -741 60 -478 484 -822 956 -774 120 12 195 33 301 84 219 106 387 303 459 540
            25 81 28 105 28 245 1 133 -2 166 -22 235 -83 289 -298 513 -582 607 -85 28
            -110 31 -240 34 -104 3 -165 -1 -215 -12z m687 -475 c55 -40 76 -127 44 -187
            -8 -15 -144 -156 -303 -314 -246 -245 -293 -287 -325 -293 -71 -13 -102 7
            -276 179 -161 159 -202 211 -202 260 0 63 51 130 110 146 70 19 104 1 224
            -119 l111 -111 215 215 c121 121 233 224 255 236 47 24 102 19 147 -12z"/>
        </g>
    </svg>
  )
}
