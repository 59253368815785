import './PreceptorshipEvaluation.css'
import React from "react";
import { useDispatch } from "react-redux";
import { useAttendance } from '../../../../../../consultation';
import { apiV2 } from '../../../../../../../../helpers';
import NewSelect from '../../../../../../../../newcomponents/form/select/select';
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import ConsultationTextField from '../../../../../../consultation-text-field';
import ReactQuill from 'react-quill';


const {
    PreceptorshipSelectOptions,
    PreceptorshipHeaders,
} = require('../../SOAPSection.json')

export default function PreceptorshipEvaluation({ options, feedbackKey }) {
    const dispatch = useDispatch()
    const {
        attendance,
        holdingPreceptorshipInstance, 
        setHoldingPreceptorshipInstance, 
        canWritePreceptorship,
    } = useAttendance()

    const handleSelect = (event, type) => {
        if (!canWritePreceptorship) return;
        setHoldingPreceptorshipInstance(prev => ({ ...prev, [event.target.id]: { ...prev[event.target.id], [type]: event.target.selected } }));
    };

    const getFeedback = async () => {

        const feedbackOptions = {
            "subjective_feedback": {
                "reason_for_consultation": "CIAP 20: " + attendance.reason_for_consultation.map(item => item.code).join(", "),
                "main_complaint": attendance.main_complaint,
                "history_of_current_illness": attendance.history_of_current_illness,
                "past_pathological_history": attendance.past_pathological_history,
            },
            "objectiveFeedback": {
                "physical_exam": attendance.physical_exam,
            },
            "assessment_feedback": {
                "related_diseases_and_problems": "CID 10: " + attendance.related_diseases_and_problems.map(item => item.code).join(", "),
                "diagnosis": attendance.diagnosis,
            },
            "plan_feedback": {
                "intervention_clinical_procedures": "CIAP 20: " + attendance.intervention_clinical_procedures.map(item => item.code).join(", "),
                "conduct": attendance.conduct,
            }
        }
        
        const gradesOptions = {
            "subjective_feedback": {
                "reason_for_consultation": holdingPreceptorshipInstance?.reason_for_consultation,
                "main_complaint": holdingPreceptorshipInstance?.main_complaint,
                "history_of_current_illness": holdingPreceptorshipInstance?.history_of_current_illness,
                "past_pathological_history": holdingPreceptorshipInstance?.past_pathological_history,
            },
            "objectiveFeedback": {
                "physical_exam": holdingPreceptorshipInstance?.physical_exam,
                "vital_sign": holdingPreceptorshipInstance?.vital_sign,
                "anthropometry": holdingPreceptorshipInstance?.anthropometry,
            },
            "assessment_feedback": {
                "related_diseases_and_problems": holdingPreceptorshipInstance?.related_diseases_and_problems,
                "diagnosis": holdingPreceptorshipInstance?.diagnosis,
            },
            "plan_feedback": {
                "intervention_clinical_procedures": holdingPreceptorshipInstance?.intervention_clinical_procedures,
                "conduct": holdingPreceptorshipInstance?.conduct,
            }
        }

        let payload = {
            "content": feedbackOptions[feedbackKey] || {}, 
            "grades": gradesOptions[feedbackKey] || {}
        }

        try {
            const response = await apiV2.post(`preceptorship/feedback/${feedbackKey}`, payload);
            setHoldingPreceptorshipInstance(prev => ({ ...prev, [feedbackKey]: response.data.message }));
        } catch (err) {
            dispatch(loaded())
            console.error(err);
        }
    };

    const handleChange = (id, value) => {
        setHoldingPreceptorshipInstance(prevData => ({ ...prevData, [`${id}`]: value }));
    };

    return (
        <div className='PreceptorshipEvaluation-EvaluationTable'>
            <table className='PreceptorshipEvaluation-Table'>
                <thead>
                    <tr>
                        {PreceptorshipHeaders.map(header => <th key={header.id}>{header.name}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {options.map((option, index) => (
                        <tr key={option.id} className={index % 2 === 1 ? 'PreceptorshipEvaluation-Table-OddRow' : ''}>
                            <td>{option.name}</td>
                            <td>
                                <NewSelect 
                                    id={option.id}
                                    onSelect={(event) => handleSelect(event, 'coherence')}
                                    options={PreceptorshipSelectOptions}
                                    selected={holdingPreceptorshipInstance[option.id]?.coherence}
                                    disabled={!canWritePreceptorship}
                                />
                            </td>
                            <td>
                                <NewSelect 
                                    id={option.id}
                                    onSelect={(event) => handleSelect(event, 'accuracy')}
                                    options={PreceptorshipSelectOptions}
                                    selected={holdingPreceptorshipInstance[option.id]?.accuracy}
                                    disabled={!canWritePreceptorship}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div>

                <ConsultationTextField
                    id={feedbackKey}
                    title='Feedback'
                    value={holdingPreceptorshipInstance[feedbackKey]}
                    handleChange={(value) => handleChange(feedbackKey, value)}
                    readOnly={!canWritePreceptorship}
                />
                <div className='PreceptorshipEvaluation-EvaluationTable-BtnBox'>
                    {
                        canWritePreceptorship && <NewButton
                            label='Feedback automático'
                            onClick={getFeedback}
                            model={ButtonModel.SECONDARY}
                        />
                    }
                </div>
            </div>
        </div>
    );
};