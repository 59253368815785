import React from 'react'
import RiskIco from '../../../../../../../../../../../../../component/icon/risk'
import { useThousandDays } from '../../../../../ThousandDaysSection'
import { AlertRowTextType } from '../../../../../../../../../../../../../component/alertRowText/alertRowText'
import { getPregnancyBackgroundResults } from '../utils'



export default function RiskBadge() {
    const {
        holdingProject,
        pregnancyBackground,
        selectedLanguage
    } = useThousandDays()

    const {
        LowPregnancyRisk,
        AveragePregnancyRisk,
        HighPregnancyRisk
    }  = require('../../../../../../../../../../../../data/translation.json')[selectedLanguage]

    const { risk_type } = getPregnancyBackgroundResults(pregnancyBackground, holdingProject.patient)

    if (!risk_type) return

    const riskStr = {
        'LOW': {
            'content': LowPregnancyRisk,
            'type': AlertRowTextType.SUCCESS
        },
        'MEDIUM': {
            'content': AveragePregnancyRisk,
            'type': AlertRowTextType.INFO
        },
        'HIGH': {
            'content': HighPregnancyRisk,
            'type': AlertRowTextType.INFO
        },
        'NONE': {
            'content': '',
            'type': ''
        }
    }

    return <div className='InitSubSection-PregnancyAge'>
        <div className='InitSubSection-PregnancyAge-Icon'>
            <RiskIco style={{ fill: '#80a6e5', fontSize: '1rem' }} />
        </div>
        <div>{ riskStr[risk_type].content }</div>
    </div>
}