import React from 'react'

export default function ListIcon({ style }) {
  return (
    <svg style={style} viewBox="0 0 512.000000 512.000000">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M272 4480 c-102 -22 -193 -96 -241 -199 -22 -47 -26 -69 -26 -141 1
            -72 5 -94 28 -142 62 -128 203 -208 341 -194 293 30 415 382 204 588 -75 73
            -201 110 -306 88z"/>
            <path d="M1360 4405 c-101 -32 -170 -129 -170 -241 0 -34 7 -78 15 -98 23 -55
            79 -111 135 -135 l49 -21 1778 2 c1678 3 1780 4 1810 21 90 49 138 130 138
            232 -1 101 -48 179 -140 228 l-40 22 -1765 2 c-1490 1 -1772 0 -1810 -12z"/>
            <path d="M250 2891 c-93 -29 -177 -101 -219 -190 -38 -81 -38 -201 0 -282 56
            -119 161 -190 294 -197 104 -5 173 19 248 88 75 69 110 147 110 250 0 62 -6
            88 -28 139 -35 78 -113 151 -190 180 -57 22 -164 27 -215 12z"/>
            <path d="M1393 2810 c-123 -25 -203 -122 -203 -250 0 -106 53 -189 150 -234
            l45 -21 1770 0 c1650 0 1772 1 1806 17 54 25 96 64 127 117 23 39 27 57 27
            121 0 64 -4 82 -27 121 -31 53 -73 92 -127 117 -34 16 -156 17 -1781 19 -960
            0 -1764 -3 -1787 -7z"/>
            <path d="M233 1300 c-80 -26 -161 -99 -200 -178 -23 -48 -27 -70 -28 -142 0
            -77 3 -92 33 -152 73 -148 229 -223 384 -183 160 40 260 168 260 330 1 235
            -225 398 -449 325z"/>
            <path d="M1335 1187 c-93 -44 -145 -126 -145 -231 0 -76 34 -151 88 -196 77
            -64 -36 -60 1897 -58 l1760 3 40 22 c92 49 139 127 140 228 0 102 -48 183
            -138 232 -30 17 -132 18 -1812 20 l-1780 3 -50 -23z"/>
        </g>
    </svg>
  )
}

