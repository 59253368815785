export function getDayClassName(cellDate, calendarCursor) {
    const isSelectedDay = (
        calendarCursor.selectedDay.getDate() === cellDate.getDate() &&
        calendarCursor.selectedDay.getMonth() === cellDate.getMonth() &&
        calendarCursor.selectedDay.getFullYear() === cellDate.getFullYear()
    )
    const todayDate = new Date()

    const timeDiff = todayDate.getTime() - cellDate.getTime();
    const dayDiff = timeDiff / (1000 * 3600 * 24);

    let className = '';
    className += dayDiff > 1  ? 'dia-anterior ' : '';
    
    if (cellDate.getMonth() === calendarCursor.monthSearchCursor) {
        className += (todayDate.getDate() === cellDate.getDate() && todayDate.getMonth() === cellDate.getMonth()) ? 'dia-atual ' : '';
    }
    
    
    if (cellDate.getMonth() === calendarCursor.monthSearchCursor && isSelectedDay) {
        className += isSelectedDay ? 'selected-day' : '';
    } else if (cellDate.getMonth() === calendarCursor.monthSearchCursor && cellDate.getFullYear() === calendarCursor.yearSearchCursor) {
        className += 'mes-atual'
    } else if (
        (calendarCursor.yearSearchCursor > cellDate.getFullYear()) ||
        (calendarCursor.yearSearchCursor === cellDate.getFullYear() && calendarCursor.monthSearchCursor > cellDate.getMonth())
    ) {
        className += 'mes-passado'
    } else if (
        (calendarCursor.yearSearchCursor < cellDate.getFullYear()) ||
        (calendarCursor.yearSearchCursor === cellDate.getFullYear() && calendarCursor.monthSearchCursor < cellDate.getMonth())
    ) {
        className += 'proximo-mes'
    }
    
    return className.trim();
}