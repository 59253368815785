import './ChildbirthSubSection.css'
import React from 'react'
import Tabs from '../../../../../../../../../../newcomponents/tabs/tabs'
import TabItem from '../../../../../../../../../../newcomponents/tabs/tab-item'
import Partograph from './subcomponents/Childbirth/Partograph'
import ImediatePostPartum from './subcomponents/ImediatePostPartum/ImediatePostPartum'
import Birthforescast from './subcomponents/Birthforecast/Birthforecast'
import { useThousandDays } from '../../ThousandDaysSection'

export default function ChildbirthSubSection() {
    const { selectedLanguage } = useThousandDays()

    const { Partogram, Immediatepostpartum, Expecteddeliverydate } =
			require('../../../../../../../../../data/translation.json')[selectedLanguage]

    return (
			<>
				<Tabs>
					<TabItem
						id='childbirth'
						name={Partogram}
					>
						<div className='ChildbirthSubSection-SubsectionWrapper ThousandDays-Consultation-TabWrapper'>
							<Partograph />
						</div>
					</TabItem>
					<TabItem
						id='imediate_postpartum'
						name={Immediatepostpartum}
					>
						<div className='ChildbirthSubSection-SubsectionWrapper ThousandDays-Consultation-TabWrapper'>
							<ImediatePostPartum />
						</div>
					</TabItem>
					<TabItem
						id='birthforecast'
						name={Expecteddeliverydate}
					>
						<div className='ChildbirthSubSection-SubsectionWrapper ThousandDays-Consultation-TabWrapper'>
							<Birthforescast />
						</div>
					</TabItem>

					{/*
                <TabItem id='discharge' name='Alta médica'>
                    <MedicalDischarge />
                </TabItem>
                */}
				</Tabs>
			</>
		)
}