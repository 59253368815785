export function uniqueIDs(selected, combined = []) {
    if (Array.isArray(selected)) {
        combined = [...selected, ...combined];
    } else if (selected?.id) {
        combined.unshift(selected);
    }

    const uniqueDict = {};
    const uniqueById = [];

    for (const item of combined) {
        if (item?.id && !uniqueDict[item.id]) {
            uniqueDict[item.id] = true;
            uniqueById.push(item);
        }
    }

    return uniqueById;
}