import React, { useState } from 'react';
import { Checkbox, Input } from '..';
import TimetableJson from './Timetable.json';
import './Timetable.css';

const { headDefault } = TimetableJson;

export default function Timetable({ timetableRef, timetableErrorRef=null }) {
  const [renderTrigger, setRenderTrigger] = useState(0);

  const handleChangeTimetableDay = (event) => {
    const day = event.target.name.substring(0, 3)
    
    timetableRef.current = event.target.value
      ? { ...timetableRef.current, [event.target.name]: event.target.value }
      : { 
        ...timetableRef.current, 
        [event.target.name]: event.target.value,
        [`${day}_first_start`]: "",
        [`${day}_first_end`]: "",
        [`${day}_second_start`]: "",
        [`${day}_second_end`]: "",
      }
    setRenderTrigger(old => old + 1);
  };

  const handleChangeTimetableHour = (event) => {
    timetableRef.current = {
      ...timetableRef.current,
      [event.target.name]: event.target.value,
    };
    setRenderTrigger(old => old + 1);
  };

  return (
    <div ref={timetableErrorRef}>
      <div >
        <table className='list-box'>
          <thead>
            <tr>
              {headDefault.map((header) => {
                return <td className={header.className} key={header.colunm}>
                  {header.text && (
                    <div className='timetable-row-just-center'>
                      {header.text}
                      <Checkbox
                        name={header.colunm}
                        checked={!!timetableRef?.current?.[header?.colunm]}
                        action={handleChangeTimetableDay}
                      />
                    </div>
                  )}
                </td>
              })}
            </tr>
          </thead>
          <tbody>
            {TimetableJson.timetable.map((row, lineIndex) => (
              <tr key={lineIndex}>
                <td>{TimetableJson.hoursLimit[lineIndex]}</td>
                {row.map(({ name }, idx) => {
                  return (
                    <td key={idx}>
                      <Input
                        type='time'
                        name={name}
                        action={handleChangeTimetableHour}
                        value={timetableRef?.current?.[name]}
                        disabled={!timetableRef?.current?.[`${name?.substring(0, 3)}_open`]}
                      />
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
