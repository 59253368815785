export default function isValidCPF(strCPF="") {
    strCPF = strCPF?.replace(/\D/g, '')
    var sum, rest, idx;
    sum = 0

    if (strCPF === "00000000000") return false;

    for (idx=1; idx<=9; idx++) {
        sum = sum + parseInt(strCPF.substring(idx-1, idx)) * (11 - idx)
    }
    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11))  rest = 0;
    if (rest !== parseInt(strCPF.substring(9, 10)) ) return false;

    sum = 0;
    for (idx = 1; idx <= 10; idx++) sum = sum + parseInt(strCPF.substring(idx-1, idx)) * (12 - idx);
    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11))  rest = 0;
    if (rest !== parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;
}
