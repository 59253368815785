import './SOAPSubjective.css'
import React, { useEffect, useRef } from 'react'
import { useAttendance } from '../../../../../../consultation'
import MedicalTeamIco from '../../../../../../../../component/icon/medicalTeam'
import PreceptorshipEvaluation from '../PreceptorshipEvaluation/PreceptorshipEvaluation'
import ToggleAppointmentField from '../../../ToggleAppointmentField/ToggleAppointmentField'
import TextAppointmentField from '../../../TextAppointmentField/TextAppointmentField'

const { PreceptorshipSections } = require('../../SOAPSection.json')

export default function SOAPSubjective() {
    const { attendance, displayPreceptorship } = useAttendance()

    return <div className='SOAPSubjective'>
        { !attendance?.template_type?.subjective?.length
            ? <p>Não há campos selecionados no Subjetivo para esse template</p>
            : null
        }
        { attendance?.template_type?.subjective?.map(({id, name, component}) =>  {
            const components = {
                "text": <TextAppointmentField field_type={id} name={name} />,
                "select": <ToggleAppointmentField field_type={id} name={name} />
            }
            return components[component]
        })}
        {
            displayPreceptorship
            ? <div>
                <div className='SOAP-Section-Separator' />
                <div className='SOAP-Subjective-Preceptorship'>
                    <div className='SOAP-Subjective-Preceptorship-Label'>
                        <MedicalTeamIco style={{ width: '21px', fill: '#CED4DA', marginRight: '8px' }} /> Preceptoria
                    </div>
                    <PreceptorshipEvaluation 
                        options={PreceptorshipSections.Subjective.options}
                        feedbackKey={PreceptorshipSections.Subjective.feedbackKey}
                    />
                </div>
            </div> 
            : null
        }
    </div>
}
