export function getNameById(id, list) {
    const item = list.find(obs => obs.id === id);
    return item ? item.name : '-';
}

function strpDefaultToothJson(value) {
    return { 
        "situation": value?.situation || 'NOT_EVALUATED', 
        "procedures_performed": value?.procedures_performed || ''
    }
}

export function strpDentistAssessment(assessment, project=null, attendance=null) {
    return {
        "id": assessment?.id,
        "attendance": assessment?.attendance || attendance,
        "project": assessment?.project || project,
        "_18": strpDefaultToothJson(assessment?._18),
        "_17": strpDefaultToothJson(assessment?._17),
        "_16": strpDefaultToothJson(assessment?._16),
        "_15": strpDefaultToothJson(assessment?._15),
        "_14": strpDefaultToothJson(assessment?._14),
        "_13": strpDefaultToothJson(assessment?._13),
        "_12": strpDefaultToothJson(assessment?._12),
        "_11": strpDefaultToothJson(assessment?._11),
        "_21": strpDefaultToothJson(assessment?._21),
        "_22": strpDefaultToothJson(assessment?._22),
        "_23": strpDefaultToothJson(assessment?._23),
        "_24": strpDefaultToothJson(assessment?._24),
        "_25": strpDefaultToothJson(assessment?._25),
        "_26": strpDefaultToothJson(assessment?._26),
        "_27": strpDefaultToothJson(assessment?._27),
        "_28": strpDefaultToothJson(assessment?._28),
        "_48": strpDefaultToothJson(assessment?._48),
        "_47": strpDefaultToothJson(assessment?._47),
        "_46": strpDefaultToothJson(assessment?._46),
        "_45": strpDefaultToothJson(assessment?._45),
        "_44": strpDefaultToothJson(assessment?._44),
        "_43": strpDefaultToothJson(assessment?._43),
        "_42": strpDefaultToothJson(assessment?._42),
        "_41": strpDefaultToothJson(assessment?._41),
        "_31": strpDefaultToothJson(assessment?._31),
        "_32": strpDefaultToothJson(assessment?._32),
        "_33": strpDefaultToothJson(assessment?._33),
        "_34": strpDefaultToothJson(assessment?._34),
        "_35": strpDefaultToothJson(assessment?._35),
        "_36": strpDefaultToothJson(assessment?._36),
        "_37": strpDefaultToothJson(assessment?._37),
        "_38": strpDefaultToothJson(assessment?._38),
        "visible_plate": assessment?.visible_plate ||'NOT_EVALUATED',
        "spontaneous_bleeding": assessment?.spontaneous_bleeding || 'NOT_EVALUATED',
        "presence_of_dental_calculus": assessment?.presence_of_dental_calculus || 'NOT_EVALUATED',
        "presence_of_mobility": assessment?.presence_of_mobility || 'NOT_EVALUATED',
        "bleeding_on_probing_in_at_least_10": assessment?.bleeding_on_probing_in_at_least_10 || 'NOT_EVALUATED',
        "attachment_loss_with_diagnosis_of_periodontitis":  assessment?.attachment_loss_with_diagnosis_of_periodontitis || 'NOT_EVALUATED',
        "treatment_plan": assessment?.treatment_plan || ''
    }
}
