import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AddAlert, AlertType, Input, InputTime, MaskDataHora } from "../../../../../../../../../../component";
import CalendarDateModal from "../../../../../../../../../../component/calendar/CalendarDateModal";
import Modal, { MODAL_INIT_PROPS } from "../../../../../../../../../../newcomponents/modal/modal";
import NewButton, { ButtonModel } from "../../../../../../../../../../newcomponents/button/button";
import { generateRecurringEvents } from "../../../CreateSchedulesModal/helpers";
import { useBookingAppointmentCursor } from "../../../../BookingAppointmentCursor";
import { useApp } from "../../../../../../../../../../layout/App";
import { createEvents } from "../../../../../../http";
import { postActionSchedule } from "../../../../../../../../../agenda/SelectScheduleHealthPlaceUserManager/http";
import ConflictingEventsModal from "../../../ConflictingEventsModal/ConflictingEventsModal";
import { loaded, loading } from "../../../../../../../../../../layout/redux/AppActions";

const PageThree = ({
    recurringAppointment,
    recurringAppointmentErr,
    setRecurringAppointment,
    setRecurringAppointmentErr,
    setCurrentPage,
}) => {
    const dispatch = useDispatch()
    const {
        updateSchedule,
        health_place_user__user__person,
        setModalInfo
    } = useBookingAppointmentCursor()
    const { currentHealthPlaceUser } = useApp()
    const [secondaryModal, setSecondaryModal] = useState(MODAL_INIT_PROPS)

    const openCalendarStartDateModal = () => {
        setSecondaryModal(prev => ({
            ...prev,
            open: true,
            title: 'Data de início',
            content: <CalendarDateModal
                name='start_date'
                currentDay={recurringAppointment?.start_day}
                currentMonth={recurringAppointment?.start_month}
                currentYear={recurringAppointment?.start_year}
                renderNextSelectedDay={(event) => {
                    setRecurringAppointment(prev => ({ 
                        ...prev, 
                        start_day: event?.daySearchCursor,
                        start_month: event?.monthSearchCursor,
                        start_year: event?.yearSearchCursor
                    }))
                    setSecondaryModal(MODAL_INIT_PROPS)
                }}
            />
        }))
    }

    const openCalendarEndDateModal = () => {
        setSecondaryModal(prev => ({
            ...prev,
            open: true,
            title: 'Data de término',
            content: <CalendarDateModal
                name='end_date'
                currentDay={recurringAppointment?.end_day}
                currentMonth={recurringAppointment?.end_month}
                currentYear={recurringAppointment?.end_year}
                renderNextSelectedDay={(event) => {
                    setRecurringAppointment(prev => ({ 
                        ...prev, 
                        end_day: event?.daySearchCursor,
                        end_month: event?.monthSearchCursor,
                        end_year: event?.yearSearchCursor
                    }))
                    setSecondaryModal(MODAL_INIT_PROPS)
                }}
            />
        }))
    }

    const handleSelectWeekDay = (name='') => {
        setRecurringAppointment(prev => ({ ...prev, "week_days": { ...prev.week_days, [name]: !prev.week_days[name]}}))
    }

    const handleChangeHour = (event) => {
        setRecurringAppointment(prev => ({ ...prev, [event.target.name]: event.target.value}))
    }

    const handleChangeInputFrequency = (event) => {
        let value = event.target.value.replace(/\D/g, '')
        value = value < 0 ? '1' : value > 48 ? '48' : value
        setRecurringAppointment(prev => ({ ...prev, [event.target.name]: value }))
    }

    const validateForm = () => {
        const newErrors = {};
        
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set current time to midnight for accurate comparison
        
        // Create start and end dates from the form inputs
        const startDate = new Date(recurringAppointment.start_year, recurringAppointment.start_month - 1, recurringAppointment.start_day);
        const endDate = new Date(recurringAppointment.end_year, recurringAppointment.end_month - 1, recurringAppointment.end_day);
        
        // Validate that the start date is not after the end date
        if (startDate > endDate) {
            newErrors.start_date = { error: true, message: "* A data de início não pode ser posterior à data de término" };
            newErrors.start_day = { error: true, message: true };
            newErrors.start_month = { error: true, message: true };
            newErrors.start_year = { error: true, message: true };
        }
        
        // Validate that the start date is today or later
        if (startDate < currentDate) {
            newErrors.start_date = { error: true, message: "* A data de início precisa ser hoje ou uma data futura" };
            newErrors.start_day = { error: true, message: true };
            newErrors.start_month = { error: true, message: true };
            newErrors.start_year = { error: true, message: true };
        }
        
        // Validate that the end date is today or later and not before the start date
        if (endDate < currentDate) {
            newErrors.end_date = { error: true, message: "* A data de término deve ser hoje ou uma data futura" };
            newErrors.end_day = { error: true, message: true };
            newErrors.end_month = { error: true, message: true };
            newErrors.end_year = { error: true, message: true };
        } else if (endDate < startDate) {
            newErrors.end_date = { error: true, message: "* A data de término não pode ser anterior à data de início" };
            newErrors.end_day = { error: true, message: true };
            newErrors.end_month = { error: true, message: true };
            newErrors.end_year = { error: true, message: true };
        }
        
        // Validate that at least one weekday is selected
        const isWeekdaySelected = Object.values(recurringAppointment.week_days).some(day => day === true);
        if (!isWeekdaySelected) {
            newErrors.week_days = { error: true, message: "* Pelo menos um dia da semana deve ser selecionado" };
        }
        
        // Validate time inputs
        if (!recurringAppointment.start_hour) {
            newErrors.start_hour = { error: true, message: "* O horário de início é obrigatório" };
        }
        if (!recurringAppointment.end_hour) {
            newErrors.end_hour = { error: true, message: "* O horário de término é obrigatório" };
        } else if (recurringAppointment.start_hour && recurringAppointment.end_hour && recurringAppointment.start_hour >= recurringAppointment.end_hour) {
            newErrors.end_hour = { error: true, message: "* O horário de término deve ser depois do horário de início" };
        }
        
        // Validate repeat_frequency
        const repeatFrequency = Number(recurringAppointment.repeat_frequency);
        if (repeatFrequency < 1 || repeatFrequency > 48) {
            newErrors.repeat_frequency = { error: true, message: "* A frequência de repetição deve ser entre 1 e 48 semanas" };
        }
    
        // Set errors
        setRecurringAppointmentErr(newErrors);
        
        // If there are no errors, return true; otherwise, return false
        return Object.keys(newErrors).length === 0;
    };

    const assignPatientToAllEvents = async (events) => {
        try {
            const assignPromises = events.map(event => {
                const assignPayload = {
                    result: recurringAppointment.result,
                    classification: recurringAppointment.classification,
                    decision: recurringAppointment.decision,
                    priority: recurringAppointment.priority,
                    doctor: health_place_user__user__person,
                    health_place: currentHealthPlaceUser?.health_place?.id,
                    person: recurringAppointment?.person?.id,
                    start: event.start,
                    end: event.end,
                    specialty: recurringAppointment.specialty?.id || '',
                    health_insurance: recurringAppointment.health_insurance?.id || ''
                };
                return postActionSchedule(assignPayload);
            });
    
            const assignResponses = await Promise.all(assignPromises);
    
            // Check if all assignments were successful
            assignResponses.forEach((res, idx) => {
                if (!(res.status === 200 || res.status === 201)) {
                    throw new Error(`Failed to assign patient to event ${idx + 1}`);
                }
            });
    
        } catch (err) {
            console.error("Error in assignPatientToAllEvents:", err);
            throw err;
        }
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            const payload = { events: generateRecurringEvents(recurringAppointment) };
            
            console.log("Creating events...", recurringAppointment);
            dispatch(loading())
            try {
                // Step 1: Create events
                const res = await createEvents(health_place_user__user__person, payload);
                
                if (res.status === 201) {
                    console.log("Events created successfully:", res.data);
                    
                    // Step 2: Assign patient to all created events
                    const events = res.data.events;
                    await assignPatientToAllEvents(events);
                    await updateSchedule()
                    setModalInfo(MODAL_INIT_PROPS)
                    console.log("Patient assigned successfully to all events.");
                    dispatch(AddAlert('Consulta recorrente', 'Consultas agendadas com sucesso!', AlertType.SUCCESS));
                } else {
                    throw new Error("Failed to create events");
                }
            } catch (err) {
                console.error("Error in handleSubmit:", err);
                if (err?.response?.data?.detail === 'Conflicting events detected.') {
                    setSecondaryModal(prev => ({
                        ...prev,
                        open: true,
                        title: 'Conflito de horários',
                        content: <ConflictingEventsModal
                            setModalInfo={setSecondaryModal}
                            events={err?.response?.data?.conflicts} 
                        />
                    }));
                } else {
                    dispatch(AddAlert('Consulta recorrente', 'Falha ao agendar consultas recorrentes', AlertType.ERROR));
                }
            }
            dispatch(loaded())
        }
    };

    return <div className="RecurringPatientModal-PageThree">
        <Modal {...secondaryModal} dismissFn={() => setSecondaryModal(MODAL_INIT_PROPS)} />
        <div>
            <p>Período</p>
            <div className='RecurringPatientModal-DateGrid'>
                <div>
                    <span><b className='RecurringPatientModal-Asterisk'>*</b> Começa em: </span>
                    <div className='RecurringPatientModal-Input '>
                        <Input 
                            name='start_date'
                            placeholder='DD/MM/YYYY'
                            actionFocus={openCalendarStartDateModal}
                            value={MaskDataHora(`${recurringAppointment?.start_year}-${recurringAppointment?.start_month}-${recurringAppointment?.start_day}`, 'DD/MMM/AAAA', '-')?.data}
                            errors={{
                                error: { 'start_date': recurringAppointmentErr?.start_date?.error },
                                message: recurringAppointmentErr?.start_date?.message
                            }}
                        />
                    </div>
                </div>
                <div>
                    <span><b className='RecurringPatientModal-Asterisk'>*</b> Termina em: </span>
                    <div className='RecurringPatientModal-Input '>
                        <Input 
                            name='end_date'
                            placeholder='DD/MM/YYYY'
                            actionFocus={openCalendarEndDateModal}
                            value={MaskDataHora(`${recurringAppointment?.end_year}-${recurringAppointment?.end_month}-${recurringAppointment?.end_day}`, 'DD/MMM/AAAA', '-')?.data}
                            errors={{
                                error: { 'end_date': recurringAppointmentErr?.end_date?.error },
                                message: recurringAppointmentErr?.end_date?.message
                            }}
                        />
                    </div>
                </div>
                <div className='RecurringPatientModal-Input'>
                    <span><b className='RecurringPatientModal-Asterisk'>*</b> De:</span>
                    <InputTime
                        name='start_hour'
                        action={handleChangeHour}
                        value={recurringAppointment.start_hour}
                        errors={{
                            error: { 'start_hour': recurringAppointmentErr?.start_hour?.error },
                            message: recurringAppointmentErr?.start_hour?.message
                        }}
                    />
                </div>
                <div className='RecurringPatientModal-Input'>
                    <span><b className='RecurringPatientModal-Asterisk'>*</b> Até:</span>
                    <InputTime
                        name='end_hour'
                        action={handleChangeHour}
                        value={recurringAppointment.end_hour}
                        errors={{
                            error: { 'end_hour': recurringAppointmentErr?.end_hour?.error },
                            message: recurringAppointmentErr?.end_hour?.message
                        }}
                    />
                </div>
            </div>
        </div>
        <div>

            <div className='RecurringPatientModal-RepeatFrequency'>
                <div className='RecurringPatientModal-InputRow'>
                    A cada <Input 
                        name='repeat_frequency'
                        value={recurringAppointment.repeat_frequency} 
                        action={handleChangeInputFrequency}
                        type='number'
                        min={1}
                        max={48}
                        errors={{
                            error: { 'repeat_frequency': recurringAppointmentErr?.repeat_frequency?.error },
                            message: true
                        }}
                    /> 
                    semana(s) no(s) dia(s):
                    
                </div>
                <div className='RecurringPatientModal-DaysOfTheWeek'>
                    <div 
                        name='sun' 
                        className={recurringAppointment.week_days.sun ? 'active' : ''}
                        onClick={() => handleSelectWeekDay('sun')}
                    >
                        D
                    </div>
                    <div 
                        name='mon' 
                        className={recurringAppointment.week_days.mon ? 'active' : ''}
                        onClick={() => handleSelectWeekDay('mon')}
                    >
                        S
                    </div>
                    <div 
                        name='tue' 
                        className={recurringAppointment.week_days.tue ? 'active' : ''}
                        onClick={() => handleSelectWeekDay('tue')}
                    >
                        T
                    </div>
                    <div 
                        name='wed' 
                        className={recurringAppointment.week_days.wed ? 'active' : ''}
                        onClick={() => handleSelectWeekDay('wed')}
                    >
                        Q
                    </div>
                    <div 
                        name='thu' 
                        className={recurringAppointment.week_days.thu ? 'active' : ''}
                        onClick={() => handleSelectWeekDay('thu')}
                    >
                        Q
                    </div>
                    <div 
                        name='fri' 
                        className={recurringAppointment.week_days.fri ? 'active' : ''}
                        onClick={() => handleSelectWeekDay('fri')}
                    >
                        S
                    </div>
                    <div 
                        name='sat' 
                        className={recurringAppointment.week_days.sat ? 'active' : ''}
                        onClick={() => handleSelectWeekDay('sat')}
                    >
                        S
                    </div>
                </div>
                    
                {recurringAppointmentErr?.week_days?.message ? <p className='RecurringPatientModal-Error'>{recurringAppointmentErr?.week_days?.message}</p> : null}
                {recurringAppointmentErr?.repeat_frequency?.message ? <p className='RecurringPatientModal-Error'>{recurringAppointmentErr?.repeat_frequency?.message}</p> : null}
            </div>

        </div>
        <div className='RecurringPatientModal-BtnBox'>
            <NewButton 
                label='Voltar'
                model={ButtonModel.SECONDARY}
                onClick={() => setCurrentPage('2')}
            />
            <NewButton 
                label='Prosseguir'
                //disabled={!recurringAppointment?.doctor?.id || !recurringAppointment?.result}
                onClick={handleSubmit}
            />
        </div>
    </div>
}

export default PageThree