import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { maskSnellenChart } from '../../component/form/Mask'
import Button, { ButtonModel } from '../../newcomponents/button/button'
import showToast from '../../newcomponents/toast/toast'
import {  IcoClose } from '../../component'
import IcoSave from '../../component/icon/save-icon'
import api from '../../helpers/api'
import { Biomicroscopy, VisualAcuity, DynamicRefraction, Funduscopy, Pressure, StaticRefraction }  from './ophthalmology-components'

export const isOpthalmologist = (crms) => {
    let isOpthalmologist = false
    crms.forEach(crm => {
        if (crm.specialties.includes('Oftalmologia')) {
            isOpthalmologist = true
        }
    })
    return isOpthalmologist
}

export const handleCDegree = (value) => {
    if (value > 0) return value * -1
    return value
}

export const handleAxis = (value) => {
    if (value < 0) return 0
    if (value > 180) return 180
    return value
}

const radioButtonStatus = (isNormal) => {
    return isNormal ? {id: 'isNormal', name: 'Normal'} : {id: 'areOthers', name: 'Outros'}
} 

class Eye {
    constructor(obj=null) {
        this.visualAcuity = {'sc': '20/', 'cc': '20/'}
        this.dynamicRefraction = {'refractionType': 'DY', 'eDegree': '', 'cDegree': '', 'axis': '', 'vision': '20/', 'notes': ''}
        this.staticRefraction = {'refractionType': 'ST', 'eDegree': '', 'cDegree': '', 'axis': '', 'vision': '20/', 'notes': ''}
        this.biomicroscopy = {'isNormal': true, 'anteriorChamber': '', 'crystalline': '', 'cornea': '', 'conjunctiva': ''}
        this.funduscopy = {'isNormal': true, 'macularRegion': '', 'periphery': '', 'vitreous': '', 'excavation': ''}
        this.pressure = null

        if (typeof obj === 'object')
            Object.assign(this, obj)
    }

    setModalStatus(type, status) {
        if (status) {
            this[type] = type === 'biomicroscopy' 
            ? {'isNormal': true, 'anteriorChamber': '', 'crystalline': '', 'cornea': '', 'conjunctiva': ''}
            : {'isNormal': true, 'macularRegion': '', 'periphery': '', 'vitreous': '', 'excavation': ''}
        } else {
            this[type] = {...this[type], 'isNormal': false}
        }
        return this
    }
}
export class Ophthalmology {
    constructor(obj=null) {
        this.id = null
        this.rightEye = new Eye()
        this.leftEye = new Eye()
        if (obj !== null && typeof obj === 'object')
            Object.assign(this, { id: (obj.id ? obj.id : null), rightEye: new Eye(obj.rightEye), leftEye: new Eye(obj.leftEye) })
    }

    setModalStatus(type, isNormal) {
        let rightEye = this.rightEye.setModalStatus(type, isNormal)
        let leftEye = this.leftEye.setModalStatus(type, isNormal)
        return [rightEye, leftEye]
    }
}

const radioButtonTypes = [{id: 'isNormal', name: 'Normal', enabled: true}, {id: 'areOthers', name: 'Outros', enabled: true}]


export default function DiagnosisOphthalmic({ attendance, setAttendance, isClosed, setError, fetchAttendance }) {
    let ophthalmology = new Ophthalmology(attendance?.ophthalmology)
    const [inputErrors, setInputErrors] = useState({})
    const dispatch = useDispatch()
    
    const updateOphthalmology = (data, partial) => {
        partial
        ? setAttendance(prevData => ({ ...prevData, ophthalmology: new Ophthalmology({ ...prevData.ophthalmology, ...data })}))
        : setAttendance(prevData => ({ ...prevData, ophthalmology: new Ophthalmology(data) }))
    }
    const handleSubmit = () => {
        if (ophthalmology.id) {
            api.patch(`attendance/${attendance.id}/ophthalmology`, ophthalmology)
            .then(res => {
                updateOphthalmology(res.data.ophthalmology, false)
                showToast('consultation-save', 'Salvo!', 'consultation-save-toast')
                fetchAttendance()
            })
            .catch(err => {
                console.error(err.response.data)
                setError(true)
            })
        } else {
            api.post(`attendance/${attendance.id}/ophthalmology`, ophthalmology)
            .then(res => {
                updateOphthalmology(res.data.ophthalmology, false)
                showToast('consultation-save', 'Salvo!', 'consultation-save-toast')
                fetchAttendance()
            })
            .catch(err => {
                console.error(err.response.data)
                setError(true)
            })
        }
    }

    const handleRadioButtons = (data, type) => {
        if (isClosed) return 
        const isNormal = data.id === 'isNormal'

        let rightEye, leftEye;
        [rightEye, leftEye] = ophthalmology.setModalStatus(type, isNormal)

        updateOphthalmology({ rightEye: rightEye, leftEye: leftEye })
    }

    const handleChange = (event) => {
        if (isClosed) return 

        let eye, eyeProp, eyeSubProp;
        [eye, eyeProp, eyeSubProp] = event.target.name.split('-')

        let isRightEye = eye === 'rightEye'

        const eyeData = isRightEye ? ophthalmology.rightEye : ophthalmology.leftEye

        if (!eyeSubProp) {
            eyeData[eyeProp] = event.target.value
        } else {
            eyeData[eyeProp][eyeSubProp] =  event.target.value
        }
        
        isRightEye 
        ? updateOphthalmology({ 'rightEye': eyeData }, true)
        : updateOphthalmology({ 'leftEye': eyeData }, true)
    }

    return <section>
    {
    <div className='ophthalmology-tab'>
        <VisualAcuity 
            handleChange={handleChange} 
            maskSnellenChart={maskSnellenChart} 
            ophthalmology={ophthalmology} 
            inputErrors={inputErrors} 
        />
        <DynamicRefraction 
            handleChange={handleChange}
            maskSnellenChart={maskSnellenChart}
            ophthalmology={ophthalmology}
            inputErrors={inputErrors}
            handleCDegree={handleCDegree}
            handleAxis={handleAxis}
        />
        <StaticRefraction
            handleChange={handleChange}
            maskSnellenChart={maskSnellenChart}
            ophthalmology={ophthalmology}
            inputErrors={inputErrors}
            handleCDegree={handleCDegree}
            handleAxis={handleAxis}
        />
        <Pressure 
            handleChange={handleChange} 
            ophthalmology={ophthalmology} 
            inputErrors={inputErrors}
        />
        <Biomicroscopy
            handleChange={handleChange} 
            handleRadioButtons={handleRadioButtons}
            ophthalmology={ophthalmology}
            inputErrors={inputErrors}
            radioButtonStatus={radioButtonStatus}
            radioButtonTypes={radioButtonTypes}
        />
        <Funduscopy 
            handleChange={handleChange} 
            handleRadioButtons={handleRadioButtons}
            ophthalmology={ophthalmology}
            inputErrors={inputErrors}
            radioButtonStatus={radioButtonStatus}
            radioButtonTypes={radioButtonTypes}
        />
    </div>
    }
    </section>
}