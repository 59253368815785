import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { AddAlert, AlertType } from '../../../component'
import api from '../../../helpers/api'
import Button from '../../../newcomponents/button/button'
import Loading from '../../../newcomponents/loading/loading'
import ConcludeRegisterPage from '../../register/ConcludeRegisterPage/ConcludeRegisterPage'
import ManageAuthenticationPage from './subcomponents/ManageAuthenticationPage/ManageAuthenticationPage'
import { useApp } from '../../../layout/App'


export default function DentistAppointmentPage() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { project_token } = useParams()
    const { currentHealthPlaceUser } = useApp()
    const [projectNotFound, setProjectNotFound] = useState(false)
    const [patient, setPatient] = useState(null)
    const [loading, setLoading] = useState(true)
    
    const handleOpenNewDentistAppointment = () => {
        api.post('appointment/dentistappointment/1000days/', { project_token })
            .then(res => {
                window.location.replace(`${window.location.origin}/consultation/${res.data.new_attendance_id}/dentistappointment`)
            })
            .catch(err => {
                setLoading(false)
                dispatch(AddAlert('Consulta odontológica', 'Falha ao abrir nova consulta', AlertType.ERROR))
                console.error(err)
            })
    }

    useEffect(() => {
        api.get(`projects/auth/?token=${project_token}`)
            .then(res => {
                let patient = res.data?.results?.[0]?.patient
                patient ? setPatient(patient) : setProjectNotFound(true)
                setLoading(false)
            })
            .catch(err => {
                console.error(err)
                setLoading(false)
                setProjectNotFound(true)
            })
    }, [project_token])

    switch (true) {
        case loading:
            return <div className='ManageAuthenticationPage'><Loading /></div>
        case projectNotFound:
            return <div className='ManageAuthenticationPage'>
                <section>
                    <h3 className='ManageAuthenticationPage-Title'>Salutho</h3>
                    <div className='ManageAuthenticationPage-Headline'>
                        <h4>Projeto 1000 Dias - Consulta odontológica</h4>
                        <span>Link não achado, revise e tente novamente</span>
                    </div>
                </section>
            </div>
        case !localStorage?.token:
            return <section><ManageAuthenticationPage patient={patient} /></section>
        case currentHealthPlaceUser?.profile !== 'DENTIST':
            dispatch(AddAlert('Prontuário Odontológico', 'Aba exclusiva para dentistas', AlertType.ERROR, 5000))
            setTimeout(() => history.push('/home'), 5000)
            
            return <p>Área restrita para Dentistas</p>
        case !currentHealthPlaceUser?.user?.person?.id:
            return <ConcludeRegisterPage />
        default:
            return <div className='ManageAuthenticationPage'>
                <section>
                    <h3 className='ManageAuthenticationPage-Title'>Salutho</h3>
                    <div className='ManageAuthenticationPage-Headline'>
                        <h4>Projeto 1000 Dias - Consulta odontológica</h4>
                        <span>Paciente <b>{patient?.name}</b></span>
                    </div>
                    <div className='ManageAuthenticationPage-BtnFullWidth'>
                        <Button
                            onClick={handleOpenNewDentistAppointment}
                            label='Iniciar consulta'
                        />
                    </div>
                </section>
            </div>
    }
}