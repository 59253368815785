import './HealthPlaceInstitutionalAddressModal.css'
import React, { useEffect, useState } from "react"
import { AddAlert, AlertType, IcoSearch, Input, MaskItemCep, Select } from '../../../../../../../../component'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../../../../../../../helpers/api'
import { listaCidades, loaded, loading } from '../../../../../../../../layout/redux/AppActions'
import Button from '../../../../../../../../newcomponents/button/button'
import ActionForm from '../../../../../../../../component/form/actions/actions'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'

function strpHealthPlace(health_place={}) {

    return {
        "id": health_place?.id || null,
        "address": {
            id: health_place?.address?.id || null, 
            zip: health_place?.address?.zip || '', 
            address: health_place?.address?.address || '', 
            number: health_place?.address?.number || '', 
            complement: health_place?.address?.complement || '', 
            district: health_place?.address?.district || '',
            city: health_place?.address?.city || {}
        }
    }
}

export default function HealthPlaceInstitutionalAddressModal({ setModalInfo=() => null, initHoldingHealthPlace=null, fetchExtraAction=() => null }) {
    const dispatch = useDispatch()
    const [editingHealthPlace, setEditingHealthPlace] = useState(strpHealthPlace(initHoldingHealthPlace))
    const [editingHealthPlaceErrors, setEditingHealthPlaceErrors] = useState({})
    const { listas } = useSelector(state => state.appState)

    useEffect(()=> {
        dispatch(listaCidades())
        // eslint-disable-next-line
    }, [])

    const getCEP = zipcode => {
        dispatch(loading())
        api.get(`address/cep/${zipcode}/`)
        .then(res => {
            dispatch(loaded())
            setEditingHealthPlace(prev => ({ ...prev, "address": { id: prev.address.id, ...res.data, number: '', complement: '' } }))
        })
        .catch(() => {
            dispatch([loaded(), AddAlert('Consulta de CEP', 'CEP não encontrado', AlertType.ERROR)])
            setEditingHealthPlace(prev => ({ ...prev, "address": { id: prev.address.id, "zip": "", "address": "", "number": "", "complement": "", "district": "", "city": {}} }))
        })
    }

    const validateData = (data) => {
        const errors = {}
        const keys = ['zip', 'address', 'number', 'district', 'city']
        keys.forEach(key => {
            let isEmptyObject = Object.keys(data[key]).length === 0
            if (data[key] === '' || data[key] === undefined || isEmptyObject) errors[key] = 'vazio'
            if (key === 'city') {
                for (var prop in data['city']) {
                    if (data['city'][prop] === '' || data['city'][prop] ===  undefined) {
                        errors['city'] = 'vazio'
                        return
                    }
                }
            }
        });
        setEditingHealthPlaceErrors(errors)
        return Object.keys(errors).length > 0
    }

    const handleChangeInfo = (event) => {
        if (Object.keys(editingHealthPlaceErrors).length > 0) setEditingHealthPlaceErrors({})
        setEditingHealthPlace(prev => ({ ...prev, address: { ...prev.address, [event.target.name]: event.target.value } }))
    }
    
    const handleSubmit = async () => {
        const thereIsError = validateData(editingHealthPlace?.address)
        if (thereIsError) return null

        const addressID = typeof editingHealthPlace?.address === 'number' ? editingHealthPlace?.address : editingHealthPlace?.address?.id
        const payload = {...editingHealthPlace?.address, city: editingHealthPlace?.address.city.id, zip: editingHealthPlace?.address?.zip.replace(/\D/g, '')}
        
        await api.patch(`/address/${addressID}/`, payload)
            .then(() => {
                fetchExtraAction()
                dispatch(AddAlert('Endereço', 'Endereço salvo com sucesso!', AlertType.SUCCESS))
                setModalInfo(MODAL_INIT_PROPS)
            })
            .catch(err => {
                dispatch(AddAlert('Endereço', 'Ocorreu um erro ao tentar salvar o endereço, tente novamente em instantes', AlertType.ERROR))
                console.error('HealthPlaceInstitutionalAddressModal ~ handleSubmit ', err)
            })
    }

    return <div className='HealthPlaceInstitutionalAddressModal'>
        <div className='HealthPlaceInstitutionalAddressModal-PersonalInfo'>
            <div className='HealthPlaceInstitutionalAddressModal-Subtitle-Header'>
                <div className='HealthPlaceInstitutionalAddressModal-Subtitle-Header-Icon HealthPlaceInstitutionalAddressModal-Subtitle-Header-Icon-Blue'>E</div>
                <div className='HealthPlaceInstitutionalAddressModal-Subtitle-Header-Title'>Endereço</div>
            </div>
        </div>
        <div className='HealthPlaceInstitutionalAddressModal-AddressInfo-4FR'>
            <div className='HealthPlaceInstitutionalAddressModal-Input'>
                <span><b className='HealthPlaceInstitutionalAddressModal-Asterisk'>*</b> CEP</span>
                <Input 
                    name='zip' 
                    action={handleChangeInfo} 
                    actionBlur={handleChangeInfo}
                    value={MaskItemCep(editingHealthPlace?.address?.zip)}
                    maxLength='9'
                    required={{erro: editingHealthPlaceErrors, message: 'Campo obrigatório'}}
                >
                    <ActionForm action={() => getCEP(editingHealthPlace?.address?.zip)} title='Pesquisar'><IcoSearch /></ActionForm>
                </Input>
            </div>
            <div className='HealthPlaceInstitutionalAddressModal-Select'>
                <span><b className='HealthPlaceInstitutionalAddressModal-Asterisk'>*</b> Cidade</span>
                <Select
                    name='city'
                    action={handleChangeInfo}
                    options={listas.cidades}
                    selected={editingHealthPlace?.address?.city}
                    required={{erro: editingHealthPlaceErrors, message: 'Campo obrigatório'}}
                />
            </div>
            <div className='HealthPlaceInstitutionalAddressModal-Input'>
                <span>UF</span>
                <Input 
                    name='uf' 
                    disabled={true} 
                    value={editingHealthPlace?.address?.city?.state}
                    required={{erro: editingHealthPlaceErrors, message: 'Obrigatório'}}
                />
            </div>
            <div className='HealthPlaceInstitutionalAddressModal-Input'>
                <span><b className='HealthPlaceInstitutionalAddressModal-Asterisk'>*</b> Bairro</span>
                <Input 
                    name='district' 
                    action={handleChangeInfo} 
                    actionBlur={handleChangeInfo}
                    value={editingHealthPlace?.address?.district}
                    required={{erro: editingHealthPlaceErrors, message: 'Campo obrigatório'}}
                />
            </div>
        </div>
        <div className='HealthPlaceInstitutionalAddressModal-AddressInfo-2FR'>
            <div className='HealthPlaceInstitutionalAddressModal-Input'>
                <span><b className='HealthPlaceInstitutionalAddressModal-Asterisk'>*</b> Endereço</span>
                <Input 
                    name='address' 
                    action={handleChangeInfo} 
                    actionBlur={handleChangeInfo}
                    value={editingHealthPlace?.address?.address}
                    required={{erro: editingHealthPlaceErrors, message: 'Campo obrigatório'}}
                />
            </div>
            <div className='HealthPlaceInstitutionalAddressModal-Input'>
                <span><b className='HealthPlaceInstitutionalAddressModal-Asterisk'>*</b> Número</span>
                <Input 
                    name='number' 
                    action={handleChangeInfo} 
                    actionBlur={handleChangeInfo}
                    value={editingHealthPlace?.address?.number}
                    maxLength={15}
                    required={{erro: editingHealthPlaceErrors, message: 'Campo obrigatório'}} 
                />
            </div>
        </div>
        <div className='HealthPlaceInstitutionalAddressModal-Input'>
            <span>Complemento</span>
            <Input 
                name='complement' 
                action={handleChangeInfo}
                actionBlur={handleChangeInfo}
                value={editingHealthPlace?.address?.complement} 
            />
        </div>
        <div className='HealthPlaceInstitutionalAddressModal-Btn-Box'>
            <Button 
                onClick={handleSubmit}
                label='Salvar'
            />
        </div>
    </div>
}