import './EditInstitutionalCoverageCitiesModal.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FilterSelect, Select } from '../../../../../../../../component'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import { listaCidades } from '../../../../../../../../layout/redux/AppActions'
import { useConfiguration } from '../../../../../../configuration'
import NewButton from '../../../../../../../../newcomponents/button/button'

const strpHealthPlace = (health_place) => {

	return {
		id: health_place?.id,
		citys: health_place?.citys || []
	}
}

export default function EditInstitutionalCoverageCitiesModal() {
	const { institution, saveInstitution, setModalProps, fetchInstitution } = useConfiguration()
	const dispatch = useDispatch()
	const { listas } = useSelector((state) => state.appState)
	const [holdingHealthPlace, setHoldingHealthPlace] = useState(strpHealthPlace(institution))

	useEffect(() => {
		dispatch([listaCidades({ limit: 50 })])
	}, []) // eslint-disable-line

	const handlePartners = (event) => {
		setHoldingHealthPlace(prev => ({
			...prev,
			[event.target.name]: event.target.name === 'type' ? event.target.value.id : event.target.value,
		}))
	}

	const handleSubmit = async () => {
		const payload = { citys: holdingHealthPlace.citys.map((e) => e.id) }
		await saveInstitution(holdingHealthPlace.id, payload)
			.then(() => {
				fetchInstitution()
				setModalProps(MODAL_INIT_PROPS)
			})
	}

	return (<div className='EditInstitutionalCoverageCitiesModal'>
		<div>
			<div className='EditInstitutionalCoverageCitiesModal-PersonalInfo'>
                <div className='EditInstitutionalCoverageCitiesModal-Subtitle-Header'>
                    <div className='EditInstitutionalCoverageCitiesModal-Subtitle-Header-Icon'>A</div>
                    <div className='EditInstitutionalCoverageCitiesModal-Subtitle-Header-Title'>Área de Abrangência (cidades)</div>
                </div>
            </div>
			<div className='EditInstitutionalCoverageCitiesModal-Select'>
				<Select
					name='citys'
					action={handlePartners}
					options={listas.cidades}
					multiSelect
					closeOnSelect={false}
					selected={holdingHealthPlace?.citys || []}
				>
					<FilterSelect
						action={(e) => dispatch(listaCidades({ name: e, limit: 50 }))}
						title='Filtrar'
					/>
				</Select>
			</div>
		</div>
		<div className='EditInstitutionPartners-BtnBox'>
			<NewButton label='Atualizar' onClick={handleSubmit} />
		</div>
	</div>)
}
