import './CompleteHemogramWrite.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Mask1DecimalPlace, MaskThousand } from '../../../../../../../../../../../../../../../component/mask/mask'
import { AddAlert, AlertType, Input } from '../../../../../../../../../../../../../../../component'
import Button from '../../../../../../../../../../../../../../../newcomponents/button/button'
import AlertRowText from '../../../../../../../../../../../../../../../component/alertRowText/alertRowText'
import api from '../../../../../../../../../../../../../../../helpers/api'
import { getHemoglobineResult, getLeukocytesResult, getPlateletsResult } from '../utils'
import { parseNumberWithComma, parseNumberWithThousandSeparator } from '../../../../../../../../../../../../../../../utils/parsers'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import getCurrentDate from '../../../../../../../../../../../../../../../utils/getCurrentDate'
import { buildDate, formatISO8601 } from '../../../../../../../../../../../../../../../utils/convertDate'
import NewSelect from '../../../../../../../../../../../../../../../newcomponents/form/select/select'
import { getYearsStart1900 } from '../../../../../../../../../../../../../../../utils/getYearsStart1900'

const selectOptions_data = require('../../../../../../../../../../../../../../../component/form/select/options.json')

function strpHemogram(exam={}) {
	let { day, month, year } = getCurrentDate()

	let date = exam?.date ? new Date(exam.date) : ''

	return {
		"id": exam?.id,
		"hemoglobin": exam?.hemoglobin || "", 
		"leukocytes": exam?.leukocytes || "", 
		"platelets": exam?.platelets || "",
		'date_day': date ? `${date.getDate() < 10 ? '0': ''}${date.getDate()}` : day,
		'date_month': date ? `${date.getMonth() + 1 < 10 ? '0': ''}${date.getMonth() + 1}` : month,
		'date_year': date ? `${date.getFullYear()}` : year,
	}
}

function parseHemogram(exam={}) {
	let date = buildDate(exam?.date_year, exam?.date_month, exam?.date_day, '10', '10')

	return {
		"id": exam?.id,
		"hemoglobin": exam?.hemoglobin ? parseNumberWithComma(exam.hemoglobin) : "", 
		"leukocytes": exam?.leukocytes ? parseNumberWithThousandSeparator(exam.leukocytes) : "", 
		"platelets": exam?.platelets ? parseNumberWithThousandSeparator(exam.platelets) : "",
		"date": formatISO8601(date),
	}
}

export default function CompleteHemogramWrite({ holdingCompleteHemogram=null, setModalInfo=() => null }) {
	const { selectedLanguage, fetchThousandDaysProjects, holdingProject } = useThousandDays()
	const [hemogram, setHemogram] = useState(strpHemogram(holdingCompleteHemogram))
	const dispatch = useDispatch()

	const {
		Lowattention,
		Mediumattention,
		Hightattention,
		Change,
		Completebloodcount,
		Hemoglobin,
		Leukocytes,
		Platelets,
		Cells,
		Save,
		Savedexam,
		Notsavedexam,
		Day,
		Week,
		Year,
		date,
		Select_Months_Options,
	} = require('../../../../../../../../../../../../../../data/translation.json')[selectedLanguage]

	const handleChange = event => {
		if (event.target.name === 'hemoglobin') {
			setHemogram(prev => ({
				...prev,
				[`${event.target.name}`]: Mask1DecimalPlace(event.target.value),
			}))
		} else {
			setHemogram(prev => ({
				...prev,
				[`${event.target.name}`]: MaskThousand(event.target.value),
			}))
		}
	}

	const handleSelect = event => {
		setHemogram(prev => ({ ...prev, [event.target.id]: event.target.selected }))
	}

	const handleSubmit = async () => {
		const payload = {
			...parseHemogram(hemogram),
			person: holdingProject.patient.id,
			project: holdingProject.id,
		}

		try {
			if (payload?.id) {
				await api.patch(`/exam/complete_hemogram/${payload.id}/`, payload)
			} else {
				await api.post('/exam/complete_hemogram/', payload)
			}
			dispatch(AddAlert(Completebloodcount, Savedexam, AlertType.SUCCESS))
			fetchThousandDaysProjects()
			setModalInfo({})
		} catch (err) {
			dispatch(AddAlert(Completebloodcount, Notsavedexam, AlertType.ERROR))
			console.error("CompleteHemogramWrite ~ handleSubmit: ", err)
		}
	}

	const date_months_options = selectOptions_data.months.map((option) => ({ id: option.id, name: Select_Months_Options[option.id] }))

	return (
		<div className='CompleteHemogramWrite'>
			<div>
				<b>{date}</b>
				<div className='ABOGroupRHFactorWrite-Date'>
					<NewSelect
						id='date_day'
						options={selectOptions_data.days}
						selected={hemogram.date_day}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Day}
					/>
					<NewSelect
						id='date_month'
						options={date_months_options}
						selected={hemogram.date_month}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Week}
					/>
					<NewSelect
						id='date_year'
						options={getYearsStart1900()}
						selected={hemogram.date_year}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Year}
					/>
				</div>
			</div>
			<div className='CompleteHemogramWrite-DataRow'>
				<Input
					name='hemoglobin'
					action={handleChange}
					maxLength={4}
					value={Mask1DecimalPlace(hemogram.hemoglobin)}
					label={Hemoglobin}
					placeholder='g/dL'
					autoComplete='off'
				/>
				{hemogram.hemoglobin ? (
					<AlertRowText {...getHemoglobineResult(hemogram.hemoglobin)} />
				) : null}
			</div>
			<div className='CompleteHemogramWrite-DataRow'>
				<Input
					name='leukocytes'
					action={handleChange}
					maxLength={7}
					value={MaskThousand(hemogram.leukocytes)}
					label={Leukocytes}
					placeholder={`${Cells} / ml (µL)`}
					autoComplete='off'
				/>
				{hemogram.leukocytes ? (
					<AlertRowText {...getLeukocytesResult(hemogram.leukocytes, Change)} />
				) : null}
			</div>
			<div className='CompleteHemogramWrite-DataRow'>
				<Input
					name='platelets'
					action={handleChange}
					maxLength={7}
					value={MaskThousand(hemogram.platelets)}
					label={Platelets}
					placeholder={`${Cells} / ml (µL)`}
					autoComplete='off'
				/>
				{hemogram.platelets ? (
					<AlertRowText
						{...getPlateletsResult(
							hemogram.platelets,
							Hightattention,
							Mediumattention,
							Lowattention
						)}
					/>
				) : null}
			</div>
			<div className='AddNewExamModal-ButtonRow'>
				<Button
					label={Save}
					onClick={handleSubmit}
				/>
			</div>
		</div>
	)
}
