import React from "react"

export default function PasteIco({ style={} }) {

    return <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M2005 4839 c-89 -13 -196 -68 -264 -136 -56 -56 -121 -166 -121 -205
            0 -16 -10 -18 -105 -18 -67 0 -118 -5 -138 -14 -43 -17 -85 -68 -93 -111 l-6
            -35 -107 0 c-64 0 -131 -6 -166 -15 -172 -45 -305 -179 -350 -352 -23 -87 -23
            -2860 0 -2948 47 -178 197 -321 374 -355 32 -6 259 -10 566 -10 l513 0 28 -57
            c33 -70 129 -167 200 -205 108 -58 106 -58 855 -58 425 0 707 4 740 10 184 35
            345 196 379 379 14 74 13 1710 0 1782 -25 131 -124 266 -239 328 -97 51 -154
            61 -363 61 l-188 0 0 538 c0 416 -3 548 -14 587 -40 150 -151 261 -300 301
            -31 8 -104 14 -187 14 l-137 0 -6 35 c-8 43 -50 94 -93 111 -20 9 -71 14 -138
            14 -90 0 -105 2 -105 16 0 30 -59 140 -100 186 -105 120 -272 180 -435 157z
            m160 -340 c48 -28 68 -73 75 -166 6 -74 10 -87 36 -115 44 -46 81 -58 189 -58
            l95 0 0 -80 0 -80 -480 0 -480 0 0 79 0 79 109 4 c92 2 114 6 138 24 52 39 73
            81 73 147 0 154 124 239 245 166z m-883 -608 c3 -118 12 -141 72 -185 27 -21
            36 -21 726 -21 690 0 699 0 726 21 60 44 69 67 72 185 l4 111 134 -4 c123 -3
            135 -5 156 -26 l23 -23 3 -534 3 -535 -349 0 c-376 0 -411 -4 -514 -56 -71
            -36 -159 -123 -197 -193 -62 -117 -61 -101 -61 -923 l0 -749 -509 3 c-497 3
            -510 4 -537 24 -15 11 -37 33 -48 48 -21 27 -21 31 -24 1419 -1 766 0 1406 3
            1424 7 40 49 91 90 109 18 8 69 13 127 14 l96 0 4 -109z m2644 -1357 c15 -11
            37 -33 48 -48 21 -27 21 -34 21 -886 0 -852 0 -859 -21 -886 -11 -15 -33 -37
            -48 -48 -27 -21 -36 -21 -726 -21 -690 0 -699 0 -726 21 -15 11 -37 33 -48 48
            -21 27 -21 37 -24 859 -2 458 0 846 3 864 7 40 49 91 90 109 24 11 161 13 717
            11 676 -2 687 -2 714 -23z"/>
            <path d="M2655 2226 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25
            -8 209 -10 554 -8 504 3 517 4 544 24 53 39 69 71 69 134 0 63 -16 95 -69 134
            -27 21 -40 21 -554 23 -423 2 -533 0 -557 -11z"/>
            <path d="M2655 1746 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25
            -8 209 -10 554 -8 504 3 517 4 544 24 53 39 69 71 69 134 0 63 -16 95 -69 134
            -27 21 -40 21 -554 23 -423 2 -533 0 -557 -11z"/>
            <path d="M2655 1266 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25
            -8 209 -10 554 -8 504 3 517 4 544 24 53 39 69 71 69 134 0 63 -16 95 -69 134
            -27 21 -40 21 -554 23 -423 2 -533 0 -557 -11z"/>
        </g>
    </svg>
}
