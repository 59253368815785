import './BookingAppointmentCursor.css'
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom'
import api from "../../../../../../helpers/api";
import Modal, { MODAL_INIT_PROPS } from "../../../../../../newcomponents/modal/modal";
import TimeSchedule from "./subcomponents/TimeSchedule/TimeSchedule";
import { Calendar } from "../../../../../../component/calendar/calendario";
import { IcoBin, MaskDataHora, Select, adicionaZero } from "../../../../../../component";
import IcoSchedule from '../../../../../../component/icon/schedule';
import IcoBlockCalendar from '../../../../../../component/icon/calendar_block';
import DeleteScheduleSlot from './subcomponents/TimeSchedule/subcomponents/DeleteScheduleSlot/DeleteScheduleSlot';
import MarkTimeAsUnavailable from './subcomponents/TimeSchedule/subcomponents/MarkTimeAsUnavailable/MarkTimeAsUnavailable';
import ReplicateIco from '../../../../../../component/icon/replicate';
import { useApp } from '../../../../../../layout/App';
import MarkTimeAsAvailable from './subcomponents/TimeSchedule/subcomponents/MarkTimeAsAvailable/MarkTimeAsAvailable';
import IcoCalendarUnblock from '../../../../../../component/icon/calendar_unblock';

const BookingAppointmentContext = React.createContext()

export function useBookingAppointmentCursor() {
	return useContext(BookingAppointmentContext)
}


export default function BookingAppointmentCursor({ selectedHealthProfessional=null }) {
    const history = useHistory()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
    const [schedulingStats, setSchedulingStats] = useState({})
    const [loadingCalendar, setLoadingCalendar] = useState(false)
    const [calendarCursor, setCalendarCursor] = useState({ yearSearchCursor: new Date().getFullYear(), monthSearchCursor: new Date().getMonth(), selectedDay: new Date() })
    const [filledTimesOfTheDay, setFilledTimesOfTheDay] = useState({ data: '', horarios: [] })
    const [filledTimesOfTheWeek, setFilledTimesOfTheWeek] = useState([])
    const [selectedTimesCursor, setSelectedTimesCursor] = useState([])
    const [displayMode, setDisplayMode] = useState('daily')
    
    const atualDate = new Date()
    const health_place_user__user__person = queryParams.get('health_place_user__user__person')

    useEffect(() => {
        setCalendarCursor({ yearSearchCursor: new Date().getFullYear(), monthSearchCursor: new Date().getMonth(), selectedDay: new Date() })
    }, [health_place_user__user__person])

    useEffect(() => {
        fetchMonthSchedulesStats({ monthSearchCursor: atualDate.getMonth() + 1, yearSearchCursor: atualDate.getFullYear() })
    
        const selectedDayCursor = adicionaZero(calendarCursor.selectedDay.getDate());
        const selectedMonthCursor = adicionaZero(calendarCursor.selectedDay.getMonth() + 1);
        const selectedYearCursor = calendarCursor.selectedDay.getFullYear();
    
        fetchFilledTimesOfTheDay({ 
            yearSearchCursor: selectedYearCursor, 
            monthSearchCursor: selectedMonthCursor, 
            daySearchCursor: selectedDayCursor 
        })

        fetchFilledTimesOfTheWeek({ 
            yearSearchCursor: selectedYearCursor, 
            monthSearchCursor: selectedMonthCursor, 
            daySearchCursor: selectedDayCursor 
        })

      }, [health_place_user__user__person])
    
    const getStartOfWeek = (date) => {       
        if (date.getDay() === 6){
            return new Date(date)
        }
        const diff = date.getDate() - date.getDay();
        return new Date(date.setDate(diff));
    }

    const groupAppointmentsByDay = (appointments) => {
        let appointmentsByDay = {}

        appointments.forEach(appointment => {
            const date = appointment.fields.start
            const formattedAppointmentDate = date.split('T')[0];

            if (!appointmentsByDay[formattedAppointmentDate]) {
                appointmentsByDay[formattedAppointmentDate] = []
            }
            appointmentsByDay[formattedAppointmentDate].push(appointment);
        });
        return appointmentsByDay
    }

    const fetchFilledTimesOfTheDay = async ({ yearSearchCursor, monthSearchCursor, daySearchCursor }) => {
        let date = `${yearSearchCursor}-${monthSearchCursor}-${daySearchCursor}`

        if (health_place_user__user__person) {
            await api.get(`schedule/doctor/${health_place_user__user__person}/day/?date=${date}`)
            .then(res => {
                setFilledTimesOfTheDay({ "data": date, "horarios": res.data })
            })
            .catch(error => {
                console.error('fetchFilledTimesOfTheDay ~', error)
            })
        }
    }

    const fetchFilledTimesOfTheWeek = async ({ yearSearchCursor, monthSearchCursor, daySearchCursor }) => {
        let date = `${yearSearchCursor}-${monthSearchCursor}-${daySearchCursor}`
        const firstDayOfTheWeek = getStartOfWeek(new Date(date))
        const formattedDate = firstDayOfTheWeek.toISOString().split('T')[0];

        if (health_place_user__user__person) {
            await api.get(`schedule/doctor/${health_place_user__user__person}/week/?date=${formattedDate}`)
            .then(res => {
                setFilledTimesOfTheWeek(groupAppointmentsByDay(res.data))
            })
            .catch(error => {
                console.error('fetchFilledTimesOfTheWeek ~', error)
            })
        }
    }

    const updateSchedule = async () => {
        const [yearSearchCursor, monthSearchCursor, daySearchCursor] = filledTimesOfTheDay.data.split('-')

        await fetchFilledTimesOfTheDay({ yearSearchCursor, monthSearchCursor, daySearchCursor })
        await fetchFilledTimesOfTheWeek({ yearSearchCursor, monthSearchCursor, daySearchCursor })
        await fetchMonthSchedulesStats({ yearSearchCursor, monthSearchCursor })
    }

    const fetchMonthSchedulesStats = async ({ yearSearchCursor, monthSearchCursor }) => {
        let date = `${yearSearchCursor}-${monthSearchCursor}-01`
        
        if (health_place_user__user__person) {
            setLoadingCalendar(true)
            await api.get(`/schedule/event/${health_place_user__user__person}/monthIfHasEvent/?date=${date}`)
                .then(res => {
                    setLoadingCalendar(false)
                    setSchedulingStats({ [yearSearchCursor]: { [monthSearchCursor]: res.data.month } })
                })
                .catch(error => {
                    setLoadingCalendar(false)
                    console.error('fetchMonthSchedulesStats ~ ', error)
                })
        }
    }

    const renderNextSelectedMonth = async ({ monthSearchCursor, yearSearchCursor }) => {
        await fetchMonthSchedulesStats({ yearSearchCursor, monthSearchCursor })
    }

    const renderNextSelectedDay = ({ yearSearchCursor, monthSearchCursor, daySearchCursor }) => {
        fetchFilledTimesOfTheDay({ yearSearchCursor, monthSearchCursor, daySearchCursor })
        fetchFilledTimesOfTheWeek({ yearSearchCursor, monthSearchCursor, daySearchCursor })

    }

    const deleteAllSelectSecheduleSlot = () => {

        setModalInfo(prev => ({
            ...prev,
            open: true,
            title: 'Remover Horário(s)',
            content: <DeleteScheduleSlot appointments={selectedTimesCursor} />
        }))
    }

    const blockAllSelectSecheduleSlot = () => {
        
        setModalInfo(prev => ({
            ...prev,
            open: true,
            title: 'Bloquear Horário(s)',
            content: <MarkTimeAsUnavailable appointments={selectedTimesCursor} />
        }))
    }

    const unblockAllSelectSecheduleSlot = () => {

        setModalInfo(prev => ({ 
            ...prev,
            open: true,
            title: 'Disponibilizar Horário(s)',
            content: <MarkTimeAsAvailable appointments={selectedTimesCursor} />
        }))
    }

    return <BookingAppointmentContext.Provider
        value={{
            calendarCursor,
            selectedHealthProfessional,
            health_place_user__user__person,
            updateSchedule,
            filledTimesOfTheDay,
            filledTimesOfTheWeek,
            selectedTimesCursor,
            setSelectedTimesCursor,
            setModalInfo,
        }}
    >
        <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
        {
            health_place_user__user__person
            ? <div className='BookingAppointmentCursor'>
                <div className='BookingAppointmentCursor-ActionButtonsArea'>
                    <Calendar 
                        name='data'
                        renderNextSelectedDay={renderNextSelectedDay}
                        renderNextSelectedMonth={renderNextSelectedMonth}
                        value={schedulingStats}
                        loading={loadingCalendar}
                        calendarCursor={calendarCursor}
                        setCalendarCursor={setCalendarCursor}  
                    />
                    <div>
                        <Select
                            label='Modo de exibição'
							name='displayMode'
							action={event => setDisplayMode(event.target.value.id)}
							options={[{id: 'daily', name: 'Diário'}, {id: 'weekly', name: 'Semanal'}]}
							selected={displayMode}
                            selectedIten={false}
                        />
                    </div>
                    <div className='BookingAppointmentCursor-ActionButtonsArea-SelectedTimes'>
                    {
                        selectedTimesCursor?.length
                        ? <div 
                            title='Bloquear horários selecionados' 
                            className='BookingAppointmentCursor-ActionButtonsArea-ActionRow-Btn' 
                            onClick={blockAllSelectSecheduleSlot}
                        >
                            <IcoBlockCalendar /> <b>Bloquear horários selecionados</b>
                        </div>
                        : null
                    }
                    {
                        selectedTimesCursor?.length
                        ? <div 
                            title='Disponibilizar horários selecionados' 
                            className='BookingAppointmentCursor-ActionButtonsArea-ActionRow-Btn' 
                            onClick={unblockAllSelectSecheduleSlot}
                        >
                            <IcoCalendarUnblock /> <b>Disponibilizar horários selecionados</b>
                        </div>
                        : null
                    }
                    {
                        selectedTimesCursor?.length
                        ? <div 
                                title='Remover horários selecionados' 
                                className='BookingAppointmentCursor-ActionButtonsArea-ActionRow-Btn' 
                                onClick={deleteAllSelectSecheduleSlot}
                            >
                                <IcoBin /> <b>Remover horários selecionados</b>
                        </div>
                        : null
                    }
                    </div>
                    {
                        selectedTimesCursor?.length
                        ? <div className='SelectedTimesCursorSlot-TimeRow'>
                        {
                            selectedTimesCursor.map(appointment => {
                                const startHourTime = MaskDataHora(appointment?.fields?.start || appointment?.start, 'DD/MMM/AAAA', '-');
                                const endHourTime = MaskDataHora(appointment?.fields?.end || appointment?.end, 'DD/MMM/AAAA', '-');
                                return <div onClick={() => setSelectedTimesCursor(prev => prev.filter(({pk}) => appointment.pk != pk))}>
                                    <b>{startHourTime.data} | {startHourTime?.hora?.slice(0, 5) || '-'} - {endHourTime?.hora?.slice(0, 5) || '-'}</b>
                                </div>
                            })
                        }
                        </div>
                        : null
                    }
                    
                </div>
                <TimeSchedule  displayMode={displayMode}/>
            </div>
            : <div className='BookingAppointmentCursor-NotFound'>
                <div>
                    <p><b>Nenhuma agenda selecionada</b></p>
                </div>
            </div>
        }
    </BookingAppointmentContext.Provider>
}