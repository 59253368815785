import React from 'react'

export default function IcoPrintXLS({ style={} }) {
    return <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M1166 5104 c-123 -30 -250 -118 -322 -224 -17 -25 -44 -76 -60 -115
            l-29 -70 -3 -1067 -3 -1068 -47 0 c-68 0 -148 -42 -187 -98 l-30 -44 -3 -726
            c-3 -817 -6 -781 71 -847 45 -39 90 -55 153 -55 l42 0 4 -183 c4 -167 7 -188
            31 -250 50 -128 139 -229 256 -291 133 -69 38 -66 1657 -66 1425 0 1463 0
            1538 20 43 11 103 34 135 52 74 42 168 139 208 214 67 126 63 5 63 1864 l0
            1685 -642 642 -643 643 -1065 -1 c-883 -1 -1075 -3 -1124 -15z m2056 -706 c4
            -381 5 -395 27 -451 43 -108 121 -187 226 -226 53 -20 75 -21 439 -21 l384 0
            7 -1012 c3 -557 5 -1281 3 -1609 l-3 -596 -30 -48 c-21 -35 -45 -56 -80 -74
            l-49 -26 -1421 -3 c-1015 -2 -1435 1 -1474 9 -40 8 -65 21 -97 51 -60 55 -74
            106 -74 270 l0 128 1330 0 c1478 0 1368 -5 1443 68 68 66 67 54 67 814 0 483
            -3 692 -12 725 -15 60 -87 137 -143 152 -28 8 -436 11 -1362 11 l-1323 0 0
            1013 c0 862 2 1021 15 1063 19 65 77 122 142 140 38 10 257 13 1015 13 l966 1
            4 -392z m-88 -2295 c57 -16 58 -17 53 -47 -3 -17 -12 -56 -21 -87 l-16 -56
            -57 19 c-72 25 -178 26 -222 4 -38 -20 -57 -61 -42 -92 15 -34 68 -69 154
            -103 96 -38 142 -67 188 -118 48 -53 63 -100 57 -184 -9 -122 -78 -200 -211
            -241 -58 -17 -86 -20 -185 -15 -106 4 -199 24 -225 47 -7 6 25 155 35 166 2 2
            29 -6 59 -17 78 -30 208 -37 258 -14 44 19 67 60 57 98 -9 37 -51 73 -114 98
            -216 85 -287 157 -286 289 1 214 237 330 518 253z m-1811 -90 c25 -54 59 -127
            74 -162 l29 -64 44 104 c24 57 56 130 72 162 l28 57 116 0 c89 0 115 -3 111
            -12 -2 -7 -59 -107 -126 -222 -67 -116 -119 -216 -117 -223 5 -12 201 -353
            248 -430 l20 -33 -121 0 -121 0 -84 168 -84 167 -16 -40 c-9 -22 -42 -97 -74
            -167 l-57 -128 -117 0 c-65 0 -118 2 -118 4 0 2 59 106 130 231 72 125 130
            229 130 231 0 3 -56 104 -125 226 -69 121 -125 222 -125 224 0 2 53 4 118 4
            l119 0 46 -97z m819 -275 l3 -373 183 -3 182 -2 0 -85 0 -85 -290 0 -290 0 0
            460 0 460 105 0 105 0 2 -372z"/>
        </g>
    </svg>
}
