import axios from 'axios';
import { getHotlinkFromPath } from '../utils/getParams';

const baseURL = process.env.REACT_APP_SALUTHO_API

const api = axios.create({
  baseURL: `${baseURL}/api/v1/`
});

const apiV2 = axios.create({
  baseURL: `${baseURL}/api/v2/`
})

api.interceptors.request.use(async (config) => {
  const hotlink = getHotlinkFromPath()
  
  if (hotlink) {
    config.headers['X-THOUSANDDAYS-HOTLINK'] = hotlink
  }

  if (localStorage.selectedLeaderHealthPlaceUser) {
    config.headers['X-SELECTED-LEADER-HEALTHPLACEUSER'] = localStorage.getItem('selectedLeaderHealthPlaceUser')
  }
  
  const token = localStorage.token;
  
  if (token)
    config.headers.authorization = `Bearer ${token}`;

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  
  (err) => Promise.reject(err)
);

api.delete = (url, data) => {
  const headers = {
    'Authorization': `Bearer ${localStorage.token}`,
    'Content-Type': 'application/json'
  };

  const selectedLeaderHealthPlaceUser = localStorage.getItem('selectedLeaderHealthPlaceUser');
  if (selectedLeaderHealthPlaceUser && selectedLeaderHealthPlaceUser !== 'null') {
    headers['X-SELECTED-LEADER-HEALTHPLACEUSER'] = selectedLeaderHealthPlaceUser;
  }

  return fetch(`${baseURL}/api/v1/${url}`, {
    method: 'DELETE',
    body: JSON.stringify(data),
    headers
  });
}

// api.post = (url, data) => {
//   return fetch(`${baseURL}/api/v1/${url}`, {
//     method: 'POST',
//     body: JSON.stringify(data),
//     headers:{
//       'Authorization': `Bearer ${localStorage.token}`,
//       'X-SELECTED-LEADER-HEALTHPLACEUSER': localStorage.getItem('selectedLeaderHealthPlaceUser'),
//       'Content-Type': 'application/json'
//     }
//   })
// }

apiV2.interceptors.request.use(async (config) => {
  const hotlink = getHotlinkFromPath()
  
  if (hotlink) {
    config.headers['X-THOUSANDDAYS-HOTLINK'] = hotlink
  }

  if (localStorage.selectedLeaderHealthPlaceUser) {
    config.headers['X-SELECTED-LEADER-HEALTHPLACEUSER'] = localStorage.getItem('selectedLeaderHealthPlaceUser')
  }
  
  const token = localStorage.token;
  
  if (token)
    config.headers.authorization = `Bearer ${token}`;

  return config;
});

apiV2.interceptors.response.use(
  (response) => {
    return response;
  },
  
  (err) => Promise.reject(err)
);

apiV2.delete = (url, data) => {
  return fetch(`${baseURL}/api/v2/${url}`, {
    method: 'DELETE',
    body: JSON.stringify(data),
    headers:{
      'Authorization': `Bearer ${localStorage.token}`,
      'X-SELECTED-LEADER-HEALTHPLACEUSER': localStorage.getItem('selectedLeaderHealthPlaceUser')
    }
  })
}

export { apiV2 };
export default api;