import './PatientResponsibleInformation.css'
import React from "react";
import { useHoldingPatient } from '../../../../HoldingPatientInfoView';
import { MaskCpf, MaskTelefone } from '../../../../../../component';
import HorizontalField from '../../../../../../component/HorizontalField/HorizontalField';

const { 
    KINSHIP_CHOICES
} = require('../../../../../../component/form/select/options.json')

export default function PatientResponsibleInformation() {
    const { holdingPatient, openEditPatient } = useHoldingPatient()

    return <div className='PatientResponsibleInformation'>
        <div className='PatientResponsibleInformation-Header'>
            <div className='PatientResponsibleInformation-Header-Icon'>R</div>
            <div className='PatientResponsibleInformation-Header-Title'>Responsável</div>
        </div>
        <div>
            <HorizontalField
                label='Nome'
                content={holdingPatient?.person_responsible_name}
            />
            <HorizontalField
                label='CPF'
                content={MaskCpf(holdingPatient?.person_responsible_cpf)}
            />
            <HorizontalField 
                label='Parentesco'
                content={KINSHIP_CHOICES.find(({id}) => id === holdingPatient?.person_responsible_kinship)?.name}
            />
            <HorizontalField 
                label='Telefone'
                content={holdingPatient?.person_responsible_contact_phone ? MaskTelefone(holdingPatient.person_responsible_contact_phone) : ''}
            />
        </div>
        <div className='PatientResponsibleInformation-ActionRow'>
            <div 
                className='PatientResponsibleInformation-ActionRow-Btn' 
                onClick={openEditPatient}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}
