export function getFiltersetCategoryID(activities, all=false) {
    let GETALL = all || activities.filter(activity => activity.checked).length === 0

    let data = GETALL ? activities : activities.filter(activity => activity.checked)

    let queryset = data.map((activity, idx) => {
            if (idx === 0) return activity.id
            return `%2C${activity.id}`
    }).join('')

    return queryset
}

export function formatDate(operator) {
    let date = new Date()
    let days;
    switch(true) {
        case operator === '6_months':
            days = 182.5
            break
        case operator === '3_months':
            days = 91.25
            break
        case operator === '1_month':
            days = 30.5
            break
        case operator === '7_days':
            days = 7
            break
        default:
            return null
    }
    date.setDate(date.getDate() - days)

    let day, month, year;
    month = '' + (date.getMonth() + 1)
    day = '' + date.getDate()
    year = date.getFullYear()

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}