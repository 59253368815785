import './TreponemalTestWrite.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Button, { ButtonModel } from '../../../../../../../../../../../../../../../newcomponents/button/button'
import NewSelect from '../../../../../../../../../../../../../../../newcomponents/form/select/select'
import AlertRowText, { AlertRowTextType } from '../../../../../../../../../../../../../../../component/alertRowText/alertRowText'
import { AddAlert, AlertType, DataTransform, Input } from '../../../../../../../../../../../../../../../component'
import Toggle from '../../../../../../../../../../../../../../../newcomponents/toggle/toggle'
import { getTreponemalResult } from '../utils'
import api from '../../../../../../../../../../../../../../../helpers/api'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../../../../../newcomponents/modal/modal'
import getCurrentDate from '../../../../../../../../../../../../../../../utils/getCurrentDate'
import { buildDate, formatISO8601 } from '../../../../../../../../../../../../../../../utils/convertDate'
import { getYearsStart1900 } from '../../../../../../../../../../../../../../../utils/getYearsStart1900'

const selectOptions_data = require('../../../../../../../../../../../../../../../component/form/select/options.json')

function strpTreponemalTest(exam={}) {
	let { day, month, year } = getCurrentDate()

	let date = exam?.date ? new Date(exam.date) : ''

	return {
		"id": exam?.id,
		"result": exam?.result || 'NOT_EVALUATED',
		"previous_treatment": exam?.previous_treatment || false,
		"health_surveillance_email": exam?.health_surveillance_email || '',
		"health_surveillance_health_unit": exam?.health_surveillance_health_unit || '',
		"date_day": date ? `${date.getDate() < 10 ? '0': ''}${date.getDate()}` : day,
		"date_month": date ? `${date.getMonth() + 1 < 10 ? '0': ''}${date.getMonth() + 1}` : month,
		"date_year": date ? `${date.getFullYear()}` : year,
	}
}

function parseTreponemalTest(exam={}) {
	let date = buildDate(exam?.date_year, exam?.date_month, exam?.date_day, '10', '10')

	return {
		"id": exam?.id,
		"result": exam?.result || 'NOT_EVALUATED',
		"previous_treatment": exam?.previous_treatment || false,
		"health_surveillance_email": exam?.health_surveillance_email || '',
		"health_surveillance_health_unit": exam?.health_surveillance_health_unit || '',
		"date": formatISO8601(date),
	}
}

const HealthSurveillanceNoticationForm = ({ setSurveillanceNotificationModal, data, setData, selectedLanguage, setModalInfo=() => null }) => {
	const {
		Savereport,
		Healthunit,
		Epidemiologicalsurveillance,
		Treponemaltest,
	} = require('../../../../../../../../../../../../../../data/translation.json')[selectedLanguage]

	const handleSubmit = () => {
		setSurveillanceNotificationModal(false)
	}

	const handleChange = event => {
		setData(prev => ({ ...prev, [event.target.name]: event.target.value }))
	}

	return (
		<>
			<p className='TreponemalTestWrite-title'>
				{`${Treponemaltest} - ${Epidemiologicalsurveillance}`}
			</p>

			<div className='TreponemalTestWrite-NotifyHealthSurveillance-Input'>
				<b>E-mail</b>
				<Input
					name='health_surveillance_email'
					action={handleChange}
					value={data.health_surveillance_email}
					placeholder='ex: saúde.epidemiologia@jaraguadosul.sc.gov.br'
					autoComplete='off'
				/>

				<b>{Healthunit}</b>
				<Input
					name='health_surveillance_health_unit'
					action={handleChange}
					value={data.health_surveillance_health_unit}
					autoComplete='off'
				/>
			</div>
			<div className='AddNewExamModal-ButtonRow'>
				<Button
					label={Savereport}
					onClick={handleSubmit}
				/>
			</div>
		</>
	)
}

export default function TreponemalTestWrite({ holdingTreponemalTest=null, setModalInfo = () => null }) {
	const dispatch = useDispatch()
	const { fetchThousandDaysProjects, holdingProject: { hiv_hbs_antihcv_vdrl_exams } } = useThousandDays()
	const [surveillanceNotificationModal, setSurveillanceNotificationModal] = useState(false)
	const last_exam = hiv_hbs_antihcv_vdrl_exams.length
		? hiv_hbs_antihcv_vdrl_exams[0]
		: null
	const last_exam_non_treponemal_result = last_exam?.vdrl || ''
	const [data, setData] = useState(strpTreponemalTest(holdingTreponemalTest))
	const { selectedLanguage, holdingProject } = useThousandDays()

	const {
		Reagent,
		Notreagent,
		Notevaluated,
		Indeterminate,
		Greaterthan,
		Savedexam,
		Notsavedexam,
		Treponemaltest,
		Result,
		In,
		Vdrltestnotperformed,
		Recordofprevioustreatment,
		Reporttoepidemiologicalsurveillance,
		Savetreponemaltest,
		Notconfirmedsyphillis,
		NotDidTrepomic,
		Select_Months_Options,
		Day,
		Week,
		Year,
		date,
		NOT_EVALUATED
	} = require('../../../../../../../../../../../../../../data/translation.json')[selectedLanguage]

	const translate = {
		REAGENT: Reagent,
		NON_REAGENT: Notreagent,
		INDETERMINATE: Indeterminate,
		NOT_EVALUATED: Notevaluated,
		ONE_BY_ONE: '1 / 1',
		ONE_BY_TWO: '1 / 2',
		ONE_BY_FOUR: '1 / 4',
		ONE_BY_EIGHT: '1 / 8',
		ONE_BY_SIXTEEN: '1 / 16',
		ONE_BY_THIRTY_TWO: '1 / 32',
		ONE_BY_SIXTY_FOUR: '1 / 64',
		GREATER_ONE_BY_SIXTY_FOUR: `${Greaterthan} 1 / 64`,
	}

	const handleSelect = event => {
		setData(prev => ({ ...prev, [`${event.target.id}`]: event.target.selected }))
	}

	const handleSubmit = async () => {
		const payload = {
			...parseTreponemalTest(data),
			person: holdingProject.patient.id,
			project: holdingProject.id,
			non_treponemal_result: data.result === 'REAGENT' ? last_exam?.id : null,
		}

		try {
			if (payload?.id) {
				await api.patch(`/exam/treponemal_test/${payload.id}/`, payload)
			} else {
				await api.post('/exam/treponemal_test/', payload)
			}
			dispatch(AddAlert(Treponemaltest, Savedexam, AlertType.SUCCESS))
			fetchThousandDaysProjects()
			setModalInfo(MODAL_INIT_PROPS)
		} catch (err) {
			dispatch(AddAlert(Treponemaltest, Notsavedexam, AlertType.ERROR))
			console.error("TreponemalTestWrite ~ handleSubmit: ", err)
		}
	}

	const togglePreviousTreatment = () => {
		setData(prev => ({ ...prev, previous_treatment: !prev.previous_treatment }))
	}

	const date_months_options = selectOptions_data.months.map((option) => ({ id: option.id, name: Select_Months_Options[option.id] }))

	if (surveillanceNotificationModal) {
		return (
			<HealthSurveillanceNoticationForm
				setSurveillanceNotificationModal={setSurveillanceNotificationModal}
				data={data}
				setData={setData}
				selectedLanguage={selectedLanguage}
				setModalInfo={setModalInfo}
			/>
		)
	}

	return (
		<>
			<div>
				<b>{date}</b>
				<div className='ABOGroupRHFactorWrite-Date'>
					<NewSelect
						id='date_day'
						options={selectOptions_data.days}
						selected={data.date_day}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Day}
					/>
					<NewSelect
						id='date_month'
						options={date_months_options}
						selected={data.date_month}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Week}
					/>
					<NewSelect
						id='date_year'
						options={getYearsStart1900()}
						selected={data.date_year}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Year}
					/>
				</div>
			</div>
			<div className='TreponemalTestWrite-Content'>
				<div className='TreponemalTestWrite-Data'>
					<NewSelect
						id='result'
						label={Result}
						options={[
							{ id: 'NOT_EVALUATED', name: Notevaluated },
							{ id: 'REAGENT', name: Reagent },
							{ id: 'NON_REAGENT', name: Notreagent },
						]}
						onSelect={handleSelect}
						selected={data.result}
						canBeEmpty={false}
					/>
				</div>
				{data.result === 'REAGENT' ? (
					<div>
						<div className='TreponemalTestWrite-NonTreponemalResult'>
							<AlertRowText
								type={AlertRowTextType.INFO}
								content={
									last_exam?.date
										? `VDRL: ${
												translate[last_exam_non_treponemal_result]
										  } ${In} ${DataTransform(last_exam.date, {
												format: 'DD/MM/YYYY',
										  })}`
										: Vdrltestnotperformed
								}
							/>
						</div>
						{last_exam_non_treponemal_result !== 'REAGENT' ? (
							<Toggle
								label={Recordofprevioustreatment}
								value={data.previous_treatment}
								onChange={togglePreviousTreatment}
							/>
						) : null}
					</div>
				) : null}
				<AlertRowText
					{...getTreponemalResult(
						{
							...data,
							non_treponemal_result: last_exam,
						},
						Notconfirmedsyphillis,
						NotDidTrepomic,
						NOT_EVALUATED
					)}
				/>
			</div>
			<div>
				{(data.result === 'REAGENT' &&
					last_exam_non_treponemal_result === 'REAGENT') ||
				(data.result === 'REAGENT' && !data.previous_treatment) ? (
					<div className='TreponemalTestWrite-Notify-Health-Surveillance-Button'>
						<Button
							label={Reporttoepidemiologicalsurveillance}
							onClick={() => setSurveillanceNotificationModal(true)}
							model={ButtonModel.SECONDARY}
						/>
					</div>
				) : null}
			</div>
			<div className='AddNewExamModal-ButtonRow'>
				<Button
					label={Savetreponemaltest}
					onClick={handleSubmit}
				/>
			</div>
		</>
	)
}

/**
 *
 *
 */
