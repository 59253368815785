import './VideoCallScreen.css'
import { AddAlert, AlertType, IcoCamera, IcoMicrofone, IcoTelefone } from '..'
import PipIcon from '../icon/pip'
import React, { useEffect, useRef, useState } from 'react'
import Video from 'twilio-video'
import Loading from '../../newcomponents/loading/loading'
import { useDispatch } from 'react-redux'

const VideoCallScreen = ({ token, setToken }) => {
	const dispatch = useDispatch()
	const [room, setRoom] = useState(null)
	const [roomErr, setRoomErr] = useState(false)
	const [numParticipants, setNumParticipants] = useState(0)
	const localVideoRef = useRef()
	const remoteVideoRef = useRef()
	const [callConfig, setCallConfig] = useState({ video: true, mic: true })
	const [pipActive, setPipActive] = useState(false)

	const handleError = error => {
		console.error('Error in joinRoom:', error)
		console.log(`Unable to join room: ${error.message}`)
		let errorMessage =
			'Um error inesperado aconteceu. Verifique sua conexão e tente novamente.'

		if (error instanceof DOMException) {
			switch (error.name) {
				case 'NotFoundError':
					errorMessage =
						'Câmera ou microfone não encontrado. Certifique-se de que seus dispositivos estejam conectados e funcionando.'
					break
				case 'NotAllowedError':
				case 'PermissionDeniedError':
					errorMessage = 'Conceda acesso à sua câmera e microfone.'
					break
				case 'NotReadableError':
					errorMessage =
						'A câmera ou o microfone já está em uso ou o acesso foi negado. Por favor, feche outros aplicativos usando os dispositivos e tente novamente.'
					break
			}
		}

		setRoomErr(true)
		// Display the error message to the user
		dispatch(AddAlert('Ligação de vídeo', errorMessage, AlertType.ERROR, 7500))
	}

	useEffect(() => {
		if (token) {
			joinRoom()
		}

		return () => {
			if (room) {
				room.disconnect()
			}
		}
	}, [token])

	useEffect(() => {
		if (room) {
			setNumParticipants(room.participants.size + 1) // Include local participant

			room.on('participantConnected', () => {
				setNumParticipants(prevNum => prevNum + 1)
			})

			room.on('participantDisconnected', () => {
				setNumParticipants(prevNum => prevNum - 1)
			})
		}
	}, [room])

	const twoParticipants = numParticipants > 1 ? 'twoParticipants' : ''

	const joinRoom = async () => {
		try {
			setRoomErr(false)
			await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
			const room = await Video.connect(token, {
				video: { height: 700 },
				audio: true,
			})

			setRoom(room)

			// Check if localVideoRef has any child elements and remove them
			while (localVideoRef.current.firstChild) {
				localVideoRef.current.removeChild(localVideoRef.current.firstChild)
			}

			room.localParticipant.tracks.forEach(trackPublication => {
				if (trackPublication.track) {
					localVideoRef.current.appendChild(trackPublication.track.attach())
				}
			})

			room.participants.forEach(participant => {
				participant.tracks.forEach(trackPublication => {
					if (trackPublication.track) {
						if (trackPublication.track.kind === 'video') {
							const videoTrack = trackPublication.track
							remoteVideoRef.current.appendChild(videoTrack.attach())
						} else if (trackPublication.track.kind === 'audio') {
							const audioTrack = trackPublication.track
							const audioElement = audioTrack.attach()
							audioElement.muted = false
							remoteVideoRef.current.appendChild(audioElement)
						}
					}
				})

				participant.on('trackSubscribed', track => {
					if (track.kind === 'video') {
						const videoElement = remoteVideoRef.current.querySelector('video')
						if (videoElement) {
							remoteVideoRef.current.removeChild(videoElement)
						}
						const videoTrack = track
						remoteVideoRef.current.appendChild(videoTrack.attach())
					} else if (track.kind === 'audio') {
						const audioTrack = track
						const audioElement = audioTrack.attach()
						audioElement.muted = false
						remoteVideoRef.current.appendChild(audioElement)
					}
				})
			})

			room.on('participantConnected', participant => {
				participant.on('trackSubscribed', track => {
					if (track.kind === 'video') {
						const videoElement = remoteVideoRef.current.querySelector('video')
						if (videoElement) {
							remoteVideoRef.current.removeChild(videoElement)
						}
						const videoTrack = track
						remoteVideoRef.current.appendChild(videoTrack.attach())
					} else if (track.kind === 'audio') {
						const audioTrack = track
						const audioElement = audioTrack.attach()
						audioElement.muted = false
						remoteVideoRef.current.appendChild(audioElement)
					}
				})

				participant.tracks.forEach(trackPublication => {
					if (trackPublication.isSubscribed) {
						if (trackPublication.track.kind === 'video') {
							const videoTrack = trackPublication.track
							remoteVideoRef.current.appendChild(videoTrack.attach())
						} else if (trackPublication.track.kind === 'audio') {
							const audioTrack = trackPublication.track
							const audioElement = audioTrack.attach()
							audioElement.muted = false
							remoteVideoRef.current.appendChild(audioElement)
						}
					}
				})
			})

			room.on('participantDisconnected', participant => {
				participant.tracks.forEach(trackPublication => {
					if (trackPublication.track) {
						if (trackPublication.track.kind === 'video') {
							const videoTrack = trackPublication.track
							videoTrack.detach().forEach(element => {
								if (remoteVideoRef.current.contains(element)) {
									remoteVideoRef.current.removeChild(element)
								}
							})
						} else if (trackPublication.track.kind === 'audio') {
							const audioTrack = trackPublication.track
							audioTrack.detach().forEach(element => {
								if (remoteVideoRef.current.contains(element)) {
									remoteVideoRef.current.removeChild(element)
								}
							})
						}
					}
				})

				const audioElement = remoteVideoRef.current.querySelector('audio')
				if (audioElement) {
					remoteVideoRef.current.removeChild(audioElement)
				}
				const videoElement = remoteVideoRef.current.querySelector('video')
				if (videoElement) {
					remoteVideoRef.current.removeChild(videoElement)
				}
			})
		} catch (error) {
			handleError(error)
		}
	}

	const toggleMic = async () => {
		if (!room) return

		room.localParticipant.audioTracks.forEach(trackPublication => {
			const track = trackPublication.track
			if (track.isEnabled) {
				track.disable()
				setCallConfig(prev => ({ ...prev, mic: false }))
			} else {
				track.enable()
				setCallConfig(prev => ({ ...prev, mic: true }))
			}
		})
	}

	const toggleCamera = async () => {
		if (!room) return

		room.localParticipant.videoTracks.forEach(trackPublication => {
			const track = trackPublication.track
			if (track.isEnabled) {
				track.disable()
				setCallConfig(prev => ({ ...prev, video: false }))
			} else {
				track.enable()
				setCallConfig(prev => ({ ...prev, video: true }))
			}
		})
	}

	const handleEndCall = () => {
		if (room) {
			room.disconnect()
			setToken('')
		}
	}

	const togglePIP = () => {
		if (!document.pictureInPictureEnabled) {
			console.log('O modo PIP não é suportado neste navegador.')
			return
		}

		const videoElement = localVideoRef.current.querySelector('video')
        const remoteElement = remoteVideoRef.current.querySelector('video')

		if (
			(remoteElement || videoElement) &&
			typeof videoElement.requestPictureInPicture === 'function'
		) {
			if (!document.pictureInPictureElement) {
                if (remoteElement) {
                    remoteElement.requestPictureInPicture().catch(error => {
                        console.error('Erro ao entrar em modo PIP:', error)
                    })
                }
				videoElement.requestPictureInPicture().catch(error => {
					console.error('Erro ao entrar em modo PIP:', error)
				})
				setPipActive(true)
			} else {
				document.exitPictureInPicture().catch(error => {
					console.error('Erro ao sair do modo PIP:', error)
				})
				setPipActive(false)
			}
		} else {
			console.error(
				'Elemento de vídeo ou método requestPictureInPicture não encontrado ou não suportado.'
			)
		}
	}

	if (roomErr) return null
	if (!room || !room?.localParticipant)
		return (
			<div className='VideoCallScreen-Loading'>
				<Loading />
			</div>
		)

	return (
		<div className='VideoCallScreen'>
			<div className={`VideoCallScreen-Container ${twoParticipants}`}>
				<div
					ref={localVideoRef}
					className='VideoCallScreen-Local'></div>
				<div
					ref={remoteVideoRef}
					className='VideoCallScreen-Remote'></div>
			</div>
			{room?.localParticipant ? (
				<div className='VideoCallScreen-Footer'>
					<button
						onClick={() => togglePIP()}
						className={`VideoCallScreen-Footer-Btn ${
							pipActive ? 'active' : ''
						}`}>
						<PipIcon />
					</button>
					<button
						onClick={toggleMic}
						className={`VideoCallScreen-Footer-Btn ${
							!callConfig.mic ? 'VideoCallScreen-BtnSlash' : ''
						}`}>
						<IcoMicrofone />
					</button>
					<button
						onClick={toggleCamera}
						className={`VideoCallScreen-Footer-Btn ${
							!callConfig.video ? 'VideoCallScreen-BtnSlash' : ''
						}`}>
						<IcoCamera />
					</button>
					<button
						onClick={handleEndCall}
						className='VideoCallScreen-Footer-EndBtn'>
						<IcoTelefone />
					</button>
				</div>
			) : null}
		</div>
	)
}

export default VideoCallScreen
