import './HealthPlaceInventoryAdmin.css'
import React, { useEffect, useState } from "react";
import { useConfiguration } from "../../../../../configuration";
import HealthPlaceInventoryAdminModal from './HealthPlaceInventoryAdminModal/HealthPlaceInventoryAdminModal';
import { MODAL_INIT_PROPS } from '../../../../../../../newcomponents/modal/modal';
import { stringifyCollaboratorOption } from '../../../../../../../utils/stringifyCollaboratorOption';
import { useApp } from '../../../../../../../layout/App';
import { getCollaborators } from '../../../../../../collaborators/http';


export default function HealthPlaceInventoryAdmin({ accessConfiguration={}, fetchInventoryConfig= async () => null }) {
    const { setModalProps } = useConfiguration()
    const [inventoryAdmins, setInventoryAdmins] = useState([])
    const { currentHealthPlaceUser } = useApp()

    useEffect(() => {
        fetchInventoryAdmins()
    }, [accessConfiguration?.admins?.length])

    const fetchInventoryAdmins = async (params={}) => {
        if (accessConfiguration?.admins?.length) {
            await getCollaborators({
                ...params,
                health_place__id: currentHealthPlaceUser?.health_place?.id,
                limit: 500,
                has_person: true,
                id__in: accessConfiguration?.admins || []
            })
                .then(res => setInventoryAdmins(res.data.results.map((instance) => stringifyCollaboratorOption(instance))))
                .catch(err => {
                    console.error('HealthPlaceInventoryAdmin ~ fetchInventoryAdmins ~ ', err)
                    setInventoryAdmins([])
                })
        } else {
            setInventoryAdmins([])
        }
	}

    return <div className='HealthPlaceInventoryAdmin'>
        <div className='HealthPlaceInventoryAdmin-Header'>
            <div className='HealthPlaceInventoryAdmin-Header-Icon'>A</div>
            <div className='HealthPlaceInventoryAdmin-Header-Title'>Administradores</div>
        </div>
        <div>
            {
                inventoryAdmins?.length
                ? inventoryAdmins.map(({ str }) => <p>{str}</p>)
                : <p>Sem administradores de estoque cadastrados.</p>
            }
            {
                accessConfiguration?.admins?.map(inventoryAdmin => console.log(inventoryAdmin))
            }
        </div>
        <div className='HealthPlaceInventoryAdmin-ActionRow'>
            <div
                className='HealthPlaceInventoryAdmin-ActionRow-Btn' 
                onClick={() => setModalProps(prev => ({
                    ...prev,
                    open: true,
                    title: 'Configurar Estoque',
                    content: <HealthPlaceInventoryAdminModal 
                        initAccessConfiguration={accessConfiguration}
                        extraAction={async () => {
                            await fetchInventoryConfig()
                            setModalProps(MODAL_INIT_PROPS)
                        }}
                    />,
                    dismissText: '',
                }))}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}