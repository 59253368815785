import './TemplateTypeSection.css'
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import NewButton from "../../../../../../newcomponents/button/button";
import Modal, { MODAL_INIT_PROPS } from '../../../../../../newcomponents/modal/modal';
import NewEditHoldingTemplate from './subcomponents/NewEditHoldingTemplate/NewEditHoldingTemplate';
import { getTemplateTypes } from '../../../../http';
import { AddAlert, AlertType, Paginate } from '../../../../../../component';
import { NewDocumentIcon } from '../../../../../../component/icon/newDocument';


export default function TemplateTypeSection({ initHoldingTemplate=null, selectTemplateTypeConfirm=() => null }) {
    const dispatch = useDispatch()
    const [modalInfoV2, setModalInfoV2] = useState(MODAL_INIT_PROPS)
    const [templateTypes, setTemplateTypes] = useState([])
    const [holdingTemplate, setHoldingTemplate] = useState(initHoldingTemplate)
    const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })

    useEffect(() => {
        fetchTemplateTypes({ offset: 0 })
    }, [])

    const fetchTemplateTypes = async (params={}) => {
        params = { offset: 0, limit: 6, active: true, ...params }

        try {
            let response = await getTemplateTypes(params)
            setTemplateTypes(response.data.results)
            setPagination({
				totalElements: response.data.count,
				totalPages: Math.ceil(response.data.count / 6),
				pageNumber: params.offset / 6,
			})
        } catch (err) {
            console.error("TemplateTypeSection ~ fetchTemplateTypes ~ ", err)
            dispatch(AddAlert('Template de Prontuário', 'Falha ao obter templates', AlertType.ERROR))
        }
    }

    const extraActionHoldingTemplateModal = async () => {
        await fetchTemplateTypes({ offset: 0 })
        setModalInfoV2(MODAL_INIT_PROPS)
    }

    const openNewHoldingTemplateModal = () => {
        setModalInfoV2(prev => ({
            ...prev,
            open: true,
            title: 'Novo Template',
            content: <NewEditHoldingTemplate 
                extraAction={extraActionHoldingTemplateModal}
            />
        }))
    }

    const openEditHoldingTemplateModal = () => {
        setModalInfoV2(prev => ({
            ...prev,
            open: true,
            title: 'Editar Template',
            content: <NewEditHoldingTemplate
                initHoldingTemplate={holdingTemplate}
                extraAction={extraActionHoldingTemplateModal}
            />
        }))
    }

    const handleNextPage = e => {
        fetchTemplateTypes({ offset: e * 6 })
	}

    const handleSelectTemplate = () => {
        selectTemplateTypeConfirm(holdingTemplate?.id)
    }

    return <div className="TemplateTypeSection">
        <Modal {...modalInfoV2} dismissFn={() => setModalInfoV2(MODAL_INIT_PROPS)} />
        <div className='TemplateTypeSection-ActionRow'>
            <div
                onClick={openNewHoldingTemplateModal}
                title="Novo Template"
            >
                <NewDocumentIcon />
            </div>
        </div>
        {
            templateTypes?.length
            ? <span>Selecione o template de prontuário que deseja utilizar para esse atendimento</span>
            : <span>Nenhum template foi localizado</span>
        }
        <div className='TemplateTypeSection-Manager'>
            {
                templateTypes.map((holdingTemplateType, index) => {
                    return <div 
                        key={index} 
                        className={`TemplateTypeSection-Manager-Instance ${holdingTemplateType?.id === holdingTemplate?.id ? 'active' : ''}`}
                        onClick={() => setHoldingTemplate(holdingTemplateType)}
                    >
                        <div className='TemplateTypeSection-Manager-Instance-File'>
                            <div className='TemplateTypeSection-Manager-Instance-File-TopLeft'></div>
                            <span className="TemplateTypeSection-Manager-Instance-Title">
                                {holdingTemplateType?.name}
                            </span>
                            <span className='TemplateTypeSection-Manager-Instance-Subtitle'>{holdingTemplateType?.specialty?.name || ''}</span>
                        </div>
                    </div>
                })
            }
        </div>
        {
            holdingTemplate?.can_edit
            ? <div className='TemplateTypeSection-AltActionBox'>
                <div 
                    className='TemplateTypeSection-AltActionBox-Btn'
                    onClick={openEditHoldingTemplateModal}
                >
                    <b>Editar Template</b>
                </div>
            </div>
            : null
        }

        {
            /*
        <div className='TemplateTypeSection-BtnBox-Btn'>
            <NewButton 
                label='Editar'
                model={ButtonModel.SECONDARY}
                disabled={!holdingTemplate}
                onClick={openEditHoldingTemplateModal}
            />
        </div>
            */
        }
        <div className='TemplateTypeSection-Paginate'>
            <Paginate data={pagination} action={handleNextPage} />
        </div>
        <div className='TemplateTypeSection-BtnBox'>
            <NewButton
                label='Confirmar seleção'
                onClick={handleSelectTemplate}
                disabled={!holdingTemplate}
            />
        </div>
    </div>
}