import './PatientExistentHandlerSameHealthPlace.css'
import React, { useEffect } from "react";
import { MODAL_INIT_PROPS } from '../../../../../newcomponents/modal/modal';
import Button, { ButtonModel } from '../../../../../newcomponents/button/button';

export default function PatientExistentHandlerSameHealthPlace({ 
        patient_name='', 
        patient_id=null, 
        document_type='', 
        document='',
        patient_birthday='',
        patient_mother_name='',
        setSecondaryModalInfo=() => null,
        handlePatientSubmission=() => null,
        inputValues=null,
        forcedSubmissionAllowed=null,
        callback=() => null}) {
    const [birthday_year, birthday_month, birthday_day] = patient_birthday.split('-')

    return <div className="PatientExistentHandlerSameHealthPlace">
        <p>
            Já existe um registro para <b>{patient_name || 'esse paciente'}</b>, data de nascimento <b>{patient_birthday ? `${birthday_day}/${birthday_month}/${birthday_year}` : '-não cadastrado-'}</b>,
             nome da mãe <b>{patient_mother_name ? patient_mother_name : '-não cadastrado-'}</b> e <b>{document_type ? document_type : 'documento'} {document ? document : '-não cadastrado-'}</b>. 
            Deseja visualizar o registro existente{forcedSubmissionAllowed ? ', retornar para ajustar ou  prosseguir com o cadastro?': ' ou retornar para ajustar?'}
        </p>
        <div className='PatientExistentHandlerSameHealthPlace-BtnBox'>
            <Button 
                label='Retornar para ajustar'
                onClick={() => setSecondaryModalInfo(MODAL_INIT_PROPS)}
                model={ButtonModel.SECONDARY}
            />
            <Button 
                label='Ver paciente cadastrado'
                onClick={() => {
                    setSecondaryModalInfo(MODAL_INIT_PROPS)
                    window.open(`/pacientes/${patient_id}/detalhar`)
                }}
            />
           {forcedSubmissionAllowed 
                ? <Button 
                label='Prosseguir o cadastro'
                onClick={() => {
                    handlePatientSubmission(inputValues, {}, (res=null) => {                 
                        setSecondaryModalInfo(MODAL_INIT_PROPS)
                        if (res) callback(res)
                        else callback()
                    }, true)
                }}/>
                : null}
        </div>
    </div>
}