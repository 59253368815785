export const adicionaZero = (numero) => {
  if (numero <= 9)
      return `0${numero}`;
  else
      return numero;
}

export const MaskTelefone = (valor) => {
  valor = valor || ''
  valor = valor.replace(/\D/g, '');

  if (valor.length === 10) {
    return valor.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
  } else if (valor.length === 11) { 
    return valor.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
  }
  return valor;
}

export const MaskItemCep = (valor) => {
  let valueNew = ''
  valueNew = valor.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2')
  return valueNew
}

export const MaskCpf = (value) => {
  if (!value) return ''
  return value
  .replace(/\D/g, '')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1-$2')
}

export const MaskRG = (value) => {
  if (!value) return ''
  console.log('value ~', value)
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4');
}

export const MaskData = (valor) => {
  let valueNew = ''
  valueNew = valor
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d{2})(\d{2})/, '$3/$2/$1')
  return valueNew
}

export const UmaskData = (valor) => {
  let valueNew = ''
  valueNew = valor
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{2})(\d{4})/, '$3-$2-$1')
  return valueNew
}

export const DataTransform = (valor, options) => {
  let valueNew = ''
  let data = new Date(valor);
  if (options?.format) {
    const formato = options.format.split('/');
    const formType = {
      'DD': adicionaZero(data.getDate()),
      'MM': adicionaZero(data.getMonth()+1),
      'YYYY': data.getFullYear()
    }
    valueNew = `${formType[formato[0]]}/${formType[formato[1]]}/${formType[formato[2]]}`;
  } else {
    valueNew = `${adicionaZero(data.getDate())}/${adicionaZero(data.getMonth()+1)}/${data.getFullYear()}`;
  }
  return valueNew
}

export const MaskDataHora = (valor, options, split = '/', volta = '/') => {
  let valueNew = {data: '', hora: ''}
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (regex.test(valor)) {
    valor += 'T00:00:00';
  }

  let data = new Date(valor);
  if (isNaN(data.getTime())) {
    return { data: '', hora: '' };
  }

  if (options?.format) {
    const formato = options.format.split(split);
    const formType = {
      'DD': adicionaZero(data.getDate()),
      'MM': adicionaZero(data.getMonth()+1),
      'YYYY': data.getFullYear()
    }
    const formatoHora = options.format.split(':');
    const formTypeHora = {
      'HH': adicionaZero(data.getHours()),
      'MM': adicionaZero(data.getMinutes()),
      'SS': adicionaZero(data.getSeconds())
    }

    valueNew.data = `${formType[formato[0]]}${volta}${formType[formato[1]]}${volta}${formType[formato[2]]}`;
    valueNew.hora = `${formTypeHora[formatoHora[0]]}:${formTypeHora[formatoHora[1]]}:${formTypeHora[formatoHora[2]]}`;
  } else {
    valueNew.data = `${adicionaZero(data.getDate())}${volta}${adicionaZero(data.getMonth()+1)}${volta}${data.getFullYear()}`;
    valueNew.hora = `${adicionaZero(data.getHours())}:${adicionaZero(data.getMinutes())}:${adicionaZero(data.getSeconds())}`;
  }
  return valueNew
}

export const MaskCNPJ = (valor) => {
  let valueNew = ''
  if (!valor) return ''
  if (valor.length < 14) {
    valueNew = valor
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2}\.\d{3})(\d)/, '$1.$2')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
  } else {
    valueNew = valor
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
  }

  return valueNew
}


export const idade = (dataNascimento) => {
  if (!dataNascimento) {
    return { anos: null, meses: null };
  }

  const dataAtual = new Date();
  const anoAtual = dataAtual.getFullYear();
  const mesAtual = dataAtual.getMonth() + 1;
  const diaAtual = dataAtual.getDate();

  const dataAniversario = new Date(dataNascimento);
  const anoAniversario = dataAniversario.getFullYear();
  const mesAniversario = dataAniversario.getMonth() + 1;
  const diaAniversario = dataAniversario.getDate();

  let idade = anoAtual - anoAniversario;

  if (mesAtual < mesAniversario || (mesAtual === mesAniversario && diaAtual < diaAniversario)) {
    idade--;
  }

  if (idade <= 0) {
    const meses = 12 + (mesAtual - mesAniversario) + (diaAtual < diaAniversario ? -1 : 0);
    return { meses: meses < 0 ? 0 : meses };
  }

  return { anos: idade < 0 ? 0 : idade };
}

export const maskSnellenChart = (value) => {
  const hasNumerator = (typeof value === 'string' && value.startsWith('20/'))
  if (typeof value === 'number')  return `20/${20 / value}`
  if (!value || !hasNumerator) return '20/'
  return value
}

export const MaskSUSCard = (value) => {
  if (!value) return ''
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
}

export function MaskYYYY_MM_DDT00_00_00(yyyy_mm_ddT00_00_00) { // yyyy_mm_ddT00_00_00: 2025-02-02T07:00:00
  const [datePart, timePart] = yyyy_mm_ddT00_00_00.split('T');
  
  if (!datePart || !timePart) {
    return { data: '', hora: '' };
  }

  const [year, month, day] = datePart.split('-');
  
  const [hours, minutes, seconds] = timePart.split(':');
  
  const formattedDate = `${day}/${month}/${year}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;
  
  return {
    "date": formattedDate,
    "hour": formattedTime
  };
}