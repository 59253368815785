import React from 'react'

import './loading.css'

export default function Loading({size}) {
    return <div className={`loading lds-spinner ${size}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
}

export function LoadingPage() {
    return <div className='LoadingPage'>
        <Loading />
    </div>
}