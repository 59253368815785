import React from 'react'

export default function PrinterIcon({style={}}) {
    return <svg style={style} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M1476 5104 c-168 -41 -316 -173 -379 -339 -21 -58 -22 -72 -25 -492
            l-3 -433 -294 0 c-174 0 -316 -4 -347 -11 -196 -40 -367 -207 -413 -400 -22
            -96 -22 -1642 0 -1738 35 -148 148 -289 285 -353 41 -20 99 -41 128 -47 31 -7
            173 -11 347 -11 l294 0 3 -433 c3 -420 4 -434 25 -492 47 -122 141 -231 254
            -290 130 -68 85 -66 1237 -63 l1037 3 64 23 c155 56 276 175 334 329 21 56 22
            72 25 490 l3 433 294 0 c174 0 316 4 347 11 201 42 375 216 417 417 15 74 15
            1630 0 1704 -30 147 -147 301 -277 367 -109 55 -155 61 -484 61 l-297 0 -3
            433 c-3 418 -4 434 -25 490 -58 154 -179 273 -334 329 l-64 23 -1045 2 c-876
            1 -1054 -1 -1104 -13z m2157 -218 c66 -25 149 -104 178 -168 24 -53 24 -55 27
            -465 l3 -413 -1281 0 -1281 0 3 413 c3 406 3 413 26 462 25 55 78 115 131 147
            74 47 78 47 1141 45 953 -2 1007 -3 1053 -21z m1077 -1287 c80 -37 125 -81
            162 -157 l33 -67 0 -815 0 -815 -33 -67 c-37 -76 -82 -120 -162 -157 l-55 -26
            -300 0 -300 0 -5 396 c-5 434 -3 419 -67 446 -48 20 -2798 20 -2846 0 -64 -27
            -62 -12 -67 -446 l-5 -396 -300 0 -300 0 -67 33 c-76 37 -120 82 -157 162
            l-26 55 0 815 0 815 26 55 c47 99 116 159 218 186 36 10 500 12 2121 11 l2075
            -2 55 -26z m-872 -2306 l-3 -838 -24 -53 c-31 -68 -113 -144 -183 -169 -50
            -17 -109 -18 -1068 -18 -959 0 -1018 1 -1068 18 -70 25 -152 101 -183 169
            l-24 53 -3 838 -2 837 1280 0 1280 0 -2 -837z"/>
            <path d="M1774 1696 c-41 -18 -64 -52 -64 -96 0 -45 23 -78 66 -96 51 -21 463
            -20 509 2 31 14 65 64 65 94 0 30 -34 80 -65 94 -45 22 -463 23 -511 2z"/>
            <path d="M1758 1263 c-56 -35 -66 -117 -19 -164 l29 -29 676 0 c476 0 682 3
            700 11 60 28 71 122 20 170 l-26 24 -676 2 c-618 2 -679 1 -704 -14z"/>
            <path d="M1739 821 c-49 -49 -36 -134 25 -166 28 -14 95 -16 703 -13 l671 3
            26 24 c51 48 40 142 -20 170 -18 8 -224 11 -700 11 l-676 0 -29 -29z"/>
        </g>
    </svg>
}