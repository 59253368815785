import './CurrentUserDropdown.css';
import React, { useState, useEffect, useRef } from "react";
import getInitials from '../../../utils/getInitials';
import UserLogoutModal from '../../modals/UserLogoutModal';
import { userRoles } from '../../../utils/userRoles';
import { useApp } from '../../App';
import { getAppointmentServiceLocation } from '../../../pages/configuration/subcomponents/HealthUnitConfig/HealthPlaceInstitutionalMenu/http';
import { patchCollaborator } from '../../../pages/collaborators/http';

export default function CurrentUserDropdown() {
    const { currentHealthPlaceUser, currentUser, loadHealthPlaceUser, setModalInfo, setCurrentHealthPlaceUser, isHealthProfessional } = useApp();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [serviceLocationOptions, setServiceLocationOptions] = useState([]);
    const dropdownRef = useRef(null);

    useEffect(() => {
        fetchServiceLocationsOptions();
    }, []);

    const name = currentUser?.person?.name || currentUser?.email;
    const profile = currentHealthPlaceUser?.profile;

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = () => {
        setDropdownOpen(false);
        setModalInfo({ open: true, title: 'Logout', content: <UserLogoutModal setModalInfo={setModalInfo} /> });
    };

    const handleSwitchUser = () => {
        setDropdownOpen(false);
        loadHealthPlaceUser(null);
    };

    const handleSelectConsultorio = async (event) => {
        setCurrentHealthPlaceUser(prev => ({ ...prev, "current_service_location": serviceLocationOptions?.find(({ id }) => id === Number(event.target.value)) }))
        const payload = { "current_service_location": event.target.value || null }
        await patchCollaborator(currentHealthPlaceUser?.id, payload)
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const fetchServiceLocationsOptions = async () => {
        try {
            let res = await getAppointmentServiceLocation({ active: true });
            setServiceLocationOptions(res.data.results);
        } catch (err) {
            setServiceLocationOptions([]);
            console.error('fetchServiceLocationsOptions ~ ', err);
        }
    };

    return (
        <div className='CurrentUserDropdown' ref={dropdownRef}>
            <div className='CurrentUserDropdown-header' onClick={toggleDropdown}>
                <div className='CurrentUserDropdown-avatar'>
                    {
                        currentUser?.person?.avatar_img_url ?
                            <img alt='doctor profile' src={currentUser?.person?.avatar_img_url} title={name} />
                            : <h5>{getInitials(name)}</h5>
                    }
                </div>

                <div className='CurrentUserDropdown-NamesInfo'>
                    <b>{name}</b><br />
                    <span>
                    {userRoles?.[profile]?.title || '-'} | {currentHealthPlaceUser?.health_place?.name}
                    </span>
                </div>
            </div>

            {dropdownOpen &&
                <div className='CurrentUserDropdown-menu'>
                    <div className='CurrentUserDropdown-menu-profileInfo'>
                        <p className='CurrentUserDropdown-menu-profileInfo-email'>{currentUser?.email}</p>
                        <div className='CurrentUserDropdown-menu-profileInfo-avatar'>
                            {
                                currentUser?.person?.avatar_img_url ?
                                    <img alt='doctor profile' src={currentUser?.person?.avatar_img_url} title={name} />
                                    : <h5>{getInitials(name)}</h5>
                            }
                        </div>
                        <p className='CurrentUserDropdown-menu-profileInfo-hi'>Olá, {name}!</p>
                        <span>{currentHealthPlaceUser?.health_place?.name} | {userRoles?.[profile]?.title || '-'}</span>
                    </div>
                    {
                        isHealthProfessional
                        ? <div className='CurrentUserDropdown-menu-select'>
                            <span>Atendendo em:</span>
                            <div className='CurrentUserDropdown-SelectBox'>
                                <select
                                    value={currentHealthPlaceUser?.current_service_location?.id}
                                    onChange={handleSelectConsultorio}
                                    className='CurrentUserDropdown-select'
                                >
                                    <option value="">Selecione um consultório</option>
                                    {serviceLocationOptions.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        : null
                    }
                    
                    {currentUser?.health_place_users?.length > 1 &&
                        <div className='CurrentUserDropdown-menu-item' onClick={(e) => { e.stopPropagation(); handleSwitchUser(); }}>
                            Trocar Unidade de Saúde
                        </div>
                    }
                    <div className='CurrentUserDropdown-menu-item' onClick={(e) => { e.stopPropagation(); handleLogout(); }}>
                        Sair
                    </div>
                </div>
            }
        </div>
    );
}
