import React from 'react'

export default function MedicalTeamIco({ style={} }) {
    return <svg style={style} viewBox="0 0 512.000000 512.000000">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M2433 5110 c-172 -31 -347 -142 -447 -284 -82 -116 -119 -210 -137
            -350 -9 -68 -14 -82 -37 -100 -206 -158 -366 -420 -423 -691 -35 -168 -21
            -235 50 -235 52 0 71 26 82 115 51 425 363 782 774 890 479 124 981 -105 1200
            -550 83 -168 115 -315 115 -530 l0 -101 -102 4 c-95 3 -105 5 -125 28 -17 19
            -23 37 -23 73 0 158 -75 313 -185 381 -19 12 -35 25 -35 28 0 4 28 25 61 46
            65 42 84 72 69 110 -10 26 -46 56 -69 56 -8 0 -62 -29 -121 -64 -342 -206
            -771 -357 -1160 -410 -87 -12 -143 -67 -156 -154 -12 -78 -26 -86 -156 -93
            -131 -7 -184 -25 -243 -84 -55 -55 -80 -116 -79 -195 1 -75 18 -121 64 -174
            50 -57 109 -87 187 -94 l67 -7 14 -85 c37 -224 162 -438 338 -579 43 -35 88
            -68 101 -74 23 -10 23 -12 23 -184 0 -193 -5 -214 -62 -256 -18 -13 -199 -95
            -403 -182 -203 -86 -391 -169 -417 -183 -62 -33 -148 -124 -183 -191 -49 -98
            -55 -152 -55 -564 l0 -378 25 -24 c30 -30 64 -32 99 -4 l26 20 0 383 c0 320 3
            391 16 436 29 96 81 160 169 203 57 28 451 197 459 197 3 0 6 -106 6 -235 l0
            -235 -147 0 c-164 0 -202 -10 -259 -68 -65 -65 -69 -84 -69 -327 0 -208 1
            -222 22 -261 56 -106 191 -165 257 -113 52 41 19 129 -50 129 -12 0 -36 11
            -51 24 l-28 24 -3 191 -3 191 30 30 29 30 352 0 351 0 24 -25 c25 -24 25 -25
            25 -220 0 -195 0 -196 -25 -220 -13 -14 -36 -25 -50 -25 -38 0 -61 -16 -73
            -48 -12 -34 6 -85 33 -96 55 -21 161 17 209 75 53 62 56 81 56 316 0 249 -6
            272 -79 334 -57 50 -98 59 -260 59 l-141 0 0 211 c0 179 2 210 14 206 8 -3
            131 -83 273 -178 291 -195 324 -210 416 -187 38 10 120 59 310 187 142 95 265
            175 273 178 12 4 14 -39 14 -288 l0 -294 -55 -24 c-69 -29 -146 -111 -169
            -177 -20 -61 -20 -157 0 -218 22 -64 100 -147 166 -177 79 -35 194 -34 265 4
            114 59 177 160 177 283 1 126 -69 232 -186 287 l-48 22 0 317 c0 175 3 318 6
            318 8 0 402 -169 459 -197 88 -43 140 -107 169 -203 13 -45 16 -116 16 -436
            l0 -383 26 -20 c33 -26 65 -27 95 -2 l24 19 3 374 c3 407 -2 469 -47 565 -37
            78 -126 172 -198 210 -32 17 -220 100 -418 184 -198 84 -375 163 -393 176 -57
            42 -62 63 -62 255 l1 173 44 28 c218 138 374 380 419 647 l12 75 65 6 c35 3
            82 15 105 26 147 77 196 264 103 393 -29 39 -29 41 -29 180 0 242 -25 397 -93
            563 -67 167 -200 352 -326 454 -56 46 -59 51 -66 103 -10 92 -35 186 -66 251
            -119 251 -348 405 -617 414 -59 2 -130 0 -159 -5z m250 -155 c157 -33 309
            -152 380 -297 42 -86 60 -155 36 -145 -170 73 -275 103 -427 119 -189 20 -394
            -11 -573 -87 -46 -19 -85 -35 -88 -35 -12 0 12 80 44 147 27 54 56 95 104 142
            142 142 329 198 524 156z m374 -1301 c98 -47 140 -123 152 -272 8 -105 17
            -129 65 -180 52 -56 80 -65 209 -72 133 -7 158 -18 191 -84 16 -32 17 -44 7
            -75 -6 -20 -22 -48 -37 -62 -23 -21 -37 -25 -131 -30 -132 -7 -138 -12 -148
            -131 -10 -109 -46 -234 -98 -333 -32 -61 -65 -103 -142 -180 -86 -87 -113
            -108 -195 -148 -130 -65 -228 -89 -365 -89 -142 -1 -232 20 -360 82 -166 81
            -284 199 -365 365 -53 110 -75 189 -86 308 -9 112 -17 119 -142 126 -117 7
            -148 23 -171 88 -13 36 -12 43 5 78 33 67 58 78 191 85 125 6 148 14 201 64
            43 39 62 77 71 134 l6 45 135 23 c273 46 591 146 835 264 68 32 92 32 172 -6z
            m-707 -1786 c150 -37 390 -24 523 27 16 7 17 -4 17 -137 0 -106 4 -157 16
            -191 23 -67 93 -140 170 -178 35 -17 64 -33 64 -34 0 -1 -124 -84 -275 -185
            -202 -135 -283 -183 -305 -183 -22 0 -103 48 -305 183 -151 101 -275 184 -275
            186 0 2 27 15 60 30 74 33 151 113 174 180 12 35 16 86 16 192 0 133 1 144 18
            137 9 -3 55 -16 102 -27z m1012 -1410 c100 -56 114 -184 29 -261 -64 -57 -150
            -62 -211 -10 -42 35 -60 73 -60 128 0 128 131 205 242 143z"/>
            <path d="M2130 3107 c-37 -19 -51 -54 -47 -119 4 -70 25 -98 74 -98 55 0 73
            27 73 113 0 58 -4 77 -17 89 -27 24 -55 29 -83 15z"/>
            <path d="M2940 3113 c-41 -15 -50 -35 -50 -110 0 -60 4 -77 20 -93 27 -27 81
            -26 106 1 28 31 27 150 -1 178 -23 22 -53 32 -75 24z"/>
            <path d="M2265 2610 c-22 -24 -24 -72 -4 -96 23 -28 84 -62 148 -84 121 -41
            296 -22 399 44 50 31 65 52 65 86 0 68 -71 91 -139 45 -48 -33 -95 -45 -174
            -45 -79 0 -126 12 -174 45 -45 31 -96 33 -121 5z"/>
            <path d="M501 2869 c-101 -18 -200 -99 -237 -195 -17 -43 -19 -77 -19 -294 0
            -230 1 -249 23 -312 30 -87 85 -170 155 -236 l57 -53 0 -104 0 -103 -157 -53
            c-87 -28 -174 -60 -194 -70 -48 -24 -103 -88 -117 -138 -8 -26 -12 -147 -12
            -370 l0 -332 25 -24 c31 -32 69 -32 100 0 l25 24 0 330 c0 328 0 330 22 355
            16 17 65 39 158 70 74 25 138 46 142 46 5 0 8 -85 8 -190 l0 -190 -64 0 c-49
            0 -70 -5 -96 -22 -65 -45 -75 -68 -78 -191 -2 -63 1 -127 7 -148 23 -75 123
            -130 180 -98 56 31 50 110 -9 132 -30 11 -30 12 -30 94 l0 83 165 0 165 0 0
            -85 c0 -70 -3 -85 -15 -85 -25 0 -65 -47 -65 -75 0 -15 11 -37 26 -52 45 -45
            130 -19 182 57 20 30 22 45 22 154 0 107 -3 126 -23 160 -30 53 -77 76 -154
            76 l-63 0 0 135 c0 74 2 135 4 135 2 0 27 -15 55 -34 100 -68 135 -61 295 59
            l118 88 54 -13 c51 -12 57 -12 82 7 33 25 41 76 17 104 -10 10 -46 28 -81 39
            l-64 22 0 103 0 103 45 36 c60 50 129 148 162 232 27 67 27 75 31 317 4 282
            -1 311 -68 396 -28 35 -59 59 -107 83 l-68 33 -275 2 c-151 1 -300 -3 -329 -8z
            m612 -163 c23 -11 47 -35 62 -61 22 -37 25 -53 25 -143 0 -120 8 -116 -142
            -78 -100 25 -213 77 -317 144 -46 31 -74 42 -102 42 -53 0 -73 -9 -168 -80
            l-83 -62 4 76 c4 80 18 110 70 149 43 32 83 36 353 34 232 -2 264 -4 298 -21z
            m-424 -281 c111 -80 307 -154 455 -171 l59 -7 -6 -60 c-15 -165 -160 -315
            -335 -347 -228 -41 -453 138 -468 372 l-5 73 118 87 c65 48 121 87 125 88 5 0
            30 -16 57 -35z m155 -739 c33 3 72 10 88 14 l28 9 0 -67 c0 -37 3 -83 6 -103
            6 -35 5 -37 -77 -98 -46 -34 -87 -62 -91 -64 -4 -1 -46 27 -93 62 -72 53 -86
            68 -81 85 3 12 6 57 6 102 l0 80 78 -13 c46 -8 100 -11 136 -7z"/>
            <path d="M4102 2870 c-124 -18 -239 -106 -299 -228 l-28 -57 0 -235 c0 -233 0
            -236 28 -304 34 -85 101 -180 161 -230 l46 -37 0 -104 0 -103 -64 -22 c-35
            -11 -71 -29 -81 -39 -24 -28 -16 -79 17 -104 25 -19 31 -19 82 -7 l55 13 117
            -88 c64 -48 133 -92 152 -97 52 -14 98 3 192 72 44 33 82 60 85 60 3 0 5 -76
            5 -170 l0 -170 -37 -19 c-111 -56 -154 -195 -97 -309 72 -145 254 -174 371
            -60 108 106 90 281 -38 361 l-49 30 0 183 0 183 103 -35 c158 -53 147 -21 147
            -415 l0 -330 25 -24 c13 -14 36 -25 50 -25 14 0 37 11 50 25 l25 24 0 332 c0
            223 -4 344 -12 370 -14 50 -69 114 -117 138 -20 10 -107 42 -193 70 l-158 53
            0 103 0 104 60 57 c71 68 114 132 148 222 23 61 26 84 30 257 3 129 0 207 -8
            245 -37 167 -165 285 -335 310 -81 11 -355 11 -433 0z m478 -166 c54 -25 100
            -69 126 -121 17 -33 19 -62 19 -238 l0 -200 -32 -67 c-151 -319 -581 -324
            -736 -9 l-32 65 -3 199 c-3 197 -3 199 23 252 36 74 102 123 185 139 14 2 111
            3 215 2 168 -2 195 -4 235 -22z m-304 -1018 c36 -4 90 -1 137 7 l77 13 0 -80
            c0 -45 3 -90 6 -102 5 -17 -9 -32 -78 -83 -46 -34 -86 -62 -90 -64 -4 -1 -46
            27 -93 62 -86 64 -87 65 -81 100 3 20 6 66 6 103 l0 67 28 -9 c16 -4 55 -11
            88 -14z m415 -819 c21 -16 39 -51 39 -77 0 -30 -53 -80 -85 -80 -38 0 -85 50
            -85 90 0 61 83 104 131 67z"/>
        </g>
    </svg>
    
}

