import './RecallTicketModal.css'
import React, { useEffect, useRef, useState } from 'react'
import NewButton from '../../../../../../newcomponents/button/button'
import { useDispatch } from 'react-redux'
import { loaded, loading } from '../../../../../../layout/redux/AppActions'
import { patchRecallPanelServiceTicket } from '../../../../http'
import { AddAlert, AlertType, Input } from '../../../../../../component'
import { uniqueIDs } from '../../../../../../utils/uniqueIDs'
import NewSelect from '../../../../../../newcomponents/form/select/select'
import { getAppointmentServiceLocation } from '../../../../../configuration/subcomponents/HealthUnitConfig/HealthPlaceInstitutionalMenu/http'

export default function RecallTicketModal({ ticket={}, extraAction=async () => null }) {
    const dispatch = useDispatch()
    const searchTimeout = useRef(null)
    const [serviceLocation, setServiceLocation] = useState(ticket?.attendance?.service_location)
    const [serviceLocationOptions, setServiceLocationOptions] = useState([])

    useEffect(() => {
        fetchServiceLocationsOptions()
    }, [])

    const handleSearchServiceLocations = (event) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)

        searchTimeout.current = setTimeout(() => {
            fetchServiceLocationsOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }

    const fetchServiceLocationsOptions = async (params={}) => {
        try {
            let res = await getAppointmentServiceLocation({...params, limit: 500 })
            setServiceLocationOptions(res.data.results)
        } catch (err) {
            setServiceLocationOptions([])
            console.error('fetchServiceLocationsOptions ~ ', err)
        }
    }

    const handleRecallPatient = async () => {
        let payload = {
            "service_location": serviceLocation?.id
        }
        dispatch(loading())
		try {
			await patchRecallPanelServiceTicket(ticket?.id, payload)
			await extraAction({ offset: 0 })
			
			dispatch(AddAlert('Chamar paciente', 'Paciente chamado com sucesso', AlertType.SUCCESS))
            extraAction()
		} catch (err) {
			dispatch(AddAlert('Chamar paciente', 'Falha ao chamar paciente', AlertType.ERROR))
			console.error('RecallTicketModal ~ handleRecallPatient ~ patchRecallPanelServiceTicket ~ ', err)
		}
        dispatch(loaded())
	}
    return <div className='RecallTicketModal'>
        <p className='RecallTicketModal-P'>Deseja chamar o paciente novamente?</p>
        <div>
            <NewSelect 
                label='Local de Atendimento'
                onSelect={event => {
                        setServiceLocation(serviceLocation?.id != event.target.selected 
                            ? serviceLocationOptions?.find(({ id }) => id === event.target.selected)
                            : '')
                        fetchServiceLocationsOptions({ offset: 0 })
                    }
                }     
                canBeEmpty={false}
                options={uniqueIDs(serviceLocation, serviceLocationOptions)}
                optionRefKey='id'
                optionStrKey='name'
                selected={serviceLocation?.id || ''}
                filterNode={<div className='NewAppointmentModal-NewSelect'>
                    <Input 
                        placeholder='Pesquisar por nome'
                        action={handleSearchServiceLocations}
                        actionFocus={()=> fetchServiceLocationsOptions()}
                    />
                </div>
                }
            />
        </div>
        <div className='RecallTicketModal-BtnBox'>
            <NewButton
                label='Confirmar'
                onClick={handleRecallPatient}
            />
        </div>
    </div>
}