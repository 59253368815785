import './ConfirmMergeModal.css'
import Modal from '../../../../../newcomponents/modal/modal'
import React from 'react'

export default function ConfirmMergeModal({show, confirmHandler, dismissHandler}) {

    return <Modal
        open={show}
        title='Confirmar mesclagem'
        dismissText='Não, cancelar'
        confirmText='Sim, mesclar!'
        dismissFn={dismissHandler}
        confirmFn={confirmHandler}
        content={
            <div className='confirm-merge-modal'>
                <div className='confirm-merge-warning'>
                    Atenção!
                </div>

                Ao mesclar paciente, a opção escolhida irá representar o paciente no prontuário, 
                a opção não escolhida será inativada no sistema. A mesclagem de dados irá usar os dados pessoais, 
                foto de perfil, responsável e endereço da opção escolhida e irá unificar os prontuários de atendimento 
                e arquivos anexos em um único prontuário. Uma vez unificado o paciente, essa ação não poderá ser desfeita 
                posteriormente.

                <div className='confirm-merge'>
                    Deseja realmente mesclar o usuário?
                </div>
            </div>
        }
    />

}