import './HealthPlaceInstitutionalPlanAgreement.css'
import React from "react";
import { useConfiguration } from '../../../../../configuration';
import HorizontalField from '../../../../../../../component/HorizontalField/HorizontalField';
import EditInstitutionPartnersModal from './EditInstitutionPartnersModal/EditInstitutionPartnersModal';

export default function HealthPlaceInstitutionalPlanAgreement() {
    const { institution, setModalProps } = useConfiguration()

    return <div className='HealthPlaceInstitutionalPlanAgreement'>
        <div className='HealthPlaceInstitutionalPlanAgreement-Header'>
            <div className='HealthPlaceInstitutionalPlanAgreement-Header-Icon'>P</div>
            <div className='HealthPlaceInstitutionalPlanAgreement-Header-Title'>Planos e Convênios Parceiros</div>
        </div>
        <div className='HealthPlaceInstitutionalPlanAgreement-Body'>
            {
                institution?.health_insurances?.length
                ? institution?.health_insurances.map(health_insurance_cursor => <HorizontalField label={health_insurance_cursor?.name} content={'Ativo'} />) 
                : <p>Nenhum plano ativo</p>
            }
        </div>
        <div className='HealthPlaceInstitutionalPlanAgreement-ActionRow'>
            <div 
                className='HealthPlaceInstitutionalPlanAgreement-ActionRow-Btn' 
                onClick={() => setModalProps(prev => ({
                    ...prev,
                    open: true,
                    title: 'Editar Instituição',
                    content: <EditInstitutionPartnersModal />,
                    dismissText: '',
                }))}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}

