import './PersonalDataProfileImageModal.css'
import React, { useState } from "react"
import { useDispatch } from 'react-redux'
import { Cropper } from 'react-cropper'
import Button from '../../../../../../../../newcomponents/button/button'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import { AddAlert, AlertType, IcoArrowBack, IcoArrowForward } from '../../../../../../../../component'
import ZoomOutIco from '../../../../../../../../component/icon/zoom-out'
import ZoomInIco from '../../../../../../../../component/icon/zoom-in'
import UpIco from '../../../../../../../../component/icon/up'
import DownIco from '../../../../../../../../component/icon/down'
import LeftIco from '../../../../../../../../component/icon/left'
import RightIco from '../../../../../../../../component/icon/right'
import { putMySelfProfileImg } from '../../../../../../http'

export default function PersonalDataProfileImageModal({ setModalInfo=() => null, initHoldingUserData=null, fetchExtraAction=async () => null }) {
    const dispatch = useDispatch()
    const [cropper, setCropper] = useState();
    const defaultUser = require('../../../../../../../../media/DefaultUser.png')

    const handleSubmit = async () => {
        const base64_avatar = cropper?.getCroppedCanvas()?.toDataURL()

        if (base64_avatar && typeof cropper !== "undefined") {
        try {
            await putMySelfProfileImg({"base64_avatar": base64_avatar})
            await fetchExtraAction(base64_avatar)
            dispatch(AddAlert('Editar Foto', 'Foto salva com sucesso!', AlertType.SUCCESS))
            setModalInfo(MODAL_INIT_PROPS)
        } catch (err) {
            console.error('PersonalDataProfileImageModal ~ handleSubmit ~ ', err);
            if (err.response.status === 413) {
                dispatch(AddAlert('Editar Foto', 'É permitido o upload de imagem até 20MB, por favor selecione outro arquivo', AlertType.SUCCESS));
            } else {
                dispatch(AddAlert('Editar Foto', 'Falha ao salvar Foto', AlertType.ERROR));
            }
            
        }}
    }

    return <div className='PersonalDataProfileImageModal'>
        <div className='PersonalDataProfileImageModal-PersonalInfo'>
            <div className='PersonalDataProfileImageModal-Subtitle-Header'>
                <div className='PersonalDataProfileImageModal-Subtitle-Header-Icon PersonalDataProfileImageModal-Subtitle-Header-Icon-Purple'>F</div>
                <div className='PersonalDataProfileImageModal-Subtitle-Header-Title'>Foto</div>
            </div>
        </div>
        <div>
            <div style={{ width: "100%" }}>
                <Cropper
                    style={{ height: 320, width: "100%" }}
                    zoomTo={0.5}
                    initialAspectRatio={1}
                    preview=".PersonalDataProfileImageModal-img-preview"
                    src={initHoldingUserData?.base64_avatar || defaultUser}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                    aspectRatio={1}
                    guides={true}
                />
            </div>
            <br style={{ clear: "both" }} />

            <div className='PersonalDataProfileImageModal-ActionRow'>
                <button className='PersonalDataProfileImageModal-Action' onClick={() => {if (cropper) cropper.zoom(0.1)}}><ZoomInIco /></button>
                <button className='PersonalDataProfileImageModal-Action' onClick={() => {if (cropper) cropper.zoom(-0.1)}}><ZoomOutIco /></button>

                <button className='PersonalDataProfileImageModal-Action' onClick={() => {if (cropper) cropper.move(10, 0)}}><LeftIco /></button>
                <button className='PersonalDataProfileImageModal-Action' onClick={() => {if (cropper) cropper.move(-10, 0)}}><RightIco /></button>
                <button className='PersonalDataProfileImageModal-Action' onClick={() => {if (cropper) cropper.move(0, -10)}}><DownIco /></button>
                <button className='PersonalDataProfileImageModal-Action' onClick={() => {if (cropper) cropper.move(0, 10)}}><UpIco /></button>
            </div>
        </div>

        <div className='PersonalDataProfileImageModal-Btn-Box'>
            <Button 
                onClick={handleSubmit}
                label='Salvar'
            />
        </div>
    </div>
}