import './PasswordData.css'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
	AddAlert,
	AlertType,
	IcoEye,
	IcoEyeBlocked,
	Input,
} from '../../../../../component'
import ActionForm from '../../../../../component/form/actions/actions'
import Button, { ButtonModel } from '../../../../../newcomponents/button/button'
import { isInvalidPassword } from '../../../../../validators'
import api from '../../../../../helpers/api'
import { useApp } from '../../../../../layout/App'

const initFormData = {
	password: '',
	confirmPassword: '',
}

class FormData {
	constructor(data) {
		this.password = data.password
		this.confirmPassword = data.confirmPassword
	}

	getErrors() {
		let errors = {}
		let validationErr = isInvalidPassword(this.password, this.confirmPassword)
		if (validationErr) {
			errors['password'] = validationErr
			errors['confirmPassword'] = true
		}
		return errors
	}
}

export default function PasswordData() {
	const dispatch = useDispatch()
	const history = useHistory()
	const [showPass, setShowPass] = useState(true)
	const [confirmShowPass, setConfirmShowPass] = useState(true)
	const [formData, setFormData] = useState(initFormData)
	const [formDataErr, setFormDataErr] = useState(initFormData)
	const { idUid, idRecuperar } = useParams()

	const handleSubmit = () => {
		const payload = new FormData(formData)
		let errors = payload.getErrors()

		if (Object.keys(errors).length) return setFormDataErr(errors)

		idRecuperar
			? handleRecoverPassword(formData)
			: handleChangePassword(formData)
	}

	const handleRecoverPassword = (formData) => {
		const params = {
			new_password1: formData.password,
			new_password2: formData.confirmPassword,
			uid: idUid,
			token: idRecuperar,
		}
		api
			.post(`/auth/password/reset/confirm/`, params)
			.then(() =>
				dispatch([
					AddAlert(
						'Alterar senha',
						'Senha alterada com sucesso',
						AlertType.SUCCESS
					),
					history.push('/login'),
				])
			)
			.catch(() =>
				dispatch(
					AddAlert(
						'Alterar senha',
						'Não foi possível recuperar senha',
						AlertType.ERROR
					)
				)
			)
	}

	const handleChangePassword = (formData) => {
		const params = {
			new_password1: formData.password,
			new_password2: formData.confirmPassword,
		}

		api
			.post(`/auth/password/change/`, params)
			.then(async () => {
				dispatch(AddAlert('Alterar senha', 'Senha alterada com sucesso!', AlertType.SUCCESS))
			})
			.catch((error) => {
				console.error(error)
				dispatch(
					AddAlert(
						'Alterar senha',
						'Não foi possível alterar senha',
						AlertType.ERROR
					)
				)
			})
	}

	const handleChange = (event) => {
		setFormDataErr({})
		setFormData((prev) => ({
			...prev,
			[`${event.target.name}`]: event.target.value,
		}))
	}

	const handleBlur = () => {
		let validationErr = isInvalidPassword(formData.password, formData.password)
		if (validationErr) setFormDataErr({ password: validationErr })
	}

	return (
		<>
			<div className={idRecuperar ? '' : 'PasswordData-InputRow'}>
				<div>
					<b>Senha</b>
					<Input
						type={showPass ? 'password' : 'text'}
						name='password'
						action={handleChange}
						actionBlur={handleBlur}
						value={formData.password}
						required={{ erro: formDataErr, message: formDataErr.password }}
					>
						<ActionForm
							action={() => setShowPass(!showPass)}
							title={showPass ? 'visivel' : 'invisivel'}
						>
							{showPass ? <IcoEye /> : <IcoEyeBlocked />}
						</ActionForm>
					</Input>
				</div>
				<div>
					<b>Confirmar senha</b>
					<Input
						type={confirmShowPass ? 'password' : 'text'}
						name='confirmPassword'
						action={handleChange}
						value={formData.confirmPassword}
						required={{
							erro: formDataErr,
							message: formDataErr.confirmPassword,
						}}
					>
						<ActionForm
							action={() => setConfirmShowPass(!confirmShowPass)}
							title={confirmShowPass ? 'visivel' : 'invisivel'}
						>
							{confirmShowPass ? <IcoEye /> : <IcoEyeBlocked />}
						</ActionForm>
					</Input>
				</div>
			</div>
			<div
				className={idRecuperar ? 'PasswordData-BtnBox' : 'PasswordData-BtnRow'}
			>
				<Button
					onClick={handleSubmit}
					label='Salvar'
				/>
				{idRecuperar ? (
					<Button
						label='Cancelar'
						onClick={() => history.push('/login')}
						model={ButtonModel.SECONDARY}
					/>
				) : null}
			</div>
		</>
	)
}
