import './PatientAddressData.css'
import React from "react";
import { useHoldingPatient } from '../../../../HoldingPatientInfoView';
import { MaskItemCep } from '../../../../../../component';
import HorizontalField from '../../../../../../component/HorizontalField/HorizontalField';


export default function PatientAddressData() {
    const { openEditAddress, holdingPatient } = useHoldingPatient()

    return <div>
        <div className='PatientAddressData-Header'>
            <div className='PatientAddressData-Header-Icon'>E</div>
            <div className='PatientAddressData-Header-Title'>Endereço</div>
        </div>
        <div>
            <HorizontalField
                label='CEP'
                content={holdingPatient?.address?.zip ? MaskItemCep(holdingPatient?.address?.zip) : '-'}
            />
            <HorizontalField
                label='Cidade'
                content={holdingPatient?.address?.city?.name || '-'}
            />
            <HorizontalField
                label='Estado'
                content={holdingPatient?.address?.city?.state || '-'}
            />
            <HorizontalField
                label='Bairro'
                content={holdingPatient?.address?.district || '-'}
            />
            <HorizontalField
                label='Endereço'
                content={holdingPatient?.address?.address || '-'}
            />
            <HorizontalField
                label='Número'
                content={holdingPatient?.address?.number || '-'}
            />
            <HorizontalField
                label='Complemento'
                content={holdingPatient?.address?.complement || '-'}
            />
        </div>
        <div className='PatientAddressData-ActionRow'>
            <div 
                className='PatientAddressData-ActionRow-Btn' 
                onClick={() => openEditAddress()}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}