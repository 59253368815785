import './PatientFilters.css'
import React, { useEffect, useRef, useState } from "react";
import { Input } from '../../../../component';
import NewSelect from '../../../../newcomponents/form/select/select';
import { NewPatientIcon } from '../../../../component/icon/newPatient';
import { getYearsStart1900 } from '../../../../utils/getYearsStart1900';
import NewPatient from '../NewPatient/NewPatient';

const defaultOptions = require('../../../../component/form/select/options.json')

export default function PatientFilters({ 
        filtersRef=null, 
        birthdayPeriod=null, 
        setBirthdayPeriod=() => null, 
        setModalInfo=() => null, 
        fetchPatientsList=() => null, 
        setIsActive=() => null, 
        isActive=null,
        verifyDuplicates=null,
        setVerifyDuplicates=() => null
    }) {
    const searchTimeOut = useRef(null)
    const [filtersList, setFiltersList] = useState([])

    const nameOrCpfRef = useRef('')
    const medicalRecordNumberRef = useRef('')
    const motherNameRef = useRef('')
    const emailRef = useRef('')
    const cityRef = useRef('')

    useEffect(() => {

        updateFilterList()

		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(() => {
				fetchPatientsList({ offset: 0 })
			}, 300)
		} catch (err) {
			console.error('handleSearch', err)
		}
	}, [birthdayPeriod, isActive, verifyDuplicates])

    const handleSelect = (event) => {
		setBirthdayPeriod(prevData => ({ ...prevData, [`${event.target.id}`]: event.target.selected }))
	}

    const handleNewPatient = () => {
		setModalInfo({
			open: true,
			title: 'Cadastro de Paciente',
			content: <NewPatient setModalInfo={setModalInfo} fetchPatientsList={fetchPatientsList} />,
		})
	}

    const handleSearch = target => {
        if (target){
            filtersRef.current = { ...filtersRef.current, [target.name]: target.value }
        }

        updateFilterList()

		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(async () => {
				await fetchPatientsList({ offset: 0 })
			}, 300)
		} catch (err) {
			console.error('handleSearch', err)
		}
	}

    const updateFilterList = () => {
        const tempFilterList = Object.entries(filtersRef.current)

        if (birthdayPeriod.day || birthdayPeriod.month || birthdayPeriod.year) {
            tempFilterList.push(['birthday', true])
        }

        setFiltersList(() => {
            const newFilters = tempFilterList
                .filter(item => item[1] !== '' && item[0] !== 'offset')
                .map(item => {
                    switch (item[0]) {
                        case 'birthday':
                            return ['Data de nascimento', 'birthday']
                        case 'city':
                            return ['Cidade', 'city']
                        case 'email':
                            return ['E-mail', 'email']
                        case 'name_or_cpf':
                            return ['Paciente', 'name_or_cpf']
                        case 'medical_record_number':
                            return ['Prontuário', 'medical_record_number']
                        case 'mother_name':
                            return ['Nome da mãe', 'mother_name']
                        default:
                            return item[0]
                    }
                })
            
                return newFilters
        })
    }

    const clearFilter = targetFilter => {
        setFiltersList(filtersList.filter(item => item[1] != targetFilter))
        filtersRef.current = { ...filtersRef.current, [targetFilter]: '' }

        switch (targetFilter){
            case 'email':
                emailRef.current.value = ''
                break
            case 'name_or_cpf':
                nameOrCpfRef.current.value = ''
                break
            case 'city':
                cityRef.current.value = ''
                break
            case 'birthday':
                birthdayPeriod.day = ''
                birthdayPeriod.month = ''
                birthdayPeriod.year = ''
                break
            case 'mother_name':
                motherNameRef.current.value = ''
            case 'medical_record_number':
                medicalRecordNumberRef.current.value = ''
        }

        handleSearch()
    }

    return <section className='PatientsFilters'>
        <div className='PatientsFilters-SideBarLeft-ActionRow'>
            <div
                onClick={handleNewPatient}
                title="Adicionar Paciente"
            >
                <NewPatientIcon />
            </div>
        </div>
        <div className='PatientsFilter-Separator' />
        <div>
            <Input
                label='Paciente'
                name='name_or_cpf'
                action={event => handleSearch(event.target)}
                ref={nameOrCpfRef}
                autoComplete='false'
                placeholder='Filtrar por Nome | CPF | RG'
            />
            <Input
                label='Prontuário'
                name='medical_record_number'
                action={event => handleSearch(event.target)}
                ref={medicalRecordNumberRef}
                autoComplete='false'
                placeholder='Filtrar por número de prontuário'
            />
            <Input
                label='Nome da mãe'
                name='mother_name'
                action={event => handleSearch(event.target)}
                ref={motherNameRef}
                autoComplete='false'
                placeholder='Filtrar por nome da mãe'
            />
            <Input
                label='E-mail'
                name='email'
                autoComplete='false'
                action={event => handleSearch(event.target)}
                ref={emailRef}
                placeholder='Filtrar por email'
            />
            <b>Data de Nascimento</b>
            <div className='PatientsFilters-Birthday-Select-Date'>
                <NewSelect
                    id='day'
                    defaultText='Dia'
                    options={defaultOptions.days}
                    onSelect={handleSelect}
                    selected={birthdayPeriod?.day}
                />
                <NewSelect
                    id='month'
                    defaultText='Mês'
                    options={defaultOptions.months}
                    onSelect={handleSelect}
                    selected={birthdayPeriod?.month}
                />
                <NewSelect
                    id='year'
                    defaultText='Ano'
                    options={getYearsStart1900()}
                    onSelect={handleSelect}
                    selected={birthdayPeriod?.year}
                />
            </div>
            <p>
                <b>Localização</b>
            </p>
            <Input
                name='city'
                label='Cidade'
                action={event => handleSearch(event.target)}
                ref={cityRef}
                placeholder='Filtrar por cidade'
            />
        </div>
        <div className='InactivePatients-Box'>
                <input
                    type="checkbox"
                    checked={isActive}
                    onChange={() => setIsActive(prev => !prev)}
                    defaultChecked
                    id='inactivePatientsCheckbox'
                    className='InactivePatients-Checkbox'
                />
                <label for={"inactivePatientsCheckbox"} className='InactivePatients-Label'>Incluir somente pacientes ativos</label>
        </div>
        <div className='verifyDuplicates-Box'>
                <input
                    type="checkbox"
                    checked={verifyDuplicates}
                    onChange={() => setVerifyDuplicates(prev => !prev)}
                    id='verifyDuplicatesCheckbox'
                    className='verifyDuplicates-Checkbox'
                />
                <label for={"verifyDuplicatesCheckbox"} className='verifyDuplicates-Label'>Verificar pacientes duplicados</label>
        </div>
        <div className='PatientsActiveFilters'>
            <p className='PatientsActiveFilters-Title'>Filtros Ativos:</p>

            <ul className='PatientsActiveFilters-List'>
                {filtersList.map((item, index) => { 
                    return (
                        <li key={index} className=''>
                            <button onClick={() => clearFilter(item[1])} className='PatientsActiveFilters-Button'>
                                <p className='PatientsActiveFilters-Name'>
                                    {item[0]}
                                </p>
                                <div className='PatientsActiveFilters-Separator'/>
                                <span className='PatientsActiveFilters-Remove'>x</span>
                            </button>
                        </li>
                    )
                })}
            </ul>
        </div>

    </section>
}