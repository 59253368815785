import './ConfirmServedTicketModal.css'
import React from 'react'
import NewButton from '../../../../../../newcomponents/button/button'
import { useDispatch } from 'react-redux'
import { loaded, loading } from '../../../../../../layout/redux/AppActions'
import { patchCallPanelServiceTicket } from '../../../../http'
import { AddAlert, AlertType } from '../../../../../../component'

export default function ConfirmServedTicketModal({ ticket={}, extraAction=async () => null }) {
    const dispatch = useDispatch()

    const handleConfirmServed = async () => {
        dispatch(loading())
		try {
			await patchCallPanelServiceTicket(ticket?.id, { "status": 'SERVED' })
			await extraAction({ offset: 0 })
			
			dispatch(AddAlert('Chamado atendido', 'Chamado atendido com sucesso', AlertType.SUCCESS))
            extraAction()
		} catch (err) {
			dispatch(AddAlert('Chamado atendido', 'Falha ao atender chamado', AlertType.ERROR))
			console.error('ConfirmServedTicketModal ~ handleConfirmServed ~ patchCallPanelServiceTicket ~ ', err)
		}
        dispatch(loaded())
	}
    return <div className='ConfirmServedTicketModal'>
        <p>O paciente entrou no local de atendimento?</p>
        <div className='ConfirmServedTicketModal-BtnBox'>
            <NewButton
                label='Confirmar'
                onClick={handleConfirmServed}
            />
        </div>
    </div>
}