import React from 'react'

export default function IcoHealthProfile({ style }) {
    return <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M771 4964 l-21 -27 0 -2379 0 -2379 25 -24 24 -25 1441 0 1441 0 38
        38 c21 20 137 141 257 267 120 127 258 271 306 321 l88 91 0 2045 0 2045 -21
        27 -20 26 -1769 0 -1769 0 -20 -26z m3439 -2069 l0 -1935 -296 0 c-173 0 -303
        -4 -314 -10 -35 -19 -40 -64 -40 -366 l0 -294 -1325 0 -1325 0 0 2270 0 2270
        1650 0 1650 0 0 -1935z m-144 -2126 c-51 -60 -331 -349 -338 -349 -5 0 -8 86
        -8 190 l0 190 186 0 186 0 -26 -31z"/>
        <path d="M1705 4568 c-42 -25 -44 -36 -45 -240 l0 -198 -190 0 c-119 0 -198
        -4 -214 -11 -43 -20 -47 -49 -44 -293 3 -226 3 -228 27 -247 21 -17 41 -19
        222 -19 l199 0 0 -194 c0 -107 3 -202 6 -211 15 -39 54 -45 284 -45 l222 0 29
        29 29 29 0 196 0 196 198 0 199 0 24 26 c24 26 24 26 24 261 l0 235 -28 24
        c-28 24 -31 24 -223 24 l-194 0 0 194 c0 192 0 195 -24 223 l-24 28 -229 2
        c-161 2 -234 -1 -248 -9z m365 -348 c0 -269 -19 -250 250 -250 l200 0 0 -125
        0 -125 -190 0 c-199 0 -239 -7 -254 -45 -3 -9 -6 -104 -6 -211 l0 -194 -125 0
        -125 0 0 196 0 196 -29 29 -29 29 -196 0 -196 0 0 125 0 125 198 0 c139 1 202
        4 213 13 35 27 39 54 39 246 l0 191 125 0 125 0 0 -200z"/>
        <path d="M2563 3320 c-47 -19 -58 -92 -20 -128 l23 -22 606 0 c334 0 614 3
        623 6 24 10 46 60 39 89 -3 14 -18 34 -31 45 -25 19 -42 20 -622 19 -328 0
        -606 -4 -618 -9z"/>
        <path d="M1294 2768 c-47 -22 -56 -86 -19 -123 l24 -25 1246 0 1246 0 24 25
        c33 33 32 70 -4 106 l-29 29 -1234 -1 c-827 0 -1240 -4 -1254 -11z"/>
        <path d="M1281 2263 c-34 -29 -37 -74 -8 -109 l25 -29 1234 -2 c981 -2 1238 0
        1258 10 38 19 55 61 40 98 -7 16 -21 34 -31 39 -11 6 -483 10 -1257 10 -1166
        0 -1241 -1 -1261 -17z"/>
        <path d="M1278 1740 c-25 -26 -29 -36 -24 -63 3 -18 16 -40 27 -49 20 -17 95
        -18 1261 -18 774 0 1246 4 1257 10 10 5 24 23 31 39 15 37 -2 79 -40 98 -20
        10 -283 13 -1253 13 l-1229 0 -30 -30z"/>
        <path d="M1281 1253 c-11 -10 -24 -32 -27 -50 -5 -27 -1 -37 24 -63 l30 -30
        1229 0 c970 0 1233 3 1253 13 38 19 55 61 40 98 -7 16 -21 34 -31 39 -11 6
        -483 10 -1257 10 -1166 0 -1241 -1 -1261 -17z"/>
        <path d="M1269 751 c-16 -16 -29 -37 -29 -46 0 -24 27 -72 45 -79 9 -3 288 -6
        619 -6 595 0 604 0 630 21 32 25 35 75 7 110 l-19 24 -612 3 -611 2 -30 -29z"/>
        </g>
    </svg>
}

