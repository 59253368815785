import React, { useState } from 'react'
import { AddAlert, AlertType, Input, MaskCpf, MaskTelefone } from '../../../../../component'
import api from '../../../../../helpers/api'
import Button, { ButtonModel } from '../../../../../newcomponents/button/button'
import NewSelect from '../../../../../newcomponents/form/select/select'
import { isValidCPF, isValidDate, isValidEmail } from '../../../../../validators'
import LoginDentistModal from '../../modal/LoginDentistModal'
import { useDispatch } from 'react-redux'
import { loaded, loading } from '../../../../../layout/redux/AppActions'
import { getYearsStart1900OlderThan18 } from '../../../../../utils/getYearsStart1900'

const initPersonData = {
    email: '',
    name: '',
    cpf: '',
    sex: '',
    contact_phone: '',
    birthday_day: '',
    birthday_month: '',
    birthday_year: '',
}


        
class PersonForm {
    constructor(data) {
        let words = data.name.trim().split(' ')
        for (let i = 0; i < words.length; i++) {
            if (words[i]) words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
        }
        this.email = data.email.trim()
        this.name = words.join(' ').trim()
        this.cpf = data.cpf.replace(/\D/g, '')
        this.sex = data.sex
        this.contact_phone = data.contact_phone.replace(/\D/g, '')
        this.birthday = `${data?.birthday_year}-${data?.birthday_month}-${data?.birthday_day}`
    }

    getErrors() {
        let errors = {}
        Object.entries(this).map(([key, value]) => {
            switch(true) {
                case key === 'contact_phone' && !([10, 11].includes(value.length)):
                    errors['contact_phone'] = 'Contato inválido'
                    break
                case key === 'birthday' && !isValidDate(value):
                    errors['birthday_day'] = 'Data inválida'
                    errors['birthday_month'] = true
                    errors['birthday_year'] = true
                    break
                case key === 'cpf' && !isValidCPF(value):
                    errors['cpf'] = 'CPF inválido'
                    break
                case key === 'email' && !isValidEmail(value):
                    errors['email'] = 'E-mail inválido'
                    break
                default:
                    if (!value) errors[key] = 'Campo obrigatório'
            }
            return null
        })
        return errors
    }

    format() {
        let data = { ...this, profile: 'DENTIST' }
        data['user'] = {
            email: data.email,
            person: {
                name: data.name,
                birthday: data.birthday,
                cpf: data.cpf,
                sex: data.sex,
                contact_phone: data.contact_phone,
            },
        }
        delete data.email
        delete data.name
        delete data.birthday
        delete data.cpf
        delete data.sex
        delete data.contact_phone
        return data
    }
}

export default function IdentifyDentistPerson({ setPage, setModalProps }) {
    const dispatch = useDispatch()
    const [personData, setPersonData] = useState(initPersonData)
    const [personDataErr, setPersonDataErr] = useState(initPersonData)
    const defaultOptions = require('../../../../../component/form/select/options.json')

    const handleChange = (event) => {
        setPersonDataErr(prev => ({ ...prev, [event.target.name]: '' }))
        switch (event.target.name) {
            case 'contact_phone':
                setPersonData(prev => ({ ...prev, contact_phone: MaskTelefone(event.target.value)}))
                break
            case 'cpf':
                setPersonData(prev => ({ ...prev, cpf: MaskCpf(event.target.value) }))
                break
            default:
                setPersonData(prev => ({ ...prev, [event.target.name]: event.target.value }))
        }
    }
    
    const handleSelect = (event) => {
        setPersonDataErr(prev => ({ ...prev, [event.target.id]: '' }))
        setPersonData(prev => ({ ...prev, [event.target.id]: event.target.selected }))
    }

    const handleSubmit = () => {
        const payload = new PersonForm(personData)
        let errors = payload.getErrors()
        if (Object.keys(errors).length) return setPersonDataErr(errors)
        
        dispatch(loading())
        api.post('health_place_user/new/', payload.format())
            .then(res => {
                dispatch(loaded())
                const user = res.data.user
                localStorage.setItem('token', res.data.key)
                localStorage.setItem('user', JSON.stringify(res.data.user))
            })
            .catch(error => {
                dispatch([
                    loaded(),
                    AddAlert('Registro', 'Falha ao registrar usuário', AlertType.ERROR),
                ])
                console.error(error)
            })
    }

    const checkIfCPFAlreadyExists = async (cpf) => {
        cpf = cpf.replace(/\D/g, '')
        if (!cpf) return
        api.get(`/person/cpf/exist/${cpf}/`)
        .then(() => setPersonDataErr(prev => ({ ...prev, cpf: 'CPF já cadastrado'})))
    }

    const checkIfEmailAlreadyExist = async (email) => {
        api.get(`/person/email/exist/${email}/`)
            .then(() => setModalProps({open: true, title: 'Você já possui registro', content: <LoginDentistModal email={personData.email} /> }))
    }
    
    return <>
        <Input 
            label='E-mail' 
            name='email' 
            value={personData.email}
            action={handleChange}
            actionBlur={event => checkIfEmailAlreadyExist(event.target.value)}
            errors={{ error: personDataErr, message: personDataErr?.email }}
        />
        <Input 
            label='Nome'
            name='name'
            value={personData.name}
            action={handleChange}
            errors={{ error: personDataErr, message: personDataErr?.name }}
        />
        <div className='PersonalData-ChildBirthRow'>
            <b>Data de nascimento</b>
            <div>
                <NewSelect
                    id='birthday_day'
                    defaultText='Dia'
                    options={defaultOptions.days}
                    onSelect={handleSelect}
                    selected={personData.birthday_day}
                    error={personDataErr?.birthday_day}
                />
                <NewSelect
                    id='birthday_month'
                    defaultText='Mês'
                    options={defaultOptions.months}
                    onSelect={handleSelect}
                    selected={personData.birthday_month}
                    error={personDataErr?.birthday_month}
                />
                <NewSelect 
                    id='birthday_year'
                    defaultText='Ano'
                    options={getYearsStart1900OlderThan18()}
                    onSelect={handleSelect}
                    selected={personData.birthday_year}
                    error={personDataErr?.birthday_year}
                />
            </div>
        </div>
        <Input
            label='CPF'
            name='cpf'
            value={personData.cpf}
            maxLength={14}
            action={handleChange}
            actionBlur={event => checkIfCPFAlreadyExists(event.target.value)}
            errors={{ error: personDataErr, message: personDataErr?.cpf }}
        />
        <div className='PersonalData-RowCol2'>
            <Input 
                label='Telefone'
                name='contact_phone'
                maxLength={15}
                value={personData.contact_phone}
                action={handleChange}
                errors={{ error: personDataErr, message: personDataErr?.contact_phone }}
            />
            <NewSelect 
                id='sex'
                label='Gênero'
                canBeEmpty={false}
                options={defaultOptions.SEX_CHOICES}
                onSelect={handleSelect}
                selected={personData.sex}
                error={personDataErr?.sex}
            />
        </div>
        <div className='ManageAuthenticationPage-BtnFullWidth'>
            <Button 
                label='Próximo'
                onClick={handleSubmit}
            />
        </div>
        <div className='ManageAuthenticationPage-BtnFullWidth'>
            <Button 
                label='Voltar'
                model={ButtonModel.SECONDARY}
                onClick={() => setPage('1')}
            />
        </div>
    </>
}