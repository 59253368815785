import React, { useEffect, useRef } from 'react'
import { useContext } from 'react'

export const WsEvents = {}

const WsContext = React.createContext()

const wsScheme = process.env.NODE_ENV === 'production' ? 'wss' : 'ws'
const wsHost = process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST ? process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST : window.location.hostname

export default function WebsocketContext({children}) {

    const socket = useRef()
    const handlers = useRef({})

    useEffect(() => {

        //connect()
//
        //let pingInterval = setInterval(() => socket.current.send('"PING"'), 20000)
//
        //return () => clearInterval(pingInterval)

        // eslint-disable-next-line
    }, [])

//    const connect = () => {
//
//        socket.current = new WebSocket(`${wsScheme}://${wsHost}/ws/socket.io/?authorization=Token ${localStorage.token}`);
//
//        socket.current.onopen = e => {
//            console.log('Listening to events')
//        }
//
//        socket.current.onmessage = handleMessage
//
//        socket.current.onclose = e => {
//            console.log('websocket disconected, reconnecting...')
//            //setTimeout(connect, 5000)
//        }
//
//
//    }
//
    const handleMessage = packet => {

        if(JSON.parse(packet.data) === 'PONG'){
            return
        }

        for(let event in handlers.current) {
            let eventType = JSON.parse(packet.data)
            let eventHandlerType = event.split(':')[0]

            if(eventHandlerType === eventType)
                handlers.current[event]()
        }
    }

    const setHandler = (event, origin, handlerFn) => {
        handlers.current[`${event}:${origin}`] = handlerFn
    }

    return <WsContext.Provider value={{
        setHandler: setHandler
    }}>
        {children}
    </WsContext.Provider>
}

export function useWebsocket() {
    return useContext(WsContext)
}