import './PatientDeathRecordModal.css'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../../../../newcomponents/modal/modal'
import { Input } from '../../../../../../component'
import NewMultiSelect from '../../../../../../newcomponents/form/select/multiselect'
import { getCIDTen } from '../../../../../consultation/http'

export default function PatientDeathRecordModal({show, submitModal, patient}) {
    const [cidOptions, setCidOptions] = useState([])
    const [inputValues, setInputValues] = useState({date_of_death: '', death_reason: '', death_cid: [], death_declaration_number: ''})
    const [errors, setErrors] = useState({})
    const searchTimeout = useRef(null)


    useEffect(()=> {
        fetchCIDTenCodes()
    }, [])

    const fetchCIDTenCodes = async (params={}) => {
        try {
            const cids = await getCIDTen(params)
            setCidOptions(cids)
        } catch (err) {
            setCidOptions([])
        }
    }

    if(!patient) return null

    const handleInputChange = (event) => {
        setErrors({})
        const { name, value } = event.target;
        setInputValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const toggleOption = ({ optionRef }) => {
        setErrors({})
        let selected = cidOptions.find(cid => cid.code === optionRef)
        let isExisting = inputValues.death_cid.find(cid => cid.code === optionRef)

        if (isExisting) {
            setInputValues(prev => ({ ...prev, death_cid: prev.death_cid.filter(cid => cid.code !== optionRef) }));
        } else {
            setInputValues(prev => ({ ...prev, death_cid: [...prev.death_cid, selected] }));
        }
    };

    const handleSubmission = () => {
        const requiredFields = ['date_of_death', 'death_reason', 'death_declaration_number', 'death_cid'];
        let newErrors = {};
    
        requiredFields.forEach(field => {
            if (!inputValues[field] || (field === 'death_cid' && inputValues[field].length === 0)) {
                newErrors[field] = 'Preencha os campos obrigatórios.';
            }
        });
    
        if (patient.birthday > inputValues.date_of_death) {
            newErrors.date_of_death = 'A data do óbito não pode ser anterior à data de nascimento do paciente.';
        }
    
        setErrors(newErrors);
    
        if (Object.keys(newErrors).length === 0) {
            submitModal(inputValues);
        }
    }

    const searchRelatedDiseasesProblems = (search) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)

        searchTimeout.current = setTimeout((search) => {
            fetchCIDTenCodes({ search });
        }, 400, search)
    };

    return <Modal
        open={show}
        title='Registrar Óbito de Paciente'
        dismissText='Cancelar'
        confirmText='Enviar'
        dismissFn={() => submitModal(null)}
        confirmFn={handleSubmission}
        content={
            <div className='death-record-modal'>
                    <div className='death-record-input-box'>
                        <Input
                            label='Data *'
                            type='date'
                            name='date_of_death'
                            value={inputValues.date_of_death}
                            action={handleInputChange}
                            errors={{ error: errors, message: errors?.date_of_death }}
                        />
                    </div>
                    <div className='death-record-input-box'>
                        <Input
                            label='Descrição *'
                            type='textarea'
                            name='death_reason'
                            value={inputValues.death_reason}
                            action={handleInputChange}
                            errors={{ error: errors, message: errors?.death_reason }}
                        />
                    </div>
                    <div className='death-record-input-box'>
                        <NewMultiSelect
                        filterNode={<div>
                            <Input 
                                placeholder='Pesquisar por CID ou Título'
                                action={({ target: { value } }) => searchRelatedDiseasesProblems(value)}
                                actionFocus={()=> fetchCIDTenCodes()}
                            />
                        </div>}
                        label='CID-10 *'
                        defaultText='Selecione a(s) causa(s)'
                        onlyDefaultText
                        onSelect={toggleOption}
                        selectedOptions={inputValues.death_cid?.map(cid => cid.code) || []}
                        optionRefKey='code'
                        optionStrKey='title'
                        options={cidOptions.map(cid => ({ ...cid, title: `${cid.code} | ${cid.title}` }))}
                        error={errors.death_cid ? errors.death_cid : ''}
                        />
                    </div>
                    <div className='death-record-cids-selected'>
                        {inputValues.death_cid.map(({code, title}, id) => <div key={id} className='death-record-cid-item'>{code} | {title}</div>)}
                    </div>
                    <div className='death-record-input-box'>
                        <Input
                            label='Número da declaração de óbito *'
                            type='text'
                            name='death_declaration_number'
                            value={inputValues.death_declaration_number}
                            action={handleInputChange}
                            errors={{ error: errors, message: errors?.death_declaration_number }}
                        />
                    </div>
                    <p>*campos obrigatórios</p>
                </div>
        }
    />

}