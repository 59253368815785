import './UltrasonographyWrite.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../../../../../../../../../../../../../../newcomponents/button/button'
import NewSelect from '../../../../../../../../../../../../../../../newcomponents/form/select/select'
import AlertRowText from '../../../../../../../../../../../../../../../component/alertRowText/alertRowText'
import api from '../../../../../../../../../../../../../../../helpers/api'
import { AddAlert, AlertType, Input } from '../../../../../../../../../../../../../../../component'
import { getAmnioticFluidResults, getPlacentaResults } from '../utils'
import { isValidDate } from '../../../../../../../../../../../../../../../validators'
import { buildDate, formatISO8601 } from '../../../../../../../../../../../../../../../utils/convertDate'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import getCurrentDate from '../../../../../../../../../../../../../../../utils/getCurrentDate'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../../../../../newcomponents/modal/modal'

const selectOptions_data = require('../../../../../../../../../../../../../../../component/form/select/options.json')
const pregnancyattendance_data = require('../../../../../../../../../../../../../../thousandDays/data/PregnancyAttendance.json')

const getFullYears = () => {
	let years = []
	for (
		let i = new Date().getFullYear();
		i > new Date().getFullYear() - 2;
		i--
	) {
		years.push({ id: `${i}`, name: `${i}` })
	}
	return years.reverse()
}


function strpUltrasonography(exam={}) {
	let { day, month, year } = getCurrentDate()

	let date = exam?.date ? new Date(exam.date) : ''

	return {
		"id": exam?.id,
		"ultrasound_weeks": exam?.ultrasound_weeks || '0',
		"ultrasound_days": exam?.ultrasound_days || '0',
		"placenta": exam?.placenta || 'NOT_EVALUATED',
		"amniotic_fluid": exam?.amniotic_fluid || '',
		"baby_weight": exam?.baby_weight || '',
		"buttock_to_head_length": exam?.buttock_to_head_length || '',
		"date_day": date ? `${date.getDate() < 10 ? '0': ''}${date.getDate()}` : day,
		"date_month": date ? `${date.getMonth() + 1 < 10 ? '0': ''}${date.getMonth() + 1}` : month,
		"date_year": date ? `${date.getFullYear()}` : year,
	}
}

function parseUltrasonography(exam={}) {
	let date = buildDate(exam?.date_year, exam?.date_month, exam?.date_day, '10', '10')

	return {
		"id": exam?.id,
		"ultrasound_weeks": exam?.ultrasound_weeks || '0',
		"ultrasound_days": exam?.ultrasound_days || '0',
		"placenta": exam?.placenta || 'NOT_EVALUATED',
		"amniotic_fluid": exam?.amniotic_fluid === '' ? null : Number(exam.amniotic_fluid),
		"baby_weight": exam?.baby_weight === '' ? null : Number(exam.baby_weight),
		"buttock_to_head_length": exam?.buttock_to_head_length === '' ? null : Number(exam.buttock_to_head_length),
		"date": formatISO8601(date),
	}
}

export default function ObstetricUltrasonographyWrite({ holdingExam=null, setModalInfo = () => null }) {
	const dispatch = useDispatch()
	const [data, setData] = useState(strpUltrasonography(holdingExam))
	const [ultrasoundEmissionDateErr, setUltrasoundEmissionDateErr] = useState(false)
	const { selectedLanguage, fetchThousandDaysProjects, holdingProject } = useThousandDays()

	const {
		Gestationalage,
		Saveobstetricultrasound,
		Ultra_Week_Options,
		Ultra_Day_Options,
		Weeks,
		Days,
		Ultradate,
		Amnioticfluid,
		Day,
		Week,
		Year,
		Select_Months_Options,
		Notevaluated,
		Previous,
		Posterior,
		Fundal,
		Side,
		Preview,
		Marginal,
		Lowa,
		Okalert,
		SavedExam,
		Erralert,
		FailExam,
		Change,
		Anthropometry,
		BabyWeight,
		ButtockToHeadLength,
	} = require('../../../../../../../../../../../../../../data/translation.json')[selectedLanguage]

	const translate = require('../../../../../../../../../../../../../../data/translation.json')[selectedLanguage]
	const ultra_week_options = pregnancyattendance_data.ultraweekoptions.map(
		(option) => ({
			id: option.id,
			name: Ultra_Week_Options[option.id],
		})
    )

    const ultra_day_options = pregnancyattendance_data.ultradayoptions.map(
			(option) => ({
				id: option.id,
				name: Ultra_Day_Options[option.id],
			})
    )

    const ultra_data_months_options = selectOptions_data.months.map((option) => ({
		id: option.id,
		name: Select_Months_Options[option.id],
		})
	)

	const handleSelect = (event) => {
		setUltrasoundEmissionDateErr(false)
		setData((prev) => ({
			...prev,
			[`${event.target.id}`]: event.target.selected,
		}))
	}

	const handleChange = (event) => {
		let cleanedValue =
			event.target.name === 'amniotic_fluid' || event.target.name === 'baby_weight' || event.target.name === 'buttock_to_head_length'
				? event.target.value.replace(/\D/g, '')
				: event.target.value

		setData((prev) => ({ ...prev, [`${event.target.name}`]: cleanedValue }))
	}

	const handleSubmit = async () => {
		let date = buildDate(data.date_year, data.date_month, data.date_day)
		
		if (!isValidDate(date)) {
			return setUltrasoundEmissionDateErr(true)
		}
		let payload = {
			...parseUltrasonography(data),
			person: holdingProject.patient.id,
			project: holdingProject.id,
		}

		try {
			if (payload?.id) {
				await api.patch(`/exam/obstetric_ultrasonography/${payload.id}/`, payload)
			} else {
				await api.post('/exam/obstetric_ultrasonography/', payload)
			}
			dispatch(AddAlert(Okalert, SavedExam, AlertType.SUCCESS))
			fetchThousandDaysProjects()
			setModalInfo(MODAL_INIT_PROPS)
		} catch (err) {
			dispatch(AddAlert(Erralert, FailExam, AlertType.ERROR))
			console.error("UltrasonographyWrite ~ handleSubmit: ", err)
		}
	}

	return (
		<div className='ObstetricUltrasonographyWrite'>
			<div className='ObstetricUltrasonographyWrite-Data'>
			<div>
					<b>{Ultradate}</b>
					<div className='ObstetricUltrasonographyWrite-UltrasoundDate'>
						<NewSelect
							id='date_day'
							options={selectOptions_data.days}
							selected={data.date_day}
							onSelect={handleSelect}
							canBeEmpty={false}
							defaultText={Day}
							error={ultrasoundEmissionDateErr}
						/>
						<NewSelect
							id='date_month'
							options={ultra_data_months_options}
							selected={data.date_month}
							onSelect={handleSelect}
							canBeEmpty={false}
							defaultText={Week}
							error={ultrasoundEmissionDateErr}
						/>
						<NewSelect
							id='date_year'
							options={getFullYears()}
							selected={data.date_year}
							onSelect={handleSelect}
							canBeEmpty={false}
							defaultText={Year}
							error={ultrasoundEmissionDateErr}
						/>
					</div>
				</div>
				<p><b>{Gestationalage}</b></p>
				<div className='ObstetricUltrasonographyWrite-UltrasoundBox'>
					<NewSelect
						id='ultrasound_weeks'
						options={ultra_week_options}
						selected={data.ultrasound_weeks}
						onSelect={handleSelect}
						canBeEmpty={false}
						label={Weeks}
					/>
					<NewSelect
						id='ultrasound_days'
						options={ultra_day_options}
						selected={data.ultrasound_days}
						onSelect={handleSelect}
						canBeEmpty={false}
						label={Days}
					/>
				</div>
				<p><b>{Anthropometry}</b></p>
				<div className='ObstetricUltrasonographyWrite-UltrasoundBox'>
					<Input
						name='baby_weight'
						label={BabyWeight}
						action={handleChange}
						value={data.baby_weight}
						autoComplete='off'
						placeholder='g'
						maxLength={5}
					/>
					<Input
						name='buttock_to_head_length'
						label={ButtockToHeadLength}
						action={handleChange}
						value={data.buttock_to_head_length}
						autoComplete='off'
						placeholder='mm'
						maxLength={3}
					/>
				</div>
				<b>Placenta</b>
				<div className='ObstetricUltrasonographyWrite-PlacentaLiqAmn'>
					<NewSelect
						id='placenta'
						options={[
							{ id: 'NOT_EVALUATED', name: Notevaluated },
							{ id: 'PREVIOUS', name: Previous },
							{ id: 'POSTERIOR', name: Posterior },
							{ id: 'FUNDAL', name: Fundal },
							{ id: 'SIDE', name: Side },
							{ id: 'PREVIEW', name: Preview },
							{ id: 'MARGINAL', name: Marginal },
							{ id: 'LOW', name: Lowa },
						]}
						selected={data.placenta}
						onSelect={handleSelect}
						canBeEmpty={false}
					/>
					<AlertRowText {...getPlacentaResults(data.placenta, Change)} />
				</div>
				<b>{Amnioticfluid} MBV</b>
				<div className='ObstetricUltrasonographyWrite-PlacentaLiqAmn'>
					<Input
						name='amniotic_fluid'
						placeholder='cm'
						value={data.amniotic_fluid}
						maxLength={2}
						action={handleChange}
					/>
					<AlertRowText {...getAmnioticFluidResults(data.amniotic_fluid, translate)} />
				</div>
			</div>
			<div className='AddNewExamModal-ButtonRow'>
				<Button
					label={Saveobstetricultrasound}
					onClick={handleSubmit}
				/>
			</div>
		</div>
	)
}
