export default function EmailIcon({ width, height, color }) {
    return <svg width={width} height={height} version="1.0" viewBox="0 0 512 512">

    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
    <path d="M2460 5104 c-25 -9 -58 -26 -75 -37 -16 -12 -181 -169 -365 -349
    l-335 -328 -372 0 c-432 0 -442 -2 -534 -91 -94 -92 -94 -88 -97 -519 l-3
    -375 -168 -170 c-98 -99 -179 -189 -193 -215 l-23 -45 -3 -492 -3 -492 23 -20
    c37 -35 90 -26 113 20 13 23 15 103 15 484 0 250 3 455 8 455 11 -1 1608
    -1588 1602 -1593 -3 -3 -365 -203 -805 -444 l-800 -440 -5 639 -5 640 -24 19
    c-30 25 -76 24 -101 -1 -20 -20 -20 -29 -18 -773 l3 -752 23 -43 c35 -66 88
    -119 150 -149 l57 -28 1438 -3 1438 -2 24 24 c32 32 32 70 0 101 l-24 25
    -1413 0 c-1356 0 -1415 1 -1454 19 -41 19 -79 61 -86 94 -5 28 2036 1148 2102
    1155 39 4 57 -2 130 -41 137 -72 1984 -1088 1992 -1095 16 -16 -38 -87 -82
    -110 -44 -22 -48 -22 -476 -22 -421 0 -432 0 -458 -21 -36 -29 -36 -80 1 -109
    26 -21 32 -21 482 -18 l456 3 57 28 c62 30 115 83 150 149 l23 43 0 1375 0
    1375 -27 50 c-17 32 -88 111 -192 215 l-166 165 0 97 c0 85 -3 101 -20 118
    -33 33 -75 30 -107 -6 l-23 -25 0 -407 0 -407 -680 -680 -680 -680 -63 33
    c-228 122 -215 117 -307 117 -92 0 -75 7 -307 -117 l-63 -33 -680 680 -680
    680 0 669 0 669 25 44 c16 26 41 51 65 63 39 20 59 20 1641 20 l1602 0 41 -24
    c23 -13 51 -42 64 -64 19 -35 22 -54 22 -160 0 -104 3 -123 20 -144 25 -33 49
    -39 88 -22 41 17 45 36 40 196 -3 117 -6 139 -28 183 -35 72 -105 138 -173
    164 -55 20 -74 21 -430 21 l-372 0 -345 338 c-260 255 -357 343 -395 361 -65
    31 -172 38 -235 15z m168 -149 c24 -10 132 -111 307 -283 148 -147 271 -270
    273 -274 2 -5 -289 -8 -647 -8 -358 0 -651 1 -651 3 0 14 539 537 571 555 49
    26 95 28 147 7z m-1948 -1897 l0 -133 -67 67 -68 68 65 65 c35 36 66 65 67 65
    2 0 3 -60 3 -132z m3827 -65 l-67 -68 0 135 0 135 67 -68 68 -67 -68 -67z
    m173 -1304 l0 -1241 -32 20 c-18 11 -379 210 -803 442 -423 232 -772 425 -775
    427 -6 5 1591 1592 1603 1593 4 0 7 -558 7 -1241z"/>
    <path d="M2380 3789 c-189 -49 -342 -158 -445 -318 -303 -468 -21 -1085 531
    -1161 113 -15 154 5 154 73 0 41 -37 63 -126 75 -105 14 -178 42 -270 104
    -253 169 -336 506 -192 781 47 89 165 205 255 251 243 122 528 75 710 -117
    107 -113 163 -240 163 -368 0 -65 -30 -190 -59 -247 -51 -101 -177 -137 -216
    -62 -12 24 -15 83 -15 355 0 312 -1 326 -20 345 -30 30 -83 27 -109 -6 -12
    -15 -21 -38 -21 -51 0 -14 -3 -23 -7 -21 -77 40 -91 43 -183 43 -83 0 -101 -4
    -145 -27 -149 -78 -236 -266 -205 -446 48 -288 316 -439 518 -292 l43 31 47
    -49 c58 -59 103 -76 186 -70 123 10 231 97 280 226 98 256 64 485 -103 695
    -100 126 -240 215 -405 257 -105 27 -262 26 -366 -1z m230 -484 c122 -63 148
    -304 47 -437 -45 -59 -110 -86 -167 -69 -125 37 -196 208 -151 363 16 55 66
    118 113 141 42 21 120 22 158 2z"/>
    </g>
    </svg>
}
