import './LastMenstruation.css'
import React, { useEffect } from 'react'
import NewSelect from '../../../../../../../../../../../../newcomponents/form/select/select'
import { useThousandDays } from '../../../../ThousandDaysSection'

const selectOptions_data = require('../../../../../../../../../../../../component/form/select/options.json')

const getFullYears = () => {
	let years = []
	for (
		let i = new Date().getFullYear();
		i > new Date().getFullYear() - 2;
		i--
	) {
		years.push({ id: `${i}`, name: `${i}` })
	}
	return years.reverse()
}

export function LastMenstruationModal() {
	const { selectedLanguage, obstetricData, setObstetricData, saveObstetricData } = useThousandDays()

	const {
		SelectOptions_Data_Months_Options,
		Day,
		Month,
		Year 
	} = require('../../../../../../../../../../../data/translation.json')[selectedLanguage]

	const selectOptions_data_months_options = selectOptions_data.months.map(
		(option) => ({
			id: option.id,
			name: SelectOptions_Data_Months_Options[option.id],
		})
	)

	useEffect(() => {
		if (obstetricData.last_menstruation_year && obstetricData.last_menstruation_month && obstetricData.last_menstruation_day){
			saveObstetricData()
		}
	}, [
		obstetricData.last_menstruation_year,
		obstetricData.last_menstruation_month,
		obstetricData.last_menstruation_day,
	])

	const handleSelect = (event) => {
		setObstetricData((prev) => ({
			...prev,
			[event.target.id]: event.target.selected,
		}))
	}

	return (
		<>
			<div>
				<div className='LastMenstruation-DateRow'>
					<NewSelect
						id='last_menstruation_day'
						options={selectOptions_data.days}
						selected={obstetricData.last_menstruation_day}
						onSelect={handleSelect}
						defaultText={Day}
						disabled={false}
						canBeEmpty={false}
					/>
					<NewSelect
						id='last_menstruation_month'
						options={selectOptions_data_months_options}
						selected={obstetricData.last_menstruation_month}
						onSelect={handleSelect}
						defaultText={Month}
						canBeEmpty={false}
					/>
					<NewSelect
						id='last_menstruation_year'
						options={getFullYears()}
						selected={obstetricData.last_menstruation_year}
						onSelect={handleSelect}
						defaultText={Year}
						canBeEmpty={false}
					/>
				</div>
			</div>
		</>
	)
}

export default function LastMenstruation() {
	const {
		selectedLanguage,
		obstetricData,
		setObstetricData,
		saveObstetricData,
	} = useThousandDays()

	const { Dateoflastmenstrualperiod } = require('../../../../../../../../../../../data/translation.json')[
		selectedLanguage
	]

	const { SelectOptions_Data_Months_Options, Day, Month, Year, Dum } =
		require('../../../../../../../../../../../data/translation.json')[
			selectedLanguage
		]

	const selectOptions_data_months_options = selectOptions_data.months.map(
		(option) => ({
			id: option.id,
			name: SelectOptions_Data_Months_Options[option.id],
		})
	)

	useEffect(() => {
		if (obstetricData.last_menstruation_year && obstetricData.last_menstruation_month && obstetricData.last_menstruation_day) {
			saveObstetricData()
		}
	}, [
		obstetricData.last_menstruation_year,
		obstetricData.last_menstruation_month,
		obstetricData.last_menstruation_day,
	])

	const handleSelect = (event) => {
		setObstetricData((prev) => ({
			...prev,
			[event.target.id]: event.target.selected,
		}))
	}

	return (
		<>
			<p className='LastMenstruation-Subtitle'>{Dum}</p>
			<div className='PregnancyRiskCalculator-Separator' />
			<div>
				<b>{Dateoflastmenstrualperiod}</b>
				<div className='LastMenstruation-DateRow'>
					<NewSelect
						id='last_menstruation_day'
						options={selectOptions_data.days}
						selected={obstetricData.last_menstruation_day}
						onSelect={handleSelect}
						defaultText={Day}
						disabled={false}
						canBeEmpty={false}
					/>
					<NewSelect
						id='last_menstruation_month'
						options={selectOptions_data_months_options}
						selected={obstetricData.last_menstruation_month}
						onSelect={handleSelect}
						defaultText={Month}
						canBeEmpty={false}
					/>
					<NewSelect
						id='last_menstruation_year'
						options={getFullYears()}
						selected={obstetricData.last_menstruation_year}
						onSelect={handleSelect}
						defaultText={Year}
						canBeEmpty={false}
					/>
				</div>
			</div>
		</>
	)
}
