import './CategoriesOptions.css'
import React from "react";
import HorizontalField from '../../../../../../../../component/HorizontalField/HorizontalField';
import EditCategoriesOptionsModal from './EditCategoriesOptionsModal/EditCategoriesOptionsModal';
import { useInventoryMaterials } from '../../../../InventoryMaterials';

export default function CategoriesOptions({ activeCategories=[], setModalInfo=() => null }) {
    const { canReadAndWrite } = useInventoryMaterials()
    
    return <div className='CategoriesOptions'>
        <div className='CategoriesOptions-Header'>
            <div className='CategoriesOptions-Header-Icon'>C</div>
            <div className='CategoriesOptions-Header-Title'>Categorias</div>
        </div>
        <div className='CategoriesOptions-ActionRow'>
            {
                canReadAndWrite
                ? <div 
                    className='CategoriesOptions-ActionRow-Btn' 
                    onClick={() => setModalInfo({
                        open: true,
                        title: 'Editar opções de categorias',
                        content: <EditCategoriesOptionsModal />
                    })}
                >
                    <b>Editar</b>
                </div>
                : null
            }
        </div>
        <div className='CategoriesOptions-Body'>
            {
                activeCategories?.length
                ? activeCategories.map(category_cursor => <HorizontalField label={category_cursor?.name} content='Ativo' />) 
                : <p>Nenhuma categoria habilitada</p>
            }
        </div>
    </div>
}