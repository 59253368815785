import './AddStudent.css'
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useApp } from "../../../../../../../layout/App";
import { useDispatch } from "react-redux";
import { getCollaborators } from "../../../../../../collaborators/http";
import { loaded, loading } from "../../../../../../../layout/redux/AppActions";
import { AddAlert, AlertType, Input } from "../../../../../../../component";
import Portal from "../../../../../../../component/potal/portal";
import useOutsideClick from "../../../../../../../component/useOusideClick/useoutsideclick";
import NewButton, { ButtonModel } from '../../../../../../../newcomponents/button/button';
import { MODAL_INIT_PROPS } from '../../../../../../../newcomponents/modal/modal';
import AlertRowText, { AlertRowTextType } from '../../../../../../../component/alertRowText/alertRowText';
import InfoLine from '../../../../../../../component/info-line/info-line';
import { apiV2 } from '../../../../../../../helpers';
import { userRoles } from '../../../../../../../utils/userRoles';

export default function AddStudent({ setModalInfo= () => null, fetchAssociatedStudents= () => null }) {
    const dispatch = useDispatch()
    const { id } = useParams()
    const ref = useRef()
    const searchTimeOut = useRef(null)
    const [studentsOptions, setStudentsOptions] = useState([])
    const { currentHealthPlaceUser } = useApp()
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [dropdownStyles, setDropdownStyles] = useState({})
    const [selectedStudent, setSelectedStudent] = useState(null)

    useOutsideClick(ref, e => {
        if (!e && isDropdownOpen) setIsDropdownOpen(false)
    })

    const selectStudent = (student) => {
        setSelectedStudent(student)
        setIsDropdownOpen(false)
    }

    useEffect(() => {
        fetchStudentsOptions()
    }, [])

    const fetchStudentsOptions = async (params) => {
        dispatch(loading())
        await getCollaborators({ 
            ...params, 
            health_place__id: currentHealthPlaceUser?.health_place?.id, 
            profile__in: 'DOCTOR%2CDENTIST%2CPSYCHOLOGIST%2CNUTRITIONIST%2CNURSE',
            has_person: true,
            limit: 500,
        })
            .then(res => {
                dispatch(loaded())
                setStudentsOptions(res.data.results.map(student => {
                    const str = student?.user?.person?.name
                            ? `${student?.user?.person?.name}`
                            : `${student?.user?.email} (cadastro incompleto)`
                        
                    return { 
                        id: student?.id, 
                        name: `${str} | ${ userRoles?.[student?.profile]?.title || '-' } | ${student?.health_place?.name}` 
                    }
                }))
            })
            .catch(err => {
                dispatch(loaded())
                console.error('fetchStudentsOptions', err)
                setStudentsOptions([])
            })
    }

    const handleSearchStudent = async (event) => {
        searchTimeOut.current && clearTimeout(searchTimeOut.current)
        searchTimeOut.current = setTimeout(async () => {
            await fetchStudentsOptions({ name_cpf_or_email: event.target.value })
            handleOpenDropdown(event)
        }, 300, event.target.name)
    }

    const handleOpenDropdown = (event) => {
        const inputRect = event.target.getBoundingClientRect()
        setDropdownStyles({
            left: inputRect.left + window.scrollX,
            top: inputRect.bottom + window.scrollY,
            width: inputRect.width,
            position: 'absolute'
        });
        setIsDropdownOpen(true);
    }

    const handleSubmit = () => {
        let payload = {
            "student_health_place_user": selectedStudent?.id,
            "room_id": id
        }
        apiV2.put('/preceptorship/associated/student', payload)
            .then(() => {
                fetchAssociatedStudents()
                setModalInfo(MODAL_INIT_PROPS)
            })
            .catch(err => {
                console.error('AddStudent ~ handleSubmit', err)
                dispatch(AddAlert('Novo aluno', 'Falha ao adicionar novo aluno', AlertType.ERROR))
            })
    }

    return <div className="AddStudent">
        <div>
            <strong>Pesquisar aluno</strong>
            <Input 
                name='student_name_or_cpf'
                placeholder='Nome | CPF' 
                action={handleSearchStudent}
            />
            {
                isDropdownOpen && (
                    <Portal name='student-dropdown'>
                        <div ref={ref} style={dropdownStyles} className='AddStudent-DropdownBox'>
                            <div className='AddStudent-DropdownBox-SelectOptions'>
                                {
                                    studentsOptions.map(student => <div key={student.id} onClick={() => selectStudent(student)}>
                                        {student?.name || '-'}  
                                    </div>)
                                }
                            </div>
                            
                        </div>
                    </Portal>
                )
            }
            {
                !selectedStudent?.id
                ? <AlertRowText type={AlertRowTextType.INFO} content='Nenhum estudante foi selecionado' />
                : <div className='AddStudent-SelectedStudent' onClick={() => setSelectedStudent(null)}>
                    <InfoLine 
                        label='Novo aluno'
                        value={selectedStudent?.name || '-'}
                    />
                </div>
            
            }
        </div>
        <div className="AddStudent-BtnBox">
            <NewButton
                label='Fechar'
                model={ButtonModel.SECONDARY}
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
            />
            <NewButton 
                label='Adicionar'
                onClick={handleSubmit}
                disabled={!selectedStudent?.id}
            />
        </div>
    </div>
}