import './NewEvaluation.css'
import React, { useEffect, useState } from "react";
import { useParams } from "react-router"
import NewButton, { ButtonModel } from '../../../../../../../newcomponents/button/button';
import { MODAL_INIT_PROPS } from '../../../../../../../newcomponents/modal/modal';
import { useApp } from '../../../../../../../layout/App';
import { List, MaskDataHora, Paginate } from '../../../../../../../component';
import Button from '../../../../../../../newcomponents/button/button';
import NewSelect from '../../../../../../../newcomponents/form/select/select';
import { getAttendancesHistory } from '../../../../../../AttendanceManager/http';
import Loading from '../../../../../../../newcomponents/loading/loading';
import AlertRowText, { AlertRowTextType } from '../../../../../../../component/alertRowText/alertRowText';
import { history } from '../../../../../../../helpers';

const defaultOptions = require('../../../../../../../component/form/select/options.json')
const { headDefault } = require('./NewEvaluation.json')

export default function NewEvaluation({ associatedStudents=[], setModalInfo=() => null }) {
    const { id } = useParams()
    const { currentHealthPlaceUser, isAdminOrAssistant } = useApp()
    const [selectedStudentHealthPlaceUser, setSelectedStudentHealthPlaceUser] = useState(null)
    const [appointments, setAppointments] = useState([])
    const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        if (selectedStudentHealthPlaceUser) fetchAttendancesHistory({ offset: 0 })
    }, [selectedStudentHealthPlaceUser])

	const fetchAttendancesHistory = async params => {
		params = { 
            ...params, 
            limit: 20, 
            doctor__user__id: selectedStudentHealthPlaceUser, 
            health_place__id: currentHealthPlaceUser?.health_place?.id 
        }
		setLoading(true)
		try {
			let response = await getAttendancesHistory(params)
			setAppointments(response.data.results)
			setPagination({
				totalElements: response.data.count,
				totalPages: Math.ceil(response.data.count / 20),
				pageNumber: params.offset / 20,
			})
			
		} catch (err) {
			console.error('NewEvaluation ~ fetchAttendancesHistory', err)
		}
        setLoading(false)
	}

	const handleNextPage = e => {
		fetchAttendancesHistory({ offset: e * 20 })
	}

    const handleSelect = (event) => {
        setSelectedStudentHealthPlaceUser(event.target.selected)
    }
    
    return <div className='NewEvaluation'>
        <div className='NewEvaluation-Body'>
            <div className='NewEvaluation-Body-Select'>
                <strong>Aluno</strong>
                <NewSelect
                    id='student_health_place_user'
                    options={associatedStudents.map(associatedStudent => {
                        const str = associatedStudent?.student_health_place_user?.user?.person?.name
                        ? `${associatedStudent?.student_health_place_user?.user?.person?.name}`
                        : `${associatedStudent?.student_health_place_user?.user?.email} (cadastro incompleto)`
                    
                        return { id: associatedStudent?.student_health_place_user.user?.id, name: `${str}` }
                    })}
                    onSelect={handleSelect}
                    selected={selectedStudentHealthPlaceUser}
                />
            </div>
            {
                loading 
                ? <div className='NewEvaluation-Loading'>
                    <Loading />
                </div>
                : !selectedStudentHealthPlaceUser
                ? <div>
                    <AlertRowText content='Selecione um aluno' type={AlertRowTextType.INFO} outlined />
                </div>
                : <div className='NewEvaluation-Body-List'>
                    <List
                        head={
                            isAdminOrAssistant
                                ? headDefault
                                : [...headDefault, { colunm: 'acoes', text: '', width: '180px' }]
                        }
                        data={appointments}
                        noData='Nenhum atendimento encontrado'
                        maxHeight='calc(60vh - 80px)'
                        listCustom={(e, i) => {
                            const custom = e
                            const data = MaskDataHora(e.start)
                            custom['h_student'] = e?.doctor?.name
                            custom['h_patient'] = e?.person?.name || '-'
                            custom['h_date'] =
                                data?.data && data?.hora
                                    ? `${data?.data} ${data?.hora}`
                                    : '-'
                            custom['h_state'] = defaultOptions?.DECISION_CHOICES?.find(ob => ob?.id === custom?.decision)?.name || '-'
                            custom['acoes'] = <div>
                                <Button 
                                    model={ButtonModel.SECONDARY}
                                    label='Avaliar'
                                    onClick={() => history.push(`/preceptoria/consultation/${id}/${e?.id}`)}
                                />
                            </div>
                            return custom
                        }}
                    />
                </div>
            }
        </div>
        <div>
            <div>
                <Paginate
                    data={pagination}
                    action={handleNextPage}
                />
            </div>
            <div className='NewEvaluation-BtnBox'>
                <NewButton 
                    label='Fechar'
                    model={ButtonModel.SECONDARY}
                    onClick={() => setModalInfo(MODAL_INIT_PROPS)}
                />
            </div>
        </div>
    </div>
}