export const MaskWeight = (value) => {
  return value
      .replace(/\D/, '')
      .replace(/^[0]+/, '')
      .replace(/^(\d{1,})(\d{2})$/, "$1,$2")
}

export const MaskIMC = (bmi) => {
  if (!bmi) return ''
  if (bmi < 18.5) return `Abaixo do peso: ${bmi.replace('.', ',')}`
  if (bmi >= 18.6 && bmi < 25) return `Normal: ${bmi.replace('.', ',')}`
  if (25 <= bmi && bmi <= 29.9) return `Sobrepeso: ${bmi.replace('.', ',')}`
  return `Obesidade: ${bmi.replace('.', ',')}`
}

export const MaskItemCep = valor => {
  let valueNew = ''
  valueNew = valor.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2')
  return valueNew
}

export const MaskData = valor => {
  let valueNew = ''
  valueNew = valor
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
  return valueNew
}


export const Mask1DecimalPlace = (inputString) => {
    const cleanedString = inputString.replace(/[^0-9]/g, '');

    if (cleanedString.length <= 1) {
        return cleanedString;
    }

    let beforeComma = cleanedString.substring(0, cleanedString.length - 1);
    let afterComma = cleanedString.substring(cleanedString.length - 1);

    let outputString = beforeComma + "," + afterComma;

    return outputString;
}

export const MaskThousand = (value) => {
  if (typeof value === 'number') value = String(value)

  return value?.length <= 3
      ? value
      : value
          .replace(/\D/g, '')
          .replace(/^(\d*)(\d{3})/g, "$1.$2")
}
