import './HealthPlaceServiceLocations.css'
import React, { useEffect, useState } from "react";
import HorizontalField from '../../../../../../../component/HorizontalField/HorizontalField';
import EditServiceLocationsOptionsModal from './EditServiceLocationsOptionsModal/EditServiceLocationsOptionsModal';
import { getAppointmentServiceLocation } from '../../http';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../newcomponents/modal/modal';

export default function HealthPlaceServiceLocations() {
    const [modalProps, setModalProps] = useState(MODAL_INIT_PROPS)
    const [serviceLocationOptions, setServiceLocationOptions] = useState([])

    useEffect(() => {
        fetchServiceLocationsOptions()
    }, [])

    const fetchServiceLocationsOptions = async () => {
        try {
            let res = await getAppointmentServiceLocation({ active: true })
            setServiceLocationOptions(res.data.results)
        } catch (err) {
            setServiceLocationOptions([])
            console.error('fetchServiceLocationsOptions ~ ', err)
        }
    }

    return <div className='HealthPlaceServiceLocations'>
        <Modal 
            {...modalProps} 
            dismissFn={() => {
                fetchServiceLocationsOptions()
                setModalProps(MODAL_INIT_PROPS)}
            }
        />
        <div className='HealthPlaceServiceLocations-Header'>
            <div className='HealthPlaceServiceLocations-Header-Icon'>L</div>
            <div className='HealthPlaceServiceLocations-Header-Title'>Locais de Atendimento</div>
        </div>
        <div className='HealthPlaceServiceLocations-Body'>
            {
                serviceLocationOptions?.length
                ? serviceLocationOptions.map(cursor => <HorizontalField label={`${cursor?.name}`} content={'Ativo'} />) 
                : <p>Nenhum local de atendimento habilitado</p>
            }
        </div>
        <div className='HealthPlaceServiceLocations-ActionRow'>
            <div 
                className='HealthPlaceServiceLocations-ActionRow-Btn' 
                onClick={() => setModalProps(prev => ({
                    ...prev,
                    open: true,
                    title: 'Editar Instituição',
                    content: <EditServiceLocationsOptionsModal />,
                    dismissText: '',
                }))}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}
