import './RemoveHealthPlaceModal.css'
import React, { useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useConfiguration } from "../../../../../configuration"
import { AddAlert, AlertType, Input } from '../../../../../../../component'
import { patchHealthPlaceBranch } from '../../../../../http'
import { MODAL_INIT_PROPS } from '../../../../../../../newcomponents/modal/modal'
import NewButton, { ButtonModel } from '../../../../../../../newcomponents/button/button'


export default function RemoveHealthPlaceModal({ healthPlace }) {
    const dispatch = useDispatch()
    const inputRemoveCompanyName = useRef()
    const { fetchHealthPlaceBranches, setModalProps } = useConfiguration()
    const [confirmHealthPlaceName, setConfirmHealthPlaceName] = useState('')
    
    const handleChange = (event) => {
        setConfirmHealthPlaceName(event.target.value)
    }

    const handleSubmit = async () => {
        const classError = 'erro-input'

        if (healthPlace.name !== confirmHealthPlaceName) {
            inputRemoveCompanyName.current.className = classError
            return dispatch(AddAlert('Instituição', 'Nome da instituição incorreto, verifique e tente novamente!', AlertType.ERROR))
        } else {
            inputRemoveCompanyName.current.className = ''
        }

        await patchHealthPlaceBranch(healthPlace.id, { "active": false })
            .then(() => {
                dispatch(AddAlert('Instituição', `Instituição ${healthPlace.name} removida`, AlertType.INFO))
                fetchHealthPlaceBranches()
                setModalProps(MODAL_INIT_PROPS)
            })
            .catch(err => {
                console.error('patchHealthPlace: ', err)
            })
    }

    return <>
        <span className='RemoveHealthPlaceModal-ErrorDisclaimer'>Cuidado! Ação irreversível em andamento.</span>
        <p>Esta ação causará a exclusão permanente de todos os dados da instituição</p>
        <p>bem como de seus profissionais cadastrados e suas respectivas agendas</p>
        <p>Ao confirmar a exclusão esta ação não poderá ser desfeita.</p>
        <p>Tem certeza que deseja excluir <strong>{healthPlace.name}</strong>?</p>
        <div className='RemoveHealthPlaceModal-Input'>
            <b>Nome da Instituição</b>
            <Input
                name='name'
                placeholder='Confirme o nome da instituição.'
                action={handleChange}
                value={confirmHealthPlaceName}
                ref={inputRemoveCompanyName}
            />
        </div>
        <div className='RemoveHealthPlaceModal-BtnBox'>
            <NewButton 
                label='Fechar'
                onClick={() => setModalProps(MODAL_INIT_PROPS)}
                model={ButtonModel.SECONDARY}
            />
            <NewButton 
                label='Confirmar'
                onClick={handleSubmit}
                model={ButtonModel.DANGER}
            />
        </div>
    </>
}
