import { useHistory } from 'react-router-dom'

const baseURL = process.env.REACT_APP_SALUTHO_API

export const StatusCodes = {
    OK: 200,
    ACCEPTED: 202,
    UNAUTHORIZED: 401,
    NOT_FOUND: 404
}

function getHeaders(auth){

    let headers = {
        'Content-Type': 'application/json'
    }

    if(auth){
        headers['Authorization'] = `Bearer ${localStorage.token}`
    }

    return headers
}

export function useApi() {

    const history = useHistory()

    const handleResponse = async(response) => {

        if(response.status === StatusCodes.UNAUTHORIZED){
            history.go('/login')
            return
        }

        let content = null

        if(response.status === StatusCodes.ACCEPTED)
            content = await response.json()
        else
            content = await response.text()

        return {status: response.status, content: content}
    }

    return {
        get: async(url, auth=true) => {
            let response = await fetch(`${baseURL}${url}`, {
                method: 'GET',
                headers: getHeaders(auth)
            })

            return handleResponse(response)
        },
        put: async(url, data, auth=true) => {
            let response = await fetch(`${baseURL}${url}`, {
                method: 'PUT',
                headers: getHeaders(auth),
                body: JSON.stringify(data)
            })

            return handleResponse(response)
        }
    }

}