import React from 'react'
import './VerifyEmailRegister.css'
import { history } from '../../../helpers'
import { verificarEmailReenviar } from '../../../layout/redux/AppActions'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import Button, { ButtonModel } from '../../../newcomponents/button/button'
import EmailIcon from '../../../component/icon/email'

export default function VerifyEmailPage() {
  const dispatch = useDispatch()
  const [btnTime, setBtnTime] = useState(false)
  
  const handleVerification = () => {
    if (btnTime) return null
    setBtnTime(true)
    dispatch(verificarEmailReenviar())
    setTimeout(() => {
      setBtnTime(false)
    }, 10000);
  }

  return (
    <div className='VerifyEmailRegister'>
      <section>
        <h3>Salutho</h3>
        <div className='VerifyEmailRegister-Headline'>
          <h4>Verificação de E-mail</h4>
          <span>O link de ativação da sua conta foi enviado para o e-mail fornecido em seu registro</span>
          <div>
            <EmailIcon width={40} color='#0083ef' />
          </div>
          <span>Não recebeu o e-mail? <b className='VerifyEmailRegister-SendBtn' onClick={handleVerification}>Enviar novamente</b></span>
        </div>
        
        <div className='VerifyEmailRegister-BtnBox'>
          <Button
            label='Cancelar'
            onClick={() => history.push('/login')}
            model={ButtonModel.SECONDARY}
          />
        </div>
      </section>
    </div>
  )
}
