import './NewEditAnthropometry.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AddAlert, AlertType, Input, idade } from '../../../../../../../../../../component'
import AlertRowText, { AlertRowTextType } from '../../../../../../../../../../component/alertRowText/alertRowText'
import { Mask1DecimalPlace } from '../../../../../../../../../../component/mask/mask'
import NewButton from '../../../../../../../../../../newcomponents/button/button'
import { loaded, loading } from '../../../../../../../../../../layout/redux/AppActions'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal'
import { getIMCResult } from '../../../VitalSigns/utils'
import { putAnthropometry } from '../../../../../../../../http'
import { strpAnthropometry } from '../../utils'

export default function NewEditAnthropometry({ person, attendanceID=null, initAnthropometry=null, extraAction=() => null }) {
    const dispatch = useDispatch()
    const selectedLanguage = 'pt-BR'
    const [holdingAnthropometry, setholdingAnthropometry] = useState(strpAnthropometry(initAnthropometry))

    const handleChange = event => {
		let cleanedValue =
			event.target.name === 'weight'
				? Mask1DecimalPlace(event.target.value)
				: event.target.value.replace(/\D/g, '')
		setholdingAnthropometry(prev => ({ ...prev, [`${event.target.name}`]: cleanedValue }))
	}

    const saveAnthropometry = async () => {
        const payload = { ...holdingAnthropometry, "attendance": attendanceID, "person": person }
        dispatch(loading())
        try {
            await putAnthropometry(payload)
            dispatch(AddAlert('Antropometria', 'Antropometria salva com sucesso!', AlertType.SUCCESS))
            extraAction(MODAL_INIT_PROPS)
        } catch (err) {
            console.error('saveAnthropometry', err)
        }
        dispatch(loaded())
    }

    return (
		<div>
			<div className='NewEditAnthropometry'>
				<div>
					<div className='NewEditAnthropometry-IMCCalculator'>
						<div>
							<b>Peso</b>
							<Input
								name='weight'
								placeholder='kg'
								value={holdingAnthropometry.weight}
								maxLength={5}
								action={handleChange}

								autoComplete='off'

							/>
						</div>
						<div>
							<b>Altura</b>
							<Input
								name='height'
								placeholder='cm'
								value={holdingAnthropometry.height}
								maxLength={3}
								action={handleChange}

								autoComplete='off'

							/>
						</div>
					</div>
					<AlertRowText
						{...getIMCResult(
							holdingAnthropometry.weight,
							holdingAnthropometry.height,
							selectedLanguage
						)}
						outlined
					/>
				</div>
				<div>
					<b>Circunferência abdominal</b>
					<div className='NewEditAnthropometry-MainRow-Input'>
						<Input
							name='abdominal_circumference'
							placeholder='cm'
							value={holdingAnthropometry.abdominal_circumference}
							maxLength={3}
							action={handleChange}
							autoComplete='off'
						/>
					</div>
				</div>
			</div>
            <div className='NewEditAnthropometry-BtnBox'>
                <NewButton 
                    onClick={saveAnthropometry}
                    label='Salvar Antropometria'
                />
            </div>
		</div>
	)
}