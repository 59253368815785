export function uniqueCIDs(combined) {
    let uniqueDict = {};
    let uniqueByCode = [];

    for (let item of combined) {
        if (!uniqueDict[item.code]) {
            uniqueDict[item.code] = true;
            uniqueByCode.push(item);
        }
    }

    return uniqueByCode
}