import React from 'react'
import NewButton, {
	ButtonModel,
} from '../../../../../../../../newcomponents/button/button'
import Timetable from '../../../../../../../../component/timetable/Timetable'
import { useHealthPlaceForm } from '../SaveHealthPlaceModal'
import { useConfiguration } from '../../../../../../configuration'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'

export default function HealthPlaceTimesData() {
	const { setModalProps } = useConfiguration()
	const { timetableRef, onNext } = useHealthPlaceForm()

	return (
		<div className='HealthPlaceTimesData HealthPlaceForm-Body'>
			<Timetable timetableRef={timetableRef} />
			<div className='HealthPlaceForm-Btn-Box'>
				<NewButton
					model={ButtonModel.SECONDARY}
					label='Fechar'
					onClick={() => setModalProps(MODAL_INIT_PROPS)}
				/>
				<NewButton
					model={ButtonModel.PRIMARY}
					label='Avançar'
					onClick={onNext}
				/>
			</div>
		</div>
	)
}
