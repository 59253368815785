import './css/ophthalmology-table.css'
import React from "react"

export const handleSignal = (number) => {
    if (number > 0 && !number.includes('+'))  {
        return `+${number}`
    }
    return number
}

export default function OphthalmologyTable({ophthalmology}) {
    const isNormalBiomicroscopy = ophthalmology?.rightEye?.biomicroscopy?.isNormal
    const isNormalFunduscopy = ophthalmology?.rightEye?.funduscopy?.isNormal

    return <React.Fragment>
        <strong>Oftalmologia:</strong>
        <div className="ophthalmologyTable-visualAcuity">
            <p>Acuidade Visual:</p>
            <div>
                <div />
                <p>SC</p>
                <p>CC</p>
                <p>OD</p>
                <strong>{ ophthalmology?.rightEye?.visualAcuity?.sc === '20/' ? '-' : ophthalmology?.rightEye?.visualAcuity?.sc }</strong>
                <strong>{ ophthalmology?.rightEye?.visualAcuity?.cc === '20/' ? '-' : ophthalmology?.rightEye?.visualAcuity?.cc }</strong>
                <p>OE</p>
                <strong>{ ophthalmology?.leftEye?.visualAcuity?.sc === '20/' ? '-' : ophthalmology?.leftEye?.visualAcuity?.sc }</strong>
                <strong>{ ophthalmology?.leftEye?.visualAcuity?.cc === '20/' ? '-' : ophthalmology?.leftEye?.visualAcuity?.cc }</strong>
            </div>
        </div>

        <div className="ophthalmologyTable-refraction">
            <p>Refração dinâmica:</p>
            <div>
                <div />
                <p>Grau E</p>
                <p>Grau C</p>
                <p>Eixo</p>
                <p>Visão</p>
                <p>Observação</p>

                <p>OD</p>
                <strong>{ handleSignal(ophthalmology?.rightEye?.dynamicRefraction?.eDegree) || '-' }</strong>
                <strong>{ handleSignal(ophthalmology?.rightEye?.dynamicRefraction?.cDegree) || '-' }</strong>
                <strong>{ ophthalmology?.rightEye?.dynamicRefraction?.axis || '-' }{ ophthalmology?.rightEye?.dynamicRefraction?.axis && '°'}</strong>
                <strong>{ ophthalmology?.rightEye?.dynamicRefraction?.vision === '20/' ? '-' : ophthalmology?.rightEye?.dynamicRefraction?.vision }</strong>
                <strong>{ ophthalmology?.rightEye?.dynamicRefraction?.notes || '-' }</strong>

                <p>OE</p>
                <strong>{ handleSignal(ophthalmology?.leftEye?.dynamicRefraction?.eDegree) || '-' }</strong>
                <strong>{ handleSignal(ophthalmology?.leftEye?.dynamicRefraction?.cDegree) || '-'}</strong>
                <strong>{ ophthalmology?.leftEye?.dynamicRefraction?.axis || '-'}{ophthalmology?.leftEye?.dynamicRefraction?.axis && '°'}</strong>
                <strong>{ ophthalmology?.leftEye?.dynamicRefraction?.vision === '20/' ? '-' : ophthalmology?.leftEye?.dynamicRefraction?.vision }</strong>
                <strong>{ ophthalmology?.leftEye?.dynamicRefraction?.notes || '-' }</strong>
            </div>
        </div>
            
        <div className="ophthalmologyTable-refraction">
            <p>Refração estática:</p>
            <div>
                <div />
                <p>Grau E</p>
                <p>Grau C</p>
                <p>Eixo</p>
                <p>Visão</p>
                <p>Observação</p>

                <p>OD</p>
                <strong>{ handleSignal(ophthalmology?.rightEye?.staticRefraction?.eDegree) || '-' }</strong>
                <strong>{ ophthalmology?.rightEye?.staticRefraction?.cDegree || '-' }</strong>
                <strong>{ ophthalmology?.rightEye?.staticRefraction?.axis || '-' }{ ophthalmology?.rightEye?.staticRefraction?.axis && '°'}</strong>
                <strong>{ ophthalmology?.rightEye?.staticRefraction?.vision === '20/' ? '-' : ophthalmology?.rightEye?.staticRefraction?.vision }</strong>
                <strong>{ ophthalmology?.rightEye?.staticRefraction?.notes || '-' }</strong>

                <p>OE</p>
                <strong>{ handleSignal(ophthalmology?.leftEye?.staticRefraction?.eDegree) || '-' }</strong>
                <strong>{ ophthalmology?.leftEye?.staticRefraction?.cDegree || '-' }</strong>
                <strong>{ ophthalmology?.leftEye?.staticRefraction?.axis || '-' }{ ophthalmology?.leftEye?.staticRefraction?.axis && '°'}</strong>
                <strong>{ ophthalmology?.leftEye?.staticRefraction?.vision === '20/' ? '-' : ophthalmology?.leftEye?.staticRefraction?.vision }</strong>
                <strong>{ ophthalmology?.leftEye?.staticRefraction?.notes || '-' }</strong>
            </div>
        </div>

        <div className="ophthalmologyTable-pressure">
            <p>Pressão intra-ocular</p>
            <div>
                <p>OD</p>
                <strong>{ ophthalmology?.rightEye?.pressure || '-' }</strong>
                <p>OE</p>
                <strong>{ ophthalmology?.leftEye?.pressure || '-' }</strong>
            </div>
        </div>

        {
            !isNormalBiomicroscopy && <div className="ophthalmologyTable-biomicroscopy">
                <p>Biomicroscopia ({isNormalFunduscopy ? 'Normal' : 'Outros'}):</p>
                <div>
                    <div />
                    <p>OD</p>
                    <p>OE</p>

                    <p>Câmara anterior</p>
                    <strong>{ handleSignal(ophthalmology?.rightEye?.biomicroscopy.anteriorChamber) || '-' }</strong>
                    <strong>{ handleSignal(ophthalmology?.leftEye?.biomicroscopy.anteriorChamber) || '-' }</strong>

                    <p>Cristalino</p>
                    <strong>{ handleSignal(ophthalmology?.rightEye?.biomicroscopy.crystalline) || '-' }</strong>
                    <strong>{ handleSignal(ophthalmology?.leftEye?.biomicroscopy.crystalline) || '-' }</strong>

                    <p>Córnea</p>
                    <strong>{ handleSignal(ophthalmology?.rightEye?.biomicroscopy.cornea) || '-' }</strong>
                    <strong>{ handleSignal(ophthalmology?.leftEye?.biomicroscopy.cornea) || '-' }</strong>

                    <p>Conjuntiva</p>
                    <strong>{ handleSignal(ophthalmology?.rightEye?.biomicroscopy.conjunctiva) || '-' }</strong>
                    <strong>{ handleSignal(ophthalmology?.leftEye?.biomicroscopy.conjunctiva) || '-' }</strong>
                </div>
            </div>
        }
        
        {
            <div className="ophthalmologyTable-funduscopy">
                <p>Fundoscopia ({isNormalFunduscopy ? 'Normal' : 'Outros'}):</p>
                <div>
                    <div />
                    <p>OD</p>
                    <p>OE</p>

                    <p>Excavação</p>
                    <strong>{ handleSignal(ophthalmology?.rightEye?.funduscopy.excavation) || '-' }</strong>
                    <strong>{ handleSignal(ophthalmology?.leftEye?.funduscopy.excavation) || '-' }</strong>

                    {
                        !isNormalFunduscopy && <>
                            <p>Região Macular</p>
                            <strong>{ handleSignal(ophthalmology?.rightEye?.funduscopy.macularRegion) || '-' }</strong>
                            <strong>{ handleSignal(ophthalmology?.leftEye?.funduscopy.macularRegion) || '-' }</strong>

                            <p>Periferia</p>
                            <strong>{ handleSignal(ophthalmology?.rightEye?.funduscopy.periphery) || '-' }</strong>
                            <strong>{ handleSignal(ophthalmology?.leftEye?.funduscopy.periphery) || '-' }</strong>

                            <p>Vítreo</p>
                            <strong>{ handleSignal(ophthalmology?.rightEye?.funduscopy.vitreous) || '-' }</strong>
                            <strong>{ handleSignal(ophthalmology?.leftEye?.funduscopy.vitreous) || '-' }</strong>
                        </>
                    }
                    
                </div>
            </div>
        }
    </React.Fragment>
}