import './NotesSection.css'
import React from "react"
import TextAppointmentField from '../TextAppointmentField/TextAppointmentField'

export default function NotesSection() {

    return <>
        <TextAppointmentField 
            field_type='notes'
            name={'Anotações'}
            notes='* Esse campo serve como bloco de notas e não é compartilhado com o paciente.'
        />
    </>
}