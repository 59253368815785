import React from 'react'

export default function ToothIcon({ width, height }) {
    return <svg version="1.0" width={width} height={height} viewBox="0 0 512 512">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" >
    <path d="M1285 5000 c-331 -35 -599 -163 -835 -400 -212 -211 -348 -463 -414
    -765 -74 -345 -22 -864 151 -1479 256 -916 719 -1849 1060 -2138 119 -101 235
    -129 329 -80 107 55 127 103 348 857 156 532 235 740 351 921 113 176 255 237
    400 170 191 -87 331 -380 540 -1131 158 -568 205 -705 264 -768 64 -70 173
    -89 265 -49 281 125 678 787 1010 1687 216 585 339 1124 362 1583 34 692 -354
    1307 -958 1520 -172 61 -388 90 -542 73 -232 -26 -383 -86 -871 -346 -88 -46
    -171 -85 -185 -85 -14 0 -97 39 -185 86 -263 140 -494 251 -591 282 -174 58
    -341 79 -499 62z m271 -215 c214 -38 445 -143 659 -301 272 -201 381 -265 550
    -322 237 -80 509 -100 765 -56 136 24 126 34 -45 49 -210 18 -459 66 -604 117
    -92 32 -181 76 -181 89 0 42 503 311 709 379 155 52 260 65 404 51 182 -18
    345 -71 489 -159 293 -179 499 -481 580 -852 19 -89 22 -134 22 -295 0 -198
    -8 -283 -50 -523 -159 -916 -712 -2199 -1107 -2569 -76 -71 -104 -84 -123 -58
    -19 26 -91 258 -188 605 -110 393 -147 515 -208 680 -132 358 -279 565 -464
    657 -71 35 -85 38 -178 41 -164 6 -267 -38 -388 -165 -173 -184 -299 -478
    -498 -1173 -99 -345 -186 -620 -204 -645 -27 -37 -93 5 -198 124 -350 401
    -794 1408 -978 2223 -76 333 -104 553 -104 803 0 160 3 207 23 295 80 370 286
    673 579 852 219 134 504 193 738 153z"/>
    </g>
    </svg>
    
}

