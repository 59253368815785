export class Glasses {
    constructor(glasses=null) {
        this.type = {id: 'FAR', name: 'Longe'}
        this.rightEyeEDegree = ''
        this.rightEyeCDegree = ''
        this.rightEyeAxis = ''
        this.rightEyeDNP = ''
        this.leftEyeEDegree = ''
        this.leftEyeCDegree = ''
        this.leftEyeAxis = ''
        this.leftEyeDNP = ''
        this.addition = ''
        this.notes = ''

        if (glasses !== {} && glasses !== null) {
            Object.assign(this, glasses)
        }
    }
}


export const validateGlasses = (glasses) => {
    const errors = {}

    Object.entries(glasses)
    .filter(([key, _]) => key !== 'addition' && key !== 'notes')
    .forEach(([key, value]) => {
        if (key === 'type' && !value.id === 'FAR' && !value.id === 'CLOSE' && !value.id === 'MULTIFOCAL' && !value.id === 'BIFOCAL') {
            errors[key] = 'glasses type is invalid'
        } else {
            if (value === '') {
                errors[key] = `${key} is invalid`
            }
        }
    })
    return errors
}
