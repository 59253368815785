export const strpImediatePostPartum = (imediatePostPartum=null) => {
    return {
        'project': imediatePostPartum?.project || -1,
        'pinard_global': imediatePostPartum?.pinard_global
            ? (typeof imediatePostPartum?.pinard_global === 'object' ? imediatePostPartum?.pinard_global?.id : imediatePostPartum?.pinard_global)
            : '',
        'transvaginal_bleeding': imediatePostPartum?.transvaginal_bleeding
            ? (typeof imediatePostPartum?.transvaginal_bleeding === 'object' ? imediatePostPartum?.transvaginal_bleeding?.id : imediatePostPartum?.transvaginal_bleeding)
            : 'NOT_EVALUATED',
        'oxytocic': imediatePostPartum?.oxytocic
            ? (typeof imediatePostPartum?.oxytocic === 'object' ? imediatePostPartum?.oxytocic?.id : imediatePostPartum?.oxytocic)
            : '',
        'breastfeeding_in_frst_hour': imediatePostPartum?.breastfeeding_in_frst_hour
            ? (typeof imediatePostPartum?.breastfeeding_in_frst_hour === 'object' ? imediatePostPartum?.breastfeeding_in_frst_hour?.id : imediatePostPartum?.breastfeeding_in_frst_hour)
            : '',
        'notes': imediatePostPartum?.notes || ''
    }
}

export const parseImediatePostPartum = (imediatePostPartum, project_id) => {

    return {
        'project': project_id,
        'pinard_global': imediatePostPartum?.pinard_global
            ? (typeof imediatePostPartum?.pinard_global === 'object' ? imediatePostPartum?.pinard_global?.id : imediatePostPartum?.pinard_global)
            : '',
        'transvaginal_bleeding': imediatePostPartum?.transvaginal_bleeding
            ? (typeof imediatePostPartum?.transvaginal_bleeding === 'object' ? imediatePostPartum?.transvaginal_bleeding?.id : imediatePostPartum?.transvaginal_bleeding)
            : 'NOT_EVALUATED',
        'oxytocic': imediatePostPartum?.oxytocic
            ? (typeof imediatePostPartum?.oxytocic === 'object' ? imediatePostPartum?.oxytocic?.id : imediatePostPartum?.oxytocic)
            : '',
        'breastfeeding_in_frst_hour': imediatePostPartum?.breastfeeding_in_frst_hour
            ? (typeof imediatePostPartum?.breastfeeding_in_frst_hour === 'object' ? imediatePostPartum?.breastfeeding_in_frst_hour?.id : imediatePostPartum?.breastfeeding_in_frst_hour)
            : '',
        'notes': imediatePostPartum?.notes || ''
    }
}