import './HealthPlaceInstitutionalAppearance.css'
import React from "react";
import { useConfiguration } from '../../../../../configuration';
import HorizontalField from '../../../../../../../component/HorizontalField/HorizontalField';
import HealthPlaceInstitutionalAppearanceModal from './HealthPlaceInstitutionalAppearanceModal/HealthPlaceInstitutionalAppearanceModal';



export default function HealthPlaceInstitutionalAppearance({ pageRef }) {
    const { institution, setModalProps } = useConfiguration()

    return <div className='HealthPlaceInstitutionalAppearance'>
        <div className='HealthPlaceInstitutionalAppearance-Header'>
            <div className='HealthPlaceInstitutionalAppearance-Header-Icon'>A</div>
            <div className='HealthPlaceInstitutionalAppearance-Header-Title'>Aparência</div>
        </div>
        <div>
            <HorizontalField 
                label='Cor Primária' 
                content={<div 
                    style={{ width: '12px', height: '12px', backgroundColor: institution?.primary_color, borderRadius: '50%' }} 
                />}
            />
            {/* <HorizontalField
                label='Cor Secundária' 
                content={<div 
                    style={{ width: '12px', height: '12px', backgroundColor: institution?.primary_color_light, borderRadius: '50%' }} 
                />} 
            /> */}
            <HorizontalField
                label='Cor Secundária' 
                content={<div 
                    style={{ width: '12px', height: '12px', backgroundColor: institution?.primary_color_lighter, borderRadius: '50%' }} 
                />} 
            />
            <HorizontalField
                label='Cor Secundária 2' 
                content={<div 
                    style={{ width: '12px', height: '12px', backgroundColor: institution?.primary_color_light_2, borderRadius: '50%' }} 
                />} 
            />
            <HorizontalField 
                label='Cor Terciária' 
                content={<div 
                    style={{ width: '12px', height: '12px', backgroundColor: institution?.primary_color_dark, borderRadius: '50%' }} 
                />} 
            />
            <HorizontalField 
                label='Cor Terciária 2' 
                content={<div 
                    style={{ width: '12px', height: '12px', backgroundColor: institution?.primary_color_darker, borderRadius: '50%' }} 
                />} 
            />
            <HorizontalField 
                label='Cor da Fonte' 
                content={<div 
                    style={{ width: '12px', height: '12px', backgroundColor: institution?.primary_font_color, borderRadius: '50%', border: '1px grey solid'}} 
                />} 
            />
        </div>
        <div className='HealthPlaceInstitutionalAppearance-ActionRow'>
            <div
                className='HealthPlaceInstitutionalAppearance-ActionRow-Btn' 
                onClick={() => setModalProps(prev => ({
                    ...prev,
                    open: true,
                    title: 'Editar Instituição',
                    content: <HealthPlaceInstitutionalAppearanceModal pageRef={pageRef}/>,
                    dismissText: '',
                }))}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}