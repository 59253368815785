import './GestationUnit.css'
import React from "react";
import { useHistory } from 'react-router-dom'
import { IcoCalendario, IcoEstetoscopio, MaskDataHora } from '../../../../../../../component';
import InfoLine from '../../../../../../../component/info-line/info-line';
import PregnantIcon from '../../../../../../../component/icon/pregnant';
import { MaskXXWeekYDay, translateABOAndRHFactor } from '../../../../../../consultation/subcomponents/ConsultationManager/subcomponents/SOAP/subcomponents/ThousandDaysSection/subsections/InitSubSection/utils';

export default function GestationUnit({ project=null }) {
    const history = useHistory()
    const projectStartDate = MaskDataHora(project.start_date)

    const abo_group_rh_factor_exam = project?.abo_group_rh_factor_exams?.[0]
    
    return <div className='GestationUnit'>
        <div className='GestationUnit-Header'>
            <div>
                <div className='GestationUnit-Header-TitleBox'>
                    <b className='GestationUnit-Header-TitleBox-PatientName'>{project.patient?.social_name || project.patient?.name || 'Paciente não encontrado'}</b>
                </div>
                <span className='GestationUnit-Header-AltText'>{project?.patient.social_name ? (`Nome de registro: ${project.patient.name} |` || 'Nome não registrada') : ''} { projectStartDate?.data ? `Acompanhamento iniciado em ${projectStartDate.data}` : '-'}</span>
                <div className='GestationUnit-Header-TitleBox-AlertsBox'>
                    <div className='GestationUnit-Header-TitleBox-AlertsBox-Box'>
                        <div className='GestationUnit-AppointmentBox-Subtitle-DecisionBox'>
                            <PregnantIcon style={{ fill: '#80a6e5', fontSize: '1.2rem' }} />
                            <b>{ MaskXXWeekYDay(project?.calculated_pregnancy_age?.age, 'pt-BR') }</b>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div>
            <div className='GestationUnit-AppointmentBox-Subtitle'><b>Resumo</b></div>
            <div className='GestationUnit-AppointmentBox-Grid'>
                <InfoLine 
                    label='Consultas realizadas'
                    value={<span className='GestationUnit-AppointmentBox-Grid-AltText'>{project?.previous_prenatal_attendances?.length}</span>}
                />
                <InfoLine
                    label='Grupo ABO & Fator RH'
                    value={abo_group_rh_factor_exam?.abo_group && abo_group_rh_factor_exam?.rh_factor
                    ? <span className='GestationUnit-PatientBox-Grid-AltText'>
                            {translateABOAndRHFactor[abo_group_rh_factor_exam?.abo_group]}
                            {translateABOAndRHFactor[abo_group_rh_factor_exam?.rh_factor]}
                    </span>
                    : <span className='GestationUnit-PatientBox-Grid-AltText'>
                        -
                    </span>}
                />
            </div>
        </div>
        <div className='GestationUnit-ActionRow'>
            <div className='GestationUnit-ActionRow-Btn' onClick={() => history.push(`/pacientes/${project?.patient?.id}/detalhar`)}>
                <IcoEstetoscopio /> <b>Ver paciente</b>
            </div>
            <div className='GestationUnit-ActionRow-Btn' onClick={() => history.push(`/atendimentos/agendamento`)}>
                <IcoCalendario /> <b>Agendar atendimento</b>
            </div>
        </div>
    </div>
}