import React from 'react'

export function IcoEyeBlocked() {
  return (
    <svg className='icon-eye-blocked' viewBox='0 0 48 48'>
      <path d='M44.341 0.659c-0.879-0.879-2.303-0.879-3.182 0l-9.476 9.476c-2.435-0.738-5.013-1.135-7.683-1.135-10.468 0-19.541 6.096-24 15 1.928 3.85 4.718 7.174 8.11 9.708l-7.451 7.451c-0.879 0.879-0.879 2.303 0 3.182 0.439 0.439 1.015 0.659 1.591 0.659s1.152-0.22 1.591-0.659l40.5-40.5c0.879-0.879 0.879-2.303 0-3.182zM19.5 15c1.98 0 3.66 1.279 4.262 3.056l-5.706 5.706c-1.777-0.602-3.056-2.282-3.056-4.262 0-2.485 2.015-4.5 4.5-4.5zM5.162 24c1.795-2.837 4.185-5.246 7.005-7.045 0.184-0.117 0.369-0.231 0.556-0.342-0.467 1.281-0.722 2.664-0.722 4.106 0 2.573 0.81 4.956 2.189 6.91l-2.856 2.856c-2.458-1.727-4.556-3.932-6.172-6.485z'></path>
      <path d='M36 20.719c0-1.273-0.2-2.5-0.567-3.651l-15.085 15.085c1.152 0.368 2.379 0.567 3.652 0.567 6.627 0 12-5.373 12-12z'></path>
      <path d='M38.907 13.593l-3.251 3.251c0.059 0.037 0.119 0.073 0.177 0.111 2.82 1.799 5.21 4.208 7.005 7.045-1.795 2.837-4.185 5.246-7.005 7.045-3.543 2.26-7.635 3.455-11.834 3.455-1.812 0-3.605-0.223-5.342-0.658l-3.604 3.604c2.799 1.006 5.81 1.554 8.946 1.554 10.468 0 19.541-6.096 24-15-2.11-4.215-5.256-7.8-9.093-10.407z'></path>
    </svg>
  )
}
