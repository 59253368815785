import './HealthPlaceInventory.css'
import React, { useEffect, useState } from "react";
import HealthPlaceInventoryConfig from "./subcomponents/HealthPlaceInventoryConfig/HealthPlaceInventoryConfig";
import HealthPlaceInventoryAdmin from './subcomponents/HealthPlaceInventoryAdmin/HealthPlaceInventoryAdmin';
import { getInventoryConfig } from './http';
import { useApp } from '../../../../../layout/App';

function strpAccessConfiguration(access) {
    return {
        health_place: access?.['health_place'] || null,
        can_read: access?.['can_read'] || [],
        can_read_and_write: access?.['can_read_and_write'] || ['ADMINISTRATOR','ASSISTANT','DENTIST','NURSE','DOCTOR','NUTRITIONIST','PSYCHOLOGIST'],
        admins: access?.['admins'] || []
    }
}

export default function HealthPlaceInventory() {
    const { currentHealthPlaceUser } = useApp()
    const [accessConfiguration, setAccessConfiguration] = useState(strpAccessConfiguration())

    useEffect(() => {
        fetchInventoryConfig()
    }, [])

    const fetchInventoryConfig = async () => {
		if (currentHealthPlaceUser?.health_place?.id) {
			try {
				const res = await getInventoryConfig(currentHealthPlaceUser?.health_place?.id)
                setAccessConfiguration(strpAccessConfiguration(res.data))
			} catch (err) {
				setAccessConfiguration(strpAccessConfiguration())
				console.error('HealthPlaceInventory ~ fetchInventoryConfig ~ ', err)
			}
		}
	}

    return (
        <div className='HealthPlaceInventory'>
		<div className='HealthPlaceInventory-Col'>
			<div className='HealthPlaceInventory-Container'>
                <HealthPlaceInventoryConfig
                    accessConfiguration={accessConfiguration}
                    fetchInventoryConfig={fetchInventoryConfig}
                />
			</div>
		</div>
		<div className='HealthPlaceInventory-Col'>
			<div className='HealthPlaceInventory-Container'>
                <HealthPlaceInventoryAdmin
                    accessConfiguration={accessConfiguration}
                    fetchInventoryConfig={fetchInventoryConfig}
                />
			</div>
		</div>
    </div>
    )
}
