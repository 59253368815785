import { useHistory } from 'react-router-dom';
import { IcoEstetoscopio } from '../../../../../../component';
import IcoBusiness from '../../../../../../component/icon/business';
import './appointmentUnit.css'

export default function AppointmentUnit ({ appointment=null }) {
    const { DECISION_CHOICES, HEALTH_PLACE_TYPE_CHOICES } = require('../../../../../../component/form/select/options.json')

    const formatText = (text) => {
        let formattedText = text.replace(/<[^>]*>/g, ' | ');
        return formattedText.trim().replace(/^\|\s*/, '').replace(/\s*\|$/, '');
    }

    const formatDateTime = (stringDate) => {
        const monthNames = [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
            "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
        const date = new Date(stringDate)

        const day = String(date.getDate()).padStart(2, '0');
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day} de ${month} de ${year}, às ${hours}:${minutes}`
    }

    return (
        <div className='AppointmentUnit-Container' style={{
            border: `1px solid ${(DECISION_CHOICES?.find(({ id }) => id === appointment.decision)).color}`,
            backgroundColor: (DECISION_CHOICES?.find(({ id }) => id === appointment.decision)).secondColor,
            color: (DECISION_CHOICES?.find(({ id }) => id === appointment.decision)).color
        }}>
            <div className='AppointmentUnit-InfoLine'>
                <p className='AppointmentUnit-Title'>{formatDateTime(appointment.start)}</p>
            </div>
            <div className='AppointmentUnit-InfoLine'>
                <IcoBusiness style={{width: '20px', height: '20px', fill: (DECISION_CHOICES?.find(({ id }) => id === appointment.decision)).color }}/>
                <p>{HEALTH_PLACE_TYPE_CHOICES.find(({ id }) => id === appointment.health_place.type).name} {appointment.health_place.name}</p>
            </div>
            <div className='AppointmentUnit-InfoLine'>
                <IcoEstetoscopio style={{width: '20px', height: '20px'}}/> 
                <p>{appointment.doctor.name} {appointment?.specialty?.id ?  `- ${appointment?.specialty?.name}`: ''}</p>
            </div>
            <div className='AppointmentUnit-InfoLine status-line'>
                <p className='AppointmentUnit-Status'>{(DECISION_CHOICES?.find(({ id }) => id === appointment.decision)).name}</p>
            </div>
            { 
            //campo deixado de fora até incluir funcionalidade de observação de uma consulta.
            /* <div className='AppointmentUnit-InfoLine'>
                <p>{formatText(appointment.notes)}</p>
            </div> */
            }
        </div>
    )
}