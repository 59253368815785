import './LoginDentistModal.css'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AddAlert, AlertType, Input } from '../../../../component'
import api from '../../../../helpers/api'
import Button from '../../../../newcomponents/button/button'
import Loading from '../../../../newcomponents/loading/loading'
import { loaded, loading } from '../../../../layout/redux/AppActions'

export default function LoginDentistModal({ email }) {
    const dispatch = useDispatch()
    const [loadingModal, setLoadingModal] = useState(true)
    const [codeError, setCodeError] = useState(false)

    useEffect(() => {
        api.get(`auth/verification_code/?email=${email}`)
            .then(() => {
                setLoadingModal(false)
            })
            .catch(err => {
                dispatch(AddAlert('Código de Verificação', 'Falha ao enviar email de verificação, tente novamente em instantes', AlertType.ERROR))
                console.error(err)
                setLoadingModal(false)
            })
    }, [email]) // eslint-disable-line

    useEffect(() => {
        if (!loadingModal) {
            const inputElements = [...document.querySelectorAll('#id-codeinput')]
            inputElements.forEach((node, index)=> {
                node.addEventListener('keydown', (event) => {
                    if (event.keyCode === 8 && event.target.value === '') inputElements[Math.max(0, index-1)].focus()
                })
                node.addEventListener('input', (event) => {
                    setCodeError(false)
                    const [first,...rest] = event.target.value
                    event.target.value = first ?? ''
                    const lastInputBox = index === inputElements.length-1
                    const didInsertContent = first !== undefined
                    if (didInsertContent && !lastInputBox) {
                        inputElements[index+1].focus()
                        inputElements[index+1].value = rest.join('')
                        inputElements[index+1].dispatchEvent(new Event('input'))
                    }
                })
            })
        }
    }, [loadingModal])


    if (loadingModal) return <section className='LoginDentistModal'><Loading /></section>

    const sendEmailVerificationAgain = () => {
        dispatch(loading())
        api.get(`auth/verification_code/?email=${email}`)
            .then(() => dispatch([
                loaded(),
                AddAlert('Código de Verificação', 'Foi enviado novamente para seu e-mail, verifique sua caixa de spam', AlertType.INFO, 5000),
                
            ]))
            .catch(() => dispatch([
                loaded(),
                AddAlert('Código de Verificação', 'Falha ao enviar e-mail de verificação, tente novamente', AlertType.ERROR, 5000),
            ]))
    }

    const handleVerification = () => {
        const inputElements = [...document.querySelectorAll('#id-codeinput')]
        const code = inputElements.map(node => node.value).join('')

        if (code.length !== 6) return setCodeError(true)
        dispatch(loading())
        api.post('auth/verification_code/', { email: email, verification_code: code })
            .then(res => {
                dispatch(loaded())
                const user = res.data.user
                localStorage.setItem('token', res.data.key)
                localStorage.setItem('user', JSON.stringify(res.data.user))
            })
            .catch(err => {
                dispatch([
                    loaded(),
                    AddAlert('Código de Verificação', 'Falha ao verificar código', AlertType.ERROR),
                ])
                console.error(err)
            })
    }
    
    return <section className='LoginDentistModal'>
        <div>
            <span>Por favor preencha o código de verificação enviado para o e-mail <b>{email}</b>:</span>
            <div className='LoginDentistModal-VerificationCode'>
                <Input name='codeinput' maxLength={1} errors={{ error: { codeinput: codeError }, message: codeError }} />
                <Input name='codeinput' maxLength={1} errors={{ error: { codeinput: codeError }, message: codeError }} />
                <Input name='codeinput' maxLength={1} errors={{ error: { codeinput: codeError }, message: codeError }} />
                <Input name='codeinput' maxLength={1} errors={{ error: { codeinput: codeError }, message: codeError }} />
                <Input name='codeinput' maxLength={1} errors={{ error: { codeinput: codeError }, message: codeError }} />
                <Input name='codeinput' maxLength={1} errors={{ error: { codeinput: codeError }, message: codeError }} />
            </div>
            <Button 
                label='Verificar'
                onClick={handleVerification}
            />
            <p className='LoginDentistModal-SendAgain'>Não recebeu? <span onClick={sendEmailVerificationAgain}>Enviar código novamente</span></p>
        </div>
    </section>
}