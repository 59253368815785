import React, { useEffect } from 'react'
import { useThousandDays } from '../../../../../../ThousandDaysSection'
import { Input, RadioButton } from '../../../../../../../../../../../../../../component'
import AlertRowText from '../../../../../../../../../../../../../../component/alertRowText/alertRowText'
import NewSelect from '../../../../../../../../../../../../../../newcomponents/form/select/select'
import {
	getAPGARResults,
	getNewBornBodyTemperatureResults,
	getNewBornHeartRate,
	getNewBornLengthResults,
	getNewBornOxygenSaturation,
	getNewBornRespirationRateResults,
	getNewBornWeightResults,
} from '../../utils'

const partograph_data = require('../../Partograph.json')

export default function NewBorn({ handleChange, handleSelect }) {
	const { selectedLanguage, partograph, savePartograph } = useThousandDays()

	const {
		Gestationalage,
		Weight,
		Length,
		Oxygensaturation,
		Bodytemperature,
		Heartrate,
		Respiratoryrate,
		APGARscoreat1minute,
		APGARscoreat5minutes,
		Weeks,
		Days,
		Apgar_Appearance_Options,
		Apgar_Heart_Rate_Options,
		Apgar_Gesticulation_Options,
		Apgar_Activity_Options,
		Apgar_Respiration_Options
	} = require('../../../../../../../../../../../../../data/translation.json')[selectedLanguage]

	const apgar_appearance_options = partograph_data.apgar_appearance_options.map(
		option => ({
			id: option.id,
			name: Apgar_Appearance_Options[option.id],
		})
	)

	const apgar_heart_rate_options = partograph_data.apgar_heart_rate_options.map(
		option => ({
			id: option.id,
			name: Apgar_Heart_Rate_Options[option.id],
		})
	)

	const apgar_gesticulation_options =
		partograph_data.apgar_gesticulation_options.map(option => ({
			id: option.id,
			name: Apgar_Gesticulation_Options[option.id],
		}))

	const apgar_activity_options = partograph_data.apgar_activity_options.map(
		option => ({
			id: option.id,
			name: Apgar_Activity_Options[option.id],
		})
	)

	const apgar_respiration_options =
		partograph_data.apgar_respiration_options.map(option => ({
			id: option.id,
			name: Apgar_Respiration_Options[option.id],
		}))

	let {
		apgar_first_appearance,
		apgar_first_heart_rate,
		apgar_first_gesticulation,
		apgar_first_activity,
		apgar_first_min_respiration,
		apgar_fifth_appearance,
		apgar_fifth_heart_rate,
		apgar_fifth_gesticulation,
		apgar_fifth_activity,
		apgar_fifth_min_respiration,
	} = partograph

	useEffect(() => {
		savePartograph()
	}, [
		partograph.ultrasound_weeks,
		partograph.ultrasound_days,
		partograph.apgar_first_appearance,
		partograph.apgar_first_heart_rate,
		partograph.apgar_first_gesticulation,
		partograph.apgar_first_activity,
		partograph.apgar_first_min_respiration,
		partograph.apgar_fifth_appearance,
		partograph.apgar_fifth_heart_rate,
		partograph.apgar_fifth_gesticulation,
		partograph.apgar_fifth_activity,
		partograph.apgar_fifth_min_respiration,
	])

	return (
		<div>
			<p>
				<b>{Gestationalage}</b>
			</p>
			<div className='ObstetricUltrasonographyWrite-UltrasoundBox'>
				<NewSelect
					id='ultrasound_weeks'
					options={partograph_data.ultraweekoptions}
					selected={partograph.ultrasound_weeks}
					onSelect={handleSelect}
					canBeEmpty={false}
					label={Weeks}
				/>
				<NewSelect
					id='ultrasound_days'
					options={partograph_data.ultradayoptions}
					selected={partograph.ultrasound_days}
					onSelect={handleSelect}
					canBeEmpty={false}
					label={Days}
				/>
			</div>
			<div className='ThousandDaysSection-Childbirth-Separator' />
			<div className='ThousandDaysSection-Childbirth-Anthropometry'>
				<div>
					<b>{Weight}</b>
					<div className='ThousandDaysSection-Childbirth-VitalSigns-Input'>
						<Input
							name='newborn_weight'
							action={handleChange}
							actionBlur={savePartograph}
							value={partograph.newborn_weight}
							placeholder='g'
							maxLength={5}
							autoComplete='off'
						/>
					</div>
					<AlertRowText
						{...getNewBornWeightResults(partograph.newborn_weight, selectedLanguage)}
						outlined
					/>
				</div>
				<div>
					<b>{Length}</b>
					<div className='ThousandDaysSection-Childbirth-VitalSigns-Input'>
						<Input
							name='newborn_height'
							action={handleChange}
							actionBlur={savePartograph}
							value={partograph.newborn_height}
							placeholder='cm'
							maxLength={2}
							autoComplete='off'
						/>
					</div>
					<AlertRowText
						{...getNewBornLengthResults(
							partograph.newborn_height,
							selectedLanguage
						)}
						outlined
					/>
				</div>
			</div>
			<div className='ThousandDaysSection-Childbirth-Separator' />
			<div className='ThousandDaysSection-Childbirth-VitalSigns'>
				<div>
					<b>{Oxygensaturation}</b>
					<div className='ThousandDaysSection-Childbirth-VitalSigns-Input'>
						<Input
							name='newborn_oxygen_saturation'
							action={handleChange}
							actionBlur={savePartograph}
							value={partograph.newborn_oxygen_saturation}
							placeholder='%'
							maxLength={5}
							autoComplete='off'
						/>
					</div>
					<AlertRowText
						{...getNewBornOxygenSaturation(
							partograph.newborn_oxygen_saturation,
							selectedLanguage
						)}
						outlined
					/>
				</div>
				<div>
					<b>{Bodytemperature}</b>
					<div className='ThousandDaysSection-Childbirth-VitalSigns-Input'>
						<Input
							name='newborn_body_temperature'
							placeholder='°C'
							maxLength={4}
							value={partograph.newborn_body_temperature}
							action={handleChange}
							actionBlur={savePartograph}
							autoComplete='off'
						/>
					</div>
					<AlertRowText
						{...getNewBornBodyTemperatureResults(
							partograph.newborn_body_temperature,
							selectedLanguage
						)}
						outlined
					/>
				</div>
				<div>
					<b>{Heartrate}</b>
					<div className='ThousandDaysSection-Childbirth-VitalSigns-Input'>
						<Input
							name='newborn_heart_rate'
							placeholder='rpm'
							maxLength={3}
							value={partograph.newborn_heart_rate}
							action={handleChange}
							actionBlur={savePartograph}
							autoComplete='off'
						/>
					</div>
					<AlertRowText
						{...getNewBornHeartRate(partograph.newborn_heart_rate, selectedLanguage)}
						outlined
					/>
				</div>
				<div>
					<b>{Respiratoryrate}</b>
					<div className='ThousandDaysSection-Childbirth-VitalSigns-Input'>
						<Input
							name='newborn_respiration_rate'
							placeholder='mpm'
							maxLength={2}
							value={partograph.newborn_respiration_rate}
							action={handleChange}
							actionBlur={savePartograph}
							autoComplete='off'
						/>
					</div>
					<AlertRowText
						{...getNewBornRespirationRateResults(
							partograph.newborn_respiration_rate,
							selectedLanguage
						)}
						outlined
					/>
				</div>
			</div>
			<div className='ThousandDaysSection-Childbirth-Separator' />

			<p className='ThousandDaysChildBirth_APGAR_Title'>
				<b>{APGARscoreat1minute}</b>
			</p>
			<div className='ThousandDaysChildBirth_APGAR '>
				<RadioButton
					label='Aparência'
					name='apgar_first_appearance'
					action={handleChange}
					checked={apgar_first_appearance}
					options={apgar_appearance_options}
				/>
			</div>
			<div className='ThousandDaysChildBirth_APGAR'>
				<RadioButton
					label='Pulso'
					name='apgar_first_heart_rate'
					action={handleChange}
					checked={apgar_first_heart_rate}
					options={apgar_heart_rate_options}
				/>
			</div>
			<div className='ThousandDaysChildBirth_APGAR'>
				<RadioButton
					label='Gesticulação'
					name='apgar_first_gesticulation'
					action={handleChange}
					checked={apgar_first_gesticulation}
					options={apgar_gesticulation_options}
				/>
			</div>
			<div className='ThousandDaysChildBirth_APGAR'>
				<RadioButton
					label='Atividade'
					name='apgar_first_activity'
					action={handleChange}
					checked={apgar_first_activity}
					options={apgar_activity_options}
				/>
			</div>
			<div className='ThousandDaysChildBirth_APGAR'>
				<RadioButton
					label='Respiração'
					name='apgar_first_min_respiration'
					action={handleChange}
					checked={apgar_first_min_respiration}
					options={apgar_respiration_options}
				/>
			</div>
			<AlertRowText
				{...getAPGARResults(
					apgar_first_appearance,
					apgar_first_heart_rate,
					apgar_first_gesticulation,
					apgar_first_activity,
					apgar_first_min_respiration,
					selectedLanguage
				)}
				outlined
			/>
			<div className='ThousandDaysSection-Childbirth-Separator' />

			<p className='ThousandDaysChildBirth_APGAR_Title'>
				<b>{APGARscoreat5minutes}</b>
			</p>
			<div className='ThousandDaysChildBirth_APGAR'>
				<RadioButton
					label='Aparência'
					name='apgar_fifth_appearance'
					action={handleChange}
					checked={partograph.apgar_fifth_appearance}
					options={apgar_appearance_options}
				/>
			</div>
			<div className='ThousandDaysChildBirth_APGAR'>
				<RadioButton
					label='Pulso'
					name='apgar_fifth_heart_rate'
					action={handleChange}
					checked={partograph.apgar_fifth_heart_rate}
					options={apgar_heart_rate_options}
				/>
			</div>
			<div className='ThousandDaysChildBirth_APGAR'>
				<RadioButton
					label='Gesticulação'
					name='apgar_fifth_gesticulation'
					action={handleChange}
					checked={partograph.apgar_fifth_gesticulation}
					options={apgar_gesticulation_options}
				/>
			</div>
			<div className='ThousandDaysChildBirth_APGAR'>
				<RadioButton
					label='Atividade'
					name='apgar_fifth_activity'
					action={handleChange}
					checked={partograph.apgar_fifth_activity}
					options={apgar_activity_options}
				/>
			</div>
			<div className='ThousandDaysChildBirth_APGAR'>
				<RadioButton
					label='Respiração'
					name='apgar_fifth_min_respiration'
					action={handleChange}
					checked={partograph.apgar_fifth_min_respiration}
					options={apgar_respiration_options}
				/>
			</div>
			<AlertRowText
				{...getAPGARResults(
					apgar_fifth_appearance,
					apgar_fifth_heart_rate,
					apgar_fifth_gesticulation,
					apgar_fifth_activity,
					apgar_fifth_min_respiration,
					selectedLanguage
				)}
				outlined
			/>
		</div>
	)
}
