import React from 'react'

export default function InfoOutlinedIcon({ style, id }) {
  return (
    <svg style={style} viewBox="0 0 512.000000 512.000000" id={id}>
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M2270 4835 c-769 -103 -1429 -575 -1765 -1260 -107 -220 -171 -417
            -212 -663 -26 -152 -26 -552 0 -704 41 -245 104 -441 212 -663 310 -634 914
            -1097 1610 -1234 333 -65 706 -53 1039 34 524 137 1004 481 1312 942 428 640
            505 1467 203 2170 -308 720 -948 1227 -1719 1363 -161 29 -520 36 -680 15z
            m520 -316 c232 -28 424 -86 635 -189 576 -283 960 -794 1081 -1440 22 -116 30
            -423 15 -547 -28 -228 -87 -432 -182 -631 -292 -616 -878 -1032 -1563 -1113
            -126 -15 -431 -6 -546 15 -432 81 -786 269 -1087 577 -157 161 -251 296 -353
            504 -140 285 -200 546 -200 865 0 320 61 582 200 865 200 407 497 704 905 905
            204 100 405 161 625 189 96 12 373 13 470 0z"/>
            <path d="M2510 3621 c-105 -34 -160 -106 -160 -211 0 -58 23 -111 67 -152 104
            -99 273 -65 334 67 34 74 19 176 -34 233 -47 51 -149 82 -207 63z"/>
            <path d="M2480 2908 c-18 -13 -43 -36 -54 -51 -21 -28 -21 -36 -24 -713 -2
            -666 -2 -685 17 -723 58 -113 224 -113 282 0 19 38 19 57 17 723 -3 677 -3
            685 -24 713 -35 48 -82 73 -134 73 -32 0 -57 -7 -80 -22z"/>
        </g>
    </svg>
  )
}
