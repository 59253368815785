import './EditCategoriesOptionsModal.css'
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { AddAlert, AlertType, IcoBin, IcoPencil, Input, List } from '../../../../../../../../../component';
import NewButton, { ButtonModel } from '../../../../../../../../../newcomponents/button/button';
import { useApp } from '../../../../../../../../../layout/App';
import { getInventoryMaterialCategories, patchInventoryMaterialCategory, postInventoryMaterialCategory } from '../http';
import Toggle from '../../../../../../../../../newcomponents/toggle/toggle';
import { loaded, loading } from '../../../../../../../../../layout/redux/AppActions';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../../newcomponents/modal/modal';
import DeleteCategoryOption from '../DeleteCategoryOption/DeleteCategoryOption';
import joinNames from '../../../../../../../../../utils/joinNames';

const strpHoldingCategory = (holdingCategory=null) => {
    return {
        id: holdingCategory?.id || null,
        name: holdingCategory?.name || '',
        active: holdingCategory?.active || true,
    }
}

const INIT_HOLDING_CATEGORY_ERR = { name: false }

const ConfirmCategoryLookalikeSavingModal = ({ similarNames=[], payload, onConfirm, onClose }) => (
    <div>
        <p>Categoria(s) semelhante(s) já cadastrada(s) com o nome <b>{payload?.name}</b>:</p>
        <b>{joinNames(similarNames)}</b>
        <p className='ConfirmCategoryLookalikeSavingModal-CallToAction'>Deseja salvar mesmo assim?</p>
        <div className='ConfirmCategoryLookalikeSavingModal-BtnBox'>
            <NewButton label='Sim, desejo salvar' onClick={onConfirm} model={ButtonModel.SECONDARY} />
            <NewButton label='Fechar' onClick={onClose} />
        </div>
    </div>
);

export default function EditCategoriesOptionsModal() {
    const dispatch = useDispatch()
    const [secondaryModal, setSecondaryModal] = useState(MODAL_INIT_PROPS)
    const [categoriesOptions, setCategoriesOptions] = useState([])
    const [holdingCategory, setHoldingCategory] = useState(strpHoldingCategory())
    const [holdingCategoryErr, setHoldingCategoryErr] = useState(INIT_HOLDING_CATEGORY_ERR)
    const { currentHealthPlaceUser } = useApp()

    useEffect(() => {
        fetchCategoriesOptions()
    }, [])

    const headDefault = [
        { "colunm": "name", "text": "Nome" },
        { "colunm": "h_active", "text": "Ativo", "width": "72px" },
        { "colunm": "h_action", "text": <div className='ListInventoryMaterials-HeaderRow-Action'>Ações</div>, width: '72px' }
    ]

    const fetchCategoriesOptions = async () => {
        try {
            let res = await getInventoryMaterialCategories()
            setCategoriesOptions(res.data.results)
        } catch (err) {
            setCategoriesOptions([])
            console.error('fetchCategoriesOptions ~ ', err)
        }
    }

    const handleSubmit = async (extraPayload={}) => {
        if (!holdingCategory.name) {
            setHoldingCategoryErr(prev => ({ ...prev, name: true }));
            return;
        }

        const payload = { ...holdingCategory, health_place: currentHealthPlaceUser?.health_place?.id, ...extraPayload };
        dispatch(loading());

        try {
            if (payload.id) {
                await patchInventoryMaterialCategory(holdingCategory.id, payload);
            } else {
                await postInventoryMaterialCategory(payload);
            }
            setHoldingCategory(strpHoldingCategory());
            setSecondaryModal(MODAL_INIT_PROPS)
            dispatch(AddAlert('Estoque de Materiais', 'Categoria salva com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            handleSaveError(err, payload);
        } finally {
            dispatch(loaded());
            await fetchCategoriesOptions()
        }
    }

    const handleToggle = async (category) => {
        try {
            await patchInventoryMaterialCategory(category.id, { "active": !category.active })
            await fetchCategoriesOptions()
        } catch (err) {
            dispatch(AddAlert('Estoque de Materiais', 'Falha ao editar categoria', AlertType.ERROR))
            console.error('handleSubmit ~ ', err)
        }
    }

    const handleDeleteHoldingCategory = (cursor) => {
        if (cursor?.active_assignments_count) {
            dispatch(AddAlert('Deleção de Categoria', 'Este item está associado a registros no estoque, portanto, pode apenas ser inativado', AlertType.INFO))
        } else {
            setSecondaryModal(prev => ({ 
                ...prev, 
                open: true, 
                title: 'Deleção de Categoria', 
                content: <DeleteCategoryOption
                    category={cursor}
                    setSecondaryModal={setSecondaryModal}
                    extraAction={async () => {
                        fetchCategoriesOptions()
                        setHoldingCategory(strpHoldingCategory())
                    }}
                /> 
            }))
        }
    }

    const handleSaveError = (err, payload) => {
        if (err?.response?.data?.error === 'A Inventory category with a similar name already exists. Please use a more distinct name.') {
            setSecondaryModal({
                open: true,
                content: <ConfirmCategoryLookalikeSavingModal
                    similarNames={err.response.data.similar_names}
                    payload={payload}
                    onConfirm={() => handleSubmit({ pass_similar_validation: true })}
                    onClose={() => setSecondaryModal(MODAL_INIT_PROPS)}
                />
            });
        } else {
            const errors = {
                'Inventory category with this name already exists for this health place.': 'Categoria com esse nome já cadastrada, revise as opções existentes.'
            }
            const errorMessage = errors?.[err?.response?.data?.error] || 'Falha ao salvar categoria.';
            dispatch(AddAlert('Estoque de Materiais', errorMessage, AlertType.ERROR));
            console.error('Error on form submit:', err);
        }
    };

    return <div className='EditCategoriesOptionsModal'>
        <Modal {...secondaryModal} dismissFn={() => setSecondaryModal(MODAL_INIT_PROPS)} />
        <div className='EditCategoriesOptionsModal-CursorContainer'>
            <div>
                <span>Categoria</span>
                <Input
                    name='name'
                    value={holdingCategory.name}
                    maxLength={100}
                    action={event => {
                        setHoldingCategory(prev => ({ ...prev, name: event.target.value}))
                        setHoldingCategoryErr(INIT_HOLDING_CATEGORY_ERR)
                    }}
                    errors={{ error: holdingCategoryErr, message: holdingCategoryErr.name }}
                />
            </div>
            <div className='EditCategoriesOptionsModal-CursorContainer-SaveBtn'>
                <NewButton
                    label='Salvar'
                    onClick={() => handleSubmit()}
                    model={holdingCategory?.id ? ButtonModel.SECONDARY : ButtonModel.PRIMARY}
                />
            </div>
        </div>
        <div className='EditCategoriesOptionsModal-Body-List'>
            <List
                head={headDefault}
                data={categoriesOptions}
                maxHeight='320px'
                listCustom={cursor => {
                    const custom = cursor;
                    custom['h_active'] = (
                        <div>
                            <Toggle 
                                id=''
                                label=''
                                value={cursor.active}
                                onChange={() => handleToggle(cursor)}
                            />
                        </div>
                    )
                    custom['h_action'] = (
                        <div className='EditCategoriesOptionsModal-ActionRow'>

                            <div 
                                title='Editar categoria'
                            	onClick={() => setHoldingCategory(strpHoldingCategory(cursor))}
                            >
                            	<IcoPencil />
                            </div>
                            <div
                                title='Excluir categoria'
                                onClick={() => handleDeleteHoldingCategory(cursor)}
                            >
                                <IcoBin />
                            </div>
                        </div>
                    )
                    return custom
                }}
            />
        </div>
    </div>
}