import './HealthProfileSection.css';
import React, { useState } from 'react';
import VitalSigns from './subcomponents/VitalSigns/VitalSigns';
import Anthropometry from './subcomponents/Anthropometry/Anthropometry';
import { useAttendance } from '../../../../consultation';


export default function HealthProfileSection() {
    const { attendance } = useAttendance()
    const [openSection, setOpenSection] = useState('vital_sign')
    
    const sections = {
      'vital_sign': <VitalSigns person={attendance?.person} attendanceID={attendance?.id} />,
      'anthropometry': <Anthropometry person={attendance?.person} attendanceID={attendance?.id} />,
    }

    return (
      <div>
          <div className='HealthProfileSection-TabManager'>
              <div 
                className={`HealthProfileSection-TabManager-btn ${openSection === 'vital_sign' ? 'active' : ''}`} 
                onClick={() => setOpenSection('vital_sign')}
              >
                  Sinais Vitais
              </div>
              <div
                className={`HealthProfileSection-TabManager-btn ${openSection === 'anthropometry' ? 'active' : ''}`}
                onClick={() => setOpenSection('anthropometry')}
              >
                  Antropometria
              </div>
          </div>
          <div>
            {sections[openSection]}
          </div>
      </div>
    )

}
