export const ADD_ALERT = 'ADD_ALERT'
const addAlert = e => ({
  type: ADD_ALERT,
  payload: e
})

export const REMOVE_ALERT = 'REMOVE_ALERT'
const removeAlert = e => ({
  type: REMOVE_ALERT,
  payload: e
})

export const AlertType = {
  SUCCESS: 0,
  ERROR: 1,
  WARNING: 2,
  INFO: 3,
}

export const AddAlert = (title='Insira um título', message = 'Insira uma mensagem', type=AlertType.INFO, h=5000) => {
  const alert = { id: Math.floor(Math.random() * 65536), title: title, message: message, type: type, time: h }

  return dispatch => {
    dispatch(addAlert(alert))
  }
}

export const RemoveAlert = e => {
  return dispatch => {
    dispatch(removeAlert(e))
  }
}
