import api from "../../../../../helpers/api"
import { getParams } from "../../../../../utils"

export async function getAppointmentServiceLocation(params={}) {
    params = getParams({ ...params, limit: 200 })
    const url = `/appointment/service_location/${params}`
    let response = api.get(url)

    return response
}

export async function postAppointmentServiceLocation(payload={}) {
    const url = '/appointment/service_location/'
    let response = api.post(url, payload)
    
    return response
}

export async function patchAppointmentServiceLocation(id, payload={}) {
    const url = `/appointment/service_location/${id}/`
    let response = api.patch(url, payload)

    return response
}

export async function deleteAppointmentServiceLocation(id) {
    const url = `appointment/service_location/${id}/`
    let response = api.delete(url)

    return response
}

export async function getAppointmentCallPanelService(params={}) {
    params = getParams({ ...params, limit: 200 })
    const url = `/appointment/call_panel_service/${params}`
    let response = api.get(url)

    return response
}

export async function postAppointmentCallPanelService(payload={}) {
    const url = '/appointment/call_panel_service/'
    let response = api.post(url, payload)
    
    return response
}

export async function patchAppointmentCallPanelService(id, payload={}) {
    const url = `/appointment/call_panel_service/${id}/`
    let response = api.patch(url, payload)

    return response
}

export async function deleteAppointmentCallPanelService(id) {
    const url = `appointment/call_panel_service/${id}/`
    let response = api.delete(url)

    return response
}