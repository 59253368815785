import React from 'react'

export default function NewTaskIcon({ style={} }) {
    return <svg style={style} viewBox="0 0 512.000000 512.000000">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M1053 5095 c-152 -41 -298 -173 -347 -313 l-15 -42 1642 0 c1020 0
            1668 -4 1710 -10 265 -39 492 -205 607 -444 81 -170 74 6 77 -1904 l3 -1703
            23 6 c115 33 247 142 305 251 66 127 63 7 60 1981 l-3 1788 -22 60 c-46 119
            -139 229 -243 284 -121 65 4 61 -1960 60 -1503 0 -1793 -3 -1837 -14z"/>
            <path d="M438 4450 c-166 -28 -299 -123 -376 -268 -66 -124 -63 -7 -60 -1979
            l3 -1788 22 -60 c46 -119 139 -229 243 -284 121 -65 -3 -61 1955 -61 1941 0
            1830 -3 1947 56 62 32 156 119 198 184 16 25 40 73 52 106 l23 59 3 1788 c3
            1967 6 1856 -58 1976 -64 120 -171 210 -299 253 l-66 23 -1770 1 c-974 1
            -1791 -2 -1817 -6z m1861 -1039 c14 -9 34 -30 45 -45 20 -27 21 -41 24 -511
            l3 -484 489 -3 489 -3 30 -29 c56 -54 64 -134 19 -191 -43 -54 -48 -55 -557
            -55 l-470 0 -3 -477 c-3 -438 -5 -481 -21 -511 -50 -91 -177 -96 -239 -10
            l-23 33 -3 483 -3 482 -470 0 c-509 0 -514 1 -557 55 -25 31 -37 84 -28 120 4
            14 22 43 41 63 l33 37 491 3 491 3 0 462 c0 273 4 477 10 498 25 90 135 133
            209 80z"/>
        </g>
    </svg>
}

