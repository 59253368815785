import './CreateSchedulesModal.css'
import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { AddAlert, AlertType, Input, InputTime, MaskDataHora, adicionaZero } from "../../../../../../../../component";
import NewButton from '../../../../../../../../newcomponents/button/button';
import { generateScheduledEvents } from './helpers';
import { createEvents } from '../../../../http';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { useBookingAppointmentCursor } from '../../BookingAppointmentCursor';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import ConflictingEventsModal from '../ConflictingEventsModal/ConflictingEventsModal';
import CalendarDateModal from '../../../../../../../../component/calendar/CalendarDateModal';

const INIT_INPUT_VALUES = {
    "start_hour": "",
    "end_hour": "",
    "duration": 50,
    "gapInterval": 10,
    "start_day": adicionaZero(String(new Date().getDate())),
    "start_month": adicionaZero(String(new Date().getMonth() + 1)),
    "start_year": String(new Date().getFullYear()),
    "end_day": adicionaZero(String(new Date().getDate())),
    "end_month": adicionaZero(String(new Date().getMonth() + 1)),
    "end_year": String(new Date().getFullYear()),
    "week_days": {
        "sun": true,
        "mon": true,
        "tue": true,
        "wed": true,
        "thu": true,
        "fri": true,
        "sat": true,
    },
    "repeat_frequency": '1'
}

export default function CreateSchedulesModal() {
    const dispatch = useDispatch()
    const {
        updateSchedule,
        health_place_user__user__person,
        setModalInfo
    } = useBookingAppointmentCursor()
    const [schedulingCursor, setSchedulingCursor] = useState(INIT_INPUT_VALUES)
    const [errors, setErrors] = useState({})
    const [secondaryModal, setSecondaryModal] = useState(MODAL_INIT_PROPS)

    const handleSelect = (event) => {
        setSchedulingCursor(prev => ({ ...prev, [event.target.id]: event.target.selected }))
    }

    const handleSelectWeekDay = (name='') => {
        setSchedulingCursor(prev => ({ ...prev, "week_days": { ...prev.week_days, [name]: !prev.week_days[name]}}))
    }

    const handleChangeHour = (event) => {
        setSchedulingCursor(prev => ({ ...prev, [event.target.name]: event.target.value}))
    }

    const handleChangeDuration = (event) => {
        setSchedulingCursor(prev => ({ ...prev, "duration": Number(event.target.value) < 1 ? 1 : Number(event.target.value) }))
    }

    const handleChangeGapInterval = (event) => {
        setSchedulingCursor(prev => ({ ...prev, gapInterval: Number(event.target.value) < 0 ? 0 : Number(event.target.value)}))
    }

    const validateForm = () => {
        const newErrors = {};
    
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set current time to midnight for accurate comparison
    
        // Create start and end dates from the form inputs
        const startDate = new Date(schedulingCursor.start_year, schedulingCursor.start_month - 1, schedulingCursor.start_day);
        const endDate = new Date(schedulingCursor.end_year, schedulingCursor.end_month - 1, schedulingCursor.end_day);
    
        // Validate that the start date is not after the end date
        if (startDate > endDate) {
            newErrors.start_date = { error: true, message: "* A data de início não pode ser posterior à data de término" };
            newErrors.start_day = { error: true, message: true };
            newErrors.start_month = { error: true, message: true };
            newErrors.start_year = { error: true, message: true };
        }
    
        // Validate that the start date is today or later
        if (startDate < currentDate) {
            newErrors.start_date = { error: true, message: "* A data de início precisa ser hoje ou uma data futura" };
            newErrors.start_day = { error: true, message: true };
            newErrors.start_month = { error: true, message: true };
            newErrors.start_year = { error: true, message: true };
        }
    
        // Validate that the end date is today or later and not before the start date
        if (endDate < currentDate) {
            newErrors.end_date = { error: true, message: "* A data de término deve ser hoje ou uma data futura" };
            newErrors.end_day = { error: true, message: true };
            newErrors.end_month = { error: true, message: true };
            newErrors.end_year = { error: true, message: true };
        } else if (endDate < startDate) {
            newErrors.end_date = { error: true, message: "* A data de término não pode ser anterior à data de início" };
            newErrors.end_day = { error: true, message: true };
            newErrors.end_month = { error: true, message: true };
            newErrors.end_year = { error: true, message: true };
        }
    
        // Validate that at least one weekday is selected
        const isWeekdaySelected = Object.values(schedulingCursor.week_days).some(day => day === true);
        if (!isWeekdaySelected) {
            newErrors.week_days = { error: true, message: "* Pelo menos um dia da semana deve ser selecionado" };
        }
    
        // Validate time inputs
        if (!schedulingCursor.start_hour) {
            newErrors.start_hour = { error: true, message: "* O horário de início é obrigatório" };
        }
        if (!schedulingCursor.end_hour) {
            newErrors.end_hour = { error: true, message: "* O horário de término é obrigatório" };
        } else if (schedulingCursor.start_hour && schedulingCursor.end_hour && schedulingCursor.start_hour >= schedulingCursor.end_hour) {
            newErrors.end_hour = { error: true, message: "* O horário de término deve ser depois do horário de início" };
        }
    
        // Validate repeat_frequency
        const repeatFrequency = Number(schedulingCursor.repeat_frequency);
        if (repeatFrequency < 1 || repeatFrequency > 48) {
            newErrors.repeat_frequency = { error: true, message: "* A frequência de repetição deve ser entre 1 e 48 semanas" };
        }
    
        // Validate duration and gapInterval
        if (schedulingCursor.duration <= 0) {
            newErrors.duration = { error: true, message: "* A duração deve ser um número positivo" };
        }
        if (schedulingCursor.gapInterval < 0) {
            newErrors.gapInterval = { error: true, message: "* O intervalo não pode ser negativo" };
        }
    
        setErrors(newErrors);
    
        // If there are no errors, return true; otherwise, return false
        return Object.keys(newErrors).length === 0;
    }

    const handleSubmit = async () => {
        if (validateForm()) {
            const eventsParams = generateScheduledEvents(schedulingCursor);
            const payload = {
                events: eventsParams
            }
            console.log("Form is valid. Submitting...", schedulingCursor);
            dispatch(loading())
            try {
                await createEvents(health_place_user__user__person, payload)
                await updateSchedule()
                setModalInfo(MODAL_INIT_PROPS)
                dispatch(AddAlert('Criar Horário(s)', 'Horário(s) criado(s) com sucesso', AlertType.SUCCESS))
            } catch (err) {
                if (err?.response?.data?.detail === 'Conflicting events detected.') {
                    setSecondaryModal(prev => ({
                        ...prev,
                        open: true,
                        title: 'Conflito de horários',
                        content: <ConflictingEventsModal
                            setModalInfo={setSecondaryModal}
                            events={err?.response?.data?.conflicts} 
                        />
                    }))
                } else {
                    dispatch(AddAlert('Criar Horário(s)', 'Falha ao criar horário(s)', AlertType.ERROR))
                }
                console.error('CreateSchedulesModal ~ handleSubmit ~ ', err)
            }
            dispatch(loaded())
        }
    }

    const openCalendarStartDateModal = () => {
        setSecondaryModal(prev => ({
            ...prev,
            open: true,
            title: 'Data de início',
            content: <CalendarDateModal
                name='start_date'
                currentDay={schedulingCursor?.start_day}
                currentMonth={schedulingCursor?.start_month}
                currentYear={schedulingCursor?.start_year}
                renderNextSelectedDay={(event) => {
                    setSchedulingCursor(prev => ({ 
                        ...prev, 
                        start_day: event?.daySearchCursor,
                        start_month: event?.monthSearchCursor,
                        start_year: event?.yearSearchCursor
                    }))
                    setSecondaryModal(MODAL_INIT_PROPS)
                }}
            />
        }))
    }

    const openCalendarEndDateModal = () => {
        setSecondaryModal(prev => ({
            ...prev,
            open: true,
            title: 'Data de término',
            content: <CalendarDateModal
                name='end_date'
                currentDay={schedulingCursor?.end_day}
                currentMonth={schedulingCursor?.end_month}
                currentYear={schedulingCursor?.end_year}
                renderNextSelectedDay={(event) => {
                    setSchedulingCursor(prev => ({ 
                        ...prev, 
                        end_day: event?.daySearchCursor,
                        end_month: event?.monthSearchCursor,
                        end_year: event?.yearSearchCursor
                    }))
                    setSecondaryModal(MODAL_INIT_PROPS)
                }}
            />
        }))
    }

    return <div className='CreateSchedulesModal'>
        <Modal {...secondaryModal} dismissFn={() => setSecondaryModal(MODAL_INIT_PROPS)} />
        <div>
            <p>Período</p>
            <div className='CreateSchedulesModal-DateGrid'>
                <div>
                    <span><b className='CreateSchedulesModal-Asterisk'>*</b> Começa em: </span>
                    <div className='CreateSchedulesModal-Input '>
                        <Input 
                            name='start_date'
                            placeholder='DD/MM/YYYY'
                            actionFocus={openCalendarStartDateModal}
                            value={MaskDataHora(`${schedulingCursor?.start_year}-${schedulingCursor?.start_month}-${schedulingCursor?.start_day}`, 'DD/MMM/AAAA', '-')?.data}
                            errors={{
                                error: { 'start_date': errors?.start_date?.error },
                                message: errors?.start_date?.message
                            }}
                        />
                    </div>
                </div>
                <div>
                    <span><b className='CreateSchedulesModal-Asterisk'>*</b> Termina em: </span>
                    <div className='CreateSchedulesModal-Input '>
                        <Input 
                            name='end_date'
                            placeholder='DD/MM/YYYY'
                            actionFocus={openCalendarEndDateModal}
                            value={MaskDataHora(`${schedulingCursor?.end_year}-${schedulingCursor?.end_month}-${schedulingCursor?.end_day}`, 'DD/MMM/AAAA', '-')?.data}
                            errors={{
                                error: { 'end_date': errors?.end_date?.error },
                                message: errors?.end_date?.message
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div className='CreateSchedulesModal-HourCalculator-InputGrid'>
                <div className='CreateSchedulesModal-Input'>
                    <span><b className='CreateSchedulesModal-Asterisk'>*</b> De:</span>
                    <InputTime
                        name='start_hour'
                        action={handleChangeHour}
                        value={schedulingCursor.start_hour}
                        errors={{
                            error: { 'start_hour': errors?.start_hour?.error },
                            message: errors?.start_hour?.message
                        }}
                    />
                </div>
                <div className='CreateSchedulesModal-Input'>
                    <span><b className='CreateSchedulesModal-Asterisk'>*</b> Até:</span>
                    <InputTime
                        name='end_hour'
                        action={handleChangeHour}
                        value={schedulingCursor.end_hour}
                        errors={{
                            error: { 'end_hour': errors?.end_hour?.error },
                            message: errors?.end_hour?.message
                        }}
                    />
                </div>
                <div className='CreateSchedulesModal-Input'>
                    <span><b className='CreateSchedulesModal-Asterisk'>*</b> Duração: (min)</span>
                    <Input
                        name='duration'
                        type='number'
                        value={schedulingCursor.duration}
                        action={handleChangeDuration} 
                        errors={{
                            error: { 'duration': errors?.duration?.error },
                            message: true
                        }}
                    />
                </div>
                <div className='CreateSchedulesModal-Input'>
                    <span><b className='CreateSchedulesModal-Asterisk'>*</b> Intervalo: (min)</span>
                    <Input
                        name='gapInterval'
                        type='number'
                        value={schedulingCursor.gapInterval}
                        action={handleChangeGapInterval}
                        errors={{
                            error: { 'gapInterval': errors?.gapInterval?.error },
                            message: true
                        }}
                    />
                </div>
            </div>
        </div>
        <div>
            <div>
                <span><b className='CreateSchedulesModal-Asterisk'>*</b> No(s) dia(s):</span>
                <div className='CreateSchedulesModal-DaysOfTheWeek'>
                    <div 
                        name='sun' 
                        className={schedulingCursor.week_days.sun ? 'active' : ''}
                        onClick={() => handleSelectWeekDay('sun')}
                    >
                        D
                    </div>
                    <div 
                        name='mon' 
                        className={schedulingCursor.week_days.mon ? 'active' : ''}
                        onClick={() => handleSelectWeekDay('mon')}
                    >
                        S
                    </div>
                    <div 
                        name='tue' 
                        className={schedulingCursor.week_days.tue ? 'active' : ''}
                        onClick={() => handleSelectWeekDay('tue')}
                    >
                        T
                    </div>
                    <div 
                        name='wed' 
                        className={schedulingCursor.week_days.wed ? 'active' : ''}
                        onClick={() => handleSelectWeekDay('wed')}
                    >
                        Q
                    </div>
                    <div 
                        name='thu' 
                        className={schedulingCursor.week_days.thu ? 'active' : ''}
                        onClick={() => handleSelectWeekDay('thu')}
                    >
                        Q
                    </div>
                    <div 
                        name='fri' 
                        className={schedulingCursor.week_days.fri ? 'active' : ''}
                        onClick={() => handleSelectWeekDay('fri')}
                    >
                        S
                    </div>
                    <div 
                        name='sat' 
                        className={schedulingCursor.week_days.sat ? 'active' : ''}
                        onClick={() => handleSelectWeekDay('sat')}
                    >
                        S
                    </div>
                </div>
                {errors?.week_days?.message ? <p className='CreateSchedulesModal-Error'>{errors?.week_days?.message}</p> : null}
            </div>

        </div>
        <div className='CreateSchedulesModal-BtnBox'>
            <NewButton 
                label='Prosseguir'
                onClick={handleSubmit}
                disabled={false}
            />
        </div>
    </div>
}
