import React from 'react'

export default function IcoPassword({ style }) {
    return <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M2420 4964 c-553 -70 -982 -418 -1149 -931 -55 -167 -63 -245 -68
            -648 l-6 -371 -67 -11 c-163 -25 -287 -127 -351 -288 l-24 -60 -3 -1030 c-2
            -738 0 -1049 9 -1098 30 -184 166 -327 347 -366 38 -8 448 -11 1455 -11 1515
            0 1454 -2 1565 51 113 55 208 189 231 326 9 49 11 360 9 1098 l-3 1030 -24 60
            c-64 161 -188 263 -351 288 l-67 11 -6 371 c-5 403 -13 481 -68 648 -111 341
            -351 624 -665 784 -114 59 -246 105 -365 128 -82 16 -329 28 -399 19z m358
            -318 c338 -72 620 -305 755 -626 68 -160 70 -177 74 -612 l4 -398 -1051 0
            -1051 0 4 398 c3 369 5 402 25 473 58 206 151 360 302 503 169 159 353 243
            615 280 56 8 250 -3 323 -18z m1212 -1955 c19 -10 44 -34 55 -52 20 -33 20
            -52 20 -1059 0 -1004 0 -1026 -20 -1058 -11 -18 -38 -41 -60 -52 -41 -20 -67
            -20 -1425 -20 -1358 0 -1384 0 -1425 20 -22 11 -49 34 -60 52 -20 32 -20 54
            -20 1058 0 1013 0 1025 20 1060 12 19 36 43 55 52 33 17 122 18 1430 18 1342
            0 1397 -1 1430 -19z"/>
            <path d="M2440 2242 c-36 -9 -85 -29 -110 -44 -198 -116 -276 -373 -178 -581
            43 -90 115 -165 197 -204 l60 -28 1 -185 c0 -156 3 -191 18 -220 49 -99 215
            -99 264 0 15 29 18 64 18 220 l1 185 60 28 c131 62 226 205 236 354 16 233
            -121 426 -338 478 -82 19 -148 18 -229 -3z m212 -313 c83 -59 72 -211 -18
            -254 -41 -19 -107 -19 -148 0 -84 40 -101 184 -28 248 46 42 140 44 194 6z"/>
        </g>
    </svg>
}

