import './MarkTimeAsAvailable.css'
import React from "react";
import { useDispatch } from 'react-redux';
import { patchAvailableEvents } from "../../../../../../http";
import { useBookingAppointmentCursor } from "../../../../BookingAppointmentCursor";
import { AddAlert, AlertType, MaskDataHora } from "../../../../../../../../../../component";
import NewButton, { ButtonModel } from "../../../../../../../../../../newcomponents/button/button";
import { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal';
import { loaded, loading } from '../../../../../../../../../../layout/redux/AppActions';

export default function MarkTimeAsAvailable({ appointments=[] }) {
    const dispatch = useDispatch()
    const {
        updateSchedule,
        health_place_user__user__person, 
        setModalInfo,
        setSelectedTimesCursor
    } = useBookingAppointmentCursor()

    const handleSubmit = async () => {
        dispatch(loading())
        try {
            let payload = {
                'event_ids': appointments.map(({pk}) => pk),
            }
            await patchAvailableEvents(health_place_user__user__person, payload)
            await updateSchedule()
            setSelectedTimesCursor([])
            dispatch(AddAlert('Agendamento', 'Horário(s) disponibilizado(s) com sucesso!', AlertType.SUCCESS))
            setModalInfo(MODAL_INIT_PROPS)
        } catch (err) {
            dispatch(AddAlert('Agendamento', 'Falha ao disponibilizar horário(s) de atendimento(s)', AlertType.ERROR))
            console.error('MarkTimeAsAvailable ~ handleSubmit ~ ', err)
        }
        dispatch(loaded())
    }

    return <div className="MarkTimeAsAvailable">
        <p>Tem certeza que deseja mover os atendimentos selecionados para <b>disponível</b>?</p>
        <div className='MarkTimeAsAvailable-TimeRow'>
        {
            appointments.map(appointment => {
                const startHourTime = MaskDataHora(appointment?.fields?.start || appointment?.start, 'DD/MMM/AAAA', '-');
                const endHourTime = MaskDataHora(appointment?.fields?.end || appointment?.end, 'DD/MMM/AAAA', '-');
                return <div>
                    <b>{startHourTime.data} | {startHourTime?.hora?.slice(0, 5) || '-'} - {endHourTime?.hora?.slice(0, 5) || '-'}</b>
                </div>
            })
        }
        </div>

        <div className='MarkTimeAsAvailable-BtnBox'>
            <NewButton 
                label='Cancelar'
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
                model={ButtonModel.SECONDARY}
            />
            <NewButton 
                label='Salvar'
                onClick={handleSubmit}
            />
        </div>
    </div>
}