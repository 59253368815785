import './RecoverPassword.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Input, AddAlert, AlertType } from '../../component'
import { history } from '../../helpers'
import { validarCampo } from '../../component/validation/Validation'
import Button, { ButtonModel } from '../../newcomponents/button/button'
import { useParams } from 'react-router-dom'
import PasswordData from '../configuration/subcomponents/HealthProfessionalConfig/PasswordData/PasswordData'
import { loaded, loading } from '../../layout/redux/AppActions'
import api from '../../helpers/api'

export default function RecoverPasswordPage() {
	const { idRecuperar } = useParams()

	return idRecuperar ? <ChangePassword /> : <RequestRecoverPassword />
}

function ChangePassword() {
	return (
		<div className='RecoverPassword'>
			<section>
				<h3>Salutho</h3>
				<div className='RecoverPassword-Headline'>
					<h4>Alterar senha</h4>
					<span>Preencha os campos abaixo para atualizar sua senha</span>
				</div>
				<PasswordData />
			</section>
		</div>
	)
}

function RequestRecoverPassword() {
	const dispatch = useDispatch()
	const [inputState, setInputState] = useState({ email: '' })
	const [valid, setValid] = useState({})
	const [hasSubmitted, setHasSubmitted] = useState(false)

	const handleChange = (e) => {
		if (hasSubmitted){
			const inputValueValidator = {
				target: {
					name: e.target.name,
					pattern: 'email',
					value: e.target.value
				}
			}
			const error = validateAnswers(inputValueValidator)
			setValid(error)
		}
		setInputState({ ...inputState, [e.target.name]: e.target.value })
	}

	const recuperarSenha = async ({ email }) => {

		dispatch(loading())
		try {
			await api.post(`auth/password/reset/`, {'email': email})
			setTimeout(() => {
			  dispatch([
				AddAlert('Recuperar senha', `Enviaremos as instruções ao e-mail ${email}`, AlertType.SUCCESS), 
				loaded()
			  ])
			}, 100)
			history.push('/login')
		} catch (error) {
			dispatch(loaded());
			if (error.response && error.response.data && error.response.data.email) {
			  dispatch(AddAlert('Recuperar senha', error.response.data.email[0], AlertType.ERROR));
			} else {
			  dispatch(AddAlert('Recuperar senha', 'Houve um erro ao recuperar a senha.', AlertType.ERROR));
			}
			console.error(error, 'Erro ao recuperar a senha');
		}
	}

	const enviarEmail = () => {
		setHasSubmitted(true)
		const inputValueValidator = {
			target: {
				name: 'email',
				pattern: 'email',
				value: inputState.email
			}
		}
		const error = validateAnswers(inputValueValidator)
		setValid(error)
		
		if (!error.email) {
			recuperarSenha({ email: inputState.email, history })
		}
	}

	const validateAnswers =(inputValueValidator) => {
		const val = validarCampo(inputValueValidator)

		switch (val.mensagem){
			case 'regex':
				return {email: true, mensagem: 'Email inválido.'}
				break
			case 'vazio':
				return {email: true, mensagem: 'Campo obrigatório.'}
				break
			case false:
				return {email: false, mensagem: false}
				break
		}
	}

	return (
		<div className='RecoverPassword'>
			<section>
				<h3>Salutho</h3>
				<div className='RecoverPassword-Headline'>
					<h4>Problemas para fazer login?</h4>
					<span>
						Digite seu e-mail e enviaremos um link para você voltar à sua conta.
					</span>
				</div>
				<Input
					label='E-mail'
					name='email'
					action={handleChange}
					value={inputState.email}
					errors={{
						error: {email: valid?.mensagem},
						message: valid.mensagem,
					  }}      
				/>
				<div className='RecoverPassword-BtnBox'>
					<Button
						label='Recuperar'
						onClick={() => enviarEmail()}
					/>
					<Button
						label='Cancelar'
						onClick={() => history.push('/login')}
						model={ButtonModel.SECONDARY}
					/>
				</div>
			</section>
		</div>
	)
}
