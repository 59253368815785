import './NewEditHoldingTemplate.css'
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import ArrowDownIco from '../../../../../../../../component/icon/arrow_down';
import { AddAlert, AlertType, Input } from '../../../../../../../../component';
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { putSaveTemplateType } from '../../../../../../http';
import NewMultiSelect from '../../../../../../../../newcomponents/form/select/multiselect';
import NewSelect from '../../../../../../../../newcomponents/form/select/select';
import { uniqueIDs } from '../../../../../../../../utils/uniqueIDs';
import { getSpecialties } from '../../../../../../../agenda/SelectScheduleHealthPlaceUserManager/http';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import DeleteHoldingTemplate from '../DeleteHoldingTemplate/DeleteHoldingTemplate';

const ACTIVE_FIELD_OPTIONS = [
    { id: 'main_complaint', name: 'Queixa Principal', component: 'text' },
    { id: 'history_of_current_illness', name: 'História da doença atual (HDA)', component: 'text'  },
    { id: 'past_pathological_history', name: 'História patológica pregressa (HPP)', component: 'text' },
    { id: 'physical_exam', name: 'Exame Físico', component: 'text' },
    { id: 'diagnosis', name: 'Hipóteses diagnósticas', component: 'text' },
    { id: 'conduct', name: 'Conduta', component: 'text' },
    { id: 'reason_for_consultation', name: 'Motivo de consulta (CIAP-2)', component: 'select' },
    { id: 'intervention_clinical_procedures', name: 'Intervenção e/ou procedimentos clínicos (CIAP-2)', component: 'select' },
    { id: 'related_diseases_and_problems', name: 'Doenças e Problemas Relacionados (CID-10)', component: 'select' },
    { id: 'interrogatory_about_various_devices', name: 'Interrogatório sobre diversos aparelhos (IDA)', component: 'text' },
    { id: 'physiological_history', name: 'História fisiológica', component: 'text' },
    { id: 'vaccination_history', name: 'História vacinal', component: 'text' },
    { id: 'assessment_by_specialists_and_examinations_carried_out', name: 'Avaliação por especialistas e exames realizados', component: 'text' },
    { id: 'family_history', name: 'História Patológica Familiar', component: 'text' },
    { id: 'lifestyle_habits', name: 'Hábitos de vida', component: 'text' },
    { id: 'social_history', name: 'História social', component: 'text' },
    { id: 'impression', name: 'Impressão', component: 'text' },
    { id: 'nursing_planning', name: 'Planejamento de enfermagem', component: 'text' },
    { id: 'nursing_planning_evolution', name: 'Evolução do planejamento de enfermagem', component: 'text' },
    { id: 'evolution', name: 'Evolução', component: 'text' },
    { id: 'pertinent_observations', name: 'Observações pertinentes', component: 'text' },
    { id: 'medications_for_continuous_use', name: 'Medicamentos de uso contínuo', component: 'text' },
]

const strpTemplate = (holdingTemplate) => {

    return {
        "id": holdingTemplate?.id || null,
        "name": holdingTemplate?.name || '',
        "specialty": holdingTemplate?.specialty || '',
        "health_place": holdingTemplate?.health_place,
        "subjective": holdingTemplate?.subjective || [],
        "objective": holdingTemplate?.objective || [],
        "assessment": holdingTemplate?.assessment || [],
        "plan": holdingTemplate?.plan || [],
    }
}

const parseTemplate = (holdingTemplate) => {
    return {
        "id": holdingTemplate?.id || null,
        "name": holdingTemplate?.name || '',
        "specialty_id": holdingTemplate?.specialty?.id || null,
        "health_place": holdingTemplate?.health_place,
        "subjective": holdingTemplate?.subjective || [],
        "objective": holdingTemplate?.objective || [],
        "assessment": holdingTemplate?.assessment || [],
        "plan": holdingTemplate?.plan || [],
        "can_edit": holdingTemplate?.can_edit || true,
    }
}

export default function NewEditHoldingTemplate({ initHoldingTemplate={}, extraAction=async () => null }) {
    const dispatch = useDispatch()
    const searchTimeout = useRef(null)
    const [selectedOpenSection, setSelectedOpenSection] = useState('')
    const [holdingTemplate, setHoldingTemplate] = useState(strpTemplate(initHoldingTemplate))
    const [specialtiesOptions, setSpecialtiesOptions] = useState([])
    const [modalInfoV3, setModalInfoV3] = useState(MODAL_INIT_PROPS)

    useEffect(() => {
        fetchSpecialtiesOptions({ offset: 0 })
    }, [])

    const fetchSpecialtiesOptions = async (params={}) => {
        params = { ...params, limit: 50 }

        try {
            let res = await getSpecialties(params)

            setSpecialtiesOptions(res.data.results)
        } catch(err) {
            console.error('fetchSpecialtiesOptions ~ ', err)
        }
    }

    const toggleSection = (section) => {
        setSelectedOpenSection(section === selectedOpenSection ? '' : section)
    }

    const toggleOption = (loadKey, optionsList, fieldNameRef, optionRef) => {
		const selectedOption = optionsList.find(option => option[fieldNameRef] === optionRef);
		const isExisting = holdingTemplate?.[loadKey].find(option => option[fieldNameRef] === optionRef);
		
		let nextState = holdingTemplate
		if (isExisting) {
			nextState = { ...holdingTemplate, [loadKey]: holdingTemplate[loadKey].filter(option => option[fieldNameRef] !== optionRef) }
		} else {
			nextState = { ...holdingTemplate, [loadKey]: [...holdingTemplate[loadKey], selectedOption] }
		}
		setHoldingTemplate(nextState)
	};

    const isValidHoldingTemplate = () => {
        const isThereSomeChecked = holdingTemplate?.subjective?.length
            || holdingTemplate?.objective?.length
            || holdingTemplate?.assessment?.length
            || holdingTemplate?.plan?.length

        return holdingTemplate?.name && isThereSomeChecked && holdingTemplate?.specialty?.id
    }

    const handleSubmit = async () => {
        let payload = parseTemplate(holdingTemplate)
        
        dispatch(loading())
        try {
            await putSaveTemplateType(payload)
            await extraAction()
            dispatch(AddAlert('Template de Prontuário', 'Template de Prontuário salvo com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            dispatch(AddAlert('Template de Prontuário', 'Falha ao salvar template de prontuário', AlertType.ERROR))
        }
        dispatch(loaded())
    }

    const handleOpenDeleteHoldingTemplate = () => {
        const extraActionDeleteHoldingTemplate = async () => {
            await extraAction()
            setModalInfoV3(MODAL_INIT_PROPS)
        }

        setModalInfoV3(prev => ({
            ...prev,
            open: true,
            title: 'Exclusão de Template',
            content: <DeleteHoldingTemplate 
                holdingTemplate={holdingTemplate}
                extraActionDeleteHoldingTemplate={extraActionDeleteHoldingTemplate}
                setSecondaryModal={setModalInfoV3}
            />
        }))
    }

    const handleSearchSpecialties = (event) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)

        searchTimeout.current = setTimeout(() => {
            fetchSpecialtiesOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }

    return <div className="NewEditHoldingTemplate">
        <Modal {...modalInfoV3} dismissFn={() => setModalInfoV3(MODAL_INIT_PROPS)} />
        <div className='NewEditHoldingTemplate-Body'>
            <div>
                <div className='NewEditHoldingTemplate-Input'>
                    <Input
                        label='Nome *'
                        value={holdingTemplate.name}
                        action={event => setHoldingTemplate(prev => ({ ...prev, 'name': event.target.value }))}
                    />
                </div>
                <div className='NewEditHoldingTemplate-Select'>
                    <NewSelect
                        label='Especialidade *'
                        onSelect={event => {
                            setHoldingTemplate(prev => ({ 
                                ...prev, 
                                "specialty": prev?.specialty?.id != event.target.selected 
                                    ? specialtiesOptions?.find(({ id }) => id === event.target.selected)
                                    : ''
                            }))
                            fetchSpecialtiesOptions({ offset: 0 })
                        }}     
                        canBeEmpty={false}
                        options={uniqueIDs(holdingTemplate?.specialty, specialtiesOptions)}
                        optionRefKey='id'
                        optionStrKey='strf'
                        selected={holdingTemplate?.specialty?.id || ''}
                        filterNode={<div className='NewEditHoldingTemplate-NewSelect'>
                            <Input 
                                placeholder='Pesquisar por nome'
                                action={handleSearchSpecialties}
                                actionFocus={()=> fetchSpecialtiesOptions()}
                            />
                        </div>
                        }
                    />
                </div>
            </div>
            <div>
                <div >
                    <div 
                        className={`NewEditHoldingTemplate-AccordionNav ${selectedOpenSection === 'subjective' ? 'open' : ''}`} 
                        onClick={() => toggleSection('subjective')}
                    >
                        Subjetivo <ArrowDownIco />
                    </div>
                    {
                        selectedOpenSection === 'subjective' 
                        ? <div>
                            <div className={`NewEditHoldingTemplate-AccordionNav-Section ${selectedOpenSection === 'subjective' ? 'open' : ''}`}>
                                <div className='NewEditHoldingTemplate-Select'>
                                    <strong>Campos habilitados</strong>
                                    <NewMultiSelect
                                        id='subjective'
                                        options={ACTIVE_FIELD_OPTIONS}
                                        selectedOptions={holdingTemplate.subjective.map(({id}) => id)}
                                        onSelect={({ optionRef }) => {
                                            toggleOption('subjective', ACTIVE_FIELD_OPTIONS, 'id', optionRef)
                                        }}
                                        onlyDefaultText
                                    />
                                    <div className='NewEditHoldingTemplate-Select-SelectedOptionsRow'>
                                        {
                                            holdingTemplate.subjective.map(({id, name}) => <div>
                                                <div className='NewEditHoldingTemplate-Select-SelectedOptionsRow-Name'>{name}</div>
                                                <div 
                                                    className='NewEditHoldingTemplate-Select-SelectedOptionsRow-CloseBox'
                                                    onClick={() => toggleOption('subjective', ACTIVE_FIELD_OPTIONS, 'id', id)}
                                                >
                                                    x
                                                </div>
                                            </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </div>
                <div >
                    <div 
                        className={`NewEditHoldingTemplate-AccordionNav ${selectedOpenSection === 'objective' ? 'open' : ''}`} 
                        onClick={() => toggleSection('objective')}
                    >
                        Objetivo <ArrowDownIco />
                    </div>
                    {
                        selectedOpenSection === 'objective' 
                        ? <div>
                            <div className={`NewEditHoldingTemplate-AccordionNav-Section ${selectedOpenSection === 'objective' ? 'open' : ''}`}>
                                <div className='NewEditHoldingTemplate-Select'>
                                    <strong>Campos habilitados</strong>
                                    <NewMultiSelect
                                        id='objective'
                                        options={ACTIVE_FIELD_OPTIONS}
                                        selectedOptions={holdingTemplate.objective.map(({id}) => id)}
                                        onSelect={({ optionRef }) => {
                                            toggleOption('objective', ACTIVE_FIELD_OPTIONS, 'id', optionRef)
                                        }}
                                        onlyDefaultText
                                    />
                                    <div className='NewEditHoldingTemplate-Select-SelectedOptionsRow'>
                                        {
                                            holdingTemplate.objective.map(({id, name}) => <div>
                                                <div className='NewEditHoldingTemplate-Select-SelectedOptionsRow-Name'>{name}</div>
                                                <div 
                                                    className='NewEditHoldingTemplate-Select-SelectedOptionsRow-CloseBox'
                                                    onClick={() => toggleOption('objective', ACTIVE_FIELD_OPTIONS, 'id', id)}
                                                >
                                                    x
                                                </div>
                                            </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </div>
                <div >
                    <div 
                        className={`NewEditHoldingTemplate-AccordionNav ${selectedOpenSection === 'assessment' ? 'open' : ''}`} 
                        onClick={() => toggleSection('assessment')}
                    >
                        Avaliação <ArrowDownIco />
                    </div>
                    {
                        selectedOpenSection === 'assessment' 
                        ? <div>
                            <div className={`NewEditHoldingTemplate-AccordionNav-Section ${selectedOpenSection === 'assessment' ? 'open' : ''}`}>
                                <div className='NewEditHoldingTemplate-Select'>
                                    <strong>Campos habilitados</strong>
                                    <NewMultiSelect
                                        id='assessment'
                                        options={ACTIVE_FIELD_OPTIONS}
                                        selectedOptions={holdingTemplate.assessment.map(({id}) => id)}
                                        onSelect={({ optionRef }) => {
                                            toggleOption('assessment', ACTIVE_FIELD_OPTIONS, 'id', optionRef)
                                        }}
                                        onlyDefaultText
                                    />
                                    <div className='NewEditHoldingTemplate-Select-SelectedOptionsRow'>
                                        {
                                            holdingTemplate.assessment.map(({id, name}) => <div>
                                                <div className='NewEditHoldingTemplate-Select-SelectedOptionsRow-Name'>{name}</div>
                                                <div 
                                                    className='NewEditHoldingTemplate-Select-SelectedOptionsRow-CloseBox'
                                                    onClick={() => toggleOption('assessment', ACTIVE_FIELD_OPTIONS, 'id', id)}
                                                >
                                                    x
                                                </div>
                                            </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </div>
                <div >
                    <div 
                        className={`NewEditHoldingTemplate-AccordionNav ${selectedOpenSection === 'plan' ? 'open' : ''}`} 
                        onClick={() => toggleSection('plan')}
                    >
                        Plano <ArrowDownIco />
                    </div>
                    {
                        selectedOpenSection === 'plan' 
                        ? <div>
                            <div className={`NewEditHoldingTemplate-AccordionNav-Section ${selectedOpenSection === 'plan' ? 'open' : ''}`}>
                                <div className='NewEditHoldingTemplate-Select'>
                                    <strong>Campos habilitados</strong>
                                    <NewMultiSelect
                                        id='plan'
                                        options={ACTIVE_FIELD_OPTIONS}
                                        selectedOptions={holdingTemplate.plan.map(({id}) => id)}
                                        onSelect={({ optionRef }) => {
                                            toggleOption('plan', ACTIVE_FIELD_OPTIONS, 'id', optionRef)
                                        }}
                                        onlyDefaultText
                                    />
                                    <div className='NewEditHoldingTemplate-Select-SelectedOptionsRow'>
                                        {
                                            holdingTemplate.plan.map(({id, name}) => <div>
                                                <div className='NewEditHoldingTemplate-Select-SelectedOptionsRow-Name'>{name}</div>
                                                <div 
                                                    className='NewEditHoldingTemplate-Select-SelectedOptionsRow-CloseBox'
                                                    onClick={() => toggleOption('plan', ACTIVE_FIELD_OPTIONS, 'id', id)}
                                                >
                                                    x
                                                </div>
                                            </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>
        </div>

        <div className='NewEditHoldingTemplate-BtnBox'>
            {
                holdingTemplate?.id
                ? <NewButton 
                    label='Excluir Template'
                    model={ButtonModel.WARNING_OUTLINED}
                    onClick={handleOpenDeleteHoldingTemplate}
                />
                : null
            }
            <NewButton
                label='Salvar Template'
                disabled={!isValidHoldingTemplate()}
                onClick={handleSubmit}
            />
        </div>
    </div>
}

