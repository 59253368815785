import './StudentsManager.css'
import React from 'react'
import { List } from '../../../../../component'
import NewButton, { ButtonModel } from '../../../../../newcomponents/button/button'
import AddStudent from './subcomponents/AddStudent/AddStudent'
import RemoveStudent from './subcomponents/RemoveStudent/RemoveStudent'

const { headDefault } = require('./StudentsManager.json')


export default function StudentsManager({ associatedStudents=[], setModalInfo=() => null, fetchAssociatedStudents=() => null }) {
    
    const handleRemoveAssociatedEdgeModal = (associatedStudentEdge) => {
        setModalInfo({ 
            open: true,
            content: <RemoveStudent 
                associatedStudentEdge={associatedStudentEdge}
                setModalInfo={setModalInfo}
                fetchAssociatedStudents={fetchAssociatedStudents}
            />,
            title: 'Remover aluno'
        })
    }

    return <div>
        <div className='StudentsManager-Action'>
            <NewButton 
                model={ButtonModel.PRIMARY}
                label='Adicionar aluno'
                onClick={() => setModalInfo({ 
                    open: true, 
                    title: 'Adicionar estudante', 
                    content: <AddStudent 
                        setModalInfo={setModalInfo}
                        fetchAssociatedStudents={fetchAssociatedStudents}
                    /> 
                })}
            />
        </div>
        <div>
            <List
                head={headDefault}
                data={associatedStudents}
                noData='Ainda não há alunos'
                listCustom={custom => {
                    custom['h_student'] = <p>{custom?.student_health_place_user?.user?.person?.name || `${custom?.student_health_place_user?.user?.email} (cadastro incompleto)` || '-'}</p>
                    custom['h_action'] = <div onClick={() => handleRemoveAssociatedEdgeModal(custom)}>
                        <NewButton 
                            label='Remover'
                            model={ButtonModel.SECONDARY}
                        />
                    </div>
                    return custom
                }}
            />
        </div>
        
    </div>
}