import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export function TextareaEditor({
  children, 
  action, 
  value = '', 
  label, 
  name, 
  color = '',
  required = {},
  maxLength = '1000' 
}) {
  const require = Object.keys(required)
  return (
    <div className={`form-box ${color} ${require.length && (required.erro[name]?'erro':'')} `}>
      <label className='label-input' htmlFor={`id-${name}`}>{require.length?<span>*</span>:''} {label}</label>
      <div>
        {/* <CKEditor
          editor={ ClassicEditor }
          data={value}
          config={editorConfiguration}
          onReady={ editor => {
              // You can store the "editor" and use when it is needed.
              // console.log( 'Editor is ready to use!', editor );
          } }
          onChange={ ( event, editor ) => {
              const data = editor.getData();
              // console.log( { event, editor, data } );
              action({target: {name, event, editor, value: data}})
          } }
          onBlur={ ( event, editor ) => {
            const data = editor.getData();
              // console.log( 'Blur.', editor );
              action({target: {name, event, editor, value: data}})
          } }
          onFocus={ ( event, editor ) => {
            const data = editor.getData();
              // console.log( 'Focus.', editor );
              action({target: {name, event, editor, value: data}})
          } }
        /> */}
        <ReactQuill
          value={value}
          onChange={(content, _, source, editor) => {

                if (source === 'api') return

                action({target: {name:name, value: content}})

            }
          }></ReactQuill>
      </div>
        {
          required.erro?.[name] ?
            <span className='campo-obrigatorio'>{required.message}</span>
          :null
        }
    </div>
  )
}
