import React from 'react'

export default function ChartIcon({ style }) {
  return (
    <svg style={style} viewBox="0 0 512.000000 512.000000">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M420 4789 c-14 -5 -36 -20 -48 -32 -55 -51 -52 95 -52 -2200 l0
            -2124 23 -34 c12 -18 38 -44 56 -56 l34 -23 2127 0 2127 0 34 23 c104 70 106
            197 6 271 l-28 21 -2030 3 -2029 2 0 2013 0 2013 -22 44 c-13 24 -35 53 -50
            63 -31 23 -112 32 -148 16z"/>
            <path d="M4580 3749 c-14 -5 -401 -386 -860 -845 l-836 -835 -354 390 c-385
            424 -391 429 -478 416 -30 -4 -56 -18 -85 -43 -270 -240 -985 -905 -995 -926
            -59 -129 80 -269 211 -212 18 8 183 153 368 323 185 169 377 345 427 391 l91
            83 351 -386 c194 -213 366 -395 384 -406 37 -22 94 -25 139 -5 19 8 408 390
            934 917 790 792 903 909 914 947 36 123 -90 238 -211 191z"/>
        </g>
    </svg>
  )
}

