export default function getCurrentDate() {
	let currentDate = new Date()
	
    let day = currentDate.getDate().toString()
	day = day.length === 1 ? '0' + day : day

	let month = (currentDate.getMonth() + 1).toString()
	month = month.length === 1 ? '0' + month : month

	let year = currentDate.getFullYear().toString()

	return { day, month, year }
}