import { ADD_ALERT, REMOVE_ALERT } from './actions'

export const alertsInitState = []

export default function alertsState(state = alertsInitState, { type, payload }) {
  switch (type) {
    case ADD_ALERT:
      return [...state, payload]
    case REMOVE_ALERT:
      return state.filter(alert => {
        return alert.id !== payload
      })

    default:
      return state
  }
}
