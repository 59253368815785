import './ActiveLabour.css'
import React from 'react'
import { Line } from 'react-chartjs-2'
import {
	Chart,
	LinearScale,
	CategoryScale,
	LineController,
	PointElement,
	LineElement,
	Title,
	plugins,
} from 'chart.js'

Chart.register(
	LinearScale,
	CategoryScale,
	LineController,
	PointElement,
	LineElement,
	Title
)

const HeartRateChart = ({ partograph }) => {
	const data = {
		labels: Array.from({ length: 12 }, (_, i) => (i + 1).toString()),
		datasets: [
			{
				label: 'Batimentos cardíacos',
				align: 'center',
				data: Array.from({ length: 12 }, (_, i) => {
					const key = `fetal_beat_rate_${i + 1}`
					const value = partograph[key]
					const numericValue = parseInt(value, 10)
					console.log(`numericValue: ${numericValue}`)
					return numericValue
				}),
				fill: false,
				borderColor: 'navy',
				borderWidth: 2,
				pointRadius: 0,
				tension: 0.1,
			},
		],
	}

	const options = {
		legend: {
			display: false,
		},
		plugins: {
			title: {
				display: true,
				text: 'Batimentos Cardíacos',
				font: {
					size: 18,
				},
				padding: {
					bottom: 20,
				},
			},
		},
		scales: {
			x: {
				type: 'linear',
				position: 'bottom',
				min: 1,
				max: 12,
				ticks: {
					stepSize: 1,
					callback: function (value, index, values) {
						return value + 'h'
					},
				},
				title: {
					display: true,
					text: 'Tempo',
					align: 'center',
					padding: { top: 20 },
				},
			},
			y: {
				type: 'linear',
				position: 'left',
				min: 80,
				max: 200,
				ticks: {
					stepSize: 10,
				},
				title: {
					display: true,
					text: 'Batimentos cardíacos',
					align: 'center',
					padding: { right: 20 },
				},
				elements: {
					line: {
						backgroundColor: 'rgba(0, 0, 0, 0)',
					},
				},
				grid: {
					color: context => {
						const value = context.tick.value
						if (value === 100 || value === 180) {
							return 'darkblue'
						}
						return 'lightblue'
					},
					borderColor: 'transparent',
				},
			},
		},
	}

	return (
		<div style={{ position: 'relative', width: '600px', height: '300px' }}>
			<Line
				data={data}
				options={options}
			/>
		</div>
	)
}

export default HeartRateChart
