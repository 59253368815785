import './Collaborators.css'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Button,
	IcoBin,
	IcoPencil,
	List,
	Paginate,
	Input,
	Select,
} from '../../component'
import Modal, { MODAL_INIT_PROPS } from '../../newcomponents/modal/modal'
import NewButton, { ButtonModel } from '../../newcomponents/button/button'
import SaveContributorModal from './modals/SaveContributorModal/SaveContributorModal'
import RemoveContributorModal from './modals/RemoveContributorModal/RemoveContributorModal'
import IcoTeam from '../../component/icon/team'
import ManageTeamEdgesModal from './modals/ManageTeamEdgesModal/ManageTeamEdgesModal'
import { getCollaborators } from './http'
import { loaded, loading } from '../../layout/redux/AppActions'
import { perfilUser } from '../../utils/userRoles'
import { useApp } from '../../layout/App'

const CollaboratorsContext = React.createContext()

const initFilters = {
	offset: 0,
	name_cpf_or_email: '',
	profile: { name: '', id: '' },
}

export const useCollaborators = () => {
	return useContext(CollaboratorsContext)
}

export default function Collaborators({ healthPlace = null }) {
	const isCollaboratorsModal = !!healthPlace
	const dispatch = useDispatch()

	const { currentHealthPlaceUser } = useApp()
	const [pagination, setPagination] = useState({
		pageNumber: 0,
		totalPages: 1,
		totalElements: 0,
	})
	const [collaborators, setCollaborators] = useState([])
	const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)

	const filtersRef = useRef(initFilters)
	const searchTimeOut = useRef(null)

	const fetchCollaborators = async params => {
		dispatch(loading())

		await getCollaborators({
			...params,
			health_place__id: healthPlace?.id || currentHealthPlaceUser?.health_place?.id,
			limit: 20,
		})
			.then(res => {
				dispatch(loaded())
				setCollaborators(res.data.results)
				setPagination({
					totalElements: res.data.count,
					totalPages: Math.ceil(res.data.count / 20),
					pageNumber: params.offset / 20,
					next: res.data.next,
					previous: res.data.previous,
				})
			})
			.catch(err => {
				dispatch(loaded())
				console.error('fetchCollaborators', err)
				setCollaborators([])
			})
	}

	useEffect(() => {
		if (currentHealthPlaceUser?.health_place?.id) {
			fetchCollaborators({ offset: 0 })
		}
	}, [currentHealthPlaceUser?.health_place?.id])

	const handlePagination = e => {
		fetchCollaborators({ offset: e * 20 })
	}

	const buildParams = () => {
		return {
			active: true,
			offset: filtersRef.current.offset,
			limit: 20,
			name_cpf_or_email: filtersRef.current.name_cpf_or_email,
			profile: filtersRef.current.profile.id,
		}
	}

	const handleSearch = target => {
		filtersRef.current = {
			...filtersRef.current,
			[target.name]: target.value,
		}
		const params = buildParams()
		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(async () => {
				await fetchCollaborators(params)
			}, 300)
		} catch (err) {
			console.error('handleSearch', err)
		}
	}

	const headDefault = [
		{ colunm: 'h_user', text: 'Profissional' },
		{ colunm: 'h_register_status', text: 'Cadastro' },
		{ colunm: 'h_perfisList', text: 'Perfil' },
		{ colunm: 'h_thousand_days', text: '1000 dias' },
		{ colunm: 'h_enabled_preceptorship', text: 'Preceptor' },
		{ colunm: 'h_action', text: '', width: '140px' },
	]

	const PROFILE_CHOICES = [
		{ id: 'DOCTOR', name: 'Médico' },
		{ id: 'DENTIST', name: 'Dentista' },
		{ id: 'NURSE', name: 'Enfermeiro' },
		{ id: 'PSYCHOLOGIST', name: 'Psicólogo' },
		{ id: 'NUTRITIONIST', name: 'Nutricionista' },
		{ id: 'ASSISTANT', name: 'Assistente' },
	]

	return (
		<CollaboratorsContext.Provider
			value={{
				fetchCollaborators,
				setModalInfo,
			}}>
			<Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
			<strong>Colaborador</strong>
			<div className='Collaborator-Content'>
				<section className='Collaborator-Content-SideBarLeft'>
					<div className='Collaborator-BtnFullWidth'>
						{healthPlace ? (
							<p>
								<strong>{healthPlace.name}</strong>
							</p>
						) : null}
						<p></p>
						<NewButton
							model={ButtonModel.PRIMARY}
							label='Novo colaborador'
							onClick={() =>
								setModalInfo({
									open: true,
									title: 'Novo colaborador',
									content: <SaveContributorModal healthPlace={healthPlace} />,
								})
							}
						/>
					</div>
					<div className='PatientsFilter-Separator' />
					<div className='Collaborator-Content-SideBarLeft-BtnBox'>
						<Input
							label='Colaborador'
							name='name_cpf_or_email'
							action={event => handleSearch(event.target)}
							autoComplete='false'
							placeholder='Filtrar por Nome | CPF | E-mail'
						/>
						<Select
							label='Perfil'
							name='profile'
							action={event => handleSearch(event.target)}
							optionLabel='name'
							optionValue='name'
							options={PROFILE_CHOICES}
							selected={filtersRef.current.profile || ''}
						/>
					</div>
				</section>
				<div className='Collaborator-Separator' />
				<div className='Collaborator-Body'>
					<div className='Collaborator-List'>
						<List
							head={headDefault}
							data={collaborators}
							maxHeight={isCollaboratorsModal ? 'calc(100vh - 300px)' : 'calc(100vh - 260px)'}
							listCustom={e => {
								const custom = e
								const perfil = perfilUser(e.profile)

								custom['h_user'] = e?.user?.person?.name
									? `${e?.user?.person?.name}`
									: `${e?.user?.email}`
								custom['h_register_status'] = !e?.user?.person?.name
									? 'Incompleto'
									: 'Ok'
								custom['h_perfisList'] = perfil?.title
								custom['h_thousand_days'] = e?.enabled_thousand_days ? 'Sim' : 'Não'
								
								custom['h_enabled_preceptorship'] = e?.enabled_preceptorship ? 'Sim' : 'Não'
								custom['h_action'] = (
									<div className='Collaborators-ActionRow'>
										{e.profile === 'DOCTOR' ||
										e.profile === 'DENTIST' ||
										e.profile === 'PSYCHOLOGIST' ||
										e.profile === 'NUTRITIONIST' ? (
											<div onClick={() => setModalInfo({
												open: true,
												title: 'Gerenciar equipe',
												content: (
													<ManageTeamEdgesModal
														leader={e}
														setModalInfo={setModalInfo}
													/>
												),
											})}>
												<IcoTeam />
											</div>
										) : null}
										<div 
											onClick={() => setModalInfo({ 
												open: true, 
												title: 'Editar colaborador', 
												content: <SaveContributorModal 
													healthPlaceUser={e} 
													healthPlace={healthPlace} 
												/> 
											})}>
											<IcoPencil />
										</div>
										<div onClick={() => setModalInfo({ open: true, title: 'Remover colaborador', content: <RemoveContributorModal holdingColaborator={e} /> })}>
											<IcoBin />
										</div>
									</div>
								)
								return custom
							}}
						/>
						<Paginate
							data={pagination}
							action={handlePagination}
						/>
					</div>
				</div>
			</div>
		</CollaboratorsContext.Provider>
	)
}
