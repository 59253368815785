import './StreptococcusGBSWrite.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../../../../../../../../../../../../../../newcomponents/button/button'
import NewSelect from '../../../../../../../../../../../../../../../newcomponents/form/select/select'
import AlertRowText from '../../../../../../../../../../../../../../../component/alertRowText/alertRowText'
import api from '../../../../../../../../../../../../../../../helpers/api'
import { AddAlert, AlertType } from '../../../../../../../../../../../../../../../component'
import { getGBSResult } from '../utils'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../../../../../newcomponents/modal/modal'
import getCurrentDate from '../../../../../../../../../../../../../../../utils/getCurrentDate'
import { buildDate, formatISO8601 } from '../../../../../../../../../../../../../../../utils/convertDate'
import { getYearsStart1900 } from '../../../../../../../../../../../../../../../utils/getYearsStart1900'

const selectOptions_data = require('../../../../../../../../../../../../../../../component/form/select/options.json')

function strpStreptococcusGBS(exam={}) {
	let { day, month, year } = getCurrentDate()

	let date = exam?.date ? new Date(exam.date) : ''

	return {
		"id": exam?.id,
		"gbs": exam?.gbs || 'NOT_SEARCHED',
		"date_day": date ? `${date.getDate() < 10 ? '0': ''}${date.getDate()}` : day,
		"date_month": date ? `${date.getMonth() + 1 < 10 ? '0': ''}${date.getMonth() + 1}` : month,
		"date_year": date ? `${date.getFullYear()}` : year,
	}
}

function parseStreptococcusGBS(exam={}) {
	let date = buildDate(exam?.date_year, exam?.date_month, exam?.date_day, '10', '10')

	return {
		"id": exam?.id,
		"gbs": exam?.gbs || 'NOT_SEARCHED',
		"date": formatISO8601(date),
	}
}

export default function StreptococcusGBSWrite({ holdingStreptococcusGBS=null, setModalInfo=() => null }) {
	const dispatch = useDispatch()
	const [data, setData] = useState(strpStreptococcusGBS(holdingStreptococcusGBS))
	const { selectedLanguage, fetchThousandDaysProjects, holdingProject } = useThousandDays()

	const {
		Positive,
		Negative,
		Notinvestigated,
		Savedexam,
		Notsavedexam,
		Groupbstreptococcustest,
		Savegbs,
		Select_Months_Options,
		Day,
		Week,
		Year,
		date
	} = require('../../../../../../../../../../../../../../data/translation.json')[selectedLanguage]

	const handleSelect = event => {
		setData(prev => ({
			...prev,
			[`${event.target.id}`]: event.target.selected,
		}))
	}

	const handleSubmit = async () => {
		const payload = {
			...parseStreptococcusGBS(data),
			person: holdingProject.patient.id,
			project: holdingProject.id,
		}

		try {
			if (payload?.id) {
				await api.patch(`/exam/streptococcus_gbs/${payload.id}/`, payload)
			} else {
				await api.post('/exam/streptococcus_gbs/', payload)
			}
			dispatch(AddAlert(Groupbstreptococcustest, Savedexam, AlertType.SUCCESS))
			fetchThousandDaysProjects()
			setModalInfo(MODAL_INIT_PROPS)
		} catch (err) {
			dispatch(AddAlert(Groupbstreptococcustest, Notsavedexam, AlertType.ERROR))
			console.error("StreptococcusGBSWrite ~ handleSubmit: ", err)
		}
	}

	const date_months_options = selectOptions_data.months.map((option) => ({ id: option.id, name: Select_Months_Options[option.id] }))

	return (
		<>
			<div>
				<b>{date}</b>
				<div className='ABOGroupRHFactorWrite-Date'>
					<NewSelect
						id='date_day'
						options={selectOptions_data.days}
						selected={data.date_day}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Day}
					/>
					<NewSelect
						id='date_month'
						options={date_months_options}
						selected={data.date_month}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Week}
					/>
					<NewSelect
						id='date_year'
						options={getYearsStart1900()}
						selected={data.date_year}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Year}
					/>
				</div>
			</div>
			<div className='StreptococcusGBSWrite-Data'>
				<NewSelect
					id='gbs'
					label='GBS'
					options={[
						{ id: 'NOT_SEARCHED', name: Notinvestigated },
						{ id: 'POSITIVE', name: Positive },
						{ id: 'NEGATIVE', name: Negative },
					]}
					onSelect={handleSelect}
					selected={data.gbs}
					canBeEmpty={false}
				/>
				{data.gbs && data.gbs !== 'NOT_SEARCHED' ? (
					<AlertRowText {...getGBSResult(data.gbs, selectedLanguage)} />
				) : null}
			</div>
			<div className='AddNewExamModal-ButtonRow'>
				<Button
					label={Savegbs}
					onClick={handleSubmit}
				/>
			</div>
		</>
	)
}
