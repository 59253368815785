import './TicketsManager.css'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { AddAlert, AlertType, List, MaskDataHora, Paginate } from '../../../../component/index.js'
import Loading from '../../../../newcomponents/loading/loading.js'
import Modal, { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal.js'
import { useApp } from '../../../../layout/App.js'
import TicketsHistoryFilters from './subcomponents/TicketsHistoryFilters/TicketsHistoryFilters.js'
import { getTicketsHistory, patchCallPanelServiceTicket } from '../../http.js'
import { parseTicketURLQuerysetParams } from '../../helpers.js'
import CrossMarkIcon from '../../../../component/icon/cross-mark.js'
import CheckCircleOutlinedTwo from '../../../../component/icon/check-outlined-2.js'
import ConfirmServedTicketModal from './subcomponents/ConfirmServedTicketModal/ConfirmServedTicketModal.js'
import ConfirmCancelTicketModal from './subcomponents/ConfirmCancelTicketModal/ConfirmCancelTicketModal.js'
import IcoCalendarLoop from '../../../../component/icon/calendar_loop.js'
import RecallTicketModal from './subcomponents/RecallTicketModal/RecallTicketModal.js'

const { TICKET_STATUS_CHOICES } = require('../../../../component/form/select/options.json')

const TicketsContext = React.createContext()

export function useTicketsManager() {
	return useContext(TicketsContext)
}


export const INIT_TICKET_FILTERS = {
	"call_panel_service__id__in": [],
	"attendance__person__name_or_cpf": "",
	"attendance__doctor__id__in": [],
	"called_at__gte": new Date(),
	"called_at__lte": new Date(),
	"attendance__service_location__id__in": [],
	"status__in": [],
	"ordering": "",
}

export default function TicketsManager() {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(true)
	const [tickets, setTickets] = useState([])
	const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })
	const [modalProps, setModalProps] = useState(MODAL_INIT_PROPS)
	const [filters, setFilters] = useState(INIT_TICKET_FILTERS)
	const { currentHealthPlaceUser } = useApp()

	useEffect(() => {
		fetchInitTicketHistory()
	}, [])

	const fetchInitTicketHistory = async () => {
		setLoading(true)
		await fetchTicketsHistory({ offset: 0 })
		setLoading(false)
	}

    const fetchTicketsHistory = async params => {
		const builtParams = parseTicketURLQuerysetParams(filters)

		params = { 
			offset: 0,
			...builtParams, 
			...params, 
			limit: 18, 
			health_place__id: currentHealthPlaceUser?.health_place?.id
		}

		try {
			let response = await getTicketsHistory(params)
			setTickets(response.data.results)
			setPagination({
				totalElements: response.data.count,
				totalPages: Math.ceil(response.data.count / 18),
				pageNumber: params.offset / 18,
			})
		} catch (err) {
			console.error('fetchTicketsHistory ~ ', err)
		}
	}

	const handleNextPage = e => {
		fetchTicketsHistory({ offset: e * 18 })
	}

	const headDefault = [
		{ "colunm": "h_status", "text": "Status", "width": '100px' },
		{ "colunm": "h_attendance__person__name", "text": "Paciente" },
		{ "colunm": "h_attendance__doctor__name", "text": "Profissional" },
		{ "colunm": "h_attendance__service_location__name", "text": "Local de Atendimento" },
		{ "colunm": "h_called_at", "text": "Data e Hora", "width": "140px" },
        { "colunm": "h_action", "text": <div className='TicketsManager-List-Filters-HeaderRow-Action'>Ações</div>, "width": '108px' }
    ]

	return (
		<TicketsContext.Provider
			value={{
				tickets,
				pagination,
			}}>
			<Modal {...modalProps} dismissFn={() => setModalProps(MODAL_INIT_PROPS)} />
			<div className='TicketsManager-Content'>
				<TicketsHistoryFilters
					filters={filters}
					setFilters={setFilters}
					fetchTicketsHistory={fetchTicketsHistory}
					setModalProps={setModalProps}
				/>
				<div className='TicketsManager-Separator' />
				<div className='TicketsManager-Body'>
					{
						loading 
						? <div className='TicketsManager-Loading'><Loading /></div>
						: <div>
							{
								tickets?.length
								? <div className='TicketsManager-List'>
									<List
										head={headDefault}
										data={tickets}
										maxHeight='calc(100vh - 100px)'
										listCustom={cursor => {
											const custom = cursor;
											const cursorStatus = TICKET_STATUS_CHOICES.find(({id}) => id === cursor?.status)
											const cursorCalledAt = cursor?.called_at ? MaskDataHora(cursor?.called_at, 'DD/MMM/AAAA', '-') : null
											custom['h_attendance__person__name'] = cursor?.attendance?.person?.social_name?.toUpperCase() || cursor?.attendance?.person?.name?.toUpperCase() || '-'
											custom['h_attendance__doctor__name'] = cursor?.attendance?.doctor?.name?.toUpperCase() || '-'
											custom['h_called_at'] = cursorCalledAt?.data && cursorCalledAt?.hora ? `${cursorCalledAt?.data} ${cursorCalledAt?.hora?.slice(0, 5)}` : '-'
											custom['h_attendance__service_location__name'] = cursor?.attendance?.service_location?.name || '-'
											custom['h_call_panel_service'] = cursor?.call_panel_service?.name || '-'
											custom['h_status'] = <div 
												className='TicketsManager-List-HeaderContent-OutlinedEffect'
												style={{
													'border': `1px solid ${cursorStatus.color}`,
													'backgroundColor': cursorStatus.secondColor,
													'color': cursorStatus.color,
												}}
											>
												{cursorStatus?.name || '-'}
											</div>
											custom['h_action'] = (
												cursorStatus?.id != 'CANCEL' && cursorStatus?.id != 'SERVED'
												? <div className='TicketsManager-List-Filters-ActionRow'>
													<div
														className='TicketsManager-List-Filters-ActionRow-Recall'
														title='Chamar novamente'
														onClick={() => {
															setModalProps({
																open: true,
																title: 'Chamar novamente',
																content: <RecallTicketModal 
																	ticket={cursor}
																	extraAction={() => {
																		setFilters(INIT_TICKET_FILTERS)
																		setModalProps(MODAL_INIT_PROPS)
																		fetchTicketsHistory({ offset: 0 })
																	}}
																/>
															})
														}}
													>
														<IcoCalendarLoop />
													</div>
													<div
														className='TicketsManager-List-Filters-ActionRow-Success'
														title='Paciente atendido'
														onClick={() => {
															setModalProps({
																open: true,
																title: 'Confirmar atendimento de chamado',
																content: <ConfirmServedTicketModal
																	ticket={cursor}
																	extraAction={() => {
																		setFilters(INIT_TICKET_FILTERS)
																		setModalProps(MODAL_INIT_PROPS)
																		fetchTicketsHistory({ offset: 0 })
																	}}
																/> 
															})
														}}
													>
														<CheckCircleOutlinedTwo />
													</div>
													<div
														className='TicketsManager-List-Filters-ActionRow-Error'
														title='Cancelar chamado'
														onClick={() => {
															setModalProps({
																open: true,
																title: 'Confirmar cancelamento de chamado',
																content: <ConfirmCancelTicketModal
																	ticket={cursor}
																	extraAction={() => {
																		setFilters(INIT_TICKET_FILTERS)
																		setModalProps(MODAL_INIT_PROPS)
																		fetchTicketsHistory({ offset: 0 })
																	}}
																/> 
															})
														}}
													>
														<CrossMarkIcon />
													</div>
												</div>
												: null
											)
											return custom
										}}
									/>
									<Paginate data={pagination} action={handleNextPage} />
								</div>
								: <div className='TicketsManager-NotFound'>
									<div>
										<p><b>Nenhum chamado foi encontrado</b></p>
									</div>
								</div>
							}
						</div> 
					}
				</div>
			</div>
		</TicketsContext.Provider>
	)
}
