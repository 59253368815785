import './ConfigInventoryMaterials.css'
import React, { useEffect, useState } from "react";
import CategoriesOptions from './subcomponents/CategoriesOptions/CategoriesOptions';
import LocationsOptions from './subcomponents/LocationsOptions/LocationsOptions';
import ProductTypesOptions from './subcomponents/ProductTypesOptions/ProductTypesOptions';
import ManufacturersOptions from './subcomponents/ManufacturersOptions/ManufacturersOptions';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../newcomponents/modal/modal';
import { getInventoryMaterialCategories } from './subcomponents/CategoriesOptions/http';
import { getInventoryMaterialLocations } from './subcomponents/LocationsOptions/http';
import { getInventoryMaterialManufacturers } from './subcomponents/ManufacturersOptions/http';

export default function ConfigInventoryMaterials() {
    const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
    const [activeCategories, setActiveCategories] = useState([])
    const [activeLocations, setActiveLocations] = useState([])
    const [activeManufacturers, setActiveManufacturers] = useState([])

    useEffect(() => {
        fetchStats()
    }, [])

    const fetchStats = () => {
        fetchActiveCategoriesOptions()
        fetchActiveLocationsOptions()
        fetchActiveManufacturersOptions()
    }

    const fetchActiveCategoriesOptions = async () => {
        try {
            let res = await getInventoryMaterialCategories({ active: true })
            setActiveCategories(res.data.results)
        } catch (err) {
            setActiveCategories([])
            console.error('fetchActiveCategoriesOptions ~ ', err)
        }
    }

    const fetchActiveLocationsOptions = async () => {
        try {
            let res = await getInventoryMaterialLocations({ active: true })
            setActiveLocations(res.data.results)
        } catch (err) {
            setActiveLocations([])
            console.error('fetchActiveLocationsOptions ~ ', err)
        }
    }

    const fetchActiveManufacturersOptions = async () => {
        try {
            let res = await getInventoryMaterialManufacturers({ active: true })
            setActiveManufacturers(res.data.results)
        } catch (err) {
            setActiveManufacturers([])
            console.error('fetchActiveManufacturersOptions ~ ', err)
        }
    }

    return <div className='ConfigInventoryMaterials'>
        <Modal 
            {...modalInfo} 
            dismissFn={() => {
                fetchStats()
                setModalInfo(MODAL_INIT_PROPS)}
            }
        />
        <div className='ConfigInventoryMaterials-Col'>
			<div className='ConfigInventoryMaterials-Container'>
                <CategoriesOptions activeCategories={activeCategories} setModalInfo={setModalInfo} />
			</div>
            {
                /**
                <div className='ConfigInventoryMaterials-Container'>
                    <ProductTypesOptions />
                </div>                 
                */
            }
			<div className='ConfigInventoryMaterials-Container'>
                <LocationsOptions activeLocations={activeLocations} setModalInfo={setModalInfo} />
			</div>
		</div>
		<div className='ConfigInventoryMaterials-Col'>
            <div className='ConfigInventoryMaterials-Container'>
                <ManufacturersOptions activeManufacturers={activeManufacturers} setModalInfo={setModalInfo} /> 
            </div>
		</div>
    </div>
}