import isValidDateExcludingPastDates from "../../../../../../../../validators/isValidDateExcludingPastDates"

export class InventoryMaterialRequest {
    #errors

    constructor(data) {
        Object.assign(this, data)
        
        this.id = data?.id || null
        this.name = data?.name || ''
        this.code = data?.code || ''
        this.category = data?.category || ''
        this.manufacturer = data?.manufacturer || ''
        this.presentation = data?.presentation || ''
        this.health_place = data?.health_place || null
        this.minimum_stock = typeof data?.minimum_stock === 'number' ? data.minimum_stock : null
        this.expiration_notice = typeof data?.expiration_notice === 'number' ? data.expiration_notice : null
        this.base64_avatar = data?.base64_avatar || ''

        this.#errors = {}
    }
    
    isValid() {
        const OPEN_FIELDS = ['name','code','category','manufacturer','presentation']
        const NUM_OPEN_FIELDS = ['minimum_stock', 'expiration_notice']

        Object.entries(this).map(([key, value]) => {
            if (OPEN_FIELDS.includes(key) && !value) {
                this.#errors[key] = true
            } else if (NUM_OPEN_FIELDS.includes(key) && value === null) {
                this.#errors[key] = true
            } else if (key === 'expiration_date' && !isValidDateExcludingPastDates(value)) {
                this.#errors['expiration_year'] = true
                this.#errors['expiration_month'] = true
                this.#errors['expiration_day'] = 'Data anterior'
            }
        })

        return Object.keys(this.#errors).length === 0
    }

    getErrors() {
        return this.#errors
    }
}

export class MaterialFlowEntryForm {
    #errors;

    constructor(data) {
        Object.assign(this, data);

        this.material = data?.material
        this.batch_quantity = typeof data?.batch_quantity === 'number' ? data.batch_quantity : null;
        this.day = data?.day || '';
        this.month = data?.month || '';
        this.year = data?.year || '';
        this.location = data?.location || null;
        this.invoice = data?.invoice || '';
        this.responsible = data?.responsible || null;
        this.source = data?.source || null;
        this.note = data?.note || '';
        this.health_place = data?.health_place || null
        this.expiration_date = this.year && this.month && this.day
            ? `${this.year}-${String(this.month).padStart(2, '0')}-${String(this.day).padStart(2, '0')}`
            : "";

        this.#errors = {};
    }

    isValid() {
        const REQUIRED_FIELDS = ['batch_quantity', 'location', 'invoice', 'responsible', 'source'];

        Object.entries(this).forEach(([key, value]) => {
            if (REQUIRED_FIELDS.includes(key) && !value) {
                this.#errors[key] = true;
            }
        });

        if (this.batch_quantity !== null && this.batch_quantity <= 0) {
            this.#errors['batch_quantity'] = 'Quantidade deve ser maior que zero';
        }

        if (!isValidDateExcludingPastDates(this.expiration_date)) {
            this.#errors['year'] = true
            this.#errors['month'] = true
            this.#errors['day'] = 'Data inválida'
        }

        return Object.keys(this.#errors).length === 0;
    }

    getErrors() {
        return this.#errors;
    }
}

export class MaterialFlowExitForm {
    #errors

    constructor(data) {
        Object.assign(this, data);

        this.material = data?.material || '';
        this.source = data?.source || null;
        this.type = data?.type || '';
        this.day = data?.day || '';
        this.month = data?.month || '';
        this.year = data?.year || '';
        this.invoice = data?.invoice || '';
        this.location = data?.location || null;
        this.requested_amount = typeof data?.requested_amount === 'number' ? data.requested_amount : null;
        this.patient = data?.patient || null;
        this.justification = data?.justification || '';
        this.health_place = data?.health_place || null;
        this.health_place_user = data?.health_place_user || null;

        this.time = this.year && this.month && this.day
            ? `${this.year}-${String(this.month).padStart(2, '0')}-${String(this.day).padStart(2, '0')}`
            : "";

        this.#errors = {};
    }

    isValid() {
        const REQUIRED_FIELDS = ['source', 'type', 'invoice', 'location', 'requested_amount'];
        
        Object.entries(this).forEach(([key, value]) => {
            if (REQUIRED_FIELDS.includes(key) && !value) {
                this.#errors[key] = true;
            }
        });

        if (this.requested_amount !== null && this.requested_amount <= 0) {
            this.#errors['requested_amount'] = 'Quantidade deve ser maior que zero.';
        }

        if (this.source === 'allocation_to_patient' && !this.patient) {
            this.#errors['patient'] = true;

        }

        if (!isValidDateExcludingPastDates(this.time)) {
            this.#errors['year'] = true;
            this.#errors['month'] = true;
            this.#errors['day'] = 'Data inválida';
        }

        return Object.keys(this.#errors).length === 0;
    }

    getErrors() {
        return this.#errors;
    }
}