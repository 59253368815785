import './BookingAppointment.css'
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom'
import { stringifyCollaboratorOption } from "../../../../utils/stringifyCollaboratorOption";
import { useApp } from "../../../../layout/App";
import { getCollaborators } from "../../../collaborators/http";
import NewSelect from "../../../../newcomponents/form/select/select";
import { Input } from "../../../../component";
import BookingAppointmentCursor from './subcomponents/BookingAppointmentCursor/BookingAppointmentCursor';
import { useDispatch } from 'react-redux';
import { loaded, loading } from '../../../../layout/redux/AppActions';

export default function BookingAppointment() {
    const dispatch = useDispatch()
    const searchTimeOut = useRef()
    const location = useLocation()
    const history = useHistory()
    const { currentHealthPlaceUser, isHealthProfessional } = useApp()
    const queryParams = new URLSearchParams(location.search)
    const [healthProfessionals, setHealthProfessionals] = useState([])
    const [selectedHealthProfessional, setSelectedHealthProfessional] = useState(null)

    useEffect(() => {
        fetchFirstTimeHealthProfessionals()
        
        if (isHealthProfessional) {
            history.push(`/atendimentos/agendamento?health_place_user__user__person=${currentHealthPlaceUser?.user?.person?.id}`)
            setSelectedHealthProfessional(stringifyCollaboratorOption(currentHealthPlaceUser))
        }
        // eslint-disable-next-line
    }, [])

    const fetchFirstTimeHealthProfessionals = async () => {
        dispatch(loading())
        await fetchHealthProfessionals({})
        dispatch(loaded())
    }
    
    const fetchHealthProfessionals = async (params = {}) => {
        try {
            const res = await getCollaborators({
                ...params,
                health_place__id: currentHealthPlaceUser?.health_place?.id,
                limit: 500,
                profile__in: 'DOCTOR%2CDENTIST%2CPSYCHOLOGIST%2CNUTRITIONIST%2CNURSE',
                has_person: true
            });
            
            const results = res.data.results.map(instance => stringifyCollaboratorOption(instance));
            setHealthProfessionals(results);
            
            return results;
        } catch (err) {
            console.error('fetchHealthProfessionals ~ ', err);
            setHealthProfessionals([]);
            return []; 
        }
    };

    const handleSearchHealthProfessionals = (event) => {
        if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

        searchTimeOut.current = setTimeout(() => {
            fetchHealthProfessionals({ 'name_cpf_or_email': event.target.value })
        }, 400, event.target.value)
    }

    const handleBlur = () => {
        fetchHealthProfessionals({ 'name_cpf_or_email': '' });
    };

    const handleSelectHealthProfessional = (selected) => {
        history.push(`/atendimentos/agendamento?health_place_user__user__person=${selected}`)
        setSelectedHealthProfessional(healthProfessionals.find(({id}) => id === Number(selected)))
    }

    return (
        <div>
            <div className='BookingAppointment-Select'>
                <b>Profissional selecionado</b>
                <NewSelect 
                    options={healthProfessionals}
                    selected={queryParams.get('health_place_user__user__person') ? Number(queryParams.get('health_place_user__user__person')) : ''}
                    optionStrKey='str'
                    onSelect={event => handleSelectHealthProfessional(event.target.selected)}
                    filterNode={<div className='BookingAppointment-NewSelect-FilterBar'>
                        <Input 
                            placeholder='Pesquisar por e-mail, nome ou CPF'
                            action={handleSearchHealthProfessionals}
                            actionFocus={handleBlur}
                        />
                    </div>
                    }
                />
            </div>
            <BookingAppointmentCursor selectedHealthProfessional={selectedHealthProfessional} />
        </div>
    )
}