import './ThousandDaysPage.css'
import React, { useState } from 'react'
import PregnanciesManager from './subcomponents/PregnanciesManager/PregnanciesManager'
import FollowUpRequestsManager from './subcomponents/FollowUpRequestsManager/FollowUpRequestsManager'

const ThousandDaysPage = () => {
	const [selectedOption, setSelectedOption] = useState('PregnanciesManager')

	const options = {
		PregnanciesManager: <PregnanciesManager />,
		FollowUpRequestManager: <FollowUpRequestsManager />,
	}

	return (
		<>
			{options[selectedOption]}
		</>
	)
}

export default ThousandDaysPage
