import React from 'react'

export default function IcoCalendarLoop({ style={} }) {
    return (
    <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M3867 4993 l-127 -128 352 -352 353 -353 -1800 0 c-1537 0 -1810 -2
            -1869 -15 -344 -73 -611 -337 -693 -683 -13 -53 -17 -152 -20 -484 l-5 -418
            181 0 181 0 0 385 c0 240 4 404 11 437 37 180 186 343 369 405 53 17 136 18
            1850 21 l1795 2 -353 -352 -352 -353 128 -128 127 -127 512 513 c353 352 517
            524 528 549 24 59 19 117 -14 169 -16 24 -253 268 -527 542 l-499 497 -128
            -127z"/>
            <path d="M4698 2158 c-4 -355 -6 -410 -22 -458 -26 -78 -52 -127 -100 -186
            -68 -84 -138 -133 -255 -178 l-56 -21 -1792 -3 c-986 -1 -1793 1 -1793 5 0 4
            156 164 347 355 l348 348 -125 125 -125 125 -513 -512 c-282 -282 -519 -524
            -526 -538 -23 -42 -28 -86 -16 -132 10 -38 67 -99 533 -566 l522 -522 125 125
            125 125 -348 348 c-191 191 -347 350 -347 354 0 4 820 9 1823 10 l1822 3 88
            28 c49 15 112 39 140 54 197 99 360 277 437 476 56 143 60 189 60 632 l0 405
            -174 0 -175 0 -3 -402z"/>
        </g>
    </svg>
    )
}
