import './PreceptorshipDetailRoom.css'
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Loading from "../../../newcomponents/loading/loading";
import { capitalizeWords } from '../../../utils/capitalizeWords';
import { formatISO8601Str } from '../../../utils/convertDate';
import StudentsManager from './subcomponents/StudentsManager/StudentsManager';
import Modal, { MODAL_INIT_PROPS } from '../../../newcomponents/modal/modal';
import NewEditPreceptorshipRoomModal from './subcomponents/NewEditPreceptorshipRoomModal/NewEditPreceptorshipRoomModal';
import AssessmentsManager from './subcomponents/AssessmentsManager/AssessmentsManager';
import { PatientIcon } from '../../../component/icon/patient';
import MedicalTeamIco from '../../../component/icon/medicalTeam';
import { NotesIcon } from '../../../component/icon/notes';
import { IcoBin, IcoPencil } from '../../../component';
import RemovePreceptorshipRoomModal from './subcomponents/RemovePreceptorshipRoomModal/RemovePreceptorshipRoomModal';
import { getParams } from '../../../utils';
import { apiV2 } from '../../../helpers';


export default function PreceptorshipDetailRoom() {
    const { id } = useParams()
    const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
    const [holdingRoom, setHoldingRoom] = useState(null)
    const [loading, setLoading] = useState(true)
    const [assessments, setAssessments] = useState([])
    const [associatedStudents, setAssociatedStudents] = useState([])
    const [selectedOption, setSelectedOption] = useState('StudentsManager')

    const fetchAssociatedStudents = async () => {
        setLoading(true)
        await apiV2.get(`preceptorship/group/${id}/students`)
        .then(res => {
            setAssociatedStudents(res.data)
        })
        .catch(err => {
            console.error('fetchAssociatedStudents', err)
            setAssociatedStudents([])
        })
        setLoading(false)
    }
    
    const fetchAssessments = async () => {
        const params = {
            "room_id": id,
        }
        
        setLoading(true)
        await apiV2.get(`/preceptorship/assessments${getParams(params)}`)
            .then(res => {
                setAssessments(res.data)
            })
            .catch(err => {
                console.error("AssessmentsManager ~ fetchAssessments", err)
                setAssessments([])
            })
        setLoading(false)
    }

    useEffect(() => {
        fetchCurrentDetailedRoom()
        fetchAssociatedStudents()
        fetchAssessments()
    }, [])

    const options = {
        'StudentsManager': <StudentsManager
            setModalInfo={setModalInfo}
            associatedStudents={associatedStudents}
            fetchAssociatedStudents={fetchAssociatedStudents}
        />,
        'AssessmentsManager': <AssessmentsManager 
            setModalInfo={setModalInfo}
            assessments={assessments}
            associatedStudents={associatedStudents}
        />
    }

    const fetchCurrentDetailedRoom = async () => {
        setLoading(true)
        await apiV2.get(`/preceptorship/group/${id}`)
            .then(res => {
                setHoldingRoom(res.data)
            })
            .catch(err => {
                console.error('fetchCurrentDetailedRoom', err)
                setHoldingRoom(null)
            })
        setLoading(false)
    }

    const handleOpenEditRoom = () => {
        setModalInfo({ 
            open: true, 
            title: 'Editar Grupo de Acompanhamento', 
            content: <NewEditPreceptorshipRoomModal 
                setModalInfo={setModalInfo} 
                fetchData={fetchCurrentDetailedRoom}
                initHoldingRoom={holdingRoom}
            /> 
        })
    }
    
    const handleOpenDeleteRoom = () => {
        setModalInfo({ 
            open: true, 
            title: 'Remover Grupo de Acompanhamento', 
            content: <RemovePreceptorshipRoomModal setModalInfo={setModalInfo} /> 
        })
    }

    return <div>
        <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
        {
            loading
            ? <div className="PreceptorshipDetailRoom-Loading">
                <Loading />
            </div>
            : <div>
                <div className='PreceptorshipDetailRoom-Header'>
                    <div className='PreceptorshipDetailRoom-Header-SlotBox'>
                        <div className='PreceptorshipDetailRoom-Header-SlotBox-Slot'>
                            <div className='PreceptorshipDetailRoom-Header-SlotBox-Slot-IconBox'>
                                <MedicalTeamIco style={{ fill: '#fff', width: '21px' }} />
                            </div>
                            <div className='PreceptorshipDetailRoom-Header-SlotBox-Slot-Info'>
                                <b>{holdingRoom?.name ? capitalizeWords(holdingRoom.name) : '-'}</b><br/>
                                <span>Criado em { holdingRoom?.time ? formatISO8601Str(holdingRoom.time) : '-' }</span>
                            </div>
                        </div>
                        <div className='PreceptorshipDetailRoom-Header-SlotBox-Slot'>
                            <div className='PreceptorshipDetailRoom-Header-SlotBox-Slot-IconBox'>
                                <NotesIcon className='' style={{ fill: '#fff', width: '21px' }} />
                            </div>
                            <div className='PreceptorshipDetailRoom-Header-SlotBox-Slot-Info'>
                                <b>Descrição</b><br/>
                                <span>{holdingRoom?.description || '-'}</span>
                            </div>
                        </div>
                        <div className='PreceptorshipDetailRoom-Header-SlotBox-Slot'>
                            <div className='PreceptorshipDetailRoom-Header-SlotBox-Slot-IconBox'>
                                <PatientIcon style={{ fill: '#fff', width: '21px' }} />
                            </div>
                            <div className='PreceptorshipDetailRoom-Header-SlotBox-Slot-Info'>
                                <b>Alunos</b><br/>
                                <span>{ associatedStudents?.length || '0'}</span>
                            </div>
                        </div>
                    </div>
                    <div className='PreceptorshipDetailRoom-Header-ActionBox'>
                        <div className='PreceptorshipDetailRoom-Header-ActionBox-Icon' onClick={handleOpenEditRoom}>
                            <IcoPencil className='PreceptorshipDetailRoom-Header-ActionBox-Icon-Svg' />
                        </div>
                        <div className='PreceptorshipDetailRoom-Header-ActionBox-Icon' onClick={handleOpenDeleteRoom}>
                            <IcoBin className='PreceptorshipDetailRoom-Header-ActionBox-Icon-Svg' />
                        </div>
                    </div>
                </div>
                <div className='PreceptorshipDetailRoom-Content-Header'>
                    <div 
                        className={`PreceptorshipDetailRoom-Content-Header-Btn ${selectedOption === 'StudentsManager' ? 'active' : ''}`}
                        onClick={() => setSelectedOption('StudentsManager')}
                    >
                        Alunos
                    </div>
                    <div
                        className={`PreceptorshipDetailRoom-Content-Header-Btn ${selectedOption === 'AssessmentsManager' ? 'active' : ''}`}
                        onClick={() => setSelectedOption('AssessmentsManager')}
                    >
                        Avaliações
                    </div>
                </div>
                <div>
                    { options[selectedOption] }
                </div>
            </div>
        }
    </div>
}