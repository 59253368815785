import { Mask1DecimalPlace } from "../../../../../../../../component/mask/mask"

export function strpAnthropometry(anthropometry = null) {
    return {
        _id: anthropometry?._id,
        person: anthropometry?.person,
        attendance: anthropometry?.attendance,
        weight: anthropometry?.weight
            ? Mask1DecimalPlace(String(anthropometry.weight))
            : '',
        height: anthropometry?.height || '',
        abdominal_circumference: anthropometry?.abdominal_circumference
            ? String(anthropometry.abdominal_circumference)
            : '',
        active: true,
    }
}

export function parseAnthropometry(anthropometry) {
    return {
        _id: anthropometry?._id,
        person: anthropometry?.person?.id,
        attendance: anthropometry?.attendance,
        weight: anthropometry?.weight ? Number(String(anthropometry.weight).replace(',', '.')) : null,
        height: anthropometry?.height ? Number(anthropometry.height) : null,
        abdominal_circumference: anthropometry?.abdominal_circumference
            ? Number(anthropometry.abdominal_circumference)
            : null,
        active: true,
    }
}