import { AlertRowTextType } from '../../../../../../../../../../../../component/alertRowText/alertRowText'
import { Mask1DecimalPlace } from '../../../../../../../../../../../../component/mask/mask'
import { buildDate } from '../../../../../../../../../../../../utils/convertDate'

const prenatal_translate = require('../../../../../../../../../../../../pages/data/translation.json')

export function getPresentationResult(
	currentPrenatalAttendance,
	pregnancy_age_at,
	selectedLanguage
) {
	const { Notnormal } = prenatal_translate[selectedLanguage]
	let weeks = 0
	while (pregnancy_age_at >= 7) {
		weeks += 1
		pregnancy_age_at -= 7
	}

	if (weeks === 0) return { type: '', content: '' }

	const presentation = currentPrenatalAttendance.presentation

	if (weeks >= 34 && (presentation === 'CORMIC' || presentation === 'PELVIC')) {
		return { type: AlertRowTextType.WARNING, content: Notnormal }
	} else if (presentation === 'NOT_EVALUATED') {
		return { type: '', content: '' }
	} else {
		return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
	}
}

export function getUterineHeightResult(
	currentPrenatalAttendance,
	pregnancy_age_at,
	Change
) {
	let weeks = 0
	while (pregnancy_age_at >= 7) {
		weeks += 1
		pregnancy_age_at -= 7
	}
	let uterine_height = Number(
		String(currentPrenatalAttendance.uterine_height).replace(',', '.')
	)

	if (weeks === 0) return { type: '', content: '' }

	const weekHeightRange = {
		20: [18.8, 22.6],
		21: [19.7, 23.5],
		22: [20.6, 24.4],
		23: [21.5, 25.3],
		24: [22.4, 26.1],
		25: [23.2, 27.0],
		26: [24.0, 27.8],
		27: [24.8, 28.6],
		28: [25.6, 29.4],
		29: [26.3, 30.1],
		30: [27.1, 30.8],
		31: [27.8, 31.6],
		32: [28.5, 32.2],
		33: [29.2, 32.9],
		34: [29.8, 33.6],
		35: [30.4, 34.2],
		36: [31.1, 34.8],
		37: [31.7, 35.4],
		38: [32.2, 36.0],
		39: [32.8, 36.6],
		40: [33.3, 37.1],
		41: [33.8, 37.6],
		42: [34.3, 38.1],
	}

	let range
	if (weeks <= 20) {
		range = weekHeightRange[20]
	} else if (weeks > 42) {
		range = weekHeightRange[42]
	} else {
		range = weekHeightRange[weeks]
	}

	return uterine_height < range[0] || uterine_height > range[1]
		? { type: AlertRowTextType.WARNING, content: Change }
		: { type: AlertRowTextType.SUCCESS, content: 'Normal' }
}

export function getFetalMovementsResult(
	currentPrenatalAttendance,
	allPrenatalAttendances,
	selectedLanguage
) {
	const { Notnormal, Notnoticed } = prenatal_translate[selectedLanguage]
	const current_fetal_movements = currentPrenatalAttendance.fetal_movements

	const previousPrenatalAttendances = allPrenatalAttendances.filter(
		attendance =>
			new Date(attendance.prenatal_attendance_date) <
			new Date(currentPrenatalAttendance.prenatal_attendance_date)
	)

	if (current_fetal_movements === 'ABSENT') {
		for (const previousPrenatalAttendance of previousPrenatalAttendances) {
			let past_fetal_movements = previousPrenatalAttendance.fetal_movements
			if (
				past_fetal_movements === 'PRESENT' ||
				past_fetal_movements === 'DIMINISHED'
			) {
				return { type: AlertRowTextType.WARNING, content: Notnormal }
			}
		}
		return { type: AlertRowTextType.INFO, content: Notnoticed }
	} else if (current_fetal_movements === 'NOT_EVALUATED') {
		return { type: '', content: '' }
	} else {
		return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
	}
}

export function getHeartbeatResult(heartbeat, selectedLanguage) {
	const { Notnormal, notnoticed } = prenatal_translate[selectedLanguage]
	if (!heartbeat && (heartbeat === 0 || heartbeat === '0'))
		return { type: '', content: '' }

	heartbeat = Number(heartbeat)

	if (heartbeat < 110 || heartbeat > 160)
		return { type: AlertRowTextType.WARNING, content: Notnormal }

	return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
}

export function strpPreNatalAttendanceEditor(preNatalAttendance = null) {
	let prenatal_attendance_date = preNatalAttendance?.prenatal_attendance_date
		? new Date(preNatalAttendance?.prenatal_attendance_date)
		: new Date()

	return {
		...preNatalAttendance,
		id: preNatalAttendance?.id || null,
		prenatal_attendance_day: prenatal_attendance_date
			? `${prenatal_attendance_date.getDate() < 10 ? '0' : ''}${prenatal_attendance_date.getDate()}`
			: '',
		prenatal_attendance_month: prenatal_attendance_date
			? `${prenatal_attendance_date.getMonth() + 1 < 10 ? '0' : ''}${prenatal_attendance_date.getMonth() + 1}`
			: '',
		prenatal_attendance_year: prenatal_attendance_date
			? `${prenatal_attendance_date.getFullYear()}`
			: '',
		uterine_height: preNatalAttendance?.uterine_height
			? Mask1DecimalPlace(preNatalAttendance.uterine_height)
			: '',
		presentation: preNatalAttendance?.presentation || 'NOT_EVALUATED',
		heartbeat: preNatalAttendance?.heartbeat || '',
		systolic: preNatalAttendance?.systolic ? String(preNatalAttendance.systolic) : '',
        diastolic: preNatalAttendance?.diastolic ? String(preNatalAttendance.diastolic) : '',
		fetal_movements: preNatalAttendance?.fetal_movements || 'NOT_EVALUATED',
		weight: preNatalAttendance?.weight
			? Mask1DecimalPlace(String(preNatalAttendance.weight))
			: '',
		patient_complaints: preNatalAttendance?.patient_complaints || '',
	}
}

export function parsePreNatalAttendanceEditor(preNatalAttendance, project_id) {
	const {
		id,
		prenatal_attendance_day,
		prenatal_attendance_month,
		prenatal_attendance_year,
		uterine_height,
		presentation,
		heartbeat,
		systolic,
		diastolic,
		fetal_movements,
		weight,
		patient_complaints,
	} = preNatalAttendance

	return {
		...preNatalAttendance,
		id: id || null,
		project: project_id,
		prenatal_attendance_date: buildDate(
			prenatal_attendance_year,
			prenatal_attendance_month,
			prenatal_attendance_day,
			'10',
			'10'
		),
		uterine_height: uterine_height
			? Number(String(uterine_height).replace(',', '.'))
			: null,
		presentation: presentation,
		heartbeat: heartbeat ? Number(heartbeat) : null,
		systolic: systolic ? Number(systolic) : null,
        diastolic: diastolic ? Number(diastolic) : null,
		fetal_movements: fetal_movements,
		weight: weight ? Number(String(weight).replace(',', '.')) : null,
		patient_complaints: patient_complaints,
	}
}
