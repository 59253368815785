import React from 'react'

export function EditingIcon({style={}}) {
    return <svg style={style} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M4395 5107 c-22 -8 -58 -23 -80 -35 -25 -13 -487 -469 -1206 -1189
            l-1166 -1168 -78 -390 c-67 -340 -75 -394 -66 -426 13 -43 67 -94 109 -104 20
            -4 152 18 389 65 197 39 374 75 393 80 28 8 266 241 1206 1182 1317 1319 1219
            1209 1219 1368 -1 137 -20 169 -238 387 -147 147 -193 187 -240 208 -69 32
            -181 42 -242 22z m269 -446 c136 -136 158 -162 153 -182 -4 -13 -47 -64 -97
            -114 l-90 -90 -178 178 -177 177 96 95 c54 53 104 95 115 95 11 0 84 -64 178
            -159z m-424 -426 l175 -175 -919 -919 -919 -919 -214 -42 c-117 -24 -216 -40
            -220 -37 -3 4 13 102 36 219 l42 213 917 918 c504 504 919 917 922 917 3 0 84
            -79 180 -175z"/>
            <path d="M445 4290 c-194 -40 -352 -179 -417 -368 l-23 -67 0 -1705 0 -1705
            23 -66 c57 -166 185 -294 350 -351 l67 -23 1705 0 1705 0 67 23 c163 57 286
            178 350 348 l23 59 3 1172 c2 1163 2 1173 -18 1213 -23 45 -80 80 -130 80 -50
            0 -107 -35 -130 -80 -20 -39 -20 -56 -20 -1173 0 -1274 5 -1193 -75 -1272 -81
            -81 63 -75 -1775 -75 -1838 0 -1694 -6 -1775 75 -81 81 -75 -63 -75 1775 0
            1838 -6 1694 75 1775 79 80 -2 75 1272 75 1117 0 1134 0 1173 20 45 23 80 80
            80 130 0 50 -35 107 -80 130 -39 20 -55 20 -1187 19 -631 -1 -1166 -5 -1188
            -9z"/>
        </g>
    </svg>
}
