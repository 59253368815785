import './EditInstitutionPartnersModal.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddAlert, AlertType, FilterSelect, Select } from '../../../../../../../../component'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import { listaCidades } from '../../../../../../../../layout/redux/AppActions'
import { useConfiguration } from '../../../../../../configuration'
import { getParams } from '../../../../../../../../utils'
import { getHealthInsurances } from '../../../../../../http'
import NewButton from '../../../../../../../../newcomponents/button/button'

const strpHealthPlace = (health_place) => {
	
	return {
		id: health_place?.id,
		health_insurances: health_place?.health_insurances || [],
		citys: health_place?.citys || []
	}
}

export default function EditInstitutionPartnersModal() {
	const { institution, saveInstitution, setModalProps, fetchInstitution } = useConfiguration()
	const dispatch = useDispatch()
	const [healthInsurances, setHealthInsurances] = useState([])
	const [holdingHealthPlace, setHoldingHealthPlace] = useState(strpHealthPlace(institution))

	useEffect(() => {
		dispatch([listaCidades({ limit: 50 })])
		fetchHealthInsurances({ offset: 0 })
	}, []) // eslint-disable-line

	const handlePartners = (event) => {
		setHoldingHealthPlace(prev => ({
			...prev,
			[event.target.name]: event.target.name === 'type' ? event.target.value.id : event.target.value,
		}))
	}

	const fetchHealthInsurances = async (params={}) => {
		params = getParams({ ...params, limit: 20 })

		try {
			let res = await getHealthInsurances(params)
			setHealthInsurances(res.data.results)
		} catch (err) {
			console.error('fetchHealthInsurances ~ ', err)
			dispatch(AddAlert('Planos & convênios', 'Erro ao listar planos e convênios', AlertType.ERROR))
		}
	}

	const handleSubmit = async () => {
		const payload = {
			health_insurances: holdingHealthPlace.health_insurances.map((e) => e.id),
			citys: holdingHealthPlace.citys.map((e) => e.id),
		}
		await saveInstitution(holdingHealthPlace.id, payload)
			.then(() => {
				fetchInstitution()
				setModalProps(MODAL_INIT_PROPS)
			})
	}

	return (<div className='EditInstitutionPartnersModal'>
		<div>
			<div className='EditInstitutionPartnersModal-PersonalInfo'>
                <div className='EditInstitutionPartnersModal-Subtitle-Header'>
                    <div className='EditInstitutionPartnersModal-Subtitle-Header-Icon'>P</div>
                    <div className='EditInstitutionPartnersModal-Subtitle-Header-Title'>Planos e Convênios Parceiros</div>
                </div>
            </div>
			<div className='EditInstitutionPartnersModal-Select'>
				<Select
					name='health_insurances'
					action={handlePartners}
					options={healthInsurances}
					multiSelect
					closeOnSelect={false}
					selected={holdingHealthPlace?.health_insurances || []}
				>
					<FilterSelect
						action={(e) => fetchHealthInsurances({ name: e, limit: 50 })}
						title='Filtrar'
					/>
				</Select>
			</div>
		</div>
		<div className='EditInstitutionPartners-BtnBox'>
			<NewButton label='Atualizar' onClick={handleSubmit} />
		</div>
	</div>)
}
