import './ActiveLabour.css'
import React from 'react'
import { Line } from 'react-chartjs-2'
import {
	Chart,
	LinearScale,
	CategoryScale,
	LineController,
	PointElement,
	LineElement,
	Title,
} from 'chart.js'

Chart.register(
	LinearScale,
	CategoryScale,
	LineController,
	PointElement,
	LineElement,
	Title
)

const PatographChart = ({ partograph }) => {
	const data = {
		labels: Array.from({ length: 12 }, (_, i) => (i + 1).toString()),
		datasets: [
			{
				label: 'Dilatação cervical',
				align: 'center',
				data: Array.from({ length: 12 }, (_, i) => {
					const key = `cervical_dilation_${i + 1}`
					return parseFloat(partograph[key].split(',')[0])
				}),
				fill: false,
				borderColor: 'navy',
				borderWidth: 2,
				pointRadius: 0,
				tension: 0.1,
			},
		],
	}

	const options = {
		plugins: {
			title: {
				display: true,
				text: 'Dilatação Cervical',
				font: {
					size: 18,
				},
				padding: {
					bottom: 20,
				},
			},
		},
		scales: {
			x: {
				type: 'linear',
				position: 'bottom',
				ticks: {
					stepSize: 1,
					callback: function (value, index, values) {
						return value + 'h'
					},
				},
				title: {
					display: true,
					text: 'Tempo',
					align: 'center',
					padding: { top: 20 },
				},
			},
			y: {
				type: 'linear',
				position: 'left',
				min: 4,
				max: 10,
				ticks: {
					stepSize: 1,
					callback: function (value, index, values) {
						return value + 'cm'
					},
				},
				title: {
					display: true,
					text: 'Dilatação Cervical',
					align: 'center',
					padding: { right: 20 },
				},
				layout: {
					padding: {
						top: 50,
						right: 50,
						bottom: 50,
						left: 50,
					},
				},
				legend: {
					display: false,
				},
				maintainAspectRatio: false,
				responsive: false,
			},
		},
	}

	return (
		<div style={{ position: 'relative', width: '600px', height: '300px' }}>
			<Line
				data={data}
				options={options}
			/>
		</div>
	)
}

export default PatographChart
