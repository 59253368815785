import './AttendanceHistory.css'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { IcoEstetoscopio, Paginate, adicionaZero } from '../../../../component/index.js'
import Loading from '../../../../newcomponents/loading/loading.js'
import Modal, { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal.js'
import { getAttendancesHistory } from '../../http.js'
import AttendanceHistoryFilters from './subcomponents/AttendanceHistoryFilters/AttendanceHistoryFilters.js'
import AttendanceUnit from './subcomponents/AttendanceUnit/AttendanceUnit.js'
import { useApp } from '../../../../layout/App.js'
import { parseAppointmentsURLQuerysetParams } from '../../helpers.js'

const AttendanceHistoryContext = React.createContext()

export function useAttendanceHistory() {
	return useContext(AttendanceHistoryContext)
}

export const INIT_APPOINTMENT_FILTERS = {
	"name_or_cpf": "",
	"doctor__id__in": [],
	"specialty__id__in": [],
	"health_insurance__id__in": [],
	"classification__in": [],
	"decision__in": [],
	"priority__in": [],
	"result__in": [],
	"person__birthday__gte": "",
	"person__birthday__lte": "",
	"person__address__city__in": [],
	"service_location__id__in": [],
	"start__gte": '',
	"start__lte": new Date(),
	"related_diseases_code": [],
	"ordering": "-start",
}

export default function AttendanceHistoryPage() {
	const [loading, setLoading] = useState(true)
	const [attendances, setAttendances] = useState([])
	const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })
	const [modalProps, setModalProps] = useState(MODAL_INIT_PROPS)
	const [filters, setFilters] = useState(INIT_APPOINTMENT_FILTERS)
	const { currentHealthPlaceUser } = useApp()


	useEffect(() => {
		fetchInitAttendanceHistory()
	}, [])

	const fetchInitAttendanceHistory = async () => {
		setLoading(true)
		await fetchAttendancesHistory({ offset: 0 })
		setLoading(false)
	}

	const fetchAttendancesHistory = async params => {
		const builtParams = parseAppointmentsURLQuerysetParams(filters)
		params = { 
			offset: 0,
			...builtParams, 
			...params, 
			limit: 18, 
			health_place__id: currentHealthPlaceUser?.health_place?.id
		}

		try {
			let response = await getAttendancesHistory(params)
			setAttendances(response.data.results)
			setPagination({
				totalElements: response.data.count,
				totalPages: Math.ceil(response.data.count / 18),
				pageNumber: params.offset / 18,
			})
		} catch (err) {
			console.error('fetchAttendancesHistory ~ ', err)
		}
	}

	const handleNextPage = e => {
		fetchAttendancesHistory({ offset: e * 18 })
	}

	return (
		<AttendanceHistoryContext.Provider
			value={{
				attendances,
				pagination,
			}}>
			<Modal {...modalProps} dismissFn={() => setModalProps(MODAL_INIT_PROPS)} />
			<div className='AttendanceHistoryPage-Content'>
				<AttendanceHistoryFilters
					filters={filters}
					setFilters={setFilters}
					fetchAttendancesHistory={fetchAttendancesHistory}
					setModalProps={setModalProps}
				/>
				<div className='AttendanceHistoryPage-Separator' />
				<div className='AttendanceHistoryPage-Body'>
					{
						loading 
						? <div className='AttendanceHistoryPage-Loading'><Loading /></div>
						: <div>
							{
								attendances?.length
								? <div className='AttendanceHistoryPage-AttendanceUnitWrapper'>
									{attendances.map(attendance => <AttendanceUnit
										attendance={attendance}
										setModalProps={setModalProps}
										fetchAttendancesHistory={fetchAttendancesHistory}
									/>)}
									<Paginate data={pagination} action={handleNextPage} />
								</div>
								: <div className='AttendanceHistoryPage-NotFound'>
									<div>
										<IcoEstetoscopio />
										<p><b>Nenhum atendimento foi encontrado</b></p>
									</div>
								</div>
							}
						</div> 
					}
				</div>
			</div>
		</AttendanceHistoryContext.Provider>
	)
}
