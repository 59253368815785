import React from 'react'

export function DocumentsIcon({style={}}) {
    return <svg style={style} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" >
            <path d="M1932 5090 c-18 -4 -42 -16 -54 -26 -42 -38 -48 -72 -48 -289 0 -113
            -3 -205 -7 -205 -26 0 -844 -143 -866 -152 -15 -6 -39 -24 -52 -40 -31 -37
            -32 -82 -5 -232 11 -61 17 -113 13 -116 -5 -4 -174 -64 -378 -133 -485 -166
            -485 -166 -512 -207 -13 -18 -23 -46 -23 -62 0 -31 541 -1548 566 -1585 19
            -30 61 -40 93 -22 58 33 65 8 -227 822 -150 415 -272 757 -272 760 0 3 26 14
            58 25 164 58 727 250 728 249 1 -1 126 -731 278 -1622 183 -1067 283 -1630
            295 -1650 9 -16 34 -39 55 -49 34 -17 45 -18 105 -7 461 80 1354 231 1357 229
            3 -4 -1632 -592 -1642 -591 -5 1 -137 360 -295 799 -158 439 -292 806 -298
            816 -45 73 -154 21 -131 -62 12 -43 571 -1593 590 -1637 11 -25 30 -48 49 -57
            62 -33 20 -46 931 282 679 244 883 317 1270 457 383 138 414 148 575 175 168
            29 175 30 550 30 426 0 433 1 467 70 17 33 18 135 18 1696 l0 1661 -22 31
            c-23 32 -621 603 -661 631 -20 14 -156 16 -1247 18 -674 0 -1240 -2 -1258 -7z
            m2338 -405 c6 -297 9 -309 89 -352 43 -22 50 -23 328 -23 l283 0 0 -1585 0
            -1585 -1495 0 -1495 0 0 749 0 750 -26 20 c-15 12 -33 21 -40 21 -26 0 -62
            -23 -73 -47 -8 -17 -11 -246 -11 -775 0 -737 0 -753 20 -794 38 -78 -1 -74
            792 -75 l703 -1 -847 -146 c-466 -80 -849 -144 -852 -141 -5 5 -604 3501 -608
            3548 l-3 34 365 63 c201 35 380 66 398 69 l32 6 0 -744 c0 -738 0 -745 21
            -771 26 -33 79 -36 109 -6 20 20 20 33 20 1028 0 555 3 1012 7 1015 3 4 518 6
            1142 5 l1136 -3 5 -260z m508 -132 l97 -92 -225 2 -225 2 -3 214 -2 214 130
            -124 c72 -68 175 -165 228 -216z"/>
            <path d="M2395 4001 c-49 -20 -64 -83 -30 -121 18 -20 28 -20 1126 -20 l1108
            0 20 26 c27 35 26 57 -3 91 l-24 28 -1089 2 c-598 1 -1097 -2 -1108 -6z"/>
            <path d="M2369 3452 c-33 -27 -30 -78 5 -109 l27 -23 1084 0 c773 0 1091 3
            1108 11 24 11 47 47 47 73 0 7 -9 25 -21 40 l-20 26 -1104 0 c-1057 0 -1104
            -1 -1126 -18z"/>
            <path d="M2377 2928 c-40 -31 -36 -115 8 -132 9 -3 509 -6 1111 -6 l1095 0 24
            25 c32 31 32 69 0 100 l-24 25 -1099 0 c-863 0 -1102 -3 -1115 -12z"/>
            <path d="M2374 2387 c-35 -31 -38 -82 -5 -109 22 -17 69 -18 1126 -18 l1104 0
            20 26 c12 15 21 33 21 40 0 26 -23 62 -47 73 -17 8 -335 11 -1108 11 l-1084 0
            -27 -23z"/>
            <path d="M2365 1850 c-25 -28 -23 -76 4 -103 l22 -22 1100 0 1101 0 24 28 c29
            34 30 56 3 91 l-20 26 -1108 0 c-1098 0 -1108 0 -1126 -20z"/>
        </g>
    </svg>
}
