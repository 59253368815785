import React from 'react'

export default function IcoBusiness({ style }) {
    return <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M224 5066 c-58 -18 -126 -68 -163 -118 -66 -89 -61 84 -59 -2428 l3
            -2285 25 -50 c31 -64 91 -124 155 -155 l50 -25 2325 0 2325 0 50 25 c64 31
            124 91 155 155 l25 50 0 1170 0 1170 -24 53 c-30 67 -114 145 -178 166 -27 8
            -460 97 -963 196 -503 100 -925 184 -937 187 l-22 5 -3 647 -3 646 -33 67
            c-65 132 -128 164 -412 207 -113 17 -650 98 -1193 181 -543 82 -1009 150
            -1035 149 -26 0 -65 -6 -88 -13z m1285 -376 c657 -100 1202 -185 1212 -191 10
            -5 25 -19 33 -31 14 -20 16 -243 16 -2140 l0 -2118 -320 0 -320 0 0 509 c0
            599 3 579 -95 676 -96 96 -91 95 -542 95 -354 0 -387 -1 -433 -19 -78 -30
            -134 -81 -172 -159 l-33 -67 -3 -517 -3 -518 -270 0 c-303 0 -324 4 -353 65
            -14 30 -16 253 -16 2263 0 1632 3 2238 11 2261 11 30 59 71 84 71 5 0 547 -81
            1204 -180z m2410 -1911 c504 -99 927 -187 939 -195 54 -35 52 3 52 -1180 0
            -1215 4 -1146 -65 -1178 -29 -14 -135 -16 -944 -16 l-911 0 0 1375 c0 756 3
            1375 6 1375 4 0 419 -81 923 -181z m-2032 -1532 l28 -27 3 -505 3 -505 -426 0
            -425 0 0 495 c0 331 4 502 11 518 6 13 23 31 37 40 24 15 64 17 384 14 l358
            -2 27 -28z"/>
            <path d="M689 4035 c-56 -30 -62 -119 -13 -166 l26 -24 258 0 258 0 26 24 c50
            47 42 137 -15 166 -40 21 -502 21 -540 0z"/>
            <path d="M1739 4021 c-47 -48 -37 -130 21 -164 24 -15 62 -17 265 -17 266 0
            285 4 311 67 19 43 5 93 -33 123 -24 19 -40 20 -280 20 l-255 0 -29 -29z"/>
            <path d="M689 3395 c-56 -30 -62 -119 -13 -166 l26 -24 258 0 258 0 26 24 c50
            47 42 137 -15 166 -40 21 -502 21 -540 0z"/>
            <path d="M1739 3381 c-47 -48 -37 -130 21 -164 24 -15 62 -17 265 -17 266 0
            285 4 311 67 19 43 5 93 -33 123 -24 19 -40 20 -280 20 l-255 0 -29 -29z"/>
            <path d="M689 2755 c-56 -30 -62 -119 -13 -166 l26 -24 258 0 258 0 26 24 c50
            47 42 137 -15 166 -40 21 -502 21 -540 0z"/>
            <path d="M1739 2741 c-47 -48 -37 -130 21 -164 24 -15 62 -17 265 -17 266 0
            285 4 311 67 19 43 5 93 -33 123 -24 19 -40 20 -280 20 l-255 0 -29 -29z"/>
            <path d="M689 2115 c-56 -30 -62 -119 -13 -166 l26 -24 258 0 258 0 26 24 c50
            47 42 137 -15 166 -40 21 -502 21 -540 0z"/>
            <path d="M1739 2101 c-47 -48 -37 -130 21 -164 24 -15 62 -17 265 -17 266 0
            285 4 311 67 19 43 5 93 -33 123 -24 19 -40 20 -280 20 l-255 0 -29 -29z"/>
            <path d="M3659 2101 c-47 -48 -37 -130 21 -164 24 -15 62 -17 265 -17 266 0
            285 4 311 67 19 43 5 93 -33 123 -24 19 -40 20 -280 20 l-255 0 -29 -29z"/>
            <path d="M3659 1461 c-47 -48 -37 -130 21 -164 24 -15 62 -17 265 -17 266 0
            285 4 311 67 19 43 5 93 -33 123 -24 19 -40 20 -280 20 l-255 0 -29 -29z"/>
            <path d="M3659 821 c-47 -48 -37 -130 21 -164 24 -15 62 -17 265 -17 266 0
            285 4 311 67 19 43 5 93 -33 123 -24 19 -40 20 -280 20 l-255 0 -29 -29z"/>
        </g>
    </svg>
    
}


