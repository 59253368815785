import React from 'react'


export default function WarningOutlinedIcon({ style }) {
  return (
    <svg style={style} className='icon-warningoutlined' viewBox="0 0 512.000000 512.000000">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
          <path d="M2441 4745 c-78 -22 -146 -62 -205 -121 -40 -41 -278 -429 -1108
          -1805 -581 -965 -1067 -1776 -1081 -1804 -117 -237 -1 -525 250 -627 l58 -23
          2205 0 2205 0 58 23 c254 103 367 392 247 632 -42 84 -2126 3537 -2159 3577
          -42 51 -124 109 -189 133 -75 29 -207 36 -281 15z m162 -329 c18 -7 41 -24 53
          -37 33 -37 2115 -3497 2124 -3529 17 -59 -20 -125 -83 -149 -40 -15 -4234 -15
          -4274 0 -63 24 -100 90 -83 149 9 32 2091 3492 2124 3529 21 24 70 50 94 51 7
          0 27 -6 45 -14z"/>
          <path d="M2470 3374 c-53 -17 -81 -38 -109 -85 -19 -31 -21 -50 -21 -189 0
          -85 7 -312 15 -505 9 -192 19 -432 23 -532 8 -199 10 -206 77 -241 47 -25 160
          -22 212 6 65 34 70 50 78 282 3 113 13 342 21 510 17 372 18 623 3 653 -50 97
          -177 140 -299 101z"/>
          <path d="M2507 1579 c-145 -35 -219 -200 -151 -337 55 -112 185 -160 294 -111
          96 43 144 117 144 219 0 72 -22 125 -71 172 -52 50 -145 74 -216 57z"/>
        </g>
    </svg>
  )
}
