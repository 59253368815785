import './CreateCRM.css'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddAlert, AlertType, Button, IcoAdd, IcoBin, IcoPencil, Input, List } from '../../../../../component'
import NewSelect from '../../../../../newcomponents/form/select/select'
import NewButton from '../../../../../newcomponents/button/button'
import api from '../../../../../helpers/api'
import { loaded, loading } from '../../../../../layout/redux/AppActions'
import { useApp } from '../../../../../layout/App'

const initCRMData = { nu_crm: '', uf_crm: '' }

export default function CreateCRM() {
    const dispatch = useDispatch()
    const { listas } = useSelector(state => state.appState)
    const [councils, setCouncils] = useState([])
    const [holdingCRM, setHoldingCRM] = useState(initCRMData)
    const [holdingCRMErr, setHoldingCRMErr] = useState({})
    const { fetchUserCouncils, loged } = useApp()

    const councilHead = [
        { colunm: 'h_nu_crm', text: 'Número' },
        { colunm: 'h_uf_crm', text: 'UF' },
        { colunm: 'h_actions', text: '', width: '100px' }
    ]

    const handleAddCRMData = () => {
        let errors = {}
        if (!holdingCRM.nu_crm) errors['nu_crm'] = 'Número obrigatório'
        if (!holdingCRM.uf_crm) errors['uf_crm'] = 'UF obrigatório'
        if (Object.keys(errors).length) return setHoldingCRMErr(errors)

        setCouncils(prev => [...prev.filter(crm => crm.uf_crm !== holdingCRM.uf_crm), holdingCRM])
        setHoldingCRM(initCRMData)
    }

    const handleSelect = (event) => {
        setHoldingCRMErr({})
        setHoldingCRM(prev => ({ ...prev, uf_crm: event.target.selected }))
    }

    const handleChange = (event) => {
        setHoldingCRMErr({})
        event.target.name === 'nu_crm'
        ? setHoldingCRM(prev => ({
            ...prev,
            [event.target.name]: event.target.value[0] === 't' ? event.target.value : event.target.value.replace(/\D/g, '')
        }))
        : setHoldingCRM(prev => ({ ...prev, [event.target.name]: event.target.value.replace(/\D/g, '') }))
    }

    const handleSaveCRM = async () => {
        const payload = councils.map(crm => {
            return {
                id: crm?.id,
                situation: crm?.situation,
                registration_date: crm?.registration_date,
                council: 'CRM',
                nu_crm: crm?.nu_crm,
                uf_crm: crm?.uf_crm,
                specialties: crm?.specialties
            }
        })

        dispatch(loading())
        try {
            await api.post('/health_place_user/registration/user/create_councils/', { 'councils': payload  })
            dispatch([
                loaded(),
                AddAlert(`Cadastro de CRM`, `CRM salvo com sucesso`, AlertType.SUCCESS),
            ])
            await loged()
            fetchUserCouncils()
        } catch (err) {
            console.error(err)
            dispatch([
                loaded(),
                AddAlert(`Cadastro de CRM`, `Falha ao salvar CRM, tente novamente!`, AlertType.ERROR)
            ])
        }
    }

    return (
    <div>
        <div className='CreateCRM-CRMCol'>
            <Input
                label='Número'
                name='nu_crm'
                action={handleChange}
                value={holdingCRM.nu_crm}
                maxLength='8'
                required={{ erro: holdingCRMErr, message: holdingCRMErr.nu_crm }}
                placeholder='ex: 123456'
            />
            <NewSelect
                options={listas.estados}
                canBeEmpty={false}
                label='UF'
                optionRefKey='sigla'
                optionStrKey='nome'
                selected={holdingCRM.uf_crm}
                onSelect={handleSelect}
                error={holdingCRMErr?.uf_crm}
            />
            <div className='CreateCRM-CRMCol-Btn'>
                <Button type='btn circle' action={handleAddCRMData} title='Adicionar CRM' color={holdingCRM.nu_crm && holdingCRM.uf_crm ? 'primary' : 'secondary'}>
                    <IcoAdd />
                </Button>
            </div>
        </div>

        <p className='CreateCRM-Subtitle CreateCRM-mt'>CRM's cadastrados</p>
        <List
            head={councilHead}
            data={councils.map(instance => ({ ...instance }))}
            noData={''}
            maxHeight='30vh'
            listCustom={(council) => {
                const custom = council
                custom['h_nu_crm'] = council.nu_crm
                custom['h_uf_crm'] = council.uf_crm
                custom['h_actions'] = <div className='CreateCRM-Actions'>
                    <Button
                        color='secondary'
                        type='btn circle'
                        action={() => {
                            setCouncils(prev => prev.filter(instance => instance.nu_crm !== council.nu_crm && instance.uf_crm !== council.uf_crm))
                            setHoldingCRM(council)
                        }}
                        title='Editar'
                    >
                        <IcoPencil />
                    </Button>
                    <Button
                        color='danger'
                        type='btn circle'
                        action={() => setCouncils(prev => prev.filter(instance => instance.nu_crm !== council.nu_crm && instance.uf_crm !== council.uf_crm))
                        }
                        title='Remover'
                    >
                        <IcoBin />
                    </Button>
                </div>
                return custom
            }}
        />
        <div className='CreateCRM-BtnFullWidth'>
            <NewButton
                label="Salvar CRM's"
                disabled={!councils.length}
                onClick={handleSaveCRM}
            />
        </div>
    </div>
    )
}