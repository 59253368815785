import './AssessmentUnit.css';
import React from "react";
import { renderCIAP2Field, renderCID10Field, renderSession } from '../helpers';

export default function AssessmentUnit({ attendance }) {
    const { template_type, selected_cid_ten_fields, selected_ciap_two_fields, appointment_text_fields } = attendance;

    const findFieldData = (fieldId) => {
        const textField = appointment_text_fields.find(field => field.field_type === fieldId);
        if (textField) return { renderFunc: renderSession, name: textField.field_type, data: textField.text };

        const cidField = selected_cid_ten_fields.find(field => field.field_type === fieldId);
        if (cidField) return { renderFunc: renderCID10Field, name: cidField.field_type, data: cidField.selected };

        const ciapField = selected_ciap_two_fields.find(field => field.field_type === fieldId);
        if (ciapField) return { renderFunc: renderCIAP2Field, name: ciapField.field_type, data: ciapField.selected };

        return null;
    };

    return (
        <div className='AssessmentUnit'>
            <div className='AssessmentUnit-Subtitle'><b>Avaliação</b></div>
            <div className='AssessmentUnit-Content' style={{ color: '#aaa' }}>
                {template_type?.assessment?.map((field, idx) => {
                    const fieldData = findFieldData(field.id);
                    return fieldData ? (
                        <div key={idx}>
                            {fieldData.renderFunc(field.name, fieldData.data)}
                        </div>
                    ) : null;
                })}
            </div>
        </div>
    );
}
