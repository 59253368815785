import './PatientsManager.css'
import React, { useState, useRef } from 'react'
import { Paginate } from '../../component/index.js'
import Modal, { MODAL_INIT_PROPS } from '../../newcomponents/modal/modal.js'
import { buildDate } from '../../utils/convertDate.js'
import { getPatientsList } from './http.js'
import PatientFilters from './subcomponents/PatientFilters/PatientFilters.js'
import PatientUnit from './subcomponents/PatientUnit/PatientUnit.js'
import { PatientIcon } from '../../component/icon/patient.js'

const initFilters = { offset: 0, birthday: '', city: '', email: '', name_or_cpf: '' }

export default function PatientsManager() {
	const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
	const filtersRef = useRef(initFilters)
	const [birthdayPeriod, setBirthdayPeriod] = useState({ year: '', month: '', day: '' })
	const [loading, setLoading] = useState(true)
	const [patients, setPatients] = useState([])
	const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 20, totalElements: 0 })
	const [isActive, setIsActive] = useState(true)
	const [verifyDuplicates, setVerifyDuplicates] = useState(false)

	const fetchPatientsList = async params => {
		const builtParams = buildParams()
		params = { ...builtParams, ...params }

		setLoading(true)
		try {
			let response = await getPatientsList(params)
			setPatients(response.data.results)
			setPagination({
				totalElements: response.data.count,
				totalPages: Math.ceil(response.data.count / 20),
				pageNumber: params.offset / 20,
			})
			setLoading(false)
		} catch (err) {
			setLoading(false)
			console.error('fetchAppointmentsReports', err)
		}
	}

	const handleRemoveDuplicatePatient = (basePatient, duplicate) => {
		setPatients(prev => prev.map(patient => 
			patient.id === basePatient.id
			? {
				...patient, duplicate_records: patient.duplicate_records.filter(dup => dup.id !== duplicate.id)
			} : patient
		));
	};

	const buildParams = () => {
		const birthday = buildDate(birthdayPeriod.year, birthdayPeriod.month, birthdayPeriod.day)

		const regexCpf = /\d{3}\.\d{3}\.\d{3}-\d{2}/

		if (regexCpf.test(filtersRef.current.name_or_cpf)) {
			filtersRef.current.name_or_cpf = filtersRef.current.name_or_cpf.replace(/[.-]/g, '')
		}

		return {
			active: true,
			offset: filtersRef.current.offset,
			limit: 20,
			birthday_day: birthdayPeriod.day,
			birthday_month: birthdayPeriod.month,
			birthday_year:birthdayPeriod.year,			
			city: filtersRef.current.city,
			email: filtersRef.current.email,
			medical_record_number: filtersRef.current.medical_record_number,
			mother_name: filtersRef.current.mother_name,
			name_or_cpf:filtersRef.current.name_or_cpf,
			is_active: isActive,
			find_duplicates: verifyDuplicates
		}
	}

	const handleNextPage = event => {
		fetchPatientsList({ offset: event * 20 })
	}

	return (
		<>
			<Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
			<div className='PatientsManager-Content'>
				<PatientFilters
					filtersRef={filtersRef}
					birthdayPeriod={birthdayPeriod}
					setBirthdayPeriod={setBirthdayPeriod}
					setModalInfo={setModalInfo}
					fetchPatientsList={fetchPatientsList}
					setIsActive={setIsActive}
					isActive={isActive}
					verifyDuplicates={verifyDuplicates}
					setVerifyDuplicates={setVerifyDuplicates}
				/>
				<div className='PatientsManager-Separator' />
				<div className='PatientsManager-Body'>
					{
						loading
						? <div className='PatientsManager-Loading'></div>
						: <div>
							{
								patients?.length
								? <div className='PatientsManager-PatientUnitWrapper'>
									{
										patients.map(patient => {
											return <PatientUnit patient={patient} setModalInfo={setModalInfo} handleRemoveDuplicatePatient={handleRemoveDuplicatePatient}/>
									})
									}
									<Paginate data={pagination} action={handleNextPage} />
								</div>
								: <div className='AttendanceHistoryPage-NotFound'>
									<div>
										<PatientIcon />
										<p><b>Nenhum paciente foi encontrado</b></p>
									</div>
								</div>
							}
						</div>
					}
				</div>
			</div>
		</>
	)
}
