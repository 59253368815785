import './CollaboratorsFilters.css'
import React, { useEffect, useRef, useState } from "react";
import { Input } from '../../../../../component';
import IcoCleanFilter from '../../../../../component/icon/cleanFilter';
import NewMultiSelect from '../../../../../newcomponents/form/select/multiselect';
import { getCollaborators } from '../../../../collaborators/http';
import { stringifyCollaboratorOption } from '../../../../../utils/stringifyCollaboratorOption';
import NewSelect from '../../../../../newcomponents/form/select/select';

const initFilters = { offset: 0, name_cpf_or_email__in: [], profile__in: [], registration_status: ''}

const { PROFILE_CHOICES } = require('../../../../../component/form/select/options.json')

export default function CollaboratorsFilters({ filters=null, setFilters=()=>null,fetchCollaborators=() => null, setModalInfo=() => null, buildParams=() => {}, currentHealthPlaceUser=null }) {
    const searchTimeOut = useRef(null)
    const [filtersList, setFiltersList] = useState([])
    const nameCpfOrEmailRef = useRef(null)
    const profileRef = useRef(null)
    const registrationStatusRef = useRef(null)
    const [collaboratorsOptions, setCollaborators] = useState([])
    const searchCollaboratorsTimeout = useRef(null)    

    useEffect(() => {
        fetchCollaboratorsOptions({ offset: 0 })
        updateFilterList()

		try {
            const params = buildParams()
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(() => {
				fetchCollaborators({ offset:0, ...params })
			}, 300)
		} catch (err) {
			console.error('handleSearch', err)
		}
	}, [])

    useEffect(() => {
        handleSearch()
        updateFilterList()
    }, [filters])

    const handleSearch = () => {
        const params = buildParams()
		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(async () => {
				await fetchCollaborators(params)
			}, 300)
		} catch (err) {
			console.error('handleSearch', err)
		}
	}

    const handleSearchCollaboratorsOptions = event => {
        
		if (searchCollaboratorsTimeout.current)
			clearTimeout(searchCollaboratorsTimeout.current)

		searchCollaboratorsTimeout.current = setTimeout(() => {
			fetchCollaboratorsOptions({ name_cpf_or_email: event.target.value })
		}, 400, event.target.value)
	}

    const fetchCollaboratorsOptions = async params => {
		await getCollaborators({
			...params,
			health_place__id: currentHealthPlaceUser?.health_place?.id,
            is_active: true,
			limit: 500,
		})
			.then(res => {
                setCollaborators(res.data.results.map(instance => stringifyCollaboratorOption(instance)))})
			.catch(err => {
				console.error('fetchCollaborators', err)
				setCollaborators([])
			})
	}

	const toggleOption = (loadKey, optionsList, fieldNameRef, optionRef) => {
		const selectedOption = optionsList.find(option => option[fieldNameRef] === optionRef);
		const isExisting = filters[loadKey].find(option => option[fieldNameRef] === optionRef);
        
	
		if (isExisting) {
			setFilters(prev => ({
				...prev,
				[loadKey]: prev[loadKey].filter(option => option[fieldNameRef] !== optionRef)
			}));
		} else {
			setFilters(prev => ({
				...prev,
				[loadKey]: [...prev[loadKey], selectedOption]
			}));
		}
	};

    const handleSelect = (event) => {
        setFilters(prev => ({
            ...prev,
            registration_status: event.target.selected
        }))
    }

    const updateFilterList = () => {        
        const tempFilterList = Object.entries(filters)

        setFiltersList(() => {
            const newFilters = tempFilterList
                .filter(item => item[1] !== '' && Object.entries(item[1]).length > 0 && item[0] !== 'offset')
                .map(item => {
                    switch (item[0]) {
                        case 'name_cpf_or_email__in':
                            return ['Profissional', 'name_cpf_or_email__in']
                        case 'profile__in':
                            return ['Perfil', 'profile__in']
                        case 'registration_status':
                            return ['Status do cadastro', 'registration_status']
                        default:
                            return item[0]
                    }
                })
                return newFilters
        })
    }

    const clearFilter = targetFilter => {
        if (targetFilter === 'all') {
            setFiltersList([])
            setFilters(initFilters)
            
            if (nameCpfOrEmailRef.current) {
                nameCpfOrEmailRef.current.value = [];
            }

            if (profileRef.current) {
                profileRef.current.value = [];
            }

            if (registrationStatusRef.current) {
                registrationStatusRef.current.value = '';
            }

        } else {
            setFiltersList(filtersList.filter(item => item[1] != targetFilter))
            setFilters(prev => ({ ... prev, [targetFilter]: [] }))
    
            switch (targetFilter){
                case 'name_cpf_or_email__in':
                    if (nameCpfOrEmailRef.current) {
                        nameCpfOrEmailRef.current.value = [];
                    }
                    break
                case 'profile__in':
                    if (profileRef.current) {
                        profileRef.current.value = [];
                    }
                    break
                case 'registration_status':
                    if (registrationStatusRef.current) {
                        registrationStatusRef.current.value = '';
                    }
                    break
                }
            }
        }

    return <section className='CollaboratorsFilters'>
        <div>
            <NewMultiSelect
                label='Profissionais'
                filterNode={
                    <div>
                        <Input
                            placeholder='Pesquisar por e-mail, nome ou cpf'
                            action={handleSearchCollaboratorsOptions}
                            actionBlur={() => fetchCollaboratorsOptions()}
                        />
                    </div>
                }
                defaultText={filters?.name_cpf_or_email__in?.length ? `${filters?.name_cpf_or_email__in.length} selecionados` : 'Selecione'}
                onlyDefaultText
                onSelect={({ optionRef }) => toggleOption('name_cpf_or_email__in', collaboratorsOptions, 'health_place_user__id', optionRef)}
                selectedOptions={filters.name_cpf_or_email__in?.map(doctor => doctor.health_place_user__id)}
                optionRefKey='health_place_user__id'
                optionStrKey='str'
                options={collaboratorsOptions}
            />
            <NewMultiSelect
                label='Perfil'
                defaultText={filters?.profile__in?.length ? `${filters?.profile__in?.length} selecionadas` : 'Selecione'}
                onlyDefaultText
                onSelect={({ optionRef }) => toggleOption('profile__in', PROFILE_CHOICES, 'id', optionRef)}
                selectedOptions={filters?.profile__in?.map(specialty => specialty.id)}
                optionRefKey='id'
                options={PROFILE_CHOICES}
            />
            <NewSelect
                label='Status do cadastro'
                id='registrationStatus'
                defaultText='Selecione'
                options={[{ "id": "complete" , "name": "Completo" }, { "id": "incomplete" , "name": "Incompleto" }]}
                onSelect={handleSelect}
                selected={filters.registration_status}
                ref={registrationStatusRef}
            />
        </div>
        <div className='CollaboratorsFilters-SideBarLeft-ActionRow'>
            <div
                onClick={() => clearFilter('all')}
                title="Limpar filtro"
            >
                <IcoCleanFilter />
            </div>
        </div>

        {
            filtersList?.length
            ? <div className='CollaboratorsFilters-ActiveFilters'>
                <p className='CollaboratorsFilters-ActiveFilters-Title'>Filtros Ativos:</p>

                <ul className='CollaboratorsFilters-ActiveFilters-List'>
                    {filtersList.map((item, index) => { 
                        return (
                            <li key={index} className=''>
                                <button onClick={() => clearFilter(item[1])} className='CollaboratorsFilters-ActiveFilters-Button'>
                                    <p className='CollaboratorsFilters-ActiveFilters-Name'>
                                        {item[0]}
                                    </p>
                                    <div className='CollaboratorsFilters-ActiveFilters-Separator'/>
                                    <span className='CollaboratorsFilters-ActiveFilters-Remove'>x</span>
                                </button>
                            </li>
                        )
                    })}
                </ul>
            </div>
            : null
        }

    </section>
}