import './HealthPlaceLegalDataModal.css'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AddAlert, AlertType } from '../../../../../../../../component/alert/actions'
import { Input, MaskCNPJ, MaskTelefone, Select } from '../../../../../../../../component'
import NewButton from '../../../../../../../../newcomponents/button/button'
import { useConfiguration } from '../../../../../../configuration'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import { getCollaborators } from '../../../../../../../collaborators/http'
import { stringifyCollaboratorOption } from '../../../../../../../../utils/stringifyCollaboratorOption'
import { useApp } from '../../../../../../../../layout/App'

const strpHealthPlace = (health_place) => {
	return {
		name: health_place?.name || '',
		cnpj: health_place?.cnpj || '',
		cnes: health_place?.cnes || '',
		technical_responsible: health_place?.technical_responsible || '',
		type: health_place.type || '',
		contact_whatsapp: health_place?.contact_whatsapp || ''
	}
}

const parseHealthPlace = (health_place) => {
	return {
		name: health_place?.name || '',
		cnpj: health_place?.cnpj?.replace(/\D/g, '') || '',
		cnes: health_place?.cnes || '',
		technical_responsible: health_place?.technical_responsible || '',
		type: health_place.type || '',
		contact_whatsapp: health_place?.contact_whatsapp?.replace(/\D/g, '') || '',
	}
}

export default function HealthPlaceLegalDataModal() {
	const dispatch = useDispatch()
	const { institution, saveHealthPlace, setModalProps } = useConfiguration()
	const { currentHealthPlaceUser } = useApp()
	const [holdingHealthPlace, setHoldingHealthPlace] = useState(strpHealthPlace(institution))
	const [validacao, setValidacao] = useState({ cnpj: false, contact_whatsapp: false, name: false, type: false })
	const [collaboratorsOptions, setCollaboratorsOptions] = useState()
	const { listas } = useSelector((state) => state.appState)

	useEffect(() => {
		fetchCollaboratorsOptions({offset: 0})
	}, [])

	const fetchCollaboratorsOptions = async params => {
		await getCollaborators({
			...params,
			health_place__id: currentHealthPlaceUser?.health_place?.id,
            is_active: true,
			limit: 500,
			has_person: true
		})
			.then(res => {
                setCollaboratorsOptions(res.data.results.map(instance => stringifyCollaboratorOption(instance)))})
			.catch(err => {
				console.error('fetchCollaboratorsOptions', err)
				setCollaboratorsOptions([])
			})
	}

	const handleChange = (event) => {
		if (event.target.name === 'technical_responsible') {
			setHoldingHealthPlace(prev => ({
				...prev,
				[event.target.name]: event.target.value.health_place_user__id,
			}))		
		} else {
			setHoldingHealthPlace(prev => ({
				...prev,
				[event.target.name]: event.target.name === 'type' ? event.target.value.id : event.target.value,
			}))
		}
	}

	const handleValidate = (event, v) => {
		if (event) {
			setValidacao({ ...validacao, [v.campo]: v.mensagem })
		}
	}

	const handleSave = async () => {
		const validation = Object.values(validacao).some((valor) => valor !== false && valor !== undefined
		)
		if (validation) {
			dispatch(AddAlert('Erro na validação', 'Campos obrigatórios vazios ou valor inválido', AlertType.ERROR))
			return
		}

		const err = await saveHealthPlace(institution?.id, parseHealthPlace(holdingHealthPlace))
		if (!err){			
			setModalProps(MODAL_INIT_PROPS)
			dispatch(AddAlert('Sucesso', 'Dados alterados com sucesso.', AlertType.SUCCESS));
		} else {
			err.then(erro => {
				if (erro?.response?.data?.cnpj) {
					dispatch(AddAlert('Erro', 'CNPJ já cadastrado, revise os dados.', AlertType.ERROR));
					console.error('Error saving health place:', err);
	
				} else if (erro?.response?.data?.cnes) {
					dispatch(AddAlert('Erro', 'CNES já cadastrado, revise os dados.', AlertType.ERROR));
					console.error('Error saving health place:', err);
	
				} else if (erro?.response?.data) {
					dispatch(AddAlert('Erro', 'Erro ao cadastrar filial, revise os dados.', AlertType.ERROR));
					console.error('Error saving health place:', err);
				} else {
					console.log(erro)
				}
			})
		 }

	}

	return (
		<div className='HealthPlaceLegalDataModal-Container'>
			<div className='HealthPlaceLegalDataModal-PersonalInfo'>
				<div className='HealthPlaceLegalDataModal-Subtitle-Header'>
					<div className='HealthPlaceLegalDataModal-Subtitle-Header-Icon HealthPlaceLegalDataModal-Subtitle-Header-Icon-Blue'>D</div>
					<div className='HealthPlaceLegalDataModal-Subtitle-Header-Title'>Dados Jurídicos</div>
				</div>
			</div>
			<div>
				<div className='HealthPlaceLegalDataModal-Input'>
					<span><b className='HealthPlaceLegalDataModal-Asterisk'>*</b> Nome</span>	
					<Input
						name='name'
						action={handleChange}
						actionBlur={(e, v) => handleValidate(e, v)}
						value={holdingHealthPlace.name}
						required={{ erro: validacao, message: 'Campo obrigatório' }}
					/>
				</div>
				<div className='HealthPlaceLegalDataModal-Input'>
					<span><b className='HealthPlaceLegalDataModal-Asterisk'>*</b> CNPJ</span>	
					<Input
						name='cnpj'
						action={handleChange}
						actionBlur={(e, v) => handleValidate(e, v)}
						value={MaskCNPJ(holdingHealthPlace.cnpj)}
						maxLength='18'
						required={{
							pattern: 'cnpj',
							erro: validacao,
							message: validacao.cnpj === 'invalido' ? 'Preencha CNPJ valido' : 'Campo obrigatório',
						}}
					/>
				</div>
				<div>
					<span>CNES</span>	
					<Input
						name='cnes'
						action={handleChange}
						actionBlur={(e, v) => handleValidate(e, v)}
						value={holdingHealthPlace.cnes}
						maxLength='7'
					/>
				</div>
				<div className='HealthPlaceLegalDataModal-2Col-Form'>
					<div className='HealthPlaceLegalDataModal-Input'>
						<span><b className='HealthPlaceLegalDataModal-Asterisk'>*</b> Tipo</span>
						<Select
							name='type'
							action={handleChange}
							actionClose={(e, v) => handleValidate(e, v)}
							options={listas.tipos}
							selected={holdingHealthPlace.type || {}}
							required={{ erro: validacao, message: 'Campo obrigatório' }}
						/>
					</div>
					<div className='HealthPlaceLegalDataModal-Input'>
						<span>Responsável Técnico</span>
						<Select
							name='technical_responsible'
							action={handleChange}
							actionClose={(e, v) => handleValidate(e, v)}
							options={collaboratorsOptions}
							selected={collaboratorsOptions?.find(colab => colab.health_place_user__id === holdingHealthPlace.technical_responsible) || {}}
						/>
					</div>
				</div>
				<div className='HealthPlaceLegalDataModal-Input'>
					<span><b className='HealthPlaceLegalDataModal-Asterisk'>*</b> Contato (WhatsApp)</span>
					<Input
						name='contact_whatsapp'
						action={handleChange}
						actionBlur={(e, v) => handleValidate(e, v)}
						value={MaskTelefone(holdingHealthPlace.contact_whatsapp)}
						maxLength='15'
						required={{ erro: validacao, message: 'Campo obrigatório' }}
					/>
				</div>
			</div>
			<div className='HealthPlaceLegalDataModal-SaveBtn'>
				<NewButton
					onClick={handleSave}
					label='Salvar'
				/>
			</div>
		</div>
	)
}
