import './PregnanciesManager.css'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import NewButton, { ButtonModel } from '../../../../../newcomponents/button/button'
import { AddAlert, AlertType, Input, Paginate, RadioButton } from '../../../../../component'
import Modal, { MODAL_INIT_PROPS } from '../../../../../newcomponents/modal/modal'
import { getParams } from '../../../../../utils'
import api from '../../../../../helpers/api'
import { buildDate } from '../../../../../utils/convertDate.js'
import NewSelect from '../../../../../newcomponents/form/select/select.js'
import NewThousandDaysProject from './subcomponents/NewThousandDaysProject/NewThousandDaysProject.js'
import AlertRowText, { AlertRowTextType } from '../../../../../component/alertRowText/alertRowText.js'
import GestationUnit from './subcomponents/GestationUnit/GestationUnit.js'
import { getYearsStart1900 } from '../../../../../utils/getYearsStart1900.js'

const data = require('../../../data/ThousandDaysPage.json')
const defaultOptions = require('../../../../../component/form/select/options.json')

const initFilters = { offset: 0, birthday: '', city: '', email: '', name_or_cpf: '' }

export default function PregnanciesManager() {
	const dispatch = useDispatch()
	const [modal, setModal] = useState(MODAL_INIT_PROPS)
	const [projects, setProjects] = useState([])
	const [loading, setLoading] = useState(true)
	const [filters, setFilters] = useState(data.filters.initstate)
	const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })
	const [birthdayPeriod, setBirthdayPeriod] = useState({ year: '', month: '', day: '' })

	const filtersRef = useRef(initFilters)
	const searchTimeOut = useRef(null)

	const buildParams = () => {
		const birthday = buildDate(birthdayPeriod.year, birthdayPeriod.month, birthdayPeriod.day)

		return {
			active: true,
			offset: filtersRef.current.offset,
			limit: 20,
			birthday: birthday,
			city: filtersRef.current.city,
			email: filtersRef.current.email,
			name_or_cpf: filtersRef.current.name_or_cpf,
		}
	}

	useEffect(() => {
		fetchDoctorProjects({ offset: 0 })
	}, [])

	const fetchDoctorProjects = (params = {}) => {
		params = { ...params, limit: 20 }
		setLoading(true)
		api
			.get(`/thousanddays/consultation/${getParams(params)}`)
			.then(res => {
				setLoading(false)
				setProjects(res.data.results)
				setPagination({
					totalElements: res.data.count,
					totalPages: Math.ceil(res.data.count / 20),
					pageNumber: params.offset / 20,
				})
			})
			.catch(() => {
				setLoading(false)
				setProjects([])
				dispatch(AddAlert('Projeto 1000 Dias', 'Falha ao obter projetos', AlertType.ERROR))
			})
	}

	const handlePagination = e => {
		fetchDoctorProjects({ offset: e * 20 })
	}

	const handleSearch = target => {
		filtersRef.current = { ...filtersRef.current, [target.name]: target.value }
		const params = buildParams()
		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(async () => {
				await fetchDoctorProjects(params)
			}, 300)
		} catch (err) {
			console.error('handleSearch', err)
		}
	}

	const handleSelect = event => {
		setBirthdayPeriod(prevData => ({
			...prevData,
			[`${event.target.id}`]: event.target.selected,
		}))
	}

	useEffect(() => {
		const params = buildParams()
		if (params.birthday) {
			try {
				searchTimeOut.current && clearTimeout(searchTimeOut.current)
				searchTimeOut.current = setTimeout(async () => {
					await fetchDoctorProjects(params)
				}, 300)
			} catch (err) {
				console.error('handleSearch', err)
			}
		}
	}, [birthdayPeriod])

	return (
		<>
			<Modal {...modal} dismissFn={() => setModal(MODAL_INIT_PROPS)} />
			<div className='ThousandDaysPage-Content'>
				<section className='ThousandDaysPage-Content-SideBarLeft'>
					<div className='ThousandDaysPage-BtnFullWidth'>
						<NewButton
							label='Adicionar gestação'
							onClick={() =>
								setModal({
									open: true,
									title: 'Adicionar gestante',
									content: (
										<NewThousandDaysProject
											setModal={setModal}
											fetchDoctorProjects={fetchDoctorProjects}
										/>
									),
								})
							}
							model={ButtonModel.SECONDARY}
						/>
					</div>
					<div className='ThounsandDaysPageFilter-Separator' />
					<div>
						<Input
							label='Pesquisar paciente'
							name='name_or_cpf'
							action={event => handleSearch(event.target)}
							autoComplete='false'
							placeholder='Filtrar por Nome | CPF | RG'
						/>
						<Input
							label='Contato'
							name='email'
							autoComplete='false'
							action={event => handleSearch(event.target)}
							placeholder='Filtrar por email'
						/>
						<b>Data de Nascimento</b>
						<div className='PatientsFilters-Birthday-Select-Date'>
							<NewSelect
								id='day'
								defaultText='Dia'
								options={defaultOptions.days}
								onSelect={handleSelect}
								selected={birthdayPeriod?.day}
							/>
							<NewSelect
								id='month'
								defaultText='Mês'
								options={defaultOptions.months}
								onSelect={handleSelect}
								selected={birthdayPeriod?.month}
							/>
							<NewSelect
								id='year'
								defaultText='Ano'
								options={getYearsStart1900()}
								onSelect={handleSelect}
								selected={birthdayPeriod?.year}
							/>
						</div>
						<Input
							name='city'
							label='Localização'
							action={event => handleSearch(event.target)}
							placeholder='Filtrar por cidade'
						/>
						<p><b>Status de projeto</b></p>
						<RadioButton
							name='filtros'
							action={event => setFilters(event.target.value)}
							checked={filters}
							options={data.filters.options}
						/>
					</div>
				</section>
				<div className='ThousandDaysPage-Separator' />
				<div className='ThousandDaysPage-Body'>
					<p><b>Gestantes</b></p>
					{
						loading
						? <div className='PregnanciesManager-Loading'></div>
						: <div>
							{
								projects?.length
								? <div className='PatientsManager-PatientUnitWrapper'>
									{
										projects.map(projectCursor => <GestationUnit project={projectCursor} />)
									}
									<Paginate data={pagination} action={handlePagination} />
								</div>
								: <AlertRowText content='Nenhuma gestação encontrada' type={AlertRowTextType.INFO} outlined />
							}
						</div>
					}
				</div>
			</div>
		</>
	)
}
