import React from 'react'


export default function PregnantIcon({ style }) {
  return (
    <svg style={style} className='icon-pregnant' viewBox="0 0 512.000000 512.000000">
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M2173 4599 c-176 -22 -300 -78 -409 -181 -222 -211 -298 -518 -247
        -1003 24 -230 23 -617 -1 -750 -36 -197 -96 -332 -177 -396 -66 -52 -48 -98
        56 -145 77 -35 159 -48 229 -35 64 12 169 56 188 78 10 12 15 -8 30 -107 16
        -111 51 -230 123 -420 23 -61 31 -243 15 -351 -22 -155 -86 -322 -174 -458
        -25 -40 -46 -80 -46 -90 0 -25 32 -51 64 -51 22 0 35 13 82 85 93 144 163 318
        185 468 23 157 10 152 93 35 110 -154 224 -287 383 -445 117 -117 149 -143
        172 -143 31 0 61 27 61 56 0 11 -53 72 -131 149 -274 273 -449 504 -583 773
        -121 242 -161 470 -137 788 14 188 37 278 82 318 29 26 36 28 83 21 28 -3 98
        -25 156 -47 239 -93 321 -98 421 -26 38 27 280 -186 332 -292 32 -66 46 -135
        38 -187 l-6 -38 -85 -7 c-70 -6 -88 -11 -102 -29 -22 -26 -22 -38 -2 -67 14
        -20 23 -22 118 -22 124 0 227 -24 324 -75 147 -77 243 -177 318 -330 59 -120
        78 -217 72 -360 -8 -181 -69 -327 -192 -462 -100 -109 -205 -171 -355 -209
        -52 -14 -100 -29 -104 -33 -15 -15 -11 -61 6 -79 10 -10 31 -15 57 -14 64 3
        217 58 303 109 69 42 183 145 235 213 60 78 116 199 148 320 26 96 23 299 -5
        405 -74 278 -277 500 -535 587 l-80 27 -1 98 c-1 108 -13 148 -73 243 -70 111
        -159 193 -344 316 -69 46 -148 104 -175 129 l-48 44 70 1 c142 1 276 52 372
        142 72 69 105 139 110 236 5 95 15 122 53 144 38 21 40 59 6 99 -60 70 -92
        217 -104 466 -8 165 -10 174 -41 237 -73 148 -242 240 -491 266 -110 11 -248
        11 -337 -1z m374 -119 c106 -17 190 -48 196 -70 10 -38 -12 -143 -43 -206 -35
        -72 -137 -174 -217 -217 -28 -15 -104 -41 -168 -57 -193 -49 -261 -88 -301
        -174 -75 -161 44 -322 249 -334 85 -5 117 9 117 53 0 34 -30 52 -97 58 -185
        16 -242 161 -93 237 19 10 88 32 154 49 65 17 146 44 180 61 173 85 282 226
        333 429 l8 34 30 -54 c28 -52 29 -60 36 -229 8 -188 26 -299 65 -395 14 -33
        25 -62 26 -65 1 -3 -9 -27 -21 -54 -16 -32 -25 -75 -28 -130 -7 -113 -45 -175
        -144 -235 -79 -47 -251 -73 -361 -55 -27 5 -38 12 -42 29 -13 52 -62 71 -98
        38 -26 -23 -22 -57 14 -125 34 -65 120 -159 206 -226 42 -33 50 -43 37 -48
        -33 -13 -123 5 -230 46 -142 55 -198 70 -261 70 l-51 0 0 138 c-1 208 -24 304
        -177 736 -91 257 -104 389 -50 503 42 87 143 151 292 183 99 21 336 27 439 10z
        m-859 -507 c7 -38 33 -126 58 -198 160 -456 172 -502 181 -670 6 -116 -6 -208
        -47 -355 -30 -107 -50 -253 -50 -359 0 -58 -1 -61 -42 -96 -77 -66 -133 -89
        -218 -90 -112 0 -118 4 -76 63 57 78 96 181 128 332 30 143 36 516 13 769 -21
        232 -17 488 9 599 10 39 21 72 25 72 3 0 12 -30 19 -67z"/>
        <path d="M2272 2548 c-17 -17 -16 -34 28 -495 43 -453 47 -478 84 -576 50
        -133 98 -191 341 -412 124 -112 166 -145 187 -145 33 0 63 39 53 69 -3 11 -73
        81 -155 156 -267 244 -316 311 -346 471 -8 43 -32 265 -54 492 -22 227 -44
        421 -50 432 -12 23 -68 28 -88 8z"/>
      </g>
    </svg>
  )
}
