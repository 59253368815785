import './ToggleAppointmentField.css'
import React, { useRef } from "react";
import { useAttendance } from "../../../../consultation";
import { Input } from "../../../../../../component";
import NewMultiSelect from "../../../../../../newcomponents/form/select/multiselect";
import { getAppointmentCIAPTwoField, getAppointmentCIDTenField, putAppointmentCIAPTwoField, putAppointmentCIDTenField } from '../../../../http';
import PasteIco from '../../../../../../component/icon/paste';
import PastToggleAppointmentField from './PastToggleAppointmentField/PastToggleAppointmentField';

function uniqueByCode(items) {
    const itemMap = new Map();
    for (const item of items) {
        if (!itemMap.has(item.code)) {
            itemMap.set(item.code, item);
        }
    }
    return Array.from(itemMap.values());
}

export default function ToggleAppointmentField({ field_type, name }) {
    const searchTimeout = useRef(null);

    const {
        setAttendance,
        attendance,
        attendanceExtraData: { cids, ciaps },
        fetchCIDTenCodes,
        fetchCIAPTwentyCodes,
        isEditable,
        savePartialAppointmentCIAPOrCid,
        setModalInfo,
    } = useAttendance();

    const fieldTypeMappings = {
        'reason_for_consultation': {
            options: ciaps,
            fetchCodesOptionsFn: fetchCIAPTwentyCodes,
            selectedOptionsKey: 'selected_ciap_two_fields',
            saveCodeFn: putAppointmentCIAPTwoField,
            getPastFilledCodesFn: getAppointmentCIAPTwoField,
        },
        'intervention_clinical_procedures': {
            options: ciaps,
            fetchCodesOptionsFn: fetchCIAPTwentyCodes,
            selectedOptionsKey: 'selected_ciap_two_fields',
            saveCodeFn: putAppointmentCIAPTwoField,
            getPastFilledCodesFn: getAppointmentCIAPTwoField,
        },
        'related_diseases_and_problems': {
            options: cids,
            fetchCodesOptionsFn: fetchCIDTenCodes,
            selectedOptionsKey: 'selected_cid_ten_fields',
            saveCodeFn: putAppointmentCIDTenField,
            getPastFilledCodesFn: getAppointmentCIDTenField
        },
    };

    const mapping = fieldTypeMappings[field_type];

    if (!mapping) {
        console.error(`Invalid field_type: ${field_type}`);
        return null;
    }

    const { 
        options: optionsList, 
        fetchCodesOptionsFn, 
        selectedOptionsKey, 
        saveCodeFn, 
        getPastFilledCodesFn,
    } = mapping;

    // Get the active fields set from attendance state
    const activeFieldsSet = attendance[selectedOptionsKey] || [];
    const appointmentId = attendance.id;

    // Find the active field object for the current field_type
    const activeFieldObject = activeFieldsSet.find(field => field.field_type === field_type);
    const selectedOptions = activeFieldObject?.selected || [];

    // Handle search input with debounce
    const handleSearchCodes = (search) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current);

        searchTimeout.current = setTimeout(() => {
            fetchCodesOptionsFn({ search });
        }, 400);
    };

    // Toggle option selection
    const toggleOption = async ({ optionRef }) => {
        const selectedOption = uniqueByCode([...selectedOptions, ...optionsList]).find(({ code }) => code === optionRef);

        if (!selectedOption) {
            console.error(`Option with code ${optionRef} not found`);
            return;
        }

        // Create a new copy of the active fields set
        let updatedActiveFieldsSet = [...activeFieldsSet];

        const activeFieldIndex = updatedActiveFieldsSet.findIndex(field => field.field_type === field_type);

        let updatedSelected = []
        if (activeFieldIndex !== -1) {
            // Field exists, update the selected options
            const currentField = updatedActiveFieldsSet[activeFieldIndex];
            const isSelected = currentField.selected.some(({ code }) => code === optionRef);

            updatedSelected = isSelected
                ? currentField.selected.filter(({ code }) => code !== optionRef)
                : [...currentField.selected, selectedOption];

            // Replace the field object with the updated one
            updatedActiveFieldsSet[activeFieldIndex] = {
                ...currentField,
                selected: updatedSelected,
            };
        } else {
            updatedSelected = [selectedOption]
            // Field does not exist, create a new one
            updatedActiveFieldsSet.push({
                appointment: appointmentId,
                field_type,
                selected: updatedSelected,
            });
        }

        // Update the attendance state
        setAttendance(prev => ({
            ...prev,
            [selectedOptionsKey]: updatedActiveFieldsSet,
        }));
        
        updatedSelected = updatedSelected.map(({code}) => code)

        await savePartialAppointmentCIAPOrCid(field_type, updatedSelected, saveCodeFn)
        // Fetch the latest options (if needed)
        fetchCodesOptionsFn();
    };

    // Prepare options for the NewMultiSelect component
    const combinedOptions = uniqueByCode([...selectedOptions, ...optionsList]).map(instance => ({
        ...instance,
        title: `${instance.code} | ${instance.title}`,
    }));

    return (
    <div className="">
        <div className='ToggleAppointmentField-NewMultiSelect'>
            {name && <div 
                className='ToggleAppointmentField-NewMultiSelect-Subtitle'
            >
                {name}
                <div
                    title='Preenchimentos anteriores'
                    onClick={() => setModalInfo(prev => ({
                        ...prev,
                        open: true,
                        title: `${name} - Preenchimentos anteriores`,
                        content: <PastToggleAppointmentField
                            field_type={field_type}
                            attendance={attendance}
                            getPastFilledCodesFn={getPastFilledCodesFn}
                        />
                    }))}
                >
                    <PasteIco />
                </div>
                
            </div>
            }
            <NewMultiSelect
                filterNode={
                    <div className='ToggleAppointmentField-NewMultiSelect-FilterNode'>
                        <Input
                            placeholder='Pesquisar por Código ou Título'
                            action={({ target: { value } }) => handleSearchCodes(value)}
                            actionFocus={() => fetchCodesOptionsFn()}
                        />
                    </div>
                }
                defaultText='Selecione'
                onlyDefaultText
                onSelect={toggleOption}
                selectedOptions={selectedOptions.map(({ code }) => code)}
                optionRefKey='code'
                optionStrKey='title'
                options={combinedOptions}
                disabled={!isEditable}
            />
        </div>
        <div className='ToggleAppointmentField-NewMultiSelect-SelectedOptionsRow'>
            {
                selectedOptions.map(({code, title}, idx) => <div >
                    <div key={idx} className='ToggleAppointmentField-NewMultiSelect-SelectedOptionsRow-Name'>{code} | {title}</div>
                    <div 
                        className='ToggleAppointmentField-NewMultiSelect-SelectedOptionsRow-CloseBox'
                        onClick={() => toggleOption({ "optionRef": code })}
                    >
                        x
                    </div>
                </div>)
            }
        </div>
    </div>
    );
}
