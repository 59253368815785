import './PersonalDataConfig.css'
import { useApp } from "../../../../../../../layout/App"
import { useConfiguration } from '../../../../../configuration'
import PersonalDataConfigModal from './PersonalDataConfigModal/PersonalDataConfigModal'
import HorizontalField from '../../../../../../../component/HorizontalField/HorizontalField'
import { useState } from 'react'

export default function PersonalDataConfig() {
    const { currentHealthPlaceUser, updateMenu } = useApp()
    const { setModalProps } = useConfiguration()
    const [configProps, setConfigProps] = useState({
        min_age_allowed: currentHealthPlaceUser?.min_age_allowed,
        max_age_allowed: currentHealthPlaceUser?.max_age_allowed,
        enabled_thousand_days: currentHealthPlaceUser?.enabled_thousand_days,
        enabled_preceptorship: currentHealthPlaceUser?.enabled_preceptorship,
    })

    const updateConfig = (newHealthPlaceUser) => {
        setConfigProps({
            min_age_allowed: newHealthPlaceUser?.data?.min_age_allowed,
            max_age_allowed: newHealthPlaceUser?.data?.max_age_allowed,
            enabled_thousand_days: newHealthPlaceUser?.data?.enabled_thousand_days,
            enabled_preceptorship: newHealthPlaceUser?.data?.enabled_preceptorship,
        })

        updateMenu(newHealthPlaceUser.data)
    }

    return <section>
        <div className='PersonalDataConfig-Header'>
            <span className='PersonalDataConfig-Header-Icon'>M</span>
            <p className='PersonalDataConfig-Header-Title'>Módulos</p>
        </div>
        <div>
            <HorizontalField 
                label='Atendimento'
                content={`idades entre ${configProps?.min_age_allowed || 0} à ${configProps?.max_age_allowed || 100}`}
            />
            <HorizontalField 
                label='1000 dias'
                content={configProps?.enabled_thousand_days ? 'Ativado' : 'Desativado'}
            />
            <HorizontalField 
                label='Preceptoria'
                content={configProps?.enabled_preceptorship ? 'Ativado' : 'Desativado'}
            />
        </div>
        <div className='PersonalDataConfig-Edit'>
				<div
                className='PersonalDataConfig-Edit-Btn'
					onClick={() =>
						setModalProps(prev => ({
							...prev,
							open: true,
							title: 'Módulos',
							content: <PersonalDataConfigModal fetchExtraAction={updateConfig} configProps={configProps}/>,
							dismissText: '',
						}))
					}
				>
                <b>Editar dados</b>
                </div>
			</div>
    </section>
}