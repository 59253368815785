import './Assessment.css'
import React, { useRef, useState } from 'react'
import ToothIcon from '../../../../../../../../../../../../../../component/icon/tooth'
import Portal from '../../../../../../../../../../../../../../component/potal/portal'
import useOutsideClick from '../../../../../../../../../../../../../../component/useOusideClick/useoutsideclick'
import Button, {
	ButtonModel,
} from '../../../../../../../../../../../../../../newcomponents/button/button'
import { useDentistAppointment } from '../../DentistAppointment'
import { useThousandDays } from '../../../../../../ThousandDaysSection'

const {
	ToothRows,
	ToothObs,
} = require('../../DentistAppointment.json')

function getToothPosition(toothId) {
	const position = {
		vertical: '',
		horizontal: '',
		offset: '-100%',
	}

	if (
		toothId === '18' ||
		toothId === '28' ||
		toothId === '48' ||
		toothId === '38'
	) {
		position.offset = '2.5%'
	}

	for (let i = 0; i < ToothRows.length; i++) {
		if (ToothRows[i].includes(toothId)) {
			if (i === 0 || i === 1) {
				position.vertical = 'top'
			} else {
				position.vertical = 'bottom'
			}

			if (i === 0 || i === 2) {
				position.horizontal = 'left'
			} else {
				position.horizontal = 'right'
			}
			return position
		}
	}

	return null
}

const isSomeFilled = (assessment, toothKey) =>
	assessment[`_${toothKey}`].situation !== 'NOT_EVALUATED' ||
	assessment[`_${toothKey}`].procedures_performed

const ToothInstance = ({ toothKey, selectedLanguage }) => {
	const [isActive, setIsActive] = useState(false)
	const [dropdownStyles, setDropdownStyles] = useState({})
	const outsideListenerRef = useRef()
	const { assessment, setAssessment } = useDentistAppointment()

	const {
		Tooth_Obs_Options,
		Tooth_Map_Names_Options,
		Tooth,
		Close,
		Proceduresperformed,
	} = require('../../../../../../../../../../../../../data/translation.json')[
		selectedLanguage
	]

	const tooth_obs_options = ToothObs.map(option => ({
		id: option.id,
		name: Tooth_Obs_Options[option.id],
	}))

	useOutsideClick(outsideListenerRef, e => {
		if (!e && isActive) setIsActive(false)
	})

	const handleDropdown = ({ event, value }) => {
		if (event) {
			const div = event.target
			let rect = div.getBoundingClientRect(),
				x = rect.left,
				y = rect.top,
				h = rect.height
			let windowHeight = window.innerHeight

			let dropdownDimension =
				Math.ceil(y + h) + 300 >= windowHeight
					? { left: x, bottom: windowHeight - y, top: 'auto' }
					: { left: x, top: y + h }

			setDropdownStyles({
				...dropdownDimension,
				position: 'absolute',
				width: '360px',
				zIndex: 10,
				padding: '1rem',
				backgroundColor: 'white',
				boxShadow: 'rgba(149, 157, 165, 0.2) 0px 6px 12px',
				borderRadius: '4px',
			})
		}
		setIsActive(value)
	}

	const position = getToothPosition(toothKey)

	const handleSelect = event => {
		setAssessment(prev => ({
			...prev,
			[event.target.id]: {
				...prev[event.target.id],
				situation: event.target.value,
			},
		}))
	}

	const handleChange = event => {
		setAssessment(prev => ({
			...prev,
			[event.target.name]: {
				...prev[event.target.name],
				procedures_performed: event.target.value,
			},
		}))
	}

	return (
		<div
			id={`ToothRef-${toothKey}`}
			style={{ position: 'relative' }}>
			<div
				className={`DentistAppointmentAssessment-ToothInstance ${
					isSomeFilled(assessment, toothKey)
						? 'DentistAppointmentAssessment-ToothInstanceActive'
						: ''
				}`}
				onClick={event => handleDropdown({ event: event, value: !isActive })}>
				{position?.vertical === 'top' ? (
					<p className='DentistAppointmentAssessment-ToothInstance-Str'>
						{toothKey}
					</p>
				) : null}
				<ToothIcon
					width='30px'
					height='30px'
				/>
				{position?.vertical === 'bottom' ? (
					<p className='DentistAppointmentAssessment-ToothInstance-Str'>
						{toothKey}
					</p>
				) : null}
			</div>
			<Portal name='select'>
				{isActive && (
					<div
						ref={outsideListenerRef}
						style={dropdownStyles}>
						<b>
							{Tooth} {toothKey}
						</b>
						<p>{Tooth_Map_Names_Options[toothKey]}</p>
						<select
							id={`_${toothKey}`}
							value={assessment[`_${toothKey}`].situation}
							onChange={handleSelect}
							className='DentistAppointmentAssessment-ToothInstance-SelectOptions'>
							{tooth_obs_options.map(obs => (
								<option
									value={obs.id}
									key={obs.id}>
									{obs.name}
								</option>
							))}
						</select>
						<div>
							<textarea
								name={`_${toothKey}`}
								placeholder={Proceduresperformed}
								className='DentistAppointmentAssessment-ToothInstance-Textarea'
								value={assessment[`_${toothKey}`].procedures_performed}
								onChange={handleChange}
								rows='5'
							/>
						</div>
						<div className='DentistAppointmentAssessment-ToothInstance-BtnRow'>
							<Button
								label={Close}
								onClick={() => setIsActive(false)}
								model={ButtonModel.SECONDARY}
							/>
						</div>
					</div>
				)}
			</Portal>
		</div>
	)
}

export default function DentistAppointmentAssessment() {
	const { selectedLanguage } = useThousandDays()
	return (
		<div className='DentistAppointmentAssessment'>
			<div>
				<div className='DentistAppointmentAssessment-TeethMap'>
					{ToothRows.map((teethKeys, idx) => (
						<div className='DentistAppointmentAssessment-TeethRow'>
							{teethKeys.map(key => (
								<ToothInstance
									key={idx}
									toothKey={key}
									selectedLanguage={selectedLanguage}
								/>
							))}
						</div>
					))}
				</div>
			</div>
			<div className='DentistAppointmentAssessment-Separator' />
		</div>
	)
}
