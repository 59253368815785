import './HealthPlaceInstitutionalLogoModal.css'
import React, { useState } from "react"
import { useDispatch } from 'react-redux'
import { Cropper } from 'react-cropper'
import Button from '../../../../../../../../newcomponents/button/button'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import { AddAlert, AlertType } from '../../../../../../../../component'
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions'
import { putHealthPlaceLogo } from '../../../../../../http'
import ZoomInIco from '../../../../../../../../component/icon/zoom-in'
import ZoomOutIco from '../../../../../../../../component/icon/zoom-out'
import LeftIco from '../../../../../../../../component/icon/left'
import RightIco from '../../../../../../../../component/icon/right'
import DownIco from '../../../../../../../../component/icon/down'
import UpIco from '../../../../../../../../component/icon/up'

export default function HealthPlaceInstitutionalLogoModal({ setModalInfo=() => null, initHoldingHealthPlace=null, fetchExtraAction= async () => null }) {
    const dispatch = useDispatch()
    const defaultUser = require('../../../../../../../../media/DefaultUser.png')
    const [cropper, setCropper] = useState();

    const handleSubmit = async () => {
        const base64_avatar = cropper?.getCroppedCanvas()?.toDataURL()
        if (base64_avatar && typeof cropper !== "undefined") {
            dispatch(loading())
            try {
                await putHealthPlaceLogo({ "base64_logo": base64_avatar })
                await fetchExtraAction()
                dispatch(AddAlert('Editar Logo', 'Logo salva com sucesso', AlertType.SUCCESS))
                setModalInfo(MODAL_INIT_PROPS)
            } catch (err) {
                dispatch(AddAlert('Editar Logo', 'Falha ao salvar logo, revise os dados', AlertType.ERROR));
                console.error('HealthPlaceInstitutionalLogoModal ~ handleSubmit', err);
            }
            dispatch(loaded())
        }
    }

    return <div className='HealthPlaceInstitutionalLogoModal'>
        <div className='HealthPlaceInstitutionalLogoModal-PersonalInfo'>
            <div className='HealthPlaceInstitutionalLogoModal-Subtitle-Header'>
                <div className='HealthPlaceInstitutionalLogoModal-Subtitle-Header-Icon HealthPlaceInstitutionalLogoModal-Subtitle-Header-Icon-Purple'>L</div>
                <div className='HealthPlaceInstitutionalLogoModal-Subtitle-Header-Title'>Logo</div>
            </div>
        </div>
        <div>
            <div style={{ width: "100%" }}>
                <Cropper
                    style={{ height: 320, width: "100%" }}
                    zoomTo={0.5}
                    initialAspectRatio={1}
                    preview=".HealthPlaceInstitutionalLogoModal-img-preview"
                    src={initHoldingHealthPlace?.base64_avatar || defaultUser}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                    aspectRatio={1}
                    guides={true}
                />
            </div>
            <br style={{ clear: "both" }} />
            <div className='HealthPlaceInstitutionalLogoModal-ActionRow'>
                <button className='HealthPlaceInstitutionalLogoModal-Action' onClick={() => {if (cropper) cropper.zoom(0.1)}}><ZoomInIco /></button>
                <button className='HealthPlaceInstitutionalLogoModal-Action' onClick={() => {if (cropper) cropper.zoom(-0.1)}}><ZoomOutIco /></button>

                <button className='HealthPlaceInstitutionalLogoModal-Action' onClick={() => {if (cropper) cropper.move(10, 0)}}><LeftIco /></button>
                <button className='HealthPlaceInstitutionalLogoModal-Action' onClick={() => {if (cropper) cropper.move(-10, 0)}}><RightIco /></button>
                <button className='HealthPlaceInstitutionalLogoModal-Action' onClick={() => {if (cropper) cropper.move(0, -10)}}><DownIco /></button>
                <button className='HealthPlaceInstitutionalLogoModal-Action' onClick={() => {if (cropper) cropper.move(0, 10)}}><UpIco /></button>
            </div>
        </div>

        <div className='HealthPlaceInstitutionalLogoModal-Btn-Box'>
            <Button 
                onClick={handleSubmit}
                label='Salvar'
            />
        </div>
    </div>
}