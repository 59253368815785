import React from 'react'

export default function IcoQuickly({ style }) {
  return (
    <svg style={style} className='icon-quickly' viewBox="0 0 512 512">
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M3020 4203 c-278 -42 -457 -101 -654 -217 -409 -241 -694 -640 -787
        -1103 -34 -169 -38 -420 -9 -588 116 -686 615 -1205 1306 -1357 135 -29 482
        -33 616 -5 340 69 613 209 849 435 245 233 405 525 481 872 20 92 22 133 22
        325 0 246 -12 322 -80 524 -138 414 -451 769 -842 958 -227 109 -474 164 -726
        162 -83 -1 -162 -3 -176 -6z m401 -329 c483 -81 889 -429 1043 -895 46 -139
        66 -266 66 -419 0 -363 -132 -683 -390 -940 -257 -257 -577 -390 -940 -390
        -296 0 -566 90 -802 267 -336 254 -528 640 -528 1063 0 363 133 684 390 940
        200 200 442 326 715 374 111 20 330 20 446 0z"/>
        <path d="M3120 3548 c-18 -13 -43 -36 -54 -51 -20 -28 -21 -40 -24 -490 -2
        -295 1 -475 7 -497 8 -26 72 -96 253 -277 198 -196 251 -243 283 -253 115 -34
        225 77 194 195 -9 33 -47 76 -215 245 l-203 205 -3 422 c-3 410 -4 422 -24
        450 -35 48 -82 73 -134 73 -32 0 -57 -7 -80 -22z"/>
        <path d="M1010 3989 c-14 -6 -36 -20 -48 -32 -78 -72 -68 -185 21 -251 27 -20
        41 -21 392 -24 348 -3 366 -2 404 17 112 58 113 224 0 281 -36 19 -59 20 -391
        19 -210 0 -363 -4 -378 -10z"/>
        <path d="M575 3346 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 24 -7
        160 -10 394 -8 342 3 358 4 384 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -26 20 -41 21 -394 23 -290 2 -373 0 -397 -11z"/>
        <path d="M370 2709 c-14 -6 -36 -20 -48 -32 -78 -72 -68 -185 21 -251 28 -21
        37 -21 512 -21 473 0 484 0 511 21 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 20 -40 21 -499 23 -300 1 -481 -2 -497 -8z"/>
        <path d="M575 2066 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 24 -7
        160 -10 394 -8 342 3 358 4 384 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -26 20 -41 21 -394 23 -290 2 -373 0 -397 -11z"/>
        <path d="M1010 1429 c-14 -6 -36 -20 -48 -32 -78 -72 -68 -185 21 -251 27 -20
        41 -21 392 -24 348 -3 366 -2 404 17 112 58 113 224 0 281 -36 19 -59 20 -391
        19 -210 0 -363 -4 -378 -10z"/>
      </g>
    </svg>
  )
}
