export const userRoles = {
    'ADMINISTRATOR': { 
      title: 'Administrador', 
      value: '5', 
      val: 'ADMINISTRATOR', 
      schema: { "enabled_thousand_days": false, "is_health_professional": false },
    },
    'DOCTOR': { 
      title: 'Médico', 
      value: '4', 
      val: 'DOCTOR', 
      schema: { "enabled_thousand_days": false, "is_health_professional": true }, 
    },
    'DENTIST': { 
      title: 'Dentista', 
      value: '8', 
      val: 'DENTIST', 
      schema: { "enabled_thousand_days": false, "is_health_professional": true } 
    },
    'PSYCHOLOGIST': { 
      title: 'Psicólogo', 
      value: '9', 
      val: 'PSYCHOLOGIST', 
      schema: { "enabled_thousand_days": false, "is_health_professional": true } 
    },
    'NUTRITIONIST': { 
      title: 'Nutricionista', 
      value: '10', 
      val: 'NUTRITIONIST', 
      schema: { "enabled_thousand_days": false, "is_health_professional": true } 
    },
    'NURSE': {
      title: 'Enfermagem',
      value: '11',
      val: 'NURSE',
      schema: { "enabled_thousand_days": false, "is_health_professional": true } 
    },
    'ASSISTANT': { 
      title: 'Assistente de saúde', 
      value: '6', 
      val: 'ASSISTANT', 
      schema: { "enabled_thousand_days": false, "is_health_professional": false } 
    }
}

export const perfilUser = (profile) => {
    const health_place_user = localStorage.user ? JSON.parse(localStorage.user)?.health_place_users?.[0] : {}
    const userRole = userRoles[profile]
  
    if (userRole) userRole['schema']['enabled_thousand_days'] = health_place_user['enabled_thousand_days']
  
    return userRole
  }