import './PatientCallPanel.css';
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getUnAuthTicketsHistory } from './http';
import { MaskDataHora } from '../../component';
import { formatDateWithTimezoneFromDateObj } from '../../utils/convertDate';
import audioFile from './senha_sound.m4a'
import { getFullInitials } from '../../utils/getInitials';

const wsScheme = process.env.NODE_ENV === 'production' ? 'wss' : 'ws'
const wsHost = process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST ? process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST : window.location.hostname

function abbreviateMiddleNames(fullName) {
    if (!fullName) return ''
    const nameParts = fullName.trim().split(' ');
  
    if (nameParts.length <= 2) {
      return fullName; 
    }
  
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];
  
    const middleNames = nameParts.slice(1, -1).map(name => `${name.charAt(0)}.`);
  
    return [firstName, ...middleNames, lastName].join(' ');
}

export default function PatientCallPanel() {
    const audioRef = useRef(new Audio(audioFile));
    const { idUid } = useParams();
    const [currentTime, setCurrentTime] = useState('');
    const [tickets, setTickets] = useState([0, 0, 0, 0, 0])
    const [connectionStatus, setConnectionStatus] = useState('Disconnected');
    const socketRef = useRef(null);
    const reconnectInterval = useRef(null);

    useEffect(() => {
        fetchTicketsHistory()
    }, [])

    const fetchTicketsHistory = async params => {
		params = { 
            "offset": 0, 
            "limit": 5, 
            "uuid": idUid,
            "status__in": "CALLED,SERVED",
            "called_at__gte": formatDateWithTimezoneFromDateObj(new Date(), '00', '00'),
		    "called_at__lte": formatDateWithTimezoneFromDateObj(new Date(), '23', '59'),
        }

		try {
			let response = await getUnAuthTicketsHistory(params)
			setTickets(response.data.results)
		} catch (err) {
			console.error('fetchTicketsHistory ~ ', err)
		}
	}

    useEffect(() => {
        const connectWebSocket = () => {

            if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) return;

            setConnectionStatus('Connecting...');
            socketRef.current = new WebSocket(`${wsScheme}://${wsHost}/ws/call_panel/connection/${idUid}/`)

            socketRef.current.onopen = () => {
                console.log('WebSocket connected');
                setConnectionStatus('Connected');

                const pingInterval = setInterval(() => {
                    if (socketRef.current.readyState === WebSocket.OPEN) {
                        socketRef.current.send(JSON.stringify({ type: 'ping' }));
                    }
                }, 10000);

                socketRef.current.onclose = () => clearInterval(pingInterval);
            };

            socketRef.current.onmessage = async (event) => {
                const data = JSON.parse(event.data);
                console.log(data)
                if (data.type === 'pong') {
                    console.log('Received pong')
                } else if (data.type === 'ticket_update' && data?.data === 'cancel') {
                    await fetchTicketsHistory()
                } else if (data.type === 'ticket_update') {
                    await fetchTicketsHistory()
                    audioRef.current.play();
                }
            };

            socketRef.current.onerror = (error) => {
                console.error('WebSocket error:', error);
                setConnectionStatus('Error');
                socketRef.current.close();
            };

            socketRef.current.onclose = () => {
                console.log('WebSocket disconnected');
                setConnectionStatus('Disconnected');

                reconnectInterval.current = setTimeout(connectWebSocket, 5000);
            };
        };

        connectWebSocket();

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
            if (reconnectInterval.current) {
                clearTimeout(reconnectInterval.current);
            }
        };
    }, []);

    useEffect(() => {
        const updateDateTime = () => {
            const now = new Date();
            const day = String(now.getDate()).padStart(2, '0');
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');

            setCurrentTime(`${day}/${month} ${hours}:${minutes}`);
        };

        updateDateTime();
        const intervalId = setInterval(updateDateTime, 60000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="PatientCallPanel">
            {
                /*
            <div className='PatientCallPanel-Header'>
                <div className='PatientCallPanel-Header-CurrentCall'>
                    <p>Paciente</p>
                    <p>{tickets?.[0]?.attendance?.person?.name || '-'}</p>
                </div>
                <div className='PatientCallPanel-Header-ServiceLocal'>
                    <p className='PatientCallPanel-Header-ServiceLocal-CalledTitle'>Sala</p>
                    <p className='PatientCallPanel-Header-ServiceLocal-Called'>{tickets?.[0]?.attendance?.service_location?.name || '-'}</p>
                </div>
            </div>
                */
            }

            <div className='PatientCallPanel-Content'>
                <div className='PatientCallPanel-Content-Header'>
                    <p>Histórico de chamados</p>
                </div>
                <div className='PatientCallPanel-Content-List-Header'>
                    <p>Paciente</p>
                    {/* <p>Profissional</p> */}
                    
                    <p>Sala</p>
                    <p>Horário</p>
                </div>
                <div className='PatientCallPanel-Content-List'>
                    {
                        tickets.map(ticketCursor => {
                            const start = MaskDataHora(ticketCursor?.called_at, 'DD/MMM/AAAA', '-');
                            return <div className='PatientCallPanel-Content-List-Row'>
                                {/* 
                                <p>{ getFullInitials(ticketCursor?.attendance?.person?.name) || '-'}</p>
                                <p>{ticketCursor?.attendance?.doctor?.name || '-'}</p> 
                                */}
                                <p>{ ticketCursor?.attendance?.person?.social_name || abbreviateMiddleNames(ticketCursor?.attendance?.person?.name) || '-'}</p>
                                <p>{ticketCursor?.attendance?.service_location?.name || '-'}</p>
                                <p>{start?.hora?.slice(0, 5)}</p>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className='PatientCallPanel-Footer'>
                <div className='PatientCallPanel-Footer-CurrentHour'>
                    {currentTime}
                </div>
                <div className='PatientCallPanel-Footer-Branding'>
                    {
                        tickets?.[0]?.call_panel_service?.health_place?.logo_url
                        ? <img 
                            alt='logo parceiro' 
                            src={tickets?.[0]?.call_panel_service?.health_place?.logo_url} 
                            title='Logo Parceiro' 
                            className="PatientCallPanel-Footer-Branding-Logo" 
                        /> 
                        : 'Salutho'
                    }
                </div>
            </div>
        </div>
    );
}
