import './DownloadDocumentProgressBarModal.css'
import React, { useEffect, useState } from "react";
import api from '../../../../../../../../../../../../../../helpers/api';
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../../../../newcomponents/modal/modal';
import { useInternetSpeed } from '../../../../../../../../../../../../../../utils/useInternetSpeed';

export default function DownloadDocumentProgressBarModal({ activity=null, setModalInfo=() => null }) {
    const speed = useInternetSpeed()
    const [downloadProgress, setDownloadProgress] = useState(0);

    useEffect(() => {
        handleDownload(activity)
    }, [])

    const handleDownload = async (activity) => {
        const url = activity.attachment_url;
    
        try {
            const chunkSize = 2 * 1024 * 1024; // 5 MB chunks
            let start = 0;
            let end = chunkSize - 1;
            let totalSize = 0;
            let downloadedBlob = new Blob([]);
            let mimeType = '';
    
            // Step 1: Make a HEAD request to get the total size and MIME type of the file
            const headResponse = await api.head(url);
            totalSize = parseInt(headResponse.headers['content-length'], 10);
            mimeType = headResponse.headers['content-type'];
            console.log('Total file size:', totalSize);
            console.log('MIME type:', mimeType);
    
            // Step 2: Function to download a chunk of the file
            const downloadChunk = async (start, end) => {
                const response = await api.get(`partial_file_view/${activity.attachment_uuid}/${start}-${end}`, {
                    responseType: 'blob',
                });
                return response.data;
            };
    
            // Step 3: Download all chunks sequentially and update progress
            while (start < totalSize) {
                console.log('Downloading chunk:', start, end);
                const chunkBlob = await downloadChunk(start, end);
                downloadedBlob = new Blob([downloadedBlob, chunkBlob], {
                    type: mimeType,
                });
    
                // Update the download progress
                start = end + 1;
                end = Math.min(start + chunkSize, totalSize - 1);
                const progress = (start / totalSize) * 100;
                setDownloadProgress(progress);
            }
    
            // Step 4: Once all chunks are downloaded, save the file with the correct extension
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(downloadedBlob);
            link.setAttribute('download', `${activity.attachment_uuid}`);
            document.body.appendChild(link);
            link.click();
    
            // Clean up the link element after download
            document.body.removeChild(link);
            setModalInfo(MODAL_INIT_PROPS);
            return `Download of ${activity.attachment_uuid} completed successfully`;
        } catch (err) {
            console.error('Error downloading file:', err);
        }
    };

    return <div className='DownloadDocumentProgressBarModal'>
        {
            speed && speed < 1 
            ? <p className='DownloadDocumentProgressBarModal-Asterisk'>*A velocidade da sua internet está lenta: {speed} Mbps. Isso irá causar maior tempo necessário para o download de arquivos e navegação na plataforma.</p> 
            : null
        }
        <p>Baixando {activity.attachment_uuid}...</p>
        <div className="DownloadDocumentProgressBarModal-upload-progress">
            <div className="DownloadDocumentProgressBarModal-progress-bar" style={{ width: `${downloadProgress}%` }}>
                {downloadProgress.toFixed(2)}%
            </div>
        </div>
    </div>
}