import './DefaultEditor.css'
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useAttendance } from '../../../../../../../../../../../../../../../consultation'
import { AddAlert, AlertType, TextareaEditor } from '../../../../../../../../../../../../../../../../../component'
import NewButton, { ButtonModel } from '../../../../../../../../../../../../../../../../../newcomponents/button/button'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../../../../../../../newcomponents/modal/modal'

const strpHoldingDocument = (document) => {
    return {
        "id": document?.id || "",
        "category": document?.category || "",
        "note": document?.note || "",
        "title": document?.title || "",
    }
}

export default function DefaultEditor({ saveDocument, initHoldingDocument=null }) {
    const dispatch = useDispatch()
    const [holdingDocument, setHoldingDocument] = useState(strpHoldingDocument(initHoldingDocument))
    const { attendance, setModalInfo } = useAttendance()

    const handleSubmit = async () => {
        if (!holdingDocument.note) return dispatch(AddAlert('Salvar documento', 'Documento está vazio', AlertType.ERROR))
        let payload = { note: holdingDocument.note }

        await saveDocument(payload)
        setModalInfo(MODAL_INIT_PROPS)
    }

    const handleChange = (event) => {
        setHoldingDocument(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    return <div>
        <div>
            <TextareaEditor
                name='note'
                action={handleChange} 
                value={holdingDocument.note}
            />
        </div>
        <div>
            <p>{`${attendance.doctor?.sex === 'M'? 'Dr.' : 'Dra.'} ${attendance.doctor?.name}`}</p>
        </div>
        <div className='DefaultEditor-BtnBox'>
            <NewButton label='Salvar' onClick={handleSubmit} />
        </div>
    </div>
}