import './GestationSubSection.css'
import React from 'react'
import PartnerPrenatal from './subcomponents/PartnerPrenatal/PartnerPrenatal'
import DentistAppointment from './subcomponents/DentistAppointment/DentistAppointment'
import PregnancyRiskCalculator from './subcomponents/PregnancyRiskCalculator/PregnancyRiskCalculator'
import Exams from './subcomponents/Exam/ThousandDaysExamSubSection'
import LastMenstruation from './subcomponents/LastMenstruation/LastMenstruation'
import Tabs from '../../../../../../../../../../newcomponents/tabs/tabs'
import TabItem from '../../../../../../../../../../newcomponents/tabs/tab-item'
import PreNatalAttendance from './subcomponents/PrenatalAttendance/PreNatalAttendance'
import { useThousandDays } from '../../ThousandDaysSection'

export default function GestationSubSection() {
	const { selectedLanguage, holdingProject } = useThousandDays()

	const { Attentions, Exam, Riskmapping, Partner, Dental, Dum} =
		require('../../../../../../../../../data/translation.json')[selectedLanguage]

	return (
		<>
			<Tabs>
				<TabItem
					id='prenatal_attendances'
					name={Attentions}>
					<div className='GestationSubSection-SubsectionWrapper ThousandDays-Consultation-TabWrapper'>
						<PreNatalAttendance />
					</div>
				</TabItem>
				<TabItem
					id='exam'
					name={Exam}>
					<div className='GestationSubSection-SubsectionWrapper ThousandDays-Consultation-TabWrapper'>
						<Exams />
					</div>
				</TabItem>
				<TabItem
					id='risk'
					name={Riskmapping}>
					<div className='GestationSubSection-SubsectionWrapper ThousandDays-Consultation-TabWrapper'>
						<PregnancyRiskCalculator />
					</div>
				</TabItem>
				<TabItem
					id='partner_prenatal'
					name={Partner}>
					<div className='GestationSubSection-SubsectionWrapper ThousandDays-Consultation-TabWrapper'>
						<PartnerPrenatal />
					</div>
				</TabItem>
				<TabItem
					id='dentist_appointment'
					name={Dental}>
					<div className='GestationSubSection-SubsectionWrapper ThousandDays-Consultation-TabWrapper'>
						<DentistAppointment project={holdingProject.id} />
					</div>
				</TabItem>
				<TabItem
					id='dum'
					name={Dum}>
					<div className='GestationSubSection-SubsectionWrapper ThousandDays-Consultation-TabWrapper'>
						<LastMenstruation />
					</div>
				</TabItem>
			</Tabs>
		</>
	)
}
