import './ActiveLabour.css'
import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import NewSelect from '../../../../../../../../../../../../../../newcomponents/form/select/select'
import { Input } from '../../../../../../../../../../../../../../component'
import { useThousandDays } from '../../../../../../ThousandDaysSection'
import PatographChart from './PartographChart'
import HeartRateChart from './HeartRateChart'

const Chart = () => {
	const ref = useRef()
	useEffect(() => {
		drawChart()
	}, [])

	const drawChart = () => {
		const margin = { top: 10, right: 30, bottom: 40, left: 52 },
			width = 600 - margin.left - margin.right,
			height = 240 - margin.top - margin.bottom

		const svg = d3
			.select(ref.current)
			.append('svg')
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom)
			.append('g')
			.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

		const x = d3.scaleLinear().domain([0, 12]).range([0, width])

		const y = d3.scaleLinear().domain([4, 10]).range([height, 0])

		// Background shapes
		svg
			.append('path')
			.attr('d', `M ${x(0)} ${y(4)} L ${x(0)} ${y(10)} L ${x(6)} ${y(10)} Z`)
			.attr('fill', '#c0dfc3')

		svg
			.append('path')
			.attr(
				'd',
				`M ${x(0)} ${y(4)} L ${x(6)} ${y(10)} L ${x(10)} ${y(10)} L ${x(4)} ${y(
					4
				)} Z`
			)
			.attr('fill', '#ffe595')

		svg
			.append('path')
			.attr(
				'd',
				`M ${x(4)} ${y(4)} L ${x(10)} ${y(10)} L ${x(12)} ${y(10)} L ${x(
					12
				)} ${y(4)} Z`
			)
			.attr('fill', '#f8c9df')
		svg
			.append('text')
			.attr(
				'transform',
				'translate(' + width / 2 + ' ,' + (height + margin.top + 20) + ')'
			)
			.style('text-anchor', 'middle')
			.style('font-family', 'sans-serif') // specify the font-family
			.style('font-size', '10px') // specify the font-size
	}

	return (
		<div className='Partograph-ActiveLabour-GraphWrapper'>
			<div ref={ref}></div>
		</div>
	)
}

const selectOptions_data = require('../../../../../../../../../../../../../../component/form/select/options.json')

const getYears = () => {
	let years = []
	for (
		let i = new Date().getFullYear() - 1;
		i <= new Date().getFullYear();
		i++
	) {
		years.push({ id: `${i}`, name: `${i}` })
	}
	return years
}

export default function ActiveLabour({ handleChange, handleSelect }) {
	const { selectedLanguage, partograph, savePartograph } = useThousandDays()

	const {
		Birthhour,
		Typeofanesthesia,
		Notevaluated,
		Local,
		Spinal,
		Epidural,
		Median,
		Sidemiddle,
		Estimatedbloodloss,
		Hoursinlabor,
		Cervicaldilation,
		Amnoticfluid,
		Fetalheartrate,
		Contractions,
		Urineoutput,
		Temperature,
		Pulse,
		Rupturepofmenbranes,
		Deliveryoftheplacenta,
		Episiotomy,
		SelectOptions_Data_Months_Options,
		Day,
		Month,
		Year,
		Hour,
	} = require('../../../../../../../../../../../../../data/translation.json')[
		selectedLanguage
	]

	const selectOptions_data_months_options = selectOptions_data.months.map(
		option => ({
			id: option.id,
			name: SelectOptions_Data_Months_Options[option.id],
		})
	)

	useEffect(() => {
		savePartograph()
	}, [
		partograph.birthtime_day,
		partograph.birthtime_month,
		partograph.birthtime_year,
		partograph.birthtime_hour,
		partograph.birthtime_minute,
		partograph.anesthesia_types,
		partograph.episiotomy,
		partograph.placenta_delivery,
		partograph.membranes_rupture,
	])

	return (
		<>
			<div className='ThousandDaysSection-DatetimeRow'>
				<div>
					<b>{Birthhour}</b>
					<div className='ThousandDaysSection-DatetimeRow-SelectRow'>
						<div className='ThousandDaysSection-Childbirth-Datetime'>
							<NewSelect
								id='birthtime_day'
								options={selectOptions_data.days}
								onSelect={handleSelect}
								selected={partograph.birthtime_day}
								canBeEmpty={false}
								defaultText={Day}
							/>
							<NewSelect
								id='birthtime_month'
								options={selectOptions_data_months_options}
								onSelect={handleSelect}
								selected={partograph.birthtime_month}
								defaultText={Month}
								canBeEmpty={false}
							/>
							<NewSelect
								id='birthtime_year'
								options={getYears()}
								onSelect={handleSelect}
								selected={partograph.birthtime_year}
								defaultText={Year}
								canBeEmpty={false}
							/>
						</div>
						<div className='ThousandDaysSection-Childbirth-InfoHour'>
							<NewSelect
								id='birthtime_hour'
								options={selectOptions_data.hours}
								onSelect={handleSelect}
								selected={partograph.birthtime_hour}
								defaultText={Hour}
								canBeEmpty={false}
							/>
							<NewSelect
								id='birthtime_minute'
								options={selectOptions_data.minutes}
								onSelect={handleSelect}
								selected={partograph.birthtime_minute}
								defaultText='Min.'
								canBeEmpty={false}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='Partograph-ActiveLabour-2FR'>
				<div>
					<b>{Typeofanesthesia}</b>
					<div className='Partograph-ActiveLabour-Input'>
						<NewSelect
							id='anesthesia_types'
							options={[
								{ id: 'NOT_EVALUATED', name: Notevaluated },
								{ id: 'LOCAL', name: Local },
								{ id: 'RAQUIDEA', name: Spinal },
								{ id: 'PERIDUAL', name: Epidural },
							]}
							onSelect={handleSelect}
							selected={partograph.anesthesia_types}
							canBeEmpty={false}
						/>
						<b>{Episiotomy}</b>
						<div className='Partograph-ActiveLabour-Input'>
							<NewSelect
								id='episiotomy'
								options={[
									{ id: 'NOT_EVALUATED', name: Notevaluated },
									{ id: 'AVARAGE', name: Median },
									{ id: 'SIDE_AVARAGE', name: Sidemiddle },
								]}
								onSelect={handleSelect}
								selected={partograph.episiotomy}
								canBeEmpty={false}
							/>
						</div>
					</div>
				</div>
			</div>
			<div>
				<b>{Estimatedbloodloss}</b>
				<div className='ThousandDaysSection-MotherInfoSection-Input'>
					<Input
						name='estimated_blood_loss'
						placeholder='ml'
						maxLength={6}
						value={partograph.estimated_blood_loss}
						action={handleChange}
						actionBlur={savePartograph}
						autoComplete='off'
					/>
				</div>
			</div>
			<div className='Partograph-ActiveLabour-GraphWrapper'>
				<div>
					<div className='Partograph-ActiveLabor-Position-Absolute'>
						<Chart />
					</div>
					<PatographChart partograph={partograph} />
				</div>
				<HeartRateChart partograph={partograph} />
			</div>
			<table className='Partograph-ActiveLabour-Table'>
				<tr>
					<th>{Hoursinlabor}</th>
					<th>1ª</th>
					<th>2ª</th>
					<th>3ª</th>
					<th>4ª</th>
					<th>5ª</th>
					<th>6ª</th>
					<th>7ª</th>
					<th>8ª</th>
					<th>9ª</th>
					<th>10ª</th>
					<th>11ª</th>
					<th>12ª</th>
				</tr>
				<tr>
					<td>{Cervicaldilation}</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.cervical_dilation_1}
							name='cervical_dilation_1'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.cervical_dilation_2}
							name='cervical_dilation_2'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.cervical_dilation_3}
							name='cervical_dilation_3'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.cervical_dilation_4}
							name='cervical_dilation_4'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.cervical_dilation_5}
							name='cervical_dilation_5'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.cervical_dilation_6}
							name='cervical_dilation_6'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.cervical_dilation_7}
							name='cervical_dilation_7'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.cervical_dilation_8}
							name='cervical_dilation_8'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.cervical_dilation_9}
							name='cervical_dilation_9'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.cervical_dilation_10}
							name='cervical_dilation_10'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.cervical_dilation_11}
							name='cervical_dilation_11'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.cervical_dilation_12}
							name='cervical_dilation_12'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
				</tr>
				{/*
                    FIXME
<tr>
                <td>Sangramento vaginal (0 + ++)</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
            </tr>
                */}

				<tr className='Partograph-ActiveLabour-Table-OddRow'>
					<td>{Amnoticfluid}</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.amniotic_fluid_1}
							name='amniotic_fluid_1'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.amniotic_fluid_2}
							name='amniotic_fluid_2'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.amniotic_fluid_3}
							name='amniotic_fluid_3'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.amniotic_fluid_4}
							name='amniotic_fluid_4'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.amniotic_fluid_5}
							name='amniotic_fluid_5'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.amniotic_fluid_6}
							name='amniotic_fluid_6'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.amniotic_fluid_7}
							name='amniotic_fluid_7'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.amniotic_fluid_8}
							name='amniotic_fluid_8'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.amniotic_fluid_9}
							name='amniotic_fluid_9'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.amniotic_fluid_10}
							name='amniotic_fluid_10'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.amniotic_fluid_11}
							name='amniotic_fluid_11'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.amniotic_fluid_12}
							name='amniotic_fluid_12'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
				</tr>
				<tr>
					<td>{Contractions}</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.contractions_in_10_min_1}
							name='contractions_in_10_min_1'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.contractions_in_10_min_2}
							name='contractions_in_10_min_2'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.contractions_in_10_min_3}
							name='contractions_in_10_min_3'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.contractions_in_10_min_4}
							name='contractions_in_10_min_4'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.contractions_in_10_min_5}
							name='contractions_in_10_min_5'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.contractions_in_10_min_6}
							name='contractions_in_10_min_6'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.contractions_in_10_min_7}
							name='contractions_in_10_min_7'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.contractions_in_10_min_8}
							name='contractions_in_10_min_8'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.contractions_in_10_min_9}
							name='contractions_in_10_min_9'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.contractions_in_10_min_10}
							name='contractions_in_10_min_10'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.contractions_in_10_min_11}
							name='contractions_in_10_min_11'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.contractions_in_10_min_12}
							name='contractions_in_10_min_12'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
				</tr>
				<tr className='Partograph-ActiveLabour-Table-OddRow'>
					<td>{Fetalheartrate}</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.fetal_beat_rate_1}
							name='fetal_beat_rate_1'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.fetal_beat_rate_2}
							name='fetal_beat_rate_2'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.fetal_beat_rate_3}
							name='fetal_beat_rate_3'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.fetal_beat_rate_4}
							name='fetal_beat_rate_4'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.fetal_beat_rate_5}
							name='fetal_beat_rate_5'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.fetal_beat_rate_6}
							name='fetal_beat_rate_6'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.fetal_beat_rate_7}
							name='fetal_beat_rate_7'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.fetal_beat_rate_8}
							name='fetal_beat_rate_8'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.fetal_beat_rate_9}
							name='fetal_beat_rate_9'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.fetal_beat_rate_10}
							name='fetal_beat_rate_10'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.fetal_beat_rate_11}
							name='fetal_beat_rate_11'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.fetal_beat_rate_12}
							name='fetal_beat_rate_12'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
				</tr>
				<tr>
					<td>{Urineoutput}</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.urine_eliminated_1}
							name='urine_eliminated_1'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.urine_eliminated_2}
							name='urine_eliminated_2'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.urine_eliminated_3}
							name='urine_eliminated_3'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.urine_eliminated_4}
							name='urine_eliminated_4'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.urine_eliminated_5}
							name='urine_eliminated_5'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.urine_eliminated_6}
							name='urine_eliminated_6'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.urine_eliminated_7}
							name='urine_eliminated_7'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.urine_eliminated_8}
							name='urine_eliminated_8'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.urine_eliminated_9}
							name='urine_eliminated_9'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.urine_eliminated_10}
							name='urine_eliminated_10'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.urine_eliminated_11}
							name='urine_eliminated_11'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.urine_eliminated_12}
							name='urine_eliminated_12'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
				</tr>
				<tr className='Partograph-ActiveLabour-Table-OddRow'>
					<td>{Temperature}</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.body_temperature_1}
							name='body_temperature_1'
							maxLength={4}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.body_temperature_2}
							name='body_temperature_2'
							maxLength={4}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.body_temperature_3}
							name='body_temperature_3'
							maxLength={4}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.body_temperature_4}
							name='body_temperature_4'
							maxLength={4}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.body_temperature_5}
							name='body_temperature_5'
							maxLength={4}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.body_temperature_6}
							name='body_temperature_6'
							maxLength={4}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.body_temperature_7}
							name='body_temperature_7'
							maxLength={4}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.body_temperature_8}
							name='body_temperature_8'
							maxLength={4}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.body_temperature_9}
							name='body_temperature_9'
							maxLength={4}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.body_temperature_10}
							name='body_temperature_10'
							maxLength={4}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.body_temperature_11}
							name='body_temperature_11'
							maxLength={4}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.body_temperature_12}
							name='body_temperature_12'
							maxLength={4}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
				</tr>
				<tr>
					<td>{Pulse}</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.beat_rate_1}
							name='beat_rate_1'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.beat_rate_2}
							name='beat_rate_2'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.beat_rate_3}
							name='beat_rate_3'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.beat_rate_4}
							name='beat_rate_4'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.beat_rate_5}
							name='beat_rate_5'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.beat_rate_6}
							name='beat_rate_6'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.beat_rate_7}
							name='beat_rate_7'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.beat_rate_8}
							name='beat_rate_8'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.beat_rate_9}
							name='beat_rate_9'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.beat_rate_10}
							name='beat_rate_10'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.beat_rate_11}
							name='beat_rate_11'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
					<td>
						<input
							onBlur={savePartograph}
							onChange={handleChange}
							value={partograph?.beat_rate_12}
							name='beat_rate_12'
							maxLength={3}
							className='Partograph-ActiveLabour-Table-Input'
						/>
					</td>
				</tr>
				<tr className='Partograph-ActiveLabour-Table-OddRow'>
					<td>{Rupturepofmenbranes}</td>
					<td>
						<div
							id='membranes_rupture'
							onClick={handleSelect}
							selected='SELECTED_1'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.membranes_rupture === 'SELECTED_1' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='membranes_rupture'
							onClick={handleSelect}
							selected='SELECTED_2'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.membranes_rupture === 'SELECTED_2' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='membranes_rupture'
							onClick={handleSelect}
							selected='SELECTED_3'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.membranes_rupture === 'SELECTED_3' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='membranes_rupture'
							onClick={handleSelect}
							selected='SELECTED_4'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.membranes_rupture === 'SELECTED_4' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='membranes_rupture'
							onClick={handleSelect}
							selected='SELECTED_5'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.membranes_rupture === 'SELECTED_5' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='membranes_rupture'
							onClick={handleSelect}
							selected='SELECTED_6'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.membranes_rupture === 'SELECTED_6' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='membranes_rupture'
							onClick={handleSelect}
							selected='SELECTED_7'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.membranes_rupture === 'SELECTED_7' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='membranes_rupture'
							onClick={handleSelect}
							selected='SELECTED_8'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.membranes_rupture === 'SELECTED_8' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='membranes_rupture'
							onClick={handleSelect}
							selected='SELECTED_9'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.membranes_rupture === 'SELECTED_9' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='membranes_rupture'
							onClick={handleSelect}
							selected='SELECTED_10'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.membranes_rupture === 'SELECTED_10' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='membranes_rupture'
							onClick={handleSelect}
							selected='SELECTED_11'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.membranes_rupture === 'SELECTED_11' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='membranes_rupture'
							onClick={handleSelect}
							selected='SELECTED_12'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.membranes_rupture === 'SELECTED_12' ? 'filled' : ''
							}`}
						/>
					</td>
				</tr>
				<tr>
					<td>{Deliveryoftheplacenta}</td>
					<td>
						<div
							id='placenta_delivery'
							onClick={handleSelect}
							selected='SELECTED_1'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.placenta_delivery === 'SELECTED_1' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='placenta_delivery'
							onClick={handleSelect}
							selected='SELECTED_2'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.placenta_delivery === 'SELECTED_2' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='placenta_delivery'
							onClick={handleSelect}
							selected='SELECTED_3'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.placenta_delivery === 'SELECTED_3' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='placenta_delivery'
							onClick={handleSelect}
							selected='SELECTED_4'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.placenta_delivery === 'SELECTED_4' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='placenta_delivery'
							onClick={handleSelect}
							selected='SELECTED_5'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.placenta_delivery === 'SELECTED_5' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='placenta_delivery'
							onClick={handleSelect}
							selected='SELECTED_6'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.placenta_delivery === 'SELECTED_6' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='placenta_delivery'
							onClick={handleSelect}
							selected='SELECTED_7'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.placenta_delivery === 'SELECTED_7' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='placenta_delivery'
							onClick={handleSelect}
							selected='SELECTED_8'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.placenta_delivery === 'SELECTED_8' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='placenta_delivery'
							onClick={handleSelect}
							selected='SELECTED_9'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.placenta_delivery === 'SELECTED_9' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='placenta_delivery'
							onClick={handleSelect}
							selected='SELECTED_10'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.placenta_delivery === 'SELECTED_10' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='placenta_delivery'
							onClick={handleSelect}
							selected='SELECTED_11'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.placenta_delivery === 'SELECTED_11' ? 'filled' : ''
							}`}
						/>
					</td>
					<td>
						<div
							id='placenta_delivery'
							onClick={handleSelect}
							selected='SELECTED_12'
							className={`Partograph-ActiveLabour-Table-Radio ${
								partograph?.placenta_delivery === 'SELECTED_12' ? 'filled' : ''
							}`}
						/>
					</td>
				</tr>
			</table>
		</>
	)
}
