import React from 'react'

export function MedicalIcon({style={}}) {
    return <svg style={style} viewBox="0 0 512.000000 512.000000">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M1040 5107 c-49 -16 -133 -102 -148 -153 -7 -23 -12 -66 -12 -97 l0
            -55 -242 -4 c-235 -3 -245 -4 -293 -28 -64 -31 -124 -91 -155 -155 l-25 -50 0
            -1765 0 -1765 27 -51 c40 -76 70 -107 140 -145 l63 -34 618 -3 617 -3 25 25
            c30 30 33 74 6 107 l-19 24 -610 5 c-597 5 -611 5 -638 26 -15 11 -37 33 -48
            48 -21 27 -21 31 -24 1417 l-2 1389 2240 0 2240 0 -2 -1389 c-3 -1386 -3
            -1390 -24 -1417 -11 -15 -33 -37 -48 -48 -27 -20 -42 -21 -518 -26 l-490 -5
            -19 -24 c-27 -33 -24 -77 6 -107 l25 -25 497 3 498 3 63 34 c70 38 100 69 140
            145 l27 51 0 1765 0 1765 -34 63 c-38 70 -69 100 -145 140 -51 27 -52 27 -293
            30 l-243 4 0 55 c0 80 -18 128 -67 183 -95 106 -236 108 -338 5 -57 -56 -75
            -102 -75 -189 l0 -56 -1200 0 -1200 0 0 56 c0 81 -18 129 -67 184 -65 72 -158
            97 -253 67z m135 -172 l25 -24 0 -231 0 -231 -25 -24 c-15 -16 -36 -25 -55
            -25 -19 0 -40 9 -55 25 l-25 24 0 231 0 231 25 24 c15 16 36 25 55 25 19 0 40
            -9 55 -25z m2880 0 l25 -24 0 -231 0 -231 -25 -24 c-15 -16 -36 -25 -55 -25
            -19 0 -40 9 -55 25 l-25 24 0 231 0 231 25 24 c15 16 36 25 55 25 19 0 40 -9
            55 -25z m-3175 -391 c0 -128 13 -168 75 -229 102 -103 243 -101 338 5 54 60
            67 103 67 224 l0 96 1200 0 1200 0 0 -96 c0 -128 13 -168 75 -229 102 -103
            243 -101 338 5 54 60 67 103 67 224 l0 97 229 -3 c252 -3 255 -4 305 -72 20
            -26 21 -43 24 -297 l3 -269 -2241 0 -2240 0 0 254 c0 264 4 292 43 335 43 46
            68 50 300 51 l217 0 0 -96z"/>
            <path d="M1445 3031 c-90 -22 -172 -90 -215 -176 l-25 -50 0 -470 0 -470 31
            -88 c18 -48 47 -113 65 -144 46 -78 180 -210 259 -256 71 -41 204 -87 251 -87
            l29 0 0 -257 c0 -276 7 -345 46 -463 42 -126 99 -215 204 -320 180 -179 394
            -262 640 -247 358 23 658 266 761 617 20 68 23 102 26 372 l5 296 33 6 c66 13
            170 74 227 134 187 196 182 491 -11 675 -195 186 -491 180 -674 -12 -182 -191
            -182 -472 1 -663 57 -61 161 -121 228 -134 l36 -7 -4 -286 c-4 -278 -5 -288
            -31 -366 -36 -108 -89 -195 -168 -274 -79 -79 -166 -132 -274 -168 -69 -23
            -96 -27 -205 -27 -109 0 -136 4 -205 27 -108 36 -195 89 -274 168 -79 79 -132
            166 -168 274 -26 78 -27 87 -31 368 l-4 287 31 0 c47 0 180 46 251 87 79 46
            213 178 259 256 18 31 47 96 65 144 l31 88 0 470 0 470 -27 51 c-15 28 -38 65
            -52 81 -38 45 -134 92 -204 99 -56 6 -62 5 -86 -19 -56 -56 -24 -124 64 -138
            58 -9 91 -29 124 -73 20 -27 21 -39 21 -469 0 -488 1 -479 -68 -609 -35 -67
            -130 -167 -194 -205 -228 -133 -504 -100 -687 83 -79 80 -139 191 -156 294 -7
            42 -10 208 -8 472 3 393 4 408 24 434 33 44 66 64 124 73 87 14 120 82 65 136
            -25 26 -67 31 -130 16z m2146 -992 c67 -36 98 -68 137 -143 22 -43 26 -64 27
            -136 0 -78 -3 -90 -34 -148 -19 -35 -48 -74 -64 -88 -27 -23 -118 -74 -132
            -74 -3 0 -5 38 -5 84 0 82 1 85 34 118 62 62 61 156 -3 219 -65 65 -157 65
            -222 0 -63 -63 -65 -152 -3 -220 33 -37 34 -41 34 -120 0 -44 -2 -81 -5 -81
            -14 0 -105 51 -132 74 -16 14 -45 53 -64 88 -31 58 -34 70 -33 148 0 100 24
            161 89 225 100 100 249 121 376 54z"/>
        </g>
    </svg>
}
