import './NewCheckbox.css'
import React from "react";

// example: 
// state = [..., choice,...] = [..., { id: 'RECIPE', name: 'Receita Normal', checked: false },...]
// setState([choices])

export default function NewCheckbox ({ state, setState, className='', title='', defaultHandleCheckbox=true }) {
    
    return <section className={`${className}`}>
        <p className='NewCheckbox-p'>{ title }</p>
        <form className='NewCheckbox-form'>
        {
            state.map((choice, idx) => <div key={choice?.id}>
                    <input
                        key={idx}
                        type="checkbox"
                        className='NewCheckbox-input'
                        id={choice?.id} 
                        name={choice?.id} 
                        value={choice?.id}
                        onChange={(event) => {
                            defaultHandleCheckbox
                            ? setState(prev => prev.map(option => option.id === choice.id ? { ...choice, checked: !choice.checked } : option ))
                            : setState(event)
                        }} 
                        checked={choice?.checked}
                    />
                    <label className='NewCheckbox-label' htmlFor={choice?.id}>{choice?.name}</label>
                </div>
            )
        }
        </form>
    </section>
}