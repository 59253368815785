import './ManageTeamEdgesModal.css'
import React, { useEffect, useRef, useState } from "react";
import NewButton from "../../../../newcomponents/button/button";
import { AddAlert, AlertType, Button, IcoBin, Input, List, Paginate } from '../../../../component';
import NewSelect from '../../../../newcomponents/form/select/select';
import { getCollaborators } from '../../http';
import { loaded, loading } from '../../../../layout/redux/AppActions';
import { useDispatch } from 'react-redux';
import api from '../../../../helpers/api';
import { getParams } from '../../../../utils';
import { userRoles } from '../../../../utils/userRoles';

const headDefault = [
  { colunm: 'h_user', text: 'Profissional' },
  { colunm: 'h_profile', text: 'Perfil' },
  { colunm: 'h_enabled_thousand_days', text: '1000 dias' },
  { colunm: 'h_action', text: '', width: '60px' }
]

export default function ManageTeamEdgesModal({ leader, setModalInfo=null }) {
    const dispatch = useDispatch()
    const searchTimeout = useRef(null)
    const [selectedLed, setSelectedLed] = useState(null)
    const [associatedTeamEdges, setAssociatedTeamEdges] = useState([])
    const [ledOptions, setLedOptions] = useState([])
    const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })
    
    const limit = setModalInfo ? 5 : 20

    useEffect(() => {
        fetchLeds({ offset: 0 })
        fetchLedsOptions()
    }, [])

    const handlePagination = (e) => {
        fetchLeds({ offset: e * limit })
    }

    const fetchLedsOptions = async (params) => {
        await getCollaborators({ 
            ...params, 
            exclude_leds_of_leader: leader?.id, 
            health_place__id: leader?.health_place?.id, 
            profile__in: 'ASSISTANT%2CNURSE',
            has_person: true
        })
            .then(res => {
                setLedOptions(res.data.results.map(instance => {
                    const str = instance?.user?.person?.name
                        ? `${instance?.user?.person?.name}`
                        : `${instance?.user?.email} (cadastro incompleto)`
                    
                    return { 
                        id: instance?.id, 
                        name: `${str} | ${ userRoles?.[instance?.profile]?.title || '-' } | ${instance?.health_place?.name}` 
                    }
                }))
            })
            .catch(err => {
                console.error('fetchLedsOptions', err)
                setLedOptions([])
            })
    }

    const fetchLeds = async (params) => {
        params = { ...params, "leader__id": leader?.id, "limit": limit }
        dispatch(loading())
        api.get(`health_place_user/associate/team_edge/${getParams(params)}`)
          .then(res => {
            setAssociatedTeamEdges(res.data.results)
            setPagination({
              totalElements: res.data.count, 
              totalPages: Math.ceil(res.data.count / limit),
              pageNumber: (params.offset / limit),
              next: res.data.next, 
              previous: res.data.previous
            })
          })
          .catch(err => {
            console.error('fetchCollaborators', err)
            setAssociatedTeamEdges([])
          })
        dispatch(loaded())
    }

    const handleAddLed = async () => {
        if (!selectedLed) return 

        const payload = {
            "leader": leader?.id,
            "led": selectedLed
        }

        dispatch(loading())
        try {
            await api.post('/health_place_user/associate/team_edge/', payload)
            fetchLeds({ offset: 0 })
            fetchLedsOptions()
            dispatch(AddAlert('Gerência de Equipe', 'Membro adicionado com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            dispatch(AddAlert('Gerência de Equipe', 'Falha ao adicionar membro!', AlertType.ERROR))
            console.error('handleAddLed ~ post ~ /health_place_user/associate/team_edge/', err)
        }
        
        dispatch(loaded())
    }

    const handleRemove = async (selecetedAssociatedTeamEdge) => {
        dispatch(loading())
        try {
            await api.delete(`health_place_user/associate/team_edge/${selecetedAssociatedTeamEdge?.id}/`)
            fetchLeds({ offset: 0 })
            fetchLedsOptions()
            dispatch(AddAlert('Remoção de membro', 'Membro removido com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            dispatch(AddAlert('Remoção de membro', 'Falha ao adicionar membro!', AlertType.ERROR))
            console.error('handleRemove ~ delete ~ /health_place_user/associate/team_edge/', err)
        }
        dispatch(loaded())
    }

    const handleSelect = (event) => {
        setSelectedLed(event.target.selected)
    }

    const handleSearch = (search) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)
  
        searchTimeout.current = setTimeout((search) => {
            fetchLedsOptions({ 'user__email__icontains': search })
        }, 400, search)
    }

    return <div className={setModalInfo ? "ManageTeamEdgesModal" : ''}>
        <NewSelect 
            label='Usuário'
            options={ledOptions}
            selected={selectedLed}
            onSelect={handleSelect}
            filterNode={<div className='ManageTeamEdgesModal-NewSelect'>
                <Input 
                    placeholder='Pesquisar por email'
                    action={({ target: { value } }) => handleSearch(value)}
                    actionFocus={()=> handleSearch()}
                />
            </div>
            }
        />
        <div className='ManageTeamEdgesModal-BtnBox'>
            <NewButton 
                label='Adicionar'
                onClick={handleAddLed}
            />
        </div>
        <div className='ManageTeamEdgesModal-Wrapper' />
        <div className={setModalInfo ? 'ManageTeamEdgesModal-List' : 'ManageTeamEdgesFullWidth-List'}>
            <List
                head={headDefault}
                data={associatedTeamEdges}
                maxHeight={setModalInfo ? "240px" : 'calc(75vh - 240px)'}
                listCustom={teamEdge => {
                    const custom = teamEdge
                    custom['h_user'] = teamEdge?.led?.user?.person?.name 
                        ? <p>{ teamEdge?.led?.user?.person?.name }</p> 
                        : <p>{ teamEdge?.led?.user?.email} (cadastro incompleto)</p>
                    custom['h_profile'] = <p>{ userRoles?.[teamEdge?.led?.profile]?.title || '-' }</p>
                    custom['h_enabled_thousand_days'] = <p>{ teamEdge?.led?.enabled_thousand_days ? 'Sim' : 'Não' }</p>
                    custom['h_action'] = (
                        <div className='ManageTeamEdgesModal-ActionRow'>
                            <div onClick={() => handleRemove(teamEdge)}>
                                <IcoBin />
                            </div>
                        </div>
                    )
                    return custom
                }}
            />
        </div>
        <Paginate data={pagination} action={handlePagination} />
    </div>
}