import './UnavailableScheduleModal.css'
import React from "react";
import { MaskDataHora } from '../../../../../../../../../../../../../../component';


export default function UnavailableScheduleModal({ appointment={} }) {

    const startHourTime = MaskDataHora(appointment?.fields?.start || appointment?.start, 'DD/MMM/AAAA', '-');
    const endHourTime = MaskDataHora(appointment?.fields?.end || appointment?.end, 'DD/MMM/AAAA', '-');

    return <div className="UnavailableScheduleModal">
        <div className='UnavailableScheduleModal-TimeRow'>
            <div>
                <b>{startHourTime.data} | {startHourTime?.hora?.slice(0, 5) || '-'} - {endHourTime?.hora?.slice(0, 5) || '-'}</b>
            </div>
        </div>
        <div>
            <span className='UnavailableScheduleModal-Message'>"{appointment?.message}"</span>
        </div>
    </div>
}