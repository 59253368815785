import React from 'react'

export function PatientIcon({style={}}) {
    return <svg style={style} viewBox="0 0 512.000000 512.000000">

    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
    <path d="M1753 4945 c-198 -36 -364 -123 -509 -269 -128 -128 -210 -270 -255
    -445 -26 -96 -37 -298 -20 -340 23 -54 106 -65 135 -17 9 13 16 69 19 146 7
    145 23 213 77 326 78 163 211 295 374 374 126 60 190 74 346 74 156 0 220 -14
    346 -74 298 -143 470 -442 451 -780 -6 -95 -20 -196 -33 -242 -3 -10 -14 -18
    -25 -18 -16 0 -19 7 -19 55 0 46 -4 60 -25 80 -23 24 -31 25 -136 25 -61 0
    -130 5 -152 12 -54 16 -131 90 -165 158 -48 97 -115 96 -167 -2 -37 -70 -77
    -113 -130 -141 -38 -21 -57 -22 -333 -27 l-294 -5 -19 -24 c-13 -16 -19 -39
    -19 -77 l0 -54 -62 0 c-80 0 -151 -32 -199 -89 -57 -66 -71 -136 -48 -243 34
    -160 144 -264 320 -302 19 -4 34 -24 68 -91 25 -49 68 -111 102 -149 l59 -64
    0 -224 0 -223 29 -50 c41 -72 97 -130 165 -170 92 -54 154 -69 286 -69 132 0
    194 15 286 70 75 44 114 84 158 158 l36 60 0 224 0 224 59 64 c34 38 77 100
    102 149 34 67 49 87 68 91 109 24 190 72 249 150 52 68 75 137 76 229 1 70 -2
    86 -26 127 -16 25 -42 57 -58 70 l-31 24 18 95 c23 130 24 379 0 473 -25 100
    -72 210 -125 296 -62 102 -206 243 -311 308 -196 120 -445 168 -668 127z m362
    -1145 c73 -76 184 -120 302 -120 l63 0 0 -223 c0 -261 -8 -313 -61 -425 -104
    -216 -339 -339 -580 -304 -183 26 -339 140 -418 304 -53 112 -61 164 -61 425
    l0 223 223 0 c169 0 237 4 282 15 66 18 134 57 180 105 16 16 32 30 35 30 3 0
    19 -14 35 -30z m-934 -290 c17 -10 19 -23 19 -150 0 -158 -1 -159 -68 -110
    -49 35 -81 93 -89 160 -5 45 -3 51 25 79 32 33 76 41 113 21z m1591 -21 c28
    -28 30 -34 25 -81 -8 -63 -47 -130 -95 -163 -61 -42 -62 -41 -62 115 0 154 2
    159 68 160 24 0 42 -8 64 -31z m-1112 -880 c72 -27 187 -49 260 -49 73 0 188
    22 260 49 30 12 56 21 58 21 1 0 2 -67 2 -149 l0 -149 -33 -44 c-129 -170
    -445 -170 -574 0 l-33 44 0 149 c0 82 1 149 3 149 1 0 27 -9 57 -21z"/>
    <path d="M3545 4615 c-21 -20 -25 -34 -25 -80 l0 -55 -56 0 c-31 0 -75 -5 -98
    -12 -55 -16 -138 -99 -154 -154 -19 -64 -16 -676 3 -789 60 -341 325 -622 662
    -699 37 -9 79 -17 93 -18 l25 -3 3 -580 c2 -652 4 -641 -69 -714 -50 -49 -117
    -73 -196 -69 l-58 3 -7 60 c-19 149 -98 345 -190 470 -165 222 -423 377 -692
    414 -128 18 -173 15 -201 -14 -33 -32 -33 -78 0 -110 20 -21 34 -25 82 -25 76
    0 222 -31 303 -64 284 -116 494 -379 538 -673 l9 -63 -403 0 -403 0 -24 53
    c-30 66 -81 118 -150 154 -45 23 -64 27 -137 27 -73 0 -92 -4 -137 -27 -66
    -34 -116 -84 -150 -150 -23 -45 -27 -64 -27 -137 0 -73 4 -92 27 -137 60 -116
    160 -178 287 -178 60 0 86 5 130 26 69 33 126 89 157 156 l24 52 572 3 572 3
    67 32 c93 44 151 100 196 191 l37 76 5 610 5 611 30 3 c17 2 64 11 105 22 329
    83 586 361 645 695 19 113 22 724 3 789 -16 55 -99 138 -154 154 -23 7 -67 12
    -98 12 l-56 0 0 55 c0 67 -29 105 -80 105 -19 0 -40 -9 -55 -25 -25 -24 -25
    -26 -25 -213 0 -171 2 -192 19 -213 14 -18 29 -24 61 -24 58 0 80 28 80 101
    l0 54 55 0 c46 0 60 -4 81 -25 l25 -25 -3 -363 c-4 -346 -5 -365 -27 -432 -43
    -130 -88 -203 -185 -300 -100 -100 -177 -146 -306 -186 -108 -34 -292 -34
    -400 0 -243 75 -410 241 -491 486 -22 67 -23 86 -27 432 l-3 363 25 25 c21 21
    35 25 81 25 l55 0 0 -55 c0 -67 29 -105 80 -105 19 0 40 9 55 25 25 24 25 26
    25 215 0 189 0 191 -25 215 -15 16 -36 25 -55 25 -19 0 -40 -9 -55 -25z
    m-1082 -3109 c103 -43 128 -177 48 -257 -65 -65 -157 -65 -222 0 -124 123 13
    325 174 257z"/>
    <path d="M1045 2389 c-346 -50 -670 -302 -801 -623 -81 -199 -78 -162 -82
    -898 l-3 -658 25 -25 25 -25 1711 0 1711 0 24 25 25 24 0 431 0 431 -25 24
    c-32 33 -78 33 -110 0 -24 -23 -25 -29 -25 -160 l0 -135 -200 0 -200 0 0 134
    c0 120 -2 136 -21 160 -28 35 -80 36 -114 1 l-25 -24 0 -376 0 -375 -1040 0
    -1040 0 0 375 0 376 -25 24 c-32 33 -78 33 -110 0 -24 -23 -25 -29 -25 -160
    l0 -135 -201 0 -201 0 4 353 c4 324 6 358 26 432 101 367 418 626 797 652 75
    5 93 10 112 29 31 31 30 78 -2 109 -21 22 -33 25 -87 24 -35 -1 -90 -5 -123
    -10z m-325 -1909 l0 -160 -200 0 -200 0 0 160 0 160 200 0 200 0 0 -160z
    m2800 0 l0 -160 -200 0 -200 0 0 160 0 160 200 0 200 0 0 -160z"/>
    </g>
    </svg>
}



