import React from 'react'

export function NewPatientIcon({style={}}) {
    return <svg style={style} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M993 4896 l-28 -24 -5 -354 -5 -353 -352 -5 c-350 -5 -353 -5 -375
        -28 -17 -17 -23 -34 -23 -66 0 -53 22 -82 74 -96 21 -6 174 -10 359 -10 l322
        0 0 -346 0 -346 29 -29 c50 -49 138 -32 160 32 7 21 11 146 11 360 l0 329 319
        0 c175 0 332 3 350 6 78 16 110 113 55 168 l-27 26 -348 0 -349 0 0 336 c0
        283 -2 341 -15 369 -28 58 -103 73 -152 31z"/>
        <path d="M3016 4440 c-343 -54 -635 -271 -780 -581 -20 -41 -48 -114 -63 -164
        -25 -84 -27 -101 -27 -275 0 -173 2 -191 28 -274 59 -195 145 -335 287 -471
        l89 -84 -32 -5 c-18 -3 -64 -10 -103 -16 -156 -25 -370 -114 -509 -214 -268
        -192 -454 -489 -511 -820 -12 -73 -15 -194 -15 -683 l0 -595 29 -29 29 -29
        1727 0 1727 0 29 29 29 29 0 595 c0 489 -3 610 -15 683 -60 343 -258 652 -540
        839 -174 116 -329 173 -576 210 l-37 6 103 102 c157 157 243 314 287 527 29
        136 21 347 -16 475 -109 368 -385 636 -751 726 -93 23 -300 33 -389 19z"/>
        </g>
    </svg>
}
