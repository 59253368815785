import './DeleteHoldingTemplate.css'
import React from "react";
import { useDispatch } from 'react-redux';
import { AddAlert, AlertType } from '../../../../../../../../component';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button';
import { patchTemplateType } from '../../../../../../http';

export default function DeleteHoldingTemplate({ holdingTemplate={}, setSecondaryModal=() => null, extraActionDeleteHoldingTemplate= async () => null }) {
    const dispatch = useDispatch()

    const handleDelete = async () => {
        if (holdingTemplate?.id) {
            dispatch(loading())
            try {
                await patchTemplateType(holdingTemplate?.id, { "active": false })
                await extraActionDeleteHoldingTemplate()
                setSecondaryModal(MODAL_INIT_PROPS)
                dispatch([AddAlert('Deleção de Template', 'Template deletado com sucesso.', AlertType.SUCCESS)])
            } catch (err) {
                console.error('DeleteHoldingTemplate ~ handleDelete ~ patchTemplateType ~ ', err)
            }
            dispatch(loaded())
        }
    }

    return <div className='DeleteHoldingTemplate'>
        <p>Deseja realmente excluir este template?</p>
        <div className='DeleteHoldingTemplate-BtnBox'>
            <NewButton
                label='Não, cancelar'
                onClick={() => setSecondaryModal(MODAL_INIT_PROPS)}
                model={ButtonModel.SECONDARY}
            />
            <NewButton 
                label='Sim, excluir'
                onClick={handleDelete}
                model={ButtonModel.DANGER}
            />
        </div>
    </div>
}