import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import './ConcludeRegisterPage.css'
import { loaded, loading } from '../../../layout/redux/AppActions'
import NewSelect from '../../../newcomponents/form/select/select'
import { AddAlert, AlertType, Input, MaskCpf, MaskTelefone } from '../../../component'
import { isValidCPF } from '../../../validators'
import api from '../../../helpers/api'
import Button from '../../../newcomponents/button/button'
import { getYearsStart1900 } from '../../../utils/getYearsStart1900'
import { useApp } from '../../../layout/App'
import isValidDateExcludingFutureDates from '../../../validators/isValidDateExcludingFutureDates'

const initPersonData = {
    name: '',
    cpf: '',
    contact_phone: '',
    sex: '',
    gender: '',
    birthday_day: '',
    birthday_month: '',
    birthday_year: '',
}

const strpPerson = (person) => {
    const birthday = person?.birthday?.split('-')
    return {
        name: person?.name || '',
        cpf: MaskCpf(person?.cpf) || '',
        contact_phone: MaskTelefone(person?.contact_phone) || '',
        sex: person?.sex || '',
        gender: person?.gender || '',
        birthday_day: birthday?.[2] || '',
        birthday_month: birthday?.[1] || '',
        birthday_year: birthday?.[0] || '',
    }
}

class PersonForm {
    constructor(data) {
        let words = data.name.trim().split(' ')
        for (let i = 0; i < words.length; i++) {
            if (words[i]) words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
        }

        this.name = words.join(' ').trim()
        this.cpf = data.cpf.replace(/\D/g, '')
        this.birthday = data?.birthday_year && data?.birthday_month && data?.birthday_day 
                    ? `${data?.birthday_year}-${data?.birthday_month}-${data?.birthday_day}` : null
        this.sex = data.sex
        this.gender = data.gender
        this.contact_phone = data.contact_phone.replace(/\D/g, '')
    }

    getErrors() {
        let errors = {}
        Object.entries(this).map(([key, value]) => {          
            
            switch(true) {
                case key === 'contact_phone':
                    if (!value){
                        errors['contact_phone'] = true
                    } else if (!([10, 11].includes(value.length))){
                        errors['contact_phone'] = 'Contato inválido'
                    }
                    break
                case key === 'birthday':
                    if (!value){
                        errors['birthday_day'] = true
                        errors['birthday_month'] = true
                        errors['birthday_year'] = true
                    } else if (!isValidDateExcludingFutureDates(value)){
                        errors['birthday_day'] = 'Data inválida'
                        errors['birthday_month'] = true
                        errors['birthday_year'] = true
                    }
                    break
                case key === 'cpf':
                    if (!value){
                        errors['cpf'] = true
                    } else if (!isValidCPF(value)){
                        errors['cpf'] = 'CPF inválido'
                    }
                    break
                case key === 'gender':
                    break
                default:
                    if (!value) errors[key] = true
            }
            return null
        })
        return errors
    }
}

export default function ConcludeRegisterPage() {
    const dispatch = useDispatch()
    const defaultOptions = require('../../../component/form/select/options.json')
    const { currentHealthPlaceUser, logOut, loged } = useApp()
    const [personData, setPersonData] = useState(strpPerson(currentHealthPlaceUser?.user?.person))
    const [personFormErr, setPersonFormErr] = useState(initPersonData)
    const [hasSubmitted, setHasSubmitted] = useState(false)

    const handleSelect = (event) => {
        setPersonData(prev => ({ ...prev, [event.target.id]: event.target.selected }))
        if(hasSubmitted){
            const error = verifyFields(event.target.id, event.target.selected, personData)
            setPersonFormErr(prev => ({...prev, ...error}))
        }
    }
    
    const handleChange = (event) => {
        switch (event.target.name) {
            case 'contact_phone':
                setPersonData(prev => ({ ...prev, contact_phone: MaskTelefone(event.target.value.replace(/\D/g, '')) }))
                break
            case 'cpf':
                setPersonData(prev => ({ ...prev, cpf: MaskCpf(event.target.value.replace(/\D/g, '')) }))
                break
            default:
                setPersonData(prev => ({ ...prev, [event.target.name]: event.target.value }))
        }
        if (hasSubmitted){
            const error = verifyFields(event.target.name, event.target.value, personData)
            setPersonFormErr(prev => ({...prev, ...error}))
        }
    }

    const verifyFields = (field, value, personData)=> {
        let error = {};

        if (field === 'contact_phone') {
            const digitsOnly = value.replace(/\D/g, '');
            if (!digitsOnly) {
                error['contact_phone'] = true;
            }
            if (![10, 11].includes(digitsOnly.length)) {
                error['contact_phone'] = 'Contato inválido';
            } else {
                error['contact_phone'] = '';
            }
        } else if (['birthday_day', 'birthday_month', 'birthday_year'].includes(field)) {
            const { birthday_day, birthday_month, birthday_year } = {
                birthday_day: field === 'birthday_day' ? value : personData.birthday_day,
                birthday_month: field === 'birthday_month' ? value : personData.birthday_month,
                birthday_year: field === 'birthday_year' ? value : personData.birthday_year
            };

            if (!birthday_day) {
                error['birthday_day'] = true
            } else {
                error['birthday_day'] = ''
            }
    
            if (!birthday_month) {
                error['birthday_month'] = true
            } else {
                error['birthday_month'] = ''
            }
    
            if (!birthday_year) {
                error['birthday_year'] = true
            } else {
                error['birthday_year'] = ''
            }
        } else if (field === 'cpf') {
            if (!value) {
                error['cpf'] = true
            } else if (!isValidCPF(value)) {
                error['cpf'] = 'CPF inválido';
            } else {
                error['cpf'] = '';
            }
        } else if (field === 'gender'){
            return error
        } else {
            if (!value) {
                error[field] = true;
            } else {
                error[field] = '';
            }
        }
    
        return error;
    }

    const handleSubmit = () => {
        setHasSubmitted(true)
        const payload = new PersonForm(personData)
        let errors = payload.getErrors()
        if (Object.keys(errors).length) return setPersonFormErr(errors)
        
        dispatch(loading())
        api.post('health_place_user/registration/user/create_person/', payload)
            .then(async () => {
                dispatch([
                    loaded(),
                    AddAlert('Dados pessoais', 'Salvo com sucesso!', AlertType.SUCCESS),
                ])
                await loged() 
            })
            .catch(error => {
                dispatch([loaded(), AddAlert('Dados pessoais', 'Falha ao salvar, revise seus dados e tente novamente!', AlertType.ERROR)])
                console.error(error)
            })
    }

    return <div className='ConcludeRegisterPage'>
        <section>
            <h3 className='ConcludeRegisterPage-Title'>Salutho</h3>
            <div className='ConcludeRegisterPage-Headline'>
                <h4>Cadastro dados pessoais</h4>
                <span>Precisamos saber mais um pouco de você.</span>
            </div>
            <>
                <span><b className='Label-Asterisk'>*</b> Email</span>
                <Input 
                    name='email' 
                    value={currentHealthPlaceUser?.user?.email}
                    disabled
                />
                <span><b className='Label-Asterisk'>*</b> Nome</span>
                <Input 
                    name='name'
                    value={personData.name}
                    action={handleChange}
                    errors={{ error: personFormErr, message: personFormErr?.name }}
                />
                <div className='ConcludeRegisterPage-ChildBirthRow'>
                    <span><b className='Label-Asterisk'>*</b> Data de nascimento</span>
                    <div>
                        <NewSelect
                            id='birthday_day'
                            defaultText='Dia'
                            options={defaultOptions.days}
                            onSelect={handleSelect}
                            selected={personData.birthday_day}
                            error={personFormErr?.birthday_day}
                        />
                        <NewSelect
                            id='birthday_month'
                            defaultText='Mês'
                            options={defaultOptions.months}
                            onSelect={handleSelect}
                            selected={personData.birthday_month}
                            error={personFormErr?.birthday_month}
                        />
                        <NewSelect 
                            id='birthday_year'
                            defaultText='Ano'
                            options={getYearsStart1900()}
                            onSelect={handleSelect}
                            selected={personData.birthday_year}
                            error={personFormErr?.birthday_year}
                        />
                    </div>
                </div>
                <div className='ConcludeRegisterPage-RowCol2'>
                    <div>
                        <span><b className='Label-Asterisk'>*</b> CPF</span>
                        <Input
                            name='cpf'
                            value={personData.cpf}
                            maxLength={14}
                            action={handleChange}
                            errors={{ error: personFormErr, message: personFormErr?.cpf }}
                        />
                    </div>
                    <div>
                        <span><b className='Label-Asterisk'>*</b> Telefone</span>
                        <Input 
                            name='contact_phone'
                            maxLength={15}
                            value={personData.contact_phone}
                            action={handleChange}
                            errors={{ error: personFormErr, message: personFormErr?.contact_phone }}
                        />
                    </div>
                </div>
                <div className='ConcludeRegisterPage-RowCol2'>
                    <div>
                        <span><b className='Label-Asterisk'>*</b> Sexo</span>
                        <NewSelect 
                            id='sex'
                            canBeEmpty={false}
                            options={defaultOptions.SEX_CHOICES}
                            onSelect={handleSelect}
                            selected={personData.sex}
                            error={personFormErr?.sex}
                        />
                    </div>
                    <div>
                        <span>Gênero</span>
                        <NewSelect 
                            id='gender'
                            canBeEmpty={false}
                            options={defaultOptions.GENDER_CHOICES}
                            onSelect={handleSelect}
                            selected={personData.gender}
                            error={personFormErr?.gender}
                        />
                    </div>
                </div>
                <div className='ConcludeRegisterPage-BtnBox'>
                    <Button
                        label='Salvar'
                        onClick={handleSubmit}
                    />
                </div>
                <span className='Label-Mandatory-Filling'>* Preenchimento obrigatório</span>
            </>
            <div className='ConcludeRegisterPage-CancelRegister'>
                <span>Quer continuar mais tarde? <b onClick={() => logOut()}>Sair</b></span>
            </div>
        </section>
    </div>
}