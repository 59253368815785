import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ImagemCropper } from '../../../../../../../../component'
import NewButton, {
	ButtonModel,
} from '../../../../../../../../newcomponents/button/button'
import { useHealthPlaceForm } from '../SaveHealthPlaceModal'
import { useConfiguration } from '../../../../../../configuration'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import { createHealthPlaceBranch } from '../../../../../../http'
import replaceEmptyWithNull from '../../../../../../../../utils/replaceEmptyWithNull'
import {
	AddAlert,
	AlertType,
} from '../../../../../../../../component/alert/actions'
import { useApp } from '../../../../../../../../layout/App'
import { validarCNPJ } from '../../../../../../../../component/validation/Validation'

const parseHealthPlace = (healthPlace) => {
	return {
		address: { ...healthPlace.address, city: healthPlace.address.city.id },
		name: healthPlace.name,
		technical_responsible: healthPlace.technical_responsible.id,
		cnes: healthPlace.cnes,
		type: healthPlace?.type?.id,
		cnpj: healthPlace.cnpj.replace(/\D/g, ''),
		contact_whatsapp: healthPlace.contact_whatsapp.replace(/\D/g, ''),
		timetable: healthPlace.timetable,
		headquarter: healthPlace.headquarter || null,
	}
}

const validateFields = (healthplace) => {
	const errors = {};

    Object.keys(healthplace).forEach((field) => {
        const value = healthplace[field];
        const isError = handleValidateField(field, value);

        if (isError) {
            errors[field] = true;
        }
    });

    return errors;
}

const handleValidateField = (name, value) => {
    let error = false;

    switch (name) {
        case 'cnpj':
            if (!value) {
                error = true;
            } else {
                const validCnpj = validarCNPJ(value);
                error = validCnpj === 'invalido' ? true : false;
            }
            break;

        case 'contact_whatsapp':
            error = value.length < 10;
            break;

        case 'type':
            error = !value.id;
            break;

        case 'technical_responsible':
            error = false;
            break;

        case 'cnes':
            error = false;
            break;
		
		case 'address':
			error = validateAddressFields(value)
			break

        default:
            if (!value) {
                error = true;
            }
            break;
    }

    return error;
};

const validateAddressFields = (address) => {
	let error = false;

    if (!address?.city?.id) {
        error = true;
    }

    if (!address.address) {
        error = true;
    }

    if (!address.zip) {
        error = true;
    }

	if (!address.number || isNaN(address.number)){
		error = true
	}

    return error;
}

export default function HealthPlaceLogoData() {
	const dispatch = useDispatch()
	const { currentHealthPlaceUser } = useApp()
	const { setModalProps, fetchHealthPlaceBranches } = useConfiguration()
	const { timetableRef, base64AvatarRef, selectedHealthPlace } = useHealthPlaceForm()
	const [validacao, setValidacao] = useState({ base64_avatar: base64AvatarRef.current, })
	const [errorMessage, setErrorMessage] = useState('')

	const handleChange = (event) => {
		base64AvatarRef.current = event.target.value
		setValidacao({ ...validacao, base64_avatar: base64AvatarRef.current })
	}

	const handleSubmit = async () => {
		const errors = validateFields({
			...selectedHealthPlace,
			timetable: replaceEmptyWithNull(timetableRef.current),
			base64_avatar: base64AvatarRef.current,
			headquarter: currentHealthPlaceUser?.health_place?.id,
		})

		if (Object.keys(errors).length > 0) {
			let errorMessage = 'Verifique os dados inválidos ou obrigatórios na(s) aba(s): ';

			const generalData = ['cnpj', 'contact_whatsapp', 'type', 'name']

			const hasGeneralDataError = generalData.some(field => errors[field]);
			const hasAddressError = !!errors.address;

			if (hasAddressError) {
				errorMessage += 'Endereço, ';
			}
		
			if (hasGeneralDataError) {
				errorMessage += 'Dados Gerais, ';
			}

			if (errorMessage.endsWith(', ')) {
				errorMessage = errorMessage.slice(0, -2);
			}

			setErrorMessage(errorMessage)
		
			dispatch(
				AddAlert('Erro na validação', 'Verifique os campos com erro.', AlertType.ERROR)
			)
			return
		}
		const payload = parseHealthPlace({
			...selectedHealthPlace,
			timetable: replaceEmptyWithNull(timetableRef.current),
			base64_avatar: base64AvatarRef.current,
			headquarter: currentHealthPlaceUser?.health_place?.id,
		})
		await createHealthPlaceBranch(payload)
			.then(() => {
				setModalProps(MODAL_INIT_PROPS)
				dispatch(AddAlert('Filial cadastrada com sucesso', 'A filial foi cadastrada com sucesso.',AlertType.SUCCESS));
				fetchHealthPlaceBranches()
			})
			.catch((err) => {
				console.error('Save health place error', err)

				const errorMessage = err.response?.data?.error;

				if (errorMessage === 'CNES already exists.') {
					dispatch(AddAlert('Erro ao cadastrar filial', 'O CNES fornecido já está cadastrado no nosso sistema, corrija para poder prosseguir.',AlertType.ERROR));
				} else if (errorMessage === 'CNPJ already exists.') {
					dispatch(AddAlert('Erro ao cadastrar filial', 'O CNPJ fornecido já está cadastrado no nosso sistema, corrija para poder prosseguir.',AlertType.ERROR));
					setErrorMessage('Volte à aba de Dados Gerais para corrigir o campo inválido')
				} else {
					dispatch(AddAlert('Erro ao cadastrar filial', 'Ocorreu um erro ao cadastrar a filial.',AlertType.ERROR));
				}        		
			})
	}

	return (
		<div className='HealthPlaceForm-Body HealthPlaceLogoData'>
			<div>
				<ImagemCropper
					name='base64_avatar'
					textBtn='Selecionar logo'
					value={base64AvatarRef.current}
					action={handleChange}
					config={{
						style: { height: 200, width: '100px' },
						cropBoxResizable: true,
						initialAspectRatio: 4 / 1,
					}}
				/>
			</div>
			<div className='HealthPlaceForm-end'>
				<p className='Title-Asterisk'>{errorMessage}</p>
				<div className='HealthPlaceForm-Btn-Box'>
					<NewButton
						model={ButtonModel.SECONDARY}
						label=' Fechar'
						onClick={() => setModalProps(MODAL_INIT_PROPS)}
					/>
					<NewButton
						label='Cadastrar'
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</div>
	)
}
