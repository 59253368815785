import './HIVHbsAgAntiHCVVDRLView.css'
import React from 'react'
import { getAntiHCVResult, getHIVResult, getHbsAgResult, getVDRLResult } from '../utils'
import { Button, IcoBin, IcoPencil, List } from '../../../../../../../../../../../../../../../component'
import AlertRowText from '../../../../../../../../../../../../../../../component/alertRowText/alertRowText'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import { convertDate } from '../../../../../../../../../../../../../../../utils/convertDate'
import SerologicalTestHIVHbsAgAntiHCVVDRLWrite from '../Write/HIVHbsAgAntiHCVVDRLWrite'
import api from '../../../../../../../../../../../../../../../helpers/api'

export default function SerologicalTestHIVHbsAgAntiHCVVDRLView() {
	const { 
		selectedLanguage, 
		holdingProject: { hiv_hbs_antihcv_vdrl_exams }, 
		setModalInfo,
		fetchThousandDaysProjects
	} = useThousandDays()

	const {
		Hiv,
		HbsAg,
		Antihcvandvdrl,
		Change,
		Reagent,
		Notreagent,
		Indeterminate,
		Notevaluated,
		Greaterthan,
		Dates,
		Conclusion,
		Notinformation,
	} = require('../../../../../../../../../../../../../../data/translation.json')[selectedLanguage]

	const translate = {
		REAGENT: Reagent,
		NON_REAGENT: Notreagent,
		INDETERMINATE: Indeterminate,
		NOT_EVALUATED: Notevaluated,
		ONE_BY_ONE: '1 / 1',
		ONE_BY_TWO: '1 / 2',
		ONE_BY_FOUR: '1 / 4',
		ONE_BY_EIGHT: '1 / 8',
		ONE_BY_SIXTEEN: '1 / 16',
		ONE_BY_THIRTY_TWO: '1 / 32',
		ONE_BY_SIXTY_FOUR: '1 / 64',
		GREATER_ONE_BY_SIXTY_FOUR: `${Greaterthan} 1 / 64`,
	}

	const headDefaultHIVHBSAg = [
		{ colunm: 'h_hiv', text: 'HIV' },
		{ colunm: 'h_hiv_conclusion', text: Conclusion },
		{ colunm: 'h_hbsag', text: 'HbsAg' },
		{ colunm: 'h_hbsag_conclusion', text: Conclusion },
		{ colunm: 'h_date', text: Dates, width: '100px' },
		{ colunm: 'h_action', text: '', width: '100px' },
	]

	const headDefaultAntiHCVVDRL = [
		{ colunm: 'h_anti_hcv', text: 'Anti-HCV' },
		{ colunm: 'h_anti_hcv_conclusion', text: Conclusion },
		{ colunm: 'h_vdrl', text: 'VDRL' },
		{ colunm: 'h_vdrl_conclusion', text: Conclusion },
		{ colunm: 'h_date', text: Dates, width: '100px' },
		{ colunm: 'h_action', text: '', width: '100px' },
	]

	const handleDelete = async (id) => {
		await api.delete(`exam/serological_test_hiv_hbs_hcv_vdrl/${id}/`)
		fetchThousandDaysProjects()
	}

	const openExamWriteModal = (holdingExam) => {
		setModalInfo(prev => ({
			...prev,
			open: true,
			title: `${Hiv}, ${HbsAg}, ${Antihcvandvdrl}`,
			content: (
				<div className='SOAP-ThousandDaysExamSubSection-Modal'>
					<SerologicalTestHIVHbsAgAntiHCVVDRLWrite
						holdingSerologicalTestHIVHbsAgAntiHCVVDRL={holdingExam} 
						setModalInfo={setModalInfo} 
					/>
				</div>
			),
		}))
	}

	return (
		<div className='SerologicalTestHIVHbsAgAntiHCVVDRLView'>
			<div className='SerologicalTestHIVHbsAgAntiHCVVDRLView-List'>
				<List
					head={headDefaultHIVHBSAg}
					data={hiv_hbs_antihcv_vdrl_exams}
					noData={Notinformation}
					listCustom={(exam, i) => {
						exam['h_hiv'] = translate[exam?.hiv] || '-'
						exam['h_hiv_conclusion'] = getHIVResult(exam.hiv, Change)?.content
							? <AlertRowText {...getHIVResult(exam.hiv, Change)} />
							: '-'
						exam['h_hbsag'] = translate[exam?.hbsag] || '-'
						exam['h_hbsag_conclusion'] = getHbsAgResult(exam.hbsag, Change)?.content
							? <AlertRowText {...getHbsAgResult(exam.hbsag, Change)} />
							: '-'
						exam['h_date'] = exam?.date ? convertDate(exam.date, '-', '/') : '-'
						exam['h_action'] = (
							<div>
								<Button
									color='secondary'
									type='btn circle'
									action={() => openExamWriteModal(exam)}
									title='Editar exame'>
									<IcoPencil />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									action={() => handleDelete(exam?.id)}
									title='Excluir exame'>
									<IcoBin />
								</Button>
							</div>
						)
						return exam
					}}
				/>
				<List
					head={headDefaultAntiHCVVDRL}
					data={hiv_hbs_antihcv_vdrl_exams}
					noData={Notinformation}
					listCustom={(exam, i) => {
						exam['h_anti_hcv'] = translate[exam?.anti_hcv]
						exam['h_anti_hcv_conclusion'] = getAntiHCVResult(exam.anti_hcv, Change)?.content
							? <AlertRowText {...getAntiHCVResult(exam.anti_hcv, Change)} />
							: '-'
						exam['h_vdrl'] = translate[exam?.vdrl] || '-'
						exam['h_vdrl_conclusion'] = getVDRLResult(exam.vdrl, Change)?.content
							? <AlertRowText {...getVDRLResult(exam.vdrl, Change)} />
							: '-'
						exam['h_date'] = exam?.date ? convertDate(exam.date, '-', '/') : '-'
						exam['h_action'] = (
							<div>
								<Button
									color='secondary'
									type='btn circle'
									action={() => openExamWriteModal(exam)}
									title='Editar exame'>
									<IcoPencil />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									action={() => handleDelete(exam?.id)}
									title='Excluir exame'>
									<IcoBin />
								</Button>
							</div>
						)
						return exam
					}}
				/>
			</div>
		</div>
	)
}
