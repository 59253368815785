import './TextAppointmentField.css'
import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAttendance } from '../../../../consultation';
import PasteIco from '../../../../../../component/icon/paste';
import PastTextAppointmentField from './PastTextAppointmentField/PastTextAppointmentField';

export default function TextAppointmentField({ field_type, name, notes='' }) {
    const searchTimeout = useRef(null)
    const {
        attendance,
        setAttendance,
        isEditable,
        savePartialAppointmentTextField,
        setModalInfo,
    } = useAttendance()

    const handleChangeAppointmentTextField = (fieldType, value) => {
        setAttendance(prev => {  
            const { appointment_text_fields = [], id } = prev;
    
            let updatedFields = [...appointment_text_fields];

            const fieldIndex = updatedFields.findIndex(field => field.field_type === fieldType);
            if (fieldIndex !== -1) {
                updatedFields[fieldIndex] = { ...updatedFields[fieldIndex], "text": value };
            } else {
                updatedFields.push({ "appointment": id, "field_type": fieldType, "text": value, });
            }

            const newState = { ...prev, appointment_text_fields: updatedFields }
            return newState
        })

        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }

        searchTimeout.current = setTimeout(() => {
            savePartialAppointmentTextField(fieldType, value)
        }, 500)
    };

    const handleBlurAppointmentTextField = (field_type, text) => {
        savePartialAppointmentTextField(field_type, text)
    }

    return (
    <div >
        {name && <div 
            className='TextAppointmentField-ConsultationSubtitle'
        >
            
            {name}
            <div
                title='Preenchimentos anteriores'
                onClick={() => setModalInfo(prev => ({
                    ...prev,
                    open: true,
                    title: `${name} - Preenchimentos anteriores`,
                    content: <PastTextAppointmentField field_type={field_type} attendance={attendance} />
                }))}
            >
                <PasteIco />
            </div>
            
        </div>
        }
        <p>{notes}</p>  
        <ReactQuill
            id={field_type}
            theme="snow"
            value={attendance?.appointment_text_fields?.find((unit) => unit?.field_type == field_type)?.text || ''}
            onChange={(value) => handleChangeAppointmentTextField(field_type, value)}
            readOnly={!isEditable}
            onBlur={(previousRange, source, editor) => handleBlurAppointmentTextField(field_type, editor.getHTML())}
        />
    </div>
    );
}