export const getYearsRangeTodayToPlus50 = () => {
    let years = []
    let targetDate = new Date().getFullYear() + 50
    let currentDate = new Date().getFullYear()

    while (targetDate >= currentDate) {
        years.push({ id: `${currentDate}`, name: `${currentDate}` })
        currentDate++;
    }
    return years
}

export const getYearsRangeTodayToPlus2 = () => {
    let years = []
    let targetDate = new Date().getFullYear() + 2
    let currentDate = new Date().getFullYear()

    while (targetDate >= currentDate) {
        years.push({ id: `${currentDate}`, name: `${currentDate}` })
        currentDate++;
    }
    return years
}