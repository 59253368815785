import './alertRowText.css'
import React from 'react'
import CheckOutlinedIcon from '../icon/check-outlined'
import InfoOutlinedIcon from '../icon/info-outlined'
import WarningOutlinedIcon from '../icon/warning-outlined'

export const AlertRowTextType = {
    SUCCESS: 0,
    WARNING: 1,
    INFO: 2,
}

const AlertIcon = ({ type }) => {
    switch (type) {
      case AlertRowTextType.SUCCESS:
        return <CheckOutlinedIcon style={{ fill: '#14A44D' }} />
      case AlertRowTextType.WARNING:
        return <WarningOutlinedIcon style={{ fill: '#F95C5C' }} />
      case AlertRowTextType.INFO:
        return <InfoOutlinedIcon style={{ fill: '#aaa' }} />
      default:
        return null
    }
}

function getClassName(type) {
    switch (type) {
        case AlertRowTextType.SUCCESS:
            return 'AlertRowText-success'
        case AlertRowTextType.WARNING:
            return 'AlertRowText-error'
        case AlertRowTextType.INFO:
            return 'AlertRowText-info'
        default:
            return ''
    }
}

export default function AlertRowText({ type=AlertRowTextType.WARNING, content='', outlined=false }) {
    const className = getClassName(type)

    return <div className={`AlertRowText ${outlined ? 'AlertRowText-Outlined' : ''} ${className}`}>
        <div className='AlertRowText-icon'><AlertIcon type={type} /></div> 
        <div><span className='AlertRowText-span'>{content}</span></div>
    </div>
}