import api from "../../../../../../../../helpers/api";
import getParams from "../../../../../../../../utils/getParams";

export async function getInventoryMaterialManufacturers(params={}) {
    params = getParams({ ...params, limit: 200 })
    const url = `/inventory/manufacturer/${params}`
    let response = api.get(url)

    return response
}

export async function postInventoryMaterialManufacturer(payload={}) {
    const url = '/inventory/manufacturer/'
    let response = api.post(url, payload)
    
    return response
}

export async function patchInventoryMaterialManufacturer(id, payload={}) {
    const url = `/inventory/manufacturer/${id}/`
    let response = api.patch(url, payload)

    return response
}

export async function deleteInventoryMaterialManufacturer(id) {
    const url = `inventory/manufacturer/${id}/`
    let response = api.delete(url)

    return response
}