import './PersonalDataAddressModal.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	AddAlert,
	AlertType,
	IcoSearch,
	Input,
	MaskItemCep,
	Select,
} from '../../../../../../../../component'
import ActionForm from '../../../../../../../../component/form/actions/actions'
import getCEP from '../../../../../../../../utils/getCEP'
import NewButton, {ButtonModel} from '../../../../../../../../newcomponents/button/button'
import {
	listaCidades,
	loaded,
	loading,
} from '../../../../../../../../layout/redux/AppActions'
import api from '../../../../../../../../helpers/api'
import { useConfiguration } from '../../../../../../configuration'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import { useApp } from '../../../../../../../../layout/App'

class AddressForm {
	constructor(data) {
		this.id = data?.id || null
		this.zip = data.zip.replace(/\D/g, '')
		this.address = data.address.trim()
		this.number = data.number.trim()
		this.complement = data.complement.trim()
		this.district = data.district.trim()
		this.city = { ...data.city, name: data.city.name }
	}

	getErrors() {
		const errors = {}
		const keys = ['zip', 'address', 'number', 'district', 'city']
		keys.forEach(key => {
			switch (key) {
				case 'city':
					if (!this?.city?.name) errors['city'] = 'Campo obrigatório'
					break
				default:
					if (this[key] === '' || this[key] === undefined)
						return (errors[key] = 'Campo obrigatório')
			}
		})
		return errors
	}

	format() {
		return { ...this, city: this.city.id, zip: this.zip }
	}
}

export default function PersonalDataAddressModal({ fetchExtraAction= () => null }) {
	const dispatch = useDispatch()
	const { loged } = useApp()
	const { listas } = useSelector(state => state.appState)
	const [addressFormErr, setAddressFormErr] = useState({})
  	const { setModalProps, addressData, setAddressData } = useConfiguration()

	useEffect(() => {
		dispatch(listaCidades())
		// eslint-disable-next-line
	}, [])
	const handleZipCode = zipcode => {
		dispatch(loading())
		getCEP(zipcode)
			.then(address => {
				dispatch(loaded())
				setAddressData({ ...address, number: '', complement: '' })
			})
			.catch(() => {
				dispatch([
					loaded(),
					AddAlert('Consulta de CEP', 'CEP não encontrado', AlertType.ERROR),
				])
				setAddressData({})
			})
	}

	const handleChangeInfo = event => {
		setAddressFormErr({})
		setAddressData(prev => ({
			...prev,
			[event.target.name]: event.target.value,
		}))
	}

	const handleSave = async () => {
		const payload = new AddressForm(addressData)
		let errors = payload.getErrors()
		if (Object.keys(errors).length > 0) return setAddressFormErr(errors)

		try {
			dispatch(loading())
			if (payload?.id) {
				await api.patch(`/address/${payload?.id}/`, payload.format())
					.then(() => {
						setModalProps(MODAL_INIT_PROPS)
						fetchExtraAction()
					})
			} else {
				await api.post('health_place_user/registration/user/create_address/', payload.format())
					.then(() => setModalProps(MODAL_INIT_PROPS))
			}
			await loged()
			dispatch([loaded(), AddAlert('Meu endereço','Endereço salvo com sucesso!',AlertType.SUCCESS)])
		} catch (err) {
			console.error(err)
			dispatch([
				loaded(),
				AddAlert('Meu endereço', 'Falha ao salvar endereço.', AlertType.ERROR),
			])
		}
	}

	return (
		<section className='AddressDataModalModal'>
			<div className='row'>
				<div className='col-12 col-sm-5'>
						<b>CEP</b>
						<Input
							name='zip'
							action={handleChangeInfo}
							value={MaskItemCep(addressData.zip || '')}
							maxLength='9'
							required={{ erro: addressFormErr, message: 'Campo obrigatório' }}
						>
							<ActionForm
								action={() => handleZipCode(addressData.zip)}
								title='Pesquisar'
							>
								<IcoSearch />
							</ActionForm>
						</Input>
				</div>
				<div className='col-12 col-sm-7' />
				<div className='col-4 col-sm-3'>
					<b>Estado</b>
					<Input
						name='state'
						value={addressData.city?.state}
						disabled
					/>
				</div>
				<div className='col-8 col-sm-5'>
					<b>Cidade</b>
					<Select
						name='city'
						options={listas.cidades}
						action={handleChangeInfo}
						selected={addressData.city}
						required={{ erro: addressFormErr, message: 'Campo obrigatório' }}
						error={addressFormErr.city?.name}
					/>
				</div>
				<div className='col-12 col-sm-5'>
					<b>Bairro</b>
					<Input
						name='district'
						action={handleChangeInfo}
						value={addressData.district}
						required={{ erro: addressFormErr, message: 'Campo obrigatório' }}
					/>
				</div>
				<div className='col-12'>
					<b>Endereço</b>
					<Input
						name='address'
						action={handleChangeInfo}
						actionBlur={handleChangeInfo}
						value={addressData.address}
						required={{ erro: addressFormErr, message: 'Campo obrigatório' }}
					/>
				</div>
				<div className='col-12 col-sm-4'>
					<b>Número</b>
					<Input
						name='number'
						action={handleChangeInfo}
						value={addressData.number}
						maxLength={15}
						required={{ erro: addressFormErr, message: 'Campo obrigatório' }}
					/>
				</div>
				<div className='col-12'>
					<b>Complemento</b>
					<Input
						name='complement'
						action={handleChangeInfo}
						actionBlur={handleChangeInfo}
						value={addressData.complement}
					/>
				</div>
			</div>
			<div className='AddressDataModalModal-BtnBox'>
				<NewButton
					label='Fechar'
					onClick={() => setModalProps(MODAL_INIT_PROPS)}
					model={ButtonModel.SECONDARY}
				/>
				<NewButton
					label='Salvar'
					onClick={handleSave}
				/>
			</div>
		</section>
	)
}
