export default function isValidDateExcludingPastDates(dateStr = "") {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (dateStr.match(regex) === null) {
        return false;
    }

    const date = new Date(dateStr);

    const timestamp = date.getTime();

    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
        return false;
    }

    if (!date.toISOString().startsWith(dateStr)) {
        return false;
    }

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    if (date < now) {
        return false;
    }

    return true;
}
