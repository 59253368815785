import React from 'react'

import './button.css'

export var ButtonModel = {
    PRIMARY: 0,
    SECONDARY: 1,
    WARNING_OUTLINED: 2,
    PRIMARY_OUTLINED: 3,
    DANGER: 4,
    SECONDARY_BG: 5
}

export default function Button({ id, label, onClick, disabled, Icon, model = ButtonModel.PRIMARY, title }) {

    const getClasses = () => {
        let classes = 'button'

        switch (model) {
            case ButtonModel.PRIMARY:
                classes += ' primary'
                break
            case ButtonModel.SECONDARY:
                classes += ' secondary'
                break
            case ButtonModel.SECONDARY_BG:
                classes += ' secondary-bg'
                break
            case ButtonModel.WARNING_OUTLINED:
                classes += ' warning outlined'
                break
            case ButtonModel.PRIMARY_OUTLINED:
                classes += ' primary outlined'
                break
            case ButtonModel.DANGER:
                classes += ' danger'
                break
            default:
                classes += ' primary'
                break;
        }

        if (disabled) {
            classes += ' disabled'
        }

        return classes
    }

    return <div id={id} className={`button-container ${Icon ? 'with-icon' : ''}`}>

        <button className={getClasses()} onClick={onClick} disabled={disabled} title={title ? title : label}>
            {Icon && <div className='icon'>{Icon}</div>}
            <span className='button-span-label'>{label}</span>
        </button>

    </div>
}