import React from 'react'

export default function IcoPersonInformation({ style }) {
    return <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M64 4690 c-12 -4 -31 -21 -43 -36 l-21 -27 0 -2068 0 -2069 29 -32
            29 -33 2502 0 2502 0 29 33 29 32 0 2070 0 2070 -29 32 -29 33 -1047 0 -1047
            0 -30 -34 c-26 -29 -30 -39 -25 -75 4 -27 15 -49 32 -63 l26 -23 975 0 974 0
            0 -300 0 -300 -2360 0 -2360 0 0 300 0 300 971 0 c646 0 977 3 990 10 24 13
            49 61 49 94 0 14 -13 40 -29 58 l-29 33 -1034 2 c-568 1 -1043 -2 -1054 -7z
            m4856 -2530 l0 -1540 -2360 0 -2360 0 0 1540 0 1540 2360 0 2360 0 0 -1540z"/>
            <path d="M1408 3271 c-307 -101 -442 -492 -259 -750 22 -30 19 -41 -10 -41 -9
            0 -54 -19 -100 -42 -157 -77 -280 -231 -324 -403 -19 -73 -21 -400 -4 -439 27
            -58 5 -57 864 -54 l787 3 30 34 30 34 -5 201 c-3 146 -9 214 -20 249 -56 166
            -174 306 -319 376 -46 23 -92 41 -101 41 -27 0 -27 14 3 62 47 75 70 157 70
            246 0 202 -100 366 -277 454 -81 40 -87 42 -196 45 -89 2 -124 -1 -169 -16z
            m242 -192 c110 -33 200 -156 200 -273 0 -82 -22 -145 -72 -201 -58 -65 -106
            -88 -204 -93 -71 -4 -89 -1 -136 20 -108 50 -170 146 -170 268 -1 55 5 80 26
            124 32 64 100 129 159 150 51 18 146 20 197 5z m292 -799 c78 -30 144 -79 193
            -143 62 -81 85 -161 85 -293 l0 -104 -661 0 -661 0 4 128 c3 118 5 132 35 194
            50 105 153 195 263 229 14 4 175 8 357 8 309 1 337 0 385 -19z"/>
            <path d="M3159 3261 c-40 -40 -40 -89 0 -133 l29 -33 580 0 c623 0 617 0 641
            52 20 43 13 82 -18 114 l-29 29 -587 0 -587 0 -29 -29z"/>
            <path d="M3194 2770 c-65 -26 -84 -112 -35 -161 l29 -29 587 0 587 0 29 29
            c49 50 32 138 -32 160 -39 13 -1132 14 -1165 1z"/>
            <path d="M3181 2246 c-68 -38 -64 -146 6 -175 21 -8 184 -11 607 -9 l578 3 24
            28 c33 38 33 96 0 134 l-24 28 -584 2 c-471 2 -588 0 -607 -11z"/>
            <path d="M3159 1711 c-40 -40 -40 -89 0 -133 l29 -33 564 -3 c386 -2 575 0
            600 8 70 20 91 108 39 161 l-29 29 -587 0 -587 0 -29 -29z"/>
            <path d="M773 1223 c-12 -2 -34 -18 -48 -34 -35 -43 -34 -92 4 -130 l29 -29
            1802 0 1802 0 29 29 c31 32 38 71 18 114 -25 55 95 52 -1834 53 -979 1 -1790
            -1 -1802 -3z"/>
            <path d="M2524 4691 c-30 -13 -64 -59 -64 -88 0 -32 26 -80 49 -93 11 -5 34
            -10 51 -10 42 0 67 15 85 50 22 43 18 76 -12 110 -30 32 -75 45 -109 31z"/>
            <path d="M3393 4286 c-39 -18 -53 -43 -53 -94 0 -33 6 -45 31 -66 l31 -26 423
            0 424 0 26 23 c17 14 28 36 32 63 5 36 1 46 -25 75 l-30 34 -414 2 c-335 2
            -419 0 -445 -11z"/>
            <path d="M4585 4293 c-58 -15 -89 -87 -61 -141 45 -87 170 -63 183 34 4 35 1
            45 -23 72 -30 32 -64 44 -99 35z"/>
        </g>
    </svg>
}