import './SelectHealthPlaceUser.css'
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import Button, { ButtonModel } from '../../../newcomponents/button/button';
import { history } from '../../../helpers';
import AlertRowText, { AlertRowTextType } from '../../../component/alertRowText/alertRowText';
import { userRoles } from '../../../utils/userRoles';
import { useApp } from '../../App';

export default function SelectHealthPlaceUser({ healthPlaceUsers=[], loadHealthPlaceUser=() => null }) {
    const dispatch = useDispatch()
    const { logOut } = useApp()


    const isIncompleteHealthPlaceUser = (healthPlaceUser) => !healthPlaceUser?.user?.person?.councils || !healthPlaceUser?.user?.person?.id

    const handleSelect = (health_place_user_cursor) => {
        loadHealthPlaceUser(health_place_user_cursor)
    }

    return <div className='SelectHealthPlaceUser'>
        <div className='SelectHealthPlaceUser-Header'>
            <Button 
                label='Sair'
                model={ButtonModel.SECONDARY}
                onClick={() => logOut()}
            />
        </div>
        
        <div className="SelectHealthPlaceUser-Main">
            <b>Selecione a unidade de saúde que deseja visualizar</b>
            {
                !healthPlaceUsers?.length 
                ? <AlertRowText 
                    type={AlertRowTextType.INFO} 
                    content='Nenhum perfil foi achado para esse usuário' 
                    outlined 
                />
                : null
            }
            <div className='SelectHealthPlaceUser-Main-Grid'>
                {
                    healthPlaceUsers.map((health_place_user__instance, idx) => <div 
                        className={`${isIncompleteHealthPlaceUser(health_place_user__instance) ? 'SelectHealthPlaceUser-Main-Grid-Incomplete' : ''}`}
                    >
                        <p className='SelectHealthPlaceUser-Main-Grid-Title'><b>{ health_place_user__instance?.health_place?.name }</b></p>
                        <div 
                            className='SelectHealthPlaceUser-Main-Grid-Content'
                            onClick={() => handleSelect(health_place_user__instance)}
                        >
                            <div>
                                <b>E-mail</b>
                                <p>{ health_place_user__instance?.user?.email || '-' }</p>
                            </div>
                            <div>
                                <b>Perfil</b>
                                <p>{ userRoles?.[health_place_user__instance?.profile]?.title || '-' }</p>
                            </div>
                            <div>
                                <b>Nome</b>
                                <p>{ health_place_user__instance?.user?.person?.name || 'Concluir cadastro logando na Salutho' }</p>
                            </div>
                            <div>
                                <b>1000 Dias</b>
                                <p>{ health_place_user__instance?.enabled_thousand_days ? 'Habilitado' : 'Desabilitado' }</p>
                            </div>
                            <div>
                                <b>Conselho</b>
                                <p>
                                { 
                                    health_place_user__instance?.user?.person?.councils
                                    ? health_place_user__instance?.user?.person?.councils?.map(({ council, nu_crm, uf_crm }, idx) => {
                                        if (idx === 0) return `${council} ${nu_crm}/${uf_crm}`
                                        return ` | ${council} ${nu_crm}/${uf_crm}`
                                    })
                                    : 'Concluir cadastro logando na Salutho'
                                }
                                </p>
                            </div>
                        </div>
                    
                    </div>)
                }
            </div>
            {
                /**
            <div>
                <Paginate data={pagination} action={handleNextPage} />
            </div>
                 */
            }
        </div>
    </div>
}
