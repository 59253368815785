import api from "../../../../../../../../../../../../helpers/api"

export async function getDentistAppointments(attendance, project) {
    let params = attendance ? `?attendance=${attendance}` :
        project ? `?project=${project}` : ''

    if (!params) return

    let result = await api.get(`appointment/list/dentistappointment/${params}`)
    return result
}

export async function putDentistAppointment(assessment) {
    let result = await api.put('/appointment/dentist/', assessment)

    return result
}