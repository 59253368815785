import './ManageAuthenticationPage.css'
import React, { useState } from 'react'
import Button, { ButtonModel } from '../../../../../newcomponents/button/button'
import Modal, { MODAL_INIT_PROPS } from '../../../../../newcomponents/modal/modal'
import IdentifyDentistPerson from '../IdentifyDentistPerson/IdentifyDentistPerson'
import { LoginForm } from '../../../../login/login'

function IntroPageDentistAppointment({ setPage }) {
    return <>
        <div className='ManageAuthenticationPage-BtnFullWidth'>
            <Button 
                label='Possuo cadastro na Salutho'
                onClick={() => setPage('3')}
            />
        </div>
        <div className='ManageAuthenticationPage-BtnFullWidth'>
            <Button
                model={ButtonModel.SECONDARY}
                label='Não possuo cadastro na Salutho'
                onClick={() => setPage('2')}
            />
        </div>
    </>
}

export default function ManageAuthenticationPage({ patient }) {
    const [page, setPage] = useState('1')
    const [modalProps, setModalProps] = useState(MODAL_INIT_PROPS)

    const pages = {
        '1': <IntroPageDentistAppointment setPage={setPage} />,
        '2': <IdentifyDentistPerson setPage={setPage} setModalProps={setModalProps} />,
        '3': <LoginForm setDentistAppointmentPage={setPage} />
    }

    return <div className='ManageAuthenticationPage'>
        <Modal {...modalProps} dismissFn={() => setModalProps(MODAL_INIT_PROPS)} />
        <section>
            <h3 className='ManageAuthenticationPage-Title'>Salutho</h3>
            <div className='ManageAuthenticationPage-Headline'>
                <h4>Projeto 1000 Dias - Consulta odontológica</h4>
                <span>Olá dentista parceiro, precisamos seus dados para concluir a consulta d{patient?.sex === 'F' ? 'a' : 'o'} paciente <b>{patient?.name}</b>.</span>
            </div>
            { pages[page] }
            
        </section>
    </div>
}
