import './NewEditPreceptorshipRoomModal.css'
import React, { useRef, useState } from "react";
import NewButton, { ButtonModel } from '../../../../../newcomponents/button/button';
import { MODAL_INIT_PROPS } from '../../../../../newcomponents/modal/modal';
import { Input } from '../../../../../component';
import { useDispatch } from 'react-redux';
import { loaded, loading } from '../../../../../layout/redux/AppActions';
import { apiV2 } from '../../../../../helpers';

const strpHoldingRoom = (holdingRoom=null) => {
    return {
        "id": holdingRoom?.id || '',
        "name": holdingRoom?.name || '',
        "description": holdingRoom?.description || '',
        "time": holdingRoom?.time || ''
    }
}

export default function NewEditPreceptorshipRoomModal({ setModalInfo=() => null, fetchData=() => null, initHoldingRoom=null }) {
    const dispatch = useDispatch()
    const textareaRef = useRef()
    const [holdingRoom, setHoldingRoom] = useState(strpHoldingRoom(initHoldingRoom))
    const [holdingRoomErr, setHoldingRoomErr] = useState(strpHoldingRoom())
    
    const handleSubmit = async () => {
        if (!holdingRoom?.name) {
            setHoldingRoomErr(prev => ({ ...prev, name: 'Campo obrigatório' }))
            return
        }
        dispatch(loading())
        try {
            apiV2.put('/preceptorship/group', holdingRoom)
                .then(() => {
                    setModalInfo(MODAL_INIT_PROPS)
                    fetchData()
                })
        } catch (err) {
            console.error('NewEditPreceptorshipRoomModal ~ handleSubmit', err)
        }
        dispatch(loaded())
    }

    const adjustTextareaHeight = () => {
        textareaRef.current.style.height = 'auto'
        const scrollHeight = textareaRef.current.scrollHeight
        const maxHeight = parseInt(
            window.getComputedStyle(textareaRef.current).maxHeight,
            10
        )
        if (scrollHeight > maxHeight) {
            textareaRef.current.style.height = `${maxHeight}px`
        } else {
            textareaRef.current.style.height = `${scrollHeight}px`
        }
        
    }

    const handleChange = (event) => {
        setHoldingRoomErr(strpHoldingRoom())
        setHoldingRoom((prev) => ({ ...prev, [event.target.name]: event.target.value }))
    }
    
    const handleChangeTextarea = (event) => {
        adjustTextareaHeight()
        setHoldingRoom((prev) => ({ ...prev, [event.target.name]: event.target.value }))
    }

    return <div className='NewEditPreceptorshipRoomModal'>
        <div>
            <div>
                <b>Nome</b>
                <Input
                    name='name'
                    value={holdingRoom.name}
                    action={handleChange}
                    errors={{ error: holdingRoomErr, message: holdingRoomErr.name }}
                />
            </div>
            <div>
                <p><b>Descrição</b></p>
                <textarea
                    ref={textareaRef}
                    name='description'
                    placeholder=''
                    className='NewEditPreceptorshipRoomModal-Textarea'
                    value={holdingRoom.description}
                    onChange={handleChangeTextarea}
                    rows='5'
                />
            </div>
        </div>
        <div className='NewEditPreceptorshipRoomModal-BtnBox'>
            <NewButton 
                label='Fechar'
                model={ButtonModel.SECONDARY}
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
            />
            <NewButton 
                label='Salvar'
                onClick={handleSubmit}
            />
        </div>
    </div>
}