import React, { useState } from "react";
import SelectPatient from '../../../../../../../../../../component/SelectPatient/SelectPatient';
import NewButton from '../../../../../../../../../../newcomponents/button/button';
import api from "../../../../../../../../../../helpers/api";
import Modal, { MODAL_INIT_PROPS } from "../../../../../../../../../../newcomponents/modal/modal";
import { useDispatch } from "react-redux";
import ConfirmAssign from "../AssignScheduleToPatient/ConfirmAssign/ConfirmAssign";
import { AddAlert, AlertType } from "../../../../../../../../../../component";

const PageOne = ({ recurringAppointment, recurringAppointmentErr, setRecurringAppointment, setRecurringAppointmentErr, setCurrentPage }) => {
    const dispatch = useDispatch()
    const [secondaryModalInfo, setSecondaryModalInfo] = useState(MODAL_INIT_PROPS)

    const handleSelectPatient = (patient) => {
        setRecurringAppointment(prev => ({ ...prev, "person": patient }))
    }

    const continueSubmission = async (patient, redirect) => {
        let response = await api.post(`patient/activate_patient/${patient.id}/`)
  
        if (response.status !== 200) {
          dispatch(AddAlert('Ativar Paciente', 'Houve um erro ao ativar o paciente', AlertType.ERROR))
          return
        } else {
          handleSubmit(true)
        }
      }
  
    const cancelSubmission = () => {
        setSecondaryModalInfo(MODAL_INIT_PROPS)
        setRecurringAppointment((prev => ({ ...prev, "person": {} })))
      }


    const handleSubmit = (ignoreInactivate=false) => {
        if (!ignoreInactivate){
            if (!recurringAppointment?.person?.is_active){
                setSecondaryModalInfo({ 
                    open: true,
                    title: 'Paciente Inativado',
                    content: <ConfirmAssign
                        patient={recurringAppointment?.person} 
                        cancelSubmission={cancelSubmission} 
                        continueSubmission={continueSubmission}/>,
                  })
                  return
                }
        }
        setCurrentPage('2')
    }

    return (
        <div>
            <Modal {...secondaryModalInfo} dismissFn={() => setSecondaryModalInfo(MODAL_INIT_PROPS)}/>
            <SelectPatient
                label={'Pesquisar paciente'}
                selectedPatient={recurringAppointment.person} 
                handleSelectPatient={handleSelectPatient}
                inputErr={recurringAppointmentErr?.person}
            />
            <div className='RecurringPatientModal-BtnBox'>
                <NewButton 
                    label='Prosseguir'
                    disabled={!recurringAppointment?.person?.id}
                    onClick={() => handleSubmit()}
                />
            </div>
        </div>
    );
};

export default PageOne;
