import React from "react"

export default function IcoTeam({ style }) {
    return <svg style={style} className='icon-group' viewBox="0 0 512 512">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M2408 4670 c-338 -61 -618 -320 -709 -655 -30 -110 -33 -316 -5 -425
        56 -218 186 -409 360 -530 70 -48 203 -108 291 -131 113 -30 317 -30 430 0 88
        23 221 83 291 131 174 121 304 312 360 530 28 109 25 315 -5 425 -82 304 -302
        531 -611 630 -71 23 -107 28 -220 31 -74 1 -156 -1 -182 -6z m291 -305 c101
        -24 196 -79 277 -160 330 -332 160 -894 -301 -990 -132 -28 -248 -11 -381 54
        -291 144 -405 496 -257 793 70 140 228 265 379 302 78 19 204 19 283 1z"/>
        <path d="M845 3836 c-130 -32 -235 -96 -333 -201 -263 -281 -224 -740 81 -971
        71 -54 177 -105 257 -125 70 -17 229 -17 300 0 78 19 205 82 273 136 102 81
        196 232 228 365 18 77 16 231 -4 310 -60 234 -257 430 -488 485 -77 18 -244
        18 -314 1z m259 -298 c101 -27 208 -130 243 -233 23 -71 20 -176 -7 -250 -32
        -83 -123 -174 -205 -205 -131 -49 -277 -22 -378 70 -199 181 -141 503 109 605
        67 28 164 33 238 13z"/>
        <path d="M3956 3835 c-229 -58 -423 -253 -483 -485 -20 -79 -22 -233 -4 -310
        32 -133 126 -284 228 -365 68 -54 195 -117 273 -136 71 -17 230 -17 300 0 80
        20 186 71 257 125 249 188 327 537 185 826 -28 58 -57 96 -127 165 -91 91
        -148 127 -270 170 -69 25 -278 30 -359 10z m290 -306 c254 -95 318 -425 117
        -609 -101 -92 -247 -119 -378 -70 -82 31 -173 122 -205 205 -27 74 -30 179 -7
        250 33 99 142 206 236 232 66 19 175 15 237 -8z"/>
        <path d="M1944 2801 c-192 -47 -373 -179 -466 -339 -19 -34 -35 -51 -44 -48
        -51 20 -213 27 -496 24 -288 -4 -319 -7 -388 -27 -130 -39 -224 -95 -325 -196
        -100 -99 -154 -188 -197 -325 -23 -74 -23 -79 -23 -575 0 -550 0 -554 61 -656
        58 -97 154 -171 263 -203 49 -14 274 -16 2231 -16 1957 0 2182 2 2231 16 109
        32 205 106 263 203 61 102 61 106 61 656 0 496 0 501 -23 575 -43 137 -97 226
        -197 325 -101 101 -195 157 -325 196 -69 20 -100 23 -388 27 -283 3 -445 -4
        -496 -24 -9 -3 -25 14 -44 48 -69 119 -187 224 -325 288 -149 69 -160 70 -764
        69 -477 0 -546 -2 -609 -18z m1177 -297 c85 -25 139 -57 198 -115 58 -59 90
        -113 115 -198 14 -48 16 -140 16 -753 l0 -698 -890 0 -890 0 0 698 c0 756 -1
        742 55 852 28 55 116 145 170 173 109 56 109 56 661 56 441 1 518 -1 565 -15z
        m-1763 -377 c9 -6 12 -157 12 -698 l0 -689 -484 0 -483 0 -34 23 c-69 46 -69
        42 -69 523 0 252 4 454 10 485 34 179 183 328 360 358 66 11 670 9 688 -2z
        m3082 2 c185 -29 335 -174 370 -358 6 -31 10 -233 10 -485 0 -481 0 -477 -69
        -523 l-34 -23 -484 0 -483 0 2 693 3 692 35 6 c60 11 582 9 650 -2z"/>
    </g>
   </svg>
}
