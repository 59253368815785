import React from 'react'

export default function WarningIcon({ width, height, color }) {
    return <svg width={width} height={height} version="1.0" viewBox="0 0 512 512">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
    fill={color} stroke="none">
    <path d="M2466 4850 c-137 -25 -234 -76 -331 -175 -67 -67 -119 -155 -1063
    -1791 -546 -947 -1003 -1743 -1016 -1770 -39 -83 -51 -143 -51 -254 0 -75 6
    -121 19 -160 71 -210 216 -354 421 -417 57 -17 148 -18 2115 -18 1967 0 2058
    1 2115 18 205 63 350 207 421 417 13 39 19 85 19 160 0 111 -12 171 -51 254
    -13 26 -470 823 -1016 1769 -975 1691 -994 1723 -1068 1797 -112 113 -228 164
    -390 174 -41 3 -97 1 -124 -4z m168 -305 c70 -18 133 -60 168 -112 15 -21 468
    -803 1008 -1738 624 -1082 987 -1720 997 -1754 40 -140 -43 -303 -182 -357
    -49 -18 -103 -19 -2065 -19 -1962 0 -2016 1 -2065 19 -65 25 -137 94 -166 159
    -33 73 -32 183 0 247 90 173 1992 3450 2018 3477 38 38 96 69 150 82 56 13 73
    13 137 -4z"/>
    <path d="M2410 2610 l0 -750 150 0 150 0 0 750 0 750 -150 0 -150 0 0 -750z"/>
    <path d="M2492 1549 c-48 -14 -109 -80 -123 -131 -23 -89 12 -182 88 -229 57
    -36 154 -34 210 3 62 41 88 90 88 168 0 77 -26 127 -85 166 -43 29 -125 39
    -178 23z"/>
    </g>
    </svg>
}
