import { useDentistAppointment } from '../../DentistAppointment';
import './Plan.css'
import React, { useRef } from 'react'

export default function DentistAppointmentPlan({ Treatmentplacehold }) {
	const textareaRef = useRef(null)
	const { assessment, setAssessment } = useDentistAppointment()

	const adjustTextareaHeight = () => {
		textareaRef.current.style.height = 'auto'
		const scrollHeight = textareaRef.current.scrollHeight
		const maxHeight = parseInt(
			window.getComputedStyle(textareaRef.current).maxHeight,
			10
		)
		if (scrollHeight > maxHeight) {
			textareaRef.current.style.height = `${maxHeight}px`
		} else {
			textareaRef.current.style.height = `${scrollHeight}px`
		}
	}

	const handleChange = (event) => {
		adjustTextareaHeight()
		setAssessment((prev) => ({
			...prev,
			[event.target.id]: event.target.value,
		}))
	}

	return (
		<div>
			<textarea
				ref={textareaRef}
				id='treatment_plan'
				placeholder={Treatmentplacehold}
				className='DentistAppointmentPlan-Textarea'
				value={assessment.treatment_plan}
				onChange={handleChange}
				rows='5'
			/>
		</div>
	)
}