import './ConfirmNotificate.css'
import Button, { ButtonModel } from "../../../../../../../../../newcomponents/button/button";
import { MODAL_INIT_PROPS } from "../../../../../../../../../newcomponents/modal/modal";

export function ConfirmNotificate({ user, setSecondaryModalInfo, sendIncompleteProfileEmail }) {
    return (
        <section className="Confirm-Notificate-Modal">
            <p className="Confirm-Notificate-Modal-text">Ao confirmar, um lembrete para completar o cadastro será enviado para o email <strong>{user.email}</strong> com uma nova senha temporária para esse usuário. Deseja enviar o lembrete? </p>
            <div className="Confirm-Notificate-Modal-Button-div">
            <Button
                model={ButtonModel.SECONDARY}
                label={'Cancelar'}
                onClick={() => setSecondaryModalInfo(MODAL_INIT_PROPS)}
            />
            <Button 
                model={ButtonModel.PRIMARY}
                label={'Enviar'}
                onClick={() => sendIncompleteProfileEmail(user?.id)}
            />
        </div>
        </section>
    )
}