import api from "../../helpers/api"
import { getParams } from "../../utils"

export async function getPatientDetails(id, params={}) {
    const response = await api.get(`patient/detail/${id}/${getParams(params)}`)
    return response
}

export async function getPatientsList(params={}) {
    const response = await api.get(`patient/list/${getParams(params)}`)
    return response
}

export async function getHealthPlacePatients(params={}) {
    const response = await api.get(`patient/health_place/${getParams(params)}`)
    return response
}

export async function getCareersList(params={}) {
    const response = await api.get(`patient/career/${getParams(params)}`)
    return response
}