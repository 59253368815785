import './CreateGenericCouncil.css'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddAlert, AlertType, Button, IcoBin, IcoPencil, Input, List } from '../../../../../component'
import NewSelect from '../../../../../newcomponents/form/select/select'
import NewButton, { ButtonModel } from '../../../../../newcomponents/button/button'
import api from '../../../../../helpers/api'
import { loaded, loading } from '../../../../../layout/redux/AppActions'
import { useApp } from '../../../../../layout/App'

const initCouncilData = { nu_crm: '', uf_crm: '', specialties: '' }

const councilHead = [
    { colunm: 'h_nu_crm', text: 'Número' },
    { colunm: 'h_uf_crm', text: 'UF' },
    { colunm: 'h_actions', text: '' }
]

export default function CreateGenericCouncil({ model='' }) {
    const options = require('../../../../../component/form/select/options.json')
    const dispatch = useDispatch()
    const { listas } = useSelector(state => state.appState)
    const [councils, setCouncils] = useState([])
    const [holdingCouncil, setHoldingCouncil] = useState(initCouncilData)
    const [holdingCouncilErr, setHoldingCouncilErr] = useState({})
    const { currentHealthPlaceUser, fetchUserCouncils, loged } = useApp()

    let profile = currentHealthPlaceUser?.profile

    const councilNames = {
        'DENTIST': 'CRO',
        'PSYCHOLOGIST': 'CRP',
        'NUTRITIONIST': 'CRN',
        'NURSE': 'COREN',
    }

    let selected_council_by_profile = councilNames[profile]

    const handleChange = (event) => {
        setHoldingCouncilErr({})
        setHoldingCouncil(prev => ({
            ...prev,
            [event.target.name]: event.target.value[0] === 't' ? event.target.value : event.target.value.replace(/\D/g, '')
        }))
    }

    const handleSelect = (event) => {
        setHoldingCouncilErr({})
        setHoldingCouncil(prev => ({ ...prev, [event.target.id]: event.target.selected }))
    }

    const handleAddCouncilData = () => {
        let errors = {}
        if (!holdingCouncil.nu_crm) errors['nu_crm'] = 'Inscrição obrigatória'
        if (!holdingCouncil.uf_crm) errors['uf_crm'] = 'UF obrigatória'
        if (Object.keys(errors).length) return setHoldingCouncilErr(errors)

        setCouncils(prev => [...prev.filter(crm => crm.uf_crm !== holdingCouncil.uf_crm), { ...holdingCouncil, council: selected_council_by_profile }])
        setHoldingCouncil(initCouncilData)
    }

    const handleSaveCouncil = async () => {
        const payload = councils.map(council => {
            delete council.h_actions
            return council
        })
        dispatch(loading())
        try {
            await api.post('/health_place_user/registration/user/create_councils/', { councils: payload  })
            dispatch([
                loaded(),
                AddAlert(`Cadastro de ${selected_council_by_profile}`, `${selected_council_by_profile} salvo com sucesso`, AlertType.SUCCESS)
            ])
            await loged()
            fetchUserCouncils()
        } catch (err) {
            console.error(err)
            dispatch([
                loaded(),
                AddAlert(`Cadastro de ${selected_council_by_profile}`, `Falha ao salvar ${selected_council_by_profile}, tente novamente!`, AlertType.ERROR)
            ])
        }
    }

    const specialtiesOptions = {
        'DENTIST': options.cro_specialties,
        'PSYCHOLOGIST': options.crp_specialties,
        'NUTRITIONIST': options.crn_specialties,
        'NURSE': options.coren_specialties
    }

    return (
    <div>
        <div className='CreateGenericCouncil-CROCol'>
            <Input
                label='Inscrição'
                name='nu_crm'
                action={handleChange}
                value={holdingCouncil.nu_crm}
                maxLength='8'
                required={{ erro: holdingCouncilErr, message: holdingCouncilErr.nu_crm }}
                placeholder='ex: 123456'
            />
            <NewSelect
                id='uf_crm'
                options={listas.estados}
                canBeEmpty={false}
                label='UF'
                optionRefKey='sigla'
                optionStrKey='nome'
                selected={holdingCouncil.uf_crm}
                onSelect={handleSelect}
                error={holdingCouncilErr?.uf_crm}
            />
        </div>
        <NewSelect
            id='specialties'
            label='Especialidade'
            optionRefKey='name'
            options={specialtiesOptions[profile] || []}
            selected={holdingCouncil.specialties}
            onSelect={handleSelect}
        />
        <div className='CreateGenericCouncil-BtnFullWidth'>
            <NewButton
                label={`Adicionar ${councilNames[profile]}`}
                model={ButtonModel.SECONDARY}
                onClick={handleAddCouncilData}
            />
        </div>
        <p className='CreateGenericCouncil-Subtitle CreateGenericCouncil-mt'>{councilNames[profile]} cadastrados</p>
        <List
            head={councilHead}
            data={councils.map(instance => ({ ...instance }))}
            noData={''}
            listCustom={(council) => {
                const clear_council = { ...council }
                const custom = council
                custom['h_nu_crm'] = council.nu_crm
                custom['h_uf_crm'] = council.uf_crm
                custom['h_actions'] = <div className='CreateGenericCouncil-Actions'>
                    <Button
                        color='secondary'
                        type='btn circle'
                        action={() => {
                            setCouncils(prev => prev.filter(instance => instance.nu_crm !== council.nu_crm && instance.uf_crm !== council.uf_crm))
                            setHoldingCouncil(clear_council)
                        }}
                        title='Editar'
                    >
                        <IcoPencil />
                    </Button>
                    <Button
                        color='danger'
                        type='btn circle'
                        action={() => setCouncils(prev => prev.filter(instance => instance.nu_crm !== council.nu_crm && instance.uf_crm !== council.uf_crm))
                        }
                        title='Remover'
                    >
                        <IcoBin />
                    </Button>
                </div>
                return custom
            }}
        />
        <div className={model === 'Config' ? 'CreateGenericCouncil-BtnBox' : 'CreateGenericCouncil-BtnFullWidth'}>
            <NewButton
                label={`Salvar ${councilNames[profile]}'s`}
                disabled={!councils.length}
                onClick={handleSaveCouncil}
            />
        </div>
    </div>
    )
}