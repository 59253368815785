import './PastToggleAppointmentField.css'
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import Loading from '../../../../../../../newcomponents/loading/loading';
import { AddAlert, AlertType, MaskDataHora } from '../../../../../../../component';

export default function PastToggleAppointmentField({ field_type, attendance, getPastFilledCodesFn=async () => null }) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [pastAppointmentTextFields, setPastAppointmentTextFields] = useState([])

    useEffect(() => {
        let params = {
            "offset": 0, 
            "field_type": field_type, 
            "appointment__person__id": attendance?.person?.id,
            "selected__isnull": false,
            "ordering": "-appointment__start",
        }
        fetchPastAppointmentCodes(params)
    }, [])

    const fetchPastAppointmentCodes = async (params={}) => {
        setLoading(true)
        try {
            let res = await getPastFilledCodesFn(params)
            setPastAppointmentTextFields(res.data.results)
        } catch (err) {
            dispatch(AddAlert('Consultas Anteriores', 'Falha ao buscar consultas anteriores', AlertType.ERROR))
            console.error('PastToggleAppointmentField ~ getPastFilledCodesFn ~ ', err)
        }
        setLoading(false)
    }

    if (loading) {
        return <div className='PastToggleAppointmentField-Loading'>
            <Loading />
        </div>
    }

    return <div className='PastToggleAppointmentField'>
        {
            pastAppointmentTextFields?.length
            ? <div className='PastToggleAppointmentField-Body'>
            {
                pastAppointmentTextFields.map((instance, idx) => {
                    const attendance__start = MaskDataHora(instance?.appointment?.start, 'DD/MMM/AAAA', '-')
                    console.log('instance ~ ', instance)
                    return <div key={idx}className='PastToggleAppointmentField-TextFieldBox'>
                        <div>
                            <div className='PastToggleAppointmentField-TextFieldBox-InfoBox-TitleRow'>Atendimento em {attendance__start?.data} - {instance?.appointment?.protocol}</div>
                            {
                                instance?.selected?.length
                                ? instance?.selected?.map(({code, title}) => <span style={{ fontSize: '0.875rem' }}>{code} {title}<br /></span>)
                                : <p style={{ fontSize: '0.875rem' }}>-</p>
                            }
                        </div>
                    </div>
                })
            }
            </div>
            : <div className='PastToggleAppointmentField-EmptyMessage-Box'>
                <span>Sem registros anteriores para esse campo</span>
            </div>
        }
    </div>
}