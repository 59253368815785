import './EditProfileImage.css'
import React, { useState } from "react"
import { AddAlert, AlertType } from "../../../../component"
import { useDispatch } from 'react-redux'
import api from '../../../../helpers/api'
import { loaded, loading } from '../../../../layout/redux/AppActions'
import Button from '../../../../newcomponents/button/button'
import { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal'
import { Cropper } from 'react-cropper'
import { useHoldingPatient } from '../../HoldingPatientInfoView'
import ZoomInIco from '../../../../component/icon/zoom-in'
import ZoomOutIco from '../../../../component/icon/zoom-out'
import LeftIco from '../../../../component/icon/left'
import RightIco from '../../../../component/icon/right'
import DownIco from '../../../../component/icon/down'
import UpIco from '../../../../component/icon/up'


export default function EditProfileImage({ setModalInfo=() => null, initHoldingPatient=null, fetchExtraAction=() => null }) {
    const dispatch = useDispatch()
    const defaultUser = require('../../../../media/DefaultUser.png')
    const [cropper, setCropper] = useState();
    const { fetchHoldingPatientData } = useHoldingPatient()
    
    const handleSubmit = async () => {
        const base64_avatar = cropper?.getCroppedCanvas()?.toDataURL()
        if (base64_avatar && typeof cropper !== "undefined") {
            dispatch(loading());
            try {
                const payload = { "id": initHoldingPatient?.id, "base64_avatar": base64_avatar }
                await api.post(`patient/edit_patient/${initHoldingPatient?.id}/`, payload)
                fetchHoldingPatientData()
                dispatch(AddAlert('Editar Foto de Perfil', 'Paciente salvo com sucesso', AlertType.SUCCESS))
                setModalInfo(MODAL_INIT_PROPS)
            } catch (err) {
                dispatch(AddAlert('Editar Foto de Perfil', 'Falha ao criar novo paciente, revise os dados', AlertType.ERROR));
                console.error('handleSubmit', err);
            }
            dispatch(loaded());
        }
    }

    return <div className='EditProfileImage'>
        <div className='EditProfileImage-PersonalInfo'>
            <div className='EditProfileImage-Subtitle-Header'>
                <div className='EditProfileImage-Subtitle-Header-Icon EditProfileImage-Subtitle-Header-Icon-Purple'>F</div>
                <div className='EditProfileImage-Subtitle-Header-Title'>Foto de Perfil</div>
            </div>
        </div>
        <div>
            <div style={{ width: "100%" }}>
                <Cropper
                    style={{ height: 320, width: "100%" }}
                    zoomTo={0.5}
                    initialAspectRatio={1}
                    preview=".EditProfileImage-img-preview"
                    src={initHoldingPatient?.base64_avatar || defaultUser}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                    aspectRatio={1}
                    guides={true}
                />
            </div>
            <br style={{ clear: "both" }} />
            <div className='EditProfileImage-ActionRow'>
                <button className='EditProfileImage-Action' onClick={() => {if (cropper) cropper.zoom(0.1)}}><ZoomInIco /></button>
                <button className='EditProfileImage-Action' onClick={() => {if (cropper) cropper.zoom(-0.1)}}><ZoomOutIco /></button>

                <button className='EditProfileImage-Action' onClick={() => {if (cropper) cropper.move(10, 0)}}><LeftIco /></button>
                <button className='EditProfileImage-Action' onClick={() => {if (cropper) cropper.move(-10, 0)}}><RightIco /></button>
                <button className='EditProfileImage-Action' onClick={() => {if (cropper) cropper.move(0, -10)}}><DownIco /></button>
                <button className='EditProfileImage-Action' onClick={() => {if (cropper) cropper.move(0, 10)}}><UpIco /></button>
            </div>
        </div>

        <div className='EditProfileImage-Btn-Box'>
            <Button 
                onClick={handleSubmit}
                label='Salvar'
            />
        </div>
    </div>
}
