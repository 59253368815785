import './PatientInformation.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PatientPersonalData from './subcomponents/PatientPersonalData/PatientPersonalData'
import PatientResponsibleInformation from './subcomponents/PatientResponsibleInformation/PatientResponsibleInformation'
import PatientAddressData from './subcomponents/PatientAddressData/PatientAddressData'
import PatientProfileImage from './subcomponents/PatientProfileImage/PatientProfileImage'
import PatientInactivateModal from './subcomponents/PatientInactivateModal/PatientInactivateModal'
import PatientDeathRecordModal from './subcomponents/PatientDeathRecordModal/PatientDeathRecordModal'
import Button, { ButtonModel } from '../../../../newcomponents/button/button'
import { useHoldingPatient } from '../../HoldingPatientInfoView'
import api from '../../../../helpers/api'
import { AddAlert, AlertType } from '../../../../component'
import { PatientRequest, strpPerson } from '../../../pacientes/subcomponents/NewPatient/helpers'
import PatientActivateModal from './subcomponents/PatientActivateModal/PatientActivateModal'


export default function PatientInformation() {
	const { holdingPatient } = useHoldingPatient()
	const [confirmInactivate, setConfirmInactivate] = useState({ show: false, patient: null })
	const [confirmActivate, setConfirmActivate] = useState({ show: false, patient: null })
	const [deathRecordConfirm, setDeathRecordConfirm] = useState({show: false, patient: null})
	const dispatch = useDispatch()
	const history = useHistory()
	const currentUser = JSON.parse(localStorage.getItem('user'))

	const handleConfirmActivation = async (_, confirmed) => {
		const patient = confirmActivate.patient

		if (!confirmed) {
			setConfirmActivate({ show: false, patient: null })
			return
		}
		if (!patient) {
			dispatch(AddAlert('Ativar usuário', 'Não é possível ativar este usuário', AlertType.ERROR))
			setConfirmInactivate({ show: false, patient: null })
			return
		}

		let response = await api.post(`patient/activate_patient/${patient.id}/`)

		if (response.status !== 200) {
			dispatch(AddAlert('Ativar Paciente', 'Houve um erro ao ativar o paciente', AlertType.ERROR))
			return
		}

		dispatch(AddAlert('Ativar paciente', 'Paciente ativado com sucesso!', AlertType.SUCCESS))
		setConfirmActivate({ show: false, patient: null })
		history.push('/pacientes')
	}

	const handleDeathRecord = async (data) => {
		if (data==null) {
			setDeathRecordConfirm({show: false, patient: false})
			return
		} else {
			const updatedPatient = {...holdingPatient, ...data}
			const payload = new PatientRequest(strpPerson(updatedPatient));
			
			if (payload.isValid()){
				try{
                	const res = await api.post(`patient/edit_patient/${holdingPatient?.id}/`, payload.formatPayload())
					if (res.status == 200) {
						inactivatePatient(holdingPatient)
					}
				} catch (err) {
					dispatch(AddAlert('Editar paciente', err?.response?.data?.str || 'Falha ao salvar paciente, revise os dados', AlertType.ERROR));
					console.error('handleDeathRecord', err);
				}
			} else {
				dispatch(AddAlert('Registrar óbito', 'Falha ao registrar o óbito do paciente, revise os dados', AlertType.ERROR));
					console.error('handleDeathRecord');
			}
		}
	}

	const canBeInactivated = patient => {
        return patient?.is_active && patient?.id !== currentUser?.person?.id
    }

	const handleConfirmInactivation = (_, confirmed) => {
		if (!confirmed) {
			setConfirmInactivate({ show: false, patient: null })
			return
		}

		const patient  = confirmInactivate.patient

		if (!patient) {
			dispatch(AddAlert('Inativar usuário', 'Não é possível inativar este usuário', AlertType.ERROR))
			setConfirmInactivate({ show: false, patient: null })
			return
		}
		inactivatePatient(patient)
	}

	const inactivatePatient = async (patient) => {
		setConfirmInactivate({ show: false, patient: null })

		let response = await api.post(`patient/inactivate_patient/${patient.id}/`)
	
	
		if (response.status !== 200) {
			dispatch(AddAlert('Inativar usuário', 'Houve um erro ao inativar o usuário', AlertType.ERROR))
			return
		}
		dispatch(AddAlert('Inativar paciente', 'Paciente inativado com sucesso!', AlertType.SUCCESS))
		history.push('/pacientes')        
	}

  	
	return (
	<>
		<PatientInactivateModal
			show={confirmInactivate.show}
			inactivateHandler={handleConfirmInactivation}
			patient={confirmInactivate.patient}
		/>
		<PatientDeathRecordModal 
			show={deathRecordConfirm.show}
			submitModal={handleDeathRecord}
			patient={deathRecordConfirm.patient}
		/>
		<PatientActivateModal
			show={confirmActivate.show}
			activateHandler={handleConfirmActivation}
			patient={confirmActivate.patient}
		/>
		<div className='PatientInformation'>
			<div className='PatientInformation-Col'>
				<div className='PatientInformation-Container'>
					<PatientPersonalData />
				</div>
				<div className='PatientInformation-Container'>
					<PatientResponsibleInformation />
				</div>
			</div>
			<div className='PatientInformation-Col'>
				<div className='PatientInformation-Container'>
					<PatientProfileImage />
				</div>

				<div className='PatientInformation-Container'>
					<PatientAddressData />
				</div>
				<div className='PatientInformation-Actions'>		
					{holdingPatient.is_active && (
							<Button
								onClick={() => setDeathRecordConfirm({show: true, patient: holdingPatient})}
								model={ButtonModel.SECONDARY}
								label='Registrar Óbito'
							/>
						)}
					{canBeInactivated(holdingPatient) && currentUser.health_place_users[0].profile ==='ADMINISTRATOR' && (
						<Button 
							onClick={() => setConfirmInactivate({ show: true, patient: holdingPatient })}
							model={ButtonModel.SECONDARY}
							label='Inativar Paciente'
						/>
						)}
					{!holdingPatient.is_active && currentUser.health_place_users[0].profile ==='ADMINISTRATOR' && (
						<Button
							onClick={() => setConfirmActivate({ show: true, patient: holdingPatient })} 
							model={ButtonModel.PRIMARY}
							label={'Ativar Paciente'}
						/>
					)}
				</div>
			</div>
		</div>
	</>
	)
}
