import './MedicalRecordHistory.css'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import api from '../../../../helpers/api'
import { getParams } from '../../../../utils'
import PrinterIcon from '../../../../component/icon/newPrinter'
import Loading from '../../../../newcomponents/loading/loading'
import { MaskDataHora, Paginate } from '../../../../component'
import MedicalRecordUnit from './subcomponents/MedicalRecordUnit/MedicalRecordUnit'
import MedicalRecordFilters from './subcomponents/MedicalRecordFilters/MedicalRecordFilters'
import ArrowDownIco from '../../../../component/icon/arrow_down'
import { loaded, loading } from '../../../../layout/redux/AppActions'

const { initFilters } = require('../../../AttendanceManager/data.json')

export function parseFilters(filtersRef) {
	
	return {
		doctor__id: filtersRef.current.doctor__id,
		decision: filtersRef.current.decision,
		result: filtersRef?.current?.result,
		classification: filtersRef?.current?.classification,
		priority: filtersRef?.current?.priority,
		specialty: filtersRef?.current?.specialty,
		health_insurance: filtersRef?.current?.health_insurance,
		person__birthday__gte: filtersRef?.current?.person__birthday__gte,
		person__birthday__lte: filtersRef?.current?.person__birthday__lte,
		
		offset: filtersRef?.current?.offset,
		limit: 10,
		active: true,
	}
}

export default function MedicalRecordHistory({ person=null, setModalInfo=() => null }) {
  const dispatch = useDispatch()
  const searchTimeOut = useRef(null)
  const filtersRef = useRef(initFilters)
  const [appointmentsHistory, setAppointmentsHistory] = useState([])
  const [openSection, setOpenSection] = useState(null)
  const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })

  const handleSubmit = async () => {
    dispatch(loading())
    try {
      await api.get(`appointment/print/?patient_id=${person?.id}`)
        .then(res => {
          const link = document.createElement("a");
          link.href = res.data.base64
          link.download = 'prontuário-de-atendimento.pdf'
          link.click()
        })
        .catch(err => {
          console.error('MedicalRecordHistory ~ handleSubmit ~ ', err)
        })
    } catch (err) {
      console.error('MedicalRecordHistory ~ handleSubmit ~ ', err)
    }
    dispatch(loaded())
  }

  useEffect(() => {
		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(async () => {
				await fetchAppointmentsHistory({ offset: 0 })
			}, 300)
		} catch (err) {
			console.error('handleSearch', err)
		}
	}, [])
  
  useEffect(() => {
    fetchAppointmentsHistory({ offset: 0 })

    // eslint-disable-next-line
  }, [person?.id])

  const handleNextPage = (e) => {
    fetchAppointmentsHistory({ offset: e * 10 })
  }

  const fetchAppointmentsHistory = async (params={}) => {
    let filters = { 
      ...parseFilters(filtersRef),
      ...params,
      person__id: person?.id,
      
    }

    if (person?.id) {
      await api.get(`appointment/health_professional/${getParams(filters)}`)
        .then(res => {
          setAppointmentsHistory(res.data.results)
          setPagination({
            totalElements: res.data.count,
            totalPages: Math.ceil(res.data.count / 10),
            pageNumber: params.offset / 10,
          })
        })
        .catch(err => console.error('fetchAppointmentsHistory ~ ', err))
    }
  }

  return (<>
    <div>
      <div className='MedicalRecordHistory-ActionRow'>
        <div className='MedicalRecordHistory-ActionRow-Btn' onClick={handleSubmit}>
            <PrinterIcon /> <b>Imprimir prontuário</b>
        </div>
      </div>
      <div className='MedicalRecordHistory-Body'>
        <MedicalRecordFilters 
          filtersRef={filtersRef} 
          fetchAppointmentsHistory={fetchAppointmentsHistory} 
          setModalProps={setModalInfo}
        />
        <div className='MedicalRecordHistory-Separator' />
        <div>
          {
            false 
            ? <div className='MedicalRecordHistory-Loading'><Loading /></div>
            : <div > 
              {
                appointmentsHistory?.length
                ? <div className='MedicalRecordHistory-RecordUnitWrapper'>
                  {
                    appointmentsHistory.map(attendance => {
                      const attendanceDate = MaskDataHora(attendance?.start)
                      
                      return <div>
                        <div 
                          className={`MedicalRecordHistory-AccordionNav ${openSection === attendance?.id ? 'open' : ''}`} 
                          onClick={() => setOpenSection(current => current === attendance?.id ? null : attendance?.id)}
                        >
                          {attendance?.protocol || '-'} em {attendanceDate?.data && attendanceDate?.hora ? `${attendanceDate?.data} ${attendanceDate?.hora}` : '-'} <ArrowDownIco />
                        </div>
                        {
                          openSection === attendance?.id 
                          ? <div className={`MedicalRecordHistory-Section ${openSection === attendance?.id ? 'open' : ''}`}>
                            <MedicalRecordUnit 
                              attendance={attendance} 
                              setModalProps={setModalInfo} 
                              fetchAttendancesHistory={fetchAppointmentsHistory} 
                            />
                          </div>
                          : null  
                        }
                      </div>
                    })
                  }
                  <Paginate data={pagination} action={handleNextPage} />
                </div>
                : null
              }
            </div>
          }
        </div>

			</div> 
    </div>
    
  </>
  )
}
