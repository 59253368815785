import React, { useState } from "react";
import { useParams } from 'react-router-dom'
import VideoCallScreen from "../../component/video/VideoCallScreen";


export default function VideoCallPage() {
    const { token } = useParams()
    const [tokenRef, setTokenRef] = useState(token)

    return <>
        <VideoCallScreen token={tokenRef} setToken={setTokenRef} />
    </>
}