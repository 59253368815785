import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	AddAlert,
	AlertType,
	Button,
	IcoEye,
	Input,
	List,
} from '../../../../../component'
import CheckIcon from '../../../../../component/icon/check'
import AlertRowText, {
	AlertRowTextType,
} from '../../../../../component/alertRowText/alertRowText'
import api from '../../../../../helpers/api'
import Loading from '../../../../../newcomponents/loading/loading'
import { getParams } from '../../../../../utils'
import CancelIcon from '../../../../../component/icon/cancel'
import { history } from '../../../../../helpers'

const data = require('../../../data/ThousandDaysPage.json')

export default function FollowUpRequestsManager() {
	const dispatch = useDispatch()
	const [followUpRequests, setFollowUpRequests] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		fetchFollowUpRequests({ offset: 0 })
	}, [])

	const searchFollowUpTimeOut = useRef(null)

	const searchFollowUpRequest = name => {
		searchFollowUpTimeOut.current && clearTimeout(searchFollowUpTimeOut.current)
		searchFollowUpTimeOut.current = setTimeout(
			name => {
				fetchFollowUpRequests({ params: { patient__name__icontains: name } })
			},
			300,
			name
		)
	}

	const fetchFollowUpRequests = params => {
		params = { ...params, status: 'W', limit: 20 }
		setLoading(true)
		api
			.get(`/projects/followuprequest/${getParams(data)}`)
			.then(res => setFollowUpRequests(res.data.results))
			.catch(err => {
				console.error('fetchFollowUpRequests', err)
				setFollowUpRequests([])
				dispatch(
					AddAlert(
						'Projeto 1000 dias',
						'Falha ao obter requisições de acompanhamento',
						AlertType.ERROR
					)
				)
			})
		setLoading(false)
	}

	const updateFollowUpRequest = async payload => {
		setLoading(true)
		await api
			.patch(`/thousanddays/followuprequest/`, payload)
			.then(() => {
				fetchFollowUpRequests()
			})
			.catch(err => {
				console.log('updateFollowUpRequest', err)
				dispatch(
					AddAlert(
						'Projeto 1000 dias',
						'Erro ao aceitar/recusar projeto do paciente',
						AlertType.ERROR
					)
				)
			})
		setLoading(false)
	}

	return (
		<div className='ThousandDaysPage-Content'>
			<section className='ThousandDaysPage-Content-SideBarLeft'>
				<div className='ThousandDaysPage-Content-SideBarLeft-BtnBox'>
					<p>
						<b>Buscar solicitante</b>
					</p>
					<Input
						placeholder='Nome do solicitante'
						action={event => searchFollowUpRequest(event.target.value)}
					/>
				</div>
			</section>
			<div className='ThousandDaysPage-Separator' />
			<div className='ThousandDaysPage-Body'>
				<p>
					<b>Solicitações</b>
				</p>
				{loading ? (
					<div className='ThousandDaysPage-Loading'>
						<Loading />
					</div>
				) : (
					<div>
						{followUpRequests?.length ? (
							<List
								head={data.list_headers.requests}
								data={followUpRequests}
								listCustom={request => {
									request['h_name'] = <b>{request.patient.name}</b>
									request['h_age'] = 18
									request['h_action'] = (
										<div className='ThousandDaysPage-accept-btn-box'>
											<Button
												color='save'
												type='btn circle'
												action={() =>
													updateFollowUpRequest({ id: request.id, status: 'A' })
												}
												title='Aceitar acompanhamento'>
												<CheckIcon
													width={16}
													height={16}
													color={'#fff'}
												/>
											</Button>
											<Button
												color='danger'
												type='btn circle'
												action={() =>
													updateFollowUpRequest({ id: request.id, status: 'N' })
												}
												title='Recusar acompanhamento'>
												<CancelIcon
													width={11}
													height={14}
													color={'#fff'}
												/>
											</Button>
											<Button
												color='primary'
												type='btn circle'
												action={() =>
													history.push(
														`pacientes/${request.patient.id}/detalhar`
													)
												}
												title='Ver paciente'>
												<IcoEye />
											</Button>
										</div>
									)
									return request
								}}
							/>
						) : (
							<AlertRowText
								type={AlertRowTextType.INFO}
								content='Ainda não há solicitações de acompanhamento'
								outlined
							/>
						)}
					</div>
				)}
			</div>
		</div>
	)
}
