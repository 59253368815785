import React, { useEffect } from 'react'
import { NewCheckbox } from '../../../../../../../../../../../../../newcomponents'
import { useThousandDays } from '../../../../../ThousandDaysSection'
import RiskBadge from './RiskBadge'

export function PregnancyRiskMedicalSurgicalForm() {
	const {
		selectedLanguage,
		pregnancyBackground,
		setPregnancyBackground,
		savePregnancyBackground,
	} = useThousandDays()

	const {
		Cardiacdisorders,
		Severevaricoseveins,
		Severerespiratorydisease,
		Diabetesmellitus,
		Autoimmunediseases,
		Psychiatricillness,
		Severekidneydisease,
		Epilepsyandneurologicaldisease,
		Hematologicaldisorders,
		Arterialhypertension,
		Recurrenturinaryinfections,
		Severeinfections,
		Aidshiv,
		Syphilis,
		Tuberculosis,
		Toxoplasmosis,
		Drugdependence,
		Alcoholism,
		Thrombophilia,
		Endocrinedisorders,
		Thyroiddisorders,
		Medicalandsurgical,
	} = require('../../../../../../../../../../../../data/translation.json')[
		selectedLanguage
	]

	useEffect(() => {
		savePregnancyBackground()
	}, [
		pregnancyBackground.cardiopathies,
		pregnancyBackground.severe_varicose_veins,
		pregnancyBackground.severe_pneumopathy,
		pregnancyBackground.diabetes_mellitus,
		pregnancyBackground.autoimmune_diseases_collagenosis,
		pregnancyBackground.psychiatric_illness,
		pregnancyBackground.severe_renal_disease,
		pregnancyBackground.epilepsy_neurological_disease,
		pregnancyBackground.hemopathies,
		pregnancyBackground.hypertension,
		pregnancyBackground.recurrent_urinary_infection,
		pregnancyBackground.severe_infections,
		pregnancyBackground.aids_hiv,
		pregnancyBackground.syphilis,
		pregnancyBackground.tuberculosis,
		pregnancyBackground.toxoplasmosis,
		pregnancyBackground.drug_dependency,
		pregnancyBackground.alcoholism,
		pregnancyBackground.thrombophilia,
		pregnancyBackground.endocrinopathies,
		pregnancyBackground.thyroid_alterations,
	])

	return (
		<section>
			<NewCheckbox
				title={<b>{Medicalandsurgical}</b>}
				className='PregnancyRiskCalculator-Checkbox'
				state={[
					{
						id: 'cardiopathies',
						name: Cardiacdisorders,
						checked: pregnancyBackground.cardiopathies,
					},
					{
						id: 'severe_varicose_veins',
						name: Severevaricoseveins,
						checked: pregnancyBackground.severe_varicose_veins,
					},
					{
						id: 'severe_pneumopathy',
						name: Severerespiratorydisease,
						checked: pregnancyBackground.severe_pneumopathy,
					},
					{
						id: 'diabetes_mellitus',
						name: Diabetesmellitus,
						checked: pregnancyBackground.diabetes_mellitus,
					},
					{
						id: 'autoimmune_diseases_collagenosis',
						name: Autoimmunediseases,
						checked: pregnancyBackground.autoimmune_diseases_collagenosis,
					},
					{
						id: 'psychiatric_illness',
						name: Psychiatricillness,
						checked: pregnancyBackground.psychiatric_illness,
					},
					{
						id: 'severe_renal_disease',
						name: Severekidneydisease,
						checked: pregnancyBackground.severe_renal_disease,
					},
					{
						id: 'epilepsy_neurological_disease',
						name: Epilepsyandneurologicaldisease,
						checked: pregnancyBackground.epilepsy_neurological_disease,
					},
					{
						id: 'hemopathies',
						name: Hematologicaldisorders,
						checked: pregnancyBackground.hemopathies,
					},
					{
						id: 'hypertension',
						name: Arterialhypertension,
						checked: pregnancyBackground.hypertension,
					},
					{
						id: 'recurrent_urinary_infection',
						name: Recurrenturinaryinfections,
						checked: pregnancyBackground.recurrent_urinary_infection,
					},
					{
						id: 'severe_infections',
						name: Severeinfections,
						checked: pregnancyBackground.severe_infections,
					},
					{
						id: 'aids_hiv',
						name: Aidshiv,
						checked: pregnancyBackground.aids_hiv,
					},
					{
						id: 'syphilis',
						name: Syphilis,
						checked: pregnancyBackground.syphilis,
					},
					{
						id: 'tuberculosis',
						name: Tuberculosis,
						checked: pregnancyBackground.tuberculosis,
					},
					{
						id: 'toxoplasmosis',
						name: Toxoplasmosis,
						checked: pregnancyBackground.toxoplasmosis,
					},
					{
						id: 'drug_dependency',
						name: Drugdependence,
						checked: pregnancyBackground.drug_dependency,
					},
					{
						id: 'alcoholism',
						name: Alcoholism,
						checked: pregnancyBackground.alcoholism,
					},
					{
						id: 'thrombophilia',
						name: Thrombophilia,
						checked: pregnancyBackground.thrombophilia,
					},
					{
						id: 'endocrinopathies',
						name: Endocrinedisorders,
						checked: pregnancyBackground.endocrinopathies,
					},
					{
						id: 'thyroid_alterations',
						name: Thyroiddisorders,
						checked: pregnancyBackground.thyroid_alterations,
					},
				]}
				defaultHandleCheckbox={false}
				setState={(event) =>
					setPregnancyBackground((prev) => ({
						...prev,
						[event.target.name]: event.target.checked,
					}))
				}
			/>
			<RiskBadge />
		</section>
	)
}
