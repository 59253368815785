import './HealthPlaceInstitutionalAddress.css'
import React from "react";
import { useConfiguration } from '../../../../../configuration';
import { MaskItemCep } from '../../../../../../../component';
import HorizontalField from '../../../../../../../component/HorizontalField/HorizontalField';
import HealthPlaceInstitutionalAddressModal from './HealthPlaceInstitutionalAddressModal/HealthPlaceInstitutionalAddressModal';

export default function HealthPlaceInstitutionalAddress() {
    const { institution, setModalProps, fetchInstitution } = useConfiguration()

    
    return <div className='HealthPlaceInstitutionalAddress'>
        <div className='HealthPlaceInstitutionalAddress-Header'>
            <div className='HealthPlaceInstitutionalAddress-Header-Icon'>E</div>
            <div className='HealthPlaceInstitutionalAddress-Header-Title'>Endereço</div>
        </div>
        <div>
            <HorizontalField
                label='CEP'
                content={institution?.address?.zip ? MaskItemCep(institution?.address?.zip) : '-'}
            />
            <HorizontalField
                label='Cidade'
                content={institution?.address?.city?.name || '-'}
            />
            <HorizontalField
                label='Estado'
                content={institution?.address?.city?.state || '-'}
            />
            <HorizontalField
                label='Bairro'
                content={institution?.address?.district || '-'}
            />
            <HorizontalField
                label='Endereço'
                content={institution?.address?.address || '-'}
            />
            <HorizontalField
                label='Número'
                content={institution?.address?.number || '-'}
            />
            <HorizontalField
                label='Complemento'
                content={institution?.address?.complement || '-'}
            />
        </div>
        <div className='HealthPlaceInstitutionalAddress-ActionRow'>
            <div 
                className='HealthPlaceInstitutionalAddress-ActionRow-Btn' 
                onClick={() => setModalProps(prev => ({
                    ...prev,
                    open: true,
                    title: 'Editar Instituição',
                    dismissText: '',
                    content: <HealthPlaceInstitutionalAddressModal
                        setModalInfo={setModalProps}
                        initHoldingHealthPlace={institution}
                        fetchExtraAction={fetchInstitution}
                    />
                }))}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}

