// https://bobbyhadz.com/blog/javascript-validate-date-yyyy-mm-dd

export default function isValidDate(dateStr="") { //2022-01-24 == True // 2022-01-35 == False
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (dateStr.match(regex) === null) {
        return false;
    }

    const date = new Date(dateStr);

    const timestamp = date.getTime();

    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
        return false;
    }

    return date.toISOString().startsWith(dateStr);
}