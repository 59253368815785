import React from 'react'

import './toggle.css'

export default function Toggle({ id, label, value, onChange, disabled }) {
    return <div id={id} className={`toggle ${value ? 'on' : 'off'}`} onClick={() => !disabled && onChange(!value)}>
        <div className='toggle-trail'>
            <div className='toggle-circle'></div>
        </div>
        <div className='toggle-label'>{label}</div>
    </div>
}