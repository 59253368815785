import React, { useEffect, useState } from  'react'
import { useApi } from '../../hooks/api'

import './privacyPolicy.css'
import Logo from '../../media/SaluthoLogo.jpg'

export const PrivacyPolicy = () => {

    const [policy, setPolicy] = useState("")
    const api = useApi()

    useEffect(async() => {
        let result = await api.get("/api/v1/auth/privacy-policy/", false)

        let content = JSON.parse(result.content)

        console.log(content)

        setPolicy(content.results[0].text)

    }, [])

    return <div className='content-box'>
        <img src={Logo} width='100'></img>
        <pre>{policy}</pre>
    </div>
}