import React from 'react'

export default function IcoBoxEdit({ style={} }) {
    return <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M1062 4468 c-361 -182 -659 -332 -661 -334 -2 -2 303 -156 678 -343
            l681 -341 682 341 682 341 -265 135 c-754 385 -1059 533 -1100 533 -33 0 -159
            -60 -697 -332z"/>
            <path d="M320 3260 c0 -785 -1 -770 51 -816 13 -12 258 -142 544 -289 286
            -147 575 -297 643 -332 l122 -65 0 776 0 776 -680 340 -680 340 0 -730z"/>
            <path d="M2518 3649 l-678 -339 0 -775 0 -775 43 21 c23 12 93 48 155 81 l113
            60 -80 83 c-58 59 -90 102 -113 151 -30 63 -33 77 -33 165 0 90 2 99 38 171
            99 201 328 281 534 186 34 -15 101 -74 225 -196 l177 -174 113 57 c128 65 164
            94 179 145 6 23 9 286 7 756 l-3 722 -677 -339z"/>
            <path d="M2240 2547 c-53 -17 -133 -102 -149 -160 -16 -56 -11 -118 14 -170
            11 -24 131 -149 316 -330 l299 -292 0 -254 0 -255 243 -243 242 -243 477 -140
            c263 -77 486 -140 495 -140 10 0 25 6 34 13 13 10 451 558 556 695 18 24 33
            52 33 63 0 11 -81 199 -180 417 l-181 397 -247 249 c-248 249 -281 276 -364
            291 -53 10 -141 -18 -187 -60 -58 -52 -83 -120 -79 -211 l3 -73 -40 38 c-53
            52 -97 72 -164 78 -152 14 -283 -131 -252 -278 6 -28 16 -60 22 -72 6 -12
            -143 131 -332 319 -304 302 -350 343 -393 358 -56 18 -114 19 -166 3z"/>
        </g>
    </svg>
}
