import React from 'react'
import NewButton from '../../../../../newcomponents/button/button'
import './WhatsAppSuportData.css'

export default function WhatsAppSuportData({}) {
	function whatsappOpen() {
		const whatsappNumber = '+55(47)99288-6890'
		const initMensage = 'Olá!'
		const cleaningWhatsappNumber = whatsappNumber.replace(/\D/g, '')
		const linkWhatsApp =
			'https://wa.me/' +
			cleaningWhatsappNumber +
			'?text=' +
			encodeURIComponent(initMensage)

		window.open(linkWhatsApp, '_blank')
	}

	return (
		<>
			<p>
				<b>Precisa de Ajuda? Converse Conosco no WhatsApp!</b>
			</p>
			<div className='whatsapp-suport-data'>
				<p>
					Se você está enfrentando algum problema ou tem perguntas, estamos aqui
					para ajudar!
				</p>
				<p>
					Clique no botão abaixo para ser redirecionado ao nosso atendimento via
					WhatsApp.
				</p>
				<p>
					Nossa equipe de suporte está pronta para oferecer assistência rápida e
					personalizada para resolver suas dúvidas ou preocupações.
				</p>
				<p>
					Suporte técnico e dúvidas entre 08:30 - 12:00 / 14:00 - 17:00 
					de Segunda-feira à Sexta-feira.
				</p>
			</div>

			<NewButton
				label='👉 Converse com a gente no WhatsApp'
				onClick={() => whatsappOpen()}
			/>
		</>
	)
}
