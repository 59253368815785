import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './home.css'
import { PatientIcon } from '../../component/icon/patient'
import { IcoEstetoscopio, List, MaskDataHora } from '../../component'
import { MedicalIcon } from '../../component/icon/medical'
import IcoQuickly from '../../component/icon/quickly'
import { Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { getAttendancesHistory } from '../AttendanceManager/http'
import { history } from '../../helpers'
import { getAppointmentsStats } from './http'
import { HospitalIcon } from '../../component/icon/hospital'
import { strpBarChartData, strpTodayChartData } from './helpers'
import { useApp } from '../../layout/App'

const { headDefault, barChartOptions, doughnutChartOptions } = require('./home.json')

export default function HomePage() {
  const [headStats, setHeadStats] = useState({ 'total_attendances_count': 0,  'current_week_attendances_count': 0,  'patient_count': 0 })
  const [barChartData, setBarChartData] = useState(strpBarChartData())
  const [doughnutChartData, setDoughnutChartData] = useState(strpTodayChartData())
  const [lastAppointments, setLastAppointments] = useState([])
  const { currentHealthPlaceUser } = useApp()

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

  useEffect(() => {
    fetchAttendancesHistory()
    fetchAppointmentsStats()
  }, [])

  const fetchAttendancesHistory = async (params={}) => {
    params = { 
      ...params, 
      limit: 6,
      health_place__id: currentHealthPlaceUser?.health_place?.id 
    }

    try {
        let response = await getAttendancesHistory(params)
        setLastAppointments(response.data.results)
        setHeadStats(prev => ({ ...prev, "total_attendances_count": response.data.count }))
    } catch (err) {
        console.error('fetchAttendancesHistory', err)
    }
  }

  const fetchAppointmentsStats = async () => {
    let response = await getAppointmentsStats()

    setHeadStats(prev => ({
      ...prev,
      "current_week_attendances_count": response.data.current_week_attendances_count,
      "patient_count": response.data.patient_count
    }))
    setDoughnutChartData(strpTodayChartData(response.data.today_attendance_counts))
    setBarChartData(strpBarChartData(response.data.count_attendances_by_month_and_type))
  }


  return (
    <div className='HomePage'>
      <div className='HomePage-Header'>
        <div className='HomePage-Header-Slot'>
          <div className='HomePage-Header-Slot-IconBox'>
            <IcoQuickly style={{ fill: 'var(--primary-font-color)', fontSize: '21px' }} />
          </div>
          <div className='HomePage-Header-Slot-Info'>
            <b>{ headStats.current_week_attendances_count }</b><br/>
            <span>Atendimentos na semana</span>
          </div>
        </div>
        <div className='HomePage-Header-Slot'>
          <div className='HomePage-Header-Slot-IconBox'>
            <MedicalIcon style={{ fill: 'var(--primary-font-color)', width: '21px' }} />
          </div>
          <div className='HomePage-Header-Slot-Info'>
            <b>-</b><br/>
            <span>Agendamentos na semana</span>
          </div>
        </div>
        <div className='HomePage-Header-Slot'>
          <div className='HomePage-Header-Slot-IconBox'>
            <IcoEstetoscopio style={{ fill: 'var(--primary-font-color)', fontSize: '21px' }} />
          </div>
          <div className='HomePage-Header-Slot-Info'>
            <b>{ headStats.total_attendances_count }</b><br/>
            <span>Total de Atendimentos</span>
          </div>
        </div>
        <div className='HomePage-Header-Slot'>
          <div className='HomePage-Header-Slot-IconBox'>
            <PatientIcon style={{ fill: 'var(--primary-font-color)', width: '21px' }} />
          </div>
          <div className='HomePage-Header-Slot-Info'>
            <b>{ headStats.patient_count }</b><br/>
            <span>Paciente(s)</span>
          </div>
        </div>
        <div className='HomePage-Header-Slot'>
          <div className='HomePage-Header-Slot-IconBox'>
            <HospitalIcon style={{ fill: 'var(--primary-font-color)', width: '21px' }} />
          </div>
          <div className='HomePage-Header-Slot-Info'>
            <b>{ currentHealthPlaceUser?.health_place?.name || '-' }</b><br/>
            <span>Instituição</span>
          </div>
        </div>
      </div>
      <div className='HomePage-Dashboard'>
        <div className='HomePage-Dashboard-Card HomePage-Dashboard-AppointmentHistory'>
          <Bar data={barChartData} options={barChartOptions} />
        </div>
        <div className='HomePage-Dashboard-Card HomePage-Dashboard-AppointmentsToday'>
          <Doughnut data={doughnutChartData} options={doughnutChartOptions} />
        </div>
        <div className='HomePage-Dashboard-Card HomePage-Dashboard-LastAppointments'>
          <List
            head={headDefault}
            maxHeight='240px'
            data={lastAppointments}
            noData='Nenhum atendimento encontrado'
            listCustom={(custom) => {
              const start = MaskDataHora(custom.start)

              custom['h_name'] = custom?.person?.name || '-'
              custom['h_health_professional'] = custom?.extra_kwargs?.external_doctor_name || custom?.doctor?.name || '-'
              custom['h_date'] = start?.data && start?.hora ? `${start?.data} ${start?.hora}` : '-'

              return custom
            }}
          />
          <div
            onClick={() => history.push('/atendimentos/historico')}
            className='HomePage-Dashboard-LastAppointments-Footer'
          >
            <b>Ver mais {'>'}</b>
          </div>
        </div>
      </div>

    </div>
  )
}
