import React from 'react'
import './list.css'

export function List({
	head = [],
	data = [],
	listCustom = () => null,
	children,
	noData = 'Sem informação',
	maxHeight = '100%',
	absolute = false,
}) {
	return (
		<div>
			<div className={`${absolute ? 'responsive-wrapper' : ''}`}>
				<table className='list-box'>
					<thead>
						<tr>
							{head.map(header => (
								<td
									className={header.className}
									key={header.colunm}
									style={{ width: header.width || 'auto' }}>
									{header.text}
								</td>
							))}
						</tr>
					</thead>
					<tbody
						style={
							maxHeight ? { maxHeight: maxHeight, overflowY: 'auto' } : {}
						}>
						{data.length ? (
							data.map((container, i) => {
								listCustom(container, i)
								return (
									<tr key={`${i}-${container.id ? container.id : i}`}>
										{head.map(header => (
											<td
												className={`${header.className} ${
													header.colunm === 'acoes' || header.colunm === 'h_action' ? 'list-action-row' : 'list-normal-row'
												} `}
												key={`${i}-${container.id ? container.id : i}-${
													header.colunm
												}`}
												style={{ width: header.width || 'auto' }}>
												{container[header.colunm]
													? container[header.colunm]
													: ''}
											</td>
										))}
									</tr>
								)
							})
						) : (
							<tr>
								<td
									colSpan={head.length}
									className='text-center'>
									{noData}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	)
}
