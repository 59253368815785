import './PatientUnit.css'
import React from "react";
import { useHistory } from 'react-router-dom'
import { IcoEstetoscopio, MaskTelefone, idade } from '../../../../component';
import { PatientIcon } from '../../../../component/icon/patient';
import InfoLine from '../../../../component/info-line/info-line';
import { convertDate } from '../../../../utils/convertDate';
import DuplicatePatientModal from '../DuplicatePatient/DuplicatePatientModal/DuplicatePatientModal';


export default function PatientUnit({patient=null, setModalInfo=() => null, handleRemoveDuplicatePatient=() => null}) {
    const history = useHistory()

    const defaultUser = require('../../../../media/DefaultUser.png')

    const handleDuplicatePatient = () => {
        setModalInfo({
            open: true,
            title: 'Paciente com potencial cadastro duplicado',
        content: <DuplicatePatientModal setModalInfo={setModalInfo} patient={patient} handleRemoveDuplicatePatient={handleRemoveDuplicatePatient} />,
    })}

    const getAge = () => {
        if (patient?.birthday) {
            const age = idade(patient.birthday)
            return (age.anos ? `${age.anos} ${age.anos <= 1 ? 'ano' : 'anos'}` : age.mes ? `${age.mes} ${age.mes <= 1 ? 'mes' : 'meses'}` : '0')
        } else {
            return '-'
        }
    }

    return <div className={`PatientUnit ${patient.is_active ? '' : 'PatientUnit-Inactive'}`}>
        <div className='PatientUnit-Header'>
            <div className='PatientUnit-Header-Profile'>
                <div className='PatientUnit-Header-Profile-Avatar'>
                    <div className='img'>
                        <img
                            className='PatientUnit-Header-Profile-Avatar-Img'
                            alt='Paciente foto de perfil'
                            src={patient?.avatar_img_url || defaultUser}
                        />
                    </div>
                </div>
                <div className='PatientUnit-Header-Profile-PatientInfoBox'>
                    <b className='PatientUnit-Header-Profile-PatientInfoBox-Name'>{patient.social_name || patient.name || 'Nome não cadastrado'}</b>
                    <span className='PatientUnit-Header-Profile-PatientInfoBox-AltText'>
                        {getAge()} | {patient?.sex === 'M' ? 'Masculino' : 'Feminino'} {patient.social_name ? `| Nome de registro: ${patient.name}` : ''} {patient.medical_record_number ? `| Prontuário ${patient.medical_record_number}` : ''}
                    </span>
                </div>
            </div>
            {
                patient?.duplicate_records.length > 0 
                ? <button 
                    className='PatientUnit-Header-Duplicate-Button' 
                    onClick={handleDuplicatePatient}
                    title='Clique para ver mais informações.'>
                    Duplicidade de registro
                </button>
                : null
            }
        </div>
        <div className='PatientUnit-PersonalData'>
            <div className='PatientUnit-PersonalData-Subtitle'><PatientIcon style={{ width: '21px' }} /> <b>Dados Pessoais</b></div>
            <div className='PatientUnit-PersonalData-Grid'>
                <InfoLine 
                    label='Data de Nascimento'
                    value={<span className='PatientUnit-PersonalData-Grid-AltText'>{patient?.birthday ? convertDate(patient?.birthday, '-', '/') : '-'}</span>}
                />
                <InfoLine 
                    label='Nome da Mãe'
                    value={<span className='PatientUnit-PersonalData-Grid-AltText'>{patient?.mother_name || '-'}</span>}
                />
                <InfoLine 
                    label='Telefone'
                    value={<span className='PatientUnit-PersonalData-Grid-AltText'>{patient?.contact_phone ? MaskTelefone(patient?.contact_phone) : '-'}</span>}
                />
            </div>
        </div>
        <div className='PatientUnit-ActionRow'>
            <div className='PatientUnit-ActionRow-Btn' onClick={() => {
                if (patient?.duplicate_records.length > 0) {
                    const queryString = new URLSearchParams({ hasDuplicate: true }).toString();
                    history.push(`/pacientes/${patient.id}/detalhar?${queryString}`);
                } else {
                    history.push(`/pacientes/${patient.id}/detalhar`)}
                }
            }>
                <IcoEstetoscopio /> <b>Ver paciente</b>
            </div>
        </div>
    </div>
}