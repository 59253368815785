import './SOAPPlan.css'
import React, { useEffect, useRef } from 'react'
import DocumentsEmission from './subcomponents/DocumentEmission/DocumentsEmission';
import { useAttendance } from '../../../../../../consultation';
import MedicalTeamIco from '../../../../../../../../component/icon/medicalTeam';
import PreceptorshipEvaluation from '../PreceptorshipEvaluation/PreceptorshipEvaluation';
import TextAppointmentField from '../../../TextAppointmentField/TextAppointmentField';
import ToggleAppointmentField from '../../../ToggleAppointmentField/ToggleAppointmentField';

const { PreceptorshipSections } = require('../../SOAPSection.json')

export default function SOAPPlan() {
    const { attendance, displayPreceptorship } = useAttendance()

    return <div className='SOAPPlan'>
        { !attendance?.template_type?.plan?.length
            ? <p>Não há campos selecionados no Plano para esse template</p>
            : null
        }
        { attendance?.template_type?.plan?.map(({id, name, component}) =>  {
            const components = {
                "text": <TextAppointmentField field_type={id} name={name} />,
                "select": <ToggleAppointmentField field_type={id} name={name} />
            }
            return components[component]
        })}
        {
            displayPreceptorship
            ? <div className='SOAP-Subjective-Preceptorship'>
                <div className='SOAP-Subjective-Preceptorship-Label'>
                    <MedicalTeamIco style={{ width: '21px', fill: '#CED4DA', marginRight: '8px' }} /> Preceptoria
                </div> 
                <PreceptorshipEvaluation 
                    options={PreceptorshipSections.Subjective.options}
                    feedbackKey={PreceptorshipSections.Subjective.feedbackKey}
                />
            </div>
            : null
        }
    </div>
}
