import './AttendanceHistoryFilters.css'
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { AddAlert, AlertType, Input, adicionaZero } from '../../../../../../component';
import { uniqueIDs } from '../../../../../../utils/uniqueIDs';
import { stringifyCollaboratorOption } from '../../../../../../utils/stringifyCollaboratorOption';
import { getParams } from '../../../../../../utils';
import { getHealthInsurances } from '../../../../../configuration/http';
import { getSpecialties } from '../../../../../agenda/SelectScheduleHealthPlaceUserManager/http';
import { useApp } from '../../../../../../layout/App';
import IcoCleanFilter from '../../../../../../component/icon/cleanFilter';
import  Modal, { MODAL_INIT_PROPS } from '../../../../../../newcomponents/modal/modal';
import CalendarDateModal from '../../../../../../component/calendar/CalendarDateModal';
import { formatDateWithTimezoneFromDateObj, formatISO8601Str } from '../../../../../../utils/convertDate';
import IcoPrintXLS from '../../../../../../component/icon/print_xls';
import PrinterIcon from '../../../../../../component/icon/newPrinter';
import NewMultiSelect from '../../../../../../newcomponents/form/select/multiselect';
import { getCollaborators } from '../../../../../collaborators/http';
import { INIT_APPOINTMENT_FILTERS } from '../../AttendanceHistory';
import { loaded, loading } from '../../../../../../layout/redux/AppActions';
import { postQuickEmission } from '../../../../http';
import api from '../../../../../../helpers/api';
import { parseAppointmentsURLQuerysetParams, parsePrintReportParams } from '../../../../helpers';
import { getCIDTen } from '../../../../../consultation/http';
import NewSelect from '../../../../../../newcomponents/form/select/select';
import IcoCalendarLoop from '../../../../../../component/icon/calendar_loop';
import RoutineReportsPageModal from '../RoutineReportsPageModal/RoutineReportsPageModal';
import { getAppointmentServiceLocation } from '../../../../../configuration/subcomponents/HealthUnitConfig/HealthPlaceInstitutionalMenu/http';
import AllowPopUpRedictModal from '../AllowPopUpRedictModal/AllowPopUpRedictModal';
import { uniqueCIDs } from '../../../../../consultation/subcomponents/ConsultationManager/subcomponents/SOAP/subcomponents/Assessment/helpers';

const wsScheme = process.env.NODE_ENV === 'production' ? 'wss' : 'ws'
const wsHost = process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST ? process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST : window.location.hostname

const defaultOptions = require('../../../../../../component/form/select/options.json')

export default function AttendanceHistoryFilters({ filters=null, setFilters= () => null, fetchAttendancesHistory=() => null, setModalProps=() => null }) {
    const dispatch = useDispatch()
	const { currentHealthPlaceUser } = useApp()
	const searchTimeOut = useRef(null)
	const [serviceLocationOptions, setServiceLocationOptions] = useState([])
	const [citiesOptions, setCitiesOptions] = useState([])
	const [collaboratorsOptions, setCollaboratorsOptions] = useState([])
	const [healthInsurancesOptions, setHealthInsurancesOptions] = useState([])
    const [specialtiesOptions, setSpecialtiesOptions] = useState([])
	const [cidsOptions, setCidsOptions] = useState([])
	const [secondaryModal, setSecondaryModal] = useState(MODAL_INIT_PROPS)
	const healthPlace = currentHealthPlaceUser?.health_place

	useEffect(() => {
		fetchCIDTenCodes()
		fetchServiceLocationsOptions({ offset: 0 })
		fetchCitiesOptions({ offset: 0 })
        fetchCollaborators({ offset: 0 })
		fetchHealthInsurancesOptions({ offset: 0 })
		fetchSpecialtiesOptions({ offset: 0 })
    }, [])

    const fetchCollaborators = async params => {
		await getCollaborators({
			...params,
			health_place__id: healthPlace?.id,
			limit: 500,
			profile__in: 'DOCTOR%2CDENTIST%2CPSYCHOLOGIST%2CNUTRITIONIST%2CNURSE',
			has_person: true
		})
			.then(res => setCollaboratorsOptions(res.data.results.map(instance => stringifyCollaboratorOption(instance))))
			.catch(err => {
				console.error('fetchCollaborators', err)
				setCollaboratorsOptions([])
			})
	}
	
	const fetchSpecialtiesOptions = async (params={}) => {
        params = { ...params, limit: 50 }

        try {
            let res = await getSpecialties(params)

            setSpecialtiesOptions(res.data.results)
        } catch(err) {
            console.error('fetchSpecialtiesOptions ~ ', err)
        }
    }

	const fetchHealthInsurancesOptions = async (params={}) => {
		params = getParams({ ...params, limit: 50 })

		try {
			let res = await getHealthInsurances(params)
			setHealthInsurancesOptions(res.data.results)
		} catch (err) {
			console.error('fetchHealthInsurancesOptions ~ ', err)
			dispatch(AddAlert('Planos & convênios', 'Erro ao listar planos e convênios', AlertType.ERROR))
		}
	}

	const fetchCitiesOptions = async (params={}) => {
		params = getParams({ ...params, limit: 50 })

		try {
			let res = await api.get(`address/city/${params}`)
			setCitiesOptions(res.data.results)
		} catch (err) {
			console.error('fetchCitiesOptions ~ ', err)
			dispatch(AddAlert('Listar cidades', 'Erro ao obter cidades', AlertType.ERROR))
		}
	}

	const fetchCIDTenCodes = async (params) => {
        try {
            const cids = await getCIDTen(params)
            setCidsOptions(cids)
        } catch (err) {
            setCidsOptions([])
        }
    }

	const fetchServiceLocationsOptions = async (params={}) => {
        try {
            let res = await getAppointmentServiceLocation({...params, limit: 500 })
            setServiceLocationOptions(res.data.results)
        } catch (err) {
            setServiceLocationOptions([])
            console.error('fetchServiceLocationsOptions ~ ', err)
        }
    }

	const handleSearchCollaborators = (event) => {
		if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

		searchTimeOut.current = setTimeout(() => {
			fetchCollaborators({ 'name_cpf_or_email': event.target.value })
		}, 400, event.target.value)
	}

	const handleSearchHealthInsurance = (event) => {
		if (searchTimeOut.current) clearTimeout(searchTimeOut.current)
	
		searchTimeOut.current = setTimeout(() => {
			fetchHealthInsurancesOptions({ 'name': event.target.value })
		}, 400, event.target.value)
	}

	const handleSearchSpecialties = (event) => {
        if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

        searchTimeOut.current = setTimeout(() => {
            fetchSpecialtiesOptions({ 'name__icontains': event.target.value })
        }, 400, event.target.value)
    }

	const handleSearchCity = (event) => {
		if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

		searchTimeOut.current = setTimeout(() => {
			fetchCitiesOptions({ 'name': event.target.value })
		}, 400, event.target.value)
	}
	
	const handleSearchCIDs = (search) => {
        if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

        searchTimeOut.current = setTimeout((search) => {
            fetchCIDTenCodes({ search });
        }, 400, search)
    };

	const handleSearchServiceLocations = (event) => {
        if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

        searchTimeOut.current = setTimeout(() => {
            fetchServiceLocationsOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }

	const openRoutineReportModal = () => {
		setModalProps(prev => ({
			...prev,
			open: true,
			title: 'Rotina de Relatórios',
			content: <RoutineReportsPageModal setModalProps={setModalProps} />
		}))
	}

	const openCalendarDateModal = (filterKey, modalTitle) => {
		const date_cursor = filters?.[filterKey];
		const currentDate = date_cursor ? new Date(date_cursor) : new Date();
	
		const currentDay = String(adicionaZero(currentDate.getDate()));
		const currentMonth = String(adicionaZero(currentDate.getMonth() + 1)); // Months are zero-indexed
		const currentYear = String(currentDate.getFullYear());
		
		setSecondaryModal(prev => ({
			...prev,
			open: true,
			title: modalTitle,
			content: (
				<CalendarDateModal
					name={filterKey}
					currentDay={currentDay}
					currentMonth={currentMonth}
					currentYear={currentYear}
					renderNextSelectedDay={(dateEvent) => {
						handleSelectDateObj(filterKey, dateEvent);
						setSecondaryModal(MODAL_INIT_PROPS);
					}}
				/>
			)
		}));
	};

	const openCalendarStartGteDateModal = () => {
		openCalendarDateModal('start__gte', 'De (Data de Atendimento)');
	};
	
	const openCalendarStartLteDateModal = () => {
		openCalendarDateModal('start__lte', 'Até (Data de Atendimento)');
	};
	
	const openCalendarBirthdayGteDateModal = () => {
		openCalendarDateModal('person__birthday__gte', 'De (Data de Nascimento)');
	};
	
	const openCalendarBirthdayLteDateModal = () => {
		openCalendarDateModal('person__birthday__lte', 'Até (Data de Nascimento)');
	};

	const handleSelectDateObj = (name, {yearSearchCursor, monthSearchCursor, daySearchCursor}) => {
		let nextDate = new Date(yearSearchCursor, parseInt(monthSearchCursor, 10) - 1, daySearchCursor)
		setFilters(prev => ({ ...prev, [name]: nextDate }))

		let formattedNextDate = name.endsWith('__gte') ? formatDateWithTimezoneFromDateObj(nextDate, '00', '00') :  formatDateWithTimezoneFromDateObj(nextDate, '23', '59')
		if (name.startsWith('person__birthday__')) {
			formattedNextDate = formattedNextDate.substring(0, 10)
		}

		fetchAttendancesHistory({ offset: 0, [name]: formattedNextDate })
	}

	const toggleOption = (loadKey, optionsList, fieldNameRef, optionRef) => {
		const selectedOption = optionsList.find(option => option[fieldNameRef] === optionRef);
		const isExisting = filters?.[loadKey].find(option => option[fieldNameRef] === optionRef);
		
		let nextState = filters
		if (isExisting) {
			nextState = { ...filters, [loadKey]: filters[loadKey].filter(option => option[fieldNameRef] !== optionRef) }
		} else {
			nextState = { ...filters, [loadKey]: [...filters[loadKey], selectedOption] }
		}
		setFilters(nextState)

		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(() => {
				fetchAttendancesHistory({ offset: 0, ...parseAppointmentsURLQuerysetParams(nextState) })
			}, 300)
		} catch (err) {
			console.error('handleSearch', err)
		}
	};

	const handleSearch = async target => {
		let nextState = filters

		nextState = { ...filters, [target.name]: target.value }
		
		setFilters(nextState)

		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(() => {
				fetchAttendancesHistory({ offset: 0, ...parseAppointmentsURLQuerysetParams(nextState) })
			}, 300)
		} catch (err) {
			console.error('handleSearch', err)
		}
	}

	const downloadPDF = async () => {
		setSecondaryModal(MODAL_INIT_PROPS)
		const params = parsePrintReportParams(filters);

		dispatch(loading());
		try {
			let response = await postQuickEmission({ params })
			const taskId = response.data.task_id
			console.log(response)
			const client = new WebSocket(`${wsScheme}://${wsHost}/ws/reports/dowload_pdf/${taskId}/`)

			client.onopen = () => {
                console.log('PDF Status WebSocket Client Connected');
            }

			client.onmessage = (message) => {
				dispatch(loaded())
				const dataFromServer = JSON.parse(message.data, '_blank');
				const newWindow = window.open(dataFromServer.download_url)
				console.log(newWindow)
				if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') { 
					console.log('teste1')
					setSecondaryModal(prev => ({ 
						...prev,
						open: true,
						title: 'Pop-up e redirecionamento bloqueado',
						content: <AllowPopUpRedictModal onClick={downloadPDF} download_url={dataFromServer.download_url} /> 
					}))
					console.log('teste2')
				} else {
					dispatch(AddAlert('Relatório de atendimento', 'Relatório gerado com sucesso.', AlertType.SUCCESS))
				}
				client.close()
			}
			dispatch(AddAlert('Relatório de atendimento', 'Gerando PDF, aguarde um instante.', AlertType.INFO, 12000))
		} catch (err) {
			dispatch([loaded(), AddAlert('Relatório de atendimento', 'Não foi possível gerar o relatório', AlertType.ERROR)])
		}
	};
	
	const handleExportXLS = () => {
		const params = parsePrintReportParams(filters)

		dispatch(loading())
		api.post('/reports/export_csv', { params })
			.then(res => {
				dispatch([loaded(), AddAlert('Relatório de atendimento', 'CSV gerado com sucesso', AlertType.SUCCESS)])
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'historico-de-atendimentos.csv'); 
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			})
			.catch(() => {
                dispatch([loaded(), AddAlert('Relatório de atendimento', 'Não foi possível gerar o relatório', AlertType.ERROR)])
            })
	}

	return <div>
		<Modal {...secondaryModal} dismissFn={() => setSecondaryModal(MODAL_INIT_PROPS)} />
        <section className='AttendanceHistoryFilters-SideBarLeft'>
			<div className='AttendanceHistoryFilters-ActionRow'>
				<div
					onClick={() => {
						setFilters(INIT_APPOINTMENT_FILTERS)
						fetchAttendancesHistory(INIT_APPOINTMENT_FILTERS)
					}}
					title="Limpar filtro"
				>
					<IcoCleanFilter />
				</div>
				<div
					onClick={downloadPDF}
					title="Imprimir atendimentos"
				>
					<PrinterIcon />
				</div>
				<div
					onClick={handleExportXLS}
					title="Imprimir XLS"
				>
					<IcoPrintXLS />
				</div>
				<div
					onClick={openRoutineReportModal}
					title='Rotina de Relatório'
				>
					<IcoCalendarLoop />
				</div>
			</div>


			<div className='AttendanceHistoryFilters-Separator' />
			<div>
				<NewSelect
					label='Filtrar por'
                    id='ordering'
                    onSelect={(event) => handleSearch({ 'name': 'ordering', 'value': event.target.selected  })}
                    selected={filters.ordering}
                    options={defaultOptions.APPOINTMENT_ORDERING_OPTIONS}
					canBeEmpty={false}
                />
				<div>
					<b>Data de atendimento</b>
					<div className='AttendanceHistoryFilters-AttendanceDateDiv'>
						<Input
							label='De'
							name='start__gte'
							placeholder='DD/MM/YYYY'
							actionFocus={openCalendarStartGteDateModal}
							value={formatISO8601Str(filters?.start__gte)}
						/>
						<Input
							label='Até'
							name='start__lte'
							placeholder='DD/MM/YYYY'
							actionFocus={openCalendarStartLteDateModal}
							value={formatISO8601Str(filters?.start__lte)}
						/>
					</div>
				</div>
				<Input
					label='Paciente'
					name='name_or_cpf'
					action={event => handleSearch(event.target)}
					autoComplete='false'
					placeholder='Filtrar por Nome | CPF | RG'
				/>
				<NewMultiSelect
					label='Profissionais'
					filterNode={
						<div className='NewAppointmentModal-NewSelect'>
							<Input
								placeholder='Pesquisar por e-mail, nome ou cpf'
								action={handleSearchCollaborators}
								actionFocus={() => fetchCollaborators()}
							/>
						</div>
					}
					defaultText={filters?.doctor__id__in?.length ? `${filters?.doctor__id__in.length} selecionados` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => {
						toggleOption('doctor__id__in', collaboratorsOptions, 'id', optionRef)
						fetchCollaborators({ offset: 0 })
					}}
					selectedOptions={filters?.doctor__id__in?.map(({id}) => id)}
					optionRefKey='id'
					optionStrKey='str'
					options={collaboratorsOptions}
				/>
				<NewMultiSelect
					label='Locais de Atendimento'
					filterNode={<div className='NewAppointmentModal-NewSelect'>
						<Input 
							placeholder='Pesquisar por nome'
							action={handleSearchServiceLocations}
							actionFocus={()=> fetchServiceLocationsOptions()}
						/>
					</div>
					}
					defaultText={filters?.service_location__id__in?.length ? `${filters?.service_location__id__in.length} selecionados` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => {
						toggleOption('service_location__id__in', serviceLocationOptions, 'id', optionRef)
						fetchServiceLocationsOptions({ offset: 0 })
					}}
					selectedOptions={filters?.service_location__id__in?.map(({id}) => id)}
					optionRefKey='id'
					optionStrKey='name'
					options={serviceLocationOptions}
				/>
				<NewMultiSelect
					label='Especialidades'
					filterNode={
						<div className='NewAppointmentModal-NewSelect'>
							<Input
								placeholder='Pesquisar por nome'
								action={handleSearchSpecialties}
								actionFocus={() => fetchSpecialtiesOptions({ offset: 0 })}
							/>
						</div>
					}
					defaultText={filters.specialty__id__in?.length ? `${filters.specialty__id__in.length} selecionadas` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => {
						toggleOption('specialty__id__in', specialtiesOptions, 'id', optionRef)
						fetchSpecialtiesOptions({ offset: 0 })
					}}
					selectedOptions={filters.specialty__id__in?.map(specialty => specialty.id)}
					optionRefKey='id'
					optionStrKey='strf'
					options={uniqueIDs(filters.specialty__id__in, specialtiesOptions)}
				/>
				<NewMultiSelect
					label='Convênios'
					filterNode={
						<div className='NewAppointmentModal-NewSelect'>
							<Input
								placeholder='Pesquisar por nome'
								action={handleSearchHealthInsurance}
								actionFocus={() => fetchHealthInsurancesOptions()}
							/>
						</div>
					}
					defaultText={filters.health_insurance__id__in?.length ? `${filters.health_insurance__id__in.length} selecionados` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => {
						toggleOption('health_insurance__id__in', healthInsurancesOptions, 'id', optionRef)
						fetchHealthInsurancesOptions()
					}}
					selectedOptions={filters.health_insurance__id__in?.map(insurance => insurance.id)}
					optionRefKey='id'
					optionStrKey='name'
					options={uniqueIDs(filters.health_insurance__id__in, healthInsurancesOptions)}
				/>
				<NewMultiSelect
					label='Residência do Paciente'
					filterNode={
						<div className='NewAppointmentModal-NewSelect'>
							<Input 
								placeholder='Pesquisar por cidade'
								action={handleSearchCity}
								actionFocus={()=> fetchCitiesOptions()}
							/>
						</div>
					}
					defaultText={filters.person__address__city__in.length ? `${filters.person__address__city__in.length} selecionados` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => toggleOption('person__address__city__in', citiesOptions, 'id', optionRef)}
					selectedOptions={filters.person__address__city__in.map(option => option.id)}
					optionRefKey='id'
					optionStrKey='name'
					options={uniqueIDs(filters.person__address__city__in, citiesOptions)}
				/>
				<NewMultiSelect
					label='CIDs'
					filterNode={<div className='AttendanceHistoryFilters-NewMultiSelect-FilterNode'>
						<Input 
							placeholder='Pesquisar por CID ou Título'
							action={({ target: { value } }) => handleSearchCIDs(value)}
							actionFocus={()=> fetchCIDTenCodes()}
						/>
					</div>}
					defaultText={filters.related_diseases_code.length ? `${filters.related_diseases_code.length} selecionados` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => toggleOption('related_diseases_code', cidsOptions, 'code', optionRef)}
					selectedOptions={filters.related_diseases_code?.map(cid => cid.code) || []}
					optionRefKey='code'
					optionStrKey='title'
					options={filters.related_diseases_code
						? uniqueCIDs([...filters.related_diseases_code, ...cidsOptions]).map(cid => ({ ...cid, title: `${cid.code} | ${cid.title}` }))
						: []
					}
				/>
				<NewMultiSelect
					label='Categorias'
					filterNode={null}
					defaultText={filters.classification__in?.length ? `${filters.classification__in.length} selecionadas` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => toggleOption('classification__in', defaultOptions.CLASSIFICATION_CHOICES, 'id', optionRef)}
					selectedOptions={filters?.classification__in?.map(classification => classification.id)}
					optionRefKey='id'
					optionStrKey='name'
					options={defaultOptions.CLASSIFICATION_CHOICES}
				/>
				<NewMultiSelect
					label='Status'
					filterNode={null}
					defaultText={filters.decision__in.length ? `${filters.decision__in.length} selecionadas` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => toggleOption('decision__in', defaultOptions.DECISION_CHOICES, 'id', optionRef)}
					selectedOptions={filters.decision__in.map(decision => decision.id)}
					optionRefKey='id'
					optionStrKey='name'
					options={defaultOptions.DECISION_CHOICES}
				/>
				<NewMultiSelect
					label='Prioridades'
					filterNode={null}
					defaultText={filters.priority__in.length ? `${filters.priority__in.length} selecionadas` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => toggleOption('priority__in', defaultOptions.PRIORITY_CHOICES, 'id', optionRef)}
					selectedOptions={filters.priority__in.map(priority => priority.id)}
					optionRefKey='id'
					optionStrKey='name'
					options={defaultOptions.PRIORITY_CHOICES}
				/>
				<NewMultiSelect
					label='Tipos'
					filterNode={null}
					defaultText={filters.result__in.length ? `${filters.result__in.length} selecionados` : 'Selecione'}
					onlyDefaultText
					onSelect={({ optionRef }) => toggleOption('result__in', defaultOptions.RESULT_CHOICES, 'id', optionRef)}
					selectedOptions={filters.result__in.map(result => result.id)}
					optionRefKey='id'
					optionStrKey='name'
					options={defaultOptions.RESULT_CHOICES}
				/>
				<div>
					<b>Data de Nascimento</b>
					<div className='AttendanceHistoryFilters-AttendanceDateDiv'>
						<Input
							label='De'
							name='person__birthday__gte'
							placeholder='DD/MM/YYYY'
							actionFocus={openCalendarBirthdayGteDateModal}
							value={formatISO8601Str(filters?.person__birthday__gte)}
						/>
						<Input
							label='Até'
							name='person__birthday__lte'
							placeholder='DD/MM/YYYY'
							actionFocus={openCalendarBirthdayLteDateModal}
							value={formatISO8601Str(filters?.person__birthday__lte)}
						/>
					</div>
				</div>
			</div>

		</section>
    </div>
}