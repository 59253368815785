import './DentistAppointment.css'
import React, { useContext, useEffect, useState } from 'react'
import Button, { ButtonModel } from '../../../../../../../../../../../../newcomponents/button/button'
import AlertRowText, { AlertRowTextType } from '../../../../../../../../../../../../component/alertRowText/alertRowText'
import DentistAppointmentAssessment from './subcomponents/DentistAppointmentAssessment/Assessment'
import DentistAppointmentPlan from './subcomponents/DentistAppointmentPlan/Plan'
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../../../../../newcomponents/modal/modal'
import { getNameById, strpDentistAssessment } from './utils'
import { useDispatch } from 'react-redux'
import { AddAlert, AlertType } from '../../../../../../../../../../../../component'
import { getDentistAppointments, putDentistAppointment } from './http'
import { formatDate } from '../../../../../../../../../../../../utils/convertDate'
import DentistAppointmentAssessmentAux from './subcomponents/DentistAppointmentAssessmentAux/AssessmentAux'
import { useThousandDays } from '../../../../ThousandDaysSection'


const { ToothMapNames, ToothObs, assessmentOptions } = require('./DentistAppointment.json')

const initModalInfo = { 'title': '', open: false, confirmText: '', content: null }

const DentistAppointmentContext = React.createContext()

export function useDentistAppointment() {
    return useContext(DentistAppointmentContext)
}

const DentistAppointmentPreNatalModal = () => {
    const { setModalInfo, saveDentistAppointment } = useDentistAppointment()
    const [openSection, setOpenSection] = useState('assessment')
    const {selectedLanguage} = useThousandDays()

		const {
			Dentalmapping,
			Evaluation,
			Treatmentplan,
			Treatmentplacehold,
			Close,
			Saved,
			Visibleplaque,
			Spontaneousbleeding,
			Presenceofdentalcalculus,
			Presenceofmobility,
			Bleedingonprobing,
			Lossofattachmentwithadiagnosisofperiodontitis,
			Assessment_Options,
		} = require('../../../../../../../../../../../data/translation.json')[selectedLanguage]


    const sections = {
			assessment: <DentistAppointmentAssessment />,
			assessment_aux: (
				<DentistAppointmentAssessmentAux
					Visibleplaque={Visibleplaque}
					Spontaneousbleeding={Spontaneousbleeding}
					Presenceofdentalcalculus={Presenceofdentalcalculus}
					Presenceofmobility={Presenceofmobility}
					Bleedingonprobing={Bleedingonprobing}
					Lossofattachmentwithadiagnosisofperiodontitis={Lossofattachmentwithadiagnosisofperiodontitis}
					Assessment_Options={Assessment_Options}
				/>
			),
			plan: <DentistAppointmentPlan Treatmentplacehold={Treatmentplacehold} />,
		}

    return <div className='DentistAppointmentPreNatalModal'>
        <div className='DentistAppointmentPreNatalModal-Header'>
            <div className={openSection === 'assessment' ? 'active' : ''} onClick={() => setOpenSection('assessment')}>
                <b>{Dentalmapping}</b>
            </div>
            <div className={openSection === 'assessment_aux' ? 'active' : ''} onClick={() => setOpenSection('assessment_aux')}>
                <b>{Evaluation}</b>
            </div>
            <div className={openSection === 'plan' ? 'active' : ''} onClick={() => setOpenSection('plan')}>
                <b>{Treatmentplan}</b>
            </div>
        </div>
        <div className='DentistAppointmentPreNatalModal-Content'>
            { sections[openSection] }
        </div>
        <div className='DentistAppointmentPreNatalModal-RowBtnBox'>
            <Button
                label={Close}
                model={ButtonModel.SECONDARY}
                onClick={() => setModalInfo({})}
            />
            <Button
                label={Saved}
                onClick={() => saveDentistAppointment()}
            />
        </div>
    </div>
}


export default function DentistAppointment({ project=null, attendance=null }) {
    const dispatch = useDispatch()
    const [modalInfo, setModalInfo] = useState(initModalInfo)
    const [activeIndex, setActiveIndex] = useState(null)
    const [assessment, setAssessment] = useState(strpDentistAssessment(null, project, attendance))
    const [dentistAppointments, setDentistAppointments] = useState([])
    const { selectedLanguage } = useThousandDays()

	const {
        Dentalcheckups,
        Newappointment,
        Successfullysaved,
        Completed,
        Noappointmentscompleted,
        Treatmentplan,
        Visibleplaque,
        Spontaneousbleeding,
        Presenceofdentalcalculus,
        Presenceofmobility,
        Bleedingonprobing,
        Lossofattachmentwithadiagnosisofperiodontitis
		} = require('../../../../../../../../../../../data/translation.json')[selectedLanguage]

    useEffect(() => {
        fetchDentistAppointments()
    }, [])

    const fetchDentistAppointments = async () => {
        let res = await getDentistAppointments(attendance, project)
        setDentistAppointments(res.data.results)
    }

    const saveDentistAppointment = async () => {
        try {
            if (assessment?.attendance || assessment?.project) {
                await putDentistAppointment(assessment)
                fetchDentistAppointments()
                dispatch(AddAlert(Dentalcheckups, Successfullysaved, AlertType.SUCCESS))
                setModalInfo({})
                setAssessment(strpDentistAssessment(null, project, attendance))
            }
        } catch (err) {
            console.error('saveDentistAppointment ~', err)
        }
    }

    const toogleActiveIndex = (index) => {
        setActiveIndex(index === activeIndex ? null : index)
    }

    return (
			<DentistAppointmentContext.Provider
				value={{
					modalInfo,
					setModalInfo,
					assessment,
					setAssessment,
					project,
					attendance,
					saveDentistAppointment,
				}}
			>
				<Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
				<p className='DentistAppointment-Subtitle'>{Dentalcheckups}</p>
				<div className='DentistAppointment-Separator' />
				<Button
					label={Newappointment}
					onClick={() =>
						setModalInfo((prev) => ({
							...prev,
							open: true,
							title: Dentalcheckups,
							content: <DentistAppointmentPreNatalModal />,
						}))
					}
				/>
				<p className='PreNatalAttendance-Subtitle'>
					<strong>{Completed}</strong>
				</p>
				<div className='DentistAppointment-Separator' />
				{!dentistAppointments.length ? (
					<AlertRowText
						content={Noappointmentscompleted}
						type={AlertRowTextType.INFO}
						outlined
					/>
				) : (
					dentistAppointments.map((appointment, index) => (
						<div
							key={appointment.id}
							className='DentistAppointment-RealizedRow'
						>
							<Button
								label={formatDate(appointment.last_updated)}
								onClick={() => toogleActiveIndex(index)}
								model={ButtonModel.SECONDARY}
							/>
							{activeIndex === index ? (
								<div className='DentistAppointment-List-Instance'>
									<div className='DentistAppointment-List-ToothGrid'>
										{Object.entries(appointment)
											.filter(
												([key, value]) =>
													key.startsWith('_') &&
													(value.situation !== 'NOT_EVALUATED' ||
														value.procedures_performed)
											)
											.map(([key, { situation, procedures_performed }]) => (
												<div key={key}>
													<p>
														<strong>
															{ToothMapNames[key.replace('_', '')]}
														</strong>
													</p>
													{situation ? (
														<p>{getNameById(situation, ToothObs)}</p>
													) : null}
													{procedures_performed ? (
														<p>{procedures_performed}</p>
													) : null}
												</div>
											))}
										<div>
											<p>
												<strong>{Visibleplaque}</strong>
											</p>
											<p>
												{getNameById(
													appointment.visible_plate,
													assessmentOptions
												)}
											</p>
										</div>
										<div>
											<p>
												<strong>{Spontaneousbleeding}</strong>
											</p>
											<p>
												{getNameById(
													appointment.spontaneous_bleeding,
													assessmentOptions
												)}
											</p>
										</div>
										<div>
											<p>
												<strong>{Presenceofdentalcalculus}</strong>
											</p>
											<p>
												{getNameById(
													appointment.presence_of_dental_calculus,
													assessmentOptions
												)}
											</p>
										</div>
										<div>
											<p>
												<strong>{Presenceofmobility}</strong>
											</p>
											<p>
												{getNameById(
													appointment.presence_of_mobility,
													assessmentOptions
												)}
											</p>
										</div>
										<div>
											<p>
												<strong>
													{Bleedingonprobing}
												</strong>
											</p>
											<p>
												{getNameById(
													appointment.bleeding_on_probing_in_at_least_10,
													assessmentOptions
												)}
											</p>
										</div>
										<div>
											<p>
												<strong>
													{Lossofattachmentwithadiagnosisofperiodontitis}
												</strong>
											</p>
											<p>
												{getNameById(
													appointment.attachment_loss_with_diagnosis_of_periodontitis,
													assessmentOptions
												)}
											</p>
										</div>
									</div>
									{appointment.treatment_plan ? (
										<div className='DentistAppointment-List-TreatmentPlan'>
											<p>
												<strong>{Treatmentplan}</strong>
											</p>
											<p>{appointment.treatment_plan}</p>
										</div>
									) : null}
								</div>
							) : null}
						</div>
					))
				)}
			</DentistAppointmentContext.Provider>
		)
}

