export const getYearsStart1900 = () => {
    let years = []
    for (let i = new Date().getFullYear(); i > 1900; i--) {
        years.push({ id: `${i}`, name: `${i}` })
    }
    return years
}

export const getYearsStart1900OlderThan18 = () => {
    let years = []
    for (let i = new Date().getFullYear() - 18; i > 1900; i--) {
        years.push({ id: `${i}`, name: `${i}` })
    }
    return years
}

export const getYearsStart1900Plus30 = () => {
    let years = []
    for (let i = new Date().getFullYear() + 30 ; i > 1900; i--) {
        years.push({ id: `${i}`, name: `${i}` })
    }
    return years
}