import React from "react";
import { useParams } from "react-router"
import Button, { ButtonModel } from "../../../../../newcomponents/button/button";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../../../../layout/redux/AppActions";
import { apiV2, history } from "../../../../../helpers";
import { MODAL_INIT_PROPS } from "../../../../../newcomponents/modal/modal";

export default function RemovePreceptorshipRoomModal({ setModalInfo=() => null }) {
    const dispatch = useDispatch()
    const { id } = useParams()

    const handleDeleteRoom = async () => {
        dispatch(loading())
        try {
            await apiV2.delete(`preceptorship/group/${id}`)
            history.push('/preceptoria')
        } catch (err) {
            console.error("RemovePreceptorshipRoomModal ~ handleDeleteRoom ", err)
        }
        dispatch(loaded())
    }

    return <div>
        <p>Tem certeza que deseja <b>excluir</b> essa turma?</p>

        <div className="RemoveStudent-BtnBox">
            <Button 
                label='Fechar'
                model={ButtonModel.SECONDARY}
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
            />
            <Button 
                label='Remover'
                model={ButtonModel.DANGER}
                onClick={handleDeleteRoom}
            />
        </div>
    </div>
}