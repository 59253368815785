import React from 'react'

export function PersonIcon({ style }) {
  return (
    <svg style={style} className='icon-person' viewBox="0 0 512.000000 512.000000">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M331 4784 c-30 -8 -76 -26 -102 -41 -59 -31 -148 -123 -178 -181 -53
            -104 -51 -50 -51 -1522 0 -1472 -2 -1418 51 -1522 30 -58 119 -150 177 -181
            107 -58 87 -57 1057 -57 872 0 892 0 912 20 12 10 24 29 28 40 9 28 -11 75
            -38 89 -16 8 -273 12 -922 14 l-900 2 -53 30 c-59 32 -91 66 -125 129 l-22 41
            0 1395 0 1395 22 41 c34 63 66 97 125 129 l53 30 2195 0 2195 0 41 -22 c63
            -34 97 -66 129 -125 l30 -53 5 -1368 5 -1369 28 -24 c37 -32 62 -30 98 5 l29
            29 0 1349 c0 1472 3 1406 -57 1515 -31 58 -123 147 -181 177 -105 54 20 51
            -2327 50 -1863 0 -2178 -2 -2224 -15z"/>
            <path d="M1005 4297 c-22 -13 -49 -40 -62 -62 -21 -37 -23 -53 -23 -197 l0
            -158 -157 0 c-145 0 -161 -2 -198 -23 -22 -13 -49 -40 -62 -62 -21 -38 -23
            -51 -23 -235 0 -184 2 -197 23 -235 13 -22 40 -49 62 -62 37 -21 53 -23 198
            -23 l157 0 0 -157 c0 -145 2 -161 23 -198 13 -22 40 -49 62 -62 38 -21 51 -23
            235 -23 184 0 197 2 235 23 22 13 49 40 62 62 21 37 23 53 23 198 l0 157 158
            0 c144 0 160 2 197 23 22 13 49 40 62 62 21 38 23 51 23 235 0 184 -2 197 -23
            235 -13 22 -40 49 -62 62 -37 21 -53 23 -197 23 l-158 0 0 158 c0 144 -2 160
            -23 197 -13 22 -40 49 -62 62 -38 21 -51 23 -235 23 -184 0 -197 -2 -235 -23z
            m395 -332 c0 -263 -18 -245 245 -245 l195 0 0 -160 0 -160 -195 0 c-263 0
            -245 18 -245 -245 l0 -195 -160 0 -160 0 0 195 c0 263 18 245 -245 245 l-195
            0 0 160 0 160 195 0 c263 0 245 -18 245 245 l0 195 160 0 160 0 0 -195z"/>
            <path d="M2425 4135 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 671
            0 671 0 24 25 c33 32 33 78 0 110 l-24 25 -671 0 -671 0 -24 -25z"/>
            <path d="M4025 4135 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 271
            0 271 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -271 0 -271 0
            -24 -25z"/>
            <path d="M3465 2554 c-170 -26 -296 -67 -431 -138 -311 -166 -534 -463 -610
            -816 -26 -118 -26 -362 0 -480 104 -482 474 -845 961 -941 91 -18 339 -18 430
            0 495 98 868 471 966 966 21 103 18 349 -5 455 -100 466 -454 826 -916 931
            -83 19 -327 33 -395 23z m281 -165 c212 -29 408 -126 571 -282 312 -299 405
            -750 237 -1150 -34 -81 -103 -198 -152 -256 l-28 -33 -27 82 c-15 45 -46 114
            -69 153 -57 100 -190 229 -293 286 -44 25 -81 45 -82 46 -2 1 17 24 42 51 89
            96 132 219 122 354 -13 186 -132 343 -308 408 -227 83 -480 -22 -585 -242 -31
            -65 -37 -90 -42 -165 -8 -143 31 -256 122 -358 l46 -51 -31 -12 c-102 -43
            -248 -167 -321 -274 -47 -68 -92 -164 -109 -230 -11 -46 -12 -46 -31 -29 -36
            32 -128 180 -163 261 -61 139 -79 236 -80 412 0 115 5 174 18 228 75 312 265
            556 543 697 193 98 405 134 620 104z m-9 -504 c57 -29 111 -82 145 -147 19
            -36 23 -58 23 -133 0 -78 -4 -97 -27 -141 -57 -108 -156 -168 -278 -168 -122
            0 -221 60 -278 168 -23 44 -27 63 -27 141 0 78 4 97 27 141 28 53 87 112 137
            139 81 41 192 41 278 0z m65 -792 c46 -16 109 -46 140 -67 69 -46 165 -146
            202 -212 33 -58 71 -171 80 -235 l7 -46 -53 -35 c-356 -236 -800 -236 -1156 0
            l-53 35 7 46 c9 64 47 177 80 235 36 65 133 167 197 209 167 108 364 134 549
            70z"/>
            <path d="M505 2215 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 351
            0 351 0 24 25 c33 32 33 78 0 110 l-24 25 -351 0 -351 0 -24 -25z"/>
            <path d="M1625 2215 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 351
            0 351 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -351 0 -351 0
            -24 -25z"/>
        </g>
    </svg>
  )
}