import React from "react"

export default function TemplatesIcon({ style={} }) {
    return <svg style={style} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M589 5101 l-24 -19 0 -2522 0 -2522 24 -19 c23 -19 61 -19 1539 -19
            l1516 0 444 442 c244 244 450 455 458 470 12 23 14 260 14 1622 0 1164 -3
            1602 -11 1620 -23 51 -103 55 -128 7 -8 -14 -11 -499 -11 -1580 l0 -1561 -413
            0 -414 0 -21 -23 c-22 -23 -22 -26 -22 -435 l0 -412 -1415 0 -1415 0 0 2410 0
            2410 1850 0 1850 0 0 -258 c0 -257 0 -259 23 -280 31 -29 72 -28 103 3 l25 25
            -3 311 -3 311 -24 19 c-23 19 -69 19 -1971 19 -1903 0 -1948 0 -1971 -19z
            m3711 -4234 c0 -1 -137 -139 -305 -307 l-305 -305 0 308 0 307 305 0 c168 0
            305 -1 305 -3z"/>
            <path d="M1003 4548 c-12 -6 -27 -22 -33 -37 -7 -18 -10 -190 -8 -563 3 -511
            4 -537 22 -557 l19 -21 1557 0 1557 0 19 21 c18 20 19 46 22 557 2 372 -1 545
            -8 563 -6 15 -23 31 -36 38 -34 15 -3079 15 -3111 -1z m3007 -583 l0 -445
            -1450 0 -1450 0 0 445 0 445 1450 0 1450 0 0 -445z"/>
            <path d="M1014 3190 c-57 -23 -54 31 -54 -1027 0 -701 3 -979 11 -997 7 -13
            23 -30 38 -36 18 -7 238 -10 723 -8 668 3 697 4 717 22 l21 19 0 997 0 997
            -21 19 c-20 18 -48 19 -718 21 -383 1 -706 -2 -717 -7z m1306 -1030 l0 -890
            -605 0 -605 0 0 883 c0 486 3 887 7 890 3 4 276 7 605 7 l598 0 0 -890z"/>
            <path d="M2700 3193 c-8 -3 -23 -12 -32 -21 -17 -14 -18 -48 -18 -452 0 -431
            0 -437 21 -456 20 -18 47 -19 731 -19 l710 0 24 28 24 28 0 419 0 419 -24 28
            -24 28 -699 2 c-384 1 -705 -1 -713 -4z m1305 -473 l0 -325 -602 -3 -603 -2 0
            330 0 330 603 -2 602 -3 0 -325z"/>
            <path d="M2670 2050 c-19 -19 -20 -33 -20 -453 0 -428 0 -434 21 -453 20 -18
            49 -19 717 -22 492 -2 705 1 724 9 14 6 31 23 37 38 8 19 11 153 9 443 -3 393
            -4 417 -22 437 -19 21 -20 21 -733 21 -700 0 -713 0 -733 -20z m1340 -455 l0
            -325 -605 0 -605 0 0 325 0 325 605 0 605 0 0 -325z"/>
        </g>
    </svg>
}
