import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddAlert, AlertType } from '../../../../../../../../component'
import { searchCep } from '../../../../../../../../layout/redux/AppActions'
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button'
import { useHealthPlaceForm } from '../SaveHealthPlaceModal'
import { useConfiguration } from '../../../../../../configuration'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import FormAddress from './FormAddress'


export default function HealthPlaceAddressData() {
	const dispatch = useDispatch()
	const { setModalProps } = useConfiguration()
	const { setSelectedHealthPlace, selectedHealthPlace, onNext } = useHealthPlaceForm()
	const [hasSubmitted, setHasSubmitted] = useState(false)
	const [errors, setErrors] = useState({
		zip: false,
		state: false,
		city: false,
		district: false,
		address: false,
		number: false
	})

	useEffect(() => {
		if (hasSubmitted) {
			validateFields()
		}
	}, [selectedHealthPlace])

	const handleChange = (event) => {
		const { name, value } = event.target

		if (hasSubmitted) {
			const error = handleValidateField(name, value)
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: error,
			}));
		}

		setSelectedHealthPlace((prev) => ({
			...prev,
			address: { ...prev.address, [name]: name === 'city' ? value.id : value },
		}))
	}

	const validateFields = () => {
		const fields = ['zip', 'district', 'address', 'number']
		let validationErrors = {}

		fields.forEach(field => {

			const fieldError = handleValidateField(field, selectedHealthPlace['address'][field])
			validationErrors[field] = fieldError;

		});
		setErrors(validationErrors);
		return Object.values(validationErrors).some((error) => error === true);
	}

	const handleValidateField = (name, value) => {
		let error = false

		switch (name) {
			case 'number':
				error = isNaN(value) || !value
				break
			case 'complement':
				error = false
				break
			default:
				if (!value) {
					error = true
				}
				break
		}
		return error
	}

	const handleNext = () => {
		setHasSubmitted(true)

		const hasError = validateFields()

		if (hasError) {
			dispatch(AddAlert('Erro na validação','Corrija os campos em vermelho para avançar.',AlertType.ERROR))
			return
		}
		onNext()
	}

	const handleSearchCep = (e) => {
		let cep = Promise.resolve({
			then: function (onFulfill, onReject) {
				onFulfill(searchCep(e))
				onReject(searchCep(e))
			},
		})

		cep.then(function (v) {
			if (v.error) {
				dispatch(
					AddAlert('Consulta CEP', 'CEP não encontrado', AlertType.ERROR)
				)
			} else {
				setSelectedHealthPlace((prev) => ({
					...prev,
					address: {
						...prev.address,
						...v,
						zip: v.zip.replace(/\D/g, ''),
					},
				}))
			}
		})
	}

	return (
		<div className='HealthPlaceForm-Body HealthPlaceAddressData'>
			<FormAddress
				address={selectedHealthPlace.address}
				changeDados={handleChange}
				searchCep={handleSearchCep}
				errors={errors}
			/>
			<div className='HealthPlaceForm-end-container'>
				<p className='Title-Asterisk'>* Campos obrigatórios</p>
				<div className='HealthPlaceForm-Btn-Box'>
					<NewButton
						model={ButtonModel.SECONDARY}
						label='Fechar'
						onClick={() => setModalProps(MODAL_INIT_PROPS)}
					/>
					<NewButton
						model={ButtonModel.PRIMARY}
						label=' Avançar'
						onClick={handleNext}
					/>
				</div>
			</div>
		</div>
	)
}
