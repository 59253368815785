import React from 'react'

export default function IcoBoxBarcode({ style={} }) {
    return <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M415 4786 c-41 -18 -83 -69 -90 -109 -3 -18 -4 -442 -3 -944 3 -903
        3 -912 24 -939 11 -15 33 -37 48 -48 l27 -21 2139 0 2139 0 27 21 c15 11 37
        33 48 48 21 27 21 34 21 966 0 932 0 939 -21 966 -11 15 -33 37 -48 48 l-27
        21 -2127 2 c-1753 2 -2132 0 -2157 -11z m520 -331 l25 -24 0 -671 0 -671 -25
        -24 c-15 -16 -36 -25 -55 -25 -19 0 -40 9 -55 25 l-25 24 0 671 0 671 25 24
        c15 16 36 25 55 25 19 0 40 -9 55 -25z m320 0 l25 -24 0 -671 0 -671 -25 -24
        c-15 -16 -36 -25 -55 -25 -19 0 -40 9 -55 25 l-25 24 0 671 0 671 25 24 c15
        16 36 25 55 25 19 0 40 -9 55 -25z m320 0 l25 -24 0 -671 0 -671 -25 -24 c-15
        -16 -36 -25 -55 -25 -19 0 -40 9 -55 25 l-25 24 0 671 0 671 25 24 c15 16 36
        25 55 25 19 0 40 -9 55 -25z m480 0 l25 -24 0 -671 0 -671 -25 -24 c-15 -16
        -36 -25 -55 -25 -19 0 -40 9 -55 25 l-25 24 0 671 0 671 25 24 c15 16 36 25
        55 25 19 0 40 -9 55 -25z m320 0 l25 -24 0 -671 0 -671 -25 -24 c-15 -16 -36
        -25 -55 -25 -19 0 -40 9 -55 25 l-25 24 0 671 0 671 25 24 c15 16 36 25 55 25
        19 0 40 -9 55 -25z m480 0 l25 -24 0 -671 0 -671 -25 -24 c-15 -16 -36 -25
        -55 -25 -19 0 -40 9 -55 25 l-25 24 0 671 0 671 25 24 c15 16 36 25 55 25 19
        0 40 -9 55 -25z m480 0 l25 -24 0 -671 0 -671 -25 -24 c-15 -16 -36 -25 -55
        -25 -19 0 -40 9 -55 25 l-25 24 0 671 0 671 25 24 c15 16 36 25 55 25 19 0 40
        -9 55 -25z m320 0 l25 -24 0 -671 0 -671 -25 -24 c-15 -16 -36 -25 -55 -25
        -19 0 -40 9 -55 25 l-25 24 0 671 0 671 25 24 c15 16 36 25 55 25 19 0 40 -9
        55 -25z m320 0 l25 -24 0 -671 0 -671 -25 -24 c-15 -16 -36 -25 -55 -25 -19 0
        -40 9 -55 25 l-25 24 0 671 0 671 25 24 c15 16 36 25 55 25 19 0 40 -9 55 -25z
        m320 0 l25 -24 0 -671 0 -671 -25 -24 c-15 -16 -36 -25 -55 -25 -19 0 -40 9
        -55 25 l-25 24 0 671 0 671 25 24 c15 16 36 25 55 25 19 0 40 -9 55 -25z"/>
        <path d="M2022 2309 c-273 -139 -499 -253 -501 -255 -2 -2 231 -120 518 -263
        l521 -261 522 261 522 261 -205 105 c-564 290 -800 403 -840 403 -33 0 -134
        -47 -537 -251z"/>
        <path d="M1440 1420 c0 -526 0 -531 51 -576 13 -12 204 -115 424 -229 220
        -114 437 -228 483 -252 l82 -45 0 536 0 536 -520 260 -520 260 0 -490z"/>
        <path d="M3158 1649 l-518 -259 0 -536 0 -536 113 60 c61 33 279 146 482 252
        204 106 382 204 398 218 15 15 32 42 38 62 6 22 9 209 7 516 l-3 482 -517
        -259z"/>
        </g>
    </svg>
}
