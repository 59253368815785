import React from 'react'

export function RoutineReportsIcon({style={}}) {
    return <svg style={style} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M3215 5104 c-551 -86 -997 -521 -1100 -1073 -8 -46 -15 -85 -15 -87
            0 -2 -340 -4 -756 -4 -545 0 -768 -3 -802 -12 -59 -15 -135 -88 -151 -144 -8
            -27 -11 -579 -11 -1824 0 -1705 1 -1787 19 -1825 23 -51 89 -111 138 -124 25
            -7 233 -11 594 -11 548 0 557 0 583 21 19 15 26 30 26 54 0 24 -7 39 -26 54
            -26 21 -35 21 -581 21 l-554 0 -24 25 -25 24 0 1771 0 1771 25 24 24 25 755 0
            755 0 5 -57 c21 -217 65 -378 145 -536 l49 -96 -313 -3 c-291 -3 -314 -4 -334
            -22 -27 -25 -28 -79 -1 -106 19 -19 33 -20 387 -20 l366 0 96 -93 c162 -156
            327 -256 523 -319 l98 -31 -2 -1159 -3 -1160 -24 -19 c-22 -18 -47 -19 -578
            -19 -336 0 -561 -4 -572 -10 -10 -5 -24 -22 -30 -39 -10 -24 -10 -34 3 -59 8
            -16 23 -32 31 -36 31 -12 1125 -7 1166 5 50 16 105 61 132 112 l22 42 3 1156
            2 1155 148 0 c93 0 180 6 238 17 406 74 763 337 948 701 154 301 186 652 91
            981 -49 167 -171 393 -225 415 -49 20 -100 -16 -100 -71 0 -12 16 -45 34 -74
            198 -297 249 -681 135 -1019 -125 -374 -441 -668 -823 -766 -321 -82 -665 -22
            -948 167 -97 64 -242 209 -308 307 -286 427 -273 965 35 1374 25 33 86 98 135
            144 417 391 1045 424 1505 78 86 -65 117 -73 156 -42 15 13 22 30 22 52 0 28
            -9 42 -50 79 -106 97 -310 204 -473 250 -178 49 -388 63 -565 35z"/>
            <path d="M3270 4749 c-98 -15 -251 -71 -342 -125 -95 -57 -232 -182 -293 -268
            -117 -166 -176 -348 -176 -551 -1 -267 83 -481 266 -673 100 -106 136 -121
            180 -77 44 43 34 76 -39 146 -74 70 -153 179 -191 265 -89 200 -89 458 -1 657
            147 332 513 533 866 476 290 -46 539 -251 639 -526 164 -449 -96 -940 -564
            -1064 -111 -29 -279 -29 -392 0 -100 26 -135 26 -156 3 -22 -25 -22 -85 1
            -104 44 -39 205 -71 352 -71 198 1 365 51 526 159 288 191 452 517 431 853
            -28 434 -325 785 -747 886 -82 19 -275 27 -360 14z"/>
            <path d="M3365 4315 l-25 -24 0 -237 0 -237 -85 -137 c-47 -76 -85 -148 -85
            -162 0 -33 39 -68 76 -68 39 0 52 14 157 183 l87 138 0 263 c0 261 0 263 -23
            284 -31 29 -72 28 -102 -3z"/>
            <path d="M1017 3326 c-21 -8 -47 -22 -59 -33 -44 -40 -48 -62 -48 -275 0 -227
            5 -247 72 -288 30 -18 51 -20 239 -20 197 0 207 1 239 23 65 43 75 74 79 245
            5 211 -2 254 -46 303 -20 22 -50 43 -71 49 -56 15 -359 12 -405 -4z m371 -303
            l2 -163 -165 0 -165 0 0 165 0 165 163 -2 162 -3 3 -162z"/>
            <path d="M982 2260 c-67 -41 -72 -61 -72 -290 0 -229 5 -249 72 -290 31 -19
            47 -20 250 -18 l217 3 35 34 c49 48 59 100 55 301 -4 184 -13 213 -79 257 -32
            22 -42 23 -239 23 -188 0 -209 -2 -239 -20z m408 -290 l0 -160 -165 0 -165 0
            0 160 0 160 165 0 165 0 0 -160z"/>
            <path d="M1640 2020 c-27 -27 -26 -81 1 -106 20 -18 45 -19 503 -22 264 -2
            496 0 514 3 68 13 95 80 49 123 -23 22 -23 22 -535 22 -499 0 -513 -1 -532
            -20z"/>
            <path d="M992 1214 c-18 -9 -43 -31 -55 -48 -21 -29 -22 -43 -25 -241 -3 -206
            -3 -211 20 -244 50 -73 58 -76 274 -79 182 -3 197 -2 239 18 26 13 53 36 67
            58 22 35 23 46 23 237 0 182 -2 203 -20 234 -43 73 -53 76 -283 79 -176 2
            -212 0 -240 -14z m393 -299 l0 -160 -162 -3 -163 -2 0 165 0 165 163 -2 162
            -3 0 -160z"/>
            <path d="M1640 970 c-30 -30 -27 -83 6 -109 26 -21 36 -21 531 -21 l504 0 24
            25 c30 30 32 64 4 99 l-20 26 -515 0 c-501 0 -515 -1 -534 -20z"/>
        </g>
    </svg>
}

