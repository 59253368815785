import api from "../../../helpers/api"
import getParams from "../../../utils/getParams"


export async function postActionSchedule(payload) {
    let response = api.post(`schedule/attendance/action/open/`, payload)
    return response
}

export async function getSpecialties(params={}) {
    return api.get(`/health_place_user/specialty/${getParams(params)}`)
}

export const getHealthPlaceUserDetailed = async (params={}) => {
    params = { ...params, "active": true }
    return api.get(`health_place_user/detailed/${getParams(params)}`)
}

export const associateCollaborator = async (payload={}) => {
    return api.post(`health_place_user/associate/user/`, payload)
}

export const reactivateCollaborator = async (payload={}) => {
    return api.post(`health_place_user/reactivate/user/`, payload)
}