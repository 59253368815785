import React, { useEffect } from 'react'
import { NewCheckbox } from '../../../../../../../../../../../../../newcomponents'
import { useThousandDays } from '../../../../../ThousandDaysSection'
import RiskBadge from './RiskBadge'

export function PregnancyRiskObstetricalGynecologicalForm() {
	const {
		selectedLanguage,
		pregnancyBackground,
		setPregnancyBackground,
		savePregnancyBackground,
	} = useThousandDays()

	const {
		Threatofabortion,
		Genitourinarytractanomaly,
		Placentaprevia,
		Maternalcancer,
		Hemolyticdisease,
		Treatedsterility,
		Isoimmunization,
		Gynecologicalneoplasms,
		Congenitalmalformations,
		Delayeduterinegrowth,
		Polyhydramniosoligohydramnios,
		Abnormalcervicalcytology,
		Gestationalhypertensivedisorder,
		Gestationaldiabetes,
		Twin,
		Isthmocervicalincompetence,
		Obstetricalandgynecological,
	} = require('../../../../../../../../../../../../data/translation.json')[
		selectedLanguage
	]

	useEffect(() => {
		savePregnancyBackground()
	}, [
		pregnancyBackground.abortion_threat,
		pregnancyBackground.genitourinary_tract_anomaly,
		pregnancyBackground.placenta_previa_ogp,
		pregnancyBackground.maternal_cancer,
		pregnancyBackground.hemolytic_disease,
		pregnancyBackground.treated_sterility,
		pregnancyBackground.isoimmunization,
		pregnancyBackground.gynecological_neoplasms,
		pregnancyBackground.congenital_malformations,
		pregnancyBackground.retarded_uterine_growth,
		pregnancyBackground.polyhydramnium_oligohydramnium,
		pregnancyBackground.abnormal_cervical_cytology_nic_i_ii_iii,
		pregnancyBackground.hypertensive_disease_of_pregnancy,
		pregnancyBackground.gestional_diabetes,
		pregnancyBackground.gemellar,
		pregnancyBackground.isthmocervical_incompetence_ogp,
	])

	return (
		<section>
			<NewCheckbox
				title={<b>{Obstetricalandgynecological}</b>}
				className='PregnancyRiskCalculator-Checkbox'
				state={[
					{
						id: 'abortion_threat',
						name: Threatofabortion,
						checked: pregnancyBackground.abortion_threat,
					},
					{
						id: 'genitourinary_tract_anomaly',
						name: Genitourinarytractanomaly,
						checked: pregnancyBackground.genitourinary_tract_anomaly,
					},
					{
						id: 'placenta_previa_ogp',
						name: Placentaprevia,
						checked: pregnancyBackground.placenta_previa_ogp,
					},
					{
						id: 'maternal_cancer',
						name: Maternalcancer,
						checked: pregnancyBackground.maternal_cancer,
					},
					{
						id: 'hemolytic_disease',
						name: Hemolyticdisease,
						checked: pregnancyBackground.hemolytic_disease,
					},
					{
						id: 'treated_sterility',
						name: Treatedsterility,
						checked: pregnancyBackground.treated_sterility,
					},
					{
						id: 'isoimmunization',
						name: Isoimmunization,
						checked: pregnancyBackground.isoimmunization,
					},
					{
						id: 'gynecological_neoplasms',
						name: Gynecologicalneoplasms,
						checked: pregnancyBackground.gynecological_neoplasms,
					},
					{
						id: 'congenital_malformations',
						name: Congenitalmalformations,
						checked: pregnancyBackground.congenital_malformations,
					},
					{
						id: 'retarded_uterine_growth',
						name: Delayeduterinegrowth,
						checked: pregnancyBackground.retarded_uterine_growth,
					},
					{
						id: 'polyhydramnium_oligohydramnium',
						name: Polyhydramniosoligohydramnios,
						checked: pregnancyBackground.polyhydramnium_oligohydramnium,
					},
					{
						id: 'abnormal_cervical_cytology_nic_i_ii_iii',
						name: Abnormalcervicalcytology,
						checked:
							pregnancyBackground.abnormal_cervical_cytology_nic_i_ii_iii,
					},
					{
						id: 'hypertensive_disease_of_pregnancy',
						name: Gestationalhypertensivedisorder,
						checked: pregnancyBackground.hypertensive_disease_of_pregnancy,
					},
					{
						id: 'gestional_diabetes',
						name: Gestationaldiabetes,
						checked: pregnancyBackground.gestional_diabetes,
					},
					{
						id: 'gemellar',
						name: Twin,
						checked: pregnancyBackground.gemellar,
					},
					{
						id: 'isthmocervical_incompetence_ogp',
						name: Isthmocervicalincompetence,
						checked: pregnancyBackground.isthmocervical_incompetence_ogp,
					},
				]}
				defaultHandleCheckbox={false}
				setState={(event) =>
					setPregnancyBackground((prev) => ({
						...prev,
						[event.target.name]: event.target.checked,
					}))
				}
			/>
			<RiskBadge />
		</section>
	)
}
