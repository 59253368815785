import './AssessmentsManager.css'
import React from 'react'
import { useParams } from 'react-router'
import Button, { ButtonModel } from '../../../../../newcomponents/button/button'
import NewEvaluation from './subcomponents/NewEvaluation/NewEvaluation'
import { useApp } from '../../../../../layout/App'
import { List, MaskDataHora } from '../../../../../component'
import { history } from '../../../../../helpers'

const { headDefault } = require('./AssessmentsManager.json')
const defaultOptions = require('../../../../../component/form/select/options.json')

export default function AssessmentsManager({ assessments=[], associatedStudents=[], setModalInfo=() => null }) {
    const { id } = useParams()
    const { isAdminOrAssistant } = useApp()

    const handleOpenModalNewEvaluation = () => {
        setModalInfo({
            open: true,
            content: <NewEvaluation associatedStudents={associatedStudents} setModalInfo={setModalInfo} />,
            title: 'Nova avaliação'
        })
    }

    return <div>
        <div className='AssessmentsManager-Action'>
            <Button 
                label='Nova correção'
                onClick={handleOpenModalNewEvaluation}
            />
        </div>
        <div>
            <List
                head={
                    isAdminOrAssistant
                        ? headDefault
                        : [...headDefault, { colunm: 'acoes', text: '', width: '180px' }]
                }
                data={assessments}
                noData='Nenhum atendimento encontrado'
                listCustom={(assessmentCursor, i) => {
                    const assessmentAttendanceData = MaskDataHora(assessmentCursor?.attendance?.start)
                    assessmentCursor['h_student'] = assessmentCursor?.attendance?.doctor?.name
                    assessmentCursor['h_patient'] = assessmentCursor?.attendance?.person?.name || '-'
                    assessmentCursor['h_date'] =
                    assessmentAttendanceData?.data && assessmentAttendanceData?.hora
                            ? `${assessmentAttendanceData?.data} ${assessmentAttendanceData?.hora}`
                            : '-'
                    assessmentCursor['h_state'] = defaultOptions?.DECISION_CHOICES?.find(ob => ob?.id === assessmentCursor?.attendance?.decision)?.name || '-'
                    assessmentCursor['acoes'] = <div>
                        <Button 
                            model={ButtonModel.SECONDARY}
                            label='Avaliar'
                            onClick={() => history.push(`/preceptoria/consultation/${id}/${assessmentCursor?.attendance?.id}`)}
                        />
                    </div>
                    return assessmentCursor
                }}
            />
        </div>
    </div>
}