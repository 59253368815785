import './HealthPlaceInventoryConfigModal.css'
import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import NewSelect from "../../../../../../../../newcomponents/form/select/select";
import NewButton from '../../../../../../../../newcomponents/button/button';
import { useApp } from '../../../../../../../../layout/App';
import { patchInventoryConfig, postInventoryConfig } from '../../../http';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { AddAlert, AlertType } from '../../../../../../../../component';

const { PROFILE_CHOICES } = require('../../../../../../../../component/form/select/options.json')

export default function HealthPlaceInventoryConfigModal({ initAccessConfiguration={}, extraAction=async () => null }) {
    const dispatch = useDispatch()
    const { currentHealthPlaceUser } = useApp()
    const [holdingAccessConfig, setHoldingAccessConfig] = useState(initAccessConfiguration)

    const getStatus = (profile) => {
        if (holdingAccessConfig?.can_read_and_write?.includes(profile)) {
            return 'EDITING_ENABLED'
        } else if (holdingAccessConfig?.can_read?.includes(profile)) {
            return 'ONLY_READING'
        } else {
            return 'NO_ACCESS'
        }
    }

    const handleSelect = (event) => {
        const { id, selected } = event.target;

        const newAccessConfig = { ...holdingAccessConfig };
        
        if (selected === 'EDITING_ENABLED') {
            if (!newAccessConfig['can_read_and_write'].includes(id)) {
                newAccessConfig['can_read_and_write'].push(id);
            }
            newAccessConfig['can_read'] = newAccessConfig['can_read'].filter(profileId => profileId !== id);
        } else if (selected === 'ONLY_READING') {
            if (!newAccessConfig['can_read'].includes(id)) {
                newAccessConfig['can_read'].push(id);
            }
            newAccessConfig['can_read_and_write'] = newAccessConfig['can_read_and_write'].filter(profileId => profileId !== id);
        } else if (selected === 'NO_ACCESS') {
            newAccessConfig['can_read'] = newAccessConfig['can_read'].filter(profileId => profileId !== id);
            newAccessConfig['can_read_and_write'] = newAccessConfig['can_read_and_write'].filter(profileId => profileId !== id);
        }

        setHoldingAccessConfig(newAccessConfig);
    }
    
    const handleSubmit = async () => {

        dispatch(loading())
        try {
            const payload = {
                "can_read": holdingAccessConfig?.['can_read'] || [],
                "can_read_and_write": holdingAccessConfig?.['can_read_and_write'] || [],
                "health_place": currentHealthPlaceUser?.health_place?.id,
            }
            if (!holdingAccessConfig?.health_place) {
                await postInventoryConfig(payload)
            } else {
                await patchInventoryConfig(holdingAccessConfig?.health_place, payload)
            }
            dispatch(AddAlert('Configuração de Estoque', 'Permissões de Acesso salvas com sucesso', AlertType.SUCCESS))
            await extraAction()
        } catch (err) {
            console.error('HealthPlaceInventoryConfigModal ~ handleSubmit ~ ', err)
        }
        dispatch(loaded())
    }

    return <div className='HealthPlaceInventoryConfigModal'>
        <div className='HealthPlaceInventoryConfigModal-Header'>
            <div className='HealthPlaceInventoryConfigModal-Header-Icon'>P</div>
            <div className='HealthPlaceInventoryConfigModal-Header-Title'>Permissões de Acesso</div>
        </div>
        <div className='HealthPlaceInventoryConfigModal-Content'>
        {
            PROFILE_CHOICES.map(({ id, name }) => (
                <div className='HealthPlaceInventoryConfigModal-Select'>
                    <span>{name}</span>
                    <NewSelect
                        id={id}
                        onSelect={handleSelect}
                        options={[
                            { id: 'EDITING_ENABLED', name: 'Edição Habilitada' },
                            { id: 'ONLY_READING', name: 'Somente Leitura' },
                            { id: 'NO_ACCESS', name: 'Sem Acesso' },
                        ]}
                        selected={getStatus(id)}
                        canBeEmpty={false}
                    />
                </div>
            ))  
        }
        </div>
        <div className='HealthPlaceInventoryConfigModal-BtnBox'>
            <NewButton 
                label='Salvar'
                onClick={handleSubmit}
            />
        </div>
    </div>
}