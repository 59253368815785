function isInvalidPassword(password, confirmPassword) { 
    const specialCharRegex = /^(?=.*[.@!#$%^&*()/\\])[.@!#$%^&*()/\\a-zA-Z0-9]{1,50}$/
    const upperRegex = /^(?=.*[A-Z])[.@!#$%^&*()/\\a-zA-Z0-9]{1,50}$/
    const numberRegex = /^(?=.*[0-9])[.@!#$%^&*()/\\a-zA-Z0-9]{1,50}$/;

    if (password.length < 8) return 'A senha deve conter ao menos 8 caracteres'
    if (password.length > 50) return 'A senha deve conter ao máximo 50 caracteres'
    if (!specialCharRegex.test(password)) return 'A senha deve conter ao menos um caracter especial'
    if (!upperRegex.test(password)) return 'A senha deve conter ao menos uma letra maiúscula'
    if (!numberRegex.test(password)) return 'A senha deve conter ao menos um número'
    if (password !== confirmPassword) return 'As senhas devem ser iguais'
    return ''
}



export default isInvalidPassword