import './ConfirmCancelTicketModal.css'
import React from 'react'
import NewButton, { ButtonModel } from '../../../../../../newcomponents/button/button'
import { useDispatch } from 'react-redux'
import { loaded, loading } from '../../../../../../layout/redux/AppActions'
import { patchCallPanelServiceTicket, postCancelPanelServiceTicket } from '../../../../http'
import { AddAlert, AlertType } from '../../../../../../component'

export default function ConfirmCancelTicketModal({ ticket={}, extraAction=async () => null }) {
    const dispatch = useDispatch()

    const handleConfirmCancel = async () => {
        dispatch(loading())
		try {
			await postCancelPanelServiceTicket(ticket?.id)
			await extraAction({ offset: 0 })
			
			dispatch(AddAlert('Chamado cancelado', 'Chamado cancelado com sucesso', AlertType.SUCCESS))
            extraAction()
		} catch (err) {
			dispatch(AddAlert('Chamado cancelado', 'Falha ao cancelar chamado', AlertType.ERROR))
			console.error('ConfirmCancelTicketModal ~ handleConfirmCancel ~ patchCallPanelServiceTicket ~ ', err)
		}
        dispatch(loaded())
	}
    return <div className='ConfirmCancelTicketModal'>
        <p>Deseja cancelar o chamado do paciente?</p>
        <div className='ConfirmCancelTicketModal-BtnBox'>
            <NewButton
                label='Cancelar'
                onClick={handleConfirmCancel}
                model={ButtonModel.DANGER}
            />
        </div>
    </div>
}