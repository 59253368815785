import './ListRequestMaterials.css'
import React, { useEffect, useState } from "react";
import { getInventoryMaterialsAssignment, getMaterialFlowEntry, patchInventoryMaterialAssignment, patchMaterialFlowEntry, postMaterialFlowEntry } from "../../http";
import { AddAlert, AlertType, List, MaskDataHora, Paginate } from "../../../../../../component";
import CancelIcon from "../../../../../../component/icon/cancel";
import { useDispatch } from 'react-redux';
import FilterRequestParams from './FilterRequestParams/FilterRequestParams';
import { useInventoryMaterials } from '../../InventoryMaterials';

const INIT_FILTER_PARAMS = { material__name__icontains: '', material__code__icontains: '', material__active: true, patient: null, health_place_user: null, offset: 0 }

export default function ListRequestMaterials() {
    const limit = 20
    const dispatch = useDispatch()
    const [assignments, setAssignments] = useState([])
    const [filterParams, setFilterParams] = useState(INIT_FILTER_PARAMS)
    const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: limit, totalElements: 0 })
    const { canReadAndWrite } = useInventoryMaterials()

    useEffect(() => {
        fetchAssignments({ ...filterParams, "offset": 0 })
    }, [])

    const fetchAssignments = async (params={}) => {
        let materialAssignmentsResponse = await getInventoryMaterialsAssignment({ ...params, limit })

        let materialEntriesResponse = await getMaterialFlowEntry({...params, limit})

        const assignmentsWithType = materialAssignmentsResponse.data.results.map(assignment => ({
            ...assignment,
            flowType: 'Saída',
        }));
    
        const entriesWithType = materialEntriesResponse.data.results.map(entry => ({
            ...entry,
            flowType: 'Entrada',
        }));

        const combinedAssignments = [...assignmentsWithType, ...entriesWithType];

        setAssignments(combinedAssignments)
        setPagination({
            totalElements: materialAssignmentsResponse.data.count + materialEntriesResponse.data.count,
            totalPages: Math.ceil((materialAssignmentsResponse.data.count + materialEntriesResponse.data.count) / limit),
            pageNumber: params.offset / limit || 0,
            next: materialAssignmentsResponse.data.next || materialEntriesResponse.data.next,
            previous: materialAssignmentsResponse.data.previous || materialEntriesResponse.data.previous
        })
    }

    const handleCancel = async (assignment) => {
        try {
            if (assignment.flowType === 'Entrada') {
                await patchMaterialFlowEntry(assignment?.id, { "active": false })
            } else {
                await patchInventoryMaterialAssignment(assignment?.id, { "active": false })
            }
            await fetchAssignments()
            dispatch(AddAlert('Requisição de Material', 'Requisição cancelada com sucesso!', AlertType.SUCCESS))            
        } catch (err) {
            dispatch(AddAlert('Requisição de Material', 'Falha ao cancelar requisição.', AlertType.ERROR))            
            console.error('ListRequestMaterials ~ patchInventoryMaterialAssignment ~ ', err)
        }
    }
    
    const handleNextPage = offset => {
		fetchAssignments({ ...filterParams, "offset": offset * limit })
	}

    return <div className="ListRequestMaterials">
        <FilterRequestParams
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            fetchAssignments={fetchAssignments}
            INIT_FILTER_PARAMS={INIT_FILTER_PARAMS} 
        />
        <div>
            <List 
                head={
                    canReadAndWrite
                    ? [
                        { colunm: 'h_material', text: 'Material' },
                        { colunm: 'h_code', text: 'Código' },
                        { colunm: 'h_time', text: 'Data & Horário' },
                        { colunm: 'h_patient', text: 'Paciente' },
                        { colunm: 'h_health_place_user', text: 'Requisitante' },
                        { colunm: 'h_requested_amount', text: 'Quantidade' },
                        { colunm: 'h_type', text: 'Tipo' },
                        { colunm: 'h_active', text: 'Status de Requisição' },
                        { colunm: 'h_action', text: <div className='ListRequestMaterials-HeaderRow-Action'>Ações</div>, width: '72px' }
                    ]
                    : [
                        { colunm: 'h_material', text: 'Material' },
                        { colunm: 'h_code', text: 'Código' },
                        { colunm: 'h_time', text: 'Data & Horário' },
                        { colunm: 'h_patient', text: 'Paciente' },
                        { colunm: 'h_health_place_user', text: 'Requisitante' },
                        { colunm: 'h_requested_amount', text: 'Quantidade' },
                        { colunm: 'h_type', text: 'Tipo' },
                        { colunm: 'h_active', text: 'Status de Requisição' },
                    ]}
                data={assignments}
                listCustom={custom => {
                    let cursor = custom
                    const time = MaskDataHora(cursor?.time, 'DD/MMM/AAAA', '-')
                    cursor['h_material'] = cursor?.material?.name || '-'
                    cursor['h_code'] = cursor?.material?.code || '-'
                    cursor['h_patient'] = cursor?.patient?.name || '-'
                    cursor['h_health_place_user'] = cursor?.health_place_user?.user?.person?.name || cursor?.responsible?.name || '-'
                    cursor['h_time'] = time?.data && time?.hora ? `${time.data} ${time.hora}` : '-'
                    cursor['h_requested_amount'] = cursor?.requested_amount || cursor?.batch_quantity || '-'
                    cursor['h_type'] = cursor?.flowType || '-'
                    cursor['h_active'] = cursor?.active
                       ? <div className='ListRequestMaterials-Status-Subtitle ListRequestMaterials-Success'>Sucesso</div>
                        : <div className='ListRequestMaterials-Status-Subtitle ListRequestMaterials-Error'>Cancelada</div>
                    custom['h_action'] = (
                        <div className='ListRequestMaterials-ActionRow'>
                            {
                                cursor?.active
                                ? <div
                                    title='Cancelar requisição'
                                    onClick={() => handleCancel(cursor)}                            
                                >
                                    <CancelIcon />
                                </div>
                                : null
                            }
                        </div>
                    )
                    return custom
                }}
            />
            <div>
                <Paginate data={pagination} action={handleNextPage} />
            </div>
        </div>
    </div>
}