import React from 'react'

export function HospitalIcon({style={}}) {
    return <svg style={style} viewBox="0 0 512.000000 512.000000">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M2175 4566 c-143 -34 -255 -95 -355 -196 -217 -217 -271 -541 -136
            -815 95 -191 239 -313 446 -376 60 -19 98 -23 190 -23 137 -1 208 16 330 76
            69 34 102 58 175 132 149 149 209 293 210 496 0 133 -13 194 -65 305 -88 190
            -226 313 -425 381 -99 33 -273 42 -370 20z m273 -423 l3 -143 134 0 c170 0
            165 4 165 -134 0 -73 -4 -107 -13 -114 -8 -7 -69 -13 -148 -14 l-134 -3 -3
            -135 c-2 -112 -5 -137 -19 -147 -11 -8 -52 -13 -114 -13 -132 0 -129 -4 -129
            166 l0 134 -139 0 c-117 0 -140 2 -145 16 -3 9 -6 62 -6 119 0 93 2 105 19
            115 11 5 76 10 145 10 l126 0 0 126 c0 69 5 134 11 145 9 18 19 19 127 17
            l117 -3 3 -142z"/>
            <path d="M930 3870 l0 -140 264 0 264 0 0 140 0 140 -264 0 -264 0 0 -140z"/>
            <path d="M3185 3870 l0 -140 268 0 267 0 0 140 0 140 -267 0 -268 0 0 -140z"/>
            <path d="M1100 2055 l0 -1515 340 0 340 0 0 400 0 400 545 0 545 0 0 -400 0
            -400 335 0 335 0 0 95 0 95 -87 107 c-269 332 -530 732 -653 997 -55 118 -106
            270 -125 367 -39 208 6 475 120 702 139 278 402 510 693 613 36 13 52 24 52
            36 0 17 -15 18 -199 18 l-199 0 -17 -42 c-48 -122 -176 -282 -290 -363 -149
            -106 -289 -154 -471 -162 -185 -8 -330 27 -488 120 -138 82 -289 252 -348 394
            l-22 53 -203 0 -203 0 0 -1515z m720 600 l0 -215 -215 0 -215 0 0 215 0 215
            215 0 215 0 0 -215z m720 0 l0 -215 -215 0 -215 0 0 215 0 215 215 0 215 0 0
            -215z m-720 -650 l0 -215 -215 0 -215 0 0 215 0 215 215 0 215 0 0 -215z m720
            0 l0 -215 -215 0 -215 0 0 215 0 215 215 0 215 0 0 -215z"/>
            <path d="M3802 3429 c-261 -25 -474 -129 -667 -323 -121 -122 -191 -227 -246
            -370 -71 -184 -90 -405 -49 -566 78 -305 342 -746 757 -1263 123 -154 306
            -367 313 -366 19 2 399 471 540 665 261 359 421 640 507 889 38 110 38 110 37
            270 0 132 -4 174 -22 238 -85 308 -277 555 -543 701 -181 99 -415 146 -627
            125z m223 -524 c217 -46 394 -218 446 -435 31 -128 5 -298 -62 -417 -191 -336
            -670 -385 -929 -95 -88 99 -150 255 -150 382 0 72 27 178 65 258 48 101 151
            205 255 256 128 62 245 78 375 51z"/>
            <path d="M120 1870 l0 -1330 415 0 415 0 0 1330 0 1330 -415 0 -415 0 0 -1330z
            m628 723 l2 -213 -215 0 -215 0 0 215 0 215 213 -2 212 -3 3 -212z m0 -720 l2
            -213 -215 0 -215 0 0 215 0 215 213 -2 212 -3 3 -212z m2 -723 l0 -210 -215 0
            -215 0 0 210 0 210 215 0 215 0 0 -210z"/>
            <path d="M1940 865 l0 -325 165 0 165 0 -2 323 -3 322 -162 3 -163 2 0 -325z"/>
            <path d="M2380 865 l0 -325 165 0 165 0 0 325 0 325 -165 0 -165 0 0 -325z"/>
        </g>
    </svg>
}
