import './MaterialFlowModal.css'
import { useEffect, useRef, useState } from "react"
import { AddAlert, AlertType, Input } from "../../../../../../../../component"
import NewSelect from "../../../../../../../../newcomponents/form/select/select"
import { getYearsRangeTodayToPlus50 } from '../../../../../../../../utils/getYearsRangeTodayToPlus50';
import { getInventoryMaterialLocations, postInventoryMaterialLocation } from '../../../ConfigInventoryMaterials/subcomponents/LocationsOptions/http';
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button';
import { getCollaborators } from '../../../../../../../collaborators/http';
import { useApp } from '../../../../../../../../layout/App';
import { stringifyCollaboratorOption } from '../../../../../../../../utils/stringifyCollaboratorOption';
import { getHealthPlacePatients } from '../../../../../../../pacientes/http';
import { MaterialFlowEntryForm, MaterialFlowExitForm } from '../InsertInventoryMaterialsModal/helpers';
import { useDispatch } from 'react-redux';
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { postInventoryMaterialAssignment, postMaterialFlowEntry } from '../../../../http';

const defaultImage = require('../../../../../../../../media/defaultImage.png')
const defaultOptions = require('../../../../../../../../component/form/select/options.json')

const itemSourceEntryOptions = [
    {'id': 'purchase', 'name':'Compra'},
    {'id': 'free_sample', 'name':'Amostra Grátis'},
    {'id': 'bonus', 'name':'Bonificação'},
    {'id': 'material_utiliation', 'name':'Aproveitamento de material'},
    {'id': 'external_loan', 'name':'Emprestimo externo'} 
]

const itemSourceExitOptions = [
    {'id': 'allocation_to_patient', 'name': 'Alocação para paciente'},
    {'id': 'sale', 'name':'Venda'},
    {'id': 'return', 'name':'Devolução'},
    {'id': 'transfer', 'name':'Transferência'},
    {'id': 'expiry', 'name':'Vencimento'},
    {'id': 'drug_stability', 'name':'Estabilidade da droga'},
    {'id': 'waste_breakage', 'name':'Quebra ou disperdício'},
    {'id': 'External_loan', 'name':'Empréstimo externo'},
]

const strpMaterialItem = (instance=null) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    let [year, month, day] = regex.test(instance?.expiration_date)
        ? instance.expiration_date.split('-').map(value => Number(value))
        : ['', '', '']

    return {
        batch_quantity: instance?.batch_quantity || 0,
        day: day ? `${day < 10 ? '0': ''}${day}` : '',
		month: month ? `${month < 10 ? '0': ''}${month}` : '',
		year: year ? `${year}` : '',
        location: instance?.location?.id || '',
        invoice: instance?.invoice || '',
        responsible: instance?.responsible?.id || '',
        source: instance?.source || '',
        note: instance?.note || '',
        requested_amount: instance?.requested_amount || '',
        patient: instance?.patient || '',
        type: instance?.product_type || '',
        justification: instance?.justification || '',
    }
}

export default function MaterialFlowModal({ initMaterialData, flowType, setModalInfo, fetchMaterials, maxExitItems=null }) {
    const { currentHealthPlaceUser } = useApp()
    const dispatch = useDispatch()
    const [materialItem, setMaterialItem] = useState({})
    const [materialItemErr, setMaterialItemErr] = useState({})
    const searchTimeout = useRef(null)
    
    const [activeLocations, setActiveLocations] = useState([])
    const [collaboratorsOptions, setCollaboratosOptions] = useState([])
    const [patientsOptions, setPatientsOptions] = useState([])
    const [locationInputValue, setLocationInputValue] = useState('')


    useEffect(() => {
        fetchActiveLocationsOptions()

        if (flowType === 'entry') {
            fetchCollaboratorsOptions({ offset: 0 })
        } else {
            fetchPatientsList()
        }
    }, [])

    const fetchActiveLocationsOptions = async (params={}) => {
        try {
            let res = await getInventoryMaterialLocations({ active: true, ...params })
            setActiveLocations(res.data.results)
        } catch (err) {
            setActiveLocations([])
            console.error('fetchActiveLocationsOptions ~ ', err)
        }
    }

    const fetchCollaboratorsOptions = async params => {
		await getCollaborators({
			...params,
			health_place__id: currentHealthPlaceUser?.health_place?.id,
            is_active: true,
            has_person: true,
			limit: 500,
		})
			.then(res => {
                setCollaboratosOptions(res.data.results.map(instance => stringifyCollaboratorOption(instance)))
            })
			.catch(err => {
				console.error('fetchCollaboratorsOptions', err)
				setCollaboratosOptions([])
			})
	}

    const fetchPatientsList = async params => {
        await getHealthPlacePatients({
            ...params
        })
        .then(res => {
            setPatientsOptions(res.data.results.map(patient => {
                return {'id': patient.id ,name: `${patient.name} | ${patient.document_type === 'CPF' ? patient.cpf || 'Documento não cadastrado' : patient.rg || 'Documento não cadastrado'}`}
            }))
        })
        .catch(err => {
            console.error('fetchPatientOptions', err)
            setPatientsOptions([])
        })
    }

    const handleSearchLocation = (event) => {
        setLocationInputValue(event.target.value)
        if (searchTimeout.current) clearTimeout(searchTimeout.current)
    
        searchTimeout.current = setTimeout(() => {
            fetchActiveLocationsOptions({ 'name__icontains': event.target.value })
        }, 400, event.target.value)
    }

    const handleSearchCollaboratorsOptions = event => {
		if (searchTimeout.current)
			clearTimeout(searchTimeout.current)

		searchTimeout.current = setTimeout(() => {
			fetchCollaboratorsOptions({ name_cpf_or_email: event.target.value })
		}, 400, event.target.value)
	}

    const handleSearchPatientOptions = event => {
        if (searchTimeout.current)
			clearTimeout(searchTimeout.current)

		searchTimeout.current = setTimeout(() => {
			fetchPatientsList({ name_or_cpf: event })
		}, 400, event)
    }

    const handleChange = (event) => {
        let value = event.target.value
        if ((event.target.name === 'batch_quantity' && value > 10000000)) {
            value = 10000000
        } else if (event.target.name === 'requested_amount' && value > maxExitItems) {
            value = maxExitItems
        } else if ((event.target.name === 'batch_quantity' || event.target.name === 'requested_amount') && value < 0) {
            value = 0
        } else if (event.target.name === 'batch_quantity' || event.target.name === 'requested_amount') {
            value = Number(value)
        } 
        setMaterialItem(prev => ({ ...prev, [event.target.name]: value }))
        setMaterialItemErr(prev => ({ ...prev, [event.target.name]: false }))
    }

    const handleSelect = (event) => {
        setMaterialItem(prev => ({ ...prev, [event.target.id]: event.target.selected }))

        let field = event.target.id
        if (field === 'day' || field === 'month' || field === 'year') {
            setMaterialItemErr(prev => ({ ...prev, 'day': false, 'month': false, 'year': false }))
        } else {
            setMaterialItemErr(prev => ({ ...prev, [event.target.id]: false }))
        }
    }

    const handleClearFields = () => {
        setMaterialItem(strpMaterialItem())
        setMaterialItemErr(strpMaterialItem())
    }

    const handleSubmit = async () => {
        let payload;
        if (flowType === 'entry') {
            payload = new MaterialFlowEntryForm({ ...materialItem, health_place: currentHealthPlaceUser?.health_place?.id, material: initMaterialData?.id });
        } else {
            payload = new MaterialFlowExitForm({ ...materialItem, health_place: currentHealthPlaceUser?.health_place?.id, material: initMaterialData?.id, health_place_user: currentHealthPlaceUser.id });
        }

        if (!payload.isValid()) {
            const errors = payload.getErrors();
            if (Object.keys(errors).length) return setMaterialItemErr(errors);
        }

        dispatch(loading());

        try {
            if (flowType === 'entry') {
                console.log(payload)
                await postMaterialFlowEntry(payload);
            } else {
                await postInventoryMaterialAssignment(payload)
            }

            fetchMaterials({ offset: 0 });
            dispatch(AddAlert('Fluxo de Materiais', 'Entrada de material salva com sucesso!', AlertType.SUCCESS));
            setModalInfo(MODAL_INIT_PROPS);
        } catch (err) {
            console.error('handleSubmit ~ ', err);
        }

        dispatch(loaded()); 
    }

    const handleAddNewLocation = async (value) => {
        if (!value) {
            dispatch(AddAlert('Estoque de Materiais', 'Preencha o campo para adicionar Categoria.', AlertType.ERROR))
        }
        const payload = {name: value ,health_place: currentHealthPlaceUser?.health_place?.id }

        try {
            await postInventoryMaterialLocation(payload)
            fetchActiveLocationsOptions({name: value})
            setLocationInputValue('')
            dispatch(AddAlert('Estoque de Materiais', 'Localização salva com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            console.log('handleAddNewLocation ~ ', err)
            dispatch(AddAlert('Estoque de Materiais', 'Erro em salvar Localização. Tente novamente na aba de Configurações.', AlertType.ERROR))
        }
    }

    return (
        <section className="MaterialFlowModal">
            <div className='MaterialFlowModal-Form-Body'>
                <div className='MaterialFlowModal-Form-Inputs'>
                    <div className='MaterialFlowModal-Input MaterialFlowModal-Input-2Col'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Nome</span>
                        <Input
                            name='name'
                            disabled={true}
                            value={initMaterialData?.name}
                            maxLength='100'
                        />
                    </div>
                    <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Código</span>
                        <Input
                            name='code'
                            value={initMaterialData?.code || ''}
                            maxLength='50'
                            disabled={true}

                        />
                    </div>
                    <div className='MaterialFlowModal-Select MaterialFlowModal-Input-W'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Categoria</span>
                        <Input
                            id='category'
                            value={initMaterialData?.category?.name || 'Não informado'}
                            disabled={true}

                        />
                    </div>
                    <div className='MaterialFlowModal-Select MaterialFlowModal-Input-W'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Fabricante</span>
                        <Input 
                            id='manufacturer'
                            value={initMaterialData?.manufacturer.name || 'Não informado'}
                            disabled={true}

                        />
                    </div>
                    <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Apresentação</span>
                        <Input
                            name='presentation'
                            value={initMaterialData?.presentation || ''}
                            placeholder='Ex.: Garrafa, pacote, etc.'
                            disabled={true}

                        />
                    </div>
                    <div className='MaterialFlowModal-Select MaterialFlowModal-Input-W'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Estoque mínimo para aviso</span>
                        <Input
                            name='minimum_stock'
                            value={initMaterialData?.minimum_stock || ''}
                            maxLength='4'
                            type='number'
                            min='0'
                            max='10000000'
                            placeholder='Ex.: 10, 25, 50'
                            disabled={true}

                        />
                    </div>
                    <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Aviso de vencimento (Dias)</span>
                        <Input
                            name='expiration_notice'
                            value={initMaterialData?.expiration_notice|| ''}
                            maxLength='4'
                            type='number'
                            min='0'
                            max='1095'
                            placeholder='Ex.: 10, 25, 50'
                            disabled={true}

                        />
                    </div>
                </div>
                <div className='MaterialFlowModal-Form-ImgBox'>
                    <div className='PersonalDataProfileImage-Container-Avatar'>
                        <div className='img'>
                            <img
                                className='PersonalDataProfileImage-Container-Avatar-Img'
                                alt='Foto de perfil'
                                src={initMaterialData?.picture_image_url || defaultImage}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='MaterialFlow-Division' />
            {
                flowType === 'entry'
                ? <div className='MaterialFlow-EntryForm'>
                    <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Quantidade</span>
                        <Input
                            name='batch_quantity'
                            action={handleChange}
                            value={materialItem.batch_quantity}
                            errors={{ error: materialItemErr, message: materialItemErr.batch_quantity }}
                            type='number'
                            min='0'
                            max='10000000'
                            placeholder='Ex.: 10, 25, 50'
                        />
                    </div>
                    <div>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Validade</span>
                        <div className='MaterialFlowModal-Form-Body-ExpirationDate MaterialFlowModal-Select'>
                            <NewSelect
                                id='day'
                                defaultText='Dia'
                                options={defaultOptions.days}
                                onSelect={handleSelect}
                                selected={materialItem?.day}
                                error={materialItemErr?.day}
                            />
                            <NewSelect
                                id='month'
                                defaultText='Mês'
                                options={defaultOptions.months}
                                onSelect={handleSelect}
                                selected={materialItem?.month}
                                error={materialItemErr?.month}
                            />
                            <NewSelect 
                                id='year'
                                defaultText='Ano'
                                options={getYearsRangeTodayToPlus50()}
                                onSelect={handleSelect}
                                selected={materialItem?.year}
                                error={materialItemErr?.year}
                            />
                        </div>
                    </div>
                    <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                        <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Localização</span>
                        <NewSelect 
                            id='location'
                            onSelect={handleSelect}
                            selected={materialItem.location}
                            options={activeLocations}
                            optionStrKey='name'
                            error={materialItemErr.location}
                            addNewCategoryPermission={true}
                            handleAddNewCategory={() => handleAddNewLocation(locationInputValue)}
                            filterNode={<div className='InsertInventoryMaterialsModal-NewSelect'>
                                <Input
                                    placeholder='Pesquisar por localização'
                                    action={handleSearchLocation}
                                    actionFocus={()=> fetchActiveLocationsOptions()}
                                    value={locationInputValue}
                                />
                            </div>}
                        />
                    </div>
                    <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> NF associada</span>
                        <Input
                            name='invoice'
                            action={handleChange}
                            value={materialItem.invoice}
                            errors={{ error: materialItemErr, message: materialItemErr.invoice }}
                        />
                    </div>
                    <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Responsável</span>
                        <NewSelect 
                            id='responsible'
                            options={collaboratorsOptions}
                            optionStrKey='str'
                            selected={materialItem.responsible}
                            onSelect={handleSelect}
                            error={materialItemErr.responsible}
                            filterNode={<div className='ManageTeamEdgesModal-NewSelect'>
                            <Input 
                                placeholder='Pesquisar por email'
                                action={(value) => handleSearchCollaboratorsOptions(value)}
                                actionFocus={()=> fetchCollaboratorsOptions()}
                            />
                            </div>}
                        />
                    </div>
                    <div className='MaterialFlowModal-Select MaterialFlowModal-Input-W'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Origem</span>
                        <NewSelect 
                            id='source'
                            defaultText='Selecione'
                            options={itemSourceEntryOptions}
                            onSelect={handleSelect}
                            selected={materialItem?.source}
                            error={materialItemErr?.source}
                        />
                    </div>
                    <div className='MaterialFlowModal-Input MaterialFlowModal-Input-FullRow'>
                        <span>Observações</span>
                        <Input
                            name='note'
                            action={handleChange}
                            value={materialItem.note}
                            errors={{ error: materialItemErr, message: materialItemErr.invoice }}
                        />
                    </div>
                    <div className='MaterialFlow-EntryForm-BtnBox'>
                        <NewButton 
                            label='Limpar campos'
                            onClick={handleClearFields}
                            model={ButtonModel.SECONDARY}
                        />
                        <NewButton 
                            label='Cadastrar entrada'
                            onClick={handleSubmit}
                            model={ButtonModel.PRIMARY}
                        />                    
                    </div>
                </div>
                : <div className='MaterialFlow-ExitForm'>
                    <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                        <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Origem</span>
                        <NewSelect 
                            id='source'
                            onSelect={handleSelect}
                            selected={materialItem.source}
                            options={itemSourceExitOptions}
                            optionStrKey='name'
                            error={materialItemErr.source}
                        />
                    </div>
                    <div className='MaterialFlowModal-Input'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Tipo</span>
                        <Input
                            name='type'
                            action={handleChange}
                            value={materialItem.type}
                            errors={{ error: materialItemErr, message: materialItemErr.invoice }}
                        />
                    </div>
                    <div>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Validade</span>
                        <div className='MaterialFlowModal-Form-Body-ExpirationDate MaterialFlowModal-Select'>
                            <NewSelect
                                id='day'
                                defaultText='Dia'
                                options={defaultOptions.days}
                                onSelect={handleSelect}
                                selected={materialItem?.day}
                                error={materialItemErr?.day}
                            />
                            <NewSelect
                                id='month'
                                defaultText='Mês'
                                options={defaultOptions.months}
                                onSelect={handleSelect}
                                selected={materialItem?.month}
                                error={materialItemErr?.month}
                            />
                            <NewSelect 
                                id='year'
                                defaultText='Ano'
                                options={getYearsRangeTodayToPlus50()}
                                onSelect={handleSelect}
                                selected={materialItem?.year}
                                error={materialItemErr?.year}
                            />
                        </div>
                    </div>
                    <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> NF associada</span>
                        <Input
                            name='invoice'
                            action={handleChange}
                            value={materialItem.invoice}
                            errors={{ error: materialItemErr, message: materialItemErr.invoice }}
                        />
                    </div>
                    <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                        <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Localização</span>
                        <NewSelect 
                            id='location'
                            onSelect={handleSelect}
                            selected={materialItem.location}
                            options={activeLocations}
                            optionStrKey='name'
                            error={materialItemErr.location}
                            addNewCategoryPermission={true}
                            handleAddNewCategory={() => handleAddNewLocation(locationInputValue)}
                            filterNode={<div className='InsertInventoryMaterialsModal-NewSelect'>
                                <Input
                                    placeholder='Pesquisar por localização'
                                    action={handleSearchLocation}
                                    actionFocus={()=> fetchActiveLocationsOptions()}
                                    value={locationInputValue}
                                />
                            </div>}
                        />
                    </div>
                    <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Quantidade</span>
                        <Input
                            name='requested_amount'
                            action={handleChange}
                            value={materialItem.requested_amount}
                            errors={{ error: materialItemErr, message: materialItemErr.requested_amount }}
                            type='number'
                            min='0'
                            max={maxExitItems || '10000000'}
                            placeholder='Ex.: 10, 25, 50'
                        />
                    </div>
                    
                    { materialItem?.source === 'allocation_to_patient'
                    ? <div className='MaterialFlowModal-Input MaterialFlowModal-Input-W'>
                        <span><b className='MaterialFlowModal-Asterisk'>*</b> Paciente</span>
                        <NewSelect 
                            id='patient'
                            options={patientsOptions}
                            selected={materialItem.patient}
                            onSelect={handleSelect}
                            error={materialItemErr.patient}
                            filterNode={<div className='ManageTeamEdgesModal-NewSelect'>
                            <Input 
                                placeholder='Pesquisar por nome ou email'
                                action={({ target: { value } }) => handleSearchPatientOptions(value)}
                                actionFocus={()=> fetchPatientsList()}
                            />
                            </div>}
                        />
                    </div> : null}
                
                <div className='MaterialFlowModal-Input MaterialFlowModal-Input-FullRow'>
                    <span>Observações</span>
                    <Input
                        name='justification'
                        action={handleChange}
                        value={materialItem.justification}
                    />
                </div>
                <div className='MaterialFlow-EntryForm-BtnBox'>
                    <NewButton 
                        label='Limpar campos'
                        onClick={handleClearFields}
                        model={ButtonModel.SECONDARY}
                    />
                    <NewButton 
                        label='Alocar material'
                        onClick={handleSubmit}
                        model={ButtonModel.PRIMARY}
                    />                    
                </div>
            </div>
            }
        </section>
    )

}