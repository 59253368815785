import './UnavailableHealthPlaceMenu.css'
import React, { useState } from "react";
import { IcoBin, MaskDataHora } from '../../../../../../../../../../../../component';
import IcoQuickly from '../../../../../../../../../../../../component/icon/quickly';
import { useBookingAppointmentCursor } from '../../../../../../BookingAppointmentCursor';

export default function UnavailableHealthPlaceMenu({ appointment={}, displayMode='daily' }) {
    const { setModalInfo, setSelectedTimesCursor, selectedTimesCursor } = useBookingAppointmentCursor()
    const startHourTime = MaskDataHora(appointment?.fields?.start || appointment?.start, 'DD/MMM/AAAA', '-');
    const endHourTime = MaskDataHora(appointment?.fields?.end || appointment?.end, 'DD/MMM/AAAA', '-');
    const isChecked = !!selectedTimesCursor.find(({pk}) => pk === appointment.pk)
    const appointmentDuration = new Date(appointment.fields.end) - new Date(appointment.fields.start)

    return <div className='UnavailableHealthPlaceMenu'
    style={displayMode === 'weekly' ? {fontSize: '12px'} : {fontSize: '14px'}}
    >
      <div>
        <div className='UnavailableHealthPlaceMenu-TitleDiv'>
          <span>
            <strong>{displayMode==='weekly' ? 'Associado' : 'Agendamento associado'} à outra Instituição</strong>&nbsp;
            {displayMode==='daily' && appointmentDuration <= 1500000 && <span style={{ fontSize: '12px' }}>{`| ${startHourTime?.hora?.slice(0,5) || '-'} | ${endHourTime?.hora?.slice(0,5) || '-'}`}</span>}
          </span>
          {displayMode==='weekly' && (
            <input
              type='checkbox'
              className='UnavailableHealthPlaceMenu-checkbox'
              checked={isChecked}
              onChange={
                () => {
                  isChecked 
                  ? setSelectedTimesCursor(prev => prev.filter(({pk}) => appointment.pk != pk))
                  : setSelectedTimesCursor(prev => ([ ...prev, appointment ]))}
                }
            />
          )}
        </div>
        {appointmentDuration > 1500000 && (
        <div className='UnavailableHealthPlaceMenu-HourTime'>
          <IcoQuickly style={displayMode === 'daily' ? { fontSize: '16px' } : { fontSize: '14px' }}/> 
          <span style={displayMode === 'daily' ? { fontSize: '12px' } : { fontSize: '10px' }}>{startHourTime?.hora?.slice(0,5) || '-'} | {endHourTime?.hora?.slice(0,5) || '-'}</span>
        </div>
        )}
      </div>
    </div>
  }