import './FinishAppointmentModal.css'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { MODAL_INIT_PROPS } from '../../../../../../newcomponents/modal/modal'
import { AddAlert, AlertType } from '../../../../../../component'
import { useAttendance } from '../../../../consultation'
import api from '../../../../../../helpers/api'
import NewButton, { ButtonModel } from '../../../../../../newcomponents/button/button'

export default function FinishAppointmentModal({ setModalInfo=() => null }) {
    const dispatch = useDispatch()
    const history = useHistory()
    const { attendance } = useAttendance()

    const endConsultation = async () => {

        api.put(`/attendance/action/${attendance.id}/close/`, {})
            .then(() => {
                setModalInfo(MODAL_INIT_PROPS)
                dispatch(AddAlert('Atendimento', 'Atendimento finalizado com sucesso', AlertType.SUCCESS))
                history.push('/home')
            })
            .catch(error => {
                dispatch(AddAlert('Atendimento', 'Falha ao finalizar atendimento, tente novamente!', AlertType.ERROR))
                console.error(error)
            })
    }

    return <div>
        Ao proseguir, todos os dados serão salvos e a consulta será finalizada.
        <br></br>
        <br></br>
        <br></br>
        <div style={{textAlign: 'center', fontWeight: 'bold'}}>Deseja finalizar a consulta?</div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '0.5rem', marginTop: '2rem'}}>
            <NewButton 
                label='Não, quero continuar'
                model={ButtonModel.SECONDARY}
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
            />
            <NewButton 
                label='Sim, quero finalizar'
                onClick={endConsultation}
            />
        </div>
    </div>
}