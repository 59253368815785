import './HealthPlaceInventoryConfig.css'
import React from "react";
import { useConfiguration } from "../../../../../configuration";
import HorizontalField from '../../../../../../../component/HorizontalField/HorizontalField';
import HealthPlaceInventoryConfigModal from './HealthPlaceInventoryConfigModal/HealthPlaceInventoryConfigModal';
import { MODAL_INIT_PROPS } from '../../../../../../../newcomponents/modal/modal';

const { PROFILE_CHOICES } = require('../../../../../../../component/form/select/options.json')

export default function HealthPlaceInventoryConfig({ accessConfiguration={}, fetchInventoryConfig= async () => null }) {
    const { setModalProps } = useConfiguration()

    const getStatus = (profile) => {   
        if (accessConfiguration.can_read_and_write.includes(profile)) {
            return 'Edição Habilitada'
        } else if (accessConfiguration.can_read.includes(profile)) {
            return 'Somente Leitura'
        } else {
            return 'Sem acesso'
        }
    }

    return <div className='HealthPlaceInventoryConfig'>
        <div className='HealthPlaceInventoryConfig-Header'>
            <div className='HealthPlaceInventoryConfig-Header-Icon'>P</div>
            <div className='HealthPlaceInventoryConfig-Header-Title'>Permissões de Acesso</div>
        </div>
        <div>
        {
            accessConfiguration?.health_place
            ? PROFILE_CHOICES.map(({ id, name }) => <HorizontalField label={name} content={getStatus(id)} />)
            : <p>Estoque não configurado.</p>
        }
        </div>
        <div className='HealthPlaceInventoryConfig-ActionRow'>
            <div
                className='HealthPlaceInventoryConfig-ActionRow-Btn' 
                onClick={() => setModalProps(prev => ({
                    ...prev,
                    open: true,
                    title: 'Configurar Estoque',
                    content: <HealthPlaceInventoryConfigModal 
                        initAccessConfiguration={accessConfiguration}
                        extraAction={async () => {
                            await fetchInventoryConfig()
                            setModalProps(MODAL_INIT_PROPS)
                        }}
                    />,
                    dismissText: '',
                }))}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}