import React from 'react'

export function RadioButton({
  options,
  action,
  checked,
  label,
  name,
  type = 'checkbox',
  color = '',
  optionLabel = 'name',
  optionValue = 'id'
}) {
  checked = !checked 
            ? { id: '', name: '' }
            : typeof checked === 'object'
            ? checked
            : options.find(op => op.id === checked) || { id: '', name: '' }

  const checkedAction = e => {
    const resp = e
    action({ target: { name: name, value: resp, type: type } })
  }

  const veryfiChecked = e => {
    return checked[optionValue] === e[optionValue] ? true : false
  }

  return (
    <div className={`form-box form-radiobutton ${color} `}>
      <label htmlFor={`id-${name}`}>{label}</label>
      <div className='form-radiobutton-row-radio-box'>
        {options
          ? options.map(c => {
              return (
                <div
                  key={`${name}-${c[optionValue]}`}
                  className={`radio-box ${veryfiChecked(c) ? 'checked' : ''}`}
                  onClick={() => checkedAction(c)}
                >
                  <span className={type}></span>
                  <span className='radio-box-option-label'>{c[optionLabel]}</span>
                </div>
              )
            })
          : null}
      </div>
    </div>
  )
}
