import React from 'react'

export default function InfoIcon({ width, height, color }) {
    return <svg width={width} height={height} version="1.0" viewBox="0 0 512 512">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
    fill={color} stroke="none">
    <path d="M2931 5110 c-234 -50 -428 -214 -491 -418 -25 -80 -30 -219 -10 -302
    44 -187 207 -358 405 -426 105 -36 259 -44 367 -20 282 63 488 304 488 571 0
    165 -53 290 -174 411 -89 89 -144 123 -256 161 -84 28 -249 40 -329 23z"/>
    <path d="M2325 3379 c-160 -17 -360 -74 -666 -191 l-157 -60 -35 -141 c-20
    -78 -34 -143 -32 -145 2 -2 53 13 114 32 201 65 397 71 517 15 85 -40 124
    -132 124 -292 0 -152 -36 -309 -221 -960 -139 -493 -171 -627 -190 -802 -30
    -278 24 -461 181 -616 155 -154 341 -215 650 -213 197 0 281 13 446 69 158 52
    478 176 490 189 11 10 77 276 71 282 -1 2 -33 -8 -70 -21 -188 -69 -414 -83
    -541 -35 -68 26 -100 55 -130 120 -18 39 -21 63 -20 180 1 185 23 285 199 910
    160 565 178 637 195 785 41 348 -1 519 -169 685 -65 64 -105 95 -161 122 -166
    82 -366 111 -595 87z"/>
    </g>
    </svg>
}



