import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AddAlert, AlertType, IcoEye, IcoEyeBlocked, Input } from '../../../component'
import ActionForm from '../../../component/form/actions/actions'
import { history } from '../../../helpers'
import api from '../../../helpers/api'
import { loaded, loading, logIn } from '../../../layout/redux/AppActions'
import Button, { ButtonModel } from '../../../newcomponents/button/button'
import NewSelect from '../../../newcomponents/form/select/select'
import { isInvalidPassword, isValidEmail } from '../../../validators'
import './RegisterHealthPlaceUserPage.css'

const initFormData = { 
    email: '', 
    profile: '', 
    password: '',
    confirmPassword: '',
}

class FormData {
    constructor(data) {
        this.email = data.email
        this.profile = data.profile
        this.password = data.password
        this.confirmPassword = data.confirmPassword
    }

    getErrors() {
        let errors = {}
        Object.entries(this).map(([key, value]) => {
            if (key === 'email' && !isValidEmail(value)) {
                errors['email'] = 'Email inválido'
            } else if (key === 'password' || key === 'confirmPassword') {
                let validationErr = isInvalidPassword(this.password, this.confirmPassword)
                if (validationErr) {
                    errors['password'] = validationErr
                    errors['confirmPassword'] = true
                }
            } else if (!value) {
                errors[key] = 'Campo obrigatório'
            }
            return null
        })
        return errors
    }

    format() {
        let data = { ...this }
        data['user'] = {
            email: data.email,
            password: data.password
        }
        delete data.email
        delete data.password
        delete data.confirmPassword
        return data
    }
}

export default function RegisterPage() {
    const dispatch = useDispatch()
    const data = require('./RegisterHealthPlaceUserPage.json')
    const [showPass, setShowPass] = useState(true)
    const [confirmShowPass, setConfirmShowPass] = useState(true)
    const [formData, setFormData] = useState(initFormData)
    const [formDataErr, setFormDataErr] = useState(initFormData)
    
    const checkIfEmailAlreadyExist = async (email) => {
        api.get(`/person/email/exist/${email}/`)
            .then(() => setFormDataErr(prev => ({...prev, email: 'Email já cadastrado' })))
    }

    const handleChange = (event) => {
        if (event.target.name === 'password') {
            let validationErr = isInvalidPassword(event.target.value, event.target.value)
            validationErr ? setFormDataErr({ password: validationErr }) : setFormDataErr({})
        } else {
            setFormDataErr({})
        }
        
        setFormData(prev => ({ ...prev, [`${event.target.name}`]: event.target.value }))
    }
    
    const handleSelect = (event) =>  {
        setFormDataErr({})
        setFormData(prev => ({ ...prev, [`${event.target.id}`]: event.target.selected }))
    }

    const handleRegister = () => {
        const payload = new FormData(formData)
        let errors = payload.getErrors()

        if (Object.keys(errors).length) return setFormDataErr(errors)
        dispatch(loading())
        api.post('health_place_user/new/', payload.format())
            .then(() => {
                dispatch([
                    loaded(),
                    AddAlert('Registro', 'Usuário registrado com sucesso', AlertType.SUCCESS),
                    logIn({ email: payload.email, senha: payload.password })
                ])
            })
            .catch(error => {
                dispatch([loaded(), AddAlert('Registro', 'Falha ao registrar usuário', AlertType.ERROR)])
                console.error(error)
            })
    }

    return <div className='RegisterPage'>
        <section>
            <h3>{process.env.REACT_APP_SITE_TITLE}</h3>
            <h4>Cadastre-se para iniciar sua jonarda digital dos pacientes.</h4>
            <NewSelect
                id='profile'
                label='Perfil'
                canBeEmpty={false}
                options={data.profileChoices}
                selected={formData.profile}
                onSelect={handleSelect}
                error={formDataErr.profile}
            />
            <Input 
                label='E-mail' 
                name='email' 
                action={handleChange}
                actionBlur={event => checkIfEmailAlreadyExist(event.target.value)} 
                value={formData.email} 
                required={{ erro: formDataErr, message: formDataErr.email }}
            />
            <Input
                label='Senha'
                type={showPass ? 'password' : 'text'}
                name='password'
                action={handleChange}
                value={formData.password}
                required={{ erro: formDataErr, message: formDataErr.password }}
            >
                <ActionForm action={() => setShowPass(!showPass)} title={showPass ? 'visivel' : 'invisivel'}>
                    {showPass ? <IcoEye /> : <IcoEyeBlocked />}
                </ActionForm>
            </Input>
            <Input
                label='Confirmar senha'
                type={confirmShowPass ? 'password' : 'text'}
                name='confirmPassword'
                action={handleChange}
                value={formData.confirmPassword}
                required={{ erro: formDataErr, message: formDataErr.confirmPassword }}
            >
                <ActionForm action={() => setConfirmShowPass(!confirmShowPass)} title={confirmShowPass ? 'visivel' : 'invisivel'}>
                    {confirmShowPass ? <IcoEye /> : <IcoEyeBlocked />}
                </ActionForm>
            </Input>
            <div className='RegisterPage-BtnBox'>
                <Button
                    label='Cadastrar'
                    onClick={handleRegister}
                />
                <Button
                    label='Cancelar'
                    onClick={() => history.push('/login')}
                    model={ButtonModel.SECONDARY}
                />
            </div>
        </section>

    </div>
}