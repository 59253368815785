import React from 'react';
import {
  Chart,
  LinearScale,
  CategoryScale,
  LineController,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import './ShowDetailChartVitalSign.css';

Chart.register(
  LinearScale,
  CategoryScale,
  LineController,
  PointElement,
  LineElement,
  Title
);

export default function ShowDetailChartVitalSign({ chartConfig }) {
  const config = {
    data: {
      labels: chartConfig.labels,
      datasets: chartConfig.datasets,
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: '',
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Data',
          },
        },
        y: {
          title: {
            display: true,
            text: chartConfig.yLabel,
          },
        },
      },
    },
  };

  return (
    <div className='ShowDetailChartVitalSign-Container'>
      <div className='ShowDetailChartVitalSign-Chart'>
        <Line data={config.data} options={config.options} />
      </div>
    </div>
  );
}
