import React from 'react'

import './info-line.css'

export default function InfoLine({label, value}) {

    return <div className='info-line'>
        <label>{label}</label>
        <span>{value}</span>
    </div>
}