import './PatientPersonalData.css'
import React from "react";
import { useHoldingPatient } from '../../../../HoldingPatientInfoView';
import { MaskCpf, MaskDataHora, MaskRG, MaskSUSCard, MaskTelefone } from '../../../../../../component';
import HorizontalField from '../../../../../../component/HorizontalField/HorizontalField';

const {
    SEX_CHOICES,
    RACES_CHOICES,
    MARITAL_STATUS_CHOICES,
    GENDER_CHOICES,
    COUNTRY_CHOICES,
    PHONE_TYPES_CHOICES,
} = require('../../../../../../component/form/select/options.json')

export default function PatientPersonalData() {
    const { holdingPatient, openEditPatient } = useHoldingPatient()
    

    return <div className='PatientPersonalData'>
        <div className='PatientPersonalData-Header'>
            <div className='PatientPersonalData-Header-Icon'>P</div>
            <div className='PatientPersonalData-Header-Title'>Dados Pessoais</div>
        </div>
        <div>
            <HorizontalField 
                label='Nome' 
                content={holdingPatient?.name}
            />
            <HorizontalField 
                label='Mesmo nome social?' 
                content={holdingPatient?.is_it_the_same_social_name === 'NO' ? 'Não' : 'Sim'}
            />
            {
                holdingPatient?.social_name
                ? <HorizontalField 
                    label='Nome Social' 
                    content={holdingPatient?.social_name}
                />
                : null
            }
            <HorizontalField 
                label={`${holdingPatient?.document_type}`}
                content={holdingPatient?.rg ? holdingPatient?.rg : MaskCpf(holdingPatient?.cpf)}
            />
            <HorizontalField 
                label='Nome da Mãe' 
                content={holdingPatient?.mother_name}
            />
            <HorizontalField 
                label='Data de Nascimento' 
                content={
                    holdingPatient?.birthday
                    ? MaskDataHora(holdingPatient?.birthday, 'DD/MMM/AAAA', '-')?.data
                    : '-'
                }
            />
            <HorizontalField 
                label='Tipo de Telefone' 
                content={PHONE_TYPES_CHOICES.find(({id}) => id === holdingPatient?.phone_type)?.name}
            />
            <HorizontalField 
                label='Telefone' 
                content={MaskTelefone(holdingPatient?.contact_phone)}
            />
            <HorizontalField 
                label='E-mail' 
                content={holdingPatient?.user?.email}
            />
            <HorizontalField 
                label='Cartão Nac. de Saúde (CNS)' 
                content={MaskSUSCard(holdingPatient?.sus_card_number)} />
            <HorizontalField 
                label='Sexo' 
                content={SEX_CHOICES.find(({id}) => id === holdingPatient?.sex)?.name} />
            <HorizontalField 
                label='Raça' 
                content={RACES_CHOICES.find(({id}) => id === holdingPatient?.race)?.name} />
            <HorizontalField 
                label='Estado Civil' 
                content={MARITAL_STATUS_CHOICES.find(({id}) => id === holdingPatient?.marital_status)?.name} 
            />
            <HorizontalField 
                label='Gênero' 
                content={GENDER_CHOICES.find(({id}) => id === holdingPatient?.gender)?.name} />
            {
                holdingPatient?.passport_number
                ? <HorizontalField 
                    label='Nº de Passaporte' 
                    content={holdingPatient?.passport_number}
                />
                : null
            }
            {
                COUNTRY_CHOICES.find(({id}) => id === holdingPatient?.passport_country_of_birth)?.name
                ? <HorizontalField 
                    label='País de Nascimento' 
                    content={COUNTRY_CHOICES.find(({id}) => id === holdingPatient?.passport_country_of_birth)?.name}
                />
                : null
            }
            {
                COUNTRY_CHOICES.find(({id}) => id === holdingPatient?.passport_issuing_country)?.name
                ? <HorizontalField 
                    label='País Emissor' 
                    content={COUNTRY_CHOICES.find(({id}) => id === holdingPatient?.passport_issuing_country)?.name}
                />
                : null
            }
            {
                holdingPatient?.passport_issuance_date
                ? <HorizontalField 
                    label='Data de Emissão' 
                    content={
                        holdingPatient?.passport_issuance_date
                        ? MaskDataHora(holdingPatient?.passport_issuance_date, 'DD/MMM/AAAA', '-')?.data
                        : '-'
                    }
                />
                : null
            }
            {
                holdingPatient?.passport_expiration_date
                ? <HorizontalField 
                    label='Data de Validade' 
                    content={
                        holdingPatient?.passport_expiration_date
                        ? MaskDataHora(holdingPatient?.passport_expiration_date, 'DD/MMM/AAAA', '-')?.data
                        : '-'
                    }
                />
                : null
            }
            {
                !holdingPatient.is_active && holdingPatient?.date_of_death
                ? <HorizontalField 
                    label='Data do óbito' 
                    content={
                        holdingPatient?.date_of_death
                        ? MaskDataHora(holdingPatient?.date_of_death, 'DD/MMM/AAAA', '-')?.data
                        : '-'
                    }
                />
                : null
            }
            {
                !holdingPatient.is_active && holdingPatient?.death_reason
                ? <HorizontalField 
                    label='Descrição óbito' 
                    content={holdingPatient?.death_reason}
                />
                : null
            }
            {
                !holdingPatient.is_active && holdingPatient?.death_cid
                ? <HorizontalField 
                    label='CID-10' 
                    content={holdingPatient?.death_cid ? 
                       holdingPatient.death_cid.map(cid => cid.code).join(' - ') 
                       : null
                    }
                />
                : null
            }
            {
                !holdingPatient.is_active && holdingPatient?.death_declaration_number
                ? <HorizontalField 
                    label='Número de declaração de óbito' 
                    content={holdingPatient?.death_declaration_number}
                />
                : null
            }

            {
                /*
            <HorizontalField 
                label='Escolaridade' 
                content={EDUCATION_CHOICES.find(({id}) => id === holdingPatient?.education)?.name} />
            <HorizontalField 
                label='Carreira' 
                content={holdingPatient?.career?.name} 
            />
                 */
            }

        </div>
        <div className='PatientPersonalData-ActionRow'>
            <div 
                className='PatientPersonalData-ActionRow-Btn' 
                onClick={openEditPatient}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}