import './TableVitalSigns.css'
import React, { useEffect, useState } from 'react'
import { IcoBin, IcoPencil, List, Paginate } from '../../../../../../../../../../component';
import { Mask1DecimalPlace } from '../../../../../../../../../../component/mask/mask';


export default function TableVitalSigns({ vitalSigns=[], pagination={}, fetchVitalSigns=async () => null, openNewEditVitalSignModal=() => null, openDeleteVitalSignModal=() => null }) {
    useEffect(() => {
        fetchVitalSigns({ offset: 0, limit: 10 })
    }, [])

    const handleNextPage = async e => {
        let offset = e * 10
		await fetchVitalSigns({ "offset": offset, limit: 10 })
	}
                                    
    const headDefault = [
        { "colunm": "h_systolic", "text": "Sistólica (mmHg)" },
        { "colunm": "h_diastolic", "text": "Diastólica (mmHg)" },
        { "colunm": "h_oxygen_saturation", "text": "Saturação de oxigênio (%)" },
        { "colunm": "h_body_temperature", "text": "Temperatura corporal (°C)" },
        { "colunm": "h_heart_rate", "text": "Frequência cardíaca (bpm)" },
        { "colunm": "h_respiration_rate", "text": "Frequência respiratória (mpm)" },
        { "colunm": "h_action", "text": <div className='TableVitalSigns-HeaderRow-Action'>Ações</div>, width: '72px' } 
    ]

    return <div>
        <div className='TableVitalSigns-Body-List'>
            <List
                head={headDefault}
                data={vitalSigns}
                listCustom={cursor => {
                    const custom = cursor;
                    custom['h_systolic'] = cursor?.systolic || '-'
                    custom['h_diastolic'] = cursor?.diastolic || '-'
                    custom['h_oxygen_saturation'] = cursor?.oxygen_saturation ? Mask1DecimalPlace(cursor?.oxygen_saturation) : '-'
                    custom['h_body_temperature'] = cursor?.body_temperature ? Mask1DecimalPlace(cursor?.body_temperature) : '-'
                    custom['h_heart_rate'] = cursor?.heart_rate || '-'
                    custom['h_respiration_rate'] = cursor?.respiration_rate || '-'
                    custom['h_action'] = (
                        <div className='TableVitalSigns-ActionRow'>
                            <div
                                title='Editar Sinal Vital'
                            	onClick={() => openNewEditVitalSignModal(cursor)}
                            >
                            	<IcoPencil />
                            </div>
                            <div
                                title='Excluir Sinal Vital'
                                onClick={() => openDeleteVitalSignModal(cursor)}
                            >
                                <IcoBin />
                            </div>
                        </div>
                    )
                    return custom
                }}
            />
            <Paginate data={pagination} action={handleNextPage} />
        </div>

    </div>
}