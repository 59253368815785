import './HealthPlaceCallPanelService.css'
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import HorizontalField from '../../../../../../../component/HorizontalField/HorizontalField';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../newcomponents/modal/modal';
import EditCallPanelServicesOptionsModal from './EditCallPanelServicesOptionsModal/EditCallPanelServicesOptionsModal';
import { getAppointmentCallPanelService } from '../../http';
import { LinkIco } from '../../../../../../../component/icon/link';


export default function HealthPlaceCallPanelService() {
    const history = useHistory()
    const [modalProps, setModalProps] = useState(MODAL_INIT_PROPS)
    const [callPanelServicesOptions, setCallPanelServicesOptions] = useState([])

    useEffect(() => {
        fetchCallPainelServicesOptions()
    }, [])

    const fetchCallPainelServicesOptions = async () => {
        try {
            let res = await getAppointmentCallPanelService({ active: true })
            setCallPanelServicesOptions(res.data.results)
        } catch (err) {
            setCallPanelServicesOptions([])
            console.error('fetchCallPainelServicesOptions ~ ', err)
        }
    }

    return <div className='HealthPlaceCallPanelService'>
        <Modal 
            {...modalProps} 
            dismissFn={() => {
                fetchCallPainelServicesOptions()
                setModalProps(MODAL_INIT_PROPS)}
            }
        />
        <div className='HealthPlaceCallPanelService-Header'>
            <div className='HealthPlaceCallPanelService-Header-Icon'>P</div>
            <div className='HealthPlaceCallPanelService-Header-Title'>Painéis</div>
        </div>
        <div className='HealthPlaceCallPanelService-Body'>
            {
                callPanelServicesOptions?.length
                ? callPanelServicesOptions.map(cursor => <HorizontalField 
                    label={`${cursor?.name}`} 
                    content={<div 
                        className='HealthPlaceCallPanelService-Body-HorizontalField-Link'
                        onClick={() => history.push(`/painel-de-chamada/${cursor?.uuid}`)}
                    >
                        Abrir
                    </div>} 
                />) 
                : <p>Nenhum painel habilitado</p>
            }
        </div>
        <div className='HealthPlaceCallPanelService-ActionRow'>
            <div 
                className='HealthPlaceCallPanelService-ActionRow-Btn' 
                onClick={() => setModalProps(prev => ({
                    ...prev,
                    open: true,
                    title: 'Editar Instituição',
                    content: <EditCallPanelServicesOptionsModal />,
                    dismissText: '',
                }))}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}
