import './UnavailableScheduleMenu.css'
import React, { useState } from "react";
import { IcoBin, MaskDataHora } from '../../../../../../../../../../../../component';
import IcoQuickly from '../../../../../../../../../../../../component/icon/quickly';
import IcoBlockCalendar from '../../../../../../../../../../../../component/icon/calendar_block';
import { NewPatientIcon } from '../../../../../../../../../../../../component/icon/newPatient';
import { useBookingAppointmentCursor } from '../../../../../../BookingAppointmentCursor';
import DeleteScheduleSlot from '../../../DeleteScheduleSlot/DeleteScheduleSlot';
import IcoCalendarUnblock from '../../../../../../../../../../../../component/icon/calendar_unblock';
import MarkTimeAsAvailable from '../../../MarkTimeAsAvailable/MarkTimeAsAvailable';
import InfoOutlinedIcon from '../../../../../../../../../../../../component/icon/info-outlined';
import IcoBoxReady from '../../../../../../../../../../../../component/icon/box_ready';
import UnavailableScheduleModal from './subcomponents/UnavailableScheduleModal/UnavailableScheduleModal';

export default function UnavailableScheduleMenu({ appointment={}, displayMode='daily' }) {
    const [isOpen, setIsOpen] = useState(false);
    const { setModalInfo, setSelectedTimesCursor, selectedTimesCursor } = useBookingAppointmentCursor()
    const startHourTime = MaskDataHora(appointment?.fields?.start || appointment?.start, 'DD/MMM/AAAA', '-');
    const endHourTime = MaskDataHora(appointment?.fields?.end || appointment?.end, 'DD/MMM/AAAA', '-');
    const isChecked = !!selectedTimesCursor.find(({pk}) => pk === appointment.pk)
    const appointmentDuration = new Date(appointment.fields.end) - new Date(appointment.fields.start)
    const isSaturday = new Date(appointment.fields.start).getDay() === 6;

    const openRemoveSchedule = () => {
      setModalInfo(prev => ({
        ...prev,
        open: true,
        title: 'Remover Horário(s)',
        content: <DeleteScheduleSlot appointments={[appointment]} />
      }))
    }

    const handleMoveToAvailable = async () => {
      setModalInfo(prev => ({
        ...prev,
        open: true,
        title: 'Disponibilizar Horário(s)',
        content: <MarkTimeAsAvailable appointments={[appointment]} />
      }))
    }
    
    const openBlockingReasonUnavailableScheduleModal = () => {
      setModalInfo(prev => ({ 
        ...prev, 
        open: true,
        title: 'Horário Bloqueado',
        content: <UnavailableScheduleModal appointment={appointment} />
      }))
    }

    const dropdownOptions = [
      {
        title: 'Ver Motivo de Bloqueio',
        Icon: <InfoOutlinedIcon style={{ width: '14px', height: '14px' }} />,
        action: openBlockingReasonUnavailableScheduleModal,
      },
      { 
        title: 'Disponibilizar Horário', 
        Icon: <IcoCalendarUnblock style={{ width: '14px', height: '14px' }} />,
        action: handleMoveToAvailable,
      },
      {
        title: 'Excluir Horário',
        Icon: <IcoBin style={{ width: '14px', height: '14px' }} className='' />,
        action: openRemoveSchedule
      }
    ]

    return <div 
      className='UnavailableScheduleMenu'
      style={displayMode === 'weekly' ? {fontSize: '12px'} : {fontSize: '14px'}}
      onMouseEnter={() => setIsOpen(true)} 
      onMouseLeave={() => setIsOpen(false)}
    >
      <div>
        <div className='UnavailableScheduleMenu-TitleDiv'>
          <div>
            <strong>Bloqueado</strong>&nbsp;
            {displayMode==='daily' && appointmentDuration <= 1500000 && <span style={{ fontSize: '12px' }}>{`| ${startHourTime?.hora?.slice(0,5) || '-'} | ${endHourTime?.hora?.slice(0,5) || '-'}`}</span>}
            {displayMode === 'weekly' ? '' : (appointment?.message ? ` | ${appointment?.message.substring(0, 150)}${appointment?.message.length > 150 ? '...' : ''}` : null)} 
          </div>
          {
            displayMode === 'weekly' && (
              <input
              type='checkbox'
              className='UnavailableScheduleMenu-checkbox'
              checked={isChecked}
              onChange={
                () => {
                  isChecked 
                  ? setSelectedTimesCursor(prev => prev.filter(({pk}) => appointment.pk != pk))
                  : setSelectedTimesCursor(prev => ([ ...prev, appointment ]))}
                }
            />
            )
          }
         
        </div>
        {(displayMode==='weekly' && appointmentDuration > 2100000) || (displayMode==='daily' && appointmentDuration > 1500000) && (
        <div className='UnavailableScheduleMenu-HourTime'>
          <IcoQuickly style={displayMode === 'daily' ? { fontSize: '16px' } : { fontSize: '14px' }} /> 
          <span style={displayMode === 'daily' ? { fontSize: '12px' } : { fontSize: '10px' }}>{startHourTime?.hora?.slice(0,5) || '-'} | {endHourTime?.hora?.slice(0,5) || '-'}</span>
        </div>
        )}
      </div>
      <div className={`UnavailableScheduleMenu-Dropdown ${displayMode==='weekly' & isSaturday ? 'UnavailableScheduleMenu-Dropdown-left' : ''} ${displayMode === 'weekly' ? 'UnavailableScheduleMenu-Dropdown-content-weekly' : ''}`}>
          {isOpen && (
            <div className="UnavailableScheduleMenu-Dropdown-content">
              <div className='UnavailableScheduleMenu-Dropdown-ActionBar'>
                {dropdownOptions.map((option, index) => (
                  <div
                    key={index}
                    title={option.title}
                    onClick={option.action} 
                    className="UnavailableScheduleMenu-Dropdown-item"
                  >
                    {option.Icon} {option.label}
                  </div>
                ))}
              </div>
              <div className='UnavailableScheduleMenu-Dropdown-info'>
                  <p>{displayMode==='weekly'
                      ? <div className='UnavailableScheduleMenu-HourTime'>
                        <IcoQuickly style={{fontSize: '16px'}} /> 
                        <span style={{fontSize: '12px'}}>{startHourTime?.hora?.slice(0,5) || '-'} | {endHourTime?.hora?.slice(0,5) || '-'}</span>
                      </div>
                      : ''}
                  </p>
                {displayMode === 'weekly' ? (appointment?.message ? `Motivo: ${appointment?.message.substring(0, 150)}${appointment?.message.length > 150 ? '...' : ''}` : null) : ''}
              </div>

            </div>
          )}
      </div>
    </div>
  }