import './SOAPSection.css'
import React, { useEffect, useState } from "react";

import ThousandDaysSection from './subcomponents/ThousandDaysSection/ThousandDaysSection';
import SOAPSubjective from './subcomponents/Subjective/SOAPSubjective';
import SOAPObjective from './subcomponents/Objective/SOAPObjective';
import SOAPAssessment from './subcomponents/Assessment/SOAPAssessment';
import SOAPPlan from './subcomponents/Plan/SOAPPlan';
import ArrowDownIco from '../../../../../../component/icon/arrow_down';

const initOpenSections = {
    thousandDays: true,
    subjective: false,
    objective: false,
    assessment: false,
    plan: false
}

export default function SOAPSection() {
    const [openSections, setOpenSections] = useState(initOpenSections)
    const [renderThousandDays, setRenderThousandDays] = useState(false)

    useEffect(() => {
        const handleThousandDaysOk = (event) => {
            setRenderThousandDays(!!event.detail)
        }

        document.addEventListener('thousand_days_ok', handleThousandDaysOk);

        return () => {
            document.removeEventListener('thousand_days_ok', handleThousandDaysOk)
        }
    }, [])

    useEffect(() => {
        const handleThousandDaysError = (event) => {
            setRenderThousandDays(false)
        }

        document.addEventListener('thousand_days_error', handleThousandDaysError)

        return () => {
            document.removeEventListener('thousand_days_error', handleThousandDaysError)
        }
    }, [])

    const toggleSection = (section) => {
        setOpenSections(prevSections => ({
            ...prevSections,
            [section]: !prevSections[section]
        }))
    }

    return <>
    {
        <div className={`SOAP-1000days ${!renderThousandDays ? 'SOAP-AccordionNav-1000days-Hidden': ''}`}>
            <div className={`SOAP-AccordionNav SOAP-AccordionNav-1000days ${openSections.thousandDays ? 'open' : ''}`} onClick={() => toggleSection('thousandDays')}><span className='SOAP-AccordionNav-1000days-SubTitle'>Gestação</span>  <ArrowDownIco /></div>
            {openSections.thousandDays &&
                <>
                    <div className={`SOAP-Section ${openSections.thousandDays ? 'open' : ''}`}>
                        {
                            true
                            ? <ThousandDaysSection />
                            : <iframe className='SOAP-Section-1000Days-Frame' title='Mevo Modal' src={'http://localhost:3000/1000days/900ad166-307a-445d-8e6f-682d1f9bf000'}></iframe>
                        }

                    </div>
                </>
            }
        </div>
    }
        <div className="SOAP-Subjective">
            <div className={`SOAP-AccordionNav ${openSections.subjective ? 'open' : ''}`} onClick={() => toggleSection('subjective')}>Subjetivo <ArrowDownIco /></div>
            {openSections.subjective &&
                <>
                    <div className={`SOAP-Section ${openSections.subjective ? 'open' : ''}`}>
                        <SOAPSubjective />
                    </div>
                </>
            }
        </div>
        <div className="SOAP-Objective">
            <div className={`SOAP-AccordionNav ${openSections.objective ? 'open' : ''}`} onClick={() => toggleSection('objective')}>Objetivo <ArrowDownIco /></div>
            {openSections.objective &&
                <>
                    <div className={`SOAP-Section ${openSections.objective ? 'open' : ''}`}>
                        <SOAPObjective />
                    </div>
                </>
            }
        </div>
        <div className="SOAP-Assessment">
            <div className={`SOAP-AccordionNav ${openSections.assessment ? 'open' : ''}`} onClick={() => toggleSection('assessment')}>Avaliação <ArrowDownIco /></div>
            {openSections.assessment && <>
                <div className={`SOAP-Section ${openSections.assessment ? 'open' : ''}`}>
                    <SOAPAssessment />
                </div>
            </>
            }
        </div>
        <div className="SOAP-Plan">
            <div className={`SOAP-AccordionNav ${openSections.plan ? 'open' : ''}`} onClick={() => toggleSection('plan')}>Plano <ArrowDownIco /></div>
            {openSections.plan &&
                <div className={`SOAP-Section ${openSections.plan ? 'open' : ''}`}>
                    <SOAPPlan />
                </div>
            }
        </div>
    </>
}