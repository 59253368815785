function isIntervalGreaterThanOneDay(dateString) {
    const inputDate = new Date(dateString);
    const now = new Date();
    const intervalInMilliseconds = now - inputDate;
    const intervalInDays = intervalInMilliseconds / (1000 * 60 * 60 * 24);
    return intervalInDays > 1;
}

export function checkIfAttendanceIsEditable(attendance, isPreceptorship=false) {
    const { decision, start } = attendance;
    
    if (isPreceptorship) return false
    
    return (decision !== 'CLOSE' || !isIntervalGreaterThanOneDay(start))
}

const init_default_field = { "coherence": "", "accuracy": "" }

export const strpPreceptorship = (preceptorship) => {
    return {
        "id": preceptorship?.id || "",
        "subjective_feedback": preceptorship?.subjective_feedback || "",
        "objective_feedback": preceptorship?.objective_feedback || "",
        "assessment_feedback": preceptorship?.assessment_feedback || "",
        "plan_feedback": preceptorship?.plan_feedback || "",
        "reason_for_consultation": preceptorship?.reason_for_consultation || init_default_field,
        "main_complaint": preceptorship?.main_complaint || init_default_field,
        "history_of_current_illness": preceptorship?.history_of_current_illness || init_default_field,
        "past_pathological_history": preceptorship?.past_pathological_history || init_default_field,
        "physical_exam": preceptorship?.physical_exam || init_default_field,
        "vital_sign": preceptorship?.vital_sign || init_default_field,
        "anthropometry": preceptorship?.anthropometry || init_default_field,
        "related_diseases_and_problems": preceptorship?.related_diseases_and_problems || init_default_field,
        "diagnosis": preceptorship?.diagnosis || init_default_field,
        "intervention_clinical_procedures": preceptorship?.intervention_clinical_procedures || init_default_field,
        "conduct": preceptorship?.conduct || init_default_field
    }
}