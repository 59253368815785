import React from 'react'

export default function IcoWhatsapp({ style }) {
    return <svg style={style} viewBox="0 0 512 512" version="1.1" id="svg12" xmlns="http://www.w3.org/2000/svg">
    <defs id="defs16" />
    <g id="XMLID_468_" transform="matrix(1.5935301,0,0,1.6122708,18.155891,5.6901138)">
      <path
        id="XMLID_469_"
        d="m 227.904,176.981 c -0.6,-0.288 -23.054,-11.345 -27.044,-12.781 -1.629,-0.585 -3.374,-1.156 -5.23,-1.156 -3.032,0 -5.579,1.511 -7.563,4.479 -2.243,3.334 -9.033,11.271 -11.131,13.642 -0.274,0.313 -0.648,0.687 -0.872,0.687 -0.201,0 -3.676,-1.431 -4.728,-1.888 -24.087,-10.463 -42.37,-35.624 -44.877,-39.867 -0.358,-0.61 -0.373,-0.887 -0.376,-0.887 0.088,-0.323 0.898,-1.135 1.316,-1.554 1.223,-1.21 2.548,-2.805 3.83,-4.348 0.607,-0.731 1.215,-1.463 1.812,-2.153 1.86,-2.164 2.688,-3.844 3.648,-5.79 l 0.503,-1.011 c 2.344,-4.657 0.342,-8.587 -0.305,-9.856 -0.531,-1.062 -10.012,-23.944 -11.02,-26.348 -2.424,-5.801 -5.627,-8.502 -10.078,-8.502 -0.413,0 0,0 -1.732,0.073 -2.109,0.089 -13.594,1.601 -18.672,4.802 C 90,87.918 80.89,98.74 80.89,117.772 c 0,17.129 10.87,33.302 15.537,39.453 0.116,0.155 0.329,0.47 0.638,0.922 17.873,26.102 40.154,45.446 62.741,54.469 21.745,8.686 32.042,9.69 37.896,9.69 0.001,0 0.001,0 0.001,0 2.46,0 4.429,-0.193 6.166,-0.364 l 1.102,-0.105 c 7.512,-0.666 24.02,-9.22 27.775,-19.655 2.958,-8.219 3.738,-17.199 1.77,-20.458 -1.348,-2.216 -3.671,-3.331 -6.612,-4.743 z"
      />
      <a id="a1954" />
      <a id="a1954-8" transform="matrix(0.03578433,-0.98774319,-1.0111125,-0.03578433,299.85402,320.30525)" />
      <g id="g4755">
        <g id="g5064">
          <path
            style={{ color: '#000000', fill: '#ffffff', strokeWidth: '0.47509', stroke: 'none' }}
            d="m 214.27091,187.91596 c -0.27786,-0.14039 -10.67606,-5.52959 -12.52379,-6.2295 -0.75438,-0.28512 -1.56246,-0.56344 -2.42195,-0.56344 -1.40409,0 -2.58358,0.73647 -3.50235,2.18307 -1.0387,1.62502 -4.18309,5.49353 -5.15464,6.64916 -0.12689,0.15255 -0.3267,0.26626 -0.40382,0.33483 -0.11485,0.10211 -6.23108,2.20323 -6.71823,1.9805 0.30692,-0.98393 -1.47241,17.94674 -1.47241,17.94674 7.06893,4.18424 17.8042,1.84695 19.99288,1.13906 3.24872,-0.8361 12.70772,-6.07122 14.44663,-11.15728 1.36981,-4.00596 1.73101,-8.38282 0.81965,-9.97127 -0.62424,-1.08007 -1.69999,-1.62355 -3.06194,-2.31175 z"
            id="XMLID_469_-7"
          />
          <path
            style={{ color: '#000000', fill: '#ffffff', strokeWidth: '0.47509', stroke: 'none' }}
            d="m 214.27091,187.91596 c -0.27786,-0.14039 -10.67606,-5.52959 -12.52379,-6.2295 -0.75438,-0.28512 -1.56246,-0.56344 -2.42195,-0.56344 -1.40409,0 -2.58358,0.73647 -3.50235,2.18307 -1.0387,1.62502 -11.8994,6.3412 -12.87095,7.49683 -0.12689,0.15255 7.41622,-0.51284 7.31249,-0.51284 -0.0931,0 -1.9568,6.32728 -2.44398,6.10455 0.30692,-0.98393 -4.69104,11.42032 -4.69104,11.42032 10.06986,4.23357 14.44443,2.19255 17.15535,2.19255 h 4.6e-4 c 1.13922,0 2.05103,-0.0941 2.85543,-0.17742 l 0.51032,-0.0511 c 3.47872,-0.32461 11.12339,-4.49385 12.8623,-9.57991 1.36981,-4.00596 1.73101,-8.38282 0.81965,-9.97127 -0.62424,-1.08007 -1.69999,-1.62355 -3.06194,-2.31175 z"
            id="XMLID_469_-7-48"
            transform="matrix(0.03578433,-0.98774319,-1.0111125,-0.03578433,299.85402,320.30525)"
          />
          <path
            style={{ color: '#000000', fill: '#ffffff', strokeWidth: '0.539781', stroke: 'none' }}
            d="m 115.18737,138.07386 c 0,0 -0.33334,-7.91299 -1.1536,-14.88254 -14.326261,-17.62989 -11.7181,-23.615178 -18.072401,0.49305 -1.830861,7.69635 3.134357,16.45487 5.350911,19.85908 0.0548,0.0855 1.17684,1.95202 1.31878,2.1975 8.21651,14.18081 51.85745,55.31026 65.15189,59.35529 3.0804,0.67779 45.49366,22.90656 15.80267,-12.81313 -21.16212,-1.91668 -43.39568,-19.75315 -60.04831,-40.57646 z"
            id="XMLID_469_-7-4"
          />
        </g>
      </g>
      <path
        id="XMLID_470_"
        d="m 155.62863,9.9841294 c -83.415996,0 -140.779018,60.1432396 -140.779018,142.9322406 0,26.777 11.034781,52.43333 24.609781,75.37333 L 0.212,302.716 c -0.484,1.429 -0.124,3.009 0.933,4.085 C 1.908,307.58 2.943,308 4,308 c 0.405,0 0.813,-0.061 1.211,-0.188 l 83.236098,-37.04415 c 21.870002,11.685 41.613852,17.853 66.629852,17.853 83.408,10e-4 141.31671,-56.25252 141.31671,-139.03252 0,-82.789004 -57.35603,-139.6042006 -140.76503,-139.6042006 z m 5.09313,260.6990406 c -23.539,0 -50.32576,-8.48617 -69.92376,-21.34617 -0.659,-0.433 -1.424,-0.655 -2.194,-0.655 -0.407,0 -0.815,0.062 -1.212,0.188 L 27.307443,281.05989 60.281,223.467 c 0.418,-1.234 0.209,-2.595 -0.561,-3.647 -14.924,-20.392 -24.522038,-46.17417 -24.522038,-71.36617 0,-65.543003 54.323679,-119.993118 120.395678,-119.993118 66.064,0 121.03634,57.273787 121.03634,122.816788 0.001,65.535 -49.84322,119.40567 -115.90822,119.40567 z"
      />
    </g>
  </svg>

}