import './RecurringPatientModal.css';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import PageOne from './PageOne';
import PageTwo from './PageTwo';
import PageThree from './PageThree';
import { useApp } from '../../../../../../../../../../layout/App';
import { getParams } from '../../../../../../../../../../utils';
import { getSpecialties } from '../../../../../../../../../agenda/SelectScheduleHealthPlaceUserManager/http';
import { getHealthInsurances } from '../../../../../../../../../configuration/http';
import { AddAlert, AlertType, adicionaZero } from '../../../../../../../../../../component';
import { getCollaborators } from '../../../../../../../../../collaborators/http';
import { stringifyCollaboratorOption } from '../../../../../../../../../../utils/stringifyCollaboratorOption';

const INIT_APPOINTMENT = { 
    person: null, 
    doctor: null, 
    result: 'NORMAL',
    decision: 'SCHEDULE',
    classification: 'RETURN',
    specialty: null,
    health_insurance: null,
    priority: 'NO_PRIORITY',
    start_hour: "",
    end_hour: "",
    start_day: adicionaZero(String(new Date().getDate())),
    start_month: adicionaZero(String(new Date().getMonth() + 1)),
    start_year: String(new Date().getFullYear()),
    end_day: adicionaZero(String(new Date().getDate())),
    end_month: adicionaZero(String(new Date().getMonth() + 1)),
    end_year: String(new Date().getFullYear()),
    week_days: {
        "sun": false,
        "mon": false,
        "tue": false,
        "wed": false,
        "thu": false,
        "fri": false,
        "sat": false,
    },
    repeat_frequency: '1'
};

export default function RecurringPatientModal() {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState('1');
    const [recurringAppointment, setRecurringAppointment] = useState(INIT_APPOINTMENT);
    const [recurringAppointmentErr, setRecurringAppointmentErr] = useState({});
    const [healthInsurances, setHealthInsurances] = useState([]);
    const [specialtiesOptions, setSpecialtiesOptions] = useState([]);

    useEffect(() => {
        fetchSpecialtiesOptions();
        fetchHealthInsurances();
    }, []);

    const fetchSpecialtiesOptions = async (params={}) => {
        params = { ...params, limit: 50 }

        try {
            let res = await getSpecialties(params)

            setSpecialtiesOptions(res.data.results)
        } catch(err) {
            console.error('fetchSpecialtiesOptions ~ ', err)
        }
    }

    const fetchHealthInsurances = async (params={}) => {
        params = getParams({ ...params, limit: 50 })

        try {
            let res = await getHealthInsurances(params)
            setHealthInsurances(res.data.results)
        } catch (err) {
            console.error('fetchHealthInsurances ~ ', err)
            dispatch(AddAlert('Planos & convênios', 'Erro ao listar planos e convênios', AlertType.ERROR))
        }
    }

    const pages = {
        '1': <PageOne 
            recurringAppointment={recurringAppointment}
            recurringAppointmentErr={recurringAppointmentErr}
            setRecurringAppointment={setRecurringAppointment}
            setRecurringAppointmentErr={setRecurringAppointmentErr}
            setCurrentPage={setCurrentPage}
        />,
        '2': <PageTwo 
            recurringAppointment={recurringAppointment}
            recurringAppointmentErr={recurringAppointmentErr}
            setRecurringAppointment={setRecurringAppointment}
            setCurrentPage={setCurrentPage}
            healthInsurances={healthInsurances}
            specialtiesOptions={specialtiesOptions}
            fetchHealthInsurances={fetchHealthInsurances}
            fetchSpecialtiesOptions={fetchSpecialtiesOptions}
        />,
        '3': <PageThree
            recurringAppointment={recurringAppointment}
            recurringAppointmentErr={recurringAppointmentErr}
            setRecurringAppointment={setRecurringAppointment}
            setRecurringAppointmentErr={setRecurringAppointmentErr}
            setCurrentPage={setCurrentPage}
        />
    };

    const progress = (parseInt(currentPage) / Object.keys(pages).length) * 100;


    return <div className='RecurringPatientModal'>
        <ProgressBar progress={progress} />
        { pages[currentPage] }
    </div>;
}

const ProgressBar = ({ progress }) => {
    return (
        <div className="RecurringPatientModal-ProgressBar">
            <div className="RecurringPatientModal-ProgressBar-Fill" style={{ width: `${progress}%` }}></div>
        </div>
    );
};