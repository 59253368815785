import './NewThousandDaysProject.css'
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AddAlert, AlertType, Input } from "../../../../../../../component";
import api from '../../../../../../../helpers/api';
import { loaded, loading } from '../../../../../../../layout/redux/AppActions';
import { getParams } from '../../../../../../../utils';
import Button from '../../../../../../../newcomponents/button/button';
import { MODAL_INIT_PROPS } from '../../../../../../../newcomponents/modal/modal';
import SelectPatient from '../../../../../../../component/SelectPatient/SelectPatient';
import NewSelect from '../../../../../../../newcomponents/form/select/select';
import { stringifyCollaboratorOption } from '../../../../../../../utils/stringifyCollaboratorOption';
import { useApp } from '../../../../../../../layout/App';
import { getCollaborators } from '../../../../../../collaborators/http';


export default function NewThousandDaysProject({ setModal=() => null, fetchDoctorProjects=() => null }) {
    const dispatch = useDispatch()
    const searchTimeout = useRef(null)
    const { currentHealthPlaceUser } = useApp()
    const [selectedPatient, setSelectedPatient] = useState({})
    const [healthProfessional, setHealthProfessional] = useState({})
    const [healthProfessionalsOptions, setHealthProfessionalsOptions] = useState([])

    useEffect(() => {
        fetchHealthProfessionalsOptions()
    }, [])

    const handleSelectPatient = (selectedPatientCursor) => {
        setSelectedPatient(selectedPatientCursor)
    }

    const handleSelectHealthProfessional = (event) => {
        setHealthProfessional(healthProfessionalsOptions?.find(({ id }) => id === event.target.selected ))
    }


    const openNewProject = async (selectedPatient) => {
        let params = {}
        let payload = {
            "patient_id": selectedPatient?.id,
            "health_place_user__person_id": healthProfessional?.id
        }

        dispatch(loaded())
        await api.post(`/thousanddays/project/${getParams(params)}`, payload)
            .then(() => {
                fetchDoctorProjects({ offset: 0 })
                dispatch([loaded(), AddAlert('Projeto 1000 Dias', 'Projeto criado com sucesso', AlertType.SUCCESS)])
                setModal(MODAL_INIT_PROPS)
            })
            .catch(err => {
                err.response.data.error === 'only woman can create a thousand days project'
                ? dispatch([loaded(), AddAlert('Projeto 1000 Dias', 'Projeto exclusivo para pacientes do gênero feminino', AlertType.ERROR)])
                : dispatch([loaded(), AddAlert('Projeto 1000 Dias', 'Falha ao criar projeto do paciente, tente novamente em breve', AlertType.ERROR)])
            })
    }
    
    const handleSubmit = async () => {
        dispatch(loading())
        await openNewProject(selectedPatient)
    }

    const handleSearchHealthProfessionalsOptions = event => {
		if (searchTimeout.current)
			clearTimeout(searchTimeout.current)

            searchTimeout.current = setTimeout(() => {
                fetchHealthProfessionalsOptions({ name_cpf_or_email: event.target.value })
		}, 400, event.target.value)
	}

    const fetchHealthProfessionalsOptions = async (params={}) => {
        await getCollaborators({
            ...params,
            health_place: currentHealthPlaceUser?.health_place?.id || null,
            limit: 500,
            profile__in: 'DOCTOR%2CDENTIST%2CPSYCHOLOGIST%2CNUTRITIONIST%2CNURSE',
            has_person: true
        })
            .then(res => setHealthProfessionalsOptions(res.data.results.map((instance) => stringifyCollaboratorOption(instance))))
            .catch(err => {
                console.error('fetchHealthProfessionalsOptions ~ ', err)
                setHealthProfessionalsOptions([])
            })
    }

    return <section className="NewThousandDaysProject">
        <div>
            <div>
                <b>Profissional</b>
                <NewSelect
                    label=''
                    options={healthProfessionalsOptions}
                    selected={healthProfessional?.id}
                    optionStrKey='str'
                    onSelect={handleSelectHealthProfessional}
                    filterNode={<div className='ManageTeamEdgesModal-NewSelect'>
                        <Input 
                            placeholder='Pesquisar por e-mail, nome ou cpf'
                            action={handleSearchHealthProfessionalsOptions}
                            actionFocus={()=> fetchHealthProfessionalsOptions()}
                        />
                    </div>
                    }
                />
            </div>
            <SelectPatient 
                selectedPatient={selectedPatient}  
                handleSelectPatient={handleSelectPatient}
                fixedFilters={{ sex: 'F' }} 
            />
        </div>
        <div className='NewThousandDaysProject-BtnBox'>
            <Button
                onClick={handleSubmit}
                label='Prosseguir'
                disabled={!selectedPatient?.id || !healthProfessional?.id}
            />
        </div>
    </section>
}