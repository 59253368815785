import NewSelect from '../../../../../../../newcomponents/form/select/select';
import './CallPatientModal.css'
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router-dom'
import NewButton from '../../../../../../../newcomponents/button/button';
import { getAppointmentCallPanelService, getAppointmentServiceLocation } from '../../../../../../configuration/subcomponents/HealthUnitConfig/HealthPlaceInstitutionalMenu/http';
import { uniqueIDs } from '../../../../../../../utils/uniqueIDs';
import { AddAlert, AlertType, Input } from '../../../../../../../component';
import { useDispatch } from 'react-redux';
import { loaded, loading } from '../../../../../../../layout/redux/AppActions';
import { createCallPanelServiceTicket } from '../../../../../http';

export default function CallPatientModal({ appointment, extraAction=async () => null }) {
    const history = useHistory()
    const dispatch = useDispatch()
    const searchTimeout = useRef(null)
    const [holdingTicket, setHoldingTicket] = useState({ "call_panel_service": null, "attendance": appointment })
    const [callPanelServicesOptions, setCallPanelServicesOptions] = useState([])
    const [serviceLocationOptions, setServiceLocationOptions] = useState([])

    useEffect(() => {
        initFetchCallPainelServicesOptions()
        initFetchServiceLocationsOptions()
    }, [])

    const initFetchCallPainelServicesOptions = async () => {
        let callPainels = await fetchCallPainelServicesOptions()
        if (callPainels?.length) {
            setHoldingTicket(prev => ({ ...prev, "call_panel_service": callPainels[0] }))
        }
    }

    const fetchCallPainelServicesOptions = async (params={}) => {
        params = { ...params, active: true }

        try {
            let res = await getAppointmentCallPanelService(params)
            setCallPanelServicesOptions(res.data.results)

            return res.data.results
        } catch (err) {
            setCallPanelServicesOptions([])
            console.error('fetchCallPainelServicesOptions ~ ', err)
            return []
        }
    }

    const initFetchServiceLocationsOptions = async () => {
        let serviceLocations = await fetchServiceLocationsOptions()
        if (serviceLocations?.length) {
            setHoldingTicket(prev => ({
                ...prev,
                "attendance": {
                    ...prev.attendance, 
                    "service_location": appointment?.service_location
                }
            }))
        }
    }

    const fetchServiceLocationsOptions = async (params={}) => {
        params = { ...params, active: true }

        try {
            let res = await getAppointmentServiceLocation(params)
            setServiceLocationOptions(res.data.results)

            return res.data.results
        } catch (err) {
            setServiceLocationOptions([])
            console.error('fetchServiceLocationsOptions ~ ', err)
            return []
        }
    }

    const handleSearchServiceLocations = (event) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)

        searchTimeout.current = setTimeout(() => {
            fetchServiceLocationsOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }

    const handleCallPanelServices = (event) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)

        searchTimeout.current = setTimeout(() => {
            fetchCallPainelServicesOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }

    const handleSubmit = async () => {
        dispatch(loading())
            try {
                let payload = {
                    "call_panel_service_id": holdingTicket?.call_panel_service?.id,
                    "attendance_id": holdingTicket?.attendance?.id,
                    "attendance__service_location_id": holdingTicket?.attendance?.service_location?.id
                }
                await createCallPanelServiceTicket(payload)
                await extraAction()
                history.push('/atendimentos/paineis-de-chamada')
                dispatch(AddAlert('Chamar Paciente', 'Paciente chamado com sucesso!', AlertType.SUCCESS))
            } catch (err) {
                dispatch(AddAlert('Chamar Paciente', 'Falha ao chamar paciente!', AlertType.ERROR))
                console.error('CallPatientModal ~ handleSubmit ~ createCallPanelServiceTicket ~ ', err)
            }
        dispatch(loaded())
    }

    return <div className='CallPatientModal'>
        <div className='CallPatientModal-NewSelect'>
            <span><b className='CallPatientModal-Asterisk'>*</b> Painel de chamada</span>
            <NewSelect 
                onSelect={event => {
                    setHoldingTicket(prev => ({
                        ...prev,
                        "call_panel_service": prev?.call_panel_service?.id !== event.target.selected
                            ? callPanelServicesOptions?.find(({ id }) => id === event.target.selected)
                            : ''
                    }))
                    fetchCallPainelServicesOptions({ offset: 0 })
                }}     
                canBeEmpty={false}
                options={uniqueIDs(holdingTicket?.call_panel_service?.id, callPanelServicesOptions)}
                optionRefKey='id'
                optionStrKey='name'
                selected={holdingTicket?.call_panel_service?.id || ''}
                filterNode={<div className='NewAppointmentModal-NewSelect'>
                    <Input 
                        placeholder='Pesquisar por nome'
                        action={handleCallPanelServices}
                        actionFocus={()=> fetchCallPainelServicesOptions()}
                    />
                </div>
                }
                disabled={!callPanelServicesOptions?.length}
            />
        </div>
        <div className='CallPatientModal-NewSelect'>
            <span>Local de Atendimento</span>
            <NewSelect 
                onSelect={event => {
                    setHoldingTicket(prev => ({
                        ...prev,
                        "attendance": {
                            ...prev.attendance, 
                            "service_location": prev?.attendance?.service_location?.id !== event.target.selected
                                ? serviceLocationOptions?.find(({ id }) => id === event.target.selected)
                                : ''
                        }
                    }))
                    fetchServiceLocationsOptions({ offset: 0 })
                }}     
                canBeEmpty={false}
                options={uniqueIDs(holdingTicket?.attendance?.service_location?.id, serviceLocationOptions)}
                optionRefKey='id'
                optionStrKey='name'
                selected={holdingTicket?.attendance?.service_location?.id || ''}
                filterNode={<div className='NewAppointmentModal-NewSelect'>
                    <Input 
                        placeholder='Pesquisar por nome'
                        action={handleSearchServiceLocations}
                        actionFocus={()=> fetchServiceLocationsOptions()}
                    />
                </div>
                }
            />
        </div>
        <div className='CallPatientModal-BtnBox'>
            <NewButton 
                label='Chamar'
                onClick={handleSubmit}
            />
        </div>
    </div>
}