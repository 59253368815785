import './HealthPlaceUserUnitWrapper.css'
import React, { useEffect, useState } from "react";
import { AddAlert, adicionaZero, AlertType, IcoBin, IcoEstetoscopio, IcoPencil, MaskCNPJ } from '../../../../../component';
import AgeGroupIco from '../../../../../component/icon/age_group_ico';
import { HospitalIcon } from '../../../../../component/icon/hospital';
import WarningIcon from '../../../../../component/icon/warning';
import IcoTeam from '../../../../../component/icon/team';
import { BabyFeetIcon } from '../../../../../component/icon/baby_feet';
import ManageTeam from '../ManageTeam/ManageTeam';
import SaveCollaborator from '../SaveCollaborator/SaveCollaborator';
import RemoveCollaborator from '../RemoveCollaborator/RemoveCollaborator';
import IcoBlockCalendar from '../../../../../component/icon/calendar_block';
import { Calendar } from '../../../../../component/calendar/calendario';
import api from '../../../../../helpers/api';
import { useHistory } from 'react-router-dom';
import EditSchedule from './subcomponents/EditSchedule/EditSchedule';
import Modal, { MODAL_INIT_PROPS } from '../../../../../newcomponents/modal/modal';
import { ConfirmNotificate } from './subcomponents/EditSchedule/subcomponents/ConfirmNotificate/ConfirmNotificate';
import { useDispatch } from 'react-redux';
import { useApp } from '../../../../../layout/App';

const { PROFILE_CHOICES, months } = require('../../../../../component/form/select/options.json')

const getCouncils = (councils=[]) => councils.map(councilCursor => ` | ${councilCursor?.council} ${councilCursor?.nu_crm}/${councilCursor?.uf_crm}`).join('')

const defaultUser = require('../../../../../media/DefaultUser.png')

export default function HealthPlaceUserUnitWrapper({ holdingHealthPlaceUser=null, setModalInfo=()=>null, healthPlace=null, currentHealthPlaceUser=null }) {
    const person = holdingHealthPlaceUser?.user?.person || {}
    const [schedulingStats, setSchedulingStats] = useState({})
    const [calendarCursor, setCalendarCursor] = useState({ yearSearchCursor: new Date().getFullYear(), monthSearchCursor: new Date().getMonth(), selectedDay: new Date() })
    const [filledTimesOfTheDay, setFilledTimesOfTheDay] = useState({ data: '', horarios: [] })
    const [loading, setLoading] = useState(false)
    const [calendarDropDown, setCalendarDropdown] = useState(false)
    const history = useHistory()
    const todayDate = new Date()
    const [secondaryModalInfo, setSecondaryModalInfo] = useState(MODAL_INIT_PROPS)
    const dispatch = useDispatch()
    const { onlineUsers } = useApp()
    const [isOnline, setIsOnline] = useState()

    useEffect(() => {
        const yearSearch = todayDate.getFullYear()
        const monthSearch = todayDate.getMonth()
        const daySearch = todayDate.getDate() 

        setCalendarCursor({ yearSearchCursor: yearSearch, monthSearchCursor: monthSearch, selectedDay: todayDate})
        
        fetchMonthSchedulesStats({ 
            yearSearchCursor: adicionaZero(yearSearch), 
            monthSearchCursor: adicionaZero(monthSearch + 1) 
        })

        let timesOfTheDay = holdingHealthPlaceUser?.next_ten_available
        let filteredTimesOfTheDay = timesOfTheDay.filter(appointment => appointment.status === 'disponivel')


        setFilledTimesOfTheDay({data: `${yearSearch}-${adicionaZero(monthSearch+1)}-${adicionaZero(daySearch)}`, horarios: filteredTimesOfTheDay})
    }, [holdingHealthPlaceUser])

    useEffect(() => {
        const userIsOnline = onlineUsers[holdingHealthPlaceUser.user.id]
        setIsOnline(userIsOnline)

    }, [onlineUsers, holdingHealthPlaceUser.user.id])

    const confirmSubmission = (user) => {
        setSecondaryModalInfo({ 
            open: true,
            title: 'Confirmar Envio',
            content: <ConfirmNotificate 
                        user={user}
                        setSecondaryModalInfo={setSecondaryModalInfo}
                        sendIncompleteProfileEmail={sendIncompleteProfileEmail}
                    />,
          })
    }

    const sendIncompleteProfileEmail = async (userId) => {
        const response = await api.post('auth/send_incomplete_email/', {
            user_id: userId
        })
        if (response.status === 200) {
            dispatch(AddAlert('Lembrete enviado', 'O lembrete foi enviado com sucesso.', AlertType.SUCCESS))
            setSecondaryModalInfo(MODAL_INIT_PROPS)
        } else {
            dispatch(AddAlert('Lembrete não enviado', 'Houve um erro ao enviar o lembrete, tente novamente.', AlertType.ERROR))
        }
    }

    const fetchFilledTimesOfTheDay = async ({ yearSearchCursor, monthSearchCursor, daySearchCursor }) => {
        let date = `${yearSearchCursor}-${monthSearchCursor}-${daySearchCursor}`

        if (person.id) {
            await api.get(`schedule/doctor/${person.id}/day/?date=${date}`)
            .then(res => {                
                let appointments = res.data
                let filteredAppointments = appointments.filter(appointment => appointment.status === 'disponivel')
                setFilledTimesOfTheDay({ data: date, horarios: filteredAppointments })         
            })
            .catch(error => {
                console.error('fetchFilledTimesOfTheDay ~', error)
            })
        }
    }

    const renderNextSelectedDay = ({ yearSearchCursor, monthSearchCursor, daySearchCursor }) => {
        fetchFilledTimesOfTheDay({ yearSearchCursor, monthSearchCursor, daySearchCursor })
        setCalendarDropdown(false)
    }

    const fetchMonthSchedulesStats = async ({ yearSearchCursor, monthSearchCursor }) => {
        let date = `${yearSearchCursor}-${monthSearchCursor}-01`
        
        if (person.id) {
            setLoading(true)
            await api.get(`/schedule/event/${person.id}/monthIfHasEvent/?date=${date}`)
                .then(res => {
                    setLoading(false)
                    setSchedulingStats({ [yearSearchCursor]: { [monthSearchCursor]: filterAvailableAppointments(res.data.month) } })
                })
                .catch(error => {
                    setLoading(false)
                    console.error('fetchMonthSchedulesStats ~ ', error)
                })
        }
    }

    const renderNextSelectedMonth = async ({ monthSearchCursor, yearSearchCursor }) => {
        await fetchMonthSchedulesStats({ yearSearchCursor, monthSearchCursor })
    }

    const formatDate = (date) => {
        const [year, month, day] = date.split('-');
        return `${day} de ${months.find(item => item.id === month)?.name} de ${year}`
    }

    const handleSeeAppointment = (appointment) => {
        const start = appointment?.fields?.start?.slice(11,16) || appointment?.start?.slice(11,16) || '-'
        const end = appointment?.fields?.end?.slice(11,16) || appointment?.end?.slice(11,16) || '-'  
        const date = appointment?.fields?.start?.split("T")

        setModalInfo(prev => ({ 
            ...prev, 
            open: true,
            title: `Editar horário | ${date} | ${start} - ${end}`,
            content: <EditSchedule initSchedulingInfo={appointment} setModalInfo={setModalInfo} doctor={holdingHealthPlaceUser} setFilledTimesOfTheDay={setFilledTimesOfTheDay}/>
        }))
    }

    function filterAvailableAppointments(appointmentsData) {
        const filteredData = {};
            for (const day in appointmentsData) {
                const statuses = appointmentsData[day];
                filteredData[day] = {
                disponivel: statuses.disponivel || 0,
                ocupado: 0,
                indisponivel: 0,
                events: statuses.disponivel || 0  
            }
        }
    
        return filteredData;
    }

    return <div className='HealthPlaceUserUnitWrapper'>
        <Modal {...secondaryModalInfo} dismissFn={() => setSecondaryModalInfo(MODAL_INIT_PROPS)}/>
        <div className='HealthPlaceUserUnitWrapper-HoldingInfo'>
        <div className={`HealthPlaceUserUnitWrapper-User-Status ${isOnline ? 'online' : 'offline'}`}/>
        <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Header'>
                <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile'>
                    <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-Avatar'>
                        <div className='img'>
                            <img
                                className='HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-Avatar-Img'
                                alt='Paciente foto de perfil'
                                src={person?.avatar_img_url || defaultUser}
                            />
                        </div>
                    </div>
                    <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-InfoBox'>

                        <b className='HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-InfoBox-Name'>
                             {person?.name || holdingHealthPlaceUser?.user?.email} 
                            {person?.name 
                            ?  null 
                            : <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-InfoBox-Warning' title='Cadastro Incompleto.'>
                                <button 
                                    className='HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-InfoBox-Warning-Btn' 
                                    onClick={() => confirmSubmission(holdingHealthPlaceUser?.user)}>
                                        <WarningIcon width={25} height={25} color='orange' />
                                </button>
                            </div>}
                        </b>

                        <span className='HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-InfoBox-AltText'>
                            {PROFILE_CHOICES.find(({ id }) => id === holdingHealthPlaceUser?.profile)?.name} {getCouncils(person?.councils)}
                        </span>

                    </div>
                </div>
            </div>
            <div className='HealthPlaceUserUnitWrapper-HoldingInfo-ActionRow'>

                {holdingHealthPlaceUser?.profile === 'DOCTOR' || holdingHealthPlaceUser?.profile === 'DENTIST' ||
                holdingHealthPlaceUser?.profile === 'PSYCHOLOGIST' || holdingHealthPlaceUser?.profile === 'NUTRITIONIST'
                ? <div className={`HealthPlaceUserUnitWrapper-HoldingInfo-ActionRow-Btn ${person?.name ? '': 'disabled'}`} onClick={person?.name ? () => history.push(`/atendimentos/agendamento?health_place_user__user__person=${person?.id}`) : null}>
                        <IcoEstetoscopio /> <b>Agenda</b>
                </div>
                : null
                }

                {/* {holdingHealthPlaceUser?.profile === 'DOCTOR' || holdingHealthPlaceUser?.profile === 'DENTIST' ||
                holdingHealthPlaceUser?.profile === 'PSYCHOLOGIST' || holdingHealthPlaceUser?.profile === 'NUTRITIONIST'
                ? <div className='HealthPlaceUserUnitWrapper-HoldingInfo-ActionRow-Btn' 
                    onClick={() => setModalInfo({
                        open: true,
                        title: 'Gerenciar equipe',
                        content: (
                            <ManageTeam
                                leader={holdingHealthPlaceUser}
                                setModalInfo={setModalInfo}
                            />
                        )})}>
                    <IcoTeam /> <b>Equipe</b>
                  </div> : null
                } */}

                {currentHealthPlaceUser?.profile === "ADMINISTRATOR"
                ? <>
                    <div className='HealthPlaceUserUnitWrapper-HoldingInfo-ActionRow-Btn'
                        onClick={() => setModalInfo({ 
                            open: true, 
                            title: 'Editar colaborador', 
                            content: <SaveCollaborator 
                                healthPlaceUser={holdingHealthPlaceUser} 
                                healthPlace={healthPlace} 
                                /> 
                        })}>
                            <IcoPencil /> <b>Editar</b>
                    </div>

                    <div className='HealthPlaceUserUnitWrapper-HoldingInfo-ActionRow-Btn' 
                        onClick={() => setModalInfo({ open: true, title: 'Remover colaborador', content: <RemoveCollaborator holdingColaborator={holdingHealthPlaceUser} /> })}>
                        <IcoBin /> <b>Excluir</b>
                    </div>
                </> : null
}
            </div>
            
            <div className='HealthPlaceUserUnitWrapper-HoldingInfo-HorizontalSeparator'></div>
            <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Footer'>
                <div>

                    {
                        holdingHealthPlaceUser?.health_place?.id
                        ? <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Footer-Subtitle'>
                            <HospitalIcon style={{ width: '18px' }} /> <span>{holdingHealthPlaceUser?.health_place?.name || '-'} {holdingHealthPlaceUser?.health_place?.cnpj ? `| CNPJ ${MaskCNPJ(holdingHealthPlaceUser?.health_place?.cnpj)}` : null}</span>
                        </div>
                        : null
                    }

                    <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Footer-Subtitle'>
                        <AgeGroupIco style={{ width: '18px' }} /> <span>Atendimento para idades entre {holdingHealthPlaceUser.min_age_allowed} à {holdingHealthPlaceUser?.max_age_allowed}</span>
                    </div>
                    
                </div>
                <div>

                    {
                        holdingHealthPlaceUser?.enabled_thousand_days
                        ? <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Footer-Subtitle'>
                            <BabyFeetIcon style={{ width: '18px' }} /> <span>1000 dias</span>
                        </div>
                        : null
                    }
                    {
                        holdingHealthPlaceUser?.enabled_preceptorship
                        ? <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Footer-Subtitle'>
                            <IcoEstetoscopio style={{ width: '18px' }} /> <span>Preceptoria</span>
                        </div>
                        : null
                    }

                </div>
            </div>
        </div>
        <div className='HealthPlaceUserUnitWrapper-Separator'></div>
        <div className='HealthPlaceUserUnitWrapper-NextTenAvailable'>

            {holdingHealthPlaceUser?.profile === 'DOCTOR' || holdingHealthPlaceUser?.profile === 'DENTIST' ||
            holdingHealthPlaceUser?.profile === 'PSYCHOLOGIST' || holdingHealthPlaceUser?.profile === 'NUTRITIONIST'
            ? <span className='HealthPlaceUserUnitWrapper-NextTenAvailable-Title'>{person?.name ? 'Próximos horários disponíveis' : 'Complete o cadastro para ver os dados completos.'}</span>
            : null}

            {(holdingHealthPlaceUser?.profile === 'DOCTOR' || holdingHealthPlaceUser?.profile === 'DENTIST' ||
            holdingHealthPlaceUser?.profile === 'PSYCHOLOGIST' || holdingHealthPlaceUser?.profile === 'NUTRITIONIST') && person?.name
            ? <div className='HealthPlaceUserUnitWrapper-NextTenAvailable-Modal'>
                <button onClick={() =>  setCalendarDropdown(prev => !prev)} className='HealthPlaceUserUnitWrapper-NextTenAvailable-CalendarBtn'>
                    <span>{formatDate(filledTimesOfTheDay.data)}</span>
                </button>
                <div className={calendarDropDown ? 'HealthPlaceUserUnitWrapper-NextTenAvailable-OpenedModal' : 'HealthPlaceUserUnitWrapper-NextTenAvailable-ClosedModal'}>
                    {calendarDropDown && (
                        <Calendar 
                                name='data'
                                renderNextSelectedDay={renderNextSelectedDay}
                                renderNextSelectedMonth={renderNextSelectedMonth}
                                value={schedulingStats}
                                loading={loading}
                                calendarCursor={calendarCursor}
                                setCalendarCursor={setCalendarCursor}  
                            />
                    )}
                </div>
            </div>
            : null}

            <div className='HealthPlaceUserUnitWrapper-NextTenAvailable-List'>
                {holdingHealthPlaceUser?.profile === 'DOCTOR' || holdingHealthPlaceUser?.profile === 'DENTIST' ||
                holdingHealthPlaceUser?.profile === 'PSYCHOLOGIST' || holdingHealthPlaceUser?.profile === 'NUTRITIONIST'
                ? (filledTimesOfTheDay?.horarios.length

                ? filledTimesOfTheDay?.horarios?.map(instance => {
                        const start = instance?.fields?.start.split('T')[1].split(':').slice(0, 2).join(':')
                        const end = instance?.fields?.end.split('T')[1].split(':').slice(0, 2).join(':')
                        return <div className='HealthPlaceUserUnitWrapper-NextTenAvailable-Unit'>
                                    <span>{start} - {end}</span>
                                    <button 
                                        className='HealthPlaceUserUnitWrapper-NextTenAvailable-Btn'
                                        onClick={() => handleSeeAppointment(instance) }>
                                            Ver Horário
                                    </button>
                                </div> 
                    })

                    : <div className='HealthPlaceUserUnitWrapper-NextTenAvailable-Empty-Message'>
                        Não há horários disponíveis
                    </div>)

                : <div className='HealthPlaceUserUnitWrapper-NextTenAvailable-NoCalendar'>
                    <IcoBlockCalendar style={{'color': '#aaa', 'width': '75px', 'height': '75px'}}/>
                    <span className=''>Usuário sem agenda</span>
                </div>
            }
            </div>
        </div>
    </div>
}
