import './InventoryMenuPage.css'
import React, { useState } from "react";
import { useParams, useHistory } from 'react-router-dom';
import InventoryMaterials from './subcomponents/InventoryMaterials/InventoryMaterials';
import { MODAL_INIT_PROPS } from '../../newcomponents/modal/modal';

export default function InventoryMenuPage() {
    const history = useHistory()
    const { tab } = useParams()

    const pages = {
        'materiais': <InventoryMaterials />
    }

    return <div>
        {/* <p><strong>Estoque</strong></p> */}
        <div className='InventoryMenuPage'>
			<div className='InventoryMenuPage-Action'>
                <div
					className={`InventoryMenuPage-Action-SelectorBtn ${tab === 'materiais' ? 'active' : ''}`}
					onClick={() => history.push(`/estoque/materiais`)}
				>
					{/* icon */} Materiais
				</div>
			</div>
			<div className={`InventoryMenuPage-Wrapper InventoryMenuPage-Content-${tab}`}>
                {pages[tab] || null}
			</div>
		</div>
    </div>
}