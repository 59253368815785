import React from "react"

export default function CrossMarkIcon({ style={} }) {
    return <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M2350 4844 c-14 -2 -65 -9 -115 -15 -123 -15 -367 -78 -499 -129
            -735 -285 -1270 -922 -1425 -1695 -29 -148 -41 -275 -41 -454 0 -1034 705
            -1947 1704 -2206 239 -62 304 -69 586 -69 272 0 350 8 555 60 814 205 1449
            832 1660 1639 51 193 66 299 72 512 8 257 -12 433 -73 663 -210 794 -846 1426
            -1639 1628 -221 56 -359 72 -590 71 -93 -1 -181 -3 -195 -5z m440 -325 c823
            -102 1483 -685 1685 -1489 53 -208 70 -501 41 -716 -46 -353 -181 -679 -395
            -959 -64 -85 -232 -256 -321 -328 -373 -301 -817 -449 -1305 -434 -292 9 -521
            63 -782 187 -612 291 -1028 870 -1112 1550 -14 109 -14 351 -1 460 84 677 502
            1259 1112 1549 187 89 405 154 604 180 99 12 375 13 474 0z"/>
            <path d="M1875 3345 c-22 -8 -52 -28 -67 -44 -27 -30 -50 -92 -43 -114 2 -7 6
            -25 10 -42 5 -22 82 -106 283 -307 l277 -278 -277 -277 c-201 -202 -278 -286
            -283 -308 -4 -16 -8 -35 -10 -42 -7 -23 17 -84 46 -117 27 -32 95 -60 123 -51
            6 2 25 6 41 10 22 5 106 82 308 283 l277 277 278 -277 c152 -152 287 -281 299
            -287 12 -6 43 -11 70 -11 90 0 153 63 153 153 0 27 -5 58 -11 70 -6 12 -135
            147 -287 299 l-277 278 277 277 c201 202 278 286 283 308 4 17 8 35 10 41 9
            28 -19 96 -51 123 -33 29 -94 53 -117 46 -7 -2 -25 -6 -42 -10 -22 -5 -106
            -82 -307 -283 l-278 -277 -277 277 c-201 200 -286 278 -308 283 -16 4 -37 8
            -45 10 -8 2 -33 -2 -55 -10z"/>
        </g>
    </svg>
}
