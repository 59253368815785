import './PersonalDataConfigModal.css'
import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { AddAlert, AlertType, Input } from '../../../../../../../../component'
import NewButton from '../../../../../../../../newcomponents/button/button'
import api from '../../../../../../../../helpers/api'
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions'
import Toggle from '../../../../../../../../newcomponents/toggle/toggle'
import { useApp } from '../../../../../../../../layout/App'
import { useConfiguration } from '../../../../../../configuration'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'

const strpHealthPlaceUser = (healthPlaceUserInstance) => {
    return {
        "enabled_thousand_days": healthPlaceUserInstance?.enabled_thousand_days || false,
        "enabled_preceptorship": healthPlaceUserInstance?.enabled_preceptorship || false,
        "max_age_allowed": healthPlaceUserInstance?.max_age_allowed || 100,
        "min_age_allowed": healthPlaceUserInstance?.min_age_allowed || 0,
    }
}
export default function PersonalDataConfigModal({fetchExtraAction, configProps}) {
    const { currentHealthPlaceUser, isHealthProfessional, loged } = useApp()
    const { setModalProps } = useConfiguration()
    const dispatch = useDispatch()
    const inputMinAge = useRef(null)
    const inputMaxAge = useRef(null)
    const [healthPlaceUser, setHealthPlaceUser] = useState(strpHealthPlaceUser(configProps))

    const handleChangeAgeFields = ({ name, value }) => {
        setHealthPlaceUser(prev => ({ ...prev, [name]: value }))
    }

    const validateSearch = () => {
        if (isHealthProfessional) {
            const { min_age_allowed, max_age_allowed } = healthPlaceUser
            const classError = 'erro-input'
    
            if (min_age_allowed > max_age_allowed) {
                inputMinAge.current.className = classError
                inputMaxAge.current.className = classError
                return { message: 'Campo Idade: "até" necessita ser maior que o "de".', status: false }
            } else {
                inputMinAge.current.className = ''
                inputMaxAge.current.className = ''
            }
        }
        return { message: '', status: true }
    }

    const handleSubmit = async () => {
        const validateFileds = validateSearch()

        if (!validateFileds.status) {
            return dispatch(AddAlert('Configurações', validateFileds.message, AlertType.ERROR, 5000))
        }

        const payload = {
            "max_age_allowed": healthPlaceUser.max_age_allowed, 
            "min_age_allowed": healthPlaceUser.min_age_allowed,
            "enabled_thousand_days": healthPlaceUser.enabled_thousand_days, 
            "enabled_preceptorship": healthPlaceUser.enabled_preceptorship
        }

        dispatch(loading())
        
        api.patch(`health_place_user/user/${currentHealthPlaceUser?.id}/`, payload)
            .then(async (response) => {
                await loged()
                dispatch([loaded(),AddAlert('Configurações', 'Configurações atualizadas com sucesso!', AlertType.SUCCESS, 5000)])
                setModalProps(MODAL_INIT_PROPS)
                fetchExtraAction(response)
            })
            .catch(() => {
                dispatch([
                    loaded(),
                    AddAlert('Configurações', 'Não foi possível atualizar as configurações', AlertType.ERROR, 5000),
                ])
            })
    }

    return (
    <div>
        {
            isHealthProfessional
            ? <section>
                <div>
                    <span className='HealthPlaceUserConfig-Title'>Idade de atendimento</span>
                </div>
                <div className='HealthPlaceUserConfig-InputRatio'>
                    <div>
                        <b>De (Anos)</b>
                        <Input
                            name='min_age_allowed'
                            autoComplete='false'
                            type='number'
                            value={healthPlaceUser.min_age_allowed}
                            min='0'
                            max='125'
                            action={e => {
                                const age = Math.abs(e.target.value).toString()
                                handleChangeAgeFields({ name: 'min_age_allowed', value: age })
                            }}
                            ref={inputMinAge}
                        />
                    </div>
                    <div>
                        <b>Até (Anos)</b>
                        <Input
                            name='max_age_allowed'
                            autoComplete='false'
                            type='number'
                            value={healthPlaceUser.max_age_allowed}
                            min='0'
                            max='125'
                            action={e => {
                                const age = Math.abs(e.target.value).toString()
                                handleChangeAgeFields({ name: 'max_age_allowed', value: age })
                            }}
                            ref={inputMaxAge}
                        />
                    </div>
                </div>
            </section> 
            : null
        }
        <Toggle 
            id='enabled_thousand_days'
            label='Acompanhamento gestacional (1000 Dias)'
            value={healthPlaceUser.enabled_thousand_days}
            onChange={(value) => setHealthPlaceUser(prev => ({ ...prev, "enabled_thousand_days": value }))}
        />
        <Toggle 
            id='enabled_preceptorship'
            label='Preceptoria'
            value={healthPlaceUser.enabled_preceptorship}
            onChange={(value) => setHealthPlaceUser(prev => ({ ...prev, "enabled_preceptorship": value }))}
        />
        <div className='HealthPlaceUserConfig-BtnBox'>
            <NewButton
                label='Salvar'
                onClick={handleSubmit}
            />
        </div>
    </div>
    )
}