import './HealthPlaceInstitutionalMenu.css'
import React, { useRef } from "react";
import HealthPlaceInstitutionalLegalData from './subcomponents/HealthPlaceInstitutionalLegalData/HealthPlaceInstitutionalLegalData';
import HealthPlaceInstitutionalLogo from './subcomponents/HealthPlaceInstitutionalLogo/HealthPlaceInstitutionalLogo';
import HealthPlaceInstitutionalAddress from './subcomponents/HealthPlaceInstitutionalAddress/HealthPlaceInstitutionalAddress';
import HealthPlaceInstitutionalSchedule from './subcomponents/HealthPlaceInstitutionalSchedule/HealthPlaceInstitutionalSchedule';
import HealthPlaceInstitutionalPlanAgreement from './subcomponents/HealthPlaceInstitutionalPlanAgreement/HealthPlaceInstitutionalPlanAgreement';
import HealthPlaceInstitutionalCoverageCities from './subcomponents/HealthPlaceInstitutionalCoverageCities/HealthPlaceInstitutionalCoverageCities';
import HealthPlaceInstitutionalAppearance from './subcomponents/HealthPlaceInstitutionalAppearance/HealthPlaceInstitutionalAppearance';
import HealthPlaceCallPanelService from './subcomponents/HealthPlaceCallPanelService/HealthPlaceCallPanelService';
import HealthPlaceServiceLocations from './subcomponents/HealthPlaceServiceLocations/HealthPlaceServiceLocations';

export default function HealthPlaceInstitutionalMenu() {
    const pageRef = useRef(null)

    return (
    <div className='HealthPlaceInstitutionalMenu' ref={pageRef}>
		<div className='HealthPlaceInstitutionalMenu-Col'>
			<div className='HealthPlaceInstitutionalMenu-Container'>
                <HealthPlaceInstitutionalLegalData />
			</div>
			<div className='HealthPlaceInstitutionalMenu-Container'>
                <HealthPlaceInstitutionalAddress />
			</div>
			<div className='HealthPlaceInstitutionalMenu-Container'>
                <HealthPlaceInstitutionalSchedule />
			</div>
            <div className='HealthPlaceInstitutionalMenu-Container'>
                <HealthPlaceCallPanelService />
            </div>
		</div>
		<div className='HealthPlaceInstitutionalMenu-Col'>
			<div className='HealthPlaceInstitutionalMenu-Container'>
                <HealthPlaceInstitutionalLogo />
			</div>
            <div className='HealthPlaceInstitutionalMenu-Container'>
                <HealthPlaceInstitutionalAppearance pageRef={pageRef}/>
            </div>
            <div className='HealthPlaceInstitutionalMenu-Container'>
                <HealthPlaceInstitutionalPlanAgreement />
            </div>
            <div className='HealthPlaceInstitutionalMenu-Container'>
                <HealthPlaceInstitutionalCoverageCities />
            </div>
            <div className='HealthPlaceInstitutionalMenu-Container'>
                <HealthPlaceServiceLocations />
            </div>
		</div>
    </div>
    )
}