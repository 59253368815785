import './ShowPatientData.css'

export default function ShowPatientData({patient=null, title=null}){
    let birthday_year, birthday_month, birthday_day;

    if (patient?.birthday) {
        [birthday_year, birthday_month, birthday_day] = patient.birthday.split('-');
    }


    return(
        <div className='Show-Patient-Data-Form-Container'>
            <p className='Show-Patient-Data-Form-Title'>{title}</p>

            <div className='Show-Patient-Data'>
                <div>
                    <label>Nome: </label>
                    <span className='Show-Patient-Data-Form-field'>{patient?.name ? patient.name : '-'}</span>
                </div>
                <div>
                    <label>Sexo: </label>
                    <span className='Show-Patient-Data-Form-field'>{patient?.sex ? patient.sex : '-'}</span>
                </div>
                <div>
                    <label>Data de Nascimento: </label>
                    <span className='Show-Patient-Data-Form-field'>{patient?.birthday ? birthday_day : '-'}</span>
                    <span className='Show-Patient-Data-Form-field'>{patient?.birthday ? birthday_month : '-'}</span>
                    <span className='Show-Patient-Data-Form-field'>{patient?.birthday ? birthday_year : '-'}</span>
                </div>
                <div>
                    <label>Nome da Mãe: </label>
                    <span className='Show-Patient-Data-Form-field'>{patient?.mother_name ? patient?.mother_name : '-'}</span>
                </div>
                <div>
                    <label>{patient?.document_type === 'CPF' ? 'CPF: ' : 'RG: '}</label>
                    <span className='Show-Patient-Data-Form-field'>{patient?.cpf ? patient.cpf : (patient?.rg ? patient.rg : '-')}</span>
                </div>
                <div>
                    <label>Prontuário: </label>
                    <span className='Show-Patient-Data-Form-field'>{patient?.medical_record_number ? patient?.medical_record_number : '-'}</span>
                </div>
                <div>
                    <label>Total de prontuários de atendimento: </label>
                    <span className='Show-Patient-Data-Form-field'>{patient?.attendance_count ? patient?.attendance_count : '-'}</span>
                </div>
                <div>
                    <label>Número de arquivos anexos: </label>
                    <span className='Show-Patient-Data-Form-field'>{patient?.document_count ? patient?.document_count : '-'}</span>
                </div>

            </div>
        </div>
    )
}