import './HealthPlaceInstitutionalSchedule.css'
import React from "react";
import HorizontalField from '../../../../../../../component/HorizontalField/HorizontalField';
import { useConfiguration } from '../../../../../configuration';
import EditHealthPlaceInstitutionalScheduleModal from './EditHealthPlaceInstitutionalScheduleModal/EditHealthPlaceInstitutionalScheduleModal';


export default function HealthPlaceInstitutionalSchedule() {
    const { institution, setModalProps } = useConfiguration()
    

    const strfTimetable = (first_start, first_end, second_start, second_end) => {
        const slashTime = '--:--'

        return <div>
            <p>Manhã {first_start?.substring(0, 5) || slashTime} - {first_end?.substring(0, 5) || slashTime}</p>
            <p>Tarde {second_start?.substring(0, 5) || slashTime} - {second_end?.substring(0, 5) || slashTime}</p>
        </div>
    }
    
    return <div className='HealthPlaceInstitutionalSchedule'>
        <div className='HealthPlaceInstitutionalSchedule-Header'>
            <div className='HealthPlaceInstitutionalSchedule-Header-Icon'>H</div>
            <div className='HealthPlaceInstitutionalSchedule-Header-Title'>Horário de Funcionamento</div>
        </div>
        <div className='HealthPlaceInstitutionalSchedule-Container'>
            <HorizontalField 
                label='Domingo' 
                content={
                    institution?.timetable?.sun_open 
                    ? strfTimetable(institution?.timetable?.sun_first_start, institution?.timetable?.sun_first_end, institution?.timetable?.sun_second_start, institution?.timetable?.sun_second_end) 
                    : 'Fechado'
                } 
            />
            <HorizontalField 
                label='Segunda' 
                content={
                    institution?.timetable?.mon_open 
                    ? strfTimetable(institution?.timetable?.mon_first_start, institution?.timetable?.mon_first_end, institution?.timetable?.mon_second_start, institution?.timetable?.mon_second_end) 
                    : 'Fechado'
                } 
            />
            <HorizontalField 
                label='Terça' 
                content={
                    institution?.timetable?.tue_open 
                    ? strfTimetable(institution?.timetable?.tue_first_start, institution?.timetable?.tue_first_end, institution?.timetable?.tue_second_start, institution?.timetable?.tue_second_end) 
                    : 'Fechado'
                } 
            />
            <HorizontalField 
                label='Quarta' 
                content={
                    institution?.timetable?.wed_open 
                    ? strfTimetable(institution?.timetable?.wed_first_start, institution?.timetable?.wed_first_end, institution?.timetable?.wed_second_start, institution?.timetable?.wed_second_end) 
                    : 'Fechado'
                }
            />
            <HorizontalField 
                label='Quinta' 
                content={
                    institution?.timetable?.thu_open 
                    ? strfTimetable(institution?.timetable?.thu_first_start, institution?.timetable?.thu_first_end, institution?.timetable?.thu_second_start, institution?.timetable?.thu_second_end) 
                    : 'Fechado'
                }
            />
            <HorizontalField 
                label='Sexta' 
                content={
                    institution?.timetable?.fri_open 
                    ? strfTimetable(institution?.timetable?.fri_first_start, institution?.timetable?.fri_first_end, institution?.timetable?.fri_second_start, institution?.timetable?.fri_second_end) 
                    : 'Fechado'
                }
            />
            <HorizontalField 
                label='Sábado' 
                content={
                    institution?.timetable?.sat_open 
                    ? strfTimetable(institution?.timetable?.sat_first_start, institution?.timetable?.sat_first_end, institution?.timetable?.sat_second_start, institution?.timetable?.sat_second_end) 
                    : 'Fechado'
                }
            />
        </div>
        <div className='HealthPlaceInstitutionalSchedule-ActionRow'>
            <div 
                className='HealthPlaceInstitutionalSchedule-ActionRow-Btn' 
                onClick={() => setModalProps(prev => ({
                    ...prev,
                    open: true,
                    title: 'Editar Instituição',
                    content: <EditHealthPlaceInstitutionalScheduleModal 
                        institution={institution} 
                        setModalProps={setModalProps} 
                    />, 
                    dismissText: '',
                }))}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}

