import React from "react"

export default function SendIcon({ style={}, className='' }) {
    return <svg style={style} className={className} viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M395 4759 c-222 -45 -395 -260 -395 -490 0 -32 54 -262 146 -627 81
            -317 167 -657 192 -754 l44 -178 917 0 c852 0 918 -1 951 -18 107 -53 107
            -211 0 -264 -33 -17 -99 -18 -951 -18 l-917 0 -44 -177 c-25 -98 -111 -438
            -192 -755 -92 -365 -146 -595 -146 -627 0 -238 176 -449 410 -491 77 -14 167
            -7 241 19 126 44 4189 1735 4238 1764 136 81 231 253 231 417 0 164 -95 336
            -231 417 -49 29 -4110 1719 -4239 1765 -66 23 -186 31 -255 17z"/>
        </g>
    </svg>
}
