import React from 'react'

export default function IcoSchedule({ style }) {
    return <svg style={style} className='icon-schedule' viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M918 5099 c-58 -30 -78 -79 -78 -188 l0 -89 -202 -4 c-176 -4 -211
            -8 -262 -26 -165 -61 -281 -175 -345 -340 l-26 -67 -3 -1570 c-2 -1134 0
            -1586 8 -1629 34 -175 173 -317 348 -355 38 -8 382 -11 1195 -11 l1142 0 44
            -95 c86 -187 222 -355 385 -476 96 -72 291 -170 396 -199 302 -83 606 -59 876
            69 143 68 245 140 355 250 428 428 489 1080 149 1587 -67 99 -232 264 -327
            327 l-72 48 -3 1027 -3 1027 -26 67 c-64 165 -180 279 -345 340 -51 18 -86 22
            -261 26 l-203 4 0 89 c0 70 -4 98 -20 129 -23 45 -80 80 -130 80 -50 0 -107
            -35 -130 -80 -16 -31 -20 -59 -20 -130 l0 -90 -270 0 -270 0 0 90 c0 112 -20
            159 -80 190 -50 25 -90 25 -140 0 -60 -31 -80 -78 -80 -190 l0 -90 -270 0
            -270 0 0 90 c0 71 -4 99 -20 130 -23 45 -80 80 -130 80 -50 0 -107 -35 -130
            -80 -16 -31 -20 -59 -20 -130 l0 -90 -270 0 -270 0 0 90 c0 112 -20 159 -80
            190 -49 25 -94 25 -142 -1z m-78 -669 c0 -71 4 -99 20 -130 23 -45 80 -80 130
            -80 50 0 107 35 130 80 16 31 20 59 20 130 l0 90 270 0 270 0 0 -90 c0 -71 4
            -99 20 -130 23 -45 80 -80 130 -80 50 0 107 35 130 80 16 31 20 59 20 130 l0
            90 270 0 270 0 0 -90 c0 -112 20 -159 80 -190 50 -25 90 -25 140 0 60 31 80
            78 80 190 l0 90 270 0 270 0 0 -90 c0 -112 20 -159 80 -190 50 -25 90 -25 140
            0 60 31 80 78 80 190 l0 90 163 0 c194 0 239 -11 302 -75 69 -68 75 -101 75
            -382 l0 -243 -1950 0 -1950 0 0 243 c0 282 6 314 75 382 63 64 102 73 298 74
            l167 1 0 -90z m3360 -1441 l0 -531 -27 6 c-16 3 -71 13 -123 21 -301 48 -618
            -26 -886 -205 -101 -68 -256 -223 -324 -324 -149 -223 -228 -492 -219 -745 l4
            -91 -1103 0 c-1086 0 -1103 0 -1142 20 -26 13 -47 34 -60 60 -20 39 -20 56
            -20 1180 l0 1140 1950 0 1950 0 0 -531z m-97 -818 c336 -88 596 -349 689 -691
            33 -122 33 -338 0 -460 -93 -342 -349 -599 -689 -692 -117 -32 -345 -32 -463
            0 -342 93 -599 348 -692 689 -31 112 -31 354 0 466 100 364 387 631 757 703
            98 19 297 12 398 -15z"/>
            <path d="M1599 3117 c-94 -63 -88 -193 12 -252 30 -18 52 -20 194 -20 142 0
            164 2 194 20 100 59 106 189 12 252 -32 22 -44 23 -206 23 -162 0 -174 -1
            -206 -23z"/>
            <path d="M2489 3117 c-94 -63 -88 -193 12 -252 30 -18 52 -20 194 -20 142 0
            164 2 194 20 100 59 106 189 12 252 -32 22 -44 23 -206 23 -162 0 -174 -1
            -206 -23z"/>
            <path d="M3379 3117 c-94 -63 -88 -193 12 -252 30 -18 52 -20 194 -20 142 0
            164 2 194 20 100 59 106 189 12 252 -32 22 -44 23 -206 23 -162 0 -174 -1
            -206 -23z"/>
            <path d="M718 2449 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 34 -17
            59 -20 195 -20 183 0 220 12 255 80 25 50 25 90 0 140 -35 69 -72 80 -257 80
            -137 -1 -163 -3 -195 -21z"/>
            <path d="M1608 2449 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 34 -17
            59 -20 195 -20 136 0 161 3 195 20 45 23 80 80 80 130 0 50 -35 107 -80 130
            -34 18 -58 20 -197 20 -137 -1 -163 -3 -195 -21z"/>
            <path d="M2498 2449 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 34 -17
            59 -20 195 -20 136 0 161 3 195 20 45 23 80 80 80 130 0 50 -35 107 -80 130
            -34 18 -58 20 -197 20 -137 -1 -163 -3 -195 -21z"/>
            <path d="M709 1767 c-94 -63 -88 -193 12 -252 30 -18 52 -20 194 -20 142 0
            164 2 194 20 100 59 106 189 12 252 -32 22 -44 23 -206 23 -162 0 -174 -1
            -206 -23z"/>
            <path d="M1599 1767 c-94 -63 -88 -193 12 -252 30 -18 52 -20 194 -20 142 0
            164 2 194 20 100 59 106 189 12 252 -32 22 -44 23 -206 23 -162 0 -174 -1
            -206 -23z"/>
            <path d="M3798 1879 c-23 -12 -46 -35 -58 -59 -19 -37 -20 -58 -20 -320 0
            -262 1 -283 20 -320 13 -26 34 -47 60 -60 37 -19 58 -20 320 -20 262 0 283 1
            320 20 45 23 80 80 80 130 0 50 -35 107 -80 130 -35 18 -59 20 -230 20 l-190
            0 0 190 c0 171 -2 195 -20 230 -37 73 -127 99 -202 59z"/>
        </g>
   </svg>
}

