import './EditServiceLocationsOptionsModal.css'
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button';
import Toggle from '../../../../../../../../newcomponents/toggle/toggle';
import { AddAlert, AlertType, IcoBin, IcoPencil, Input, List } from '../../../../../../../../component';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import joinNames from '../../../../../../../../utils/joinNames';
import { useApp } from '../../../../../../../../layout/App';
import DeleteServiceLocationOptionModal from '../DeleteServiceLocationOptionModal/DeleteServiceLocationOptionModal';
import { getAppointmentServiceLocation, patchAppointmentServiceLocation, postAppointmentServiceLocation } from '../../../http';

const strpHoldingServiceLocation = (holdingServiceLocation=null) => {
    return {
        id: holdingServiceLocation?.id || null,
        name: holdingServiceLocation?.name || '',
        active: holdingServiceLocation?.active || true,
    }
}

const INIT_HOLDING_SERVICE_LOCATION_ERR = { name: false }

const ConfirmServiceLocationLookalikeSavingModal = ({ similarNames=[], payload, onConfirm, onClose }) => (
    <div>
        <p>Locais de atendimentos semelhantes já cadastradas com o nome <b>{payload?.name}</b>:</p>
        <b>{joinNames(similarNames)}</b>
        <p className='ConfirmServiceLocationLookalikeSavingModal-CallToAction'>Deseja salvar mesmo assim?</p>
        <div className='ConfirmServiceLocationLookalikeSavingModal-BtnBox'>
            <NewButton label='Sim, desejo salvar' onClick={onConfirm} model={ButtonModel.SECONDARY} />
            <NewButton label='Fechar' onClick={onClose} />
        </div>
    </div>
);

export default function EditServiceLocationsOptionsModal() {
    const dispatch = useDispatch()
    const [secondaryModal, setSecondaryModal] = useState(MODAL_INIT_PROPS)
    const [serviceLocationOptions, setServiceLocationOptions] = useState([])
    const [holdingServiceLocation, setHoldingServiceLocation] = useState(strpHoldingServiceLocation())
    const [holdingServiceLocationErr, setHoldingServiceLocationErr] = useState(INIT_HOLDING_SERVICE_LOCATION_ERR)
    const { currentHealthPlaceUser } = useApp()

    useEffect(() => {
        fetchServiceLocationsOptions()
    }, [])

    const headDefault = [
        { "colunm": "name", "text": "Nome" },
        { "colunm": "h_active", "text": "Ativo", "width": "72px" },
        { "colunm": "h_action", "text": <div className='EditServiceLocationsOptionsModal-HeaderRow-Action'>Ações</div>, width: '72px' } 
    ]

    const fetchServiceLocationsOptions = async () => {
        try {
            let res = await getAppointmentServiceLocation()
            setServiceLocationOptions(res.data.results)
        } catch (err) {
            setServiceLocationOptions([])
            console.error('fetchServiceLocationsOptions ~ ', err)
        }
    }

    const handleSubmit = async (extraPayload={}) => {
        if (!holdingServiceLocation.name) {
            setHoldingServiceLocationErr(prev => ({ ...prev, name: true }))
            return;
        }

        const payload = { ...holdingServiceLocation, health_place: currentHealthPlaceUser?.health_place?.id, ...extraPayload };

        dispatch(loading());
        try {
            if (payload?.id) {
                await patchAppointmentServiceLocation(holdingServiceLocation.id, payload)
            } else {
                await postAppointmentServiceLocation(payload)
            }
            setHoldingServiceLocation(strpHoldingServiceLocation())
            setSecondaryModal(MODAL_INIT_PROPS)
            dispatch(AddAlert('Locais de Atendimento', 'Local de atendimento salvo com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            handleSaveError(err, payload);
        } finally {
            dispatch(loaded());
            await fetchServiceLocationsOptions()
        }
    }

    const handleToggle = async (locationCursor) => {
        try {
            await patchAppointmentServiceLocation(locationCursor.id, { "active": !locationCursor.active })
            await fetchServiceLocationsOptions()
        } catch (err) {
            dispatch(AddAlert('Locais de Atendimento', 'Falha ao editar local de atendimento', AlertType.ERROR))
            console.error('handleSubmit ~ ', err)
        }
    }

    const handleDeleteHoldingServiceLocation = (cursor) => {
        if (cursor?.active_assignments_count) {
            dispatch(AddAlert('Deleção de local de atendimento', 'Este item está associado a registros no estoque, portanto, pode apenas ser inativado', AlertType.INFO))
        } else {
            setSecondaryModal(prev => ({ 
                ...prev, 
                open: true, 
                title: 'Deleção de Local de Atendimento', 
                content: <DeleteServiceLocationOptionModal
                    serviceLocation={cursor}
                    setSecondaryModal={setSecondaryModal}
                    extraAction={async () => {
                        fetchServiceLocationsOptions()
                        setHoldingServiceLocation(strpHoldingServiceLocation())
                    }}                
                /> 
            }))
        }
    }

    const handleSaveError = (err, payload) => {
        if (err?.response?.data?.error === 'A Service location with a similar name already exists. Please use a more distinct name.') {
            setSecondaryModal({
                open: true,
                content: <ConfirmServiceLocationLookalikeSavingModal
                    similarNames={err.response.data.similar_names}
                    payload={payload}
                    onConfirm={() => handleSubmit({ pass_similar_validation: true })}
                    onClose={() => setSecondaryModal(MODAL_INIT_PROPS)}
                />
            });
        } else {
            const errors = {
                'Service location with this name already exists for this health place.': 'Local de atendimento com esse nome já cadastrado, revise as opções existentes.'
            }
            const errorMessage = errors?.[err?.response?.data?.error] || 'Falha ao salvar local de atendimento.';
            dispatch(AddAlert('Estoque de Materiais', errorMessage, AlertType.ERROR));
            console.error('Error on form submit:', err);
        }
    };

    return <div className='EditServiceLocationsOptionsModal'>
        <Modal {...secondaryModal} dismissFn={() => setSecondaryModal(MODAL_INIT_PROPS)} />
        <div className='EditServiceLocationsOptionsModal-PersonalInfo'>
            <div className='EditServiceLocationsOptionsModal-Subtitle-Header'>
                <div className='EditServiceLocationsOptionsModal-Subtitle-Header-Icon'>L</div>
                <div className='EditServiceLocationsOptionsModal-Subtitle-Header-Title'>Locais de Atendimento</div>
            </div>
        </div>
        <div className='EditServiceLocationsOptionsModal-CursorContainer'>
            <div className='EditServiceLocationsOptionsModal-Select'>
                <span>Nome</span>
                <Input
                    name='name'
                    value={holdingServiceLocation.name}
                    maxLength={100}
                    action={event => {
                        setHoldingServiceLocation(prev => ({ ...prev, name: event.target.value }))
                        setHoldingServiceLocationErr(INIT_HOLDING_SERVICE_LOCATION_ERR)
                    }}
                    errors={{ error: holdingServiceLocationErr, message: holdingServiceLocationErr.name }}
                />
            </div>
            <div className='EditServiceLocationsOptionsModal-CursorContainer-SaveBtn'>
                <NewButton
                    label='Salvar'
                    onClick={() => handleSubmit()}
                    model={holdingServiceLocation?.id ? ButtonModel.SECONDARY : ButtonModel.PRIMARY}
                />
            </div>
        </div>
        <div className='EditServiceLocationsOptionsModal-Body-List'>
            <List
                head={headDefault}
                data={serviceLocationOptions}
                maxHeight='320px'
                listCustom={cursor => {
                    const custom = cursor;
                    custom['h_active'] = (
                        <div>
                            <Toggle 
                                id=''
                                label=''
                                value={cursor.active}
                                onChange={() => handleToggle(cursor)}
                            />
                        </div>
                    )
                    custom['h_action'] = (
                        <div className='EditServiceLocationsOptionsModal-ActionRow'>
                            <div
                                title='Editar local de atendimento'
                            	onClick={() => setHoldingServiceLocation(strpHoldingServiceLocation(cursor))}
                            >
                            	<IcoPencil />
                            </div>
                            <div
                                title='Excluir local de atendimento'
                                onClick={() => handleDeleteHoldingServiceLocation(cursor)}
                            >
                                <IcoBin />
                            </div>
                        </div>
                    )
                    return custom
                }}
            />
        </div>
    </div>
}