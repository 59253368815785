import './ConflictingEventsModal.css'
import React from "react";
import { MaskYYYY_MM_DDT00_00_00 } from '../../../../../../../../component';
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button';
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';


export default function ConflictingEventsModal({ events=[], setModalInfo=() => null }) {

    return <div className='ConflictingEventsModal'>
        <p className='ConflictingEventsModal-Error'>Os seguintes horários apresentam conflitos com outros horários criados na agenda. Revise os parâmetros e tente novamente.</p>
        <div className='ConflictingEventsModal-TimeRow'>
        {
            events.map(event => {
                const startHourTime = MaskYYYY_MM_DDT00_00_00(event?.start)
                const endHourTime = MaskYYYY_MM_DDT00_00_00(event?.end)
                return <div>
                    <b>{startHourTime.date} | {startHourTime?.hour?.slice(0, 5) || '-'} - {endHourTime?.hour?.slice(0, 5) || '-'}</b>
                </div>
            })
        }
        </div>
        <div className='ConflictingEventsModal-BtnBox'>
            <NewButton 
                model={ButtonModel.SECONDARY}
                label='Corrigir'
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
            />
        </div>
    </div>
}