import React from 'react'

export function AttachIcon({style={}}) {
    return <svg style={style} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M3372 5104 c-157 -26 -329 -97 -461 -191 -72 -51 -60 -39 -1561
            -1523 -569 -562 -1038 -1032 -1043 -1043 -4 -11 -22 -41 -41 -66 -190 -264
            -289 -629 -256 -945 27 -268 98 -471 235 -676 219 -329 538 -543 942 -632 144
            -31 424 -31 573 1 191 41 368 113 522 213 46 30 90 57 99 60 17 7 2729 2686
            2729 2697 0 3 -56 62 -125 131 l-125 124 -47 -44 c-44 -42 -1362 -1343 -2233
            -2205 -307 -304 -414 -404 -481 -448 -523 -346 -1209 -210 -1549 306 -69 103
            -112 203 -148 336 -22 84 -25 116 -25 256 0 140 3 172 25 256 59 221 143 361
            330 553 355 363 2351 2324 2402 2358 133 91 262 130 426 130 209 -1 366 -67
            515 -217 141 -140 202 -279 212 -475 8 -177 -43 -342 -150 -475 -62 -78 -2399
            -2388 -2454 -2426 -65 -45 -119 -61 -208 -62 -135 -1 -239 55 -310 167 -44 70
            -59 131 -53 219 9 142 -33 94 928 1045 476 470 953 942 1060 1049 l195 193
            -129 129 -130 130 -629 -622 c-1475 -1458 -1522 -1507 -1567 -1584 -23 -40
            -54 -107 -68 -150 -23 -69 -26 -94 -26 -218 0 -127 2 -147 27 -220 108 -310
            380 -505 705 -505 154 0 322 56 442 147 51 39 2374 2332 2463 2432 301 337
            361 813 154 1216 -218 421 -694 658 -1165 579z"/>
        </g>
    </svg>
}
