import './MergePatientModal.css'
import ShowPatientData from '../subcomponents/ShowPatientData/ShowPatientData'
import Button, { ButtonModel } from '../../../../../newcomponents/button/button'
import { MODAL_INIT_PROPS } from '../../../../../newcomponents/modal/modal'
import { useState } from 'react'
import ConfirmMergeModal from '../ConfirmMergeModal/ConfirmMergeModal'
import api from '../../../../../helpers/api'
import { useDispatch } from 'react-redux'
import { AddAlert, AlertType } from '../../../../../component'
import { useHistory } from 'react-router-dom'


export function MergePatientModal({patient1=null, patient2=null, setModalInfo=()=>null}){
    const [confirmModalInfo, setConfirmModalInfo] = useState({show: false, basePatient: null, duplicate: null})
    const dispatch = useDispatch()
    const history = useHistory()

    const handleMergePatient = async ()=>{
        const payload = {"base_patient": confirmModalInfo.basePatient.id, "duplicate": confirmModalInfo.duplicate.id}
        try{
            const res = await api.post(`patient/merge_patients/`, payload)
            if (res.status == 200){
                setModalInfo(MODAL_INIT_PROPS)
                dispatch(AddAlert('Mesclar usuário', 'Usuário mesclado com Sucesso', AlertType.SUCCESS))
                history.push(`/pacientes/${confirmModalInfo?.basePatient?.id}/detalhar`)
            }

        } catch (err){
            dispatch(AddAlert('Mesclar usuário', 'Erro ao mescladar usuário', AlertType.ERROR))
            console.log(err);
        }
        }

    const handleDismiss = ()=>{
        setConfirmModalInfo({show: false, basePatient: null, duplicate: null})
    }

    return (
    <div className='Merge-Patient-Modal'>
        <ConfirmMergeModal
			show={confirmModalInfo.show}
			confirmHandler={handleMergePatient}
            dismissHandler={handleDismiss}
		/>
        <div className='Merge-Patient-info-container'>
            <ShowPatientData patient={patient1} title={'Opção 1: '}/>
            <ShowPatientData patient={patient2} title={'Opção 2: '}/>
        </div>
        <div className='Merge-Patient-Button-Container'>
            <Button
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
                model={ButtonModel.SECONDARY}
                label='Cancelar'
            />
            <Button
                onClick={() => setConfirmModalInfo({show: true, basePatient: patient1, duplicate: patient2})}
                model={ButtonModel.PRIMARY}
                label='Manter opção 1'
            />
            <Button
                onClick={() => setConfirmModalInfo({show: true, basePatient: patient2, duplicate: patient1})}
                model={ButtonModel.PRIMARY}
                label='Manter opção 2'
            />
        </div>
    </div>
    )
}