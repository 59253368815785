import './CompleteHemogramView.css'
import React from 'react'
import { getHemoglobineResult, getLeukocytesResult, getPlateletsResult } from '../utils'
import AlertRowText from '../../../../../../../../../../../../../../../component/alertRowText/alertRowText'
import { Button, IcoBin, IcoPencil, List } from '../../../../../../../../../../../../../../../component'
import { Mask1DecimalPlace, MaskThousand } from '../../../../../../../../../../../../../../../component/mask/mask'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import CompleteHemogramWrite from '../Write/CompleteHemogramWrite'
import { convertDate } from '../../../../../../../../../../../../../../../utils/convertDate'
import api from '../../../../../../../../../../../../../../../helpers/api'

export default function CompleteHemogramView() {
	const { 
		selectedLanguage, 
		holdingProject: { hemogram_exams }, 
		setModalInfo,
		fetchThousandDaysProjects
	} = useThousandDays()

	const {
		Lowattention,
		Mediumattention,
		Hightattention,
		Change,
		Bloodcount,
		Hemoglobin,
		Leukocytes,
		Platelets,
		Dates,
		Conclusion,
		Notinformation,
	} = require('../../../../../../../../../../../../../../data/translation.json')[
		selectedLanguage
	]

	const headDefaultHemoglobin = [
		{ colunm: 'h_hemoglobin', text: Hemoglobin },
		{ colunm: 'h_hemoglobin_conclusion', text: Conclusion },
		{ colunm: 'h_date', text: Dates, width: '100px' },
		{ colunm: 'h_action', text: '', width: '100px' },
	]

	const headDefaultLeukocytes = [
		{ colunm: 'h_leukocytes', text: Leukocytes },
		{ colunm: 'h_leukocytes_conclusion', text: Conclusion },
		{ colunm: 'h_date', text: Dates, width: '100px' },
		{ colunm: 'h_action', text: '', width: '100px' },
	]

	const headDefaultPlatelets = [
		{ colunm: 'h_platelets', text: Platelets },
		{ colunm: 'h_platelets_conclusion', text: Conclusion },
		{ colunm: 'h_date', text: Dates, width: '100px' },
		{ colunm: 'h_action', text: '', width: '100px' },
	]

	const handleDelete = async (id) => {
		await api.delete(`exam/complete_hemogram/${id}/`)
		fetchThousandDaysProjects()
	}

	const openExamWriteModal = (holdingExam) => {
		setModalInfo(prev => ({
			...prev,
			open: true,
			title: Bloodcount,
			content: (
				<div className='SOAP-ThousandDaysExamSubSection-Modal'>
					<CompleteHemogramWrite 
						holdingCompleteHemogram={holdingExam} 
						setModalInfo={setModalInfo} 
					/>
				</div>
			),
		}))
	}

	return (
		<div className='CompleteHemogramView'>
			<div className='CompleteHemogramView-List'>
				<List
					head={headDefaultHemoglobin}
					data={hemogram_exams}
					noData={Notinformation}
					listCustom={(exam, i) => {
						exam['h_hemoglobin'] = exam?.hemoglobin ? Mask1DecimalPlace(exam.hemoglobin) : '-'
						exam['h_hemoglobin_conclusion'] = exam?.hemoglobin
							? <AlertRowText {...getHemoglobineResult(exam.hemoglobin)} />
							: '-'
						exam['h_date'] = exam?.date ? convertDate(exam.date, '-', '/') : '-'
						exam['h_action'] = (
							<div>
								<Button
									color='secondary'
									type='btn circle'
									action={() => openExamWriteModal(exam)}
									title='Editar exame'>
									<IcoPencil />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									action={() => handleDelete(exam?.id)}
									title='Excluir exame'>
									<IcoBin />
								</Button>
							</div>
						)
						return exam
					}}
				/>
				<List
					head={headDefaultLeukocytes}
					data={hemogram_exams}
					noData={Notinformation}
					listCustom={(exam, i) => {
						exam['h_leukocytes'] = exam?.leukocytes ? MaskThousand(exam.leukocytes) : '-'
						exam['h_leukocytes_conclusion'] = exam?.leukocytes
							? <AlertRowText {...getLeukocytesResult(String(exam.leukocytes), Change)} />
							: '-'
						exam['h_date'] = exam?.date ? convertDate(exam.date, '-', '/') : '-'
						exam['h_action'] = (
							<div>
								<Button
									color='secondary'
									type='btn circle'
									action={() => openExamWriteModal(exam)}
									title='Editar exame'>
									<IcoPencil />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									action={() => handleDelete(exam?.id)}
									title='Excluir exame'>
									<IcoBin />
								</Button>
							</div>
						)
						return exam
					}}
				/>
				<List
					head={headDefaultPlatelets}
					data={hemogram_exams}
					noData={Notinformation}
					listCustom={(exam, i) => {
						exam['h_platelets'] = exam?.platelets ? MaskThousand(exam.platelets) : '-'
						exam['h_platelets_conclusion'] = exam?.platelets
							? <AlertRowText {...getPlateletsResult(
								String(exam.platelets),
								Hightattention,
								Mediumattention,
								Lowattention
							)} />
							: '-'
						exam['h_date'] = exam?.date ? convertDate(exam.date, '-', '/') : '-'
						exam['h_action'] = (
							<div>
								<Button
									color='secondary'
									type='btn circle'
									action={() => openExamWriteModal(exam)}
									title='Editar exame'>
									<IcoPencil />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									action={() => handleDelete(exam?.id)}
									title='Excluir exame'>
									<IcoBin />
								</Button>
							</div>
						)
						return exam
					}}
				/>
			</div>
		</div>
	)
}
