import React from "react"

export default function CheckCircleOutlinedTwo({ style={} }) {
    return <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M2285 4675 c-708 -93 -1326 -538 -1640 -1180 -289 -592 -289 -1278 0
            -1870 323 -661 956 -1104 1694 -1185 170 -19 442 -8 614 24 755 143 1380 685
            1626 1413 149 439 149 927 0 1366 -265 782 -964 1345 -1784 1437 -122 13 -387
            11 -510 -5z m414 -415 c843 -75 1493 -730 1561 -1575 50 -606 -252 -1217 -765
            -1551 -579 -376 -1314 -372 -1885 12 -576 386 -861 1085 -720 1761 138 662
            662 1185 1326 1324 170 35 313 44 483 29z"/>
            <path d="M3340 3187 c-27 -8 -150 -125 -515 -488 l-480 -479 -255 256 c-140
            140 -272 264 -292 275 -69 35 -179 20 -236 -34 -66 -61 -86 -174 -45 -252 31
            -58 698 -720 745 -740 50 -20 124 -19 173 4 27 13 222 202 606 587 549 552
            567 571 578 619 39 166 -113 302 -279 252z"/>
        </g>
    </svg>
}

