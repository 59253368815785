import './FilterRequestParams.css'
import React, { useEffect, useRef, useState } from "react";
import { Input } from '../../../../../../../component';
import NewSelect from '../../../../../../../newcomponents/form/select/select';
import { getHealthPlacePatients } from '../../../../../../pacientes/http';
import { getCollaborators } from '../../../../../../collaborators/http';
import { stringifyCollaboratorOption } from '../../../../../../../utils/stringifyCollaboratorOption';
import { useApp } from '../../../../../../../layout/App';
import IcoCleanFilter from '../../../../../../../component/icon/cleanFilter';

const { INVENTORY_MATERIAL_ASSIGNMENT_ORDERING_OPTIONS } = require('../../../../../../../component/form/select/options.json')

export default function FilterRequestParams({ filterParams={}, setFilterParams= () => null, fetchAssignments=() => null, INIT_FILTER_PARAMS={} }) {
    const searchTimeout = useRef(null)
    const selectSearchTimeout = useRef(null)
    const [filtersList, setFiltersList] = useState([])
    const [patientOptions, setPatientOptions] = useState([])
    const [collaboratorOptions, setCollaboratorOptions] = useState([])
    const { currentHealthPlaceUser } = useApp()

    useEffect(() => {
        fetchPatients()
        fetchCollaborators()
    }, [])

    const handleChange = (event) => {
        setFilterParams(prev => ({ ...prev, [event.target.name]: event.target.value }))

        try {
            searchTimeout.current && clearTimeout(searchTimeout.current)
            searchTimeout.current = setTimeout(async () => {
                let fetchParams = { ...filterParams, [event.target.name]: event.target.value, offset: 0 }
                await fetchAssignments(fetchParams)
                updateFilterList(fetchParams)
            }, 500)
        } catch (err) {
            console.error('FilterRequestParams ~ handleChange ~ ', err)
        }
    }

    const handleSelect = (event) => {
        setFilterParams(prev => ({ ...prev, [event.target.id]: event.target.selected }))

        try {
            searchTimeout.current && clearTimeout(searchTimeout.current)
            searchTimeout.current = setTimeout(async () => {
                let fetchParams = { ...filterParams, [event.target.id]: event.target.selected, offset: 0 }
                await fetchAssignments(fetchParams)
                updateFilterList(fetchParams)
            }, 500)
        } catch (err) {
            console.error('FilterRequestParams ~ handleSelect ~ ', err)
        }
    }

    const handleSearchPatient = async (event) => {
        selectSearchTimeout.current && clearTimeout(selectSearchTimeout.current)
        selectSearchTimeout.current = setTimeout(async () => {
            let params = { 'name_or_cpf': event.target.value }
            await fetchPatients(params)
        }, 300, event.target.name)
    }

    const handleSearchCollaborators = async (event) => {
        if (selectSearchTimeout.current) clearTimeout(selectSearchTimeout.current)
    
        selectSearchTimeout.current = setTimeout(async () => {
            await fetchCollaborators({ 'name_cpf_or_email': event.target.value })
        }, 400, event.target.value)
    }

    const fetchPatients = async (params={}) => {
        let response = await getHealthPlacePatients({ ...params })
        setPatientOptions(response.data.results)
    }

    const fetchCollaborators = async (params={}) => {
        await getCollaborators({
            ...params,
            health_place: currentHealthPlaceUser?.health_place?.id || null,
            limit: 500,
            has_person: true
        })
            .then(res => setCollaboratorOptions(res.data.results.map((instance) => stringifyCollaboratorOption(instance))))
            .catch(err => {
                console.error('fetchCollaborators', err)
                setCollaboratorOptions([])
            })
    }

    const updateFilterList = (newParam={}) => {
        const tempFilterList = Object.entries({ ...filterParams, ...newParam })
 
        setFiltersList(() => {
            const newFilters = tempFilterList
                .filter(item => (item[1] !== '' && item[1] !== null && item[1] !== undefined) && item[0] !== 'offset' && item[0] !== 'ordering')
                .map(item => {
                    switch (item[0]) {
                        case 'material__name__icontains':
                            return ['Nome', 'material__name__icontains']
                        case 'material__code__icontains':
                            return ['Código', 'material__code__icontains']
                        case 'patient':
                            return ['Paciente ', 'patient']
                        case 'health_place_user':
                            return ['Requisitante ', 'health_place_user']
                        default:
                            return item[0]
                    }
                })
                return newFilters
        })
    }

    const clearFilter = async (targetFilter) => {
        if (targetFilter === 'all') {
            setFiltersList([])
            setFilterParams(INIT_FILTER_PARAMS)
            await fetchAssignments(INIT_FILTER_PARAMS)
        } else if (targetFilter) {
            setFiltersList(filtersList.filter(item => item[1] != targetFilter))
            setFilterParams(async prev => {
                let newFilter = { ...prev, [targetFilter]: '' }
                await fetchAssignments(newFilter)
    
                return newFilter
            })
        }
    }

    return <div className='ListRequestMaterials-Filters'>
        <div className='FilterRequestParams-SideBarLeft-ActionRow'>
            <div
                onClick={() => clearFilter('all')}
                title="Limpar filtro"
            >
                <IcoCleanFilter />
            </div>
        </div>
        <div className='ListRequestMaterials-Filters-Body'>
            <div className='FilterRequestParams-Select'>
                <span>Nome</span>
                <Input
                    name='material__name__icontains'
                    action={handleChange}
                    value={filterParams.material__name__icontains}
                />
            </div>
            <div className='FilterRequestParams-Input'>
                <span>Código</span>
                <Input
                    name='material__code__icontains'
                    action={handleChange}
                    value={filterParams.material__code__icontains}
                />
            </div>
            <div className='FilterRequestParams-Select'>
                <span>Paciente</span>
                <NewSelect 
                    id='patient'
                    onSelect={handleSelect}
                    selected={filterParams.patient}
                    options={patientOptions}
                    optionStrKey='name'
                    filterNode={<div className='InsertInventoryMaterialsModal-NewSelect'>
                        <Input
                            placeholder='Pesquisar por paciente'
                            action={handleSearchPatient}
                            actionFocus={()=> fetchPatients()}
                        />
                    </div>}
                />
            </div>
            <div className='ListRequestMaterials-SelectRequester FilterRequestParams-Select'>
                <span>Requisitante</span>
                <NewSelect
                    id='health_place_user'
                    options={collaboratorOptions}
                    selected={filterParams.health_place_user}
                    optionStrKey='str'
                    optionRefKey='health_place_user__id'
                    onSelect={handleSelect}
                    filterNode={<div className='ListRequestMaterials-NewSelect'>
                        <Input FilterRequestParams
                            placeholder='Pesquisar por e-mail, nome ou cpf'
                            action={handleSearchCollaborators}
                            actionFocus={()=> fetchCollaborators()}
                        />
                    </div>
                    }
                />
            </div>
            <div className='FilterRequestParams-Select'>
                <span>Filtrar por</span>
                <NewSelect 
                    id='ordering'
                    onSelect={handleSelect}
                    selected={filterParams.ordering}
                    options={INVENTORY_MATERIAL_ASSIGNMENT_ORDERING_OPTIONS}
                />
            </div>
        </div>
        {
            filtersList?.length
            ? <div className='FilterRequestParams-ActiveFilters'>
                <p className='FilterRequestParams-Title'>Filtros Ativos:</p>
                <ul className='FilterRequestParams-List'>
                    {filtersList.map((item, index) => { 
                        return (
                            <li key={index} className=''>
                                <button onClick={() => clearFilter(item[1])} className='FilterRequestParams-Button'>
                                    <p className='FilterRequestParams-Name'>
                                        {item[0]}
                                    </p>
                                    <div className='FilterRequestParams-Separator'/>
                                    <span className='FilterRequestParams-Remove'>x</span>
                                </button>
                            </li>
                        )
                    })}
                </ul>
            </div>
            : null
        }
    </div>
}