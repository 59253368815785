import { AlertRowTextType } from '../../../../../../../../../../../../../../component/alertRowText/alertRowText'

export function getPlacentaResults(placenta, Change) {
	if (placenta === 'PREVIEW') {
		return { type: AlertRowTextType.WARNING, content: Change }
	} else if (placenta === '' || placenta === 'NOT_EVALUATED') {
		return { type: '', content: '' }
	} else {
		return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
	}
}


export function getAmnioticFluidResults(amniotic_fluid, translate={}) {

	const { 
		SevereOligoamnios, 
		Oligoamnio, 
		ALreduced,
		ALnormal,
		LightPolyhydramnios,
		ModeratePolyhydramnios,
		SeverePolyhydramnios
	} = translate

	if (!amniotic_fluid && amniotic_fluid !== 0 && amniotic_fluid !== '0')
		return { type: '', content: '' }

	if (amniotic_fluid < 1) {
		return { type: AlertRowTextType.WARNING, content: SevereOligoamnios }
	}  else if (amniotic_fluid < 2) {
		return { type: AlertRowTextType.WARNING, content: Oligoamnio }
	} else if (amniotic_fluid <= 3) {
		return { type: AlertRowTextType.WARNING, content: ALreduced }
	} else if (amniotic_fluid <= 8) {
		return { type: AlertRowTextType.SUCCESS, content: ALnormal }
	} else if (amniotic_fluid <= 12) {
		return { type: AlertRowTextType.WARNING, content: LightPolyhydramnios }
	} else if (amniotic_fluid <= 16) {
		return { type: AlertRowTextType.WARNING, content: ModeratePolyhydramnios }
	} else if (amniotic_fluid > 16) {
		return { type: AlertRowTextType.WARNING, content: SeverePolyhydramnios }
	}
}
