import React from 'react'
import ActionForm from '../../../../../../../../component/form/actions/actions'
import { IcoSearch, Input, MaskItemCep } from '../../../../../../../../component'


const addressDefault = {
  zip: '',
  address: '',
  number: '',
  complement: '',
  district: '',
  city: {
    state: '',
    name: ''
  }
}

export default function FormAddress({address = addressDefault, changeDados, searchCep, errors = {}}) {
  return (
    <div className='box-registrar-conteudo'>
      <div className='row'>
          <div className='col-12 col-sm-5'>
          <b><span className='Title-Asterisk'>*</span> CEP</b>
            <Input
              name='zip'
              action={e => changeDados(e)}
              value={address.zip ? MaskItemCep(address.zip) : ''}
              maxLength='9'
              required={{
                erro: {zip: errors.zip},
              }}
            >
              <ActionForm
                action={_ => searchCep(address.zip)}
                title='Pesquisar'
              >
                <IcoSearch />
              </ActionForm>
            </Input>
          </div>
          <div className='col-12 col-sm-7' />
          <div className='col-4 col-sm-3'>
            <b><span className='Title-Asterisk'>*</span> UF</b>
            <Input
              name='state'
              disabled={true}
              action={e => changeDados(e)}
              value={address.city?.state ? address.city?.state : ''}
              required={{
                erro: {state: errors.state},
              }}
            />
          </div>
          <div className='col-8 col-sm-5'>
           <b><span className='Title-Asterisk'>*</span> Cidade</b>
            <Input
              name='city'
              disabled={true}
              action={e => changeDados(e)}
              value={address.city?.name ? address.city?.name : ''}
              required={{
                erro: {city: errors.city},
              }}
            />
          </div>
          <div className='col-12 col-sm-5'>
            <b><span className='Title-Asterisk'>*</span> Bairro</b>
            <Input
              name='district'
              action={e => changeDados(e)}
              value={address.district ? address.district : ''}
              required={{
                erro: {district: errors.district},
              }}
            />
          </div>
          <div className='col-12'>
            <b><span className='Title-Asterisk'>*</span> Endereço</b>
            <Input
              name='address'
              action={e => changeDados(e)}
              value={address.address  ? address.address : ''}
              required={{
                erro: {address: errors.address},
              }}
            />
          </div>
          <div className='col-12 col-sm-4'>
          <b><span className='Title-Asterisk'>*</span> Número</b>
            <Input
              name='number'
              action={e => changeDados(e)}
              value={address.number ? address.number : ''}
              required={{
                erro: {number: errors.number},
              }}
            />
          </div>
          <div className='col-12'>
          <b>Complemento</b>
            <Input
              name='complement'
              action={e => changeDados(e)}
              value={address.complement ? address.complement : ''}
            />
          </div>
        </div>
    </div>
  )
}