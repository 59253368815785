import React from 'react'

export default function AgeGroupIco({ style }) {
    return <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M4075 5110 c-160 -32 -292 -139 -346 -280 -73 -190 -34 -383 103
            -520 62 -62 156 -116 220 -126 46 -8 50 -24 6 -24 -62 0 -202 -41 -296 -85
            -200 -95 -350 -242 -450 -440 -36 -72 -77 -201 -86 -270 -7 -48 -6 -51 24 -68
            53 -32 193 -153 241 -208 168 -197 262 -394 304 -633 21 -124 22 -908 0 -1008
            -29 -141 -91 -257 -198 -373 l-68 -74 3 -443 c3 -422 4 -445 23 -477 11 -19
            33 -43 48 -55 28 -21 35 -21 562 -21 523 0 534 0 561 21 15 11 37 33 48 48 21
            27 21 38 24 799 l3 772 56 25 c69 29 167 123 205 196 58 107 58 112 58 795 0
            506 -3 642 -15 710 -16 93 -50 200 -85 269 -74 147 -225 308 -368 394 -98 58
            -251 113 -344 122 -75 7 -88 18 -30 28 67 11 161 63 222 124 265 265 135 720
            -227 797 -74 16 -136 17 -198 5z"/>
            <path d="M2485 4150 c-118 -21 -200 -64 -273 -145 -85 -93 -121 -190 -122
            -325 0 -231 138 -406 365 -460 l69 -17 -71 -6 c-367 -34 -703 -318 -807 -679
            -13 -46 -26 -102 -30 -124 -6 -41 -5 -42 47 -76 263 -176 455 -471 522 -801
            21 -105 30 -361 17 -467 -23 -181 -87 -321 -204 -446 l-68 -71 0 -209 c0 -243
            5 -263 80 -301 44 -23 45 -23 550 -23 505 0 506 0 550 23 83 42 80 20 80 614
            l0 522 56 25 c67 31 171 129 207 194 57 106 59 121 55 604 -4 425 -5 446 -27
            517 -81 270 -258 484 -498 605 -88 44 -220 83 -311 92 l-67 7 59 14 c316 72
            470 440 299 716 -100 161 -296 249 -478 217z"/>
            <path d="M865 3183 c-197 -44 -336 -178 -374 -361 -54 -260 100 -507 354 -567
            l50 -12 -50 -8 c-287 -42 -530 -190 -684 -418 -50 -73 -107 -202 -132 -297
            -27 -106 -39 -384 -19 -482 26 -134 127 -270 244 -328 l61 -31 5 -289 c5 -272
            6 -290 26 -316 11 -15 33 -37 48 -48 27 -21 38 -21 559 -21 l532 0 33 23 c65
            47 67 57 70 370 l3 282 54 25 c105 50 192 149 237 271 20 53 22 79 22 244 1
            204 -12 283 -65 417 -100 253 -318 462 -570 548 -57 20 -190 49 -248 56 -3 0
            18 7 48 15 235 63 388 290 353 524 -29 199 -157 345 -347 395 -55 14 -164 18
            -210 8z"/>
        </g>
    </svg>
    
}
