import { adicionaZero } from "../component"

export const convertDate = (date, split, join) => date.substring(10, 0).split(split).reverse().join(join)

export const formatDateWithTimezone = (date, hour, minute) => {
    if (!date) return;
    
    const [year, month, day] = date.split('-');
    
    // Manually format the date to avoid the UTC conversion
    const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    const formattedTime = `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:00`;
    
    // Append the GMT-3 offset directly
    return `${formattedDate}T${formattedTime}-03:00`;
};

export const formatDateWithTimezoneFromDateObj = (dateObj, hour, minute) => {
    if (!dateObj) return;

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); 
    const day = String(dateObj.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hour}:${minute}:00`;

    return `${formattedDate}T${formattedTime}-03:00`;
}

export function buildDate(year, month, day, hour='', minute='') {
    if (year && month && day && hour && minute) {
        return formatDateWithTimezone(`${year}-${month}-${day}`, hour, minute)
    } else if (year && month && day) {
        return `${year}-${month}-${day}`
    } else {
        return null
    }
}

export function formatDate(inputStr) {
    if (!inputStr) return ''

    let [year, month, day] = inputStr.split('-')
    let date = new Date(year, month, day, '10', '10');

    day = String(date.getDate()).padStart(2, '0');
    month = String(date.getMonth() + 1).padStart(2, '0');
    year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

export function formatISO8601(inputStr=null) {
    const date = new Date(inputStr)

    date.setHours(0, 0, 0, 0)

    const formattedDate = date.toISOString().replace(/\.\d{3}Z$/, "Z");

    return formattedDate
}

export function formatISO8601Str(inputStr=null) {
    if (!inputStr) return ''

    const date =  new Date(inputStr)

    return `${adicionaZero(date.getDate())}/${adicionaZero(date.getMonth() + 1)}/${date.getFullYear()}`
}