import api from "../../../../../helpers/api";

export async function getInventoryConfig(id) {
	const response = await api.get(`inventory/config/${id}/`)
	return response
}

export async function postInventoryConfig(payload={}) {
    const response = await api.post(`inventory/config/`, payload)
    return response
}

export async function patchInventoryConfig(id, payload={}) {
    const response = await api.patch(`inventory/config/${id}/`, payload)
    return response
}