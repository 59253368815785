import './DeleteLocationOption.css'
import React from "react";
import { useDispatch } from 'react-redux';
import NewButton, { ButtonModel } from '../../../../../../../../../newcomponents/button/button';
import { MODAL_INIT_PROPS } from '../../../../../../../../../newcomponents/modal/modal';
import { loaded, loading } from '../../../../../../../../../layout/redux/AppActions';
import { AddAlert, AlertType } from '../../../../../../../../../component';
import { deleteInventoryMaterialLocation } from '../http';

export default function DeleteLocationOption({ location={}, setSecondaryModal=() => null, extraAction= async () => null }) {
    const dispatch = useDispatch()

    const handleDelete = async () => {
        if (location?.id) {
            try {
                dispatch(loading())
                await deleteInventoryMaterialLocation(location?.id)
                await extraAction()
                setSecondaryModal(MODAL_INIT_PROPS)
                dispatch([AddAlert('Deleção de Localização', 'Item deletado com sucesso.', AlertType.SUCCESS), loaded()])
            } catch (err) {
                console.error('DeleteLocationOption ~ handleDelete ~ deleteInventoryMaterialLocation ~ ', err)
            }

        }
    }

    return <div className='DeleteLocationOption'>
        <p>Deseja realmente excluir esta localização?</p>
        <div className='DeleteLocationOption-BtnBox'>
            <NewButton
                label='Não, cancelar'
                onClick={() => setSecondaryModal(MODAL_INIT_PROPS)}
                model={ButtonModel.SECONDARY}
            />
            <NewButton 
                label='Sim, excluir'
                onClick={handleDelete}
                model={ButtonModel.DANGER}
            />
        </div>
    </div>
}