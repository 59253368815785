import React from 'react'

export default function RiskIco({ style={} }) {
    return <svg className='icon-risk' style={style} viewBox="0 0 512.000000 512.000000" >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M1540 4789 c-29 -12 -67 -46 -81 -72 -7 -12 -469 -2293 -469 -2314 0
            -1 -200 -4 -444 -5 -431 -3 -445 -4 -472 -24 -53 -39 -69 -71 -69 -134 0 -63
            16 -95 69 -134 27 -21 38 -21 565 -21 l538 0 37 29 c21 16 44 43 51 60 6 17
            85 400 175 851 90 451 166 823 169 827 4 5 184 -760 400 -1700 217 -939 399
            -1721 406 -1737 16 -41 67 -83 110 -91 57 -10 103 4 143 44 35 36 55 93 437
            1240 220 661 402 1198 406 1194 3 -4 45 -149 94 -322 86 -306 89 -316 127
            -352 21 -21 52 -40 70 -43 18 -3 299 -5 625 -3 580 3 592 3 619 24 53 39 69
            71 69 134 0 63 -16 95 -69 134 -27 21 -40 21 -556 24 l-529 3 -16 52 c-8 28
            -75 261 -147 517 -127 446 -133 466 -168 502 -43 44 -93 56 -153 38 -89 -26
            -67 30 -486 -1231 -211 -635 -388 -1165 -394 -1179 -7 -20 -90 325 -428 1789
            -309 1335 -425 1821 -440 1842 -40 57 -126 83 -189 58z"/>
        </g>
    </svg>
}

