import './PastTextAppointmentField.css'
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import Loading from '../../../../../../../newcomponents/loading/loading';
import { getAppointmentTextField } from '../../../../../http';
import { AddAlert, AlertType, MaskDataHora } from '../../../../../../../component';

export default function PastTextAppointmentField({ field_type, attendance }) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [pastAppointmentTextFields, setPastAppointmentTextFields] = useState([])

    

    useEffect(() => {
        let params = {
            "offset": 0, 
            "field_type": field_type, 
            "appointment__person__id": attendance?.person?.id,
            "exclude_non_empty_text": true,
            "ordering": "-appointment__start",
        }
        fetchPastAppointmentTextField(params)
    }, [])

    const fetchPastAppointmentTextField = async (params={}) => {
        setLoading(true)
        try {
            let res = await getAppointmentTextField(params)
            setPastAppointmentTextFields(res.data.results)
        } catch (err) {
            dispatch(AddAlert('Consultas Anteriores', 'Falha ao buscar consultas anteriores', AlertType.ERROR))
            console.error('PastTextAppointmentField ~ getAppointmentCIAPTwoField ~ ', err)
        }
        setLoading(false)
    }

    if (loading) {
        return <div className='PastTextAppointmentField-Loading'>
            <Loading />
        </div>
    }

    return <div className='PastTextAppointmentField'>
        {
            pastAppointmentTextFields?.length
            ? <div className='PastTextAppointmentField-Body'>
            {
                pastAppointmentTextFields.map((instance, idx) => {
                    const isEmpty = !instance?.text || instance?.text === '' || instance?.text === '<p><br></p>' || instance?.text?.length === 0
                    const attendance__start = MaskDataHora(instance?.appointment?.start, 'DD/MMM/AAAA', '-')
                    return <div key={idx}className='PastTextAppointmentField-TextFieldBox'>
                        <div>
                            <div className='PastTextAppointmentField-TextFieldBox-InfoBox-TitleRow'>Atendimento em {attendance__start?.data} - {attendance?.protocol}</div>
                            {
                                isEmpty
                                ? <p style={{ fontSize: '0.875rem' }}>-</p>
                                : <p style={{ fontSize: '0.875rem' }} dangerouslySetInnerHTML={{ __html: instance?.text }}></p>
                            }
                        </div>
                    </div>
                })
            }
            </div>
            : <div className='PastTextAppointmentField-EmptyMessage-Box'>
                <span>Sem registros anteriores para esse campo</span>
            </div>
        }
    </div>
}