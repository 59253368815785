import './HealthPlaceInstitutionalLogo.css'
import React, { useRef } from "react";
import { useConfiguration } from '../../../../../configuration';
import HealthPlaceInstitutionalLogoModal from './HealthPlaceInstitutionalLogoModal/HealthPlaceInstitutionalLogoModal';
import { useApp } from '../../../../../../../layout/App';

export default function HealthPlaceInstitutionalLogo() {
    const fileInputRef = useRef(null);
    const { setModalProps } = useConfiguration()
    const { currentHealthPlaceUser, loged } = useApp()
    const defaultUser = require('../../../../../../../media/DefaultUser.png')

    const handleEditButtonClick = () => {
        fileInputRef.current.click()
    }

    const openInstitutionalLogoModal = (event) => {
        event.preventDefault();
        let files;
        if (event.dataTransfer) {
            files = event.dataTransfer.files;
        } else if (event.target) {
            files = event.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setModalProps({
                open: true,
                title: 'Editar Instituição',
                content: <HealthPlaceInstitutionalLogoModal 
                    initHoldingHealthPlace={{ id: currentHealthPlaceUser?.health_place?.id, base64_avatar: reader.result }} 
                    setModalInfo={setModalProps} 
                    fetchExtraAction={loged} 
                />,
                dismissText: '',
            });
        };
        reader.readAsDataURL(files[0]);
    }
    console.log('currentHealthPlaceUser ~ ', currentHealthPlaceUser?.health_place?.logo_url)
    return <div className='HealthPlaceInstitutionalLogo'>
        <div className='HealthPlaceInstitutionalLogo-Header'>
            <div className='HealthPlaceInstitutionalLogo-Header-Icon'>L</div>
            <div className='HealthPlaceInstitutionalLogo-Header-Title'>Logo</div>
        </div>
        <input 
            type="file" 
            className="HealthPlaceInstitutionalLogo-HideChooseFile" 
            name="file_photo"
            ref={fileInputRef}
            onChange={openInstitutionalLogoModal}
            style={{ display: 'none' }}
        />
        <div className='HealthPlaceInstitutionalLogo-Container-Avatar'>
            <div className='img'>
                <img
                    onClick={handleEditButtonClick}
                    className='HealthPlaceInstitutionalLogo-Container-Avatar-Img'
                    alt='Paciente foto de perfil'
                    src={currentHealthPlaceUser?.health_place?.logo_url || defaultUser}
                />
            </div>
        </div>
        <div className='HealthPlaceInstitutionalLogo-ActionRow'>
            <div 
                className='HealthPlaceInstitutionalLogo-ActionRow-Btn' 
                onClick={handleEditButtonClick}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}

