import React from 'react'

export function NewDocumentIcon({style={}}) {
    return <svg style={style} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M1290 4945 c-336 -68 -587 -329 -639 -665 -9 -56 -11 -512 -9 -1765
            3 -1845 -1 -1700 58 -1857 66 -177 261 -372 438 -438 155 -58 66 -55 1422 -55
            1356 0 1267 -3 1422 55 177 66 372 261 438 438 59 156 55 49 58 1487 2 911 -1
            1340 -8 1385 -16 99 -55 208 -106 295 -41 70 -101 135 -473 508 -234 236 -454
            449 -488 473 -87 63 -202 113 -307 135 -85 17 -147 19 -916 18 -639 -1 -840
            -4 -890 -14z m1719 -311 l31 -6 0 -342 c0 -376 4 -409 61 -517 38 -70 126
            -157 197 -193 103 -52 138 -56 508 -56 l342 0 6 -31 c3 -17 6 -606 6 -1308 0
            -1390 2 -1328 -56 -1443 -36 -71 -123 -159 -193 -197 -120 -64 -58 -61 -1351
            -61 -1024 0 -1183 2 -1235 15 -172 45 -305 179 -350 352 -23 86 -23 3340 0
            3426 45 171 177 306 346 351 45 12 189 15 855 15 441 1 816 -2 833 -5z m714
            -792 c-239 -3 -261 -1 -304 35 -55 46 -59 69 -59 315 l0 223 285 -285 285
            -285 -207 -3z"/>
            <path d="M2495 3186 c-37 -17 -70 -52 -84 -89 -7 -17 -11 -127 -11 -282 l0
            -254 -269 -3 c-254 -3 -271 -4 -297 -24 -53 -39 -69 -71 -69 -134 0 -63 16
            -95 69 -134 26 -20 43 -21 296 -24 l269 -3 3 -269 c3 -253 4 -270 24 -296 39
            -53 71 -69 134 -69 63 0 95 16 134 69 20 26 21 43 24 296 l3 269 269 3 c253 3
            270 4 296 24 53 39 69 71 69 134 0 63 -16 95 -69 134 -26 20 -43 21 -296 24
            l-269 3 -3 269 c-3 253 -4 270 -24 296 -11 15 -32 37 -46 47 -34 25 -113 32
            -153 13z"/>
        </g>
    </svg>
}
