import './Deliverance.css'
import React, { useEffect } from 'react'
import { Input, RadioButton } from '../../../../../../../../../../../../../../component'
import { useThousandDays } from '../../../../../../ThousandDaysSection'

const {
    placental_detachment_options,
    default_options
} = require('./Deliverance.json')

export default function Deliverance({ handleChange }) {
    const { selectedLanguage, partograph, savePartograph } = useThousandDays()

    const {
        Default_Options,
        Placentalabruption,
        Spontaneous,
        Maneuver,
        Manualextraction,
        Placentalexamination,
        Curettage,
        Placentalweight,
        Cordlength
    } = require('../../../../../../../../../../../../../data/translation.json')[selectedLanguage]

    const default_option = default_options.map((option) => ({
			id: option.id,
			name: Default_Options[option.id],
		}))

    useEffect(() => {
        savePartograph()
    }, [
        partograph.placental_displacement,
        partograph.deliverance_spontaneous,
        partograph.deliverance_maneuver,
        partograph.deliverance_manual_extraction,
        partograph.deliverance_placenta_exam,
        partograph.deliverance_curettage
    ])

    return (
			<>
				<div className='ThousandDays-Childbirth-Deliverance-2fr'>
					<RadioButton
						label={
							<p>
								<b>{Placentalabruption}</b>
							</p>
						}
						name='placental_displacement'
						action={handleChange}
						checked={partograph.placental_displacement}
						options={placental_detachment_options}
					/>
					<RadioButton
						label={
							<p>
								<b>{Spontaneous}</b>
							</p>
						}
						name='deliverance_spontaneous'
						action={handleChange}
						checked={partograph.deliverance_spontaneous}
						options={default_option}
					/>
					<RadioButton
						label={
							<p>
								<b>{Maneuver}</b>
							</p>
						}
						name='deliverance_maneuver'
						action={handleChange}
						checked={partograph.deliverance_maneuver}
						options={default_option}
					/>
					<RadioButton
						label={
							<p>
								<b>{Manualextraction}</b>
							</p>
						}
						name='deliverance_manual_extraction'
						action={handleChange}
						checked={partograph.deliverance_manual_extraction}
						options={default_option}
					/>
					<RadioButton
						label={
							<p>
								<b>{Placentalexamination}</b>
							</p>
						}
						name='deliverance_placenta_exam'
						action={handleChange}
						checked={partograph.deliverance_placenta_exam}
						options={default_option}
					/>
					<RadioButton
						label={
							<p>
								<b>{Curettage}</b>
							</p>
						}
						name='deliverance_curettage'
						action={handleChange}
						checked={partograph.deliverance_curettage}
						options={default_option}
					/>
					<div>
						<b>{Placentalweight}</b>
						<div className='ThousandDays-Childbirth-Deliverance-Input'>
							<Input
								name='placenta_weight'
								placeholder='g'
								maxLength={5}
								value={partograph.placenta_weight}
								action={handleChange}
								actionBlur={savePartograph}
								autoComplete='off'
							/>
						</div>
					</div>
					<div>
						<b>{Cordlength}</b>
						<div className='ThousandDays-Childbirth-Deliverance-Input'>
							<Input
								name='cord_length'
								placeholder='cm'
								maxLength={5}
								value={partograph.cord_length}
								action={handleChange}
								actionBlur={savePartograph}
								autoComplete='off'
							/>
						</div>
					</div>
				</div>
			</>
		)
}