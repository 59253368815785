import './DeleteScheduleSlot.css'
import React from "react";
import { useDispatch } from "react-redux";
import { AddAlert, AlertType, MaskDataHora } from "../../../../../../../../../../component";
import { loaded, loading } from "../../../../../../../../../../layout/redux/AppActions";
import NewButton, { ButtonModel } from "../../../../../../../../../../newcomponents/button/button";
import { MODAL_INIT_PROPS } from "../../../../../../../../../../newcomponents/modal/modal";
import { useBookingAppointmentCursor } from "../../../../BookingAppointmentCursor";
import { deleteEvents } from '../../../../../../http';

export default function DeleteScheduleSlot({ appointments=[] }) {
    const dispatch = useDispatch()
    const { 
        setModalInfo, 
        updateSchedule, 
        health_place_user__user__person, 
        setSelectedTimesCursor 
    } = useBookingAppointmentCursor()

    const handleSubmit = async () => {
        dispatch(loading())
        try {
            let payload = { 'event_ids': appointments.map(({pk}) => pk) }
            await deleteEvents(health_place_user__user__person, payload)
            await updateSchedule()
            dispatch(AddAlert('Agendamento', 'Horário(s) deletado(s) com sucesso!', AlertType.SUCCESS))
            setSelectedTimesCursor([])
            setModalInfo(MODAL_INIT_PROPS)
        } catch (err) {
            dispatch(AddAlert('Agendamento', 'Falha ao deletar horário(s) de atendimento(s)', AlertType.ERROR))
            console.error('DeleteScheduleSlot ~ handleSubmit ~ ', err)
        }
        dispatch(loaded())
    }

    return <div className='DeleteScheduleSlot'>
        <p>Tem certeza que deseja excluir os horários selecionados?</p>
        <div className='DeleteScheduleSlot-TimeRow'>
        {
            appointments.map(appointment => {
                const startHourTime = MaskDataHora(appointment?.fields?.start || appointment?.start, 'DD/MMM/AAAA', '-');
                const endHourTime = MaskDataHora(appointment?.fields?.end || appointment?.end, 'DD/MMM/AAAA', '-');
                return <div>
                    <b>{startHourTime.data} | {startHourTime?.hora?.slice(0, 5) || '-'} - {endHourTime?.hora?.slice(0, 5) || '-'}</b>
                </div>
            })
        }
        </div>
        <div className='DeleteScheduleSlot-BtnBox'>
            <NewButton 
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
                label='Cancelar'
                model={ButtonModel.SECONDARY}
            />
            <NewButton 
                onClick={handleSubmit}
                label='Confirmar'
                model={ButtonModel.DANGER}
            />
        </div>
    </div>
}
