import './HealthPlaceInstitutionalAppearanceModal.css'
import React, { useEffect, useRef, useState } from "react";
import { Chrome } from "@uiw/react-color";
import InfoOutlinedIcon from '../../../../../../../../component/icon/info-outlined';
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button';
import { useConfiguration } from '../../../../../../configuration';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import { useDispatch } from 'react-redux';
import { AddAlert, AlertType } from '../../../../../../../../component';

function strpHoldingInstitution(institution=null) {
    return {
        "id": institution?.id || "",
        "primary_color": institution?.primary_color || "#005daa",
        "primary_color_light": institution?.primary_color_light || "#0083ef",
        "primary_color_lighter": institution?.primary_color_lighter || '#0d8ffb',
        "primary_color_light_2": institution?.primary_color_light_2 || '#bbe0ff',
        "primary_color_dark": institution?.primary_color_dark || '#004a88',
        "primary_color_darker": institution?.primary_color_darker || '#003969',
        "primary_font_color": institution?.primary_font_color || '#ffffff',
    }
}

export default function HealthPlaceInstitutionalAppearanceModal({ pageRef }) {
    const dispatch = useDispatch()
    const { institution, saveInstitution, fetchInstitution, setModalProps } = useConfiguration()
    const [holdingInstitution, setHoldingInstitution] = useState(strpHoldingInstitution(institution))
    const [enableAutomaticColorPick, setEnableAutomaticColorPick] = useState(true)
    const modalRef = useRef(null)
    const [showInfo, setShowInfo] = useState({
        primaryColor: false,
        secondaryColor: false,
        secondaryColor2: false,
        secondaryColor3: false,
        tertiaryColor: false,
        tertiaryColor2: false,
        primaryFontColor: false,
    })

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [pageRef]);

    const handleClickOutside = (event) => {
        const clickedID = event?.target?.id

        setShowInfo(prev => ({
            primaryColor: clickedID !== 'primaryColor' ? false : prev.primaryColor ,
            secondaryColor: clickedID !== 'secondaryColor' ? false : prev.secondaryColor ,
            secondaryColor2: clickedID !== 'secondaryColor2' ? false : prev.secondaryColor2 ,
            secondaryColor3: clickedID !== 'secondaryColor3' ? false : prev.secondaryColor3 ,
            tertiaryColor: clickedID !== 'tertiaryColor' ? false : prev.tertiaryColor ,
            tertiaryColor2: clickedID !== 'tertiaryColor2' ? false : prev.tertiaryColor2 ,
            primaryFontColor: clickedID !== 'primaryFontColor' ? false : prev.primaryFontColor ,
    }))
    }

    const adjustColorsBasedOnPrimary = (rgb) => {
        const { r, g, b } = rgb;
        const fixRGB = (value) => Math.max(0, Math.min(255, value));

        const rgbToHex = (r, g, b) => {
            const toHex = (value) => value.toString(16).padStart(2, '0');
            return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
        };

        const selectfontcolor = (r, g, b) => {
            const luminosity = ( r * 299 + g * 587 + b * 114) / 1000
            if (luminosity > 128) return '#000000'
            return '#ffffff'
        }
    
        const secondaryColor = rgbToHex(r, fixRGB(g + 38), fixRGB(b + 69));
        const secondaryColor2 = rgbToHex(fixRGB(r + 13), fixRGB(g + 50), fixRGB(b + 81));
        const secondaryColor3 = rgbToHex(fixRGB(r + 187), fixRGB(g + 131), fixRGB(b + 85));
        const tertiaryColor = rgbToHex(r, fixRGB(g - 19), fixRGB(b - 34));
        const tertiaryColor2 = rgbToHex(r, fixRGB(g - 36), fixRGB(b - 65));
        const primaryFontColor = selectfontcolor(r, g, b)
        
        return {
            secondaryColor,
            secondaryColor2,
            secondaryColor3,
            tertiaryColor,
            tertiaryColor2,
            primaryFontColor
        };
    };

    const handleChange = ({ color, chosenField }) => {
        if (enableAutomaticColorPick && chosenField === 'primary_color') {
            const adjustedColors = adjustColorsBasedOnPrimary(color.rgba);          

            setHoldingInstitution(prev => ({
                ...prev,
                primary_color: color.hex,
                primary_color_light: adjustedColors.secondaryColor,
                primary_color_lighter: adjustedColors.secondaryColor2,
                primary_color_light_2: adjustedColors.secondaryColor3,
                primary_color_dark: adjustedColors.tertiaryColor,
                primary_color_darker: adjustedColors.tertiaryColor2,
                primary_font_color: adjustedColors.primaryFontColor
            }));
        } else {
            setHoldingInstitution(prev => ({ ...prev, [chosenField]: color.hex}))
        }
    }

    const handleSetDefaultColors = async () => {
        let payload = { 
            "primary_color": "#005daa",
            "primary_color_light": "#0083ef",
            "primary_color_lighter": '#0d8ffb',
            "primary_color_dark": '#004a88',
            "primary_color_darker": '#003969',
            "primary_color_light_2": '#bbe0ff',
            "primary_font_color": '#ffffff'
        }

        try{
            await saveInstitution(holdingInstitution?.id, payload)
            await fetchInstitution()
    
            document.documentElement.style.setProperty('--primary-color', payload.primary_color)
            document.documentElement.style.setProperty('--primary-color-light', payload.primary_color_light)
            document.documentElement.style.setProperty('--primary-color-lighter', payload.primary_color_lighter)
            document.documentElement.style.setProperty('--primary-color-light-2', payload.primary_color_light_2)
            document.documentElement.style.setProperty('--primary-color-dark', payload.primary_color_dark)
            document.documentElement.style.setProperty('--primary-color-darker', payload.primary_color_darker)
            document.documentElement.style.setProperty('--primary-font-color', payload.primary_font_color)

            dispatch(AddAlert('Editar Aparência', 'Aparência salva com sucesso', AlertType.SUCCESS))
            setModalProps(MODAL_INIT_PROPS)
        } catch (err) {
            dispatch(AddAlert('Editar Aparência', 'Falha ao salvar aparência, revise os dados', AlertType.ERROR));
            console.error('HealthPlaceInstitutionalAppearanceModal ~ handleSubmit', err);
        }
    }
    
    const handleSubmit = async () => {
        dispatch(loading());
        try {
            let payload = { 
                "primary_color": holdingInstitution?.primary_color,
                "primary_color_light": holdingInstitution?.primary_color_light,
                "primary_color_lighter": holdingInstitution?.primary_color_lighter,
                "primary_color_dark": holdingInstitution?.primary_color_dark,
                "primary_color_darker": holdingInstitution?.primary_color_darker,
                "primary_color_light_2": holdingInstitution?.primary_color_light_2,
                "primary_font_color": holdingInstitution?.primary_font_color
            }
            await saveInstitution(holdingInstitution?.id, payload)
            await fetchInstitution()
            
            document.documentElement.style.setProperty('--primary-color', holdingInstitution?.primary_color)
            document.documentElement.style.setProperty('--primary-color-light', holdingInstitution?.primary_color_light)
            document.documentElement.style.setProperty('--primary-color-lighter', holdingInstitution?.primary_color_lighter)
            document.documentElement.style.setProperty('--primary-color-light-2', holdingInstitution?.primary_color_light_2)
            document.documentElement.style.setProperty('--primary-color-dark', holdingInstitution?.primary_color_dark)
            document.documentElement.style.setProperty('--primary-color-darker', holdingInstitution?.primary_color_darker)
            document.documentElement.style.setProperty('--primary-font-color', payload.primary_font_color)
    
            dispatch(AddAlert('Editar Aparência', 'Aparência salva com sucesso', AlertType.SUCCESS))
            setModalProps(MODAL_INIT_PROPS)
        } catch (err) {
            dispatch(AddAlert('Editar Aparência', 'Falha ao salvar aparência, revise os dados', AlertType.ERROR));
            console.error('HealthPlaceInstitutionalAppearanceModal ~ handleSubmit', err);
        }
        dispatch(loaded());
    }

    return <div className='HealthPlaceInstitutionalAppearanceModal' ref={modalRef}>
        <div className='HealthPlaceInstitutionalAppearanceModal-Header'>
            <div className='HealthPlaceInstitutionalAppearanceModal-Header-Icon'>A</div>
            <div className='HealthPlaceInstitutionalAppearanceModal-Header-Title'>Aparência</div>
        </div>
        <div className='HealthPlaceInstitutionalAppearanceModal-ShadesContainer'>
            <div>
                <div className='HealthPlaceInstitutionalAppearanceModal-Title-Box'>
                    <p className='HealthPlaceInstitutionalAppearanceModal-Title' ><b>Cor Primária </b></p>
                    <div onClick={() => setShowInfo(prev => ({ ...prev, primaryColor: !prev.primaryColor }))}>
                        <InfoOutlinedIcon style={{width: '20px', height: '20px', cursor: 'pointer'}} id='primaryColor'/>
                        {showInfo.primaryColor && (
                            <div className='HealthPlaceInstitutionalAppearanceModal-Inf-Container'>
                            Define a cor de fundo do menu principal, da seção de informações da página inicial e dos botões do sistema.
                            </div>
                        )}
                    </div>
                </div>
                <div 
                    className='HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor'
                    style={{ "backgroundColor": holdingInstitution.primary_color }}
                >
                    <div className='HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor-Chrome'>
                        <Chrome 
                            color={holdingInstitution.primary_color}
                            onChange={(color) => handleChange({color, chosenField: 'primary_color'})}
                        />
                    </div>
                </div>
            </div>
            {/* <div>
                <div className='HealthPlaceInstitutionalAppearanceModal-Title-Box'>
                    <p className='HealthPlaceInstitutionalAppearanceModal-Title' >
                        -----desabilitado por nao fazer alterações no sistema-----
                        <b className={enableAutomaticColorPick ? 'label-disabled' : ''} >Cor Secundária </b>
                    </p>
                    <div onClick={() => setShowInfo(prev => ({ ...prev, primaryColor: !prev.primaryColor }))}>
                        <InfoOutlinedIcon style={{width: '20px', height: '20px', cursor: 'pointer'}}/>
                        {showInfo.primaryColor && (
                            <div className='HealthPlaceInstitutionalAppearanceModal-Inf-Container'>
                            Define a cor de fundo do menu principal, da seção de informações da página inicial e dos botões do sistema.
                            </div>
                        )}
                    </div>
                </div>
                <div 
                    className='HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor'
                    style={{ "backgroundColor": holdingInstitution.primary_color_light }}
                >
                    <div className='HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor-Chrome'>
                        { !enableAutomaticColorPick
                        ? <Chrome 
                            color={holdingInstitution.primary_color_light}
                            onChange={(color) => handleChange({color, chosenField: 'primary_color_light'})}
                        />
                        : null
                        }
                    </div>
                </div>
            </div> */}
            <div>
                <div className='HealthPlaceInstitutionalAppearanceModal-Title-Box'>
                    <p className='HealthPlaceInstitutionalAppearanceModal-Title' >
                        {/* Foi colocado a label de Cor Secundária, mas para a lógica é considerado como Cor Secundária 2 */}
                        <b className={enableAutomaticColorPick ? 'label-disabled' : ''} >Cor Secundária</b>
                    </p>
                    <div onClick={() => setShowInfo(prev => ({ ...prev, secondaryColor2: !prev.secondaryColor2 }))}>
                        <InfoOutlinedIcon style={{width: '20px', height: '20px', cursor: 'pointer'}} id={'secondaryColor2'}/>
                        {showInfo.secondaryColor2 && (
                            <div className='HealthPlaceInstitutionalAppearanceModal-Inf-Container'>
                            Define detalhes visuais no menu de controle de estoque.
                            </div>
                        )}
                    </div>
                </div>
                <div 
                    className='HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor'
                    style={{ "backgroundColor": holdingInstitution.primary_color_lighter }}
                >
                    <div className='HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor-Chrome'>
                    { !enableAutomaticColorPick
                        ? <Chrome 
                            color={holdingInstitution.primary_color_lighter}
                            onChange={(color) => handleChange({color, chosenField: 'primary_color_lighter'})}
                        />
                        : null
                    }
                    </div>
                </div>
            </div>
            <div>
                <div className='HealthPlaceInstitutionalAppearanceModal-Title-Box'>
                    <p className='HealthPlaceInstitutionalAppearanceModal-Title' >
                        {/* Foi colocado a label de Cor Secundária 2, mas para a lógica é considerado como Cor Secundária 3 */}
                        <b className={enableAutomaticColorPick ? 'label-disabled' : ''} >Cor Secundária 2</b>
                    </p>
                    <div onClick={() => setShowInfo(prev => ({ ...prev, secondaryColor3: !prev.secondaryColor3 }))}>
                        <InfoOutlinedIcon style={{width: '20px', height: '20px', cursor: 'pointer'}} id={'secondaryColor3'}/>
                        {showInfo.secondaryColor3 && (
                            <div className='HealthPlaceInstitutionalAppearanceModal-Inf-Container'>
                            Define a cor de fundo das tags de filtros ativos no sistema.
                            </div>
                        )}
                    </div>
                </div>
                <div 
                    className='HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor'
                    style={{ "backgroundColor": holdingInstitution.primary_color_light_2 }}
                >
                    <div className='HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor-Chrome'>
                    { !enableAutomaticColorPick
                        ? <Chrome 
                            color={holdingInstitution.primary_color_light_2}
                            onChange={(color) => handleChange({color, chosenField: 'primary_color_light_2'})}
                        />
                        : null
                    }
                    </div>
                </div>
            </div>
            <div>
                <div className='HealthPlaceInstitutionalAppearanceModal-Title-Box'>
                    <p className='HealthPlaceInstitutionalAppearanceModal-Title' >
                        <b className={enableAutomaticColorPick ? 'label-disabled' : ''} >Cor Terciária</b>
                    </p>
                    <div onClick={() => setShowInfo(prev => ({ ...prev, tertiaryColor: !prev.tertiaryColor }))}>
                        <InfoOutlinedIcon style={{width: '20px', height: '20px', cursor: 'pointer'}} id={'tertiaryColor'}/>
                        {showInfo.tertiaryColor && (
                            <div className='HealthPlaceInstitutionalAppearanceModal-Inf-Container'>
                            Altera a cor de destaque ao passar o mouse sobre os botões e ajusta detalhes nos filtros.
                            </div>
                        )}
                    </div>
                </div>
                <div 
                    className='HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor'
                    style={{ "backgroundColor": holdingInstitution.primary_color_dark }}
                >
                    <div className='HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor-Chrome'>
                    { !enableAutomaticColorPick
                        ? <Chrome 
                            color={holdingInstitution.primary_color_dark}
                            onChange={(color) => handleChange({color, chosenField: 'primary_color_dark'})}
                        />
                        : null
                    }
                    </div>
                </div>
            </div>
            <div>
                <div className='HealthPlaceInstitutionalAppearanceModal-Title-Box'>
                    <p className='HealthPlaceInstitutionalAppearanceModal-Title' >
                        <b className={enableAutomaticColorPick ? 'label-disabled' : ''} >Cor Terciária 2</b>
                    </p>
                    <div onClick={() => setShowInfo(prev => ({ ...prev, tertiaryColor2: !prev.tertiaryColor2 }))}>
                        <InfoOutlinedIcon style={{width: '20px', height: '20px', cursor: 'pointer'}} id={'tertiaryColor2'}/>
                        {showInfo.tertiaryColor2 && (
                            <div className='HealthPlaceInstitutionalAppearanceModal-Inf-Container'>
                            Altera a cor dos botões durante o clique.
                            </div>
                        )}
                    </div>
                </div>
                <div 
                    className='HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor'
                    style={{ "backgroundColor": holdingInstitution.primary_color_darker }}
                >
                    <div className='HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor-Chrome'>
                    { !enableAutomaticColorPick
                        ? <Chrome 
                            color={holdingInstitution.primary_color_darker}
                            onChange={(color) => handleChange({color, chosenField: 'primary_color_darker'})}
                        />
                        : null
                    }
                    </div>
                </div>
            </div>
            <div>
            <div className='HealthPlaceInstitutionalAppearanceModal-Title-Box'>
                    <p className='HealthPlaceInstitutionalAppearanceModal-Title' >
                        <b className={enableAutomaticColorPick ? 'label-disabled' : ''} >Cor da fonte</b>
                    </p>
                    <div onClick={() => setShowInfo(prev => ({ ...prev, primaryFontColor: !prev.primaryFontColor }))}>
                        <InfoOutlinedIcon style={{width: '20px', height: '20px', cursor: 'pointer'}} id={'primaryFontColor'}/>
                        {showInfo.primaryFontColor && (
                            <div className='HealthPlaceInstitutionalAppearanceModal-Inf-Container'>
                            Define a cor da fonte usada no menu principal, na seção de informações da página inicial e nos botões.
                            </div>
                        )}
                    </div>
                </div>
                <div 
                    className='HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor'
                    style={{ "backgroundColor": holdingInstitution.primary_font_color }}
                >
                    <div className='HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor-Chrome'>
                    { !enableAutomaticColorPick
                        ? <Chrome
                            color={holdingInstitution.primary_font_color}
                            onChange={(color) => handleChange({color, chosenField: 'primary_font_color'})}
                        />
                        : null
                    }
                    </div>
                </div>
            </div>
        </div>
        <div className='HealthPlaceInstitutionalAppearanceModal-ActionBox'>
            <div>
                    <input
                        type="checkbox"
                        checked={enableAutomaticColorPick}
                        onChange={() => setEnableAutomaticColorPick(prev => !prev)}
                        defaultChecked
                        id='enableAutomaticColorPick-Checkbox'
                        className='EnableAutomaticColorPick-Checkbox'
                    />
                    <label for={"enableAutomaticColorPick-Checkbox"} className='enableAutomaticColorPick-Label'>Seleção automática de cores.</label>
            </div>
            <div className='HealthPlaceInstitutionalAppearanceModal-BtnBox'>
                <NewButton
                    onClick={handleSetDefaultColors}
                    label='Voltar às cores padrão'
                    model={ButtonModel.SECONDARY}
                />
                <NewButton 
                    onClick={handleSubmit}
                    label='Atualizar'
                    model={ButtonModel.PRIMARY}
                />
            </div>
        </div>
    </div>
}