import React from 'react'

export function BoxIcon({style={}}) {
    return <svg style={style} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M1143 4532 c-540 -312 -981 -571 -980 -575 4 -14 1941 -1130 1962
            -1131 27 -1 1978 1125 1967 1136 -8 8 -1962 1139 -1966 1137 0 0 -443 -255
            -983 -567z"/>
            <path d="M0 2553 l0 -1139 973 -561 c534 -309 980 -565 990 -568 16 -7 17 51
            17 1131 l0 1139 -975 563 c-536 309 -981 565 -990 568 -13 6 -15 -109 -15
            -1133z"/>
            <path d="M3258 3123 l-978 -566 0 -1140 c0 -981 2 -1138 14 -1134 16 6 588
            336 647 373 l36 22 -14 74 c-23 118 -12 386 20 510 57 217 168 410 327 568
            232 231 519 355 840 363 l105 2 3 748 c2 595 0 747 -10 746 -7 0 -453 -255
            -990 -566z"/>
            <path d="M4090 1890 c-381 -37 -702 -307 -811 -682 -26 -92 -37 -317 -20 -411
            70 -369 338 -655 698 -748 250 -64 536 -13 753 134 66 45 186 163 236 234 212
            295 229 701 42 1018 -66 113 -212 262 -313 321 -185 108 -385 153 -585 134z
            m240 -585 l0 -195 195 0 195 0 0 -150 0 -150 -195 0 -195 0 0 -195 0 -195
            -150 0 -150 0 0 195 0 195 -190 0 -190 0 0 150 0 150 190 0 190 0 0 188 c0
            104 3 192 7 195 3 4 71 7 150 7 l143 0 0 -195z"/>
        </g>
    </svg>
}

