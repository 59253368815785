import './PatientInactivateModal.css'
import React from 'react'
import Modal from '../../../../../../newcomponents/modal/modal.js'

export default function PatientInactivateModal({show, inactivateHandler, patient}) {

    if(!patient) return null

    return <Modal
        open={show}
        title='Inativar usuário'
        dismissText='Não, cancelar'
        confirmText='Sim, inativar!'
        dismissFn={inactivateHandler}
        content={
            <div className='inactivate-user-modal'>
                <div className='inactivate-user-warning'>
                    Atenção!
                </div>

                A conta do paciente <b>{patient.name}</b> será inativada,
                exigindo a reativação para voltar a utilizá-lo.

                <div className='inactivate-user-confirmation'>
                    Deseja realmente inativar o usuário?
                </div>
            </div>
        }
    />

}