import './UltrasonographyView.css'
import React from 'react'
import { getAmnioticFluidResults, getPlacentaResults } from '../utils'
import AlertRowText from '../../../../../../../../../../../../../../../component/alertRowText/alertRowText'
import { Button, IcoBin, IcoPencil, List } from '../../../../../../../../../../../../../../../component'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import { convertDate } from '../../../../../../../../../../../../../../../utils/convertDate'
import ObstetricUltrasonographyWrite from '../ObstetricUltrasonographyWrite/UltrasonographyWrite'
import api from '../../../../../../../../../../../../../../../helpers/api'

export default function ObstetricUltrasonographyView() {
	const { 
		selectedLanguage, 
		holdingProject: { obstetric_ultrasonography_exams },
		setModalInfo,
		fetchThousandDaysProjects
	} = useThousandDays()

	const {
		Notinformation,
		Notevaluated,
		Result,
		Dates,
		Age,
		Side,
		Previous,
		Preview,
		Low,
		Back,
		Change,
		BabyWeight,
		ButtockToHeadLength,
		Obstetricultrasound
	} = require('../../../../../../../../../../../../../../data/translation.json')[selectedLanguage]
	const translate = require('../../../../../../../../../../../../../../data/translation.json')[selectedLanguage]

	const optionsMap = {
		NOT_EVALUATED: Notevaluated,
		PREVIOUS: Previous,
		POSTERIOR: Back,
		FUNDAL: 'Fundal',
		SIDE: Side,
		PREVIEW: Preview,
		MARGINAL: 'Marginal',
		LOW: Low,
	}

	const headDefaultUltrasonography = [
		{ colunm: 'h_ultrasound_result', text: `${Age} Gest.` },
		{ colunm: 'h_baby_weight', text: BabyWeight },
		{ colunm: 'h_buttock_to_head_length', text: ButtockToHeadLength },
		{ colunm: 'h_date', text: Dates, width: '100px' },
		{ colunm: 'h_action', text: '', width: '100px' },
	]

	const headDefaultPlacentLiqAmn = [
		{ colunm: 'h_placenta', text: 'Placenta' },
		{ colunm: 'h_placenta_result', text: Result },
		{ colunm: 'h_liq_amn', text: 'Líq Amn (MBV)' },
		{ colunm: 'h_liq_amn_result', text: Result },
		{ colunm: 'h_date', text: Dates, width: '100px' },
		{ colunm: 'h_action', text: '', width: '100px' },
	]

	const handleDelete = async (id) => {
		await api.delete(`exam/obstetric_ultrasonography/${id}/`)
		fetchThousandDaysProjects()
	}

	const openExamWriteModal = (holdingExam) => {
		setModalInfo(prev => ({
			...prev,
			open: true,
			title: Obstetricultrasound,
			content: (
				<div className='SOAP-ThousandDaysExamSubSection-Modal'>
					<ObstetricUltrasonographyWrite 
						holdingExam={holdingExam} 
						setModalInfo={setModalInfo} 
					/>
				</div>
			),
		}))
	}
	
	return (
		<div className='ObstetricUltrasonographyView'>
			<div className='ObstetricUltrasonographyView-List'>
				<List
					head={headDefaultUltrasonography}
					data={obstetric_ultrasonography_exams}
					noData={Notinformation}
					listCustom={(exam, i) => {
						exam['h_ultrasound_result'] = `${exam.ultrasound_weeks}s ${exam.ultrasound_days}d`
						exam['h_baby_weight'] = `${exam?.baby_weight} g` || '-'
						exam['h_buttock_to_head_length'] = `${exam?.buttock_to_head_length} mm` || '-'
						exam['h_date'] = exam?.date ? convertDate(exam.date, '-', '/') : '-'
						exam['h_action'] = (
							<div>
								<Button
									color='secondary'
									type='btn circle'
									action={() => openExamWriteModal(exam)}
									title='Editar exame'>
									<IcoPencil />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									action={() => handleDelete(exam?.id)}
									title='Excluir exame'>
									<IcoBin />
								</Button>
							</div>
						)
						return exam
					}}
				/>
				<List
					head={headDefaultPlacentLiqAmn}
					data={obstetric_ultrasonography_exams}
					noData={Notinformation}	
					listCustom={(exam, i) => {
						exam['h_placenta'] = optionsMap[exam?.placenta] || '-'
						exam['h_placenta_result'] = getPlacentaResults(exam.placenta)?.content 
							? <AlertRowText {...getPlacentaResults(exam.placenta, Change)} />
							: '-'
						exam['h_liq_amn'] = exam.amniotic_fluid || '-'
						exam['h_liq_amn_result'] = getAmnioticFluidResults(exam.amniotic_fluid, translate)?.content 
							? <AlertRowText {...getAmnioticFluidResults(exam.amniotic_fluid, translate)} />
							: '-'
						exam['h_date'] = exam?.date ? convertDate(exam.date, '-', '/') : '-'
						exam['h_action'] = (
							<div>
								<Button
									color='secondary'
									type='btn circle'
									action={() => openExamWriteModal(exam)}
									title='Editar exame'>
									<IcoPencil />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									action={() => handleDelete(exam?.id)}
									title='Excluir exame'>
									<IcoBin />
								</Button>
							</div>
						)
						return exam
					}}
				/>
			</div>
		</div>
	)
}
