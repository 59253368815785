import './HealthPlaceBranches.css'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
	Input,
	List,
	IcoEye,
	MaskCNPJ,
	Select,
	MaskTelefone,
	Button,
	IcoBin,
} from '../../../../../component'
import NewButton, {
	ButtonModel,
} from '../../../../../newcomponents/button/button'
import SaveHealthPlaceModal from './subcomponents/SaveHealthPlaceModal/SaveHealthPlaceModal'
import { useConfiguration } from '../../../configuration'
import Collaborators from '../../../../collaborators/Collaborators'
import RemoveHealthPlaceModal from './subcomponents/RemoveHealthPlaceModal/RemoveHealthPlaceModal'

const headDefault = [
	{ colunm: 'name', text: 'Nome' },
	{ colunm: 'cnpj', text: 'CNPJ' },
	{ colunm: 'type', text: 'Tipo' },
	{ colunm: 'contact_whatsapp', text: 'Telefone' },
	{ colunm: 'acoes', text: '', width: '100px' },
]

export default function HealthPlaceBranches() {
	const [input, setInput] = useState({ name: '', cnpj: '', type: {} })
	const { listas } = useSelector((state) => state.appState)
	const { healthPlaceBranches, fetchHealthPlaceBranches, setModalProps } = useConfiguration()

	useEffect(() => {
		fetchHealthPlaceBranches()
	}, [])

	const handleSubmit = (event) => {
		event.preventDefault()

		fetchHealthPlaceBranches({
			name: input.name,
			cnpj: input.cnpj.length ? input.cnpj.match(/\d/g).join('') : '',
			type: Object.keys(input.type).length ? input.type.id : '',
		})
	}

	const handleChange = ({ name, value }) => {
		setInput((prev) => ({ ...prev, [name]: value }))
	}

	function getNameById(tipo) {
		const matchTipo = listas.tipos.find((item) => item.id === tipo)
		return matchTipo ? matchTipo.name : tipo || '-'
	}

	return (
		<div className='HealthPlaceBranches'>
			<div>
				<div>
					<div className='HealthPlaceBranches-FilterRow'>
						<div>
							<b>Nome</b>
							<Input
								name='name'
								placeholder='Buscar Nome'
								action={({ target }) => handleChange(target)}
								value={input.name}
							/>
						</div>
						<div>
							<b>CNPJ</b>
							<Input
								name='cnpj'
								placeholder='Buscar CNPJ'
								maxLength='18'
								action={({ target }) => handleChange(target)}
								value={MaskCNPJ(input.cnpj)}
							/>
						</div>
						<div>
							<b>Tipo</b>
							<Select
								name='type'
								action={(e) => handleChange(e.target)}
								options={listas.tipos}
								selected={input.type ? input.type : {}}
							/>
						</div>
					</div>
					<div className='HealthPlaceBranches-BtnBox'>
						<NewButton
							label='Buscar'
							onClick={handleSubmit}
							model={ButtonModel.SECONDARY}
						/>
						<NewButton
							label='Cadastrar'
							onClick={() =>
								setModalProps({
									open: true,
									title: 'Salvar filial',
									content: <SaveHealthPlaceModal />,
									dismissText: '',
								})
							}
						/>
					</div>
				</div>
			</div>
			<div className='HealthPlaceBranches-List'>
				<List
					head={headDefault}
					data={healthPlaceBranches}
					noData='Nenhuma instituição encontrado'
					listCustom={(healthPlaceInstance) => {
						healthPlaceInstance['cnpj'] = MaskCNPJ(
							healthPlaceInstance?.cnpj
						)
						healthPlaceInstance['contact_whatsapp'] = MaskTelefone(
							healthPlaceInstance?.contact_whatsapp
						)
						healthPlaceInstance['type'] = getNameById(healthPlaceInstance.type)
						healthPlaceInstance['acoes'] = (
							<div className='acoes'>
								<Button
									color='primary'
									type='btn circle'
									title='Visualizar'
									action={() =>
										setModalProps({
											open: true,
											content: (
												<div className='HealthPlaceBranches-RegisterCollaborator'>
													<Collaborators healthPlace={healthPlaceInstance} />
												</div>
											),
										})
									}
								>
									<IcoEye />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									title='Editar'
									action={() =>
										setModalProps({
											open: true,
											content: (
												<RemoveHealthPlaceModal
													healthPlace={healthPlaceInstance}
												/>
											),
											title: `Remover ${healthPlaceInstance.name}`,
											dismissText: '',
										})
									}
								>
									<IcoBin />
								</Button>
							</div>
						)
						return healthPlaceInstance
					}}
				/>
			</div>
		</div>
	)
}
