import './UserLogoutModal.css'
import React from 'react'
import { useDispatch } from 'react-redux'
import Button, { ButtonModel } from '../../newcomponents/button/button'
import { MODAL_INIT_PROPS } from '../../newcomponents/modal/modal'
import { useApp } from '../App'

export default function UserLogoutModal({ setModalInfo }) {
  const dispatch = useDispatch()
  const { logOut } = useApp()

  return (
    <>
        <p>Tem certeza que deseja <b>sair</b> da aplicação?</p>
        <div className='UserLogoutModal-BtnBox'>
          <Button
            model={ButtonModel.SECONDARY}
            label='Fechar'
            onClick={() => setModalInfo(MODAL_INIT_PROPS)}
          />
          <Button 
            model={ButtonModel.DANGER}
            label='Confirmar'
            onClick={() => {
              logOut()
              setModalInfo(MODAL_INIT_PROPS)
            }}
          />
        </div>
    </>
  )
}