import './PatientProfileImage.css'
import React, { useRef } from "react";
import { useHoldingPatient } from '../../../../HoldingPatientInfoView';

export default function PatientProfileImage() {
    const fileInputRef = useRef(null);
    const { holdingPatient, openEditProfileImage } = useHoldingPatient()

    const defaultUser = require('../../../../../../media/DefaultUser.png')

    const handleEditButtonClick = () => {
        fileInputRef.current.click()
    }

    //console.log(holdingPatient)
    return <div>
        <div className='PatientProfileImage-Header'>
            <div className='PatientProfileImage-Header-Icon'>F</div>
            <div className='PatientProfileImage-Header-Title'>Foto de Perfil</div>
        </div>
        <input 
            type="file" 
            className="PatientProfileImage-HideChooseFile" 
            name="file_photo"
            ref={fileInputRef}
            onChange={openEditProfileImage}
            style={{ display: 'none' }}
        />
        <div className='PatientProfileImage-Container-Avatar'>
            <div className='img'>
                <img
                    onClick={handleEditButtonClick}
                    className='PatientProfileImage-Container-Avatar-Img'
                    alt='Paciente foto de perfil'
                    src={holdingPatient?.avatar_img_url || defaultUser}
                />
            </div>
        </div>
        <div className='PatientProfileImage-ActionRow'>
            <div 
                className='PatientProfileImage-ActionRow-Btn' 
                onClick={handleEditButtonClick}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}