import React, { useRef } from "react";
import NewSelect from '../../../../../../../../../../newcomponents/form/select/select';
import NewButton, { ButtonModel } from '../../../../../../../../../../newcomponents/button/button';
import { Input } from '../../../../../../../../../../component';
import { uniqueIDs } from "../../../../../../../../../../utils/uniqueIDs";

const { RESULT_CHOICES } = require('../../../../../../../../../../component/form/select/options.json')

const PageTwo = ({ 
    recurringAppointment, 
    recurringAppointmentErr, 
    setRecurringAppointment, 
    setCurrentPage, 
    healthInsurances, 
    specialtiesOptions, 
    fetchHealthInsurances, 
    fetchSpecialtiesOptions,
}) => {
    const searchTimeout = useRef(null);

    const handleSearchHealthInsurance = (event) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current);

        searchTimeout.current = setTimeout(() => {
            fetchHealthInsurances({ 'name': event.target.value });
        }, 400);
    };

    const handleSearchSpecialties = (event) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current);

        searchTimeout.current = setTimeout(() => {
            fetchSpecialtiesOptions({ 'name__icontains': event.target.value });
        }, 400);
    };


    const handleSelectHealthInsurance = (event) => {
        setRecurringAppointment(prev => ({
            ...prev,
            "health_insurance": prev?.health_insurance?.id !== event.target.selected 
                ? healthInsurances.find(({ id }) => id === event.target.selected)
                : ''
        }));
    };

    const handleSelectSpecialty = (event) => {
        setRecurringAppointment(prev => ({
            ...prev,
            "specialty": prev?.specialty?.id !== event.target.selected 
                ? specialtiesOptions.find(({ id }) => id === event.target.selected)
                : ''
        }));
    };

    const handleSelectResult = (event) => {
        setRecurringAppointment(prev => ({ ...prev, "result": event.target.selected }));
    };

    return (
        <div>
            <NewSelect 
                label='Convênio'
                onSelect={handleSelectHealthInsurance}
                canBeEmpty={false}
                options={uniqueIDs(recurringAppointment?.health_insurance, healthInsurances)}
                selected={recurringAppointment?.health_insurance?.id || ''}
                filterNode={<div className='NewAppointmentModal-NewSelect'>
                    <Input 
                        placeholder='Pesquisar por nome'
                        action={handleSearchHealthInsurance}
                        actionFocus={() => fetchHealthInsurances()}
                    />
                </div>}
            />
            <NewSelect 
                label='Especialidade'
                onSelect={handleSelectSpecialty}
                canBeEmpty={false}
                options={uniqueIDs(recurringAppointment?.specialty, specialtiesOptions)}
                optionRefKey='id'
                optionStrKey='strf'
                selected={recurringAppointment?.specialty?.id || ''}
                filterNode={<div className='NewAppointmentModal-NewSelect'>
                    <Input 
                        placeholder='Pesquisar por nome'
                        action={handleSearchSpecialties}
                        actionFocus={() => fetchSpecialtiesOptions()}
                    />
                </div>}
            />
            <NewSelect 
                label='Tipo *'
                onSelect={handleSelectResult}
                canBeEmpty={false}
                options={RESULT_CHOICES}
                selected={recurringAppointment?.result || ''}
            />
            <div className='RecurringPatientModal-BtnBox'>
                <NewButton 
                    label='Voltar'
                    model={ButtonModel.SECONDARY}
                    onClick={() => setCurrentPage('1')}
                />
                <NewButton 
                    label='Prosseguir'
                    disabled={!recurringAppointment?.result}
                    onClick={() => setCurrentPage('3')}
                />
            </div>
        </div>
    );
};

export default PageTwo;
