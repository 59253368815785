import './MevoPrescription.css'
import React, { useEffect } from 'react'
import Loading from '../../../../../../../../../../../../newcomponents/loading/loading'
import NewButton from '../../../../../../../../../../../../newcomponents/button/button'
import { history } from '../../../../../../../../../../../../helpers'
import EditAddress from '../../../../../../../../../../../datailPatient/subcomponents/EditAddress/EditAddress'
import { useAttendance } from '../../../../../../../../../../consultation'

export default function MevoPrescription({ mevoModalURL, setMevoModalURL, getMevoModalURL, setModalInfo=() => null }) {
    const { attendance } = useAttendance()

    useEffect(() => {
        setMevoModalURL('')
        getMevoModalURL()
        // eslint-disable-next-line
    }, [])

    const handleEditExtraAction = () => {
        setMevoModalURL('reload')
    }

    // eslint-disable-next-line
    const urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig  
    const isValidURL = urlRegex.test(mevoModalURL)

    if (isValidURL) return <div className='patientNewDocumentBox'>
        <iframe className='patientNewDocumentFrame' title='Mevo Modal' src={mevoModalURL}></iframe>
    </div>
    
    switch (mevoModalURL) {
        case '':
            return (
                <div className='patientNewDocumentBox'>
                    <Loading />
                </div>
            )
        case 'reload':
            return <div>
                <NewButton
                    label='Recarregar'
                    onClick={() => getMevoModalURL()}
                />
            </div>
        case 'health place contact not found':
            return <p>O estabelecimento não possui contato cadastrado, solicite o cadastro para o administrador!</p>
        case 'health place address not found':
            return <p>O estabelecimento não possui endereço cadastrado, solicite o cadastro para o administrador!</p>
        case 'doctor address not found':
            return <div>
                <p>Cadastre seu endereço para criar um documento!</p>
                <NewButton 
                    onClick={() => history.push('/configuration/address')}
                    label='Cadastrar'
                />
            </div>
        case 'Council is invalid':
            return <div>
                <p>Houve um erro ao consultar seu conselho profissional, cadastre o mesmo novamente.</p>
                <NewButton 
                    label='Cadastrar'
                    onClick={() => history.push('/configuration/professionalcouncil')}
                />
            </div>
        case 'doctor specialties not found':
            return <div>
                <p>Houve um erro ao consultar sua especialidade, cadastre seu conselho profissional novamente.</p>
                <NewButton 
                    label='Cadastrar'
                    onClick={() => history.push('/configuration/professionalcouncil')}
                />
            </div>
        case 'patient address not found':
            return <>
                <p>Precisamos saber o <b>endereço do paciente</b> para acessar documentos</p>
                <NewButton 
                    label='Cadastrar'
                    onClick={() => setModalInfo(prevData => ({ 
                        ...prevData, 
                        open: true, 
                        title: 'Endereço do Paciente',
                        content: <EditAddress setModalInfo={setModalInfo} initHoldingPatient={attendance?.person} fetchExtraAction={handleEditExtraAction} />,
                        confirmText: ''
                    }))}
                />
            </>
        case 'error calling mevo finish later request':
            return <p>Houve um erro ao solicitar o modal de Terminar mais tarde.</p>
        case 'error calling mevo request':
            return <p>Houve um erro ao solicitar o Receituário Mevo.</p>
        case 'cancel':
            return <p>Terminar mais tarde selecionado! Seu progresso estará salvo quando voltar!</p>
        case 'excluded':
            return <p>Documento(s) excluído(s) com sucesso!</p>
        default:
            return <p>Houve um erro ao acessar o Receituário Mevo.</p>
    }
}