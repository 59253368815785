import './DeleteInventoryMaterialModal.css'
import React from "react";
import { useDispatch } from 'react-redux';
import IcoBoxDelete from '../../../../../../../../component/icon/box_delete';
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import { AddAlert, AlertType } from '../../../../../../../../component';
import { patchInventoryMaterial } from '../../../../http';


export default function DeleteInventoryMaterialModal({ holdingInventoryMaterial=null, fetchMaterials=() => null, setModalInfo=() => null, fetchExtraAction=() => null }) {
    const dispatch = useDispatch()

    const handleDelete = async () => {
        const payload = { active: false }

        dispatch(loading())
        try {
            if (holdingInventoryMaterial?.id) {
                await patchInventoryMaterial(holdingInventoryMaterial?.id, payload)
            }
            await fetchMaterials({ "offset": 0 })
            dispatch(AddAlert('Estoque de Materiais', 'Material excluído com sucesso!', AlertType.SUCCESS))
            setModalInfo(MODAL_INIT_PROPS)
            fetchExtraAction()
        } catch (err) {
            console.error('DeleteInventoryMaterialModal ~ handleDelete ~ ', err)
        }
        dispatch(loaded())
    }

    return <div className='DeleteInventoryMaterialModal'>
        {
            /*
        <div className='DeleteInventoryMaterialModal-Subtitle'>
            <div><IcoBoxDelete /></div>
            <b>Excluir Material</b>
        </div>
             */
        }

        <p>Tem certeza que deseja excluir o material <b>"{holdingInventoryMaterial?.name || '-'}"</b>?</p>
        <p>A ação não poderá ser desfeita.</p>
        <div className='DeleteInventoryMaterialModal-BtnBox'>
            <NewButton 
                label='Voltar'
                model={ButtonModel.SECONDARY}
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
            />
            <NewButton 
                label='Excluir'
                model={ButtonModel.DANGER}
                onClick={handleDelete}
            />
        </div>
    </div>
}