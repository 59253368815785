import './InventoryMaterialImageModal.css'
import React, { useState } from "react"
import { useDispatch } from 'react-redux'
import { Cropper } from 'react-cropper'
import Button from '../../../../../../../../../newcomponents/button/button'
import { MODAL_INIT_PROPS } from '../../../../../../../../../newcomponents/modal/modal'
import { AddAlert, AlertType } from '../../../../../../../../../component'
import ZoomOutIco from '../../../../../../../../../component/icon/zoom-out'
import ZoomInIco from '../../../../../../../../../component/icon/zoom-in'
import UpIco from '../../../../../../../../../component/icon/up'
import DownIco from '../../../../../../../../../component/icon/down'
import LeftIco from '../../../../../../../../../component/icon/left'
import RightIco from '../../../../../../../../../component/icon/right'
import { putMaterialImg } from '../../../../../http'

export default function InventoryMaterialImageModal({ setModalInfo=() => null, inventoryMaterial=null, fetchExtraAction=async () => null }) {
    const dispatch = useDispatch()
    const [cropper, setCropper] = useState();
    const defaultImage = require('../../../../../../../../../media/defaultImage.png')

    const handleSubmit = async () => {
        const base64_avatar = cropper?.getCroppedCanvas()?.toDataURL()

        if (base64_avatar && typeof cropper !== "undefined") {
        try {
            if (inventoryMaterial?.id) {
                await putMaterialImg({"material": inventoryMaterial.id, "base64_avatar": base64_avatar})
            }
            await fetchExtraAction(base64_avatar)
            dispatch(AddAlert('Editar Foto', 'Foto capturada com sucesso!', AlertType.SUCCESS))
            setModalInfo(MODAL_INIT_PROPS)
        } catch (err) {
            console.error('InventoryMaterialImageModal ~ handleSubmit ~ ', err);
            if (err.response.status === 413) {
                dispatch(AddAlert('Editar Foto', 'É permitido o upload de imagem até 20MB, por favor selecione outro arquivo', AlertType.SUCCESS));
            } else {
                dispatch(AddAlert('Editar Foto', 'Falha ao salvar Foto', AlertType.ERROR));
            }
        }}
    }

    return <div className='InventoryMaterialImageModal'>
        <div className='InventoryMaterialImageModal-PersonalInfo'>
            <div className='InventoryMaterialImageModal-Subtitle-Header'>
                <div className='InventoryMaterialImageModal-Subtitle-Header-Icon InventoryMaterialImageModal-Subtitle-Header-Icon-Purple'>F</div>
                <div className='InventoryMaterialImageModal-Subtitle-Header-Title'>Foto</div>
            </div>
        </div>
        <div>
            <div style={{ width: "100%" }}>
                <Cropper
                    style={{ height: 320, width: "100%" }}
                    zoomTo={0.5}
                    initialAspectRatio={1}
                    preview=".InventoryMaterialImageModal-img-preview"
                    src={inventoryMaterial?.base64_avatar || defaultImage}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                    aspectRatio={1}
                    guides={true}
                />
            </div>
            <br style={{ clear: "both" }} />

            <div className='InventoryMaterialImageModal-ActionRow'>
                <button className='InventoryMaterialImageModal-Action' onClick={() => {if (cropper) cropper.zoom(0.1)}}><ZoomInIco /></button>
                <button className='InventoryMaterialImageModal-Action' onClick={() => {if (cropper) cropper.zoom(-0.1)}}><ZoomOutIco /></button>

                <button className='InventoryMaterialImageModal-Action' onClick={() => {if (cropper) cropper.move(10, 0)}}><LeftIco /></button>
                <button className='InventoryMaterialImageModal-Action' onClick={() => {if (cropper) cropper.move(-10, 0)}}><RightIco /></button>
                <button className='InventoryMaterialImageModal-Action' onClick={() => {if (cropper) cropper.move(0, -10)}}><DownIco /></button>
                <button className='InventoryMaterialImageModal-Action' onClick={() => {if (cropper) cropper.move(0, 10)}}><UpIco /></button>
            </div>
        </div>

        <div className='InventoryMaterialImageModal-Btn-Box'>
            <Button 
                onClick={handleSubmit}
                label='Salvar'
            />
        </div>
    </div>
}