import './HealthPlaceGeneralData.css'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Input,
	MaskCNPJ,
	MaskTelefone,
	Select,
} from '../../../../../../../../../component'
import NewButton, {
	ButtonModel,
} from '../../../../../../../../../newcomponents/button/button'
import { useHealthPlaceForm } from '../../SaveHealthPlaceModal'
import { useConfiguration } from '../../../../../../../configuration'
import { MODAL_INIT_PROPS } from '../../../../../../../../../newcomponents/modal/modal'
import {
	AddAlert,
	AlertType,
} from '../../../../../../../../../component/alert/actions'
import { useApp } from '../../../../../../../../../layout/App'
import { stringifyCollaboratorOption } from '../../../../../../../../../utils/stringifyCollaboratorOption'
import { getCollaborators } from '../../../../../../../../collaborators/http'
import { validarCNPJ } from '../../../../../../../../../component/validation/Validation'
import { checkIfHealthPlaceExists } from '../../../../../../../http'

export default function HealthPlaceGeneralData() {
	const dispatch = useDispatch()
	const { setModalProps } = useConfiguration()
	const { currentHealthPlaceUser } = useApp()
	const { selectedHealthPlace, setSelectedHealthPlace, onNext } = useHealthPlaceForm()
	const { listas } = useSelector((state) => state.appState)
	const [collaboratorsOptions, setCollaboratorsOptions] = useState([])
	const [hasSubmitted, setHasSubmitted] = useState(false)
	const [errors, setErrors] = useState({
		cnpj: false,
		contact_whatsapp: false,
		name: false,
		type: false,
		technical_responsible: false,
		cnes: false
	})


	useEffect(() => {
		fetchCollaboratorsOptions({offset: 0})
	}, [])

	const fetchCollaboratorsOptions = async params => {
		await getCollaborators({
			...params,
			health_place__id: currentHealthPlaceUser?.health_place?.id,
            is_active: true,
			has_person: true
		})
			.then(res => {
                setCollaboratorsOptions(res.data.results)})
			.catch(err => {
				console.error('fetchCollaboratorsOptions', err)
				setCollaboratorsOptions([])
			})
	}

	const handleChange = ({ target }) => {
		const { name, value } = target

		if (hasSubmitted) {
			const error = handleValidateField(name, value)
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: error,
			}));
		}

		setSelectedHealthPlace((prev) => ({ ...prev, [name]: value }))
	}

	const validateFields = () => {
		const fields = ['cnpj', 'cnes', 'type', 'technical_responsible', 'contact_whatsapp', 'name']
		let validationErrors = {}

		fields.forEach(field => {

			const fieldError = handleValidateField(field, selectedHealthPlace[field])
			validationErrors[field] = fieldError;
		});
		setErrors(validationErrors);
		return Object.values(validationErrors).some((error) => error === true);
	}


	const handleValidateField = (name, value) => {
		let error = false;		

		switch (name) {
			case 'cnpj':
				if (!value) {
					error = true
				} else {
					const validCnpj = validarCNPJ(value)
					error = validCnpj === 'invalido' ? true : false
				}
				break

			case 'contact_whatsapp':
				error = value.length < 10;
				break;
			
			case 'type':
				error = !value.id

			case 'technical_responsible':
				break

			case 'cnes':
				break;

			default:
				if (!value) {
					error = true
				}
		}

		return error
	};

	const checkIfAlreadyExists = async () => {
		const params = {
			'cnpj': selectedHealthPlace['cnpj'].replace(/\D/g, ''),
			'cnes': selectedHealthPlace['cnes']
		}

		try{
			const res = await checkIfHealthPlaceExists(params)
			if (res.status === 200){
				return null
			}
		} catch (err) {
			if (err?.response?.data?.cnpj) {
				setErrors(prev => ({
					...prev,
					'cnpj': true
				}))
			}
			if (err?.response?.data?.cnes) {
				setErrors(prev => ({
					...prev,
					'cnes': true
				}))
			}
			return {
				'cnpj': !!err?.response?.data?.cnpj,
				'cnes': !!err?.response?.data?.cnes
			}
	}
}

	const handleNext = async () => {
		setHasSubmitted(true)
		
		const hasError = validateFields()
		const cnpjCnesError = await checkIfAlreadyExists()

		if (hasError || cnpjCnesError?.cnpj || cnpjCnesError?.cnes) {
			if (cnpjCnesError?.cnpj || cnpjCnesError?.cnes) {
				const errorMessageParts = [];

				if (cnpjCnesError.cnpj) {
					errorMessageParts.push('CNPJ');
				}
				if (cnpjCnesError.cnes) {
					errorMessageParts.push('CNES');
				}
			
				const errorMessage = `Corrija os campos em vermelho para avançar. O ${errorMessageParts.join(' e ')} informado(s) já está(ão) cadastrado(s) em nosso sistema, favor corrigir.`;
			
				dispatch(
					AddAlert(
						'Erro na validação',
						errorMessage,
						AlertType.ERROR
					)
				)
			} else {
				dispatch(
					AddAlert(
						'Erro na validação',
						'Corrija os campos em vermelho para avançar.',
						AlertType.ERROR
					)
				)
			}
			return
		}
		onNext()
	}

	return (
		<div className='HealthPlaceForm-Body HealthPlaceGeneralData'>
			<div>
				<b><span className='Title-Asterisk'>*</span> Nome</b>
				<Input
					name='name'
					action={(e) => handleChange(e)}
					//actionBlur={(e, v) => handleValidate(e, v)}
					value={selectedHealthPlace.name}
					required={{
						erro: {name: errors?.name} 
					}}
				/>
			</div>
			<div>
				<b><span className='Title-Asterisk'>*</span> Contato (Whatsapp)</b>
				<Input
					name='contact_whatsapp'
					action={(e) => handleChange(e)}
					//actionBlur={(e, v) => handleValidate(e, v)}
					value={MaskTelefone(selectedHealthPlace.contact_whatsapp)}
					maxLength='15'
					required={{
						erro: {contact_whatsapp: errors?.contact_whatsapp}
					}}
				/>
			</div>
			<div className='HealthPlaceGeneralData-Form-2Column'>
				<div>
					<b><span className='Title-Asterisk'>*</span> Tipo</b>
					<Select
						name='type'
						action={(e) => handleChange(e)}
						//actionClose={(e, v) => handleValidate(e, v)}
						options={listas.tipos}
						selected={selectedHealthPlace.type ? selectedHealthPlace.type : {}}
						required={{
							erro: {type: errors?.type}
						}}
					/>
				</div>
				<div>
					<b>Responsável Técnico</b>
					<Select
						name='technical_responsible'
						action={(e) => handleChange(e)}
						//actionClose={(e, v) => handleValidate(e, v)}
						options={collaboratorsOptions.map(instance => stringifyCollaboratorOption(instance))}
						selected={selectedHealthPlace.technical_responsible ? selectedHealthPlace.technical_responsible : {}}
						required={{
							erro: {technical_responsible: errors?.technical_responsible}
						}}
					/>
				</div>
			</div>
			<div>
				<b><span className='Title-Asterisk'>*</span> CNPJ</b>
				<Input
					name='cnpj'
					action={(e) => handleChange(e)}
					//actionBlur={(e, v) => handleValidate(e, v)}
					value={
						selectedHealthPlace.cnpj
							? MaskCNPJ(selectedHealthPlace.cnpj)
							: ''
					}
					maxLength='18'
					required={{
						erro: {cnpj: errors?.cnpj}
					}}
				/>
			</div>
			<div>
					<b>CNES</b>
					<Input
						name='cnes'
						action={(e) => handleChange(e)}
						//actionBlur={(e, v) => handleValidate(e, v)}
						value={selectedHealthPlace.cnes}
						maxLength='7'
						required={{
							erro: {cnes: errors?.cnes}
						}}
					/>
			</div>
			<div className='HealthPlaceForm-end-container'>
				<p className='Title-Asterisk'>* Campos obrigatórios</p>
				<div className='HealthPlaceForm-Btn-Box'>
					<NewButton
						model={ButtonModel.SECONDARY}
						label='Fechar'
						onClick={() => setModalProps(MODAL_INIT_PROPS)}
					/>
					<NewButton
						model={ButtonModel.PRIMARY}
						label=' Avançar'
						onClick={handleNext}
					/>
				</div>
			</div>
		</div>
	)
}
