import './ManufacturersOptions.css'
import React from "react";
import HorizontalField from '../../../../../../../../component/HorizontalField/HorizontalField';
import EditManufacturersOptionsModal from './EditManufacturersOptionsModal/EditManufacturersOptionsModal';
import { useInventoryMaterials } from '../../../../InventoryMaterials';

export default function ManufacturersOptions({ activeManufacturers=[], setModalInfo=() => null }) {
    const { canReadAndWrite } = useInventoryMaterials()

    return <div className='ManufacturersOptions'>
        <div className='ManufacturersOptions-Header'>
            <div className='ManufacturersOptions-Header-Icon'>F</div>
            <div className='ManufacturersOptions-Header-Title'>Fabricantes</div>
        </div>

        <div className='ManufacturersOptions-ActionRow'>
        {
            canReadAndWrite
            ? <div 
                className='ManufacturersOptions-ActionRow-Btn' 
                onClick={() => setModalInfo({
                    open: true,
                    title: 'Editar opções de fabricantes',
                    content: <EditManufacturersOptionsModal />
                })}
            >
                <b>Editar</b>
            </div>
            : null
        }
        </div>
        <div className='ManufacturersOptions-Body'>
            {
                activeManufacturers?.length
                ? activeManufacturers.map(manufacture_cursor => <HorizontalField label={manufacture_cursor?.name} content='Ativo' />) 
                : <p>Nenhum fabricante habilitado</p>
            }
        </div>

    </div>
}