import React from 'react'

export default function BloodDropIco({ style={} }) {
    return <svg className='icon-bloddrop' style={style} viewBox="0 0 512.000000 512.000000">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M2509 5105 c-14 -8 -42 -42 -63 -77 -20 -35 -122 -180 -227 -323
                -660 -901 -977 -1389 -1224 -1884 -132 -263 -203 -445 -256 -656 -85 -335 -26
                -772 153 -1129 145 -290 370 -544 638 -723 474 -316 1078 -397 1618 -217 531
                178 960 592 1155 1115 121 322 149 673 79 954 -151 598 -661 1489 -1465 2560
                -273 363 -302 395 -357 395 -14 -1 -37 -7 -51 -15z"/>
        </g>
    </svg>
}

